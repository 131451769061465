import axios from "axios";
import { createMessages, returnErrors } from "./messages";
import { tokenConfig } from "./auth";
import { serverurl, getDatabaseUrl } from "./databaseServerURL";

import {
  ADD_APPLICATION,
  ADD_APPLICATION_INCOME,
  ADD_APPLICATION_KIDS,
  GET_APPLICATION,
  GET_APPLICATION_INCOME,
  GET_APPLICATION_KIDS,
  GET_LAST_INCOME,
  APPLICATION_INCOME_QUERY_FALIED,
  APPLICATION_INCOME_QUERY_IN_PROGRESS,
  APPLICATION_KIDS_QUERY_FALIED,
  APPLICATION_KIDS_QUERY_IN_PROGRESS,
  CLEAR_APPLICATION_INCOME,
  CLEAR_APPLICATION_KIDS,
  CLEAR_LAST_INCOME,
  GET_CLAUSULE,
  ADD_APPLICATION_SWO,
  APPLICATION_SWO_QUERY_IN_PROGRESS,
  GET_APPLICATION_SWO,
  APPLICATION_SWO_QUERY_FALIED,
  CLEAR_APPLICATION_SWO,
  GET_LAST_PACKAGE,
  GET_LAST_SWO,
} from "./types";

//ADD_APPLICATION
export const addApplication = (body) => (dispatch, getState) => {
  axios
    .post(serverurl + getDatabaseUrl() + "/application", body, tokenConfig(getState))
    .then(res => {
      dispatch(createMessages({ addApplication: "Utworzono wpis" }));
      if (body.type == 1) {
        dispatch({
          type: ADD_APPLICATION_INCOME,
          payload: res.data,
          placed: true,
        });
      }
      else if (body.type == 2) {
        dispatch({
          type: ADD_APPLICATION_KIDS,
          payload: res.data,
        });
      }
      else if (body.type == 3) {
        dispatch({
          type: ADD_APPLICATION_SWO,
          payload: res.data,
        });
      }
    })
    .catch(err =>
      dispatch(returnErrors(err.response.data.detail, err.response.status))
    );
};

//GET_LAST_INCOME
export const getLastIncome = (owner) => (dispatch, getState) => {
  axios
    .get(serverurl + getDatabaseUrl() + "/last_income/?id=" + owner, tokenConfig(getState))
    .then(res => {
      dispatch({
        type: GET_LAST_INCOME,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch(returnErrors(err.response.data.detail, err.response.status))
    );
};

//GET_LAST_PACKAGE
export const getLastPackage = (owner) => (dispatch, getState) => {
  axios
    .get(serverurl + getDatabaseUrl() + "/last_package/?id=" + owner, tokenConfig(getState))
    .then(res => {
      dispatch({
        type: GET_LAST_PACKAGE,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch(returnErrors(err.response.data.detail, err.response.status))
    );
};

//GET_LAST_SWO
export const getLastSWO = (owner) => (dispatch, getState) => {
  axios
    .get(serverurl + getDatabaseUrl() + "/last_swo/?id=" + owner, tokenConfig(getState))
    .then(res => {
      dispatch({
        type: GET_LAST_SWO,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch(returnErrors(err.response.data.detail, err.response.status))
    );
};

//GET_APPLICATION_INCOME
export const getApplicationIncome = () => (dispatch, getState) => {
  dispatch({ type: APPLICATION_INCOME_QUERY_IN_PROGRESS });
  axios
    .get(serverurl + getDatabaseUrl() + "/application_inc/", tokenConfig(getState))
    .then(res => {
      dispatch({
        type: GET_APPLICATION_INCOME,
        payload: res.data
      });
    })
    .catch(err => {
      dispatch({ type: APPLICATION_INCOME_QUERY_FALIED });
      dispatch(returnErrors(err.response.data.detail, err.response.status));
    });
};

//MY GET_APPLICATION_INCOME
export const getMyApplicationIncome = (owner) => (dispatch, getState) => {
  dispatch({ type: APPLICATION_INCOME_QUERY_IN_PROGRESS });
  axios
    .get(serverurl + getDatabaseUrl() + "/application_inc/?id=" + owner, tokenConfig(getState))
    .then(res => {
      dispatch({
        type: GET_APPLICATION_INCOME,
        payload: res.data
      });
    })
    .catch(err => {
      dispatch({ type: APPLICATION_INCOME_QUERY_FALIED });
      dispatch(returnErrors(err.response.data.detail, err.response.status));
    });
};

//GET_APPLICATION_SWO
export const getApplicationSWO = () => (dispatch, getState) => {
  dispatch({ type: APPLICATION_SWO_QUERY_IN_PROGRESS });
  axios
    .get(serverurl + getDatabaseUrl() + "/application_swo/", tokenConfig(getState))
    .then(res => {
      dispatch({
        type: GET_APPLICATION_SWO,
        payload: res.data
      });
    })
    .catch(err => {
      dispatch({ type: APPLICATION_SWO_QUERY_FALIED });
      dispatch(returnErrors(err.response.data.detail, err.response.status));
    });
};

//MY GET_APPLICATION_SWO
export const getMyApplicationSWO = (owner) => (dispatch, getState) => {
  dispatch({ type: APPLICATION_SWO_QUERY_IN_PROGRESS });
  axios
    .get(serverurl + getDatabaseUrl() + "/application_swo/?id=" + owner, tokenConfig(getState))
    .then(res => {
      dispatch({
        type: GET_APPLICATION_SWO,
        payload: res.data
      });
    })
    .catch(err => {
      dispatch({ type: APPLICATION_SWO_QUERY_FALIED });
      dispatch(returnErrors(err.response.data.detail, err.response.status));
    });
};

//GET_APPLICATION_KIDS
export const getApplicationKids = () => (dispatch, getState) => {
  dispatch({ type: APPLICATION_KIDS_QUERY_IN_PROGRESS });
  axios
    .get(serverurl + getDatabaseUrl() + "/application_with_kids/", tokenConfig(getState))
    .then(res => {
      dispatch({
        type: GET_APPLICATION_KIDS,
        payload: res.data
      });
    })
    .catch(err => {
      dispatch({ type: APPLICATION_KIDS_QUERY_FALIED });
      dispatch(returnErrors(err.response.data.detail, err.response.status));
    });
};

//MY GET_APPLICATION_KIDS
export const getMyApplicationKids = (owner) => (dispatch, getState) => {
  dispatch({ type: APPLICATION_KIDS_QUERY_IN_PROGRESS });
  axios
    .get(serverurl + getDatabaseUrl() + "/application_with_kids/?id=" + owner, tokenConfig(getState))
    .then(res => {
      dispatch({
        type: GET_APPLICATION_KIDS,
        payload: res.data
      });
    })
    .catch(err => {
      dispatch({ type: APPLICATION_KIDS_QUERY_FALIED });
      dispatch(returnErrors(err.response.data.detail, err.response.status));
    });
};

//CLEARING
export const clearLastIncome = () => (dispatch) => {
  dispatch({ type: CLEAR_LAST_INCOME });
};
export const clearApplicationIncome = () => (dispatch) => {
  dispatch({ type: CLEAR_APPLICATION_INCOME });
};
export const clearApplicationKids = () => (dispatch) => {
  dispatch({ type: CLEAR_APPLICATION_KIDS });
};
export const clearApplicationSWO = () => (dispatch) => {
  dispatch({ type: CLEAR_APPLICATION_SWO });
};

export const getClausule = () => (dispatch, getState) => {
  axios
    .get(serverurl + "/document/clausule", tokenConfig(getState))
    .then(res => {
      dispatch({
        type: GET_CLAUSULE,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch(returnErrors(err.response.data.detail, err.response.status))
    );
};