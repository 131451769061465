import React, { Component, Fragment } from "react";
import { lighten } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import Grid from "@material-ui/core/Grid";
import Card from "../../components/Card/Card.js";
import CardBody from "../../components/Card/CardBody.js";
import CardHeader from "@material-ui/core/CardHeader";
import CircularProgress from "@material-ui/core/CircularProgress";
import PropTypes from "prop-types";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
//import DevicesOtherIcon from "@material-ui/icons/DevicesOther";
//integracja z reduxem
import { connect } from "react-redux";
import IconButton from "@material-ui/core/IconButton";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

const styles = (theme) => ({
  avatarOA: {
    backgroundColor: "#ff9800",
    height: 50,
    width: 50,
  },
  avatarOE: {
    backgroundColor: "rgba(0, 0, 0, 0.38)",
    height: 50,
    width: 50,
  },
  headertitle: {
    fontFamily: "Lato, Open Sans, sans-serif",
    fontSize: "1.2em",
    fontWeight: "700",
    color: "#444444",
  },
  headerOA: {
    textAlign: "left",
    backgroundColor: lighten("#ff9800", 0.85),
  },
  headerOE: {
    textAlign: "left",
    backgroundColor: lighten("rgba(0, 0, 0, 0.38)", 0.85),
  },
  info: {
    fontFamily: "Lato, Open Sans, sans-serif",
    fontSize: "1.5em",
    fontWeight: "300",
    color: "#0071BC",
  },
  listTextSecondary: {
    textAlign: "left",
    color: "#444444",
    fontFamily: "Lato, Open Sans, sans-serif",
    fontSize: "1em",
    fontWeight: "300",
    display: "block",
  },
  listTextSecondaryInfo: {
    textAlign: "left",
    color: "#2196f3",
    fontFamily: "Lato, Open Sans, sans-serif",
    fontSize: "1em",
    fontWeight: "300",
    display: "block",
  },
});

//sortowanie assetsów z listy
function compare(a, b) {
  if (a.nazwa.toUpperCase() < b.nazwa.toUpperCase()) {
    return -1;
  }
  if (a.nazwa.toUpperCase() > b.nazwa.toUpperCase()) {
    return 1;
  }
  return 0;
}

var wyplaszcz = {
  employeeAssetsFlattened: [],

  dodajElement: function(element) {
    this.employeeAssetsFlattened.push({
      pk: element.pk,
      grupa: element.model.eqtype_description,
      typ: element.model.eqtype,
      nazwa: element.model.name,
      model: element.model.description,
      producent: element.model.vendor,
      numerseryjny: element.serialNumber,
      numerproduktu: element.partNumber,
      datazakupu: element.purchaseDate,
      dataprzekazania: element.assignDate,
      opisdodatkowy: element.description,
      wlasciciel: element.owner.first_name + " " + element.owner.last_name,
      wlascicielDo: element.transferowner,
    });
  },
  zapiszMacierz: function(element) {
    return this.employeeAssetsFlattened;
  },
  inicjalizacja: function(element) {
    this.employeeAssetsFlattened = [];
  },
};

class AssetFilterList extends Component {
  state = {
    selectedIndex: null,
    employeeAssets: [],
    employeeAssetsFlatened: [],
    employeeAssetSelected: null,
    value: 0,
    index: null,
  };

  static propTypes = {
    employeeAssetsByStatusAc: PropTypes.array.isRequired,
    employeeAssetsByStatusTr: PropTypes.array.isRequired,
  };

  componentDidMount() {
    if (
      this.props.activeFilter === "acceptfilter" &&
      this.props.employeeAssetsByStatusAc.length !== 0
    ) {
      wyplaszcz.inicjalizacja();
      this.props.employeeAssetsByStatusAc.forEach(
        wyplaszcz.dodajElement,
        wyplaszcz
      );
      const macierz = wyplaszcz.zapiszMacierz();
      macierz.sort(compare);
      this.setState({
        employeeAssets: this.props.employeeAssetsByStatusAc,
        employeeAssetsFlatened: macierz,
      });
      macierz.sort(compare);
    } else if (
      this.props.activeFilter === "transferfilter" &&
      this.props.employeeAssetsByStatusTr.length !== 0
    ) {
      wyplaszcz.inicjalizacja();
      this.props.employeeAssetsByStatusTr.forEach(
        wyplaszcz.dodajElement,
        wyplaszcz
      );
      const macierz = wyplaszcz.zapiszMacierz();
      macierz.sort(compare);
      this.setState({
        employeeAssets: this.props.employeeAssetsByStatusTr,
        employeeAssetsFlatened: macierz,
      });
      macierz.sort(compare);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.activeFilter === "acceptfilter" &&
      nextProps.employeeAssetsByStatusAc !== this.state.employeeAssets
    ) {
      this.setState({
        selectedIndex: null,
        employeeAssetSelected: null,
      });
      //this.props.checked(false, null, null);

      wyplaszcz.inicjalizacja();
      nextProps.employeeAssetsByStatusAc.forEach(
        wyplaszcz.dodajElement,
        wyplaszcz
      );
      const macierz = wyplaszcz.zapiszMacierz();
      macierz.sort(compare);
      this.setState({
        employeeAssets: nextProps.employeeAssetsByStatusAc,
        employeeAssetsFlatened: macierz,
      });
      macierz.sort(compare);
    } else if (
      nextProps.activeFilter === "transferfilter" &&
      nextProps.employeeAssetsByStatusTr !== this.state.employeeAssets
    ) {
      this.setState({
        selectedIndex: null,
        employeeAssetSelected: null,
      });
      //this.props.checked(false, null, null);

      wyplaszcz.inicjalizacja();
      nextProps.employeeAssetsByStatusTr.forEach(
        wyplaszcz.dodajElement,
        wyplaszcz
      );
      const macierz = wyplaszcz.zapiszMacierz();
      macierz.sort(compare);
      this.setState({
        employeeAssets: nextProps.employeeAssetsByStatusTr,
        employeeAssetsFlatened: macierz,
      });
      macierz.sort(compare);
    }

    if (
      nextProps.activeListType !== "AssetFilterList" &&
      nextProps.activeListType !== null
    ) {
      this.setState({
        selectedIndex: null,
        employeeAssetSelected: null,
      });
    }
  }

  handleListItemClick = (event, index, pk) => {
    const selectedIndex = this.state.selectedIndex;
    if (selectedIndex !== index) {
      this.setState({
        selectedIndex: index,
        employeeAssetSelected: pk,
      });
      this.props.checked(true, pk, "AssetFilterList");
    } else {
      this.setState({
        selectedIndex: null,
        employeeAssetSelected: null,
      });
      this.props.checked(false, null, null);
    }
  };

  handleDisplayAssetDetails = (assetpk) => {};

  render() {
    const { employeeAssetsFlatened, selectedIndex } = this.state;
    const { classes, queryInProgress } = this.props;

    const icon = (
      <ListItemAvatar>
        <Avatar style={{ backgroundColor: "#ff9800" }}>
          <SupervisorAccountIcon />
        </Avatar>
      </ListItemAvatar>
    );

    const transfericon = (
      <ListItemAvatar>
        <Avatar style={{ backgroundColor: "#aaaaaa" }}>
          <SupervisorAccountIcon />
        </Avatar>
      </ListItemAvatar>
    );

    var nothingToDisplay = (
      <div className={classes.info}>
        Nie ma żadnych środków trwałych spełniających podane kryteria ...
      </div>
    );

    const waitToDisplay = (
      <div className={classes.info}>Czekaj, pobieram dane z serwera ...</div>
    );

    const circle = (
      <IconButton aria-label="settings">
        <CircularProgress color="secondary" />
      </IconButton>
    );

    const headerOA = (
      <CardHeader
        className={classes.headerOA}
        avatar={
          <Avatar aria-label="recipe" className={classes.avatarOA}>
            OA
          </Avatar>
        }
        action={queryInProgress ? circle : ""}
        title={
          <div className={classes.headertitle}>
            przeniesienia środków trwałych oczekujące na twoją akceptację
          </div>
        }
      />
    );

    const headerOE = (
      <CardHeader
        className={classes.headerOE}
        avatar={
          <Avatar aria-label="recipe" className={classes.avatarOE}>
            OE
          </Avatar>
        }
        action={queryInProgress ? circle : ""}
        title={
          <div className={classes.headertitle}>
            przeniesienia środków trwałych oczekujące akceptację twojego
            pracownika
          </div>
        }
      />
    );

    return (
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Card style={{ overflow: "auto", marginTop: 0, marginBottom: 0 }}>
          <CardBody style={{ padding: 0 }}>
            {this.props.activeFilter === "acceptfilter" ? headerOA : ""}
            {this.props.activeFilter === "transferfilter" ? headerOE : ""}
            <List component="nav" aria-label="main mailbox folders">
              {employeeAssetsFlatened.length === 0 && !queryInProgress
                ? nothingToDisplay
                : ""}
              {employeeAssetsFlatened.length === 0 && queryInProgress
                ? waitToDisplay
                : ""}
              {employeeAssetsFlatened.map((asset, index) => (
                <ListItem
                  key={asset.typ + asset.pk}
                  button
                  selected={selectedIndex === index}
                  onClick={(event) =>
                    this.handleListItemClick(event, index, asset.pk)
                  }
                >
                  {this.props.activeFilter === "acceptfilter" ? icon : ""}
                  {this.props.activeFilter === "transferfilter"
                    ? transfericon
                    : ""}
                  <ListItemText
                    primary={asset.nazwa}
                    secondary={
                      <Fragment>
                        <Typography
                          className={classes.listTextSecondary}
                          component="span"
                        >
                          {asset.model || "model nieznany"}
                        </Typography>
                      </Fragment>
                    }
                  />
                </ListItem>
              ))}
            </List>
          </CardBody>
        </Card>
      </Grid>
    );
  }
}

const mapStateToProps = (state) => ({
  employeeAssetsByStatusAc: state.assets.employeeAssetsByStatusAc,
  employeeAssetsByStatusTr: state.assets.employeeAssetsByStatusTr,
});

export default connect(
  mapStateToProps,
  {}
)(withStyles(styles, { withTheme: true })(AssetFilterList));
