//format datetime yyyy-mm-dd HH:MM
export function getFormattedDateTime(date) {
  const month = date.getMonth() + 1;

  const dd = (date.getDate() < 10 ? "0" : "") + date.getDate();
  const mm = (month < 10 ? "0" : "") + month;
  const yyyy = date.getFullYear();
  const HH = (date.getHours() < 10 ? "0" : "") + date.getHours();
  const MM = (date.getMinutes() < 10 ? "0" : "") + date.getMinutes();
  const dateTimeFormatted = yyyy + "-" + mm + "-" + dd + " " + HH + ":" + MM;
  return dateTimeFormatted;
}

//format datetime yyyy-mm-ddTHH:MM+TZ np "2020-12-31T13:26+01:00"
export function getFormattedDateTimeTimezone(datetime) {
  const dd = datetime.getDate();
  const mm = datetime.getMonth() + 1;
  const yyyy = datetime.getFullYear();
  const HH = datetime.getHours();
  const MM = datetime.getMinutes();
  const offset = -datetime.getTimezoneOffset() / 60;
  var timezone;
  if (-1 < offset < 10) {
    timezone = "+0" + offset + ":00";
  } else if (-10 < offset < 0) {
    timezone = "-0" + offset + ":00";
  } else if (offset > 9) {
    timezone = "+" + offset + ":00";
  } else {
    timezone = "-" + offset + ":00";
  }

  const datetimeformatted =
    yyyy + "-" + mm + "-" + dd + "T" + HH + ":" + MM + timezone;

  return datetimeformatted;
}

//format date
export function getFormattedDate(date) {
  const month = date.getMonth() + 1;

  const dd = (date.getDate() < 10 ? "0" : "") + date.getDate();
  const mm = (month < 10 ? "0" : "") + month;
  const yyyy = date.getFullYear();
  const dateFormatted = yyyy + "-" + mm + "-" + dd;

  return dateFormatted;
}

//format date
export function getMonthNameAndYear(date, filter) {
  var month = date.getMonth();
  var year = date.getFullYear();

  var monthtodisplay = null;
  var yeartodisplay = null;

  if (filter === "lastMonth") {
    switch (month) {
      case 0:
        monthtodisplay = "Grudzień";
        yeartodisplay = year - 1;
        break;
      case 1:
        monthtodisplay = "Styczeń";
        yeartodisplay = year;
        break;
      case 2:
        monthtodisplay = "Luty";
        yeartodisplay = year;
        break;
      case 3:
        monthtodisplay = "Marzec";
        yeartodisplay = year;
        break;
      case 4:
        monthtodisplay = "Kwiecień";
        yeartodisplay = year;
        break;
      case 5:
        monthtodisplay = "Maj";
        yeartodisplay = year;
        break;
      case 6:
        monthtodisplay = "Czerwiec";
        yeartodisplay = year;
        break;
      case 7:
        monthtodisplay = "Lipiec";
        yeartodisplay = year;
        break;
      case 8:
        monthtodisplay = "Sierpień";
        yeartodisplay = year;
        break;
      case 9:
        monthtodisplay = "Wrzesień";
        yeartodisplay = year;
        break;
      case 10:
        monthtodisplay = "Październik";
        yeartodisplay = year;
        break;
      case 11:
        monthtodisplay = "Listopad";
        yeartodisplay = year;
        break;
      default:
        monthtodisplay = "N/A";
        yeartodisplay = "N/A";
    }
  }

  var monthandyear = {
    month: monthtodisplay,
    year: yeartodisplay,
  };

  return monthandyear;
}

//format date
export function getLastDayOfPrevMonth(date) {
  const month = date.getMonth();
  const year = date.getFullYear();

  const lastdayofprevmonth = new Date(year, month, 0);
  const month_ldopm = lastdayofprevmonth.getMonth() + 1;
  const dd_ldopm =
    (lastdayofprevmonth.getDate() < 10 ? "0" : "") +
    lastdayofprevmonth.getDate();
  const mm_ldopm = (month_ldopm < 10 ? "0" : "") + month_ldopm;
  const yyyy_ldopm = lastdayofprevmonth.getFullYear();
  const dateFormatted = yyyy_ldopm + "-" + mm_ldopm + "-" + dd_ldopm;

  return dateFormatted;
}

//do daty date dodaj 60 dni i zwróć liczbę pozostałych dni. Wykorzystywane do wyliczania ile pracownikowi pozostało dni na zwrot sprzętu do magazynu
export function getRemainingDays(startingDate, daysToExpire) {
  //console.log(startingDate);
  //today
  const today = new Date();
  //data początkowa
  //const date1 = new Date(startingDate);
  //data końcowa jest wyliczana z daty początkowej i liczby dni daysToExpire
  const date2 = new Date(startingDate);
  date2.setDate(date2.getDate() + daysToExpire);

  // To calculate the time difference of two dates
  const Difference_In_Time = date2.getTime() - today.getTime();

  // To calculate the no. of days between two dates
  const Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);

  return Math.round(Difference_In_Days);
}

//prepare purchase summary
export function getSpendingsFromPurchases(purchases) {
  purchaseSumUpdate.inicjalisation();
  purchases.forEach(purchaseSumUpdate.update, purchaseSumUpdate);

  const pln = purchaseSumUpdate.pobierzPLN();
  const eur = purchaseSumUpdate.pobierzEUR();
  const sek = purchaseSumUpdate.pobierzSEK();
  const dkk = purchaseSumUpdate.pobierzDKK();
  const nok = purchaseSumUpdate.pobierzNOK();

  return [pln, eur, sek, dkk, nok];
}

export default getFormattedDateTime;

var purchaseSumUpdate = {
  pln: 0,
  eur: 0,
  sek: 0,
  dkk: 0,
  nok: 0,

  update: function(element) {
    if (element.priceCurrency === "pln") {
      this.pln = this.pln + Number(element.price);
    } else if (element.priceCurrency === "eur") {
      this.eur = this.eur + Number(element.price);
    } else if (element.priceCurrency === "sek") {
      this.sek = this.sek + Number(element.price);
    } else if (element.priceCurrency === "dkk") {
      this.dkk = this.dkk + Number(element.price);
    } else if (element.priceCurrency === "nok") {
      this.nok = this.nok + Number(element.price);
    }
  },
  pobierzPLN: function(element) {
    return this.pln.toFixed(2);
  },
  pobierzEUR: function(element) {
    return this.eur.toFixed(2);
  },
  pobierzSEK: function(element) {
    return this.sek.toFixed(2);
  },
  pobierzDKK: function(element) {
    return this.dkk.toFixed(2);
  },
  pobierzNOK: function(element) {
    return this.nok.toFixed(2);
  },
  inicjalisation: function(element) {
    this.pln = 0;
    this.eur = 0;
    this.sek = 0;
    this.dkk = 0;
    this.nok = 0;
  },
};

/* export async function modifyPdf(plik) {
  console.log(plik);
  //const url = "https://pdf-lib.js.org/assets/with_update_sections.pdf";
  const url = plik;
  const existingPdfBytes = await fetch(url).then((res) => res.arrayBuffer());
  console.log(existingPdfBytes);
  const pdfDoc = await PDFDocument.load(existingPdfBytes);
  const helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica);

  const pages = pdfDoc.getPages();
  console.log(pages);
  const firstPage = pages[0];
  const { width, height } = firstPage.getSize();
  firstPage.drawText("This text was added with JavaScript!", {
    x: 5,
    y: height / 2 + 300,
    size: 50,
    font: helveticaFont,
    color: rgb(0.95, 0.1, 0.1),
    rotate: degrees(-45),
  });

  const pdfBytes = await pdfDoc.save();

  fs.writeFile("helloworld.txt", "Hello World!", function(err) {
    if (err) return console.log(err);
    console.log("Hello World > helloworld.txt");
  });
} */
