import React, { Component, Fragment } from "react";
import { lighten } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import Grid from "@material-ui/core/Grid";
import Card from "../../components/Card/Card.js";
import CardBody from "../../components/Card/CardBody.js";
import CardHeader from "@material-ui/core/CardHeader";
import CircularProgress from "@material-ui/core/CircularProgress";
import PropTypes from "prop-types";
//import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
//import DevicesOtherIcon from "@material-ui/icons/DevicesOther";
import DeleteSweepIcon from "@material-ui/icons/DeleteSweep";
import PanToolIcon from "@material-ui/icons/PanTool";
//integracja z reduxem
import { connect } from "react-redux";
import IconButton from "@material-ui/core/IconButton";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { getRemainingDays } from "../../common/commonFunctions";

const styles = (theme) => ({
  avatarOA: {
    backgroundColor: "#dc004e",
    height: 50,
    width: 50,
  },
  avatarZM: {
    backgroundColor: "#ff9800",
    height: 50,
    width: 50,
  },
  headertitle: {
    fontFamily: "Lato, Open Sans, sans-serif",
    fontSize: "1.2em",
    fontWeight: "700",
    color: "#444444",
  },
  headerOA: {
    textAlign: "left",
    backgroundColor: lighten("#dc004e", 0.85),
  },
  headerZM: {
    textAlign: "left",
    backgroundColor: lighten("#ff9800", 0.85),
  },
  info: {
    fontFamily: "Lato, Open Sans, sans-serif",
    fontSize: "1.5em",
    fontWeight: "300",
    color: "#0071BC",
  },
  listTextSecondary: {
    textAlign: "left",
    color: "#444444",
    fontFamily: "Lato, Open Sans, sans-serif",
    fontSize: "1em",
    fontWeight: "300",
    display: "block",
  },
  listTextSecondaryComment: {
    textAlign: "left",
    color: "#999999",
    fontFamily: "Lato, Open Sans, sans-serif",
    fontSize: "1em",
    fontWeight: "300",
    display: "block",
  },
  listTextSecondaryError: {
    textAlign: "left",
    color: "#dc004e",
    fontFamily: "Lato, Open Sans, sans-serif",
    fontSize: "1em",
    fontWeight: "300",
    display: "block",
  },
});

//sortowanie assetsów z listy
function compare(a, b) {
  if (a.wlasciciel.toUpperCase() < b.wlasciciel.toUpperCase()) {
    return -1;
  }
  if (a.wlasciciel.toUpperCase() > b.wlasciciel.toUpperCase()) {
    return 1;
  }
  return 0;
}

var wyplaszcz = {
  employeeAssetsFlattened: [],

  dodajElement: function(element) {
    if (element.status === "wr") {
      this.employeeAssetsFlattened.push({
        pk: element.pk,
        grupa: element.model.eqtype_description,
        typ: element.model.eqtype,
        nazwa: element.model.name,
        model: element.model.description,
        producent: element.model.vendor,
        numerseryjny: element.serialNumber,
        numerproduktu: element.partNumber,
        datazakupu: element.purchaseDate,
        dataprzekazania: element.assignDate,
        opisdodatkowy: element.description,
        wlasciciel: element.owner.first_name + " " + element.owner.last_name,
        wlascicielDo: element.transferowner,
        scrapageUserComment: element.scrapageUserComment,
        scrapageReadyDate: element.scrapageReadyDate,
        scrapageAcceptDate: element.scrapageAcceptDate,
        daysLefForReturn: getRemainingDays(element.scrapageAcceptDate, 60),
        scrapageChargeValue: element.scrapageChargeValue,
        scrapageChargeStatus: element.scrapageChargeStatus_description,
        scrapageManagerComment: element.scrapageManagerComment,
      });
    } else if (element.status === "sr") {
      this.employeeAssetsFlattened.push({
        pk: element.pk,
        grupa: element.model.eqtype_description,
        typ: element.model.eqtype,
        nazwa: element.model.name,
        model: element.model.description,
        producent: element.model.vendor,
        numerseryjny: element.serialNumber,
        numerproduktu: element.partNumber,
        datazakupu: element.purchaseDate,
        dataprzekazania: element.assignDate,
        opisdodatkowy: element.description,
        wlasciciel: element.owner.first_name + " " + element.owner.last_name,
        wlascicielDo: element.transferowner,
        scrapageUserComment: element.scrapageUserComment,
        scrapageReadyDate: element.scrapageReadyDate,
        scrapageAcceptDate: element.scrapageAcceptDate,
        scrapageChargeValue: element.scrapageChargeValue,
        scrapageChargeStatus: element.scrapageChargeStatus_description,
        scrapageManagerComment: element.scrapageManagerComment,
      });
    }
  },
  zapiszMacierz: function(element) {
    return this.employeeAssetsFlattened;
  },
  inicjalizacja: function(element) {
    this.employeeAssetsFlattened = [];
  },
};

class AssetScrapFilterList extends Component {
  state = {
    selectedIndex: null,
    employeeAssets: [],
    employeeAssetsFlatened: [],
    employeeAssetSelected: null,
    value: 0,
    index: null,
  };

  static propTypes = {
    employeeAssetsByStatusSr: PropTypes.array.isRequired,
    employeeAssetsByStatusWr: PropTypes.array.isRequired,
  };

  componentDidMount() {
    if (
      this.props.activeFilter === "scrapfilter" &&
      this.props.employeeAssetsByStatusSr.length !== 0
    ) {
      wyplaszcz.inicjalizacja();
      this.props.employeeAssetsByStatusSr.forEach(
        wyplaszcz.dodajElement,
        wyplaszcz
      );
      const macierz = wyplaszcz.zapiszMacierz();
      macierz.sort(compare);
      this.setState({
        employeeAssets: this.props.employeeAssetsByStatusSr,
        employeeAssetsFlatened: macierz,
      });
      macierz.sort(compare);
    } else if (
      this.props.activeFilter === "returnfilter" &&
      this.props.employeeAssetsByStatusWr.length !== 0
    ) {
      wyplaszcz.inicjalizacja();
      this.props.employeeAssetsByStatusWr.forEach(
        wyplaszcz.dodajElement,
        wyplaszcz
      );
      const macierz = wyplaszcz.zapiszMacierz();
      macierz.sort(compare);
      this.setState({
        employeeAssets: this.props.employeeAssetsByStatusWr,
        employeeAssetsFlatened: macierz,
      });
      macierz.sort(compare);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.activeFilter === "scrapfilter" &&
      nextProps.employeeAssetsByStatusSr !== this.state.employeeAssets
    ) {
      this.setState({
        selectedIndex: null,
        employeeAssetSelected: null,
      });
      //this.props.checked(false, null, null);

      wyplaszcz.inicjalizacja();
      nextProps.employeeAssetsByStatusSr.forEach(
        wyplaszcz.dodajElement,
        wyplaszcz
      );
      const macierz = wyplaszcz.zapiszMacierz();
      macierz.sort(compare);
      this.setState({
        employeeAssets: nextProps.employeeAssetsByStatusSr,
        employeeAssetsFlatened: macierz,
      });
      macierz.sort(compare);
    } else if (
      nextProps.activeFilter === "returnfilter" &&
      nextProps.employeeAssetsByStatusWr !== this.state.employeeAssets
    ) {
      this.setState({
        selectedIndex: null,
        employeeAssetSelected: null,
      });
      //this.props.checked(false, null, null);

      wyplaszcz.inicjalizacja();
      nextProps.employeeAssetsByStatusWr.forEach(
        wyplaszcz.dodajElement,
        wyplaszcz
      );
      const macierz = wyplaszcz.zapiszMacierz();
      macierz.sort(compare);
      this.setState({
        employeeAssets: nextProps.employeeAssetsByStatusWr,
        employeeAssetsFlatened: macierz,
      });
      macierz.sort(compare);
    }

    if (
      nextProps.activeListType !== "AssetScrapFilterList" &&
      nextProps.activeListType !== null
    ) {
      this.setState({
        selectedIndex: null,
        employeeAssetSelected: null,
      });
    }
  }

  handleListItemClick = (event, index, pk) => {
    const selectedIndex = this.state.selectedIndex;
    if (selectedIndex !== index) {
      this.setState({
        selectedIndex: index,
        employeeAssetSelected: pk,
      });
      this.props.checked(true, pk, "AssetScrapFilterList");
    } else {
      this.setState({
        selectedIndex: null,
        employeeAssetSelected: null,
      });
      this.props.checked(false, null, null);
    }
  };

  handleDisplayAssetDetails = (assetpk) => {};

  render() {
    const { employeeAssetsFlatened, selectedIndex } = this.state;
    const { classes, queryInProgress, activeFilter } = this.props;

    const scrapicon = (
      <ListItemAvatar>
        <Avatar style={{ backgroundColor: "#dc004e" }}>
          <DeleteSweepIcon />
        </Avatar>
      </ListItemAvatar>
    );

    const waiticon = (
      <ListItemAvatar>
        <Avatar style={{ backgroundColor: "#ff9800" }}>
          <PanToolIcon />
        </Avatar>
      </ListItemAvatar>
    );

    var nothingToDisplay = (
      <div className={classes.info}>
        Nie ma żadnych środków trwałych spełniających podane kryteria ...
      </div>
    );

    const waitToDisplay = (
      <div className={classes.info}>Czekaj, pobieram dane z serwera ...</div>
    );

    const circle = (
      <IconButton aria-label="settings">
        <CircularProgress color="secondary" />
      </IconButton>
    );

    const headerOA = (
      <CardHeader
        className={classes.headerOA}
        avatar={
          <Avatar aria-label="recipe" className={classes.avatarOA}>
            OA
          </Avatar>
        }
        action={queryInProgress ? circle : ""}
        title={
          <div className={classes.headertitle}>
            złomowanie środków trwałych oczekujące na twoją akceptację
          </div>
        }
      />
    );

    const headerZM = (
      <CardHeader
        className={classes.headerZM}
        avatar={
          <Avatar aria-label="recipe" className={classes.avatarZM}>
            ZM
          </Avatar>
        }
        action={queryInProgress ? circle : ""}
        title={
          <div className={classes.headertitle}>
            środki trwałe przyjęte do złomowania i oczekujące na zwrot do
            magazynu
          </div>
        }
      />
    );

    return (
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Card style={{ overflow: "auto", marginTop: 0, marginBottom: 0 }}>
          <CardBody style={{ padding: 0 }}>
            {this.props.activeFilter === "scrapfilter" ? headerOA : ""}
            {this.props.activeFilter === "returnfilter" ? headerZM : ""}
            <List component="nav" aria-label="main mailbox folders">
              {employeeAssetsFlatened.length === 0 && !queryInProgress
                ? nothingToDisplay
                : ""}
              {employeeAssetsFlatened.length === 0 && queryInProgress
                ? waitToDisplay
                : ""}
              {employeeAssetsFlatened.map((asset, index) => (
                <ListItem
                  key={asset.typ + asset.pk}
                  alignItems="flex-start"
                  button
                  selected={selectedIndex === index}
                  onClick={(event) =>
                    this.handleListItemClick(event, index, asset.pk)
                  }
                >
                  {this.props.activeFilter === "scrapfilter" ? scrapicon : ""}
                  {this.props.activeFilter === "returnfilter" ? waiticon : ""}
                  <ListItemText
                    primary={asset.nazwa}
                    secondary={
                      <Fragment>
                        <Typography
                          className={classes.listTextSecondary}
                          component="span"
                        >
                          {asset.model || "model nieznany"}
                        </Typography>
                        <Typography
                          className={classes.listTextSecondary}
                          component="span"
                        >
                          {"Właściciel: "}
                          {asset.wlasciciel}
                        </Typography>
                        <Typography
                          className={classes.listTextSecondary}
                          component="span"
                        >
                          {activeFilter === "scrapfilter" && asset.datazakupu
                            ? "Zakupiono: " + asset.datazakupu
                            : ""}
                        </Typography>
                        <Typography
                          className={classes.listTextSecondary}
                          component="span"
                        >
                          {activeFilter === "scrapfilter"
                            ? "Zgłoszono do złomowania: " +
                              asset.scrapageReadyDate
                            : "Zaakceptowano złomowanie: " +
                              asset.scrapageAcceptDate}
                        </Typography>
                        <Typography
                          className={classes.listTextSecondaryComment}
                          component="span"
                        >
                          {activeFilter === "scrapfilter" &&
                          asset.scrapageUserComment
                            ? "Uzasadnienie: " + asset.scrapageUserComment
                            : ""}
                        </Typography>
                        <Typography
                          className={classes.listTextSecondaryComment}
                          component="span"
                        >
                          {asset.scrapageManagerComment
                            ? "Komentarz kierownika: " +
                              asset.scrapageManagerComment
                            : ""}
                        </Typography>
                        <Typography
                          className={classes.listTextSecondaryError}
                          component="span"
                        >
                          {asset.scrapageChargeStatus !== "nie zażądano"
                            ? "Obciążenie: " + asset.scrapageChargeStatus
                            : ""}
                        </Typography>
                        <Typography
                          className={classes.listTextSecondary}
                          component="span"
                        >
                          {asset.scrapageChargeStatus !== "nie zażądano"
                            ? "Wartość obciążenia: " + asset.scrapageChargeValue
                            : ""}
                        </Typography>
                        <Typography
                          className={classes.listTextSecondaryComment}
                          component="span"
                        >
                          {activeFilter === "returnfilter"
                            ? "Pozostało do zwrotu: " +
                              asset.daysLefForReturn +
                              " dni"
                            : ""}
                        </Typography>
                      </Fragment>
                    }
                  />
                </ListItem>
              ))}
            </List>
          </CardBody>
        </Card>
      </Grid>
    );
  }
}

const mapStateToProps = (state) => ({
  employeeAssetsByStatusSr: state.assets.employeeAssetsByStatusSr,
  employeeAssetsByStatusWr: state.assets.employeeAssetsByStatusWr,
});

export default connect(
  mapStateToProps,
  {}
)(withStyles(styles, { withTheme: true })(AssetScrapFilterList));
