import React, { Component, Fragment } from "react";
import CircularProgress from '@material-ui/core/CircularProgress';
import "./Style.css";

export class Loading extends Component {
    render() {
        return (
            <Fragment>
                <div className="Title">
                    <CircularProgress
                        color="secondary" />
                </div>
            </Fragment>
        );
    }
}

export default Loading;