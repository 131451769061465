import React, { Component, Fragment } from "react";
import { lighten } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import Grid from "@material-ui/core/Grid";
import Card from "../../components/Card/Card.js";
import CardBody from "../../components/Card/CardBody.js";
import InsertPhotoIcon from "@material-ui/icons/InsertPhoto";
import CardHeader from "@material-ui/core/CardHeader";
import CircularProgress from "@material-ui/core/CircularProgress";
import PropTypes from "prop-types";
import { getMyAssetsDetails } from "../../Redux/actions/assets";
import ComputerIcon from "@material-ui/icons/Computer";
import BuildIcon from "@material-ui/icons/Build";
import PowerIcon from "@material-ui/icons/Power";
import DevicesOtherIcon from "@material-ui/icons/DevicesOther";
//import MinimizeIcon from "@material-ui/icons/Minimize";
//import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
import Typography from "@material-ui/core/Typography";

//integracja z reduxem
import { connect } from "react-redux";
import IconButton from "@material-ui/core/IconButton";
import GetAppIcon from "@material-ui/icons/GetApp";
import { withStyles } from "@material-ui/core/styles";
//import { skipPartiallyEmittedExpressions } from "typescript";

const styles = (theme) => ({
  avatar: {
    backgroundColor: "#0071bc",
  },
  headertitle: {
    fontFamily: "Lato, Open Sans, sans-serif",
    fontSize: "1.2em",
    fontWeight: "700",
    color: "#444444",
  },
  header: {
    textAlign: "left",
    backgroundColor: lighten("#338dc9", 0.85),
  },
  info: {
    fontFamily: "Lato, Open Sans, sans-serif",
    fontSize: "1.5em",
    fontWeight: "300",
    color: "#0071BC",
  },
  listTextSecondary: {
    textAlign: "left",
    color: "#444444",
    fontFamily: "Lato, Open Sans, sans-serif",
    fontSize: "1em",
    fontWeight: "300",
    display: "block",
  },
  listTextSecondaryInfo: {
    textAlign: "left",
    color: "#2196f3",
    fontFamily: "Lato, Open Sans, sans-serif",
    fontSize: "1em",
    fontWeight: "300",
    display: "block",
  },
});

//sortowanie assetsów z listy
function compare(a, b) {
  if (a.nazwa.toUpperCase() < b.nazwa.toUpperCase()) {
    return -1;
  }
  if (a.nazwa.toUpperCase() > b.nazwa.toUpperCase()) {
    return 1;
  }
  return 0;
}

var wyplaszcz = {
  myAssetsFlattened: [],

  dodajElement: function (element) {
    this.myAssetsFlattened.push({
      pk: element.pk,
      status: element.status,
      grupa: element.model.eqtype_description,
      typ: element.model.eqtype,
      nazwa: element.model.name,
      model: element.model.description,
      producent: element.model.vendor,
      numerseryjny: element.serialNumber,
      numerproduktu: element.partNumber,
      datazakupu: element.purchaseDate,
      dataprzekazania: element.assignDate,
      opisdodatkowy: element.description,
      wlasciciel: element.owner.first_name + " " + element.owner.last_name,
      wlascicielDo: element.transferowner,
      status_description: element.status_description,
    });
  },
  zapiszMacierz: function (element) {
    return this.myAssetsFlattened;
  },
  inicjalizacja: function (element) {
    this.myAssetsFlattened = [];
  },
};

class MyAssetList extends Component {
  state = {
    selectedIndex: null,
    myAssets: [],
    myAssetsFlatened: [],
    myAssetSelected: null,
    value: 0,
    index: null,
  };

  static propTypes = {
    auth: PropTypes.object.isRequired,
    myAssetsDetails: PropTypes.object.isRequired,
    getMyAssetsDetails: PropTypes.func.isRequired,
    queryInProgressAl: PropTypes.bool.isRequired,
    queryInProgressNa: PropTypes.bool.isRequired,
    queryInProgressEl: PropTypes.bool.isRequired,
    queryInProgressIt: PropTypes.bool.isRequired,
    queryInProgressPo: PropTypes.bool.isRequired,
  };

  componentWillReceiveProps(nextProps) {
    // You don't have to do this check first, but it can help prevent an unneeded render
    if (
      nextProps.assets !== this.state.myAssets &&
      nextProps.assets !== undefined
    ) {
      //tu trzeba zrobić wypłaszczenie żeby móc przygotować strukturę do sortowania
      wyplaszcz.inicjalizacja();
      nextProps.assets.forEach(wyplaszcz.dodajElement, wyplaszcz);
      const macierz = wyplaszcz.zapiszMacierz();
      macierz.sort(compare);
      this.setState({
        myAssets: nextProps.assets,
        myAssetsFlatened: macierz,
      });

      macierz.sort(compare);
    }

    //czy zaznaczono nowy radiobutton
    if (nextProps.eqTypeSelected !== this.props.eqTypeSelected) {
      this.setState({
        selectedIndex: null,
        myAssetSelected: null,
      });
    }

    if (
      nextProps.activeListType !== "MyAssetList" &&
      nextProps.activeListType !== null
    ) {
      this.setState({
        selectedIndex: null,
        myAssetSelected: null,
      });
    }
  }

  handleListItemClick = (event, index, pk) => {
    const selectedIndex = this.state.selectedIndex;
    if (selectedIndex !== index) {
      this.setState({
        selectedIndex: index,
        myAssetSelected: pk,
      });
      this.props.checked(true, pk, "MyAssetList");
    } else {
      this.setState({
        selectedIndex: null,
        myAssetSelected: null,
      });
      this.props.checked(false, null, null);
    }
  };

  handleDisplayAssetDetails = (assetpk) => { };

  triggerDownload = () => {
    this.props.downloadTrigger(this.props.eqTypeSelected);
  };

  render() {
    const { selectedIndex, myAssetsFlatened } = this.state;
    const {
      classes,
      queryInProgressAl,
      queryInProgressNa,
      queryInProgressEl,
      queryInProgressIt,
      queryInProgressPo,
      eqTypeSelected,
    } = this.props;

    var loadInProgress = false;
    var headerTitle = "";
    var icon = "";
    var avatarLetters = "";

    const nothingToDisplay = (
      <div className={classes.info}>
        Nie masz żadnych środków trwałych w tej kategorii ...
      </div>
    );

    const waitToDisplay = (
      <div className={classes.info}>Czekaj, pobieram dane z serwera ...</div>
    );

    if (eqTypeSelected === "al") {
      loadInProgress = queryInProgressAl;
      headerTitle = "środki trwałe: BHP";
      icon = (
        <Avatar style={{ backgroundColor: "#0071bc" }}>
          <InsertPhotoIcon />
        </Avatar>
      );
      avatarLetters = "BP";
    } else if (eqTypeSelected === "na") {
      loadInProgress = queryInProgressNa;
      headerTitle = "środki trwałe: Narzędzia";
      icon = (
        <Avatar style={{ backgroundColor: "#0071bc" }}>
          <BuildIcon />
        </Avatar>
      );
      avatarLetters = "NA";
    } else if (eqTypeSelected === "el") {
      loadInProgress = queryInProgressEl;
      headerTitle = "środki trwałe: Elektro-narzędzia";
      icon = (
        <Avatar style={{ backgroundColor: "#0071bc" }}>
          <PowerIcon />
        </Avatar>
      );
      avatarLetters = "EL";
    } else if (eqTypeSelected === "it") {
      loadInProgress = queryInProgressIt;
      headerTitle = "środki trwałe: IT";
      icon = (
        <Avatar style={{ backgroundColor: "#0071bc" }}>
          <ComputerIcon />
        </Avatar>
      );
      avatarLetters = "IT";
    } else if (eqTypeSelected === "po") {
      loadInProgress = queryInProgressPo;
      headerTitle = "środki trwałe: Pozostałe";
      icon = (
        <Avatar style={{ backgroundColor: "#0071bc" }}>
          <DevicesOtherIcon />
        </Avatar>
      );
      avatarLetters = "PO";
    }

    const circle = (
      <IconButton aria-label="settings">
        <CircularProgress color="secondary" />
      </IconButton>
    );

    const download = (
      <IconButton aria-label="settings" onClick={this.triggerDownload}>
        <GetAppIcon />
      </IconButton>
    );

    return (
      <Fragment>
        <Grid container justify="center">
          <Grid item xs={12} sm={12} md={12}>
            <Card style={{ overflow: "auto" }}>
              <CardBody style={{ padding: 0 }}>
                <CardHeader
                  className={classes.header}
                  avatar={
                    <Avatar aria-label="recipe" className={classes.avatar}>
                      {avatarLetters}
                    </Avatar>
                  }
                  action={loadInProgress ? circle : download}
                  title={
                    <div className={classes.headertitle}>{headerTitle}</div>
                  }
                />
                <List component="nav" aria-label="main mailbox folders">
                  {myAssetsFlatened.length === 0 && !loadInProgress
                    ? nothingToDisplay
                    : ""}
                  {myAssetsFlatened.length === 0 && loadInProgress
                    ? waitToDisplay
                    : ""}
                  {myAssetsFlatened.map((asset, index) => (
                    <ListItem
                      key={asset.typ + asset.pk}
                      button
                      selected={selectedIndex === index}
                      onClick={(event) =>
                        this.handleListItemClick(event, index, asset.pk)
                      }
                    >
                      <ListItemAvatar>{icon}</ListItemAvatar>
                      <ListItemText
                        primary={asset.nazwa}
                        secondary={
                          <Fragment>
                            <Typography
                              className={classes.listTextSecondary}
                              component="span"
                            >
                              {asset.model || "model nieznany"}
                            </Typography>
                            <Typography
                              className={classes.listTextSecondaryInfo}
                              component="span"
                            >
                              {asset.status_description !== "w użyciu"
                                ? "Status: " + asset.status_description
                                : ""}
                            </Typography>
                          </Fragment>
                        }
                      />
                    </ListItem>
                  ))}
                </List>
              </CardBody>
            </Card>
          </Grid>
        </Grid>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  myAssetsDetails: state.assets.myAssetsDetails,
  queryInProgressAl: state.assets.queryInProgressAl,
  queryInProgressNa: state.assets.queryInProgressNa,
  queryInProgressEl: state.assets.queryInProgressEl,
  queryInProgressIt: state.assets.queryInProgressIt,
  queryInProgressPo: state.assets.queryInProgressPo,
});

export default connect(
  mapStateToProps,
  { getMyAssetsDetails }
)(withStyles(styles, { withTheme: true })(MyAssetList));
