import axios from "axios";
import { createMessages, returnErrors } from "./messages";
import { tokenConfig } from "./auth";
import { serverurl, getDatabaseUrl } from "./databaseServerURL";

import {
  GET_EVENTS,
  GET_EVENT_TYPES,
  GET_EVENT_RESOURCES,
  DELETE_EVENT,
  ADD_EVENT,
  UPDATE_EVENT,
  CLEAR_EVENTS,
  CLEAR_EVENT_TYPES,
  CLEAR_EVENT_RESOURCES,
} from "./types";

//GET
export const getEvents = () => (dispatch, getState) => {
  axios
    .get(serverurl + "/event", tokenConfig(getState))
    .then(res => {
      dispatch({
        type: GET_EVENTS,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch(returnErrors(err.response.data.detail, err.response.status))
    );
};

export const getEventsOwner = ownerId => (dispatch, getState) => {
  axios
    .get(serverurl + `/event?ownerId=${ownerId}`, tokenConfig(getState))
    .then(res => {
      dispatch({
        type: GET_EVENTS,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch(returnErrors(err.response.data.detail, err.response.status))
    );
};

export const clearEvents = () => (dispatch, getState) => {
  dispatch({ type: CLEAR_EVENTS });
};

export const getEventTypes = () => (dispatch, getState) => {
  axios
    .get(serverurl + "/eventType", tokenConfig(getState))
    .then(res => {
      dispatch({
        type: GET_EVENT_TYPES,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch(returnErrors(err.response.data.detail, err.response.status))
    );
};

export const clearEventTypes = () => (dispatch, getState) => {
  dispatch({ type: CLEAR_EVENT_TYPES });
};

export const getEventResources = () => (dispatch, getState) => {
  axios
    .get(serverurl + "/eventResource", tokenConfig(getState))
    .then(res => {
      dispatch({
        type: GET_EVENT_RESOURCES,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch(returnErrors(err.response.data.detail, err.response.status))
    );
};

export const clearEventResources = () => (dispatch, getState) => {
  dispatch({ type: CLEAR_EVENT_RESOURCES });
};

//DELETE
export const deleteEvent = id => (dispatch, getState) => {
  axios
    .delete(serverurl + `/event/${id}`, tokenConfig(getState))
    .then(res => {
      dispatch(createMessages({ deleteProject: "Wpis został usunięty" }));
      dispatch({
        type: DELETE_EVENT,
        payload: id
      });
    })
    .catch(err =>
      dispatch(returnErrors(err.response.data.detail, err.response.status))
    );
};

//ADD
export const addEvent = body => (dispatch, getState) => {
  axios
    .post(serverurl + "/event", body, tokenConfig(getState))
    .then(res => {
      dispatch(createMessages({ addProject: "Wpis został dodany" }));
      dispatch({
        type: ADD_EVENT,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch(returnErrors(err.response.data.detail, err.response.status))
    );
};

//UPDATE
export const updateEvent = (body, pk) => (dispatch, getState) => {
  axios
    .put(serverurl + `/event/${pk}`, body, tokenConfig(getState))
    .then(res => {
      dispatch(createMessages({ patchProject: "Wpis został zapisany" }));
      dispatch({
        type: UPDATE_EVENT,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch(returnErrors(err.response.data.detail, err.response.status))
    );
};
