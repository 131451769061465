import axios from "axios";
import { returnErrors } from "./messages";
import { tokenConfig } from "./auth";
import { serverurl, getDatabaseUrl } from "./databaseServerURL";

import { GET_MANAGER_DETAILS } from "./types";

//GET MANAGER DETAILS
export const getManagerDetails = pk => (dispatch, getState) => {
  axios
    .get(serverurl + `/managers/${pk}`, tokenConfig(getState))
    .then(res => {
      dispatch({
        type: GET_MANAGER_DETAILS,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch(returnErrors(err.response.data.detail, err.response.status))
    );
};
