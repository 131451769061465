import axios from "axios";
import { createMessages, returnErrors } from "./messages";
import { tokenConfig } from "./auth";
import { serverurl, getDatabaseUrl } from "./databaseServerURL";

import {
  GET_TRIPS,
  DELETE_TRIP,
  ADD_TRIP,
  GET_TRIP_DETAILS,
  CLEAR_TRIP_DETAILS,
  PATCH_TRIP,
  UPDATE_TRIP,
  ADD_TRIP_COMMENT_E,
  ADD_TIMESTAMP_E,
  DELETE_TIMESTAMP_E
} from "./types";

//GET TRIPS
export const getTrips = () => (dispatch, getState) => {
  axios
    .get(serverurl + getDatabaseUrl() + "/mytrips/", tokenConfig(getState))
    .then(res => {
      dispatch({
        type: GET_TRIPS,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch(returnErrors(err.response.data.detail, err.response.status))
    );
};

//GET TRIP DETAILS
export const getTripDetails = pk => (dispatch, getState) => {
  axios
    .get(serverurl + getDatabaseUrl() + `/mytrips/${pk}`, tokenConfig(getState))
    .then(res => {
      dispatch({
        type: GET_TRIP_DETAILS,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch(returnErrors(err.response.data.detail, err.response.status))
    );
};

//CLEAR TRIP DETAILS
export const clearTripDetails = () => (dispatch, getState) => {
  dispatch({ type: CLEAR_TRIP_DETAILS });
};

//DELETE TRIP
export const deleteTrip = id => (dispatch, getState) => {
  axios
    .delete(serverurl + getDatabaseUrl() + `/mytrips/${id}`, tokenConfig(getState))
    .then(res => {
      dispatch(createMessages({ deleteTrip: "Delegacja została usunięta" }));
      dispatch({
        type: DELETE_TRIP,
        payload: id
      });
    })
    .catch(err =>
      dispatch(returnErrors(err.response.data.detail, err.response.status))
    );
};

//ADD TRIP
export const addTrip = trip => (dispatch, getState) => {
  axios
    .post(serverurl + getDatabaseUrl() + "/mytrips/", trip, tokenConfig(getState))
    .then(res => {
      dispatch(createMessages({ addTrip: "Delegacja została dodana" }));
      dispatch({
        type: ADD_TRIP,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch(returnErrors(err.response.data.detail, err.response.status))
    );
};

//PATCH TRIP
export const patchTrip = (trip, pk, message) => (dispatch, getState) => {
  axios
    .patch(serverurl + getDatabaseUrl() + `/mytrips/${pk}`, trip, tokenConfig(getState))
    .then(res => {
      dispatch(createMessages({ patchTrip: message }));
      dispatch({
        type: PATCH_TRIP,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch(returnErrors(err.response.data.detail, err.response.status))
    );
};

//UPDATE TRIP
export const updateTrip = (trip, pk) => (dispatch, getState) => {
  axios
    .put(serverurl + getDatabaseUrl() + `/mytrips/${pk}`, trip, tokenConfig(getState))
    .then(res => {
      dispatch(createMessages({ patchTrip: "Delegacja została zapisana" }));
      dispatch({
        type: UPDATE_TRIP,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch(returnErrors(err.response.data.detail, err.response.status))
    );
};

//ADD TRIP COMMENT
export const addTripComment = tripCommentBody => (dispatch, getState) => {
  axios
    .post(serverurl + getDatabaseUrl() + "/comment/", tripCommentBody, tokenConfig(getState))
    .then(res => {
      dispatch(createMessages({ addTripComment: "Komentarz został zapisany" }));
      dispatch({
        type: ADD_TRIP_COMMENT_E,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch(returnErrors(err.response.data.detail, err.response.status))
    );
};

//ADD TIMESTAMP
export const addTimestamp = timestampBody => (dispatch, getState) => {
  axios
    .post(serverurl + getDatabaseUrl() + "/timestamp/", timestampBody, tokenConfig(getState))
    .then(res => {
      dispatch(
        createMessages({ addTimestamp: "Znacznik czasu został dodany" })
      );
      dispatch({
        type: ADD_TIMESTAMP_E,
        payload: res.data,
      });
    })
    .catch(err =>
      dispatch(returnErrors(err.response.data.detail, err.response.status))
    );
};

//DELETE TIMESTAMP E
export const deleteTimestamp = id => (dispatch, getState) => {
  axios
    .delete(serverurl + getDatabaseUrl() + `/timestamp/${id}`, tokenConfig(getState))
    .then(res => {
      dispatch(createMessages({ deleteTimestamp: "Znacznik został usunięty" }));
      dispatch({
        type: DELETE_TIMESTAMP_E,
        payload: id
      });
    })
    .catch(err =>
      dispatch(returnErrors(err.response.data.detail, err.response.status))
    );
};
