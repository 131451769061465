import React, { Component, Fragment } from "react";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import Card from "../../components/Card/Card.js";
import CardBody from "../../components/Card/CardBody.js";
import CardHeader from "@material-ui/core/CardHeader";
import CircularProgress from "@material-ui/core/CircularProgress";
import PropTypes from "prop-types";
import DeleteSweepIcon from "@material-ui/icons/DeleteSweep";
//integracja z reduxem
import { connect } from "react-redux";
import IconButton from "@material-ui/core/IconButton";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { getTransactionsQtyPerDate } from "./prepareTransactionsToDisplay";
import {
  reloadTransactions,
  getTransactionsStat,
} from "../../Redux/actions/accounting";
import { getLastDayOfPrevMonth } from "../../common/commonFunctions";
import { listStyles } from "../../common/radiolineStyles";
import ListSubheader from "@material-ui/core/ListSubheader";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import withdrawIconAA from "../../graphics/withdrawIconAA.svg";
import cashIconW from "../../graphics/cashIconW.svg";
import cashIconD from "../../graphics/cashIconD.svg";
import cashIconA from "../../graphics/cashIconA.svg";
import cashIconAA from "../../graphics/cashIconAA.svg";
import cardIconW from "../../graphics/cardIconW.svg";
import cardIconD from "../../graphics/cardIconD.svg";
import cardIconA from "../../graphics/cardIconA.svg";
import cardIconAA from "../../graphics/cardIconAA.svg";
import transferIconMinusW from "../../graphics/transferIconMinusW.svg";
import transferIconMinusAA from "../../graphics/transferIconMinusAA.svg";
import transferIconPlusW from "../../graphics/transferIconPlusW.svg";
import transferIconPlusAA from "../../graphics/transferIconPlusAA.svg";
import returnIconW from "../../graphics/returnIconW.svg";
import returnIconAA from "../../graphics/returnIconAA.svg";
import WarningIcon from "@material-ui/icons/Warning";

import ownCashIconW from "../../graphics/ownCashIconW.svg";
import ownCashIconD from "../../graphics/ownCashIconD.svg";
import ownCashIconA from "../../graphics/ownCashIconA.svg";
import ownCashIconAA from "../../graphics/ownCashIconAA.svg";

var today = new Date();

var ocak = <img style={{ height: "26px" }} src={ownCashIconW} alt="ocak"></img>;
var ocod = <img style={{ height: "26px" }} src={ownCashIconD} alt="ocod"></img>;
var ocar = <img style={{ height: "26px" }} src={ownCashIconA} alt="ocar"></img>;
var ocro = <img style={{ height: "26px" }} src={ownCashIconAA} alt="ocro"></img>;

var wi = <img style={{ height: "26px" }} src={withdrawIconAA} alt="wi"></img>;
var cpak = <img style={{ height: "26px" }} src={cardIconW} alt="cpak"></img>;
var cpod = <img style={{ height: "26px" }} src={cardIconD} alt="cpod"></img>;
var cpar = <img style={{ height: "26px" }} src={cardIconA} alt="cpar"></img>;
var cpro = <img style={{ height: "26px" }} src={cardIconAA} alt="cpro"></img>;
var chak = <img style={{ height: "26px" }} src={cashIconW} alt="chak"></img>;
var chod = <img style={{ height: "26px" }} src={cashIconD} alt="chod"></img>;
var char = <img style={{ height: "26px" }} src={cashIconA} alt="char"></img>;
var chro = <img style={{ height: "26px" }} src={cashIconAA} alt="chro"></img>;
var pbap = (
  <img style={{ height: "26px" }} src={transferIconPlusW} alt="pbap"></img>
);
var pbpc = (
  <img style={{ height: "26px" }} src={transferIconPlusAA} alt="pbap"></img>
);
var dtap = (
  <img style={{ height: "26px" }} src={transferIconMinusW} alt="dtap"></img>
);
var dtpc = (
  <img style={{ height: "26px" }} src={transferIconMinusAA} alt="dtpc"></img>
);
var rtap = <img style={{ height: "26px" }} src={returnIconW} alt="rtap"></img>;
var rtpc = <img style={{ height: "26px" }} src={returnIconAA} alt="rtpc"></img>;

var warning = <WarningIcon style={{ fontSize: 30 }} color="secondary" />;
var warning_sm = <WarningIcon style={{ fontSize: 13 }} color="secondary" />;

class EmployeeOperationsList extends Component {
  state = {
    selectedIndex: null,
    transactionSelected: null,
    value: 0,
    index: null,
    dates: {},
    transactions: [],
  };

  static propTypes = {
    transactions_eur: PropTypes.array.isRequired,
    transactions_pln: PropTypes.array.isRequired,
    transactions_sek: PropTypes.array.isRequired,
    transactions_nok: PropTypes.array.isRequired,
    queryInProgress_tr_eur: PropTypes.bool.isRequired,
    queryInProgress_tr_pln: PropTypes.bool.isRequired,
    queryInProgress_tr_sek: PropTypes.bool.isRequired,
    queryInProgress_tr_nok: PropTypes.bool.isRequired,
    dataChangeInProgress: PropTypes.bool.isRequired,
    reload_transactions: PropTypes.bool.isRequired,
    reloadTransactions: PropTypes.func.isRequired,
    getTransactionsStat: PropTypes.func.isRequired,
  };

  componentDidMount() {
    if (
      this.props.selectedCurrency === "eur" &&
      this.props.transactions_eur.length !== 0
    ) {
      const dates = getTransactionsQtyPerDate(this.props.transactions_eur);
      this.setState({
        dates: dates,
        transactions: this.props.transactions_eur,
      });
    } else if (
      this.props.selectedCurrency === "pln" &&
      this.props.transactions_pln.length !== 0
    ) {
      const dates = getTransactionsQtyPerDate(this.props.transactions_pln);
      this.setState({
        dates: dates,
        transactions: this.props.transactions_pln,
      });
    } else if (
      this.props.selectedCurrency === "sek" &&
      this.props.transactions_sek.length !== 0
    ) {
      const dates = getTransactionsQtyPerDate(this.props.transactions_sek);
      this.setState({
        dates: dates,
        transactions: this.props.transactions_sek,
      });
    } else if (
      this.props.selectedCurrency === "nok" &&
      this.props.transactions_nok.length !== 0
    ) {
      const dates = getTransactionsQtyPerDate(this.props.transactions_nok);
      this.setState({
        dates: dates,
        transactions: this.props.transactions_nok,
      });
    }
  }

  componentDidUpdate(prevProps) {
    //dodanie nowej transakcji lub zmiana waluty
    if (
      this.props.selectedCurrency === "eur" &&
      (this.props.transactions_eur.length !==
        prevProps.transactions_eur.length ||
        prevProps.selectedCurrency !== "eur")
    ) {
      const dates = getTransactionsQtyPerDate(this.props.transactions_eur);
      this.setState({
        dates: dates,
        transactions: this.props.transactions_eur,
        selectedIndex: null,
        transactionSelected: null,
      });
    } else if (
      this.props.selectedCurrency === "pln" &&
      (this.props.transactions_pln.length !==
        prevProps.transactions_pln.length ||
        prevProps.selectedCurrency !== "pln")
    ) {
      const dates = getTransactionsQtyPerDate(this.props.transactions_pln);
      this.setState({
        dates: dates,
        transactions: this.props.transactions_pln,
        selectedIndex: null,
        transactionSelected: null,
      });
    } else if (
      this.props.selectedCurrency === "sek" &&
      (this.props.transactions_sek.length !==
        prevProps.transactions_sek.length ||
        prevProps.selectedCurrency !== "sek")
    ) {
      const dates = getTransactionsQtyPerDate(this.props.transactions_sek);
      this.setState({
        dates: dates,
        transactions: this.props.transactions_sek,
        selectedIndex: null,
        transactionSelected: null,
      });
    } else if (
      this.props.selectedCurrency === "nok" &&
      (this.props.transactions_nok.length !==
        prevProps.transactions_nok.length ||
        prevProps.selectedCurrency !== "nok")
    ) {
      const dates = getTransactionsQtyPerDate(this.props.transactions_nok);
      this.setState({
        dates: dates,
        transactions: this.props.transactions_nok,
        selectedIndex: null,
        transactionSelected: null,
      });
    }
    //przeładowanie trancakcji po akceptacji lub odrzuceniu istniejącej transakcji przez ksiegowego
    if (
      prevProps.reload_transactions === false &&
      this.props.reload_transactions === true
    ) {
      const toDate = getLastDayOfPrevMonth(today);
      if (this.props.selectedCurrency === "eur") {
        const dates = getTransactionsQtyPerDate(this.props.transactions_eur);
        this.setState({
          dates: dates,
          transactions: this.props.transactions_eur,
          selectedIndex: null,
          transactionSelected: null,
        });
      } else if (this.props.selectedCurrency === "pln") {
        const dates = getTransactionsQtyPerDate(this.props.transactions_pln);
        this.setState({
          dates: dates,
          transactions: this.props.transactions_pln,
          selectedIndex: null,
          transactionSelected: null,
        });
      } else if (this.props.selectedCurrency === "sek") {
        const dates = getTransactionsQtyPerDate(this.props.transactions_sek);
        this.setState({
          dates: dates,
          transactions: this.props.transactions_sek,
          selectedIndex: null,
          transactionSelected: null,
        });
      } else if (this.props.selectedCurrency === "nok") {
        const dates = getTransactionsQtyPerDate(this.props.transactions_nok);
        this.setState({
          dates: dates,
          transactions: this.props.transactions_nok,
          selectedIndex: null,
          transactionSelected: null,
        });
      }
      this.props.reloadTransactions(false);
      this.props.getTransactionsStat("ar", toDate);
    }
    //przeładowanie transakcji po zmianie daty księgowania
    if (
      prevProps.dataChangeInProgress === true &&
      this.props.dataChangeInProgress === false
    ) {
      if (this.props.selectedCurrency === "eur") {
        const dates = getTransactionsQtyPerDate(this.props.transactions_eur);
        this.setState({
          dates: dates,
          transactions: this.props.transactions_eur,
          selectedIndex: null,
          transactionSelected: null,
        });
      } else if (this.props.selectedCurrency === "pln") {
        const dates = getTransactionsQtyPerDate(this.props.transactions_pln);
        this.setState({
          dates: dates,
          transactions: this.props.transactions_pln,
          selectedIndex: null,
          transactionSelected: null,
        });
      } else if (this.props.selectedCurrency === "sek") {
        const dates = getTransactionsQtyPerDate(this.props.transactions_sek);
        this.setState({
          dates: dates,
          transactions: this.props.transactions_sek,
          selectedIndex: null,
          transactionSelected: null,
        });
      } else if (this.props.selectedCurrency === "nok") {
        const dates = getTransactionsQtyPerDate(this.props.transactions_nok);
        this.setState({
          dates: dates,
          transactions: this.props.transactions_nok,
          selectedIndex: null,
          transactionSelected: null,
        });
      }
    }
  }

  handleListItemClick = (event, dateindex, transactionIndex, transactionpk) => {
    const selectedIndex = this.state.selectedIndex;

    if (selectedIndex !== dateindex + "_" + transactionIndex) {
      this.setState({
        selectedIndex: dateindex + "_" + transactionIndex,
        transactionSelected: transactionpk,
      });
      this.props.checked(true, transactionpk);
    } else {
      this.setState({
        selectedIndex: null,
        transactionSelected: null,
      });
      this.props.checked(false, null);
    }
  };

  render() {
    const { selectedIndex, dates, transactions, testy } = this.state;
    const {
      classes,
      unclosed,
      selectedType,
      queryInProgress_tr_eur,
      queryInProgress_tr_pln,
      queryInProgress_tr_sek,
      queryInProgress_tr_nok,
    } = this.props;

    var nothingToDisplay = (
      <div className={classes.infoListFetch}>
        Brak transakcji do wyświetlenia ...
      </div>
    );

    const waitToDisplay = (
      <div className={classes.infoListFetch}>
        Czekaj, pobieram dane z serwera ...
      </div>
    );

    const circle = (
      <IconButton aria-label="settings">
        <CircularProgress color="secondary" />
      </IconButton>
    );

    const headerOP = (
      <CardHeader
        className={classes.headerInfo1}
        avatar={
          <Avatar aria-label="recipe" className={classes.avatarInfo1}>
            HT
          </Avatar>
        }
        action={
          queryInProgress_tr_eur ||
            queryInProgress_tr_pln ||
            queryInProgress_tr_sek ||
            queryInProgress_tr_nok
            ? circle
            : ""
        }
        title={<div className={classes.headertitle}>Historia transakcji</div>}
      />
    );

    var lista = "";
    var allTypes = true;
    if (selectedType != "all" && selectedType != undefined) allTypes = false;

    if (dates.dates !== undefined) {
      lista = (
        <Fragment>
          {dates.dates.map((date, index) => (
            <li
              key={"li-" + date}
              className={classes.listSectionWithSubheaders}
            >
              <ul className={classes.ulWithSubheaders}>
                <ListSubheader className={classes.subheaderText}>
                  {date}
                </ListSubheader>
                {dates.transactionsByDates[index].map(
                  (transaction, transactionIndex) => {
                    if (allTypes || transaction.transactionType == selectedType) {
                      if (!unclosed || (unclosed && (transaction.clearanceOwner == null || transaction.transactionStatus == 'od') && transaction.transactionType != 'wi')) {
                        return (
                          <ListItem
                            button
                            key={transaction.pk}
                            selected={
                              selectedIndex === index + "_" + transactionIndex
                            }
                            onClick={(event) =>
                              this.handleListItemClick(
                                event,
                                index,
                                transactionIndex,
                                transaction.pk
                              )
                            }
                          >
                            <ListItemAvatar>
                              {transaction.transactionType === "wi"
                                ? wi
                                : eval(
                                  transaction.transactionType +
                                  transaction.transactionStatus
                                )}
                              {transaction.invoice == null && transaction.transactionType != "dt" && transaction.transactionType != "pb" && transaction.transactionType != "rt" && transaction.transactionType != "wi" ? warning_sm : ""}
                            </ListItemAvatar>
                            <ListItemText
                              primary={
                                <Fragment>
                                  <div
                                    style={{ display: "flex", alignItems: "center" }}
                                  >
                                    <Typography component="span">
                                      {transaction.transactionDescription}
                                    </Typography>
                                    {transaction.monitDate ? warning : ""}
                                  </div>
                                </Fragment>
                              }
                            />
                            <ListItemSecondaryAction>
                              <Typography>
                                {transaction.value +
                                  " " +
                                  transaction.currency_description}
                              </Typography>
                            </ListItemSecondaryAction>
                          </ListItem>
                        );
                      }
                    }
                  }
                )}
              </ul>
            </li>
          ))}
        </Fragment>
      );
    }

    return (
      <Card style={{ overflow: "auto", marginTop: "0px", marginBottom: "0px" }}>
        <CardBody style={{ padding: 0 }}>
          {headerOP}
          <List className={classes.rootListWithSubheaders} subheader={<li />}>
            {queryInProgress_tr_pln ||
              queryInProgress_tr_eur ||
              queryInProgress_tr_sek ||
              queryInProgress_tr_nok
              ? waitToDisplay
              : ""}
            {!queryInProgress_tr_pln &&
              !queryInProgress_tr_eur &&
              !queryInProgress_tr_sek &&
              !queryInProgress_tr_nok &&
              transactions.length === 0
              ? nothingToDisplay
              : ""}
            {!queryInProgress_tr_pln &&
              !queryInProgress_tr_eur &&
              !queryInProgress_tr_sek &&
              !queryInProgress_tr_nok &&
              transactions.length > 0
              ? lista
              : ""}
          </List>
        </CardBody>
      </Card>
    );
  }
}

const mapStateToProps = (state) => ({
  transactions_eur: state.accounting.transactions_eur,
  transactions_pln: state.accounting.transactions_pln,
  transactions_sek: state.accounting.transactions_sek,
  transactions_nok: state.accounting.transactions_nok,
  queryInProgress_tr_eur: state.accounting.queryInProgress_tr_eur,
  queryInProgress_tr_pln: state.accounting.queryInProgress_tr_pln,
  queryInProgress_tr_sek: state.accounting.queryInProgress_tr_sek,
  queryInProgress_tr_nok: state.accounting.queryInProgress_tr_nok,
  reload_transactions: state.accounting.reload_transactions,
  dataChangeInProgress: state.accounting.dataChangeInProgress,
});

export default connect(
  mapStateToProps,
  { reloadTransactions, getTransactionsStat }
)(withStyles(listStyles, { withTheme: true })(EmployeeOperationsList));
