//prepare purchase summary
export function getTransactionsQtyPerDate(transactions) {
  datesChecker.inicjalisation();

  if (transactions.length > 0) {
    transactions.forEach(datesChecker.findDate, datesChecker);
  }

  const dates = datesChecker.displayDates();
  const quantity = datesChecker.displayQuantity();
  const transactionsByDates = datesChecker.displayTransactionsByDates();

  return {
    dates: dates,
    qty: quantity,
    transactionsByDates: transactionsByDates,
  };
}

var datesChecker = {
  dates: [],
  quantity: [],
  transactionsByDates: [],
  transactionSerie: [],

  findDate: function(element, index, array) {
    const found = this.dates.findIndex(checkDate, element.bookDate);

    if (found === -1) {
      this.dates.push(element.bookDate);
      this.quantity.push(1);
      if (index !== 0) {
        this.transactionsByDates.push(this.transactionSerie);
      }
      this.transactionSerie = [];
      this.transactionSerie.push(element);
      //jeżeli to ostatni element to dodaj transactionSerie do transactionsByDates
      if (index === array.length - 1) {
        this.transactionsByDates.push(this.transactionSerie);
      }
    } else {
      this.quantity[found] = this.quantity[found] + 1;
      this.transactionSerie.push(element);
      if (index === array.length - 1) {
        this.transactionsByDates.push(this.transactionSerie);
      }
    }
  },

  displayDates: function(element) {
    return this.dates;
  },

  displayQuantity: function(element) {
    return this.quantity;
  },

  displayTransactionsByDates: function(element) {
    return this.transactionsByDates;
  },

  inicjalisation: function(element) {
    this.dates = [];
    this.quantity = [];
    this.transactionsByDates = [];
    this.transactionSerie = [];
  },
};

function checkDate(date, index, array) {
  return date === this;
}

export default getTransactionsQtyPerDate;
