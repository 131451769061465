import axios from "axios";
import { createMessages, returnErrors } from "./messages";
import { tokenConfig } from "./auth";
import { serverurl, getDatabaseUrl } from "./databaseServerURL";

import {
  GET_FLEET,
  DELETE_FLEET,
  ADD_FLEET,
  UPDATE_FLEET,
  FLEET_QUERY_FAILED,
  FLEET_QUERY_IN_PROGRESS,
  GET_FLEET_DETAILS,
  GET_FLEET_DETAILS2,
  FLEET_DETAILS_QUERY_FAILED,
  FLEET_DETAILS_QUERY_IN_PROGRESS,
  CLEAR_FLEET_DETAILS,
  GET_INSPECTIONS,
  INSPECTIONS_QUERY_IN_PROGRESS,
  INSPECTION_DETAILS_QUERY_IN_PROGRESS,
  GET_INSPECTION_DETAILS,
  CLEAR_INSPECTION_DETAILS,
} from "./types";

//GET FLEET
export const getFleet = () => (dispatch, getState) => {
  dispatch({ type: FLEET_QUERY_IN_PROGRESS });
  axios
    .get(serverurl + "/fleet", tokenConfig(getState))
    .then(res => {
      dispatch({
        type: GET_FLEET,
        payload: res.data
      });
    })
    .catch(err => {
      dispatch(returnErrors(err.response.data.detail, err.response.status));
      dispatch({ type: FLEET_QUERY_FAILED });
    });
};

//GET FLEET DETAILS
export const getFleetDetails = id => (dispatch, getState) => {
  dispatch({ type: FLEET_DETAILS_QUERY_IN_PROGRESS });
  axios
    .get(serverurl + `/fleet/${id}`, tokenConfig(getState))
    .then(res => {
      dispatch({
        type: GET_FLEET_DETAILS,
        payload: res.data
      });
    })
    .catch(err => {
      dispatch(returnErrors(err.response.data.detail, err.response.status));
      dispatch({ type: FLEET_DETAILS_QUERY_FAILED });
    });
};

export const getFleetDetails2 = id => (dispatch, getState) => {
  dispatch({ type: FLEET_DETAILS_QUERY_IN_PROGRESS });
  axios
    .get(serverurl + `/cars_employees?car=${id}`, tokenConfig(getState))
    .then(res => {
      dispatch({
        type: GET_FLEET_DETAILS2,
        payload: res.data
      });
    })
    .catch(err => {
      dispatch(returnErrors(err.response.data.detail, err.response.status));
      dispatch({ type: FLEET_DETAILS_QUERY_FAILED });
    });
};

//CLEAR FLEET DETAILS
export const clearFleetDetails = () => (dispatch, getState) => {
  dispatch({ type: CLEAR_FLEET_DETAILS });
};

//DELETE FLEET
export const deleteFleet = id => (dispatch, getState) => {
  axios
    .delete(serverurl + `/fleet/${id}`, tokenConfig(getState))
    .then(res => {
      dispatch(createMessages({ deleteFleet: "Samochód został usunięty" }));
      dispatch({
        type: DELETE_FLEET,
        payload: id
      });
    })
    .catch(err =>
      dispatch(returnErrors(err.response.data.detail, err.response.status))
    );
};

//ADD FLEET
export const addFleet = trip => (dispatch, getState) => {
  axios
    .post(serverurl + "/fleet", trip, tokenConfig(getState))
    .then(res => {
      dispatch(createMessages({ addFleet: "Samochód został dodany" }));
      dispatch({
        type: ADD_FLEET,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch(returnErrors(err.response.data.detail, err.response.status))
    );
};

//UPDATE FLEET
export const updateFleet = (trip, pk) => (dispatch, getState) => {
  axios
    .put(serverurl + `/fleet/${pk}`, trip, tokenConfig(getState))
    .then(res => {
      dispatch(createMessages({ patchFleet: "Samochód został zapisany" }));
      dispatch({
        type: UPDATE_FLEET,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch(returnErrors(err.response.data.detail, err.response.status))
    );
};

//GET Inspections
export const getInspections = () => (dispatch, getState) => {
  dispatch({ type: INSPECTIONS_QUERY_IN_PROGRESS });
  axios.get(serverurl + "/inspections", tokenConfig(getState))
    .then(res => {
      dispatch({
        type: GET_INSPECTIONS,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch(returnErrors(err.response.data.detail, err.response.status))
    );
};
//GET InspectionDetails
export const getInspectionDetils = id => (dispatch, getState) => {
  dispatch({ type: INSPECTION_DETAILS_QUERY_IN_PROGRESS });
  axios.get(serverurl + `/inspections/${id}`, tokenConfig(getState))
    .then(res => {
      dispatch({
        type: GET_INSPECTION_DETAILS,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch(returnErrors(err.response.data.detail, err.response.status))
    );
};

//CLEAR InspectionDetails
export const clearInspectionDetails = () => (dispatch, getState) => {
  dispatch({ type: CLEAR_INSPECTION_DETAILS });
};