import React, { Fragment } from "react";
import "../Style.css";
import { Link } from "react-router-dom";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
//import DelImg from "../graphics/delegacje.png";
import DelImg from "../graphics/NewMain/trips.svg";
//import HolImg from "../graphics/urlopy.png";
import HolImg from "../graphics/NewMain/calendar.svg";
//import AssImg from "../graphics/srodkitrwale.png";
import AssImg from "../graphics/NewMain/assets.svg";
//import EmpImg from "../graphics/pracownik.png";
import EmpImg from "../graphics/NewMain/employee.svg";
import PurImg from "../graphics/zakupy.png";
//import FleetImg from "../graphics/fleet-01.png";
import FleetImg from "../graphics/NewMain/cars.svg";
//import DocumentsImg from "../graphics/vectorstock_document.png";
import DocumentsImg from "../graphics/NewMain/document.svg";
//import AccImg from "../graphics/accounting-01.png";
import AccImg from "../graphics/NewMain/money.svg";
import ZFSSImg from "../graphics/NewMain/zfss.svg";
import Box from "@material-ui/core/Box";
import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import Container from "@material-ui/core/Container";
import store from "../store.js";

const useStyles = makeStyles({
  boxroot: {
    marginTop: "20px",
  },
  boxcontainer: {
    marginLeft: "3%",
    marginRight: "3%",
  },
  card: {
    width: 360,
    marginLeft: 15,
    marginRight: 15,
    marginTop: 25,
    marginBottom: 25,
    borderRadius: 12,
  },
  media: {
    //height: 140,     // as an example I am modifying width and height
    width: "50%",
    marginLeft: "25%",
  },
});

const materialTheme = createMuiTheme({
  palette: {
    primary: {
      main: "#0071BC",
    },
  },
});

export default function MainPage() {
  //Parametryzacja wstępna modułów Apek (nadanie im odpowiednich nazw i kolorów)
  const classes = useStyles();

  const storeState = store.getState().auth;
  const isSuperUser = storeState.user != null ? storeState.user.is_superuser : false;
  const permissions = storeState.permissions;
  const defaultCompany = storeState.main_profile.defaultCompany;

  const scheduler = (
    <ThemeProvider theme={materialTheme}>
      <Card className={classes.card}>
        <Link key="Scheduler" to="/scheduler">
          <CardActionArea>
            <CardMedia
              className={classes.media}
              component="img"
              alt="Harmonogram"
              height="140"
              image={HolImg}
              title="Harmonogram"
            ></CardMedia>
            <CardContent>
              <Typography gutterBottom variant="h5" component="h2">
                Harmonogram
              </Typography>
              <Typography
                variant="body2"
                color="textSecondary"
                component="p"
              >
                Harmonogram salki, kalendarz, wystawianie urlopów i delegacji ...
              </Typography>
            </CardContent>
          </CardActionArea>
        </Link>
      </Card>
    </ThemeProvider>
  );

  const zfss = (
    <ThemeProvider theme={materialTheme}>
      <Card className={classes.card}>
        <Link key="ZFSS" to="/ZFSS">
          <CardActionArea>
            <CardMedia
              className={classes.media}
              component="img"
              alt="ZFŚS"
              height="140"
              image={ZFSSImg}
              title="ZFŚS"
            ></CardMedia>
            <CardContent>
              <Typography gutterBottom variant="h5" component="h2">
                ZFŚS
              </Typography>
              <Typography
                variant="body2"
                color="textSecondary"
                component="p"
              >
                Zakładowy Fundusz Świadczeń Socjalnych Multisport, Paczki, Bony itp. ...
              </Typography>
            </CardContent>
          </CardActionArea>
        </Link>
      </Card>
    </ThemeProvider>
  );

  return (
    <Fragment>
      <Container className={classes.boxroot} maxWidth="xl">
        <Box
          className={classes.boxcontainer}
          display="flex"
          flexDirection="row"
          flexWrap="wrap"
          justifyContent="center"
          alignItems="center"
          p={0}
          m={0}
        >
          <ThemeProvider theme={materialTheme}>
            <Card className={classes.card}>
              <Link key="Delegacje" to="/trips">
                <CardActionArea>
                  <CardMedia
                    className={classes.media}
                    component="img"
                    alt="Delegacje"
                    height="140"
                    image={DelImg}
                    title="Delegacje"
                  ></CardMedia>
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="h2">
                      Delegacje
                    </Typography>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      component="p"
                    >
                      Wystaw, rozliczaj i zarządzaj delegacjami służbowymi ...
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Link>
            </Card>
          </ThemeProvider>
          {/*<ThemeProvider theme={materialTheme}>
            <Card className={classes.card}>
              <Link key="Urlopy" to="/holidays" onClick={(event) => event.preventDefault()}> Deaktywowany
                <CardActionArea>
                  <CardMedia
                    className={classes.media}
                    component="img"
                    alt="Urlopy"
                    height="140"
                    image={HolImg}
                    title="Urlopy"
                  ></CardMedia>
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="h2">
                      Urlopy
                    </Typography>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      component="p"
                    >
                      Wystaw wniosek urlopowy i zarządzaj planami urlopowymi
                      swoich pracowników ...
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Link>
            </Card>
          </ThemeProvider>*/}
          <ThemeProvider theme={materialTheme}>
            <Card className={classes.card}>
              <Link key="Fixed-assets" to="/assets">
                <CardActionArea>
                  <CardMedia
                    className={classes.media}
                    component="img"
                    alt="Środki trwałe"
                    height="140"
                    image={AssImg}
                    title="Środki trwałe"
                  ></CardMedia>
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="h2">
                      Środki trwałe
                    </Typography>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      component="p"
                    >
                      Firmowe środki trwałe oraz zarządzanie przypisaniem ...
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Link>
            </Card>
          </ThemeProvider>

          <ThemeProvider theme={materialTheme}>
            <Card className={classes.card}>
              <Link key="Accounting" to="/accounting">
                <CardActionArea>
                  <CardMedia
                    className={classes.media}
                    component="img"
                    alt="Finanse"
                    height="140"
                    image={AccImg}
                    title="Finanse"
                  ></CardMedia>
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="h2">
                      Finanse
                    </Typography>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      component="p"
                    >
                      Rozliczenia z pracownikiem. Wypłaty gotówkowe,
                      rozliczenia, transakcje kartą itp. ...
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Link>
            </Card>
          </ThemeProvider>

          <ThemeProvider theme={materialTheme}>
            <Card className={classes.card}>
              <Link key="Fleet" to="/fleet">
                <CardActionArea>
                  <CardMedia
                    className={classes.media}
                    component="img"
                    alt="Samochody służbowe"
                    height="140"
                    image={FleetImg}
                    title="Samochody służbowe"
                  ></CardMedia>
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="h2">
                      Samochody służbowe
                    </Typography>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      component="p"
                    >
                      Przeglądy, naprawy, eksploatacja, przebieg samochodu, itp.
                      ...
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Link>
            </Card>
          </ThemeProvider>

          <ThemeProvider theme={materialTheme}>
            <Card className={classes.card}>
              <Link key="Pracownik" to="/employee">
                <CardActionArea>
                  <CardMedia
                    className={classes.media}
                    component="img"
                    alt="Pracownik"
                    height="140"
                    image={EmpImg}
                    title="Pracownik"
                  ></CardMedia>
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="h2">
                      Pracownik
                    </Typography>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      component="p"
                    >
                      Twoje dane osobowe oraz dane o twoich pracownikach ...
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Link>
            </Card>
          </ThemeProvider>

          <ThemeProvider theme={materialTheme}>
            <Card className={classes.card}>
              <Link key="Dokumenty" to="/documents">
                <CardActionArea>
                  <CardMedia
                    className={classes.media}
                    component="img"
                    alt="Dokumenty"
                    height="140"
                    image={DocumentsImg}
                    title="Dokumenty"
                  ></CardMedia>
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="h2">
                      Dokumenty
                    </Typography>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      component="p"
                    >
                      Regulaminy, wnioski, zarządzania oraz inne dokumenty firmowe ...
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Link>
            </Card>
          </ThemeProvider>

          {permissions != null && permissions.module_scheduler || isSuperUser ? scheduler : ""}

          {(permissions != null && permissions.module_zfss || isSuperUser) && defaultCompany == 1 ? zfss : ""}

          <ThemeProvider theme={materialTheme}>
            <Card className={classes.card}>
              {/*<Link key="Remote" to="/remote">
                <CardActionArea>
                  <CardMedia
                    className={classes.media}
                    component="img"
                    alt="Rejestracja czasu pracy"
                    height="140"
                    image={RemImg}
                    title="Rejestracja czasu pracy"
                  ></CardMedia>
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="h2">
                      Praca zdalna
                    </Typography>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      component="p"
                    >
                      Ewidencja i kontrola czasu pracy podczas pracy zdalnej ...
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Link>*/}</Card>
          </ThemeProvider>
          <ThemeProvider theme={materialTheme}>
            <Card className={classes.card}></Card>
          </ThemeProvider>
        </Box>
      </Container>
    </Fragment>
  );
}
