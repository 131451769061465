import React, { Component, Fragment } from "react";
import Grid from "@material-ui/core/Grid";
import Card from "../../components/Card/Card.js";
import CardBody from "../../components/Card/CardBody.js";
import Avatar from "@material-ui/core/Avatar";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Typography from "@material-ui/core/Typography";
import CardHeader from "@material-ui/core/CardHeader";
import { listStyles } from "../../common/radiolineStyles";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import IconButton from "@material-ui/core/IconButton";
import CircularProgress from "@material-ui/core/CircularProgress";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import visa from "../../graphics/visa.svg";
import mastercard from "../../graphics/mastercard.svg";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";

const PurpleSwitch = withStyles({
  switchBase: {
    color: "#0071bc",
    "&$checked": {
      color: "#0071bc",
    },
    "&$checked + $track": {
      backgroundColor: "#0071bc",
    },
  },
  checked: {},
  track: {},
})(Switch);

var visalogo = <img style={{ height: "26px" }} src={visa} alt="visa"></img>;
var mastercardlogo = (
  <img style={{ height: "26px" }} src={mastercard} alt="visa"></img>
);

export class CardsList extends Component {
  state = {
    selectedIndex: null,
    cardSelected: null,
    isValid: true,
  };

  static propTypes = {
    queryInProgress_cards: PropTypes.bool.isRequired,
    cards: PropTypes.array.isRequired,
  };

  /*   componentDidUpdate(prevProps) {
    if (
      prevProps.isTransactionChecked === true &&
      this.props.isTransactionChecked === false &&
      this.state.selectedIndex !== null
    ) {
      this.setState({
        selectedIndex: null,
        transactionSelected: null,
      });
    }
  } */

  handleSwitchChange = (event) => {
    this.setState({
      isValid: !this.state.isValid,
    });
  };

  handleListItemClick = (event, index, pk) => {
    const selectedIndex = this.state.selectedIndex;
    if (selectedIndex !== index) {
      this.setState({
        selectedIndex: index,
        cardSelected: pk,
      });
      this.props.checked(true, pk);
    } else {
      this.setState({
        selectedIndex: null,
        cardSelected: null,
      });
      this.props.checked(false, null);
    }
  };

  render() {
    const { classes, queryInProgress_cards, cards } = this.props;

    const { selectedIndex, isValid } = this.state;

    const circle = (
      <IconButton aria-label="settings">
        <CircularProgress color="secondary" />
      </IconButton>
    );

    var nothingToDisplay = (
      <div className={classes.infoListFetch}>Brak zdefiniowanych kart ...</div>
    );

    const waitToDisplay = (
      <div className={classes.infoListFetch}>
        Czekaj, pobieram dane z serwera ...
      </div>
    );

    var lista = "";

    if (cards.length > 0) {
      lista = (
        <Fragment>
          {cards.filter(card => card.isValid === isValid).map((card, index) => ( //cards.map((card, index) => (
            <ListItem
              key={card.pk}
              button
              selected={selectedIndex === index}
              onClick={(event) =>
                this.handleListItemClick(event, index, card.pk)
              }
            >
              <ListItemAvatar>
                {card.cardNumber.charAt(0) === "4" ? visalogo : mastercardlogo}
              </ListItemAvatar>
              <ListItemText
                primary={card.cardNumber}
                secondary={
                  <Fragment>
                    <Typography
                      className={classes.listTextSecondaryInline}
                      component="span"
                    >
                      {"ważna do: " + card.validDate}
                    </Typography>
                  </Fragment>
                }
              />
              <ListItemSecondaryAction>
                <Typography>
                  {card.owner.last_name + " " + card.owner.first_name}
                </Typography>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </Fragment>
      );
    }

    return (
      <Grid container justify="center">
        <Grid item xs={12} sm={12} md={12}>
          <Card
            style={{
              marginTop: 0,
              marginBottom: 0,
              minHeight: "50vh",
              maxHeight: "80vh",
            }}
          >
            <CardHeader
              className={classes.headerInfo1}
              avatar={
                <Avatar aria-label="recipe" className={classes.avatarInfo1}>
                  ZK
                </Avatar>
              }
              action={
                <div style={{ paddingTop: "8px", paddingRight: "8px", height: "50px", display: "flex" }}>
                  {queryInProgress_cards ? circle :
                    <FormControlLabel
                      label={isValid ? "Aktywne" : "Nieaktywne"}
                      labelPlacement="start"
                      control={
                        <PurpleSwitch
                          checked={!isValid}
                          onChange={this.handleSwitchChange}
                          name="isValid"
                        />
                      }
                    />
                  }
                </div>
              }
              title={
                <div className={classes.headertitle}>Zarządzaj kartami</div>
              }
            />
            <CardBody
              style={{
                padding: 0,
                //height: "calc(100vh - 220px)",
                overflow: "auto",
              }}
            >
              <List component="nav" aria-label="main mailbox folders">
                {queryInProgress_cards ? waitToDisplay : ""}
                {!queryInProgress_cards && cards.length > 0 ? lista : ""}
                {!queryInProgress_cards && cards.length === 0
                  ? nothingToDisplay
                  : ""}
              </List>
            </CardBody>
          </Card>
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = (state) => ({
  queryInProgress_cards: state.accounting.queryInProgress_cards,
  cards: state.accounting.cards,
});

export default connect(
  mapStateToProps,
  {}
)(withStyles(listStyles, { withTheme: true })(CardsList));
