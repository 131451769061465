import {
  GET_PROJECTS,
  GET_ACTIVE_PROJECTS,
  DELETE_PROJECT,
  ADD_PROJECT,
  UPDATE_PROJECT,
  CLEAR_PROJECTS,
  CLEAR_ACTIVE_PROJECTS,
} from "../actions/types";

const initialState = {
  projects: [],
  active: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_PROJECTS:
      return {
        ...state,
        projects: action.payload,
      };
    case GET_ACTIVE_PROJECTS:
      return {
        ...state,
        active: action.payload,
      };
    case CLEAR_PROJECTS:
      return {
        ...state,
        projects: [],
      }
    case CLEAR_ACTIVE_PROJECTS:
      return {
        ...state,
        active: [],
      }
    default:
      return state;
  }
}
