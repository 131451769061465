import React, { Component } from "react";
import "./App.css";
import MainPage from "./MainPage/MainPage";
import Navbar from "./MainPage/NavBar";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import TripsViewer from "./Trips/TripsViewer";
import TripsManagerViewer from "./Trips-manager/TripsManagerViewer";
import TripsManagerViewerDetails from "./Trips-manager/TripsManagerViewerDetails";
import TripForm from "./Trips/TripForm";
import TripDetails from "./Trips/TripsDetails";
import EmployeeViewer from "./Employee/EmployeeViewer";
import HolidaysViewer from "./Holidays/HolidaysViewer";
import HolidaysManagerViewer from "./Holidays-manager/HolidaysManagerViewer";
import HolidaysManagerViewerDetails from "./Holidays-manager/HolidaysManagerViewerDetails";
import HolidaysViewerDetails from "./Holidays/HolidaysViewerDetails";
import AssetsViewer from "./Fixed-assets/AssetsViewer";
import RemoteViewer from "./Remote/RemoteViewer";
import DocumentViewer from "./Document/DocumentViewer";
import AccountingViewer from "./Accounting/AccountingViewer";
import FleetViewer from "./Fleet/FleetViewer";
import Register from "./Credentials/Register";
import Login from "./Credentials/Login";
import NoMatch from "./NoMatch";
import Loading from "./Loading";
import SessionExpired from "./SessionExpired";
import Alerts from "./Alerts";
import Alerts2 from "./Alerts2";
import PrivateRoute from "./common/PrivateRoute";
//Integracja z Alert monitorem
import { Provider as AlertProvider } from "react-alert";
import AlertTemplate from "react-alert-template-basic";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import SchedulerViewer from "./Scheduler/SchedulerViewer";
import ZFSSViewer from "./ZFSS/ZFSSViewer";

//Opcje monitora alertów
const alertOptions = {
  timeout: 3000,
  position: "bottom center",
  containerStyle: {
    zIndex: 10000,
  },
  offset: "100px",
};

const initialState = {
  employeeView: null,
  permissions: null,
};

class RootMenu extends Component {
  state = {
    employeeView: null,
    permissions: null,
  };

  static propTypes = {
    auth: PropTypes.object.isRequired,
  };

  componentDidUpdate(prevProps) {
    if (
      prevProps.auth.isLoggingOut === true &&
      this.props.auth.isLoggingOut === false
    ) {
      this.setState(initialState);
    }
  }


  static getDerivedStateFromProps(props, state) {
    if (props.auth.user !== null && state.employeeView === null) {
      return {
        permissions: props.auth.permissions,
      };
    } else {
      return null;
    }
  }

  renderApps = (employeeView) => {
    this.setState({
      employeeView: employeeView,
    });
  };

  render() {
    const { permissions } = this.state;
    const { isLoading } = this.props.auth;
    const { defaultCompany } = this.props.auth.main_profile;

    //Private Routes definition for Employee
    const TripsViewerPrivateRoute = (
      <PrivateRoute exact path="/trips" component={TripsViewer} />
    );
    const TripFormPrivateRoute = (
      <PrivateRoute exact path="/trips/trip_form" component={TripForm} />
    );
    const TripDetailsPrivateRoute = (
      <PrivateRoute exact path="/trips/my/:pk" component={TripDetails} />
    );
    const TripDetailsManagerRoute = (
      <PrivateRoute exact path="/trips/employees/:pk" component={TripsManagerViewerDetails} />
    );
    const EmployeeViewerPrivateRoute = (
      <PrivateRoute path="/employee" component={EmployeeViewer} />
    );
    const HolidaysViewerPrivateRoute = (
      <PrivateRoute exact path="/holidays" component={HolidaysViewer} />
    );
    const HolidaysViewerDetailsPrivateRoute = (
      <PrivateRoute
        exact
        path="/holidays/my/:pk"
        component={HolidaysViewerDetails}
      />
    );
    const AssetsViewerPrivateRoute = (
      <PrivateRoute exact path="/assets" component={AssetsViewer} />
    );

    const RemoteViewerPrivateRoute = (
      <PrivateRoute exact path="/remote" component={RemoteViewer} />
    );

    const SchedulerViewerPrivateRoute = (
      <PrivateRoute exact path="/scheduler" component={SchedulerViewer} />
    );

    const AccountingViewerPrivateRoute = (
      <PrivateRoute exact path="/accounting" component={AccountingViewer} />
    );

    //Private Routes definition for Manager
    const FleetManagerPrivateRoute = (
      <PrivateRoute exact path="/fleet" component={FleetViewer} />
    );

    const DocumentsRoute = (
      <PrivateRoute exact path="/documents" component={DocumentViewer} />
    );

    const ZFSSViewerPrivateRoute = (
      <PrivateRoute exact path="/zfss" component={ZFSSViewer} />
    );

    const NoMatchPage = <Route component={NoMatch} />;
    const LoadingPage = <Route component={Loading} />;

    if (this.props.auth.user != null) {
      var isSuperUser = this.props.auth.user.is_superuser;
    }
    var perm_loaded = permissions != null

    return (
      <AlertProvider template={AlertTemplate} {...alertOptions}>
        <Router>
          <Navbar switchMenu={this.renderApps} />
          <Alerts />
          <Alerts2 />
          <div className="App">
            <Switch>
              {/* I. Tryb employee */}

              {/* Aplikacja Trips */}
              {perm_loaded && permissions.module_trips || isSuperUser ? TripsViewerPrivateRoute : ""}
              {perm_loaded && permissions.module_trips || isSuperUser ? TripFormPrivateRoute : ""}
              {perm_loaded && permissions.module_trips || isSuperUser ? TripDetailsPrivateRoute : ""}
              {perm_loaded && permissions.module_trips_admin || isSuperUser ? TripDetailsManagerRoute : ""}

              {/* Aplikacja Pracownik */}
              {perm_loaded && permissions.module_employee || isSuperUser ? EmployeeViewerPrivateRoute : ""}

              {/* Aplikacja Holidays - WYGASZONA */}
              {perm_loaded && permissions.module_holidays || isSuperUser ? HolidaysViewerPrivateRoute : ""}
              {perm_loaded && permissions.module_holidays || isSuperUser ? HolidaysViewerDetailsPrivateRoute : ""}

              {/* Aplikacja Feixed-assets */}
              {perm_loaded && permissions.module_equipment || isSuperUser ? AssetsViewerPrivateRoute : ""}

              {/* Aplikacja Accounting */}
              {perm_loaded && permissions.module_accounting || isSuperUser ? AccountingViewerPrivateRoute : ""}

              {/* Aplikacja Documents */}
              {perm_loaded && permissions.module_document || isSuperUser ? DocumentsRoute : ""}

              {/* Aplikacja ZFSS */}
              {(perm_loaded && permissions.module_zfss || isSuperUser) && defaultCompany == 1 ? ZFSSViewerPrivateRoute : ""}

              {/* Aplikacja Remote - WYGASZONA */}
              {/*employee ? RemoteViewerPrivateRoute : ""*/}

              {/* Aplikacja Scheduler */}
              {perm_loaded && permissions.module_scheduler || isSuperUser ? SchedulerViewerPrivateRoute : ""}

              {/* II. Tryb manager */}
              {perm_loaded && permissions.module_fleet || isSuperUser ? FleetManagerPrivateRoute : ""}

              {/* III. URL Wspólne */}
              <Route exact path="/" component={MainPage} />
              <Route path="/register" component={Register} />
              <Route path="/login" component={Login} />
              <Route path="/sessionExpired" component={SessionExpired} />
              {isLoading ? LoadingPage : NoMatchPage}
              {/*<Route component={NoMatch} />*/}
            </Switch>
          </div>
        </Router>
      </AlertProvider>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(RootMenu);
