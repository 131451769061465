import React, { Fragment } from "react";
import "../Style.css";
import { getDatabaseUrl, serverurl } from "../Redux/actions/databaseServerURL";

import Trips from "./Trips";
//integracja z reduxem
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { getTrips, addTrip } from "../Redux/actions/trips";
import { getMyManagerDetails } from "../Redux/actions/employee";
import Button from "@material-ui/core/Button";
import { ThemeProvider } from "@material-ui/styles";
import { createMuiTheme } from "@material-ui/core";
import { getProjects, clearProjects, getActiveProjects, clearActiveProjects } from "../Redux/actions/project.js"

//importy dla dialoga
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import TextField from "@material-ui/core/TextField";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import { makeStyles } from "@material-ui/core/styles";
import DateFnsUtils from "@date-io/date-fns";
import { pl } from "date-fns/locale";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from "@material-ui/pickers";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Checkbox from "@material-ui/core/Checkbox";
import ListItemText from "@material-ui/core/ListItemText";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import axios from "axios";
import FormLabel from "@material-ui/core/FormLabel";
import FormHelperText from "@material-ui/core/FormHelperText";

const materialTheme = createMuiTheme({
  overrides: {
    MuiFormControl: {
      root: {
        width: 300,
        marginTop: 50
      },
      marginNormal: {
        marginTop: "50px"
      }
    },
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: "#0071BC"
      }
    },
    MuiButton: {
      root: {},
      textPrimary: {
        color: "#0071BC"
      },
      containedPrimary: {
        backgroundColor: "#1BAB7F",
        "&:hover": {
          backgroundColor: "#188E6A"
        }
      }
    },
    MuiFormLabel: {
      root: {
        "&.Mui-focused": {
          color: "#0071BC"
        }
      }
    },
    MuiInput: {
      underline: {
        "&:after": {
          borderBottomColor: "#0071bc"
        }
      }
    },
    MuiPickersDay: {
      day: {
        color: "#0071BC"
      },
      daySelected: {
        backgroundColor: "#0071BC",
        "&:hover": {
          backgroundColor: "#0071BC"
        }
      },

      dayDisabled: {
        color: "gray"
      },
      current: {
        color: "#0071BC"
      }
    },
    MuiCheckbox: {
      colorPrimary: {
        "&.Mui-checked": {
          color: "#0071bc"
        }
      }
    },
    MuiFormGroup: {
      root: {
        marginTop: "10px"
      }
    }
  }
});

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  menuButton: {
    marginRight: theme.spacing(2)
  },
  title: {
    flexGrow: 1
  }
}));

var numbers = [];

function getHighestNumber(item) {
  const tripIdentifier = item.tripIdentifier.split("/");
  numbers.push(Number(tripIdentifier[2]));
}

class Employee extends React.Component {
  //definicja state dla obsługi lokalnych komponentów
  state = {
    dialogOpen: false,
    startDate: new Date(),
    startDateFormatted: null,
    endDate: null,
    endDateFormatted: null,
    country: "",
    countryErrorFlag: false,
    project: [],
    projectErrorFlag: false,
    tVehicle: false,
    tPlane: false,
    tTrain: false,
    tShip: false,
    transportErrorFlag: false,
    tripStatus: "",
    place: "",
    placeErrorFlag: false,
    remarks: "",
    remarksErrorFlag: false,
    isAccommodationNeeded: false,
    employee: "",
    tripIdentifier: "",
    tripsFromMonth: [],
    formErrorMessage: "To pole nie może być puste!",
    owner: null,
    ownersManager: null,
  };

  //integracja z reduxem
  static propTypes = {
    auth: PropTypes.object.isRequired,
    trips: PropTypes.array.isRequired,
    getTrips: PropTypes.func.isRequired,
    addTrip: PropTypes.func.isRequired,
    myManagerDetails: PropTypes.object.isRequired,
    getMyManagerDetails: PropTypes.func.isRequired,
    projects: PropTypes.any.isRequired,
    active: PropTypes.any.isRequired,
    getProjects: PropTypes.func.isRequired,
    clearProjects: PropTypes.func.isRequired,
    getActiveProjects: PropTypes.func.isRequired,
    clearActiveProjects: PropTypes.func.isRequired
  };

  componentWillUnmount() {
    this.props.clearProjects();
    this.props.clearActiveProjects();
  }

  sprawdzTextList = (value, label) => {
    //place, remarks, project, country
    switch (label) {
      case "place":
        if (value === "") {
          this.setState({
            placeErrorFlag: true
          });
          return true;
        } else {
          this.setState({
            placeErrorFlag: false
          });
          return false;
        }
      case "remarks":
        if (value === "") {
          this.setState({
            remarksErrorFlag: true
          });
          return true;
        } else {
          this.setState({
            remarksErrorFlag: false
          });
          return false;
        }
      case "project":
        if (value == []) {
          this.setState({
            projectErrorFlag: true
          });
          return true;
        } else {
          this.setState({
            projectErrorFlag: false
          });
          return false;
        }
      case "country":
        if (value === "") {
          this.setState({
            countryErrorFlag: true
          });
          return true;
        } else {
          this.setState({
            countryErrorFlag: false
          });
          return false;
        }
      default:
        return false;
    }
  };

  sprawdzTransport = (tPlane, tTrain, tVehicle, tShip) => {
    if (
      tPlane === true ||
      tTrain === true ||
      tVehicle === true ||
      tShip === true
    ) {
      this.setState({
        transportErrorFlag: false
      });
      return false;
    } else {
      this.setState({
        transportErrorFlag: true
      });
      return true;
    }
  };

  componentDidMount() {
    this.props.getTrips();
    this.props.getProjects();
    this.props.getActiveProjects();
  }

  componentDidUpdate(prevProps) {
    if (this.props.auth.main_profile.defaultCompany != prevProps.auth.main_profile.defaultCompany) {
      this.props.getTrips();
      this.props.getProjects();
      this.props.getActiveProjects();
    }
  }

  handleClickOpenDialog = () => {
    this.setState({
      dialogOpen: true
    });
    this.props.getMyManagerDetails(this.props.auth.main_profile.manager);
  };

  handleClickCloseDialog = () => {
    this.setState({
      dialogOpen: false
    });
  };

  handleSaveTrip = async () => {
    //dane do walidacji
    const {
      country,
      project,
      tVehicle,
      tPlane,
      tTrain,
      tShip,
      place,
      remarks,
      startDate,
      startDateFormatted,
      endDate,
      endDateFormatted
    } = this.state;

    //walidacja pól tekstowych i list
    const countryErrorFlag = this.sprawdzTextList(country, "country");
    const projectErrorFlag = this.sprawdzTextList(project, "project");
    const placeErrorFlag = this.sprawdzTextList(place, "place");
    const remarksErrorFlag = this.sprawdzTextList(remarks, "remarks");

    //walidacja checkbox transport
    const transportErrorFlag = this.sprawdzTransport(
      tPlane,
      tTrain,
      tVehicle,
      tShip
    );

    //walidacja pól dat
    if (startDateFormatted === null) {
      const dd = startDate.getDate();
      const mm = startDate.getMonth() + 1;
      const yyyy = startDate.getFullYear();
      const fullDate = yyyy + "-" + mm + "-" + dd;
      this.setState({
        startDateFormatted: fullDate
      });
    }
    if (endDateFormatted === null && endDate !== null) {
      const dd = endDate.getDate();
      const mm = endDate.getMonth() + 1;
      const yyyy = endDate.getFullYear();
      const fullDate = yyyy + "-" + mm + "-" + dd;
      this.setState({
        endDateFormatted: fullDate
      });
    }
    //walidacja formularza

    if (
      countryErrorFlag === true ||
      projectErrorFlag === true ||
      transportErrorFlag === true ||
      placeErrorFlag === true ||
      remarksErrorFlag === true
    ) {
    } else {
      //wylicz numer miesiąca
      const MonthNbr = this.state.startDate.getMonth() + 1;

      await axios
        .get(serverurl + getDatabaseUrl() + `/trips?search=RA%2F${MonthNbr}%2F`)
        .then(res => {
          this.setState({ tripsFromMonth: res.data });
        })
        .catch(err => {
          console.log(err);
        });
      //tutaj trzeba zmienić algorytm wyliczania identyfikatora
      //trzeba pobrać element o najwyższym kluczu (ostatni) i wyciągnąć z niego numer
      const czyzero = this.state.tripsFromMonth.length;

      var NextTripNbr;
      if (czyzero === 0) {
        NextTripNbr = this.state.tripsFromMonth.length + 1;
      } else {
        //dla każdego elementu macierzy pobierz numer z identyfikatora
        const tripsFromMonth = this.state.tripsFromMonth;
        tripsFromMonth.forEach(getHighestNumber);
        NextTripNbr = Math.max(...numbers) + 1;
        numbers = [];
      }

      //wylicz identyfikator Delegacji (TripIdentifier)

      const TripId = "RA/" + MonthNbr + "/" + NextTripNbr;

      this.setState({
        tripIdentifier: TripId
      });
      this.setState({
        tripStatus: "NW"
      });

      //set employeeId
      this.setState({
        employee: this.props.auth.main_profile.pk
      });
      //set ownerId
      this.setState({
        owner: this.props.auth.user.id
      });
      //set ownersManager
      this.setState({
        ownersManager: this.props.myManagerDetails.owner
      });

      const {
        country,
        project,
        tVehicle,
        tPlane,
        tTrain,
        tShip,
        tripStatus,
        startDateFormatted,
        endDateFormatted,
        place,
        isAccommodationNeeded,
        remarks,
        employee,
        tripIdentifier,
        owner,
        ownersManager
      } = this.state;

      const trip = {
        country,
        project,
        tVehicle,
        tPlane,
        tTrain,
        tShip,
        tripStatus,
        startDate: startDateFormatted,
        endDate: endDateFormatted,
        place,
        isAccommodationNeeded,
        remarks,
        employee,
        tripIdentifier,
        owner,
        ownersManager
      };

      //sprawdzenie czy startdate i enddate zostaly zainicjowane

      //console.log(trip);

      this.props.addTrip(trip);

      this.setState({
        dialogOpen: false
      });

      this.setState({
        startDate: new Date(),
        endDate: new Date(),
        country: "",
        project: [],
        tVehicle: false,
        tPlane: false,
        tTrain: false,
        tShip: false,
        place: "",
        remarks: "",
        isAccommodationNeeded: false,
        owner: null,
        ownersManager: null
      });
    }
  };

  handleTextList = name => ({ target: { value } }) => {
    this.setState({
      ...this.state,
      [name]: value
    });
  };

  handleStartDate = date => {
    if (date !== null) {
      const dd = date.getDate();
      const mm = date.getMonth() + 1;
      const yyyy = date.getFullYear();
      const fullDate = yyyy + "-" + mm + "-" + dd;
      this.setState({
        startDate: date,
        startDateFormatted: fullDate
      });
    } else {
      this.setState({
        startDate: null,
        startDateFormatted: null
      });
    }
  };

  handleEndDate = date => {
    if (date !== null) {
      const dd = date.getDate();
      const mm = date.getMonth() + 1;
      const yyyy = date.getFullYear();
      const fullDate = yyyy + "-" + mm + "-" + dd;
      this.setState({
        endDate: date,
        endDateFormatted: fullDate
      });
    } else {
      this.setState({
        endDate: null,
        endDateFormatted: null
      });
    }
  };

  handleCheckbox = name => ({ target: { checked } }) => {
    this.setState({
      ...this.state,
      [name]: checked
    });
  };

  getState() {
    return this.state;
  }

  render() {
    const {
      dialogOpen,
      place,
      placeErrorFlag,
      remarks,
      remarksErrorFlag,
      country,
      countryErrorFlag,
      project,
      projectErrorFlag,
      startDate,
      endDate,
      isAccommodationNeeded,
      tVehicle,
      tPlane,
      tTrain,
      tShip,
      transportErrorFlag,
    } = this.state;
    const classes = useStyles;

    var project_id = [];
    if (project != null) {
      project_id = project;
    }

    return (
      <Fragment>
        <div style={{ height: 50 }}></div>

        <ThemeProvider theme={materialTheme}>
          <Button variant="contained" onClick={this.handleClickOpenDialog}>
            Utwórz delegację
          </Button>
        </ThemeProvider>

        <Dialog
          fullScreen
          open={dialogOpen}
          onClose={this.handleClickCloseDialog}
          aria-labelledby="form-dialog-title"
        >
          <AppBar position="static" style={{ background: "#0071BC" }}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={this.handleClickCloseDialog}
                aria-label="close"
                className={classes.menuButton}
              >
                <CloseIcon />
              </IconButton>
              <Typography variant="h6" className={classes.title}>
                Utwórz delegację
              </Typography>
            </Toolbar>
          </AppBar>
          <DialogContent>
            <form
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center"
              }}
            >
              <ThemeProvider theme={materialTheme}>
                <TextField
                  error={placeErrorFlag}
                  required
                  multiline
                  rowsMax="3"
                  label="Miejsce/Obszar"
                  inputProps={{ maxLength: 100 }}
                  helperText="Miejsce wykonywanej pracy."
                  margin="normal"
                  value={place}
                  onChange={this.handleTextList("place")}
                />
              </ThemeProvider>
              <MuiPickersUtilsProvider
                utils={DateFnsUtils}
                locale={pl}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center"
                }}
              >
                <ThemeProvider theme={materialTheme}>
                  <KeyboardDatePicker
                    margin="normal"
                    label="Data wyjazdu"
                    format="yyyy/MM/dd"
                    minDate={new Date()}
                    value={startDate}
                    onChange={this.handleStartDate}
                  />
                </ThemeProvider>
                <ThemeProvider theme={materialTheme}>
                  <KeyboardDatePicker
                    margin="normal"
                    label="Data powrotu"
                    format="yyyy/MM/dd"
                    emptyLabel=""
                    minDate={new Date()}
                    value={endDate}
                    onChange={this.handleEndDate}
                  />
                </ThemeProvider>
              </MuiPickersUtilsProvider>
              <ThemeProvider theme={materialTheme}>
                <FormControl required className={classes.formControl}>
                  <InputLabel error={countryErrorFlag} htmlFor="country">
                    Kraj
                  </InputLabel>
                  <Select
                    name="country"
                    className={classes.selectEmpty}
                    value={country}
                    onChange={this.handleTextList("country")}
                  >
                    <MenuItem value="DK">Dania</MenuItem>
                    <MenuItem value="FI">Finlandia</MenuItem>
                    <MenuItem value="DE">Niemcy</MenuItem>
                    <MenuItem value="PL">Polska</MenuItem>
                    <MenuItem value="SE">Szwecja</MenuItem>
                    <MenuItem value="NL">Holandia</MenuItem>
                  </Select>
                </FormControl>
              </ThemeProvider>
              <ThemeProvider theme={materialTheme}>
                <FormControl required className={classes.formControl}>
                  <InputLabel error={projectErrorFlag} htmlFor="project">
                    Projekt
                  </InputLabel>
                  <Select
                    name="project"
                    className={classes.selectEmpty}
                    multiple
                    value={project_id}
                    onChange={this.handleTextList("project")}
                    renderValue={(selected) => selected.map((id) => {
                      const project = this.props.projects.find((p) => p.pk === id);
                      return project ? project.name : '';
                    }).join(', ')}
                  >
                    {this.props.active.map((item) => {
                      return (
                        <MenuItem key={item.pk} value={item.pk}>
                          <ListItemText primary={item.name} />
                        </MenuItem>
                      );
                    })}
                  </Select>
                  <FormHelperText>Można wybrać więcej niż jeden projekt.</FormHelperText>
                </FormControl>
              </ThemeProvider>
              <ThemeProvider theme={materialTheme}>
                <FormControl required component="fieldset">
                  <FormLabel error={transportErrorFlag} component="legend">
                    Transport
                  </FormLabel>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          value={tPlane}
                          onChange={this.handleCheckbox("tPlane")}
                        />
                      }
                      label="Samolot"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          value={tVehicle}
                          onChange={this.handleCheckbox("tVehicle")}
                        />
                      }
                      label="Samochód"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          value={tTrain}
                          onChange={this.handleCheckbox("tTrain")}
                        />
                      }
                      label="Pociąg"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          value={tShip}
                          onChange={this.handleCheckbox("tShip")}
                        />
                      }
                      label="Prom"
                    />
                  </FormGroup>
                  <FormHelperText>
                    Zaznacz przynajmniej jedną z opcji
                  </FormHelperText>
                </FormControl>
              </ThemeProvider>
              <ThemeProvider theme={materialTheme}>
                <FormControl>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          value={isAccommodationNeeded}
                          color="primary"
                          onChange={this.handleCheckbox(
                            "isAccommodationNeeded"
                          )}
                        />
                      }
                      label="Zakwaterowanie"
                    />
                  </FormGroup>
                </FormControl>
              </ThemeProvider>
              <ThemeProvider theme={materialTheme}>
                <TextField
                  error={remarksErrorFlag}
                  required
                  label="Opis delegacji"
                  multiline
                  rows="4"
                  margin="normal"
                  value={remarks}
                  onChange={this.handleTextList("remarks")}
                />
              </ThemeProvider>
              <div style={{ marginBottom: 50 }}></div>
              <ThemeProvider theme={materialTheme}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={this.handleSaveTrip}
                >
                  Utwórz delegację
                </Button>
              </ThemeProvider>
            </form>
            <div style={{ marginTop: 50 }}></div>
          </DialogContent>
        </Dialog>

        <Trips label="Nowe" status="NW" trips={this.props.trips}></Trips>
        <Trips
          label="Oczekujące na akceptację"
          status="CA"
          trips={this.props.trips}
        ></Trips>
        <Trips
          label="Zaakceptowane"
          status="ZA"
          trips={this.props.trips}
        ></Trips>
        <Trips
          label="Oczekujące na rozliczenie"
          status="RZ"
          trips={this.props.trips}
        ></Trips>
        <Trips label="Zamknięte" status="ZM" trips={this.props.trips}></Trips>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  trips: state.tripsReducer.trips,
  auth: state.auth,
  myManagerDetails: state.employee.myManagerDetails,
  projects: state.project.projects,
  active: state.project.active,
});

export default connect(
  mapStateToProps,
  {
    getTrips,
    addTrip,
    getMyManagerDetails,
    getProjects,
    getActiveProjects,
    clearProjects,
    clearActiveProjects
  }
)(Employee);
