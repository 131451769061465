import React, { Component, Fragment } from "react";
import Grid from "@material-ui/core/Grid";
import Card from "../../components/Card/Card.js";
import CardBody from "../../components/Card/CardBody.js";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";
import { formStyles } from "../../common/radiolineStyles";
import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { ThemeProvider } from "@material-ui/styles";
import { formsTheme } from "../../common/radiolineStyles";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import { getFormattedDate } from "../../common/commonFunctions";
import FormHelperText from "@material-ui/core/FormHelperText";
import { addTransaction } from "../../Redux/actions/accounting";
import { sendNotification, sendNewNotificationManager } from "../../Redux/actions/employee";
import { getProjects, clearProjects, getActiveProjects, clearActiveProjects } from "../../Redux/actions/project.js"

export class AddWithdrawTransactionForm extends Component {
  state = {
    withdrawValue: null,
    currency: "pln",
    transactionDescription: null,
    transactionProject: "",
    selectedCardNumber: null,
    transactionDate: new Date(),
    transactionDateFormatted: getFormattedDate(new Date()),
  };

  static propTypes = {
    auth: PropTypes.object.isRequired,
    addTransaction: PropTypes.func.isRequired,
    myManagerDetailsV2: PropTypes.object.isRequired,
    sendNotification: PropTypes.func.isRequired,
    sendNewNotificationManager: PropTypes.func.isRequired,
    projects: PropTypes.any.isRequired,
    active: PropTypes.any.isRequired,
    getProjects: PropTypes.func.isRequired,
    getActiveProjects: PropTypes.func.isRequired,
    clearProjects: PropTypes.func.isRequired,
    clearActiveProjects: PropTypes.func.isRequired
  };

  componentWillUnmount() {
    this.props.clearProjects();
    this.props.clearActiveProjects();
  }

  componentDidMount() {
    this.props.getProjects();
    this.props.getActiveProjects();
    this.setState({
      selectedCardNumber: this.props.auth.additional_profile.businesscards[0].cardNumber,
    });
  }

  handleTextList = (name) => ({ target: { value } }) => {
    this.setState({
      [name]: value.toLowerCase(),
    });
  };

  handleSelectChangeCurrency = (event) => {
    this.setState({
      currency: event.target.value,
    });
  };

  handleSelectChangeCardNumber = (event) => {
    this.setState({
      selectedCardNumber: event.target.value,
    });
  };

  handleSelectProject = (event) => {
    console.log(event.target);
    this.setState({
      transactionProject: event.target.value,
    });
  };

  handleTransactionDateChange = (date) => {
    if (date !== null) {
      const dd = date.getDate();
      const mm = date.getMonth() + 1;
      const yyyy = date.getFullYear();
      const fullDate = yyyy + "-" + mm + "-" + dd;
      this.setState({
        transactionDate: date,
        transactionDateFormatted: fullDate,
      });
    } else {
      this.setState({
        transactionDate: null,
        transactionDateFormatted: null,
      });
    }
  };

  handleAddWithdrawTransaction = () => {
    const body = new FormData();

    body.append("value", this.state.withdrawValue);
    body.append("owner", this.props.auth.user.id);
    body.append("createDate", getFormattedDate(new Date()));
    body.append("currency", this.state.currency);
    body.append("transactionCategory", "nd");
    body.append("transactionDate", this.state.transactionDateFormatted);
    body.append("project", this.state.transactionProject);
    body.append("bookDate", this.state.transactionDateFormatted);
    body.append("transactionDescription", this.state.transactionDescription);
    body.append("transactionStatus", "aa");
    body.append("transactionType", "wi");
    body.append("card", this.state.selectedCardNumber);

    /*
    const smsbody = {
      recipient: this.props.myManagerDetailsV2.phone,
      sender: "FINANSE",
      message:
        "Uzytkownik " +
        this.props.auth.user.username +
        " zarejestrowal transakcje pobrania zaliczki w wysokosci " +
        this.state.withdrawValue +
        " " +
        this.state.currency +
        ", tytulem: " +
        this.state.transactionDescription.toUpperCase() +
        ".",
      owner: this.props.auth.user.id,
      notificationType: "sms",
      isDelivered: true,
    };
    */

    const notifyBody = {
      user: this.props.auth.user.id,
      recipient: this.props.myManagerDetailsV2.email,
      module: "Finanse",
      topic: "Nowa transakcja pobrania zaliczki",
      message:
        "Użytkownik " + this.props.auth.user.first_name + " " + this.props.auth.user.last_name +
        " zarejestrował transakcję pobrania zaliczki: \n" +
        "Data transakcji: " + this.state.transactionDateFormatted + "\n" +
        "Kwota: " + this.state.withdrawValue + " " + this.state.currency + "\n"
    }

    this.props.addTransaction(body, "wi");
    this.props.closeDialog();
    //this.props.sendNotification(smsbody);
    this.props.sendNewNotificationManager(notifyBody);
  };

  render() {
    const { classes, auth } = this.props;
    const {
      withdrawValue,
      currency,
      transactionDescription,
      transactionProject,
      selectedCardNumber,
      transactionDate,
    } = this.state;

    const buttonAddDi = (
      <Button
        disabled
        variant="contained"
        color="primary"
        className={classes.buttonAdd}
      >
        Dodaj
      </Button>
    );

    const buttonAddEn = (
      <Button
        variant="contained"
        color="primary"
        onClick={this.handleAddWithdrawTransaction}
        className={classes.buttonAdd}
      >
        Dodaj
      </Button>
    );

    const businessCards = auth.additional_profile.businesscards;

    //console.log(businessCards);

    return (
      <Fragment>
        <Grid container justify="center" spacing={2}>
          <Grid item xs={12} sm={12} md={12}>
            <Card style={{ overflow: "auto" }}>
              <CardBody>
                <Typography
                  style={{
                    marginBottom: "50px",
                    color: "#888888",
                    textAlign: "left",
                    fontFamily: "Lato, Open Sans, sans-serif",
                    fontSize: "1em",
                    fontWeight: "300",
                  }}
                >
                  Podaj wypłacaną <b>kwotę</b> oraz <b>walutę</b> w jakiej
                  wypłacasz środki. Nie zapomnij uzupełnić pola "<b>tytułem</b>"
                  ...
                </Typography>
                <ThemeProvider theme={formsTheme}>
                  <TextField
                    required
                    variant="filled"
                    id="standard-number"
                    placeholder="00,00"
                    label="Wypłacana kwota"
                    type="number"
                    className={classes.textFieldDynamic}
                    value={withdrawValue || ""}
                    onChange={this.handleTextList("withdrawValue")}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                  <FormControl
                    variant="filled"
                    className={classes.textFieldDynamic}
                  >
                    <InputLabel id="demo-simple-select-filled-label">
                      Waluta
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-filled-label"
                      id="demo-simple-select-filled"
                      value={currency}
                      onChange={this.handleSelectChangeCurrency}
                    >
                      <MenuItem value={"pln"}>PLN</MenuItem>
                      <MenuItem value={"eur"}>EUR</MenuItem>
                      <MenuItem value={"sek"}>SEK</MenuItem>
                      <MenuItem value={"nok"}>NOK</MenuItem>
                    </Select>
                  </FormControl>
                  <br></br>
                  <FormControl
                    variant="filled"
                    className={classes.textFieldStatic}
                  >
                    <InputLabel id="demo-simple-select-filled-label">
                      Projekt
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-filled-label"
                      id="demo-simple-select-filled"
                      value={transactionProject}
                      onChange={this.handleSelectProject}
                    >
                      {this.props.active.map((item) => {
                        return (
                          <MenuItem value={item.pk}>{item.name}</MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                  <br></br>
                  <TextField
                    label="tytułem"
                    helperText="Wprowadź tytuł wypłaty, max 50 znaków"
                    id="filled-margin-none"
                    className={classes.textFieldStatic}
                    required
                    multiline
                    rowsMax={2}
                    value={transactionDescription || ""}
                    inputProps={{ maxLength: 50 }}
                    onChange={this.handleTextList("transactionDescription")}
                  //variant="filled"
                  />
                  <br></br>
                  <FormControl className={classes.textFieldStatic}>
                    <InputLabel id="demo-simple-select-label">Karta</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      defaultValue={businessCards[0].cardNumber}
                      value={selectedCardNumber || ""}
                      onChange={this.handleSelectChangeCardNumber}
                    >
                      {businessCards.map((businessCard, index) => (
                        <MenuItem
                          key={businessCard.pk}
                          value={businessCard.cardNumber}
                        >
                          {businessCard.cardNumber}
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText>Wybierz z listy ...</FormHelperText>
                  </FormControl>
                  <br></br>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      className={classes.textFieldStatic}
                      autoOk
                      disableToolbar
                      variant="inline"
                      format="yyyy-MM-dd"
                      margin="normal"
                      id="date-picker-inline"
                      label="Data wypłaty"
                      value={transactionDate}
                      onChange={this.handleTransactionDateChange}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </ThemeProvider>
              </CardBody>
            </Card>
          </Grid>
          <Grid style={{ marginBottom: "30px" }} item xs={12} sm={12} md={12}>
            {!withdrawValue || !transactionDescription || !transactionProject
              ? buttonAddDi
              : buttonAddEn}
          </Grid>
        </Grid>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  myManagerDetailsV2: state.employee.myManagerDetailsV2,
  projects: state.project.projects,
  active: state.project.active,
});

export default connect(
  mapStateToProps,
  { addTransaction, sendNotification, sendNewNotificationManager, getProjects, clearProjects, getActiveProjects, clearActiveProjects }
)(withStyles(formStyles, { withTheme: true })(AddWithdrawTransactionForm));
