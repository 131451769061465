import React, { Component, Fragment } from "react";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import CircularProgress from "@material-ui/core/CircularProgress";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Card from "../components/Card/Card.js";
import CardBody from "../components/Card/CardBody.js";
import Typography from "@material-ui/core/Typography";
import { getInspections, getInspectionDetils, clearInspectionDetails } from "../Redux/actions/fleet.js";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { lighten } from "@material-ui/core/styles";
import CardHeader from "@material-ui/core/CardHeader";
import MoneyIcon from "@material-ui/icons/Money";
import DriveEtaIcon from "@material-ui/icons/DriveEta";
import { createMuiTheme } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";
import { getFormattedDate } from "../common/commonFunctions";
import Zoom from "@material-ui/core/Zoom";
import Tooltip from "@material-ui/core/Tooltip";
import Fab from "@material-ui/core/Fab";
import VisibilityIcon from "@material-ui/icons/Visibility";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Box from "@material-ui/core/Box";

import InspectionCard from "./Inspection/InspectionCard.js";
import InspectionDetailsCard from "./Inspection/InspectionDetailsCard.js";
import InspectionReqPhotosCard from "./Inspection/InspectionReqPhotosCard.js";
import InspectionOpPhotosCard from "./Inspection/InspectionOpPhotosCard.js";
import InspectionDocumentsCard from "./Inspection/InspectionDocumentsCard.js";

const materialTheme = createMuiTheme({
  palette: {
    primary: {
      main: "#0071BC",
    },
  },
  overrides: {
    MuiChip: {
      root: {
        color: "#ffffff",
        "& $avatar": {
          color: "#ffffff",
        },
      },
      icon: {
        color: "#ffffff",
      },
    },
    MuiCardActions: {
      root: {
        background: "#aaaaaa",
      },
    },
    MuiIconButton: {
      root: {
        color: "#fff",
      },
    },
  },
});

const styles = (theme) => ({
  root: {
    width: "100%",
  },
  Name: {
    textAlign: "left",
    fontFamily: "Lato, Open Sans, sans-serif",
    fontSize: "1em",
    fontWeight: "700",
    color: "#444444",
  },
  Position: {
    textAlign: "left",
    fontFamily: "Lato, Open Sans, sans-serif",
    fontSize: "1em",
    fontWeight: "400",
    color: "#aaaaaa",
  },
  chipA: {
    background: "#81c784",
    color: "#ffffff",
  },
  chipN: {
    background: "#bbbbbb",
    color: "#ffffff",
  },
  header: {
    textAlign: "left",
    backgroundColor: lighten("#338dc9", 0.85),
  },
  avatar: {
    backgroundColor: "#0071bc",
  },
  headertitle: {
    fontFamily: "Lato, Open Sans, sans-serif",
    fontSize: "1.2em",
    fontWeight: "700",
    color: "#444444",
  },
  info: {
    fontFamily: "Lato, Open Sans, sans-serif",
    fontSize: "1.5em",
    fontWeight: "300",
    color: "#0071BC",
  },
  fabblue: {
    color: theme.palette.common.white,
    backgroundColor: "#2196f3",
    "&:hover": {
      backgroundColor: "#1976d2",
    },
  },
  fb1: {
    position: "fixed",
    bottom: theme.spacing(8),
    right: theme.spacing(5),
  },
  inline: {
    display: "inline",
  },
});

export class InspectionListViewer extends Component {
  state = {
    selectedIndex: null,
    index: null,
    carSelected: null,
    isCarSelected: false,
    openDialog: false,
  };

  static propTypes = {
    inspections: PropTypes.array.isRequired,
    queryInProgressInspections: PropTypes.bool.isRequired,
    queryInProgressInspectionDetails: PropTypes.bool.isRequired,
    getInspections: PropTypes.func.isRequired,
    getInspectionDetils: PropTypes.func.isRequired,
    clearInspectionDetails: PropTypes.func.isRequired,
  };

  componentDidMount() {
    if (this.props.inspections.length === 0) {
      this.props.getInspections();
    }
  }

  handleListItemClick = (event, index, id) => {
    const selectedIndex = this.state.selectedIndex;
    if (selectedIndex !== index) {
      this.setState({
        selectedIndex: index,
        inspecSelected: id,
        isInspecSelected: true,
      });
      this.props.getInspectionDetils(id);
    } else {
      this.setState({
        selectedIndex: null,
        inspecSelected: null,
        isInspecSelected: false,
      });
      this.props.clearInspectionDetails();
    }
  };

  handleClickOpenDialog = () => {
    this.setState({
      openDialog: true,
    });
  };

  handleClickCloseDialog = () => {
    this.setState({
      openDialog: false,
    });
  };

  render() {
    const { classes, inspections, queryInProgressInspection, queryInProgressInspectionDetails } = this.props;
    const { selectedIndex, openDialog, isInspecSelected } = this.state;

    var inspectionList = null;
    const queryInProgress = <CircularProgress color="secondary" />;

    const nothingToDisplay = (
      <div className={classes.info}>Brak przeglądów / napraw do wyświetlenia ...</div>
    );

    const waitToDisplay = (
      <div className={classes.info}>Czekaj, pobieram dane z serwera ...</div>
    );

    const showButtonDisabled = (
      <Fab
        disabled
        color="inherit"
        className={classes.fabblue}
        size="small"
        aria-label="scroll back to top"
      >
        <VisibilityIcon />
      </Fab>
    );

    const showButtonEnabled = (
      <Fab
        color="inherit"
        className={classes.fabblue}
        size="small"
        aria-label="scroll back to top"
      >
        <VisibilityIcon />
      </Fab>
    );

    if (inspections !== undefined) {
      inspectionList = (
        <List component="nav" aria-label="main mailbox folders">
          {inspections.length === 0 && !queryInProgressInspection
            ? nothingToDisplay
            : ""}
          {inspections.length === 0 && queryInProgressInspection
            ? waitToDisplay
            : ""}
          {inspections.filter(inspection => inspection.entryType == this.props.entryType).sort((a, b) => {
            if (a.status === "new" && b.status === "cmpl") return -1;
            if (a.status === "cmpl" && b.status === "new") return 1;

            return a.id - b.id;
          }).map((inspec, index) => (
            <ListItem
              key={inspec.pk}
              button
              selected={selectedIndex === index}
              onClick={(event) =>
                this.handleListItemClick(event, index, inspec.pk)
              }
            >
              <ListItemAvatar>
                <Avatar>
                  {inspec.pk}
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={
                  <Fragment>
                    <Typography
                      component="span"
                      className={`${classes.inline} ${classes.Name}`}
                    >
                      {inspec.car.manufacturer + " - " + inspec.car.regNumber}
                    </Typography>
                    <Typography
                      component="span"
                      className={`${classes.inline} ${classes.Position}`}
                    >
                      {" | " + getFormattedDate(new Date(inspec.inspectionDate)) + " | " + inspec.entryType_description}
                    </Typography>
                  </Fragment>
                }
                secondary={
                  <Fragment>
                    <ThemeProvider theme={materialTheme}>
                      <span style={{ display: "flex", alignItems: "center" }}>
                        <MoneyIcon color="primary" />
                        <Typography
                          component="span"
                          className={classes.Position}
                        >
                          {" | Przebieg: " + inspec.mileage + " km"}
                        </Typography>
                      </span>
                      <span style={{ display: "flex", alignItems: "center" }}>
                        <DriveEtaIcon color="primary" />
                        <Typography
                          component="span"
                          className={classes.Position}
                        >
                          {" | Status: " + inspec.status_description}
                        </Typography>
                      </span>
                    </ThemeProvider>
                  </Fragment>
                }
              />
            </ListItem>
          ))}
        </List>
      );
    } else {
      inspectionList = <List className={classes.root}></List>;
    }

    return (
      <Fragment>
        <Grid container justify="flex-start" spacing={2} style={{ width: "100%", padding: "1%" }}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Card style={{ marginTop: "0px", overflow: "auto" }}>
              <CardBody style={{ padding: 0 }}>
                <CardHeader
                  className={classes.header}
                  avatar={
                    <Avatar aria-label="recipe" className={classes.avatar}>
                      PN
                    </Avatar>
                  }
                  action={queryInProgressInspection ? queryInProgress : ""}
                  title={
                    <div className={classes.headertitle}>{this.props.entryType != 'repair' ? 'Przeglądy' : 'Naprawy'}</div>
                  }
                />
                {inspectionList}
              </CardBody>
            </Card>
          </Grid>
        </Grid>

        <Zoom in={isInspecSelected}>
          <div
            onClick={this.handleClickOpenDialog}
            role="presentation"
            className={classes.fb1}
          >
            <Tooltip title="Wyświetl" placement="left">
              {queryInProgressInspectionDetails ? showButtonDisabled : showButtonEnabled}
            </Tooltip>
          </div>
        </Zoom>

        <Dialog
          fullScreen
          open={openDialog}
          onClose={this.handleClickCloseDialog}
          aria-labelledby="form-dialog-title"
        >
          <AppBar position="static" style={{ background: "#0071BC" }}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={this.handleClickCloseDialog}
                aria-label="close"
                className={classes.menuButton}
              >
                <CloseIcon />
              </IconButton>
              <Typography variant="h6" className={classes.title}>
                Informacje o sprawie
              </Typography>
            </Toolbar>
          </AppBar>
          <DialogContent style={{ background: "#f5f5f5" }}>
            <div className={classes.section}>
              <Box m={2}>
                <Grid container justify="flex-start" spacing={2}>
                  <InspectionCard />
                  <InspectionDetailsCard />
                  <InspectionReqPhotosCard />
                  <InspectionOpPhotosCard />
                  <InspectionDocumentsCard />
                </Grid>
              </Box>
            </div>
          </DialogContent>
        </Dialog>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  inspections: state.fleet.inspections,
  queryInProgressInspections: state.fleet.queryInProgressInspections,
  queryInProgressInspectionDetails: state.fleet.queryInProgressInspectionDetails,
});

export default connect(
  mapStateToProps,
  { getInspections, getInspectionDetils, clearInspectionDetails }
)(withStyles(styles, { withTheme: true })(InspectionListViewer));
