import React, { Component, Fragment } from "react";
import Grid from "@material-ui/core/Grid";
import Card from "../../components/Card/Card.js";
import CardBody from "../../components/Card/CardBody.js";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";
import { formStyles } from "../../common/radiolineStyles";
import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { ThemeProvider } from "@material-ui/styles";
import { formsTheme } from "../../common/radiolineStyles";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import { getFormattedDate } from "../../common/commonFunctions";
import FormHelperText from "@material-ui/core/FormHelperText";
import { addTransaction } from "../../Redux/actions/accounting";
import { sendNotification, sendNewNotification } from "../../Redux/actions/employee";
import { getProjects, clearProjects, getActiveProjects, clearActiveProjects } from "../../Redux/actions/project.js"

export class AddReturnTransactionForm extends Component {
  state = {
    value: null,
    currency: null,
    transactionDescription: null,
    transactionDate: new Date(),
    transactionDateFormatted: getFormattedDate(new Date()),
    transactionProject: "",
  };

  static propTypes = {
    auth: PropTypes.object.isRequired,
    addTransaction: PropTypes.func.isRequired,
    sendNotification: PropTypes.func.isRequired,
    sendNewNotification: PropTypes.func.isRequired,
    userDetails: PropTypes.object.isRequired,
    balanceEUR: PropTypes.object.isRequired,
    balancePLN: PropTypes.object.isRequired,
    balanceSEK: PropTypes.object.isRequired,
    balanceNOK: PropTypes.object.isRequired,
    projects: PropTypes.any.isRequired,
    active: PropTypes.any.isRequired,
    getProjects: PropTypes.func.isRequired,
    getActiveProjects: PropTypes.func.isRequired,
    clearProjects: PropTypes.func.isRequired,
    clearActiveProjects: PropTypes.func.isRequired,
  };

  componentWillUnmount() {
    this.props.clearProjects();
    this.props.clearActiveProjects();
  }

  componentDidMount() {
    this.props.getProjects();
    this.props.getActiveProjects();
    if (this.props.thisYear === true) {
      this.setState({
        currency: this.props.selectedCurrency,
      });
    } else if (this.props.thisYear === false) {
      const today = new Date();
      const transactionDate = new Date(today.getFullYear(), 0, 0);

      this.setState({
        currency: this.props.selectedCurrency,
        transactionDate: transactionDate,
        transactionDateFormatted: getFormattedDate(transactionDate),
      });
    }
  }

  handleTextList = (name) => ({ target: { value } }) => {
    this.setState({
      [name]: value.toLowerCase(),
    });
  };

  handleSelectProject = (event) => {
    console.log(event.target);
    this.setState({
      transactionProject: event.target.value,
    });
  };

  handleAddReturnTransaction = () => {
    const body = new FormData();

    body.append("value", this.state.value);
    body.append("owner", this.props.userDetails.pk);
    body.append("createDate", this.state.transactionDateFormatted);
    body.append("currency", this.state.currency);
    body.append("project", this.state.transactionProject);
    body.append("transactionCategory", "nd");
    body.append("transactionDate", this.state.transactionDateFormatted);
    body.append("bookDate", this.state.transactionDateFormatted);
    body.append("transactionDescription", this.state.transactionDescription);
    body.append("transactionStatus", "pc");
    body.append("transactionType", "rt");
    body.append(
      "clearanceOwner",
      this.props.auth.user.first_name + " " + this.props.auth.user.last_name
    );
    body.append(
      "clearanceOwnerCommentDate",
      this.state.transactionDateFormatted
    );

    /*
    const smsbodytouser = {
      recipient: this.props.userDetails.phone,
      sender: "FINANSE",
      message:
        "Uzytkownik " +
        this.props.auth.user.username +
        " zainicjowal transakcje zwrotu srodkow do kasy na kwote " +
        this.state.value +
        " " +
        this.state.currency +
        ", tytulem: " +
        this.state.transactionDescription.toUpperCase() +
        ". Zwroc w/w kwote do kasy. Szczegoly twoich transakcji mozesz sprawdzic w module Finanse aplikacji RadiolineR",
      owner: this.props.auth.user.id,
      notificationType: "sms",
      isDelivered: true,
    };
    */

    const notifyBody = {
      recipient: this.props.userDetails.email,
      module: "Finanse",
      topic: "Zwrot środków do kasy",
      message:
        "Użytkownik " + this.props.auth.user.first_name + " " + this.props.auth.user.last_name +
        " zarejestrował transakcję zwrotu środków do kasy: \n" +
        "Data transakcji: " + this.state.transactionDateFormatted + "\n" +
        "Kwota: " + this.state.value + " " + this.state.currency + "\n" +
        "Tytułem: " + this.state.transactionDescription + "\n" +
        "Tą kwotę należy zwrócić do kasy."
    }

    this.props.addTransaction(body, "rt");
    this.props.closeDialog();
    //this.props.sendNotification(smsbodytouser);
    this.props.sendNewNotification(notifyBody);
  };

  render() {
    const { classes } = this.props;
    const {
      value,
      currency,
      transactionDescription,
      transactionDate,
      transactionProject,
    } = this.state;

    const buttonAddDi = (
      <Button
        disabled
        variant="contained"
        color="primary"
        className={classes.buttonAdd}
      >
        Dodaj
      </Button>
    );

    const buttonAddEn = (
      <Button
        variant="contained"
        color="primary"
        onClick={this.handleAddReturnTransaction}
        className={classes.buttonAdd}
      >
        Dodaj
      </Button>
    );

    const historicInfo = (
      <FormHelperText>
        Dodawanie transakcji do salda historycznego wykonywane jest zawsze z
        datą ostatniego dnia danego roku.
      </FormHelperText>
    );

    return (
      <Fragment>
        <Grid container justify="center" spacing={2}>
          <Grid item xs={12} sm={12} md={12}>
            <Card style={{ overflow: "auto" }}>
              <CardBody>
                <Typography
                  style={{
                    marginBottom: "50px",
                    color: "#888888",
                    textAlign: "left",
                    fontFamily: "Lato, Open Sans, sans-serif",
                    fontSize: "1em",
                    fontWeight: "300",
                  }}
                >
                  Wprowadź <b>kwotę</b> oraz <b>walutę</b> w jakiej potwierdzasz
                  zwrot do kasy. Nie zapomnij dołączyć dowodu dokumentującego
                  zwrot środków ...
                </Typography>
                <ThemeProvider theme={formsTheme}>
                  <TextField
                    required
                    variant="filled"
                    id="standard-number"
                    placeholder="00,00"
                    label="Zwracana kwota"
                    type="number"
                    className={classes.textFieldDynamic}
                    value={value || ""}
                    onChange={this.handleTextList("value")}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                  <FormControl
                    variant="filled"
                    className={classes.textFieldDynamic}
                  >
                    <InputLabel id="demo-simple-select-filled-label">
                      Waluta
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-filled-label"
                      id="demo-simple-select-filled"
                      value={currency || ""}
                      open={false}
                    >
                      <MenuItem value={"pln"}>PLN</MenuItem>
                      <MenuItem value={"eur"}>EUR</MenuItem>
                      <MenuItem value={"sek"}>SEK</MenuItem>
                      <MenuItem value={"nok"}>NOK</MenuItem>
                    </Select>
                  </FormControl>
                  <br></br>
                  <FormControl
                    variant="filled"
                    className={classes.textFieldStatic}
                  >
                    <InputLabel id="demo-simple-select-filled-label">
                      Projekt
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-filled-label"
                      id="demo-simple-select-filled"
                      value={transactionProject}
                      onChange={this.handleSelectProject}
                    >
                      {this.props.active.map((item) => {
                        return (
                          <MenuItem value={item.pk}>{item.name}</MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                  <br></br>
                  <TextField
                    label="tytułem"
                    helperText="Wprowadź tytuł zwrotu, max 50 znaków"
                    id="filled-margin-none"
                    className={classes.textFieldStatic}
                    required
                    multiline
                    rowsMax={2}
                    value={transactionDescription || ""}
                    inputProps={{ maxLength: 50 }}
                    onChange={this.handleTextList("transactionDescription")}
                  //variant="filled"
                  />
                  <br></br>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      className={classes.textFieldStatic}
                      autoOk
                      disabled
                      disableToolbar
                      variant="inline"
                      format="yyyy-MM-dd"
                      margin="normal"
                      id="date-picker-inline"
                      label="Data zarejestrowania zwrotu"
                      value={transactionDate}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                    />
                  </MuiPickersUtilsProvider>
                  {!this.props.thisYear ? historicInfo : ""}
                </ThemeProvider>
              </CardBody>
            </Card>
          </Grid>
          <Grid style={{ marginBottom: "30px" }} item xs={12} sm={12} md={12}>
            {!value || !transactionDescription || !transactionProject ? buttonAddDi : buttonAddEn}
          </Grid>
        </Grid>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  userDetails: state.common.suser,
  balanceEUR: state.accounting.balanceEUR,
  balancePLN: state.accounting.balancePLN,
  balanceSEK: state.accounting.balanceSEK,
  balanceNOK: state.accounting.balanceNOK,
  projects: state.project.projects,
  active: state.project.active,
});

export default connect(
  mapStateToProps,
  { addTransaction, sendNotification, sendNewNotification, getProjects, clearProjects, getActiveProjects, clearActiveProjects }
)(withStyles(formStyles, { withTheme: true })(AddReturnTransactionForm));
