import React, { Component, Fragment } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import Card from "../components/Card/Card.js";
import CardBody from "../components/Card/CardBody.js";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { createMuiTheme } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";
//import LinearProgress from "@material-ui/core/LinearProgress";
//import Box from "@material-ui/core/Box";
import TextField from "@material-ui/core/TextField";
import { getUserDetails } from "../Redux/actions/common";
import { patchProfileEm } from "../Redux/actions/common";

import PropTypes from "prop-types";
import { connect } from "react-redux";

import InputAdornment from "@material-ui/core/InputAdornment";
import EditIcon from "@material-ui/icons/Edit";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

const materialTheme = createMuiTheme({
  palette: {
    primary: {
      main: "#0071BC",
    },
  },
  overrides: {
    MuiChip: {
      root: {
        color: "#ffffff",
        "& $avatar": {
          color: "#ffffff",
        },
      },
      icon: {
        color: "#ffffff",
      },
    },
    MuiCardActions: {
      root: {
        background: "#aaaaaa",
      },
    },
    MuiIconButton: {
      root: {
        color: "#fff",
      },
    },
  },
});

const styles = (theme) => ({
  avatar: {
    backgroundColor: "#0071BC",
    height: 50,
    width: 50,
  },
  Address: {
    textAlign: "left",
    fontFamily: "Lato, Open Sans, sans-serif",
    fontSize: "1em",
    fontWeight: "300",
    color: "#444444",
  },
  Name: {
    textAlign: "left",
    fontFamily: "Lato, Open Sans, sans-serif",
    fontSize: "1em",
    fontWeight: "700",
    color: "#444444",
  },
  ProfileBar: {
    textAlign: "left",
    fontFamily: "Lato, Open Sans, sans-serif",
    fontSize: "1em",
    fontWeight: "400",
    color: "#444444",
  },
  Country: {
    textAlign: "left",
    fontFamily: "Lato, Open Sans, sans-serif",
    fontSize: "1em",
    fontWeight: "300",
    color: "#444444",
    textTransform: "uppercase",
  },
  large: {
    width: "70px",
    height: "70px",
  },
});

export class ProfileCardE extends Component {
  state = {
    phone: "",
    idNumber: "",
    idValidDate: "",
    addrCity: "",
    addrStreet: "",
    addrPostalCode: "",
    addrCountry: "",
    phoneFlag: false,
    idNumberFlag: false,
    idValidDateFlag: false,
    addrCityFlag: false,
    addrStreetFlag: false,
    addrPostalCodeFlag: false,
    addrCountryFlag: false,
    salaryBankName: null,
    salaryAccountNumber: null,
    openDialog: false,
  };

  static propTypes = {
    userFull: PropTypes.object.isRequired,
    manager: PropTypes.object.isRequired,
    getUserDetails: PropTypes.func.isRequired,
    patchProfileEm: PropTypes.func.isRequired,
  };

  componentDidMount() {
    this.props.getUserDetails(this.props.userFull.main_profile.manager, "s");
    //inicjalizacja zmiennych edytowalnych
    this.setState({
      phone: this.props.userFull.additional_profile.phone,
      idNumber: this.props.userFull.additional_profile.idNumber,
      idValidDate: this.props.userFull.additional_profile.idValidDate,
      addrCity: this.props.userFull.additional_profile.addrCity,
      addrStreet: this.props.userFull.additional_profile.addrStreet,
      addrPostalCode: this.props.userFull.additional_profile.addrPostalCode,
      addrCountry: this.props.userFull.additional_profile.addrCountry,
    });
  }

  handleTextList = (name) => ({ target: { value } }) => {
    this.setState({
      ...this.state,
      [name]: value,
    });
  };

  triggerSaveProfile = () => {
    if (this.state.phone !== this.props.userFull.additional_profile.phone) {
      this.setState({
        phoneFlag: true,
      });
    } else {
      this.setState({
        phoneFlag: false,
      });
    }

    if (this.state.idNumber !== this.props.userFull.additional_profile.idNumber) {
      this.setState({
        idNumberFlag: true,
      });
    } else {
      this.setState({
        idNumberFlag: false,
      });
    }

    if (this.state.addrCity !== this.props.userFull.additional_profile.addrCity) {
      this.setState({
        addrCityFlag: true,
      });
    } else {
      this.setState({
        addrCityFlag: false,
      });
    }

    if (this.state.addrStreet !== this.props.userFull.additional_profile.addrStreet) {
      this.setState({
        addrStreetFlag: true,
      });
    } else {
      this.setState({
        addrStreetFlag: false,
      });
    }

    if (
      this.state.addrPostalCode !==
      this.props.userFull.additional_profile.addrPostalCode
    ) {
      this.setState({
        addrPostalCodeFlag: true,
      });
    } else {
      this.setState({
        addrPostalCodeFlag: false,
      });
    }

    if (
      this.state.addrCountry !== this.props.userFull.additional_profile.addrCountry
    ) {
      this.setState({
        addrCountryFlag: true,
      });
    } else {
      this.setState({
        addrCountryFlag: false,
      });
    }

    if (
      this.state.idValidDate !== this.props.userFull.additional_profile.idValidDate
    ) {
      this.setState({
        idValidDateFlag: true,
      });
    } else {
      this.setState({
        idValidDateFlag: false,
      });
    }

    this.setState({
      openDialog: true,
    });
  };

  handleCloseDialog = () => {
    this.setState({
      openDialog: false,
    });
  };

  handleSaveProfile = () => {
    var profileBody = {
      phone: this.state.phone,
      idNumber: this.state.idNumber,
      idValidDate: this.state.idValidDate,
      addrCity: this.state.addrCity,
      addrStreet: this.state.addrStreet,
      addrPostalCode: this.state.addrPostalCode,
      addrCountry: this.state.addrCountry,
    };
    this.setState({
      openDialog: false,
    });
    this.props.patchProfileEm(profileBody, this.props.userFull.main_profile.pk, 0);
  };

  render() {
    const { classes, userFull } = this.props;
    const {
      phone,
      idNumber,
      idValidDate,
      addrCity,
      addrStreet,
      addrPostalCode,
      addrCountry,
      openDialog,
      phoneFlag,
      idNumberFlag,
      idValidDateFlag,
      addrCityFlag,
      addrStreetFlag,
      addrPostalCodeFlag,
      addrCountryFlag,
    } = this.state;

    const phoneInfo = (
      <TextField
        label="Numer telefonu"
        id="outlined-margin-normal"
        value={phone}
        fullWidth
        className={classes.textField}
        margin="normal"
        variant="outlined"
        size="small"
      />
    );
    const idNumberInfo = (
      <Fragment>
        <TextField
          label="Numer dowodu"
          id="outlined-margin-normal"
          value={idNumber}
          fullWidth
          className={classes.textField}
          margin="normal"
          variant="outlined"
          size="small"
        />
      </Fragment>
    );

    const idValidDateInfo = (
      <Fragment>
        <TextField
          label="Data ważności dowodu"
          id="outlined-margin-normal"
          value={idValidDate}
          fullWidth
          className={classes.textField}
          margin="normal"
          variant="outlined"
          size="small"
        />
      </Fragment>
    );

    const addrCityInfo = (
      <Fragment>
        <TextField
          label="Miejscowość"
          id="outlined-margin-normal"
          value={addrCity}
          fullWidth
          className={classes.textField}
          margin="normal"
          variant="outlined"
          size="small"
        />
      </Fragment>
    );

    const addrStreetInfo = (
      <Fragment>
        <TextField
          label="Ulica oraz numer domu"
          id="outlined-margin-normal"
          value={addrStreet}
          fullWidth
          className={classes.textField}
          margin="normal"
          variant="outlined"
          size="small"
        />
      </Fragment>
    );

    const addrPostalCodeInfo = (
      <Fragment>
        <TextField
          label="Kod pocztowy"
          id="outlined-margin-normal"
          value={addrPostalCode}
          fullWidth
          className={classes.textField}
          margin="normal"
          variant="outlined"
          size="small"
        />
      </Fragment>
    );

    const addrCountryInfo = (
      <Fragment>
        <TextField
          label="Kraj"
          id="outlined-margin-normal"
          value={addrCountry}
          fullWidth
          className={classes.textField}
          margin="normal"
          variant="outlined"
          size="small"
        />
      </Fragment>
    );

    const nothingInfo = (
      <Fragment>
        <Typography>Brak danych do aktualizacji ...</Typography>
      </Fragment>
    );

    const saveButtonEn = (
      <Button
        onClick={this.handleSaveProfile}
        color="primary"
        autoFocus
        variant="contained"
      >
        Zapisz
      </Button>
    );

    const saveButtonDi = (
      <Button disabled color="primary" autoFocus variant="contained">
        Zapisz
      </Button>
    );

    return (
      <Fragment>
        <Grid item xs={12} sm={12} md={6} lg={8}>
          <Card style={{ marginBottom: "0px" }}>
            <CardBody>
              <ThemeProvider theme={materialTheme}>
                <Grid
                  container
                  justify="space-between"
                  alignItems="center"
                  spacing={2}
                >
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Typography className={classes.Name}>
                      Profil użytkownika
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={3} md={6} lg={3}>
                    <TextField
                      label="Imię"
                      id="outlined-margin-normal"
                      value={userFull.user.first_name}
                      fullWidth
                      className={classes.textField}
                      margin="normal"
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12} sm={3} md={6} lg={3}>
                    <TextField
                      label="Nazwisko"
                      id="outlined-margin-normal"
                      value={userFull.user.last_name}
                      fullWidth
                      className={classes.textField}
                      margin="normal"
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={12} lg={6}>
                    <TextField
                      label="E-mail"
                      id="outlined-margin-normal"
                      value={userFull.user.email}
                      fullWidth
                      className={classes.textField}
                      margin="normal"
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12} sm={3} md={4} lg={3}>
                    <TextField
                      label="Login"
                      id="outlined-margin-normal"
                      value={userFull.user.username}
                      fullWidth
                      className={classes.textField}
                      margin="normal"
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12} sm={3} md={4} lg={3}>
                    <TextField
                      label="Numer telefonu"
                      id="outlined-margin-normal"
                      value={phone}
                      fullWidth
                      className={classes.textField}
                      onChange={this.handleTextList("phone")}
                      margin="normal"
                      variant="outlined"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <EditIcon />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={3} md={4} lg={3}>
                    <TextField
                      label="Numer dowodu"
                      id="outlined-margin-normal"
                      value={idNumber}
                      onChange={this.handleTextList("idNumber")}
                      fullWidth
                      className={classes.textField}
                      margin="normal"
                      variant="outlined"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <EditIcon />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={3} md={4} lg={3}>
                    <TextField
                      label="Data ważności dowodu"
                      id="outlined-margin-normal"
                      value={idValidDate}
                      onChange={this.handleTextList("idValidDate")}
                      fullWidth
                      className={classes.textField}
                      margin="normal"
                      variant="outlined"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <EditIcon />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={3} md={4} lg={3}>
                    <TextField
                      label="PESEL"
                      id="outlined-margin-normal"
                      value={userFull.main_profile.pesel}
                      fullWidth
                      className={classes.textField}
                      margin="normal"
                      variant="outlined"
                    />
                  </Grid>

                  <Grid item xs={12} sm={3} md={4} lg={3}>
                    <TextField
                      label="Data urodzenia"
                      id="outlined-margin-normal"
                      value={userFull.main_profile.birthDate}
                      fullWidth
                      className={classes.textField}
                      margin="normal"
                      variant="outlined"
                    />
                  </Grid>

                  <Grid item xs={12} sm={3} md={4} lg={3}>
                    <TextField
                      label="Miejsce urodzenia"
                      id="outlined-margin-normal"
                      value={userFull.main_profile.birthPlace}
                      fullWidth
                      className={classes.textField}
                      margin="normal"
                      variant="outlined"
                    />
                  </Grid>

                  <Grid item xs={12} sm={3} md={4} lg={3}>
                    <TextField
                      label="Rodzaj umowy"
                      id="outlined-margin-normal"
                      value={userFull.additional_profile.cooperationType}
                      fullWidth
                      className={classes.textField}
                      margin="normal"
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={7} lg={6}>
                    <TextField
                      label="Stanowisko"
                      id="outlined-margin-normal"
                      value={userFull.additional_profile.position}
                      fullWidth
                      className={classes.textField}
                      margin="normal"
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={12} lg={6}>
                    <TextField
                      label="Przełożony"
                      id="outlined-margin-normal"
                      value={
                        this.props.manager.first_name +
                        " " +
                        this.props.manager.last_name
                      }
                      fullWidth
                      className={classes.textField}
                      margin="normal"
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={4}>
                    <TextField
                      label="Miejscowość"
                      id="outlined-margin-normal"
                      value={addrCity}
                      onChange={this.handleTextList("addrCity")}
                      className={classes.textField}
                      margin="normal"
                      fullWidth
                      variant="filled"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <EditIcon />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={4}>
                    <TextField
                      label="Ulica oraz numer domu"
                      id="outlined-margin-normal"
                      value={addrStreet}
                      onChange={this.handleTextList("addrStreet")}
                      fullWidth
                      className={classes.textField}
                      margin="normal"
                      variant="filled"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <EditIcon />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={4}>
                    <TextField
                      label="Kod pocztowy"
                      id="outlined-margin-normal"
                      value={addrPostalCode}
                      onChange={this.handleTextList("addrPostalCode")}
                      fullWidth
                      className={classes.textField}
                      margin="normal"
                      variant="filled"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <EditIcon />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={4}>
                    <TextField
                      label="Kraj"
                      id="outlined-margin-normal"
                      value={addrCountry}
                      onChange={this.handleTextList("addrCountry")}
                      fullWidth
                      className={classes.textField}
                      margin="normal"
                      variant="filled"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <EditIcon />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={8}></Grid>
                  <ThemeProvider theme={materialTheme}>
                    <Button
                      style={{
                        marginTop: "20px",
                        marginBottom: "40px",
                        marginLeft: "8px",
                      }}
                      variant="contained"
                      color="primary"
                      onClick={this.triggerSaveProfile}
                    >
                      Zapisz
                    </Button>
                  </ThemeProvider>
                </Grid>
              </ThemeProvider>
            </CardBody>
          </Card>
        </Grid>

        <Dialog
          open={openDialog}
          onClose={this.handleCloseDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Aktualizacja profilu użytkownika..."}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Następujące dane zostaną zaktualizowane:
            </DialogContentText>
            <ThemeProvider theme={materialTheme}>
              {phoneFlag ? phoneInfo : ""}
              {idNumberFlag ? idNumberInfo : ""}
              {idValidDateFlag ? idValidDateInfo : ""}
              {addrCityFlag ? addrCityInfo : ""}
              {addrStreetFlag ? addrStreetInfo : ""}
              {addrPostalCodeFlag ? addrPostalCodeInfo : ""}
              {addrCountryFlag ? addrCountryInfo : ""}
              {!phoneFlag &&
                !idNumberFlag &&
                !addrCityFlag &&
                !addrStreetFlag &&
                !addrPostalCodeFlag &&
                !addrCountryFlag
                ? nothingInfo
                : ""}
            </ThemeProvider>
          </DialogContent>
          <DialogActions>
            <ThemeProvider theme={materialTheme}>
              <Button onClick={this.handleCloseDialog} color="primary">
                Anuluj
              </Button>
              {!phoneFlag &&
                !idNumberFlag &&
                !idValidDateFlag &&
                !addrCityFlag &&
                !addrStreetFlag &&
                !addrPostalCodeFlag &&
                !addrCountryFlag
                ? saveButtonDi
                : saveButtonEn}
            </ThemeProvider>
          </DialogActions>
        </Dialog>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  userFull: state.common.userFull,
  manager: state.common.suser,
});

export default connect(
  mapStateToProps,
  { getUserDetails, patchProfileEm }
)(withStyles(styles, { withTheme: true })(ProfileCardE));
