import React from "react";

const control = (start, stop) => {
  var ctrl = (Math.floor(Math.abs(stop - start) / 1000) >= 46800) ? 'CP' : '';

  var date_start = start.toISOString().substring(0, 10);
  var date_stop = stop.toISOString().substring(0, 10);
  if (date_start != date_stop) ctrl = 'ZM';

  return ctrl;
}

export const secToTime = (seconds) => {
  var h = Math.floor(seconds / 3600);
  var m = Math.floor((seconds % 3600) / 60);
  var s = Math.round(Math.floor(seconds % 60) / 60);
  //var s = Math.floor(seconds % 60);

  m = m + s;

  if (h < 10) h = '0' + h;
  if (m < 10) m = '0' + m;
  //if (s < 10) s = '0' + s;

  var str = h + ":" + m;
  //var str = h + ":" + m + ":" + s;

  return str;
}

export const onlyToday = (remoteMyLog) => {
  var today = [];
  var now = new Date().toISOString().substring(0, 10);

  remoteMyLog.map((singlelog) => {
    var date_start = new Date(singlelog.logStart);
    var date_wot = date_start.toISOString().substring(0, 10);

    var date_stop = new Date();
    if (singlelog.logStop != null) date_stop = new Date(singlelog.logStop);
    var ctrl = control(date_start, date_stop);

    if (now == date_wot) {
      today.push({ 'pk': singlelog.pk, 'status': singlelog.status, 'logStart': singlelog.logStart, 'logStop': singlelog.logStop, 'ctrl': ctrl });
    }
  });

  return today;
}
export const inMonth = (remoteMyLog, month) => {
  var month_list = [];

  remoteMyLog.map((singlelog) => {
    var date_start = new Date(singlelog.logStart);
    var date_wot = date_start.toISOString().substring(0, 7);

    var date_stop = new Date();
    if (singlelog.logStop != null) date_stop = new Date(singlelog.logStop);

    var ctrl = control(date_start, date_stop);

    if (month == date_wot) {
      month_list.push({ 'pk': singlelog.pk, 'status': singlelog.status, 'logStart': singlelog.logStart, 'logStop': singlelog.logStop, 'ctrl': ctrl, 'latitude': singlelog.latitude, 'longitude': singlelog.longitude });
    }
  });

  return month_list;
}

export const calcTime = (remoteMyLog, month_flag) => {
  var time = [];
  var timestamps = [];
  var worktime = {};
  var worktime_ctrl = {};
  var worktime_normalized = [];

  remoteMyLog.map((singlelog) => {
    var date = new Date(singlelog.logStart);
    //month //day //start //stop
    time.push([date.toISOString().substring(0, 7), date.toISOString().substring(0, 10), date, singlelog.logStop]);
  });

  if (time[0] != undefined) {
    if (time[0][3] == null) {
      var now = new Date();
      time[0][3] = now;
    }
    else {
      time[0][3] = new Date(time[0][3]);
    }
  }

  for (var i = 1; i < time.length; i++) {
    time[i][3] = new Date(time[i][3]);
  }

  for (var i = 0; i < time.length; i++) {
    var start = time[i][2].toISOString().substring(0, 10);
    var stop = time[i][3].toISOString().substring(0, 10);

    if (start == stop) {
      var sec = Math.floor(Math.abs(time[i][3] - time[i][2]) / 1000);
      timestamps.push([start, sec, 0]);
    }
    else {
      var prev = new Date(start);
      var next = new Date(stop);
      next.setDate(next.getDate() - 1);
      if (prev.toISOString() == next.toISOString()) {
        var midnight = new Date(time[i][3]);
        midnight.setHours(0, 0, 0, 0);

        var sec1 = Math.floor(Math.abs(time[i][3] - midnight) / 1000);
        var sec2 = Math.floor(Math.abs(midnight - time[i][2]) / 1000);

        timestamps.push([start, sec2, 1]);
        timestamps.push([stop, sec1, 1]);
      }
    }
  }

  if (month_flag == 1) {
    for (var i = 0; i < timestamps.length; i++) {
      var date = timestamps[i][0];
      var month = date.slice(0, -3);
      var time = timestamps[i][1];
      var ctrl = timestamps[i][2];

      if (!worktime.hasOwnProperty(month)) {
        worktime[month] = 0;
        worktime_ctrl[month] = 0;
      }

      worktime[month] += time;
      worktime_ctrl[month] += ctrl;
    }
  } else {
    for (var i = 0; i < timestamps.length; i++) {
      var date = timestamps[i][0];
      var time = timestamps[i][1];
      var ctrl = timestamps[i][2];

      if (!worktime.hasOwnProperty(date)) {
        worktime[date] = 0;
        worktime_ctrl[date] = 0;
      }

      worktime[date] += time;
      worktime_ctrl[date] += ctrl;
    }
  }

  for (var key in worktime) {
    if (worktime_ctrl[key] > 0) worktime_ctrl[key] = 'ZM';
    else if (month_flag != 1 && worktime[key] >= 46800) worktime_ctrl[key] = 'CP';
    else if (month_flag == 1 && worktime[key] >= 936000) worktime_ctrl[key] = 'CP';
    else worktime_ctrl[key] = '';

    worktime_normalized.push([key, secToTime(worktime[key]), worktime_ctrl[key]]);
  }

  return worktime_normalized;
}