import React, { Component, Fragment } from "react";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { lighten } from "@material-ui/core/styles";
import { Grid, ThemeProvider, createMuiTheme } from "@material-ui/core";
import { getInventoryByStatus, clearInventory, clearEmpInventories } from "../../Redux/actions/assets";
import InventoryList from "./InventoryList";
import Typography from "@material-ui/core/Typography";
import { TextField, MenuItem } from '@material-ui/core';
import Paper from "@material-ui/core/Paper";


const materialTheme = createMuiTheme({
  palette: {
    primary: {
      main: "#0071BC",
    },
    secondary: {
      main: "#0071BC"
    }
  },
  overrides: {
    MuiChip: {
      root: {
        color: "#ffffff",
        "& $avatar": {
          color: "#ffffff",
        },
      },
      icon: {
        color: "#ffffff",
      },
    },
    MuiCardActions: {
      root: {
        background: "#aaaaaa",
      },
    },
  },
});

const styles = theme => ({
  root: {
    width: "100%"
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2)
  },
  table: {
    minWidth: 750
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1
  },
  checkedIcon: {
    width: "20px",
    height: "20px",
    border: "1px solid rgba(0, 0, 0, 0.84)",
    borderRadius: "3px"
  },
  uncheckedIcon: {
    width: "0px",
    height: "0px",
    padding: "9px",
    border: "1px solid rgba(0, 0, 0, .54)",
    borderRadius: "3px"
  },
  checked: {
    color: "#0071BC"
  },
  checkRoot: {
    padding: "14px",
    "&:hover": {
      backgroundColor: "unset"
    }
  },
  roottb: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1)
  },
  highlighttb:
    theme.palette.type === "light"
      ? {
        color: "#0071BC",
        backgroundColor: lighten("#338dc9", 0.85)
      }
      : {
        color: "#0071BC",
        backgroundColor: "#004f83"
      },
  titletb: {
    flex: "1 1 100%",
    textAlign: "left"
  }
});

export class MyInventoryViewer extends Component {
  state = {
    filter: '1',
  };

  static propTypes = {
    auth: PropTypes.object.isRequired,
    getInventoryByStatus: PropTypes.func.isRequired,
    clearInventory: PropTypes.func.isRequired,
    inventoryIP: PropTypes.any.isRequired,
    inventoryRE: PropTypes.any.isRequired,
    inventoryMA: PropTypes.any.isRequired,
    inventoryFI: PropTypes.any.isRequired,
    queryInProgressInventory: PropTypes.bool.isRequired,
    clearEmpInventories: PropTypes.func.isRequired,
  };

  componentDidMount() {
    this.props.getInventoryByStatus('ip');
    this.props.getInventoryByStatus('re');
    this.props.getInventoryByStatus('ma');
    this.props.getInventoryByStatus('fi');
  }

  componentWillUnmount() {
    this.props.clearInventory();
    this.props.clearEmpInventories();
  }

  handleFilterChange = (event) => {
    const newFilter = event.target.value;
    this.setState({ filter: newFilter });
  };

  render() {
    const { classes, queryInProgressInventory, inventoryIP, inventoryRE, inventoryMA, inventoryFI } = this.props;
    const { filter } = this.state;

    var combinedInventory = [];
    if (filter === 'all') {
      combinedInventory = inventoryIP.concat(inventoryRE, inventoryMA, inventoryFI);
    }

    return (
      <Fragment>
        <Grid container justify="center">
          <ThemeProvider theme={materialTheme}>

            <Grid item xs={12} sm={12} md={12} style={{ padding: '16px 12px' }}>
              <Paper className={classes.paper} style={{ margin: '0' }}>
                <Grid container style={{ padding: "0 24px" }}>
                  <Grid item xs={12} sm={6} md={6} style={{ display: 'flex', justifyContent: 'flex-start' }}>
                    <Typography variant="h5" style={{ padding: "0", margin: "30px 0" }}>
                      Lista Inwentur
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                    <TextField
                      select
                      label="Status"
                      value={filter}
                      onChange={this.handleFilterChange}
                      variant="outlined"
                      style={{ width: '175px', margin: '10px 0' }}
                    >
                      <MenuItem value="all">Wszystkie</MenuItem>
                      <MenuItem value="0">U pracownika</MenuItem>
                      <MenuItem value="1">U opiekunów biznesowch</MenuItem>
                      <MenuItem value="2">W kontrolingu</MenuItem>
                      <MenuItem value="3">Zakończone</MenuItem>
                    </TextField>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>


            {filter === 'all' && <InventoryList inventory={combinedInventory} queryInProgressInventory={queryInProgressInventory} all={true} />}
            {filter === '0' && <InventoryList inventory={inventoryIP} queryInProgressInventory={queryInProgressInventory} />}
            {filter === '1' && <InventoryList inventory={inventoryRE} queryInProgressInventory={queryInProgressInventory} />}
            {filter === '2' && <InventoryList inventory={inventoryMA} queryInProgressInventory={queryInProgressInventory} />}
            {filter === '3' && <InventoryList inventory={inventoryFI} queryInProgressInventory={queryInProgressInventory} />}
          </ThemeProvider>
        </Grid >
      </Fragment >
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  inventoryIP: state.assets.inventoryIP,
  inventoryRE: state.assets.inventoryRE,
  inventoryMA: state.assets.inventoryMA,
  inventoryFI: state.assets.inventoryFI,
  queryInProgressInventory: state.assets.queryInProgress,
});

export default connect(
  mapStateToProps,
  { getInventoryByStatus, clearInventory, clearEmpInventories }
)(withStyles(styles, { withTheme: true })(MyInventoryViewer));
