import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import MyRemoteView from "./EmployeeView/MyRemoteView.js";
import MainManagerView from "./ManagerView/MainManagerView.js";
import ManagerLogView from "./ManagerView/ManagerLogView.js";
import { connect } from "react-redux";
import { TabPanel } from "../common/TabPanel.js";

export class RemoteViewer extends Component {
  state = {
    value: 0,
  };

  static propTypes = {
    auth: PropTypes.object.isRequired,
  };

  handleChange = (event, newValue) => {
    this.setState({
      value: newValue,
    });
  };

  render() {
    const { value } = this.state;
    const { assets_emptab } = this.props.auth.user.permission;
    const { employee, manager, accounting } = this.props.auth.user.profile;

    var subViewsConfig;

    var Tab0vis = employee ? true : false;
    var Tab1vis = manager && accounting ? true : false;
    var Tab2vis = manager && accounting && assets_emptab ? true : false;

    subViewsConfig = (
      <Fragment>
        <Tabs
          value={value}
          onChange={this.handleChange}
          aria-label="simple tabs example"
        >
          {Tab0vis ? <Tab label="Moje" /> : ""}
          {Tab1vis ? <Tab label="Pracownicy" /> : ""}
          {Tab2vis ? <Tab label="Log" /> : ""}
        </Tabs>
        <TabPanel value={value} index={0}>
          {Tab0vis ? <MyRemoteView /> : ""}
        </TabPanel>
        <TabPanel value={value} index={1}>
          {Tab1vis ? <MainManagerView /> : ""}
        </TabPanel>
        <TabPanel value={value} index={2}>
          {Tab2vis ? <ManagerLogView /> : ""}
        </TabPanel>
      </Fragment>
    );

    return <div>{subViewsConfig}</div>;
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(
  mapStateToProps,
  {}
)(RemoteViewer);
