import React, { Component, Fragment } from "react";
import Holidays from "./Holidays";
import Grid from "@material-ui/core/Grid";
import "react-infinite-calendar/styles.css"; // Make sure to import the default stylesheet
import { withStyles } from "@material-ui/core/styles";
import { createMuiTheme } from "@material-ui/core";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import DateFnsUtils from "@date-io/date-fns";
import { pl } from "date-fns/locale";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from "@material-ui/pickers";
import { ThemeProvider } from "@material-ui/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";

import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  getLeaveConfig,
  getMyLeavePool,
  getMyLeave,
  clearMyLeave,
  addLeave
} from "../Redux/actions/holidays";
import {
  getEmployeeSecureDetails,
  getMyManagerDetails,
  sendNotification,
  sendNewNotification
} from "../Redux/actions/employee";

const styles = theme => ({
  root: {
    flexGrow: 1,
    overflow: "hidden",
    padding: theme.spacing(0, 3)
  },
  title: {
    fontSize: 12,
    fontFamily: "Lato, Open Sans, sans-serif",
    color: "#909090",
    fontWeight: "300",
    textAlign: "right",
    paddingRight: "10px"
  },
  title2: {
    flexGrow: 1
  },
  content: {
    fontSize: 14,
    fontFamily: "Lato, Open Sans, sans-serif",
    fontWeight: "300",
    textAlign: "left",
    paddingLeft: "10px"
  },
  appBar: {
    top: "auto",
    bottom: 0,
    background: "#0071BC"
  },
  grow: {
    flexGrow: 1
  },
  fabButton: {
    position: "absolute",
    zIndex: 1,
    top: -30,
    left: 0,
    right: 0,
    margin: "0 auto"
  }
});

const materialTheme = createMuiTheme({
  overrides: {
    MuiFormControl: {
      root: {
        width: 300,
        marginTop: 50
      },
      marginNormal: {
        marginTop: "50px"
      }
    },
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: "#0071BC"
      }
    },
    MuiPickersDay: {
      day: {
        color: "#0071BC"
      },
      daySelected: {
        backgroundColor: "#0071BC",
        "&:hover": {
          backgroundColor: "#0071BC"
        }
      },

      dayDisabled: {
        color: "gray"
      },
      current: {
        color: "#0071BC"
      }
    },
    MuiFormLabel: {
      root: {
        "&.Mui-focused": {
          color: "#0071BC"
        }
      }
    },
    MuiInput: {
      underline: {
        "&:after": {
          borderBottomColor: "#0071bc"
        }
      }
    },
    MuiButton: {
      root: {},
      textPrimary: {
        color: "#0071BC"
      },
      containedPrimary: {
        backgroundColor: "#1BAB7F",
        "&:hover": {
          backgroundColor: "#188E6A"
        }
      }
    }
  }
});

function getBusinessDays(startDate, endDate, holidaysDays) {
  let count = 0;
  let currentDate = startDate;
  let holiday = false;

  while (endDate >= currentDate) {
    const dayOfWeek = currentDate.getDay();
    //sprawdza czy data nie jest na liście świąt, jeżeli jest to ustawia flagę
    holidaysDays.forEach(function (item) {
      if (
        currentDate.getFullYear() +
        "-" +
        currentDate.getMonth() +
        "-" +
        currentDate.getDate() ===
        item.getFullYear() + "-" + item.getMonth() + "-" + item.getDate()
      ) {
        holiday = true;
      }
    });
    //inkrementuje licznik tylko wtedy kiedy nie sobota i nie niedziela
    if (!(dayOfWeek === 6 || dayOfWeek === 0) && !holiday) count++;
    currentDate.setDate(currentDate.getDate() + 1);
    holiday = false;
  }
  return count;
}

//initial state
const initialState = {
  //leave
  leaveDays: [],
  holidaysDays: [new Date(2019, 11, 25), new Date(2019, 11, 26)],
  dates: [],
  holidaysStartDate: null,
  holidaysStartDateFormatted: null,
  holidaysEndDate: null,
  holidaysEndDateFormatted: null,
  leaveType: "",
  dialogOpen: false,
  dialogREExhaust: false,
  remarks: "",
  owner: "",
  ownersManager: "",
  employee: "",
  leaveTypeErrorFlag: false,
  holidaysStartDateErrorFlag: false,
  holidaysEndDateErrorFlag: false,
  //leaveSummary
  totalCountReUsed: 0,
  totalCountRePlanned: 0,
  totalCountOn: 0,
  totalCountSi: 0,
  totalCountUn: 0,
  totalCountPa: 0,
  totalCountSp: 0,
  totalCountCa: 0,
  //leavePool
  onDemandPool: "",
  restLeavePool: "",
  restLeavePoolOverdue: "",
  careLeavePool: ""
};

class Employee extends Component {
  constructor(props) {
    super(props);
    this.state = initialState;
  }

  static propTypes = {
    holidays: PropTypes.object.isRequired,
    getLeaveConfig: PropTypes.func.isRequired,
    getMyLeave: PropTypes.func.isRequired,
    clearMyLeave: PropTypes.func.isRequired,
    getMyLeavePool: PropTypes.func.isRequired,
    addLeave: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    employeeSecureDetails: PropTypes.object.isRequired,
    getEmployeeSecureDetails: PropTypes.func.isRequired,
    myManagerDetails: PropTypes.object.isRequired,
    getMyManagerDetails: PropTypes.func.isRequired,
    sendNotification: PropTypes.func.isRequired,
    sendNewNotification: PropTypes.func.isRequired
  };

  componentDidMount() {
    //tutaj pobierz HolidaysPool
    const today = new Date();
    this.props.getLeaveConfig(today.getFullYear());
    this.props.getMyLeave(today.getFullYear());
    this.props.getMyLeavePool(today.getFullYear());
    this.props.getEmployeeSecureDetails(this.props.auth.user.employees[0]);
  }

  componentDidUpdate(prevProps) {
    //pobranie danych o managerze
    if (this.props.employeeSecureDetails !== prevProps.employeeSecureDetails) {
      this.props.getMyManagerDetails(this.props.employeeSecureDetails.manager);
    }
    //wykrycie pobrania danych o managerze i inicjalizacja zmiennych owner i ownersManager
    if (this.props.myManagerDetails !== prevProps.myManagerDetails) {
      this.setState({
        owner: this.props.auth.user.id,
        ownersManager: this.props.myManagerDetails.owner,
        employee: this.props.auth.user.employees[0]
      });
    }
    //wykrycie pobrania leaveConfig i wyliczenie dat do zablokowania
    if (
      prevProps.holidays.isGettingConfig === true &&
      this.props.holidays.isGettingConfig === false
    ) {
      var datesToBeBlocked = [];
      this.props.holidays.leaveConfig[0].holidays.forEach(element => {
        datesToBeBlocked = [...datesToBeBlocked, new Date(element)];
      });
      this.setState({
        holidaysDays: datesToBeBlocked
      });
    }
    //wykrycie pobrania leavePool i inicjalizacja state
    if (
      this.props.holidays.leavePool[0] !== undefined &&
      this.state.restLeavePool === ""
    ) {
      this.setState({
        onDemandPool: this.props.holidays.leavePool[0].onDemandPool,
        restLeavePool: this.props.holidays.leavePool[0].restLeavePool,
        restLeavePoolOverdue: this.props.holidays.leavePool[0]
          .restLeavePoolOverdue,
        careLeavePool: this.props.holidays.leavePool[0].careLeavePool
      });
    }

    if (this.props.holidays.leave.length !== prevProps.holidays.leave.length) {
      const leave = this.props.holidays.leave;
      var totalCountPa = 0;
      var totalCountUn = 0;
      var totalCountSp = 0;
      var totalCountSi = 0;
      var totalCountReUsed = 0;
      var totalCountRePlanned = 0;
      var totalCountOn = 0;
      var totalCountCa = 0;
      const today = new Date();
      var todayFormatted =
        today.getFullYear() +
        "-" +
        (today.getMonth() + 1) +
        "-" +
        today.getDate();

      leave.forEach(function (item, index) {
        if (
          item.leaveType === "PA" &&
          todayFormatted >= item.endDate &&
          item.status === "ZA"
        ) {
          totalCountPa = Number(totalCountPa) + Number(item.daysCount);
        } else if (
          item.leaveType === "UN" &&
          todayFormatted >= item.endDate &&
          item.status === "ZA"
        ) {
          totalCountUn = Number(totalCountUn) + Number(item.daysCount);
        } else if (
          item.leaveType === "SP" &&
          todayFormatted >= item.endDate &&
          item.status === "ZA"
        ) {
          totalCountSp = Number(totalCountSp) + Number(item.daysCount);
        } else if (
          item.leaveType === "SI" &&
          todayFormatted >= item.endDate &&
          item.status === "ZA"
        ) {
          totalCountSi = Number(totalCountSi) + Number(item.daysCount);
        } else if (
          item.leaveType === "CA" &&
          todayFormatted >= item.endDate &&
          item.status === "ZA"
        ) {
          totalCountCa = Number(totalCountCa) + Number(item.daysCount);
        } else if (
          item.leaveType === "ON" &&
          todayFormatted >= item.endDate &&
          item.status === "ZA"
        ) {
          totalCountOn = Number(totalCountOn) + Number(item.daysCount);
          totalCountReUsed = Number(totalCountReUsed) + Number(item.daysCount);
          totalCountRePlanned =
            Number(totalCountRePlanned) + Number(item.daysCount);
        } else if (item.leaveType === "ON") {
          totalCountRePlanned =
            Number(totalCountRePlanned) + Number(item.daysCount);
        } else if (
          item.leaveType === "RE" &&
          todayFormatted >= item.endDate &&
          item.status === "ZA"
        ) {
          totalCountReUsed = Number(totalCountReUsed) + Number(item.daysCount);
          totalCountRePlanned =
            Number(totalCountRePlanned) + Number(item.daysCount);
        } else if (item.leaveType === "RE") {
          totalCountRePlanned =
            Number(totalCountRePlanned) + Number(item.daysCount);
        }
      });

      this.setState({
        totalCountPa: totalCountPa,
        totalCountUn: totalCountUn,
        totalCountSp: totalCountSp,
        totalCountSi: totalCountSi,
        totalCountReUsed: totalCountReUsed,
        totalCountRePlanned: totalCountRePlanned,
        totalCountOn: totalCountOn,
        totalCountCa: totalCountCa
      });
    }
  }

  componentWillUnmount() {
    this.props.clearMyLeave();
  }

  disableDates = date => {
    //to trzeba zaczytać ze state a samą inicjalizacje tej tablicy zrobić w didMount lub gdzieś na początku ładowania strony
    const datesToBeBlocked = this.state.holidaysDays;
    var idx1 = -1;
    datesToBeBlocked.forEach(function (item, index) {
      if (date.getTime() === item.getTime()) {
        idx1 = index;
      }
    });
    return idx1 !== -1 || date.getDay() === 0 || date.getDay() === 6;
  };

  sprawdzFormularz = (value, label) => {
    switch (label) {
      case "leaveType":
        if (value === "") {
          this.setState({
            leaveTypeErrorFlag: true
          });
          return true;
        } else {
          this.setState({
            leaveTypeErrorFlag: false
          });
          return false;
        }
      case "holidaysStartDate":
        if (value === null || isNaN(value)) {
          this.setState({
            holidaysStartDateErrorFlag: true
          });
          return true;
        } else {
          this.setState({
            holidaysStartDateErrorFlag: false
          });
          return false;
        }
      case "holidaysEndDate":
        if (value === null || isNaN(value)) {
          this.setState({
            holidaysEndDateErrorFlag: true
          });
          return true;
        } else {
          this.setState({
            holidaysEndDateErrorFlag: false
          });
          return false;
        }
      default:
        return false;
    }
  };

  handleTextList = name => ({ target: { value } }) => {
    this.setState({
      ...this.state,
      [name]: value
    });
  };

  handleClickOpenDialog = () => {
    this.setState({
      dialogOpen: true
    });
  };

  handleClickCloseDialog = () => {
    this.setState({
      dialogOpen: false,
      holidaysStartDate: null,
      holidaysStartDateFormatted: null,
      holidaysEndDate: null,
      holidaysEndDateFormatted: null,
      leaveType: "",
      dialogREExhaust: false,
      remarks: "",
      leaveTypeErrorFlag: false,
      holidaysStartDateErrorFlag: false,
      holidaysEndDateErrorFlag: false
    });
  };

  handleClickCloseDialogREExhaust = () => {
    this.setState({
      dialogOpen: false,
      dialogREExhaust: false,
      holidaysStartDate: null,
      holidaysStartDateFormatted: null,
      holidaysEndDate: null,
      holidaysEndDateFormatted: null,
      leaveType: "",
      remarks: ""
    });
  };

  handleStartDate = date => {
    if (date !== null) {
      const dd = date.getDate();
      const mm = date.getMonth() + 1;
      const yyyy = date.getFullYear();
      const fullDate = yyyy + "-" + mm + "-" + dd;
      this.setState({
        holidaysStartDate: date,
        holidaysStartDateFormatted: fullDate
      });
    } else {
      this.setState({
        holidaysStartDate: null,
        holidaysStartDateFormatted: null
      });
    }
  };

  handleEndDate = date => {
    if (date !== null) {
      const dd = date.getDate();
      const mm = date.getMonth() + 1;
      const yyyy = date.getFullYear();
      const fullDate = yyyy + "-" + mm + "-" + dd;
      this.setState({
        holidaysEndDate: date,
        holidaysEndDateFormatted: fullDate
      });
    } else {
      this.setState({
        holidaysEndDate: null,
        holidaysEndDateFormatted: null
      });
    }
  };

  handleCreateLeave = () => {
    const leaveType = this.state.leaveType;
    const holidaysStartDate = this.state.holidaysStartDate;
    const holidaysStartDateFormatted = this.state.holidaysStartDateFormatted;
    const holidaysEndDate = this.state.holidaysEndDate;
    const holidaysEndDateFormatted = this.state.holidaysEndDateFormatted;
    const totalCountRePlanned = this.state.totalCountRePlanned;
    const totalCountCa = this.state.totalCountCa;
    const totalCountOn = this.state.totalCountOn;
    const remarks = this.state.remarks;
    const owner = this.state.owner;
    const ownersManager = this.state.ownersManager;
    const employee = this.state.employee;
    const holidaysDays = this.state.holidaysDays;
    const restLeavePool = this.state.restLeavePool;
    const onDemandPool = this.state.onDemandPool;
    const restLeavePoolOverdue = this.state.restLeavePoolOverdue;
    const careLeavePool = this.state.careLeavePool;

    //walidacja pól dat
    if (holidaysStartDateFormatted === null && holidaysStartDate !== null) {
      const dd = holidaysStartDate.getDate();
      const mm = holidaysStartDate.getMonth() + 1;
      const yyyy = holidaysStartDate.getFullYear();
      const fullDate = yyyy + "-" + mm + "-" + dd;
      this.setState({
        holidaysStartDateFormatted: fullDate
      });
    }
    if (holidaysEndDateFormatted === null && holidaysEndDate !== null) {
      const dd = holidaysEndDate.getDate();
      const mm = holidaysEndDate.getMonth() + 1;
      const yyyy = holidaysEndDate.getFullYear();
      const fullDate = yyyy + "-" + mm + "-" + dd;
      this.setState({
        holidaysEndDateFormatted: fullDate
      });
    }

    //walidacja formularza
    const leaveTypeErrorFlag = this.sprawdzFormularz(leaveType, "leaveType");
    const holidaysStartDateErrorFlag = this.sprawdzFormularz(
      holidaysStartDate,
      "holidaysStartDate"
    );
    const holidaysEndDateErrorFlag = this.sprawdzFormularz(
      holidaysEndDate,
      "holidaysEndDate"
    );

    if (
      leaveTypeErrorFlag === true ||
      holidaysStartDateErrorFlag === true ||
      holidaysEndDateErrorFlag === true
    ) {
    } else {
      //wylicz liczbę dni pracujących
      const startDate = new Date(holidaysStartDate.getTime());
      const endDate = new Date(holidaysEndDate.getTime());
      const numOfBusinessDays = getBusinessDays(
        startDate,
        endDate,
        holidaysDays
      );
      //sprawdza czy ma wystarczająco dużo dni urlopu
      if (
        leaveType === "RE" &&
        Number(totalCountRePlanned) + Number(numOfBusinessDays) >
        Number(restLeavePool) + Number(restLeavePoolOverdue)
      ) {
        this.setState({
          dialogREExhaust: true
        });
      } else if (
        leaveType === "CA" &&
        Number(totalCountCa) + Number(numOfBusinessDays) > Number(careLeavePool)
      ) {
        this.setState({
          dialogREExhaust: true
        });
      } else if (
        leaveType === "ON" &&
        (Number(totalCountRePlanned) + Number(numOfBusinessDays) >
          Number(restLeavePool) + Number(restLeavePoolOverdue) ||
          Number(totalCountOn) + Number(numOfBusinessDays) >
          Number(onDemandPool))
      ) {
        this.setState({
          dialogREExhaust: true
        });
      } else {
        //przygotuj body
        var body;
        var smsmessage;
        if (leaveType === "RE") {
          body = {
            startDate: holidaysStartDateFormatted,
            endDate: holidaysEndDateFormatted,
            daysCount: numOfBusinessDays,
            leaveType: leaveType,
            status: "CA",
            remarks: remarks,
            owner: owner,
            employee: employee,
            ownersManager: ownersManager
          };
          smsmessage =
            "Uzytkownik " +
            this.props.auth.user.username +
            " wystawil wniosek o urlop WYPOCZYNKOWY. Wniosek czeka na twoja akceptacje.";
        } else if (leaveType === "ON") {
          body = {
            startDate: holidaysStartDateFormatted,
            endDate: holidaysEndDateFormatted,
            daysCount: numOfBusinessDays,
            leaveType: leaveType,
            status: "ZA",
            remarks: remarks,
            owner: owner,
            employee: employee,
            ownersManager: ownersManager
          };
          smsmessage =
            "Uzytkownik " +
            this.props.auth.user.username +
            " wystawil wniosek o urlop NA ZADANIE. Wniosek nie wymaga twojej akceptacji.";
        } else if (leaveType === "SP") {
          body = {
            startDate: holidaysStartDateFormatted,
            endDate: holidaysEndDateFormatted,
            daysCount: numOfBusinessDays,
            leaveType: leaveType,
            status: "ZA",
            waitForDoc: "true",
            remarks: remarks,
            owner: owner,
            employee: employee,
            ownersManager: ownersManager
          };
          smsmessage =
            "Uzytkownik " +
            this.props.auth.user.username +
            " wystawil wniosek o urlop OKOLICZNOSCIOWY. Wniosek nie wymaga twojej akceptacji. Pracownik jest zobowiazany do dostarczenia dokumentu potwierdzajacego uprawnienie do urlopu.";
        }

        this.props.addLeave(body);

        this.setState({
          holidaysStartDate: null,
          holidaysStartDateFormatted: null,
          holidaysEndDate: null,
          holidaysEndDateFormatted: null,
          leaveType: "",
          dialogOpen: false,
          dialogREExhaust: false,
          remarks: "",
          leaveTypeErrorFlag: false,
          holidaysStartDateErrorFlag: false,
          holidaysEndDateErrorFlag: false
        });
        //wysyłanie SMSa
        const recipient = "48501600544";
        const sender = "URLOPY";
        const notificationType = "sms";
        const isDelivered = true;
        //przygotowanie body
        const smsbody = {
          recipient,
          sender,
          message: smsmessage,
          owner,
          notificationType,
          isDelivered
        };
        this.props.sendNotification(smsbody);
      }
    }
  };

  render() {
    const {
      holidaysStartDate,
      holidaysEndDate,
      leaveType,
      dialogOpen,
      dialogREExhaust,
      remarks,
      leaveTypeErrorFlag,
      holidaysStartDateErrorFlag,
      holidaysEndDateErrorFlag,
      restLeavePool,
      restLeavePoolOverdue,
      careLeavePool,
      totalCountReUsed,
      totalCountRePlanned,
      totalCountOn,
      totalCountSi,
      totalCountUn,
      totalCountPa,
      totalCountSp
    } = this.state;

    const { classes, employeeSecureDetails } = this.props;

    //deklaracja zmiennych dla kalendarza
    const today = new Date();

    //definicja listy dostępnych urlopów do wyboru
    var typUrlopu;
    if (careLeavePool === 0) {
      typUrlopu = (
        <Fragment>
          <Select
            name="leaveType"
            value={leaveType}
            onChange={this.handleTextList("leaveType")}
          >
            <MenuItem value="RE">Wypoczynkowy</MenuItem>
            <MenuItem value="ON">Na żądanie</MenuItem>
            <MenuItem value="SP">Okolicznościowy</MenuItem>
          </Select>
        </Fragment>
      );
    } else {
      typUrlopu = (
        <Fragment>
          <Select
            name="leaveType"
            value={leaveType}
            onChange={this.handleTextList("leaveType")}
          >
            <MenuItem value="RE">Wypoczynkowy</MenuItem>
            <MenuItem value="ON">Na żądanie</MenuItem>
            <MenuItem value="SP">Okolicznościowy</MenuItem>
            <MenuItem value="CA">Opieka nad dzieckiem</MenuItem>
          </Select>
        </Fragment>
      );
    }

    return (
      <Fragment>
        <Container maxWidth="sm">
          <h1>Podsumowanie urlopów {today.getFullYear()}</h1>
        </Container>
        <div className={classes.root}>
          <Grid container justify="center" alignItems="center">
            <Grid item xs={5}>
              <Typography className={classes.title}>Pracownik:</Typography>
            </Grid>
            <Grid item xs={5}>
              <Typography className={classes.content}>
                {employeeSecureDetails.owner.first_name +
                  " " +
                  employeeSecureDetails.owner.last_name}
              </Typography>
            </Grid>
            <Grid item xs={5}>
              <Typography className={classes.title}>Wymiar urlopu</Typography>
            </Grid>
            <Grid item xs={5}>
              <Typography className={classes.content}></Typography>
            </Grid>
            <Grid item xs={5}>
              <Typography className={classes.title}></Typography>
            </Grid>
            <Grid item xs={5}>
              <Typography className={classes.content}>
                {restLeavePool + " dni"} ({today.getFullYear()})
              </Typography>
            </Grid>
            <Grid item xs={5}>
              <Typography className={classes.title}></Typography>
            </Grid>
            <Grid item xs={5}>
              <Typography className={classes.content}>
                {restLeavePoolOverdue + " dni"} (zaległy)
              </Typography>
            </Grid>
            <Grid item xs={5}>
              <Typography className={classes.title}></Typography>
            </Grid>
            <Grid item xs={5}>
              <Typography className={classes.content}>
                {careLeavePool + " dni"} (Opieka)
              </Typography>
            </Grid>
            <Grid item xs={5}>
              <Typography className={classes.title}>Wykorzystano</Typography>
            </Grid>
            <Grid item xs={5}>
              <Typography className={classes.content}></Typography>
            </Grid>
            <Grid item xs={5}>
              <Typography className={classes.title}></Typography>
            </Grid>
            <Grid item xs={5}>
              <Typography className={classes.content}>
                {totalCountReUsed + " dni"}
              </Typography>
            </Grid>
            <Grid item xs={5}>
              <Typography className={classes.title}></Typography>
            </Grid>
            <Grid item xs={5}>
              <Typography className={classes.content}>
                {totalCountOn + " dni"} (w tym urlop NŻ)
              </Typography>
            </Grid>
            <Grid item xs={5}>
              <Typography className={classes.title}>Zaplanowano</Typography>
            </Grid>
            <Grid item xs={5}>
              <Typography className={classes.content}>
                {totalCountRePlanned + " dni"}
              </Typography>
            </Grid>
            <Grid item xs={5}>
              <Typography className={classes.title}>
                POZOSTAŁE URLOPY (wykorzystano):
              </Typography>
            </Grid>
            <Grid item xs={5}>
              <Typography className={classes.content}></Typography>
            </Grid>
            <Grid item xs={5}></Grid>
            <Grid item xs={5}>
              <Typography className={classes.content}>
                {totalCountPa + " dni (płatny)"}
              </Typography>
            </Grid>
            <Grid item xs={5}></Grid>
            <Grid item xs={5}>
              <Typography className={classes.content}>
                {totalCountUn + " dni (bezpłatny)"}
              </Typography>
            </Grid>
            <Grid item xs={5}></Grid>
            <Grid item xs={5}>
              <Typography className={classes.content}>
                {totalCountSi + " dni (chorobowy)"}
              </Typography>
            </Grid>
            <Grid item xs={5}></Grid>
            <Grid item xs={5}>
              <Typography className={classes.content}>
                {totalCountSp + " dni (okolicznościowy)"}
              </Typography>
            </Grid>
          </Grid>
        </div>
        <div style={{ marginBottom: 30 }}></div>
        <div className={classes.root}>
          <Grid
            container
            wrap="nowrap"
            spacing={2}
            justify="center"
            alignItems="center"
          ></Grid>
        </div>
        <Container>
          <h1>Wnioski o urlop</h1>
          <Holidays status="CA" />
          <Holidays status="ZA" />
          <Holidays status="OD" />
          <div style={{ marginBottom: 70 }}></div>
        </Container>
        <AppBar position="fixed" color="primary" className={classes.appBar}>
          <Toolbar>
            <Fab
              aria-label="add"
              className={classes.fabButton}
              color="secondary"
              onClick={this.handleClickOpenDialog}
            >
              <AddIcon />
            </Fab>
          </Toolbar>
        </AppBar>
        <Dialog
          fullScreen
          open={dialogOpen}
          onClose={this.handleClickCloseDialog}
          aria-labelledby="form-dialog-title"
        >
          <AppBar position="static" style={{ background: "#0071BC" }}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={this.handleClickCloseDialog}
                aria-label="close"
                className={classes.menuButton}
              >
                <CloseIcon />
              </IconButton>
              <Typography variant="h6" className={classes.title2}>
                Wystaw urlop
              </Typography>
            </Toolbar>
          </AppBar>
          <DialogContent>
            <form
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center"
              }}
            >
              <MuiPickersUtilsProvider
                utils={DateFnsUtils}
                locale={pl}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center"
                }}
              >
                <ThemeProvider theme={materialTheme}>
                  <KeyboardDatePicker
                    error={holidaysStartDateErrorFlag}
                    className={classes.textField}
                    margin="normal"
                    label="Od"
                    format="yyyy/MM/dd"
                    minDate={new Date()}
                    value={holidaysStartDate}
                    onChange={this.handleStartDate}
                    shouldDisableDate={this.disableDates}
                  />
                </ThemeProvider>
                <ThemeProvider theme={materialTheme}>
                  <KeyboardDatePicker
                    error={holidaysEndDateErrorFlag}
                    className={classes.textField}
                    margin="normal"
                    label="Do"
                    format="yyyy/MM/dd"
                    emptyLabel=""
                    minDate={new Date()}
                    value={holidaysEndDate}
                    onChange={this.handleEndDate}
                    shouldDisableDate={this.disableDates}
                  />
                </ThemeProvider>
              </MuiPickersUtilsProvider>
              <div style={{ marginTop: 16 }}></div>
              <ThemeProvider theme={materialTheme}>
                <FormControl required>
                  <InputLabel error={leaveTypeErrorFlag} htmlFor="leaveType">
                    Rodzaj urlopu
                  </InputLabel>
                  {typUrlopu}
                </FormControl>
              </ThemeProvider>
              <ThemeProvider theme={materialTheme}>
                <TextField
                  multiline
                  rowsMax="3"
                  label="Komentarz"
                  inputProps={{ maxLength: 100 }}
                  margin="normal"
                  value={remarks}
                  onChange={this.handleTextList("remarks")}
                />
              </ThemeProvider>
              <div style={{ marginTop: 30 }}></div>
              <ThemeProvider theme={materialTheme}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={this.handleCreateLeave}
                >
                  Utwórz wniosek
                </Button>
              </ThemeProvider>
            </form>
            <div style={{ marginTop: 50 }}></div>
          </DialogContent>
        </Dialog>

        <Dialog
          open={dialogREExhaust}
          onClose={this.handleClickCloseDialogREExhaust}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {
                "Planowany urlop przekracza przysługujący Ci limit. Nie mogę wystawić wniosku!"
              }
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={this.handleClickCloseDialogREExhaust}
              color="primary"
              autoFocus
            >
              Rozumiem
            </Button>
          </DialogActions>
        </Dialog>
      </Fragment>
    );
  }
}

//https://stackoverflow.com/questions/49491569/disable-specific-days-in-material-ui-calendar-in-react

const mapStateToProps = state => ({
  holidays: state.holidays,
  auth: state.auth,
  employeeSecureDetails: state.employee.employeeSecureDetails,
  myManagerDetails: state.employee.myManagerDetails
});

export default connect(
  mapStateToProps,
  {
    getLeaveConfig,
    getMyLeavePool,
    getMyLeave,
    clearMyLeave,
    addLeave,
    getEmployeeSecureDetails,
    getMyManagerDetails,
    sendNotification,
    sendNewNotification
  }
)(withStyles(styles, { withTheme: true })(Employee));
