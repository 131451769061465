import React, { Component, Fragment } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import Card from "../components/Card/Card.js";
import CardBody from "../components/Card/CardBody.js";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { createMuiTheme } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";
import { changeUserPassword } from "../Redux/actions/auth";

import PropTypes from "prop-types";
import { connect } from "react-redux";

import TextField from "@material-ui/core/TextField";

const materialTheme = createMuiTheme({
  palette: {
    primary: {
      main: "#0071BC",
    },
  },
  overrides: {
    MuiChip: {
      root: {
        color: "#ffffff",
        "& $avatar": {
          color: "#ffffff",
        },
      },
      icon: {
        color: "#ffffff",
      },
    },
    MuiCardActions: {
      root: {
        background: "#aaaaaa",
      },
    },
    MuiIconButton: {
      root: {
        color: "#fff",
      },
    },
  },
});

const styles = (theme) => ({
  avatar: {
    backgroundColor: "#0071BC",
    height: 50,
    width: 50,
  },
  Address: {
    textAlign: "left",
    fontFamily: "Lato, Open Sans, sans-serif",
    fontSize: "1em",
    fontWeight: "300",
    color: "#444444",
  },
  Name: {
    textAlign: "left",
    fontFamily: "Lato, Open Sans, sans-serif",
    fontSize: "1em",
    fontWeight: "700",
    color: "#444444",
  },
  ProfileBar: {
    textAlign: "left",
    fontFamily: "Lato, Open Sans, sans-serif",
    fontSize: "1em",
    fontWeight: "400",
    color: "#444444",
  },
  Country: {
    textAlign: "left",
    fontFamily: "Lato, Open Sans, sans-serif",
    fontSize: "1em",
    fontWeight: "300",
    color: "#444444",
    textTransform: "uppercase",
  },
  large: {
    width: "70px",
    height: "70px",
  },
});

export class PasswordChangeCard extends Component {
  state = {
    currentPassword: null,
    newPassword: null,
    newPasswordConfirmed: null,
    currentPasswordErrorFlag: false,
    newPasswordErrorFlag: false,
    newPasswordConfirmedErrorFlag: false,
    currentPasswordMessage: "",
    newPasswordMessage: "",
    newPasswordConfirmedMessage: "",
  };

  static propTypes = {
    changeUserPassword: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired,
  };

  componentDidMount() { }

  componentWillReceiveProps(nextProps) { }

  handleTextList = (name) => ({ target: { value } }) => {
    this.setState({
      ...this.state,
      [name]: value,
    });
  };

  sprawdzTextList = (value, label) => {
    //place, remarks, client, country
    switch (label) {
      case "currentPassword":
        if (value === "" || value === null) {
          this.setState({
            currentPasswordErrorFlag: true,
            currentPasswordMessage: "Podaj swoje aktualne hasło.",
          });
          return true;
        } else {
          this.setState({
            currentPasswordErrorFlag: false,
            currentPasswordMessage: "",
          });
          return false;
        }
      case "newPassword":
        if (
          /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/.test(
            value
          ) === false
        ) {
          this.setState({
            newPasswordErrorFlag: true,
            newPasswordMessage:
              "Hasło musi zawierać: 1) przynajmniej jedną małą literę [a-z], 2) przynajmniej jedną wielką literę [A-Z], 3) przynajmniej jedną cyfrę [0-9], 4) przynajmniej jeden znak specjalny #?!@$%^&*- 5) przynajmniej 8 znaków",
          });
          return true;
        } else {
          this.setState({
            newPasswordErrorFlag: false,
            newPasswordMessage: "",
          });
          return false;
        }
      default:
        return false;
    }
  };

  sprawdzZgodnoscHasel = (newPassword, newPasswordConfirmed) => {
    if (newPassword === newPasswordConfirmed) {
      this.setState({
        newPasswordConfirmedErrorFlag: false,
        newPasswordConfirmedMessage: "",
      });
      return false;
    } else {
      this.setState({
        newPasswordConfirmedErrorFlag: true,
        newPasswordConfirmedMessage:
          "Potwierdzone hasło nie jest zgodne z podanym wyżej nowym hasłem",
      });
      return true;
    }
  };

  handleChangePassword = () => {
    const { currentPassword, newPassword, newPasswordConfirmed } = this.state;
    //walidacja pól aktualne hasło i nowe hasło
    const currentPasswordErrorFlag = this.sprawdzTextList(
      currentPassword,
      "currentPassword"
    );
    const newPasswordErrorFlag = this.sprawdzTextList(
      newPassword,
      "newPassword"
    );

    //sprawdzenie zgodności newPassword i newPassword2
    const newPasswordConfirmedErrorFlag = this.sprawdzZgodnoscHasel(
      newPassword,
      newPasswordConfirmed
    );
    //zapis na bazę
    if (
      currentPasswordErrorFlag === true ||
      newPasswordErrorFlag === true ||
      newPasswordConfirmedErrorFlag === true
    ) {
    } else {
      //przygotuj body
      const changePasswordBody = {
        old_password: currentPassword,
        new_password: newPassword,
      };

      //przygotuj pk usera
      this.props.changeUserPassword(changePasswordBody, this.props.user.id);
      this.setState({
        currentPassword: null,
        newPassword: null,
        newPasswordConfirmed: null,
        currentPasswordErrorFlag: false,
        newPasswordErrorFlag: false,
        newPasswordConfirmedErrorFlag: false,
        currentPasswordMessage: "",
        newPasswordMessage: "",
        newPasswordConfirmedMessage: "",
      });
    }
  };

  render() {
    const { classes } = this.props;
    const {
      currentPassword,
      newPassword,
      newPasswordConfirmed,
      currentPasswordMessage,
      newPasswordMessage,
      newPasswordConfirmedMessage,
    } = this.state;

    const passwordChangeButEn = (
      <Button
        style={{
          marginTop: "20px",
          marginBottom: "40px",
          marginLeft: "8px",
        }}
        variant="contained"
        color="primary"
        onClick={this.handleChangePassword}
      >
        Zmień
      </Button>
    );

    const passwordChangeButDi = (
      <Button
        style={{
          marginTop: "20px",
          marginBottom: "40px",
          marginLeft: "8px",
        }}
        variant="contained"
        color="primary"
        disabled
      >
        Zmień
      </Button>
    );

    return (
      <Fragment>
        <Grid item xs={12} sm={12} md={6} lg={4}></Grid>
        <Grid item xs={12} sm={12} md={6} lg={8}>
          <Card style={{ marginBottom: "0px" }}>
            <CardBody>
              <ThemeProvider theme={materialTheme}>
                <Grid
                  container
                  justify="space-between"
                  alignItems="center"
                  spacing={2}
                >
                  <Grid item xs={7} sm={10} md={6} lg={12}>
                    <Typography className={classes.Name}>
                      Zmiana hasła
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={4} md={4} lg={6}>
                    <TextField
                      label="wprowadź aktualne hasło"
                      type="password"
                      id="outlined-margin-normal"
                      value={currentPassword || ""}
                      fullWidth
                      className={classes.textField}
                      onChange={this.handleTextList("currentPassword")}
                      helperText={currentPasswordMessage}
                      margin="normal"
                      variant="filled"
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} md={4} lg={6}></Grid>
                  <Grid item xs={12} sm={8} md={8} lg={6}>
                    <TextField
                      label="wprowadź nowe hasło"
                      type="password"
                      id="outlined-margin-normal"
                      value={newPassword || ""}
                      fullWidth
                      className={classes.textField}
                      onChange={this.handleTextList("newPassword")}
                      helperText={newPasswordMessage}
                      margin="normal"
                      variant="filled"
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} md={4} lg={6}></Grid>
                  <Grid item xs={12} sm={4} md={4} lg={6}>
                    <TextField
                      label="potwierdź nowe hasło"
                      type="password"
                      id="outlined-margin-normal"
                      value={newPasswordConfirmed || ""}
                      fullWidth
                      className={classes.textField}
                      onChange={this.handleTextList("newPasswordConfirmed")}
                      helperText={newPasswordConfirmedMessage}
                      margin="normal"
                      variant="filled"
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} md={4} lg={6}></Grid>
                  {currentPassword && newPassword && newPasswordConfirmed
                    ? passwordChangeButEn
                    : passwordChangeButDi}
                </Grid>
              </ThemeProvider>
            </CardBody>
          </Card>
        </Grid>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(
  mapStateToProps,
  { changeUserPassword }
)(withStyles(styles, { withTheme: true })(PasswordChangeCard));
