import axios from "axios";
import { createMessages, returnErrors } from "./messages";
import { tokenConfig } from "./auth";
import { serverurl, getDatabaseUrl } from "./databaseServerURL";

import {
  GET_LEAVE_CONFIG_INPROGRESS,
  GET_LEAVE_CONFIG_SUCCESS,
  GET_MYLEAVE_POOL,
  GET_MYLEAVE,
  CLEAR_MYLEAVE,
  GET_MYLEAVE_DETAILS,
  ADD_LEAVE,
  DELETE_LEAVE
} from "./types";

//GET LEAVE CONFIG
export const getLeaveConfig = year => (dispatch, getState) => {
  dispatch({ type: GET_LEAVE_CONFIG_INPROGRESS });
  axios
    .get(serverurl + getDatabaseUrl() + `/leaveconfig/?year=${year}`, tokenConfig(getState))
    .then(res => {
      dispatch({
        type: GET_LEAVE_CONFIG_SUCCESS,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch(returnErrors(err.response.data.detail, err.response.status))
    );
};

//GET MY LEAVE POOL
export const getMyLeavePool = year => (dispatch, getState) => {
  axios
    .get(serverurl + getDatabaseUrl() + `/leavepool/?year=${year}`, tokenConfig(getState))
    .then(res => {
      dispatch({
        type: GET_MYLEAVE_POOL,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch(returnErrors(err.response.data.detail, err.response.status))
    );
};

//GET MYLEAVE
export const getMyLeave = year => (dispatch, getState) => {
  axios
    .get(serverurl + getDatabaseUrl() + `/leave/?year=${year}`, tokenConfig(getState))
    .then(res => {
      dispatch({
        type: GET_MYLEAVE,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch(returnErrors(err.response.data.detail, err.response.status))
    );
};

//CLEAR MYLEAVE
export const clearMyLeave = () => (dispatch, getState) => {
  dispatch({ type: CLEAR_MYLEAVE });
};

//GET MYLEAVE DETAILS
export const getMyLeaveDetails = pk => (dispatch, getState) => {
  axios
    .get(serverurl + getDatabaseUrl() + `/leave/${pk}`, tokenConfig(getState))
    .then(res => {
      dispatch({
        type: GET_MYLEAVE_DETAILS,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch(returnErrors(err.response.data.detail, err.response.status))
    );
};

//ADD LEAVE
export const addLeave = leave => (dispatch, getState) => {
  axios
    .post(serverurl + getDatabaseUrl() + "/leave/", leave, tokenConfig(getState))
    .then(res => {
      dispatch(createMessages({ addLeave: "Wniosek został wystawiony" }));
      dispatch({
        type: ADD_LEAVE,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch(returnErrors(err.response.data.detail, err.response.status))
    );
};

//DELETE LEAVE
export const deleteLeave = id => (dispatch, getState) => {
  axios
    .delete(serverurl + getDatabaseUrl() + `/leave/${id}`, tokenConfig(getState))
    .then(res => {
      dispatch(createMessages({ deleteLeave: "Wniosek został usunięty" }));
      dispatch({
        type: DELETE_LEAVE,
        payload: id
      });
    })
    .catch(err =>
      dispatch(returnErrors(err.response.data.detail, err.response.status))
    );
};
