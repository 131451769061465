import React, { Component, Fragment } from "react";
import { withAlert } from "react-alert";
import { connect } from "react-redux";
import PropTypes from "prop-types";

export class Alerts extends Component {
  static propTypes = {
    error: PropTypes.object.isRequired,
    message: PropTypes.object.isRequired,
  };

  componentDidUpdate(prevProps) {
    const { alert, message } = this.props;
    if (message !== prevProps.message) {
      //Przeniesione na nowy typ - Alerts2.js

      //if (message.deleteTrip) alert.success(message.deleteTrip);
      //if (message.addTrip) alert.success(message.addTrip);
      //if (message.patchTrip) alert.success(message.patchTrip);
      //if (message.patchEmployeeTrip) alert.success(message.patchEmployeeTrip);
      //if (message.patchAccountingTrip) alert.success(message.patchAccountingTrip);
      //if (message.patchEmployee) alert.success(message.patchEmployee);
      //if (message.changeUserPassword) alert.success(message.changeUserPassword);
      //if (message.passwordsNotMatch) alert.error(message.passwordsNotMatch);
      //if (message.addTripComment) alert.success(message.addTripComment);
      //if (message.addTimestamp) alert.success(message.addTimestamp);
      //if (message.deleteTimestamp) alert.success(message.deleteTimestamp);
      //if (message.addLeave) alert.success(message.addLeave);
      //if (message.deleteLeave) alert.success(message.deleteLeave);
      //if (message.patchEmployeeLeave) alert.success(message.patchEmployeeLeave);
      //if (message.addEmployeeLeave) alert.success(message.addEmployeeLeave);
      //if (message.sendNotification) alert.success(message.sendNotification);
      //if (message.patchPurchase) alert.success(message.patchPurchase);
      //if (message.patchProfile2) alert.success(message.patchProfile2);
      //if (message.patchProfileEm) alert.success(message.patchProfileEm);
      //if (message.addPurchase) alert.success(message.addPurchase);
      //if (message.patchEmployeeAsset) alert.success(message.patchEmployeeAsset);
    }
  }

  render() {
    return <Fragment />;
  }
}

const mapStateToProps = (state) => ({
  error: state.errors,
  message: state.messages,
});

export default connect(mapStateToProps)(withAlert()(Alerts));
