export function correctDateFormat(date) {
  if (date != Date) date = new Date(date);
  var day = ("0" + date.getDate()).slice(-2);
  var month = ("0" + (date.getMonth() + 1)).slice(-2);
  var year = date.getFullYear();
  date = day + "." + month + "." + year;
  return date;
}

export function correctDateFormat2(date) {
  if (date != Date) date = new Date(date);
  var day = ("0" + date.getDate()).slice(-2);
  var month = ("0" + (date.getMonth() + 1)).slice(-2);
  var year = date.getFullYear();
  date = year + "-" + month + "-" + day;
  return date;
}