import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Chip from '@material-ui/core/Chip';

const styles = (theme) => ({
    left: {
        justifyContent: 'left',
    },
    chip: {
        margin: theme.spacing(0.5, 0.5, 0.5, 0)
    },
});

export class DocumentListItemChips extends Component {
  state = {
    companies: [],
  };

  setCompanies = (companies) => {
    this.setState({
        companies: companies,
    });
    this.props.companies(companies);
  };

  render() {
    const { companies, classes } = this.props;
    return (
      <div className={classes.left}>
        {companies.map((company, index) => (
            <Chip
                className={classes.chip}
                key={company.pk}
                label={company.name}
            />
        ))}
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(DocumentListItemChips);
