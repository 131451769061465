import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import WorkIcon from "@material-ui/icons/Work";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
//integracja z reduxem
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { deleteTrip } from "../Redux/actions/trips";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";

class Trips extends Component {
  state = {
    anchorEl: null,
    dialogDeleteOpen: false,
    deleteTripId: null
  };

  //integracja z reduxem
  static propTypes = {
    trips: PropTypes.array.isRequired,
    deleteTrip: PropTypes.func.isRequired
  };

  //
  getState() {
    return this.state;
  }

  handleOpenWarning = tripId => {
    this.setState({
      dialogDeleteOpen: true,
      deleteTripId: tripId
    });
  };

  handleCloseWarning = () => {
    this.setState({
      dialogDeleteOpen: false,
      deleteTripId: null
    });
  };

  handleDeleteTrip = () => {
    const TripToBeDeleted = this.state.deleteTripId;
    this.props.deleteTrip(TripToBeDeleted);
    this.setState({
      dialogDeleteOpen: false,
      deleteTripId: null
    });
  };

  handleClose = () => {
    this.setState({
      anchorEl: null
    });
  };

  handleClick = event => {
    this.setState({
      anchorEl: event.currentTarget
    });
  };

  render() {
    const { label, status, trips } = this.props;
    const { dialogDeleteOpen } = this.state;
    const TripsFiltered = [];

    const dialogDelete = (
      <Dialog
        open={dialogDeleteOpen}
        onClose={this.handleCloseWarning}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Wybrana delegacja zostanie skasowana."}
        </DialogTitle>
        <DialogActions>
          <Button onClick={this.handleCloseWarning} color="primary">
            Anuluj
          </Button>
          <Button onClick={this.handleDeleteTrip} color="primary" autoFocus>
            Skasuj
          </Button>
        </DialogActions>
      </Dialog>
    );

    trips.map(trip => {
      if (trip.tripStatus === status) {
        TripsFiltered.push(trip);
      }
    });

    //jeżeli label Nowe i Oczekujące na akceptację to renderuj...

    if (status === "NW") {
      return (
        <Fragment>
          <h1>{label}</h1>
          <List>
            {TripsFiltered.map(trip => (
              <ListItem key={trip.pk} component={Link} to={"/trips/my/" + trip.pk}>
                <ListItemAvatar>
                  <Avatar style={{ backgroundColor: "#1bab7f" }}>
                    <WorkIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={
                    trip.endDate
                      ? trip.startDate + " | " + trip.endDate
                      : trip.startDate + " | "
                  }
                  secondary={trip.place}
                />
                <ListItemSecondaryAction>
                  <IconButton
                    edge="end"
                    aria-label="delete"
                    //listId={trip.id}
                    onClick={this.handleOpenWarning.bind(this, trip.pk)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
          {dialogDelete}
        </Fragment>
      );
    } else if (status === "CA") {
      return (
        <Fragment>
          <h1>{label}</h1>
          <List>
            {TripsFiltered.map(trip => (
              <ListItem key={trip.pk} component={Link} to={"/trips/my/" + trip.pk}>
                <ListItemAvatar>
                  <Avatar style={{ backgroundColor: "#c2a33a" }}>
                    <WorkIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={
                    trip.endDate
                      ? trip.startDate + " | " + trip.endDate
                      : trip.startDate + " | "
                  }
                  secondary={trip.place}
                />
                <ListItemSecondaryAction>
                  <IconButton
                    edge="end"
                    aria-label="delete"
                    //listId={trip.id}
                    onClick={this.handleOpenWarning.bind(this, trip.pk)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
          {dialogDelete}
        </Fragment>
      );
    } else if (status === "ZA") {
      return (
        <Fragment>
          <h1>{label}</h1>
          <List>
            {TripsFiltered.map(trip => (
              <ListItem key={trip.pk} component={Link} to={"/trips/my/" + trip.pk}>
                <ListItemAvatar>
                  <Avatar style={{ backgroundColor: "#0071bc" }}>
                    <WorkIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={
                    trip.endDate
                      ? trip.startDate + " | " + trip.endDate
                      : trip.startDate + " | "
                  }
                  secondary={trip.place}
                />
              </ListItem>
            ))}
          </List>
        </Fragment>
      );
    } else if (status === "RZ") {
      return (
        <Fragment>
          <h1>{label}</h1>
          <List>
            {TripsFiltered.map(trip => (
              <ListItem key={trip.pk} component={Link} to={"/trips/my/" + trip.pk}>
                <ListItemAvatar>
                  <Avatar style={{ backgroundColor: "#b35340" }}>
                    <WorkIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={
                    trip.endDate
                      ? trip.startDate + " | " + trip.endDate
                      : trip.startDate + " | "
                  }
                  secondary={trip.place}
                />
              </ListItem>
            ))}
          </List>
        </Fragment>
      );
    } else {
      return (
        <Fragment>
          <h1>{label}</h1>
          <List>
            {TripsFiltered.map(trip => (
              <ListItem key={trip.pk} component={Link} to={"/trips/my/" + trip.pk}>
                <ListItemAvatar>
                  <Avatar style={{ backgroundColor: "#aaaaaa" }}>
                    <WorkIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={trip.startDate + " | " + trip.endDate}
                  secondary={trip.place}
                />
              </ListItem>
            ))}
          </List>
        </Fragment>
      );
    }

    //jeżeli label Zaakceptowane i Zamknięte to renderuj ...
  }
}

const mapStateToProps = state => ({
  trips: state.tripsReducer.trips
});

export default connect(mapStateToProps, { deleteTrip })(Trips);
