import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { connect } from "react-redux";
import { TabPanel } from "../common/TabPanel.js";
import MainPaczkiView from "./Paczki/MainPaczkiView.js";
import MainDochodyView from "./Dochody/MainDochodyView.js";
import MainSwiadczeniaOView from "./SwiadczeniaO/MainSwiadczeniaOView.js";
import SendViewer from "./Send/SendViewer.js";
import { getLastIncome, clearLastIncome } from "../Redux/actions/zfss.js";
import { MyViewer } from "./My/MyViewer.js";

export class ZFSS extends Component {
  state = {
    value: 0,
  };

  static propTypes = {
    auth: PropTypes.object.isRequired,
    getLastIncome: PropTypes.func.isRequired,
    clearLastIncome: PropTypes.func.isRequired,
    lastIncome: PropTypes.any.isRequired,
  };

  componentDidMount() {
    this.props.getLastIncome(this.props.auth.user.id);
  }

  componentWillUnmount() {
    this.props.clearLastIncome();
  }

  handleChange = (event, newValue) => {
    this.setState({
      value: newValue,
    });
  };

  render() {
    const { value } = this.state;
    const { permissions } = this.props.auth;

    var isManager = false;
    if (permissions != null && permissions.module_zfss_admin || this.props.auth.user.is_superuser) isManager = true;

    var valid = false;
    if (this.props.lastIncome[0] != undefined) {
      if (Date.parse(this.props.lastIncome[0].validTo) > Date.now()) valid = true;
    }
    if (this.props.placed) valid = true;

    var subViewsConfig;
    subViewsConfig = (
      <Fragment>
        <Tabs
          value={value}
          onChange={this.handleChange}
          aria-label="simple tabs example"
        >
          <Tab label="Oświadczenie o dochodach" />
          <Tab label="Paczka okolicznościowa" disabled={!valid} />
          <Tab label="Świadczenie okolicznościowe" disabled={!valid} />
          <Tab label="Moje dokumenty" />
          {isManager ? <Tab label="Przesłane dokumenty" /> : ""}
          {/*<Tab label="Multisport" disabled={true} />*/}
          {/*<Tab label="Bony" disabled={true} />*/}
          {/*<Tab label="Wypoczynek" disabled={true} />*/}
        </Tabs>
        <TabPanel value={value} index={0}>
          <MainDochodyView />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <MainPaczkiView />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <MainSwiadczeniaOView />
        </TabPanel>
        <TabPanel value={value} index={3}>
          <MyViewer />
        </TabPanel>
        {isManager ? <TabPanel value={value} index={4}>
          <SendViewer />
        </TabPanel> : ""}
        {/*<TabPanel value={value} index={2}>
          <MainMultisportView />
        </TabPanel>
        <TabPanel value={value} index={3}>
          <MainMultisportView />
        </TabPanel>
        <TabPanel value={value} index={4}>
          <MainMultisportView />
        </TabPanel>*/}
      </Fragment>
    );

    return <div>{subViewsConfig}</div>;
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  lastIncome: state.zfss.last,
  placed: state.zfss.placed,
});

export default connect(
  mapStateToProps,
  { getLastIncome, clearLastIncome }
)(ZFSS);
