import React, { Component, Fragment } from "react";
import Container from "@material-ui/core/Container";
import { ThemeProvider } from "@material-ui/styles";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import { createMuiTheme } from "@material-ui/core";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { register } from "../Redux/actions/auth";
import { Redirect } from "react-router-dom";
import { createMessages } from "../Redux/actions/messages";

const CssTextField = withStyles({
  root: {
    width: 300,
    flexWrap: "wrap",
    marginTop: "50px",
    marginLeft: "30px",
    marginRight: "30px",
    "& label.Mui-focused": {
      color: "#0071bc"
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#0071bc"
    }
  }
})(TextField);

const materialTheme = createMuiTheme({
  overrides: {
    MuiButton: {
      textPrimary: {
        color: "#0071BC"
      },
      containedPrimary: {
        backgroundColor: "#1BAB7F",
        "&:hover": {
          backgroundColor: "#188E6A"
        }
      }
    }
  }
});

export class Register extends Component {
  state = {
    username: "",
    email: "",
    password: "",
    confirmPassword: ""
  };

  static propTypes = {
    register: PropTypes.func.isRequired,
    isAuthenticated: PropTypes.bool
  };

  handleTextFieldChange = name => ({ target: { value } }) => {
    this.setState({
      ...this.state,
      [name]: value
    });
  };

  onSubmit = e => {
    e.preventDefault();
    const { username, email, password, confirmPassword } = this.state;
    if (password !== confirmPassword) {
      this.props.createMessages({
        passwordsNotMatch: "Hasła nie zgadzają się"
      });
    } else {
      const newUser = {
        username,
        password,
        email
      };
      this.props.register(newUser);
    }
  };

  render() {
    if (this.props.isAuthenticated) {
      return <Redirect to="/" />;
    }

    const { username, email, password, confirmPassword } = this.state;
    return (
      <Fragment>
        <Container maxWidth="sm">
          <h1>Wprowadź dane nowego użytkownika</h1>
        </Container>
        <Container
          maxWidth={false}
          style={{
            backgroundColor: "#eeeeee",
            marginTop: "50px",
            marginBottom: "50px"
          }}
        >
          <form
            onSubmit={this.onSubmit}
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            <CssTextField
              label="Login"
              value={username}
              inputProps={{ maxLength: 20 }}
              margin="normal"
              onChange={this.handleTextFieldChange("username")}
            />
            <CssTextField
              label="Email"
              value={email}
              inputProps={{ maxLength: 50 }}
              helperText="Podaj twój służbowy adres email."
              margin="normal"
              onChange={this.handleTextFieldChange("email")}
            />
            <CssTextField
              label="Hasło"
              value={password}
              type="password"
              inputProps={{ maxLength: 20 }}
              margin="normal"
              onChange={this.handleTextFieldChange("password")}
            />
            <CssTextField
              label="Powtórz hasło"
              value={confirmPassword}
              type="password"
              inputProps={{ maxLength: 20 }}
              margin="normal"
              onChange={this.handleTextFieldChange("confirmPassword")}
            />
            <div style={{ height: "80px" }}></div>
            <ThemeProvider theme={materialTheme}>
              <Button type="submit" variant="contained" color="primary">
                Utwórz użytkownika
              </Button>
            </ThemeProvider>
            <div style={{ height: "80px" }}></div>
          </form>
        </Container>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated
});

export default connect(
  mapStateToProps,
  { register, createMessages }
)(Register);
