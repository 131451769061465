import React, { Component, Fragment } from "react";
import IconButton from "@material-ui/core/IconButton";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Toolbar from "@material-ui/core/Toolbar";
import GetAppIcon from "@material-ui/icons/GetApp";
import CloseIcon from '@material-ui/icons/Close';
import CircularProgress from "@material-ui/core/CircularProgress";
import { lighten } from "@material-ui/core/styles";
import { Dialog, createMuiTheme } from "@material-ui/core";
import { docLand } from '../../PDFGen/DejaVuSansCondensed'
import { generate } from './InventoryDocument';
import DescriptionIcon from '@material-ui/icons/Description';
import SearchIcon from '@material-ui/icons/Search';
import VisibilityIcon from '@material-ui/icons/Visibility';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import InventoryManagerViewer from "./InventoryManagerViewer";
import InventoryControlerViewer from "./InventoryControlerViewer";
import DialogContent from "@material-ui/core/DialogContent";
import AppBar from "@material-ui/core/AppBar";
import Zoom from "@material-ui/core/Zoom";
import Fab from "@material-ui/core/Fab";
import { green } from "@material-ui/core/colors";
import { getInventoryAssets, clearInventoryAssets, getInventoryDetails, reportInventory, clearInventoryEmpAssets } from "../../Redux/actions/assets";
import PropTypes from "prop-types";
import InventoryEmployeeViewer from "./InventoryEmployeeViewer";
import { sendNewNotificationControler, sendNewNotificationNoMail } from "../../Redux/actions/employee";

const materialTheme = createMuiTheme({
  palette: {
    primary: {
      main: "#0071BC",
    },
    secondary: {
      main: "#0071BC"
    }
  },
  overrides: {
    MuiChip: {
      root: {
        color: "#ffffff",
        "& $avatar": {
          color: "#ffffff",
        },
      },
      icon: {
        color: "#ffffff",
      },
    },
    MuiCardActions: {
      root: {
        background: "#aaaaaa",
      },
    },
  },
});

const styles = theme => ({
  root: {
    width: "100%"
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2)
  },
  table: {
    minWidth: 750
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1
  },
  checkedIcon: {
    width: "20px",
    height: "20px",
    border: "1px solid rgba(0, 0, 0, 0.84)",
    borderRadius: "3px"
  },
  uncheckedIcon: {
    width: "0px",
    height: "0px",
    padding: "9px",
    border: "1px solid rgba(0, 0, 0, .54)",
    borderRadius: "3px"
  },
  checked: {
    color: "#0071BC"
  },
  checkRoot: {
    padding: "14px",
    "&:hover": {
      backgroundColor: "unset"
    }
  },
  roottb: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1)
  },
  highlighttb:
    theme.palette.type === "light"
      ? {
        color: "#0071BC",
        backgroundColor: lighten("#338dc9", 0.85)
      }
      : {
        color: "#0071BC",
        backgroundColor: "#004f83"
      },
  titletb: {
    flex: "1 1 100%",
    textAlign: "left"
  },
  fb1: {
    position: "fixed",
    bottom: theme.spacing(20),
    right: theme.spacing(5),
    zIndex: "100",
  },
  fb2: {
    position: "fixed",
    bottom: theme.spacing(2),
    right: theme.spacing(5),
    zIndex: "100",
  },
  fb3: {
    position: "fixed",
    bottom: theme.spacing(26),
    right: theme.spacing(5),
    zIndex: "100",
  },
  fb4: {
    position: "fixed",
    bottom: theme.spacing(32),
    right: theme.spacing(5),
    zIndex: "100",
  },
  fb5: {
    position: "fixed",
    bottom: theme.spacing(8),
    right: theme.spacing(5),
    zIndex: "100",
  },
  fb6: {
    position: "fixed",
    bottom: theme.spacing(14),
    right: theme.spacing(5),
    zIndex: "100",
  },
  fabgreen: {
    color: theme.palette.common.white,
    backgroundColor: "#4caf50",
    "&:hover": {
      backgroundColor: green[600],
    },
  },
  fabblue: {
    color: theme.palette.common.white,
    backgroundColor: "#2196f3",
    "&:hover": {
      backgroundColor: "#1976d2",
    },
  },
  fabred: {
    color: theme.palette.common.white,
    backgroundColor: "#dc004e",
    "&:hover": {
      backgroundColor: "#9a0036",
    },
  }
});

class InventoryList extends Component {
  state = {
    selectedIndex: null,
    viewDialogOpen: false,
    invRaportData: null,
  };

  static propTypes = {
    auth: PropTypes.object.isRequired,
    getInventoryAssets: PropTypes.func.isRequired,
    clearInventoryAssets: PropTypes.func.isRequired,
    queryRaportInProgress: PropTypes.bool.isRequired,
    queryRaportInProgress2: PropTypes.bool.isRequired,
    inventoryAssets: PropTypes.any.isRequired,
    getInventoryDetails: PropTypes.func.isRequired,
    sendNewNotificationControler: PropTypes.func.isRequired,
    sendNewNotificationNoMail: PropTypes.func.isRequired,
    reportInventory: PropTypes.func.isRequired,
    clearInventoryEmpAssets: PropTypes.func.isRequired,
  };

  componentDidUpdate(prevProps) {
    if ((prevProps.queryRaportInProgress && !this.props.queryRaportInProgress) || (prevProps.queryRaportInProgress2 && !this.props.queryRaportInProgress2)) {
      if (!this.props.queryRaportInProgress && !this.props.queryRaportInProgress2) {
        if (this.props.inventoryAssets != null) {
          var invRaportData = this.state.invRaportData;
          invRaportData.assets = this.props.inventoryAssets;

          if (this.props.inventoryDetails != null) {
            const invDetails = this.props.inventoryDetails;
            invRaportData.signedUser = invDetails.signedUser;
            invRaportData.signedAl = invDetails.signedAl;
            invRaportData.signedNa = invDetails.signedNa;
            invRaportData.signedEl = invDetails.signedEl;
            invRaportData.signedIt = invDetails.signedIt;
            invRaportData.signedPo = invDetails.signedPo;
            invRaportData.signedController = invDetails.signedController;

            if (invRaportData != null && invRaportData.assets != null && invRaportData.signedUser != null && invRaportData.signedAl != null && invRaportData.signedNa != null && invRaportData.signedEl != null && invRaportData.signedIt != null && invRaportData.signedPo != null && invRaportData.signedController != null) {
              generate(docLand, invRaportData);
              this.props.clearInventoryAssets();
            }
          }
        }
      }
    }
  }

  getState() {
    return this.state;
  }

  handleClick = (row, event) => {
    if (this.state.isInvSelected && this.state.selectedIndex == row.pk) {
      this.setState({
        selectedIndex: null,
        isInvSelected: false,
        selectedInv: null,
      })
    }
    else {
      this.setState({
        selectedIndex: row.pk,
        isInvSelected: true,
        selectedInv: row,
      })
    }
  };

  handleCloseClick = (event) => {
    this.setState({
      selectedIndex: null,
      isInvSelected: false,
      selectedInv: null,
    })
  };

  handleDownloadClick = async (event) => {
    const row = this.state.selectedInv;
    const data = {
      id: row.pk,
      inventoryStartDate: row.inventoryStartDate,
      inventoryEndDate: row.inventoryEndDate,
      inventoryUser: row.inventoryUser,
      signedUserDate: row.signedUserDate,
      signedAlDate: row.signedAlDate,
      signedNaDate: row.signedNaDate,
      signedElDate: row.signedElDate,
      signedItDate: row.signedItDate,
      signedPoDate: row.signedPoDate,
      signedControllerDate: row.signedControllerDate,
    }

    this.props.getInventoryDetails(row.pk);
    this.props.getInventoryAssets(row.pk);

    this.setState({
      invRaportData: data,
    });
  };

  openViewDialog = (event) => {
    this.setState({
      viewDialogOpen: true,
    })
  };

  handleReturnInv = (event) => {
    const invNumber = `IST/${new Date(this.state.selectedInv.inventoryStartDate).getFullYear()}/${String(this.state.selectedInv.pk).padStart(4, '0')}/${String(this.state.selectedInv.inventoryUser.pk).padStart(3, '0')}`;

    const notifyBody = {
      module: "Środki Trwałe",
      topic: "Pracownik poprosił o wycofanie inwentury do korekty",
      message:
        "Użytkownik " + this.props.auth.user.first_name + " " + this.props.auth.user.last_name +
        " poprosił o wycofanie inwentury nr. " + invNumber + " do korekty. " + "\n" +
        "Więcej informacji znajdziesz w module Środki Trwałe w EXTRANET."
    }

    this.props.sendNewNotificationControler(notifyBody);
  };

  handleReturnInvControler = (event) => {
    const invNumber = `IST/${new Date(this.state.selectedInv.inventoryStartDate).getFullYear()}/${String(this.state.selectedInv.pk).padStart(4, '0')}/${String(this.state.selectedInv.inventoryUser.pk).padStart(3, '0')}`;

    const data = {
      "status": "ip",
      "inventoryEndDate": new Date().toISOString(),
    }

    const notifyBody = {
      recipient: this.state.selectedInv.inventoryUser.pk,
      module: "Środki Trwałe",
      topic: "Twoja inwnentura została wycofana do poprawy",
      message:
        "Użytkownik " + this.props.auth.user.first_name + " " + this.props.auth.user.last_name +
        " wycofał twoją inwenturę nr. " + invNumber + " do korekty. " + "\n" +
        "Więcej informacji znajdziesz w module Środki Trwałe w EXTRANET."
    }

    this.props.reportInventory(this.state.selectedInv.pk, data);
    this.props.sendNewNotificationNoMail(notifyBody);
  };

  closeViewDialog = (event) => {
    this.setState({
      viewDialogOpen: false,
    })

    this.props.clearInventoryEmpAssets();
  }

  handleDownload = () => {
    var csvRow = [];
    var A = [
      [
        "Identyfikator",
        "Data rozpoczęcia",
        "Data ostatniej zmiany statusu",
        "Status",
      ]
    ];
    if (!this.props.user) {
      A[0].push("Pracownik");
    }
    var rows = this.props.inventory;

    if (rows.length > 0) {
      for (var i = 0; i < rows.length; i++) {
        A.push([
          `IST/${new Date(rows[i].inventoryStartDate).getFullYear()}/${String(rows[i].pk).padStart(4, '0')}/${String(rows[i].inventoryUser.pk).padStart(3, '0')}`,
          new Date(rows[i].inventoryStartDate).toISOString().split('T')[0],
          new Date(rows[i].inventoryEndDate).toISOString().split('T')[0],
          rows[i].status,
        ]);
        if (!this.props.user) {
          A[i + 1].push(rows[i].inventoryUser.first_name + " " + rows[i].inventoryUser.last_name);
        }
      }
    }

    for (var i = 0; i < A.length; ++i) {
      csvRow.push(A[i].join(";"));
    }

    var csvString = csvRow.join("\n");
    var link = window.document.createElement("a");
    link.setAttribute(
      "href",
      "data:text/csv;charset=utf-8,%EF%BB%BF" + encodeURI(csvString)
    );
    if (!this.props.user) {
      link.setAttribute("download", "inwentury.csv");
    }
    else {
      link.setAttribute("download", "moje_inwentury.csv");
    }
    link.click();
  };

  render() {
    const { classes, inventory, queryInProgressInventory, user, all } = this.props;
    const { selectedIndex, isInvSelected, selectedInv, viewDialogOpen } = this.state;
    var controler = false;
    if (isInvSelected) {
      if (this.props.auth.permissions.delete_inventorysnapshot || this.props.auth.user.is_superuser) {
        controler = true;
      }
    }
    const queryInProgress = <CircularProgress color="secondary" />;

    const downloadRaportEn = (
      <Zoom in={isInvSelected}>
        <div
          onClick={this.handleDownloadClick}
          role="presentation"
          className={classes.fb2}
        >
          <Tooltip title="Pobierz raport" placement="left">
            <Fab
              color="inherit"
              className={classes.fabblue}
              size="small"
              aria-label="scroll back to top"
            >
              <DescriptionIcon />
            </Fab>
          </Tooltip>
        </div>
      </Zoom>
    );

    const downloadRaportDis = (
      <Zoom in={isInvSelected}>
        <Tooltip title="Raport będzie dostępny po zakończeniu inwentury" placement="left">
          <div
            role="presentation"
            className={classes.fb2}
          >
            <Fab
              color="inherit"
              className={classes.fabblue}
              size="small"
              aria-label="scroll back to top"
              disabled={true}
            >
              <DescriptionIcon />
            </Fab>
          </div>
        </Tooltip>
      </Zoom>
    );

    const viewInvEn = (
      <Zoom in={isInvSelected}>
        <div
          onClick={this.openViewDialog}
          role="presentation"
          className={classes.fb6}
        >
          <Tooltip title="Wyświetl inwenturę" placement="left">
            <Fab
              color="inherit"
              className={classes.fabgreen}
              size="small"
              aria-label="scroll back to top"
            >
              <SearchIcon />
            </Fab>
          </Tooltip>
        </div>
      </Zoom>
    );
    const viewInvDis = (
      <Zoom in={isInvSelected}>
        <Tooltip title="Nie możesz wyświetlić tej inwentury" placement="left">
          <div
            role="presentation"
            className={classes.fb6}
          >
            <Fab
              color="inherit"
              className={classes.fabgreen}
              size="small"
              aria-label="scroll back to top"
              disabled={true}
            >
              <SearchIcon />
            </Fab>
          </div>
        </Tooltip>
      </Zoom>
    );

    const backInvEn = (
      <Zoom in={isInvSelected}>
        <div
          onClick={this.handleReturnInvControler}
          role="presentation"
          className={classes.fb5}
        >
          <Tooltip title="Wycofaj do korekty" placement="left">
            <Fab
              color="inherit"
              className={classes.fabred}
              size="small"
              aria-label="scroll back to top"
            >
              <RotateLeftIcon />
            </Fab>
          </Tooltip>
        </div>
      </Zoom>
    );
    const backInvDis = (
      <Zoom in={isInvSelected}>
        <Tooltip title="Nie możesz wycofać tej inwentury" placement="left">
          <div
            role="presentation"
            className={classes.fb5}
          >
            <Fab
              color="inherit"
              className={classes.fabred}
              size="small"
              aria-label="scroll back to top"
              disabled={true}
            >
              <RotateLeftIcon />
            </Fab>
          </div>
        </Tooltip>
      </Zoom>
    );

    const viewInvEmpEn = (
      <Zoom in={isInvSelected}>
        <div
          onClick={this.openViewDialog}
          role="presentation"
          className={classes.fb6}
        >
          <Tooltip title="Wyświetl inwenturę" placement="left">
            <Fab
              color="inherit"
              className={classes.fabblue}
              size="small"
              aria-label="scroll back to top"
            >
              <VisibilityIcon />
            </Fab>
          </Tooltip>
        </div>
      </Zoom>
    );
    const viewInvEmpDis = (
      <Zoom in={isInvSelected}>
        <Tooltip title="Nie możesz wyświetlić tej inwentury" placement="left">
          <div
            role="presentation"
            className={classes.fb6}
          >
            <Fab
              color="inherit"
              className={classes.fabblue}
              size="small"
              aria-label="scroll back to top"
              disabled={true}
            >
              <VisibilityIcon />
            </Fab>
          </div>
        </Tooltip>
      </Zoom>
    );
    const backInvEmpEn = (
      <Zoom in={isInvSelected}>
        <div
          onClick={this.handleReturnInv}
          role="presentation"
          className={classes.fb5}
        >
          <Tooltip title="Poproś o możliwość poprawienia inwentury" placement="left">
            <Fab
              color="inherit"
              className={classes.fabred}
              size="small"
              aria-label="scroll back to top"
            >
              <RotateLeftIcon />
            </Fab>
          </Tooltip>
        </div>
      </Zoom>
    );
    const backInvEmpDis = (
      <Zoom in={isInvSelected}>
        <Tooltip title="Nie możesz poprawić tej inwentury" placement="left">
          <div
            role="presentation"
            className={classes.fb5}
          >
            <Fab
              color="inherit"
              className={classes.fabred}
              size="small"
              aria-label="scroll back to top"
              disabled={true}
            >
              <RotateLeftIcon />
            </Fab>
          </div>
        </Tooltip>
      </Zoom>
    );

    const downloadData = (
      <Tooltip title="Eksportuj wszystkie dane">
        <IconButton
          aria-label="filter list"
          onClick={this.handleDownload}
        >
          <GetAppIcon />
        </IconButton>
      </Tooltip>
    );

    const managerView = (
      <InventoryManagerViewer inv={selectedIndex} invData={selectedInv != null ? selectedInv : null} closeViewDialog={this.closeViewDialog} />
    );

    const controlerView = (
      <InventoryControlerViewer inv={selectedIndex} invData={selectedInv != null ? selectedInv : null} closeViewDialog={this.closeViewDialog} />
    );

    if (user) {
      return (
        <Fragment>
          <Grid container justify="flex-start" spacing={2} style={{ width: "100%", padding: "1%" }}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Paper className={classes.paper}>
                <ThemeProvider theme={materialTheme}>
                  <Toolbar style={{ justifyContent: "space-between" }}>
                    <div>Inwentury</div>
                    {queryInProgressInventory ? queryInProgress : downloadData}
                  </Toolbar>
                  <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell>ID</TableCell>
                          <TableCell align="center">Data rozpoczęcia</TableCell>
                          <TableCell align="center">Data ostatniej zmiany statusu</TableCell>
                          <TableCell align="center">Status</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {inventory.length == 0 && !queryInProgressInventory ? (
                          <TableRow>
                            <TableCell colSpan={4} align="center">
                              Brak inwentur
                            </TableCell>
                          </TableRow>
                        ) : ""}
                        {queryInProgressInventory ? (
                          <TableRow>
                            <TableCell colSpan={4} align="center">
                              Pobieranie danych...
                            </TableCell>
                          </TableRow>
                        ) : ""}
                        {inventory.map((row) => (
                          <TableRow
                            hover
                            key={row.pk}
                            onClick={this.handleClick.bind(this, row)}
                            selected={row.pk == selectedIndex ? true : false}
                          >
                            <TableCell component="th" scope="row">{`IST/${new Date(row.inventoryStartDate).getFullYear()}/${String(row.pk).padStart(4, '0')}/${String(row.inventoryUser.pk).padStart(3, '0')}`}</TableCell>
                            <TableCell align="center">{(row.inventoryStartDate).split('T')[0]}</TableCell>
                            <TableCell align="center">{row.inventoryEndDate != null ? (row.inventoryEndDate).split('T')[0] : "Brak"}</TableCell>
                            <TableCell align="center">{row.status_description}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </ThemeProvider>
              </Paper>
            </Grid>
          </Grid>

          {isInvSelected && selectedInv.status != "ip" ? viewInvEmpEn : viewInvEmpDis}
          {isInvSelected && selectedInv.status != "ip" && selectedInv.status != "fi" ? backInvEmpEn : backInvEmpDis}
          {isInvSelected && selectedInv.status != "fi" ? downloadRaportDis : downloadRaportEn}

          <Dialog open={viewDialogOpen} onClose={this.closeViewDialog} fullScreen>
            <AppBar position="static" style={{ background: "#0071BC" }}>
              <Toolbar>
                <IconButton
                  edge="start"
                  color="inherit"
                  onClick={this.closeViewDialog}
                  aria-label="close"
                  className={classes.menuButton}
                >
                  <CloseIcon />
                </IconButton>
                <Typography variant="h6" className={classes.title}>
                  Podgląd inwentury {isInvSelected ? `IST/${new Date(selectedInv.inventoryStartDate).getFullYear()}/${String(selectedInv.pk).padStart(4, '0')}/${String(selectedInv.inventoryUser.pk).padStart(3, '0')}` : ""}
                </Typography>
              </Toolbar>
            </AppBar>
            <DialogContent>
              <InventoryEmployeeViewer inv={selectedIndex} invData={selectedInv != null ? selectedInv : null} closeViewDialog={this.closeViewDialog} />
            </DialogContent>
          </Dialog>
        </Fragment>
      );
    }
    else {
      const currentUser = this.props.auth.user.id;

      const sortedInventory = inventory.sort((a, b) => {
        if (a.inventoryEndDate && b.inventoryEndDate) {
          return new Date(b.inventoryEndDate) - new Date(a.inventoryEndDate);
        } else if (a.inventoryEndDate) {
          return -1;
        } else if (b.inventoryEndDate) {
          return 1;
        } else {
          return 0;
        }
      });

      const inventorySignedAfterEndDate = sortedInventory.filter(row =>
        (row.signedAl == currentUser && new Date(row.signedAlDate) >= new Date(row.inventoryEndDate)) ||
        (row.signedEl == currentUser && new Date(row.signedElDate) >= new Date(row.inventoryEndDate)) ||
        (row.signedNa == currentUser && new Date(row.signedNaDate) >= new Date(row.inventoryEndDate)) ||
        (row.signedIt == currentUser && new Date(row.signedItDate) >= new Date(row.inventoryEndDate)) ||
        (row.signedPo == currentUser && new Date(row.signedPoDate) >= new Date(row.inventoryEndDate))
      );

      const inventoryNotSignedAfterEndDate = sortedInventory.filter(row =>
        !(row.signedAl == currentUser && new Date(row.signedAlDate) >= new Date(row.inventoryEndDate)) &&
        !(row.signedEl == currentUser && new Date(row.signedElDate) >= new Date(row.inventoryEndDate)) &&
        !(row.signedNa == currentUser && new Date(row.signedNaDate) >= new Date(row.inventoryEndDate)) &&
        !(row.signedIt == currentUser && new Date(row.signedItDate) >= new Date(row.inventoryEndDate)) &&
        !(row.signedPo == currentUser && new Date(row.signedPoDate) >= new Date(row.inventoryEndDate))
      );

      return (
        <Fragment>
          <Grid container justify="flex-start" spacing={3} style={{ width: "100%" }}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Paper className={classes.paper}>
                <ThemeProvider theme={materialTheme}>
                  <Toolbar style={{ justifyContent: "space-between" }}>
                    <div></div>
                    {queryInProgressInventory ? queryInProgress : downloadData}
                  </Toolbar>
                  <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell>ID</TableCell>
                          <TableCell align="center">Data rozpoczęcia</TableCell>
                          <TableCell align="center">Data ostatniej zmiany statusu</TableCell>
                          {all ? <TableCell align="center">Status</TableCell> : ""}
                          <TableCell align="center">Pracownik</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {inventory.length == 0 && !queryInProgressInventory ? (
                          <TableRow>
                            <TableCell colSpan={4} align="center">
                              Brak inwentur
                            </TableCell>
                          </TableRow>
                        ) : ""}
                        {queryInProgressInventory ? (
                          <TableRow>
                            <TableCell colSpan={4} align="center">
                              Pobieranie danych...
                            </TableCell>
                          </TableRow>
                        ) : ""}
                        {inventoryNotSignedAfterEndDate.map((row) => (
                          <TableRow
                            hover
                            key={row.pk}
                            onClick={this.handleClick.bind(this, row)}
                            selected={row.pk == selectedIndex ? true : false}
                          >
                            <TableCell component="th" scope="row">{`IST/${new Date(row.inventoryStartDate).getFullYear()}/${String(row.pk).padStart(4, '0')}/${String(row.inventoryUser.pk).padStart(3, '0')}`}</TableCell>
                            <TableCell align="center">{(row.inventoryStartDate).split('T')[0]}</TableCell>
                            <TableCell align="center">{row.inventoryEndDate != null ? (row.inventoryEndDate).split('T')[0] : "Brak"}</TableCell>
                            {all ? <TableCell align="center">{row.status_description}</TableCell> : ""}
                            <TableCell align="center">{row.inventoryUser.first_name + " " + row.inventoryUser.last_name}</TableCell>
                          </TableRow>
                        ))}
                        <TableRow style={{ backgroundColor: '#edf7ed' }}><TableCell colspan={4} align="center" style={{ padding: "4px" }}>Podpisane</TableCell></TableRow>
                        {inventorySignedAfterEndDate.map((row) => (
                          <TableRow
                            hover
                            key={row.pk}
                            onClick={this.handleClick.bind(this, row)}
                            selected={row.pk == selectedIndex ? true : false}
                            style={{ backgroundColor: '#edf7ed' }}
                          >
                            <TableCell component="th" scope="row">{`IST/${new Date(row.inventoryStartDate).getFullYear()}/${String(row.pk).padStart(4, '0')}/${String(row.inventoryUser.pk).padStart(3, '0')}`}</TableCell>
                            <TableCell align="center">{(row.inventoryStartDate).split('T')[0]}</TableCell>
                            <TableCell align="center">{row.inventoryEndDate != null ? (row.inventoryEndDate).split('T')[0] : "Brak"}</TableCell>
                            {all ? <TableCell align="center">{row.status_description}</TableCell> : ""}
                            <TableCell align="center">{row.inventoryUser.first_name + " " + row.inventoryUser.last_name}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </ThemeProvider>
              </Paper>
            </Grid>
          </Grid>

          {isInvSelected && selectedInv.status != "ip" ? viewInvEn : viewInvDis}
          {isInvSelected && selectedInv.status != "fi" ? downloadRaportDis : downloadRaportEn}
          {controler ? (isInvSelected && selectedInv.status != "ip" && selectedInv.status != "fi" ? backInvEn : backInvDis) : null}

          <Dialog open={viewDialogOpen} onClose={this.closeViewDialog} fullScreen>
            <AppBar position="static" style={{ background: "#0071BC" }}>
              <Toolbar>
                <IconButton
                  edge="start"
                  color="inherit"
                  onClick={this.closeViewDialog}
                  aria-label="close"
                  className={classes.menuButton}
                >
                  <CloseIcon />
                </IconButton>
                <Typography variant="h6" className={classes.title}>
                  Podgląd inwentury {isInvSelected ? `IST/${new Date(selectedInv.inventoryStartDate).getFullYear()}/${String(selectedInv.pk).padStart(4, '0')}/${String(selectedInv.inventoryUser.pk).padStart(3, '0')} - ${String(selectedInv.inventoryUser.first_name)} ${String(selectedInv.inventoryUser.last_name)}` : ""}
                </Typography>
              </Toolbar>
            </AppBar>
            <DialogContent>
              {controler && selectedInv.status != "re" ? controlerView : managerView}
            </DialogContent>
          </Dialog>
        </Fragment>
      );
    }
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
  inventoryAssets: state.assets.inventoryAssets,
  inventoryDetails: state.assets.inventoryDetails,
  queryRaportInProgress: state.assets.raportQueryInProgress,
  queryRaportInProgress2: state.assets.raportQueryInProgress2,
});

export default connect(
  mapStateToProps,
  { getInventoryAssets, clearInventoryAssets, getInventoryDetails, sendNewNotificationControler, sendNewNotificationNoMail, reportInventory, clearInventoryEmpAssets }
)(withStyles(styles, { withTheme: true })(InventoryList));
