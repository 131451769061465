import { CREATE_MESSAGE, GET_ERRORS, CLEAR_ERRORS } from "./types";

//CREATE MESSAGE
export const createMessages = (msg) => {
  return {
    type: CREATE_MESSAGE,
    payload: msg,
  };
};

//RETURN ERRORS
export const returnErrors = (msg, status, type) => {
  return {
    type: GET_ERRORS,
    payload: { msg, status, type },
  };
};

//CLEAR ERRORS
export const clearErrors = () => (dispatch, getState) => {
  dispatch({ type: CLEAR_ERRORS });
};
