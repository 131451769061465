import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import { createMuiTheme } from "@material-ui/core";
import { lighten } from "@material-ui/core/styles";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import CircularProgress from "@material-ui/core/CircularProgress";

import Grid from "@material-ui/core/Grid";
import Card from "../../components/Card/Card.js";
import CardBody from "../../components/Card/CardBody.js";
import CardHeader from "@material-ui/core/CardHeader";

import { NothingToDisplay, WaitToDisplay } from "../Common/infoData.js";
import { calcTime, inMonth } from "../Common/calcTime.js";
import { getWeekdaysInMonth } from "../Common/calcDays.js";

const materialTheme = createMuiTheme({
  palette: {
    primary: {
      main: "#0071BC",
    },
  },
  overrides: {
    MuiChip: {
      root: {
        color: "#ffffff",
        "& $avatar": {
          color: "#ffffff",
        },
      },
      icon: {
        color: "#ffffff",
      },
    },
    MuiCardActions: {
      root: {
        background: "#aaaaaa",
      },
    },
    MuiIconButton: {
      root: {
        color: "#fff",
      },
    },
  },
});

const styles = (theme) => ({
  root: {
    width: "100%",
  },
  Name: {
    textAlign: "left",
    fontFamily: "Lato, Open Sans, sans-serif",
    fontSize: "1em",
    fontWeight: "700",
    color: "#444444",
  },
  Name2: {
    textAlign: "left",
    fontFamily: "Lato, Open Sans, sans-serif",
    fontSize: "1.2em",
    fontWeight: "700",
    color: "#444444",
  },
  Position: {
    textAlign: "left",
    fontFamily: "Lato, Open Sans, sans-serif",
    fontSize: "1em",
    fontWeight: "400",
    color: "#aaaaaa",
  },
  chipA: {
    background: "#81c784",
    color: "#ffffff",
  },
  chipN: {
    background: "#bbbbbb",
    color: "#ffffff",
  },
  header: {
    textAlign: "left",
    backgroundColor: lighten("#338dc9", 0.85),
  },
  avatar: {
    backgroundColor: "#0071bc",
  },
  headertitle: {
    fontFamily: "Lato, Open Sans, sans-serif",
    fontSize: "1.2em",
    fontWeight: "700",
    color: "#444444",
  },
  info: {
    fontFamily: "Lato, Open Sans, sans-serif",
    fontSize: "1.5em",
    fontWeight: "300",
    color: "#0071BC",
  },
  fabblue: {
    color: theme.palette.common.white,
    backgroundColor: "#2196f3",
    "&:hover": {
      backgroundColor: "#1976d2",
    },
  },
  fb1: {
    position: "fixed",
    bottom: theme.spacing(8),
    right: theme.spacing(5),
  },
  inline: {
    display: "inline",
  },
  button: {
    width: '100%',
    height: '50vh',
    fontSize: '3em',
  },
  alarm: {
    padding: '0 0 0 10px',
    fontSize: '1em',
  },
  red: {
    color: 'red',
  },
  none: {
    display: 'none',
  },
});

export class MonthView extends Component {
  static propTypes = {
    remoteMyLog: PropTypes.array.isRequired,
    queryInProgressMyLOG: PropTypes.bool.isRequired,
  };

  state = {
    viewMonth: null,
    selectedIndex: null,
    isItemSelected: false,
    month_log: [],
  }

  handleListItemClick = (event, index, month) => {
    const selectedIndex = this.state.selectedIndex;
    if (selectedIndex !== index) {
      this.setState({
        selectedIndex: index,
        isItemSelected: true,
        viewMonth: month,
        month_log: inMonth(this.props.remoteMyLog, month),
      });
    } else {
      this.setState({
        selectedIndex: null,
        isItemSelected: false,
        viewMonth: null,
        month_log: [],
      });
    }
  };

  render() {
    const { classes, remoteMyLog, queryInProgressMyLOG } = this.props;
    const { viewMonth, selectedIndex, isItemSelected, month_log } = this.state;

    var weekdays = 0;
    var hours = 0;
    var progress = 0;
    var log = null;
    var worktime_months = null;
    var work_months = null;
    const queryInProgress = <CircularProgress color="secondary" />;

    if (remoteMyLog !== undefined) {
      if (!queryInProgressMyLOG) {
        worktime_months = calcTime(remoteMyLog, 1);
      }
      if (month_log != null && isItemSelected) {
        log = (
          <List component="nav" aria-label="main mailbox folders" style={{ paddingTop: "0" }}>
            <ListItem style={{ backgroundColor: "rgba(224, 237, 246, 0.4)" }}>
              <Grid container style={{ textAlign: "center" }}>
                <Grid item xs={4} sm={4} md={4} lg={4}>
                  <Typography component="span" className={`${classes.inline} ${classes.Name2}`}>
                    Status
                  </Typography>
                </Grid>
                <Grid item xs={4} sm={4} md={4} lg={4}>
                  <Typography component="span" className={`${classes.inline} ${classes.Name2}`}>
                    Godzina rozpoczęcia
                  </Typography>
                </Grid>
                <Grid item xs={4} sm={4} md={4} lg={4}>
                  <Typography component="span" className={`${classes.inline} ${classes.Name2}`}>
                    Godzina zakończenia
                  </Typography>
                </Grid>
              </Grid>
            </ListItem>
            {
              month_log.length === 0 && !queryInProgressMyLOG
                ? <div className={classes.info}>Wybierz miesiąc ...</div>
                : ""
            }
            {
              month_log.map((singlelog) => (
                <ListItem key={singlelog.pk} button>
                  <Grid container style={{ textAlign: "center" }}>
                    <Grid item xs={4} sm={4} md={4} lg={4}>
                      <Typography component="span" className={`${classes.inline} ${classes.Name}`}>
                        <span className={singlelog.ctrl != '' ? classes.red : ''}>{singlelog.status == 'START' ? 'Aktywny' : 'Nieaktywny'}</span>
                      </Typography>
                    </Grid>
                    <Grid item xs={4} sm={4} md={4} lg={4}>
                      <Typography component="span" className={`${classes.inline} ${classes.Name}`}>
                        <span className={singlelog.ctrl != '' ? classes.red : ''}>{new Date(singlelog.logStart).toLocaleString()}</span>
                      </Typography>
                    </Grid>
                    <Grid item xs={4} sm={4} md={4} lg={4}>
                      <Typography component="span" className={`${classes.inline} ${classes.Name}`}>
                        <span className={singlelog.ctrl != '' ? classes.red : ''}>{singlelog.logStop ? new Date(singlelog.logStop).toLocaleString() : 'Nie zakończono'}</span>
                      </Typography>
                    </Grid>
                  </Grid>
                </ListItem>
              ))
            }
          </List>
        );
      }
      else {
        log = (
          <List component="nav" aria-label="main mailbox folders">
            {remoteMyLog.length != 0 && !isItemSelected
              ? <div className={classes.info}>Wybierz miesiąc ...</div>
              : ""}
            {remoteMyLog.length === 0 && !queryInProgressMyLOG
              ? <NothingToDisplay classes={classes.info} />
              : ""}
            {remoteMyLog.length === 0 && queryInProgressMyLOG
              ? <WaitToDisplay classes={classes.info} />
              : ""}
          </List>
        )
      }

      if (worktime_months != null) {
        work_months = (
          <List component="nav" aria-label="main mailbox folders">
            {worktime_months.length === 0 && !queryInProgressMyLOG
              ? <NothingToDisplay classes={classes.info} />
              : ""}
            {worktime_months.length === 0 && queryInProgressMyLOG
              ? <WaitToDisplay classes={classes.info} />
              : ""}
            {worktime_months.map((time, index) => (
              <ListItem
                key={time[0]}
                button
                selected={selectedIndex === index}
                onClick={(event) => this.handleListItemClick(event, index, time[0])}
              >
                <span className={classes.none}>
                  {weekdays = getWeekdaysInMonth(new Date(time[0] + "-01"))}
                  {hours = Number(time[1].slice(0, -3)) + Number('0.' + time[1].slice(-2))}
                  {progress = (hours) / (weekdays * 8) * 100}
                </span>
                <ListItemText
                  primary={
                    <Fragment>
                      <Typography
                        component="span"
                        className={`${classes.inline} ${classes.Name}`}
                      >
                        <span className={time[2] != '' ? classes.red : ""}>
                          {time[1]}
                          {' / ' + weekdays * 8 + ''}
                          {time[2] == 'ZM' ? " - wymaga weryfikacji" : time[2] == 'CP' ? ' - przekroczono 260h ' : ''}
                        </span>
                      </Typography>
                    </Fragment>
                  }
                  secondary={
                    <Fragment>
                      <ThemeProvider theme={materialTheme}>
                        <Typography
                          component="span"
                          className={classes.Position}
                        >
                          {time[0]}
                        </Typography>
                      </ThemeProvider>
                    </Fragment>
                  }
                />
                <ListItemAvatar>
                  <Avatar className={classes.avatar} style={{ width: '3.75rem', height: '3.75rem' }}>
                    {progress.toFixed(0) + '%'}
                  </Avatar>
                </ListItemAvatar>
              </ListItem>
            ))}
          </List>
        )
      }
      else {
        work_months = (
          <List component="nav" aria-label="main mailbox folders">
            {remoteMyLog.length === 0 && !queryInProgressMyLOG
              ? <NothingToDisplay classes={classes.info} />
              : ""}
            {remoteMyLog.length === 0 && queryInProgressMyLOG
              ? <WaitToDisplay classes={classes.info} />
              : ""}
          </List>
        )
      }
    } else {
      log = <List className={classes.root}></List>;
      work_months = <List className={classes.root}></List>;
    }

    return (
      <Fragment>
        <Grid container justify="flex-start">
          <Grid item xs={12} sm={12} md={6} lg={6} style={{ paddingRight: "10px" }}>
            <Card style={{ overflow: "auto", maxHeight: "50vh", marginTop: "0" }}>
              <CardBody style={{ padding: 0 }}>
                <CardHeader
                  className={classes.header}
                  avatar={
                    <Avatar aria-label="recipe" className={classes.avatar}>
                      Cz
                    </Avatar>
                  }
                  action={queryInProgressMyLOG ? queryInProgress : ""}
                  title={<div className={classes.headertitle}>Czas Pracy</div>
                  }
                />
                {work_months}
              </CardBody>
            </Card>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} style={{ paddingLeft: "10px" }}>
            <Card style={{ overflow: "auto", maxHeight: "50vh", marginTop: "0" }}>
              <CardBody style={{ padding: 0 }}>
                <CardHeader
                  className={classes.header}
                  avatar={
                    <Avatar aria-label="recipe" className={classes.avatar}>
                      Rz
                    </Avatar>
                  }
                  action={queryInProgressMyLOG ? queryInProgress : ""}
                  title={
                    <div className={classes.headertitle}>Rejestr zdarzeń</div>
                  }
                />
                {log}
              </CardBody>
            </Card>
          </Grid>
        </Grid>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  remoteMyLog: state.remote.remoteMyLog,
  queryInProgressMyLOG: state.remote.queryInProgressMyLOG,
});

export default connect(
  mapStateToProps,
  {}
)(withStyles(styles, { withTheme: true })(MonthView));
