import React, { Component, Fragment } from "react";
import { getRemote, clearRemote } from "../../Redux/actions/remote";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import { createMuiTheme } from "@material-ui/core";
import { lighten } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import CircularProgress from "@material-ui/core/CircularProgress";

import Grid from "@material-ui/core/Grid";
import Card from "../../components/Card/Card.js";
import CardBody from "../../components/Card/CardBody.js";
import CardHeader from "@material-ui/core/CardHeader";

import { NothingToDisplay, WaitToDisplay } from "../Common/infoData.js";

const materialTheme = createMuiTheme({
  palette: {
    primary: {
      main: "#0071BC",
    },
  },
  overrides: {
    MuiChip: {
      root: {
        color: "#ffffff",
        "& $avatar": {
          color: "#ffffff",
        },
      },
      icon: {
        color: "#ffffff",
      },
    },
    MuiCardActions: {
      root: {
        background: "#aaaaaa",
      },
    },
    MuiIconButton: {
      root: {
        color: "#fff",
      },
    },
  },
});

const styles = (theme) => ({
  root: {
    width: "100%",
  },
  section: {
    backgroundPosition: "50%",
    backgroundSize: "cover",
    padding: 0,
    marginTop: "30px",
  },
  cardsGroup: {
    textAlign: "left",
    color: "#444444",
    fontFamily: "Lato, Open Sans, sans-serif",
    fontSize: "1.2em",
    fontWeight: "700",
    marginTop: "30px",
    marginBottom: "10px",
  },
  Name: {
    textAlign: "left",
    fontFamily: "Lato, Open Sans, sans-serif",
    fontSize: "1em",
    fontWeight: "700",
    color: "#444444",
  },
  Name2: {
    textAlign: "left",
    fontFamily: "Lato, Open Sans, sans-serif",
    fontSize: "1.2em",
    fontWeight: "700",
    color: "#444444",
  },
  Position: {
    textAlign: "left",
    fontFamily: "Lato, Open Sans, sans-serif",
    fontSize: "1em",
    fontWeight: "400",
    color: "#aaaaaa",
  },
  header: {
    textAlign: "left",
    backgroundColor: lighten("#338dc9", 0.85),
  },
  avatar: {
    backgroundColor: "#0071bc",
  },
  headertitle: {
    fontFamily: "Lato, Open Sans, sans-serif",
    fontSize: "1.2em",
    fontWeight: "700",
    color: "#444444",
  },
  info: {
    fontFamily: "Lato, Open Sans, sans-serif",
    fontSize: "1.5em",
    fontWeight: "300",
    color: "#0071BC",
  },
  fabblue: {
    color: theme.palette.common.white,
    backgroundColor: "#2196f3",
    "&:hover": {
      backgroundColor: "#1976d2",
    },
  },
  fb1: {
    position: "fixed",
    bottom: theme.spacing(8),
    right: theme.spacing(5),
  },
  inline: {
    display: "inline",
  },
  center: {
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
  }
});

export class ManagerLogView extends Component {
  state = {
    openDialog: false,
  };

  static propTypes = {
    remoteLog: PropTypes.array.isRequired,
    queryInProgressLOG: PropTypes.bool.isRequired,
    getRemote: PropTypes.func.isRequired,
    clearRemote: PropTypes.func.isRequired,
  };

  componentWillUnmount() {
    this.props.clearRemote();
  }

  componentDidMount() {
    this.props.getRemote();
  }

  render() {
    const { classes, remoteLog, queryInProgressLOG } = this.props;

    var log = null;
    const queryInProgress = <CircularProgress color="secondary" />;

    if (remoteLog !== undefined) {
      log = (
        <List component="nav" aria-label="main mailbox folders" style={{ paddingTop: "0" }}>
          <ListItem style={{ backgroundColor: "rgba(224, 237, 246, 0.4)" }}>
            <ListItemAvatar><Avatar></Avatar></ListItemAvatar>
            <Grid container style={{ textAlign: "center" }}>
              <Grid item xs={3} sm={3} md={3} lg={3}>
                <Typography component="span" className={`${classes.inline} ${classes.Name2}`}>
                  Imię i nazwisko
                </Typography>
              </Grid>
              <Grid item xs={3} sm={3} md={3} lg={3}>
                <Typography component="span" className={`${classes.inline} ${classes.Name2}`}>
                  Status
                </Typography>
              </Grid>
              <Grid item xs={3} sm={3} md={3} lg={3}>
                <Typography component="span" className={`${classes.inline} ${classes.Name2}`}>
                  Godzina rozpoczęcia
                </Typography>
              </Grid>
              <Grid item xs={3} sm={3} md={3} lg={3}>
                <Typography component="span" className={`${classes.inline} ${classes.Name2}`}>
                  Godzina zakończenia
                </Typography>
              </Grid>
            </Grid>
          </ListItem>
          {
            remoteLog.length === 0 && !queryInProgressLOG
              ? <NothingToDisplay classes={classes.info} />
              : ""
          }
          {
            remoteLog.length === 0 && queryInProgressLOG
              ? <WaitToDisplay classes={classes.info} />
              : ""
          }
          {
            remoteLog.map((singlelog) => (
              <ListItem key={singlelog.pk} button>
                <ListItemAvatar>
                  <Avatar>
                    {singlelog.employee.last_name.substr(0, 1) + singlelog.employee.first_name.substr(0, 1)}
                  </Avatar>
                </ListItemAvatar>
                <Grid container style={{ textAlign: "center" }}>
                  <Grid item xs={3} sm={3} md={3} lg={3} className={classes.center}>
                    <Typography component="span" className={`${classes.inline} ${classes.Name}`}>
                      {singlelog.employee.last_name + " " + singlelog.employee.first_name}
                    </Typography>
                  </Grid>
                  <Grid item xs={3} sm={3} md={3} lg={3} className={classes.center}>
                    <Typography component="span" className={`${classes.inline} ${classes.Name}`}>
                      {singlelog.status == 'START' ? 'Aktywny' : 'Nieaktywny'}
                    </Typography>
                  </Grid>
                  <Grid item xs={3} sm={3} md={3} lg={3} className={classes.center}>
                    <Typography component="span" className={`${classes.inline} ${classes.Name}`}>
                      {new Date(singlelog.logStart).toLocaleString()}
                    </Typography>
                  </Grid>
                  <Grid item xs={3} sm={3} md={3} lg={3} className={classes.center}>
                    <Typography component="span" className={`${classes.inline} ${classes.Name}`}>
                      {singlelog.logStop ? new Date(singlelog.logStop).toLocaleString() : 'Nie zakończono'}
                    </Typography>
                  </Grid>
                </Grid>
              </ListItem>
            ))
          }
        </List>
      );
    } else {
      log = <List className={classes.root}></List>;
    }

    return (
      <Fragment>
        <Grid container justify="flex-start" spacing={2} style={{ width: "100%" }}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Card style={{ overflow: "auto", height: "79.5vh", marginLeft: "0.9%", width: "99%" }}>
              <CardBody style={{ padding: 0 }}>
                <CardHeader
                  className={classes.header}
                  avatar={
                    <Avatar aria-label="recipe" className={classes.avatar}>
                      Rz
                    </Avatar>
                  }
                  action={queryInProgressLOG ? queryInProgress : ""}
                  title={
                    <div className={classes.headertitle}>Rejestr zdarzeń</div>
                  }
                />
                {log}
              </CardBody>
            </Card>
          </Grid>
        </Grid>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  remoteLog: state.remote.remoteLog,
  queryInProgressLOG: state.remote.queryInProgressLOG,
});

export default connect(
  mapStateToProps,
  { getRemote, clearRemote }
)(withStyles(styles, { withTheme: true })(ManagerLogView));
