import axios from "axios";
import { serverurl, getDatabaseUrl } from "./databaseServerURL";
import { returnErrors, createMessages } from "./messages";

import {
  USER_LOADED,
  USER_LOADED2,
  USER_LOADING,
  AUTH_ERROR,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT_PENDING,
  LOGOUT_SUCCESS,
  REGISTER_FAIL,
  REGISTER_SUCCESS,
  CHANGE_PASSWORD,
  PATCH_PROFILE,
  CHANGE_COMPANY,
} from "./types";
import { clearUserDetails, getUserDetails, getUsersContactsList, getUsersFullList, getUsersList } from "./common";

//CHECK TOKEN AND LOAD USER
export const loadUser = () => (dispatch, getState) => {
  //User Loading
  dispatch({ type: USER_LOADING });

  axios
    .get(serverurl + "/auth/user", tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: USER_LOADED,
        payload: res.data,
      });
    })
    .catch((err) => {
      if (err.response) {
        dispatch(returnErrors(err.response.data.detail, err.response.status));
        dispatch({
          type: AUTH_ERROR,
        });
      } else {
        console.error(err);
      }
    });
};

//LOGIN USER
export const login = (username, password) => (dispatch) => {
  dispatch({ type: USER_LOADING });
  //Headers
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  //Request Body
  const body = JSON.stringify({ username, password });

  axios
    .post(serverurl + "/auth/login", body, config)
    .then((res) => {
      dispatch({
        type: LOGIN_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      if (err.response.data.non_field_errors === undefined) {
        dispatch(returnErrors("null", 1000));
        dispatch({
          type: LOGIN_FAIL,
        });
      } else {
        dispatch(
          returnErrors(
            err.response.data.non_field_errors[0],
            err.response.status
          )
        );
        dispatch({
          type: LOGIN_FAIL,
        });
      }
    });
};

//REGISTER USER
export const register = ({ username, password, email }) => (dispatch) => {
  //Headers
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  //Request Body
  const body = JSON.stringify({ username, email, password });

  axios
    .post(serverurl + "/auth/register", body, config)
    .then((res) => {
      dispatch({
        type: REGISTER_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch(returnErrors(err.response.data.detail, err.response.status));
      dispatch({
        type: REGISTER_FAIL,
      });
    });
};

//LOGOUT USER
export const logout = () => (dispatch, getState) => {
  //Logout Pending
  dispatch({ type: LOGOUT_PENDING });

  axios
    .post(serverurl + "/auth/logout", null, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: LOGOUT_SUCCESS,
      });
    })
    .catch((err) => {
      dispatch(returnErrors(err.response.data.detail, err.response.status));
    });
};

//CHANGE USER PASSWORD
export const changeUserPassword = (changePasswordBody, pk) => (
  dispatch,
  getState
) => {
  axios
    .put(
      serverurl + `/auth/password-change/${pk}`,
      changePasswordBody,
      tokenConfig(getState)
    )
    .then((res) => {
      dispatch(
        createMessages({
          changeUserPassword: "Hasło zostało pomyślnie zaktualizowane",
        })
      );
      dispatch({
        type: CHANGE_PASSWORD,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch(
        returnErrors(err.response.data.old_password[0], err.response.status)
      );
    });
};

//PATCH My PROFILE
export const patchProfile = (profile, pk) => (dispatch, getState) => {
  axios
    .patch(serverurl + getDatabaseUrl() + `/profile/${pk}`, profile, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: PATCH_PROFILE,
        payload: res.data,
      });
    })
    .catch((err) =>
      dispatch(returnErrors(err.response.data.detail, err.response.status))
    );
};

export const updateImage = (profile, pk) => (dispatch, getState) => {
  axios
    .patch(serverurl + getDatabaseUrl() + `/profile/${pk}`, profile, tokenConfig(getState))
    .then((res) => {
      dispatch(
        createMessages({
          patchProfile2: "Zaktualizowano zdjęcie profilu ...",
        })
      );
      dispatch({
        type: PATCH_PROFILE,
        payload: res.data,
      });
    })
    .catch((err) =>
      dispatch(returnErrors(err.response.data.detail, err.response.status))
    );
};

//PATCH PROFILE2
export const patchProfile2 = (profile, pk, up) => (dispatch, getState) => {
  axios
    .patch(serverurl + getDatabaseUrl() + `/profile_additional/${pk}?up=${up}`, profile, tokenConfig(getState))
    .then((res) => {
      dispatch(
        createMessages({
          patchProfile2: "Zaktualizowano zmiany profilu ...",
        })
      );
      dispatch({
        type: PATCH_PROFILE,
        payload: res.data,
      });
    })
    .catch((err) =>
      dispatch(returnErrors(err.response.data.detail, err.response.status))
    );
};

export const changeCompany = (company) => (dispatch, getState) => {
  const state = getState();
  const pk = state.auth.user.id;
  const profileBody = {
    defaultCompany: company,
  };

  axios
    .patch(serverurl + getDatabaseUrl() + `/profile/${pk}`, profileBody, tokenConfig(getState))
    .then((res) => {
      axios
        .get(serverurl + `/auth/user?db=${company}`, tokenConfig(getState))
        .then((res) => {
          dispatch(
            createMessages({
              changeCompany: "Zmiana firmy ...",
            })
          );
          dispatch({
            type: CHANGE_COMPANY,
            payload: res.data,
            selectedCompany: company,
          });
          if (state.common.usersContacts.length != 0) {
            dispatch(getUsersContactsList());
          }
          if (state.common.usersFull.length != 0) {
            dispatch(getUsersFullList());
          }
          if (state.common.users.length != 0) {
            dispatch(getUsersList());
          }
          if (state.common.suser.pk != undefined) {
            dispatch(clearUserDetails("s"));
            dispatch(getUserDetails(state.common.suser.pk, "s"));
          }
          if (state.common.tuser.pk != undefined) {
            dispatch(clearUserDetails("t"));
            dispatch(getUserDetails(state.common.tuser.pk, "t"));
          }
        })
        .catch((err) => {
          if (err.response) {
            dispatch(returnErrors(err.response.data.detail, err.response.status));
          } else {
            console.error(err);
          }
        });
    })
    .catch((err) =>
      dispatch(returnErrors(err.response.data.detail, err.response.status))
    );
};

//Setup config with token - helper function

export const tokenConfig = (getState) => {
  //Get token from state
  const token = getState().auth.token;

  //Headers
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  //If token, add to headers config
  if (token) {
    config.headers["Authorization"] = `Token ${token}`;
  }

  return config;
};

export const tokenConfigForm = (getState) => {
  //Get token from state
  const token = getState().auth.token;

  //Headers
  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  };

  //If token, add to headers config
  if (token) {
    config.headers["Authorization"] = `Token ${token}`;
  }

  return config;
};

export const tokenConfigRAW = (token) => {

  //Headers
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  //If token, add to headers config
  if (token) {
    config.headers["Authorization"] = `Token ${token}`;
  }

  return config;
};