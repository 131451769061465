import React, { Component, Fragment } from "react";
import TextField from "@material-ui/core/TextField";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import { connect } from "react-redux";
import { clearVendorsList, getVendorsList } from "../../Redux/actions/assets";
import { ThemeProvider } from "@material-ui/styles";
import { createMuiTheme } from "@material-ui/core";
import PropTypes from "prop-types";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import Card from "../../components/Card/Card.js";
import CardBody from "../../components/Card/CardBody.js";
import Typography from "@material-ui/core/Typography";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import FormHelperText from "@material-ui/core/FormHelperText";

const materialTheme = createMuiTheme({
  palette: {
    primary: {
      main: "#0071BC",
    },
  },
  overrides: {
    MuiTextField: {
      root: {
        width: "auto",
      },
    },
    MuiFormLabel: {
      root: {
        color: "#0071bc",
      },
    },
    MuiFormControl: {
      root: {
        marginTop: "16px",
        marginBottom: "16px",
        marginRight: "8px",
        marginLeft: "8px",
        width: 300,
        //marginTop: 50
      },
      marginNormal: {
        //marginTop: "50px"
      },
    },
  },
});

const CssTextField = withStyles({
  root: {
    width: 280,
    flexWrap: "wrap",
    marginBottom: "50px",

    "& label.Mui-focused": {
      color: "#0071bc",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#0071bc",
    },
  },
})(TextField);

const filter = createFilterOptions();

const styles = (theme) => ({
  root: {
    width: "100%",
  },
  button: {
    marginRight: theme.spacing(1),
  },
  buttonNext: {
    marginRight: theme.spacing(1),
    color: theme.palette.common.white,
    backgroundColor: "#4791db",
    "&:hover": {
      backgroundColor: "#1976d2",
    },
  },
  buttonAdd: {
    marginRight: theme.spacing(1),
    color: theme.palette.common.white,
    backgroundColor: "#4caf50",
    "&:hover": {
      backgroundColor: "#388e3c",
    },
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  textField: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
    //width: "25ch"
  },
  textSumary: {
    color: "#888888",
    textAlign: "left",
    fontFamily: "Lato, Open Sans, sans-serif",
    fontSize: "1em",
    fontWeight: "300",
  },
  textBold: {
    fontWeight: "700",
  },
});

export class AddAssetModelForm extends Component {
  state = {
    vendorSelected: null,
    open: false,
    openVendor: false,
    dialogVendorSelected: "",
    assetModelName: "",
    assetModelDescription: "",
    eqtype: "",
  };

  static propTypes = {
    queryInProgress: PropTypes.bool.isRequired,
    vendorsList: PropTypes.array.isRequired,
    clearVendorsList: PropTypes.func.isRequired,
  };

  componentDidMount() {
    this.props.clearVendorsList();
  }

  handleClose = () => {
    this.setState({
      dialogVendorSelected: "",
      open: false,
    });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    const body = {
      name: this.state.dialogVendorSelected,
    };
    this.props.addVendor(body);
    this.setState({
      vendorSelected: null,
    });

    this.handleClose();
  };

  setOpenVendor = () => {
    const openVendor = this.state.openVendor;
    if (openVendor === false && this.props.vendorsList.length === 0) {
      this.props.getVendorsList();
    }
    this.setState({
      openVendor: !openVendor,
    });
  };

  handleTextList = (name) => ({ target: { value } }) => {
    if (name === "assetModelName") {
      this.setState({
        ...this.state,
        [name]:
          value.charAt(0).toUpperCase() + value.substring(1).toLowerCase(),
      });
    } else if (name === "assetModelDescription") {
      this.setState({
        ...this.state,
        [name]: value,
      });
    } else if (name === "vendorName") {
    } else {
      this.setState({
        ...this.state,
        [name]: value,
      });
    }
  };

  handleAddAssetModel = () => {
    const assetmodelbody = {
      name: this.state.assetModelName,
      vendor: this.state.vendorSelected.name,
      description: this.state.assetModelDescription,
      eqtype: this.state.eqtype,
    };
    this.props.addAssetModel(assetmodelbody);
  };

  render() {
    const {
      open,
      openVendor,
      vendorSelected,
      dialogVendorSelected,
      assetModelName,
      assetModelDescription,
      eqtype,
    } = this.state;
    const { classes, vendorsList } = this.props;

    const loadingVendor = openVendor && vendorsList.length === 0;

    const buttonAddEn = (
      <Button
        variant="contained"
        color="primary"
        onClick={this.handleAddAssetModel}
        className={classes.buttonAdd}
      >
        Dodaj
      </Button>
    );

    return (
      <Fragment>
        <Grid container justify="center" spacing={2}>
          <Grid item xs={12} sm={12} md={12}>
            <Card style={{ overflow: "auto" }}>
              <CardBody>
                <Typography
                  style={{
                    marginBottom: "50px",
                    color: "#888888",
                    textAlign: "left",
                    fontFamily: "Lato, Open Sans, sans-serif",
                    fontSize: "1em",
                    fontWeight: "300",
                  }}
                >
                  Wyszukaj producenta z listy. Jeżeli nie znajdziesz producenta,
                  wybierz opcję <b>Dodaj "NAZWA PRODUCENTA"</b>...
                </Typography>
                <Autocomplete
                  open={openVendor}
                  onOpen={this.setOpenVendor}
                  onClose={this.setOpenVendor}
                  value={vendorSelected}
                  style={{ width: "300px" }}
                  onChange={(event, newValue) => {
                    if (typeof newValue === "string") {
                      setTimeout(() => {
                        this.setState({
                          open: true,
                          dialogVendorSelected: newValue.toUpperCase(),
                        });
                      });
                      return;
                    }

                    if (newValue && newValue.inputValue) {
                      this.setState({
                        open: true,
                        dialogVendorSelected: newValue.inputValue.toUpperCase(),
                      });

                      return;
                    }
                    if (newValue !== null) {
                      this.setState({
                        vendorSelected: {
                          pk: newValue.pk,
                          name: newValue.name,
                        },
                      });
                    } else {
                      this.setState({
                        vendorSelected: null,
                      });
                    }
                  }}
                  filterOptions={(options, params) => {
                    const filtered = filter(options, params);
                    if (params.inputValue !== "") {
                      filtered.push({
                        inputValue: params.inputValue,
                        name: `Dodaj "${params.inputValue}"`,
                      });
                    }

                    return filtered;
                  }}
                  id="free-solo-dialog-demo"
                  options={vendorsList}
                  loading={loadingVendor}
                  getOptionLabel={(option) => {
                    // e.g value selected with enter, right from the input
                    if (typeof option === "string") {
                      return option;
                    }
                    if (option.inputValue) {
                      return option.inputValue;
                    }
                    return option.name;
                  }}
                  renderOption={(option) => option.name}
                  renderInput={(params) => (
                    <CssTextField
                      {...params}
                      label="wybierz producenta z listy..."
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {loadingVendor ? (
                              <CircularProgress color="inherit" size={20} />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                    />
                  )}
                />
                <ThemeProvider theme={materialTheme}>
                  <Typography
                    style={{
                      marginBottom: "50px",
                      color: "#888888",
                      textAlign: "left",
                      fontFamily: "Lato, Open Sans, sans-serif",
                      fontSize: "1em",
                      fontWeight: "300",
                    }}
                  >
                    Wprowadź podstawowe informacje o typie środka trwałego ...
                  </Typography>
                  <TextField
                    helperText="np. Laptop/Uprząż/Karabinek"
                    required
                    label="Typ środka trwałego"
                    id="filled-margin-none"
                    className={classes.textField}
                    value={assetModelName || ""}
                    onChange={this.handleTextList("assetModelName")}
                  //variant="filled"
                  />
                  <TextField
                    helperText="np. E7440/AVAO BOD/Stalowy Oval"
                    label="Nazwa modelu"
                    id="filled-margin-none"
                    className={classes.textField}
                    value={assetModelDescription || ""}
                    onChange={this.handleTextList("assetModelDescription")}
                  //variant="filled"
                  />
                  <FormControl required className={classes.formControl}>
                    <InputLabel htmlFor="eqtype">Grupa</InputLabel>
                    <Select
                      name="eqtype"
                      className={classes.selectEmpty}
                      value={eqtype}
                      onChange={this.handleTextList("eqtype")}
                    >
                      <MenuItem value="al">BHP</MenuItem>
                      <MenuItem value="na">Narzędzia</MenuItem>
                      <MenuItem value="el">Elektro narzędzia</MenuItem>
                      <MenuItem value="it">IT</MenuItem>
                      <MenuItem value="po">Pozostałe</MenuItem>
                    </Select>
                    <FormHelperText>
                      Np. Karabinek należy do grupy "BHP"
                    </FormHelperText>
                  </FormControl>
                </ThemeProvider>
              </CardBody>
            </Card>
          </Grid>
          <Grid style={{ marginBottom: "30px" }} item xs={12} sm={12} md={12}>
            {buttonAddEn}
          </Grid>
        </Grid>

        <Dialog
          open={open}
          onClose={this.handleClose}
          aria-labelledby="form-dialog-title"
        >
          <form onSubmit={this.handleSubmit}>
            <DialogTitle id="form-dialog-title">
              Dodaj nowego producenta
            </DialogTitle>
            <DialogContent>
              <TextField
                autoFocus
                margin="dense"
                id="name"
                value={dialogVendorSelected}
                onChange={(event) =>
                  this.setState({
                    dialogVendorSelected: event.target.value.toLocaleUpperCase(),
                  })
                }
                label="Nazwa producenta"
                type="text"
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={this.handleClose} color="primary">
                Anuluj
              </Button>
              <Button type="submit" color="primary">
                Dodaj
              </Button>
            </DialogActions>
          </form>
        </Dialog>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  vendorsList: state.assets.vendorsList,
  queryInProgress: state.assets.queryInProgress,
});

export default connect(
  mapStateToProps,
  {
    getVendorsList,
    clearVendorsList,
  }
)(withStyles(styles, { withTheme: true })(AddAssetModelForm));
