import React, { Component, Fragment } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import AddAssetModelForm from "./AddAssetModelForm";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { styles } from "../../common/radiolineStyles";
import { addAssetModel, addVendor } from "../../Redux/actions/assets";

export class DialogAddAssetModel extends Component {
  static propTypes = {
    addAssetModel: PropTypes.func.isRequired,
    addVendor: PropTypes.func.isRequired,
  };

  handleAddVendor = (vendorBody) => {
    this.props.addVendor(vendorBody);
  };

  handleAddAssetModel = (assetModelBody) => {
    this.props.addAssetModel(assetModelBody);
    this.props.closeDialogAddAssetModel();
  };

  handleCloseDialogAddAssetModel = () => {
    this.props.closeDialogAddAssetModel();
  };

  render() {
    const { classes, dialogOpenAddAssetModel } = this.props;
    return (
      <Fragment>
        <Dialog
          fullScreen
          open={dialogOpenAddAssetModel}
          onClose={this.handleCloseDialogAddAssetModel}
          aria-labelledby="form-dialog-title"
        >
          <AppBar position="static" style={{ background: "#0071BC" }}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={this.handleCloseDialogAddAssetModel}
                aria-label="close"
                className={classes.menuButton}
              >
                <CloseIcon />
              </IconButton>
              <Typography variant="h6" className={classes.title}>
                Dodaj nowy model Środka Trwałego
              </Typography>
            </Toolbar>
          </AppBar>
          <DialogContent style={{ background: "#f5f5f5" }}>
            <AddAssetModelForm
              addAssetModel={this.handleAddAssetModel}
              addVendor={this.handleAddVendor}
            />
          </DialogContent>
        </Dialog>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({});

export default connect(
  mapStateToProps,
  { addAssetModel, addVendor }
)(withStyles(styles, { withTheme: true })(DialogAddAssetModel));
