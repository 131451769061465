import React, { Fragment, Component } from "react";
// core components
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  getMyAssets,
  getMyAssetsByStatus,
  getMyAssetsDetails,
  patchMyAsset,
  scrapMyAsset,
  scrapMyAssetUpdate,
  addAssetTransferLog,
  clearCurrentEquipmentTransferLog,
  patchAssetTransferLog,
  getActiveInventory,
  getMyInventory,
  getMyInventoryDetails,
  updateMyInventoryDetails,
  deleteMyInventoryDetails,
  reportInventory,
} from "../../Redux/actions/assets";
import {
  getUsersList,
  getUserDetails,
  clearUserDetails,
} from "../../Redux/actions/common";
import { getMyManagerDetailsV2, sendNotification, sendNewNotification, sendNewNotificationKeepers, sendNewNotificationControler, sendNewNotificationBHP, sendNewNotificationManager } from "../../Redux/actions/employee";
import { withStyles } from "@material-ui/core/styles";
import AssetNavigation from "../Common/AssetNavigation";
import TransferLog from "../Common/TransferLog";
import MyAssetList from "./MyAssetList";
import MyAssetScrapageRequestedCard from "./MyAssetScrapageRequestedCard";
import MyAssetWaitForReturnCard from "./MyAssetWaitForReturnCard";
import MyAssetHistoryCard from "./MyAssetHistoryCard";
import MyAssetFilterList from "./MyAssetFilterList";
import MyAssetHistoryList from "./MyAssetHistoryList";
import CircularProgress from "@material-ui/core/CircularProgress";
import Fab from "@material-ui/core/Fab";
import Button from "@material-ui/core/Button";
import VisibilityIcon from "@material-ui/icons/Visibility";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
import Zoom from "@material-ui/core/Zoom";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Avatar from "@material-ui/core/Avatar";
import Card from "../../components/Card/Card.js";
import CardBody from "../../components/Card/CardBody.js";
import CardHeader from "@material-ui/core/CardHeader";
import { ThemeProvider } from "@material-ui/styles";
import { getFormattedDate } from "../../common/commonFunctions";
import DeleteSweepIcon from "@material-ui/icons/DeleteSweep";
import Tooltip from "@material-ui/core/Tooltip";
import NotificationsActiveIcon from "@material-ui/icons/NotificationsActive";
import CommentIcon from "@material-ui/icons/Comment";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import {
  styles,
  materialTheme,
  CssTextField,
} from "../../common/radiolineStyles";
import { sendCode, verifyCode, clearCode } from "../../Redux/actions/verifycodes"
import wait from "../../graphics/wait.svg";
import warning from "../../graphics/warning.svg";
import success from "../../graphics/success.svg";
import MyInventoryList from "./MyInventoryList.js";
import CheckIcon from "@material-ui/icons/Check";
import NewAssetDialog from "./NewAssetDialog.js";
import MyInventoryDetails from "./MyInventoryDetails.js";
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import DialogContentText from '@material-ui/core/DialogContentText';

var wa = <img style={{ height: "26px" }} src={wait} alt="wa"></img>;
var wr = <img style={{ height: "26px" }} src={warning} alt="wr"></img>;
var su = <img style={{ height: "26px" }} src={success} alt="su"></img>;

export class MyAssetView extends Component {
  state = {
    isCheckedAl: false,
    checkedAssetPk: null,
    checkedAssetStatus: null,
    isAssetChecked: false,
    isTransferFrom: false,
    isTransferTo: false,
    eqTypeSelected: "al",
    alertOpen: false,
    alertSMSOpen: false,
    dialogOpen: false,
    dialogOpenAssetTransfer: false,
    dialogNotifyScrap: false,
    dialogAddCommentScrapAsset: false,
    open: false,
    newEmployeeSelected: null,
    activeFilter: null,
    activeListType: null,
    scrapageUserComment: null,
    checkedInventoryPk: null,
    dialogNewInvAssetOpen: false,
    dialogNoInvAssetOpen: false,
    assetDescription: "",
    openStep1: false,
    openStep2: false,
    code: "",
    pesel: "",
    peselError: null,
    peselValid: null,
    comment: "",
    openCommentDialog: false,
    dialogOpenScrapAsset: false,
  };

  static propTypes = {
    auth: PropTypes.object.isRequired,
    myAssetsAl: PropTypes.array.isRequired,
    myAssetsNa: PropTypes.array.isRequired,
    myAssetsEl: PropTypes.array.isRequired,
    myAssetsIt: PropTypes.array.isRequired,
    myAssetsPo: PropTypes.array.isRequired,
    myAssetsByStatusSr: PropTypes.array.isRequired,
    myAssetsByStatusWr: PropTypes.array.isRequired,
    myAssetsDetails: PropTypes.object.isRequired,
    queryInProgress: PropTypes.bool.isRequired,
    queryMySrInProgress: PropTypes.bool.isRequired,
    queryMyWrInProgress: PropTypes.bool.isRequired,
    getMyAssets: PropTypes.func.isRequired,
    getMyAssetsByStatus: PropTypes.func.isRequired,
    getMyAssetsDetails: PropTypes.func.isRequired,
    patchMyAsset: PropTypes.func.isRequired,
    scrapMyAsset: PropTypes.func.isRequired,
    scrapMyAssetUpdate: PropTypes.func.isRequired,
    patchAssetTransferLog: PropTypes.func.isRequired,
    addAssetTransferLog: PropTypes.func.isRequired,
    clearCurrentEquipmentTransferLog: PropTypes.func.isRequired,
    getUserDetails: PropTypes.func.isRequired,
    getMyManagerDetailsV2: PropTypes.func.isRequired,
    clearUserDetails: PropTypes.func.isRequired,
    usersList: PropTypes.array.isRequired,
    userDetails: PropTypes.object.isRequired,
    myManagerDetailsV2: PropTypes.object.isRequired,
    queryInProgressUL: PropTypes.bool.isRequired,
    sendNotification: PropTypes.func.isRequired,
    sendNewNotification: PropTypes.func.isRequired,
    sendNewNotificationControler: PropTypes.func.isRequired,
    sendNewNotificationBHP: PropTypes.func.isRequired,
    getActiveInventory: PropTypes.func.isRequired,
    activeInventory: PropTypes.any.isRequired,
    getMyInventory: PropTypes.func.isRequired,
    myInvAl: PropTypes.array.isRequired,
    myInvNa: PropTypes.array.isRequired,
    myInvEl: PropTypes.array.isRequired,
    myInvIt: PropTypes.array.isRequired,
    myInvPo: PropTypes.array.isRequired,
    myInventoryDetails: PropTypes.object.isRequired,
    getMyInventoryDetails: PropTypes.func.isRequired,
    updateMyInventoryDetails: PropTypes.func.isRequired,
    deleteMyInventoryDetails: PropTypes.func.isRequired,
    reportInventory: PropTypes.func.isRequired,
    codeValid: PropTypes.any.isRequired,
    sendCode: PropTypes.func.isRequired,
    clearCode: PropTypes.func.isRequired,
    verifyCode: PropTypes.func.isRequired,
    sendNewNotificationKeepers: PropTypes.func.isRequired,
  };

  getAssetsData = () => {
    this.props.getMyAssets(this.props.auth.user.id, "al");
    this.props.getMyAssets(this.props.auth.user.id, "na");
    this.props.getMyAssets(this.props.auth.user.id, "el");
    this.props.getMyAssets(this.props.auth.user.id, "it");
    this.props.getMyAssets(this.props.auth.user.id, "po");
  }

  getMyInventoryData = () => {
    this.props.getMyInventory(this.props.activeInventory, "al");
    this.props.getMyInventory(this.props.activeInventory, "na");
    this.props.getMyInventory(this.props.activeInventory, "el");
    this.props.getMyInventory(this.props.activeInventory, "it");
    this.props.getMyInventory(this.props.activeInventory, "po");
  }

  componentDidMount() {
    this.props.getActiveInventory(this.props.auth.user.id);
    /*if (!this.props.activeInventory) {
      this.props.getMyAssets(this.props.auth.user.id, "al");
      this.props.getMyAssets(this.props.auth.user.id, "na");
      this.props.getMyAssets(this.props.auth.user.id, "el");
      this.props.getMyAssets(this.props.auth.user.id, "it");
      this.props.getMyAssets(this.props.auth.user.id, "po");
    } else {
      this.props.getMyInventory(this.props.activeInventory, "al");
      this.props.getMyInventory(this.props.activeInventory, "na");
      this.props.getMyInventory(this.props.activeInventory, "el");
      this.props.getMyInventory(this.props.activeInventory, "it");
      this.props.getMyInventory(this.props.activeInventory, "po");
    }*/
    this.props.getMyAssetsByStatus(this.props.auth.user.id, "sr");
    this.props.getMyAssetsByStatus(this.props.auth.user.id, "wr");
    this.props.getMyAssetsByStatus(this.props.auth.user.id, "sd");
  }

  componentDidUpdate(prevProps) {
    if (this.props.auth.main_profile.defaultCompany != prevProps.auth.main_profile.defaultCompany) {
      this.props.getActiveInventory(this.props.auth.user.id);
      /*if (!this.props.activeInventory) {
        this.props.getMyAssets(this.props.auth.user.id, "al");
        this.props.getMyAssets(this.props.auth.user.id, "na");
        this.props.getMyAssets(this.props.auth.user.id, "el");
        this.props.getMyAssets(this.props.auth.user.id, "it");
        this.props.getMyAssets(this.props.auth.user.id, "po");
      } else {
        this.props.getMyInventory(this.props.activeInventory, "al");
        this.props.getMyInventory(this.props.activeInventory, "na");
        this.props.getMyInventory(this.props.activeInventory, "el");
        this.props.getMyInventory(this.props.activeInventory, "it");
        this.props.getMyInventory(this.props.activeInventory, "po");
      }*/
      this.props.getMyAssetsByStatus(this.props.auth.user.id, "sr");
      this.props.getMyAssetsByStatus(this.props.auth.user.id, "wr");
      this.props.getMyAssetsByStatus(this.props.auth.user.id, "sd");
    }
    if (this.props.activeInventory != prevProps.activeInventory) {
      if (!this.props.activeInventory) {
        this.getAssetsData();
      } else if (this.props.activeInventory > 0) {
        this.getMyInventoryData();
      }
    }

    if (this.props.codeValid) {
      this.handleAcceptInventory();
      this.props.clearCode();
      this.handleCloseStep2();
    }
    if (this.state.peselValid) {
      this.handleOpenStep2();
      this.handleCloseStep1();
    }
  }

  componentWillReceiveProps(nextProps) {
    //wykryj czy utworzono nowy equipment transfer log
    if (nextProps.currentEquipmentTransferLog !== null) {
      const assetbody = {
        currentEquipmentTransferLog: nextProps.currentEquipmentTransferLog,
      };
      this.props.patchMyAsset(
        this.props.myAssetsDetails.pk,
        this.props.myAssetsDetails.model.eqtype,
        assetbody,
        false
      );
      this.props.clearCurrentEquipmentTransferLog();
    }

    if (
      nextProps.myAssetsDetails.scrapageUserComment !==
      this.state.scrapageUserComment
    ) {
      this.setState({
        scrapageUserComment: nextProps.myAssetsDetails.scrapageUserComment,
      });
    }

    if (
      nextProps.myAssetsDetails.pk !== this.props.myAssetsDetails.pk ||
      nextProps.myAssetsDetails.status !== this.props.myAssetsDetails.status
    ) {
      this.setState({
        checkedAssetStatus: nextProps.myAssetsDetails.status,
      });
    }
  }

  handleCheckedAsset = (isChecked, assetpk, listtype) => {
    this.setState({
      isAssetChecked: isChecked,
      checkedAssetPk: assetpk,
      activeListType: listtype,
    });

    if (isChecked === true) {
      this.props.getMyAssetsDetails(assetpk);
    }
  };

  handleCheckedInventory = (isChecked, invpk, listtype) => {
    this.setState({
      isAssetChecked: isChecked,
      checkedInventoryPk: invpk,
      activeListType: listtype,
    });

    if (isChecked === true) {
      this.props.getMyInventoryDetails(invpk);
    }
  };

  handleEqTypeSelected = (eqtypeselected) => {
    this.setState({
      eqTypeSelected: eqtypeselected,
      checkedAssetPk: null,
      checkedInventoryPk: null,
      isAssetChecked: false,
    });
  };

  handleAlertClose = () => {
    this.setState({
      alertOpen: false,
    });
  };

  handleAlertSMSClose = () => {
    this.setState({
      alertSMSOpen: false,
    });
  };

  triggerNotification = () => {
    this.setState({
      alertOpen: true,
    });
  };

  handleClickPreview = (event) => {
    this.setState({
      dialogOpen: true,
    });
    //this.props.getMyAssetsDetails(this.state.checkedAssetPk);
  };

  handleClickInvYes = (event) => {
    var body = {
      isOwned: true,
    }
    this.props.updateMyInventoryDetails(this.state.checkedInventoryPk, body);
  };

  handleClickInvNo = (event) => {
    this.setState({
      dialogNoInvAssetOpen: true,
    })
  };

  handleNoInvAsset = (event) => {
    var body = {
      isOwned: false,
      userComment: this.state.assetDescription,
    }
    this.props.updateMyInventoryDetails(this.state.checkedInventoryPk, body);
    this.setState({
      dialogNoInvAssetOpen: false,
    });
  };

  handleClickCloseNoInvDialog = () => {
    this.setState({
      dialogNoInvAssetOpen: false,
    });
  };

  handleClickInvDelete = (event) => {
    this.props.deleteMyInventoryDetails(this.state.checkedInventoryPk);
  };

  triggerTransferAsset = (event) => {
    this.setState({
      dialogOpenAssetTransfer: true,
    });
    this.props.getMyAssetsDetails(this.state.checkedAssetPk);
  };

  handleClickCloseDialogAssetTransfer = () => {
    this.setState({
      dialogOpenAssetTransfer: false,
    });
    this.props.clearUserDetails("s");
  };

  handleTransferAsset = () => {
    const fromDate = getFormattedDate(new Date());
    const assetbody = {
      transferowner: this.state.newEmployeeSelected.id,
      acceptowner: Number(this.state.newEmployeeSelected.manager),
      status: "tr",
    };
    const assettransferlogbody = {
      fromUser: this.props.myAssetsDetails.owner.username,
      toUser: this.state.newEmployeeSelected.username,
      acceptUser: this.props.myManagerDetailsV2.username,
      fromDate: fromDate,
      toDate: null,
      acceptDate: null,
      toStatus: "wa",
      acceptStatus: "wa",
      equipment: this.props.myAssetsDetails.pk,
    };

    /*
    const smsbody = {
      recipient: this.props.userDetails.phone,
      sender: "WYPOSAZENIE",
      message:
        "Uzytkownik " +
        this.props.auth.user.username +
        " przekazal tobie srodek trwaly " +
        this.props.myAssetsDetails.model.name +
        " " +
        this.props.myAssetsDetails.model.description +
        ". Zaakceptuj, lub odrzuc przesuniecie.",
      owner: this.props.auth.user.id,
      notificationType: "sms",
      isDelivered: true,
    };
    */

    var tempSerialNumber = "Nie podano";
    var tempPartNumber = "Nie podano";

    if (this.props.myAssetsDetails.serialNumber)
      tempSerialNumber = this.props.myAssetsDetails.serialNumber

    if (this.props.myAssetsDetails.partNumber)
      tempPartNumber = this.props.myAssetsDetails.partNumber

    const notifyBody = {
      recipient: this.props.userDetails.email,
      module: "Środki Trwałe",
      topic: "Otrzymanie środka trwałego",
      message:
        "Użytkownik " + this.props.auth.user.first_name + " " + this.props.auth.user.last_name +
        " przekazał Tobie środek trwały: \n" +
        "Model: " + this.props.myAssetsDetails.model.name + "\n" +
        "Opis: " + this.props.myAssetsDetails.model.description + "\n" +
        "Numer seryjny: " + tempSerialNumber + "\n" +
        "Numer produktu: " + tempPartNumber + "\n" +
        "Zaakceptuj lub odrzuć przesunięcie w module Środki Trwałe w EXTRANET."
    }

    this.setState({
      dialogOpenAssetTransfer: false,
    });
    this.props.addAssetTransferLog(assettransferlogbody);
    this.props.patchMyAsset(
      this.props.myAssetsDetails.pk,
      this.props.myAssetsDetails.model.eqtype,
      assetbody,
      false
    );
    //this.props.sendNotification(smsbody);
    this.props.sendNewNotification(notifyBody);
  };

  handleClickCloseDialog = () => {
    this.setState({
      dialogOpen: false,
    });
  };

  handleOpenNewInvDialog = () => {
    this.setState({
      dialogNewInvAssetOpen: true,
    });
  };

  handleClickCloseNewInvDialog = () => {
    this.setState({
      dialogNewInvAssetOpen: false,
    });
  };

  handleDownload = (eqtype) => {
    var assetsToBeDownloaded = [];

    if (eqtype === "al") {
      assetsToBeDownloaded = this.props.myAssetsAl;
    } else if (eqtype === "na") {
      assetsToBeDownloaded = this.props.myAssetsNa;
    } else if (eqtype === "el") {
      assetsToBeDownloaded = this.props.myAssetsEl;
    } else if (eqtype === "it") {
      assetsToBeDownloaded = this.props.myAssetsIt;
    } else if (eqtype === "po") {
      assetsToBeDownloaded = this.props.myAssetsPo;
    } else if (eqtype === "ws") {
      assetsToBeDownloaded = this.props.myAssetsAl.concat(
        this.props.myAssetsNa,
        this.props.myAssetsEl,
        this.props.myAssetsIt,
        this.props.myAssetsPo
      );
    }

    if (assetsToBeDownloaded.length !== 0) {
      var csvRow = [];
      var A = [
        [
          "Grupa",
          "Nazwa",
          "Model",
          "Producent",
          "Numer seryjny",
          "Numer Produktu",
          "Data zakupu",
          "Data przekazania",
          "Opis dodatkowy",
          "Właściciel środka trwałego",
        ],
      ];
      var re = assetsToBeDownloaded;

      for (var item = 0; item < re.length; item++) {
        A.push([
          re[item].model.eqtype_description,
          re[item].model.name,
          re[item].model.description,
          re[item].model.vendor,
          re[item].serialNumber,
          re[item].partNumber,
          re[item].purchaseDate,
          re[item].assignDate,
          re[item].description,
          re[item].owner.first_name + " " + re[item].owner.last_name,
        ]);
      }

      for (var i = 0; i < A.length; ++i) {
        csvRow.push(A[i].join(";"));
      }

      var csvString = csvRow.join("\n");
      //var a = document.createElement("a");
      var link = window.document.createElement("a");
      link.setAttribute(
        "href",
        "data:text/csv;charset=utf-8,%EF%BB%BF" + encodeURI(csvString)
      );
      if (eqtype === "ws") {
        link.setAttribute(
          "download",
          `SrodkiTrwale${re[0].owner.first_name + re[0].owner.last_name}.csv`
        );
      } else {
        link.setAttribute(
          "download",
          `SrodkiTrwale${re[0].owner.first_name + re[0].owner.last_name + "_"}${re[0].model.eqtype_description
          }.csv`
        );
      }
      link.click();
    } else {
      this.setState({
        alertOpen: true,
      });
    }
  };

  setOpen = () => {
    const open = this.state.open;
    if (open === false && this.props.usersList.length === 0) {
      this.props.getUsersList();
    }
    this.setState({
      open: !open,
    });
  };

  getSelected = (event, value) => {
    this.setState({
      newEmployeeSelected: value,
    });
    if (value !== null) {
      this.props.getUserDetails(value.id, "s");
      this.props.getMyManagerDetailsV2(Number(value.manager));
    } else {
      this.props.clearUserDetails("s");
    }
  };

  handleTextChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  handleConfirmAsset = () => {
    const toDate = getFormattedDate(new Date());
    const bodytrl = {
      toStatus: "ta",
      toDate: toDate,
    };
    const bodyasset = {
      status: "ac",
    };

    /*
    const smsbodyemp = {
      recipient: this.props.myAssetsDetails.owner.phone,
      sender: "WYPOSAZENIE",
      message:
        "Uzytkownik " +
        this.props.auth.user.username +
        " przyjal srodek trwaly " +
        this.props.myAssetsDetails.model.name +
        " " +
        this.props.myAssetsDetails.model.description +
        ". Poczekaj na ostateczna akceptacje przelozonego.",
      owner: this.props.auth.user.id,
      notificationType: "sms",
      isDelivered: true,
    };

    const smsbodyman = {
      recipient: this.props.myAssetsDetails.acceptowner.phone,
      sender: "WYPOSAZENIE",
      message:
        "Uzytkownik " +
        this.props.myAssetsDetails.owner.username +
        " prosi o akceptacje przesuniecia srodka trwalego " +
        this.props.myAssetsDetails.model.name +
        " " +
        this.props.myAssetsDetails.model.description +
        " na uzytkownika " +
        this.props.myAssetsDetails.transferowner.username +
        ". Zaakceptuj lub odrzuc wniosek.",
      owner: this.props.auth.user.id,
      notificationType: "sms",
      isDelivered: true,
    };
    */

    var tempSerialNumber = "Nie podano";
    var tempPartNumber = "Nie podano";

    if (this.props.myAssetsDetails.serialNumber)
      tempSerialNumber = this.props.myAssetsDetails.serialNumber

    if (this.props.myAssetsDetails.partNumber)
      tempPartNumber = this.props.myAssetsDetails.partNumber

    const notifyBodyEMP = {
      recipient: this.props.myAssetsDetails.owner.email,
      module: "Środki Trwałe",
      topic: "Przyjęcie środka trwałego",
      message:
        "Użytkownik " + this.props.auth.user.first_name + " " + this.props.auth.user.last_name +
        " przyjął środek trwały: \n" +
        "Model: " + this.props.myAssetsDetails.model.name + "\n" +
        "Opis: " + this.props.myAssetsDetails.model.description + "\n" +
        "Numer seryjny: " + tempSerialNumber + "\n" +
        "Numer produktu: " + tempPartNumber + "\n" +
        "Poczekaj na ostateczną akceptację przełożonego."
    }
    const notifyBodyMAN = {
      module: "Środki Trwałe",
      topic: "Przekazanie środka trwałego",
      message:
        "Użytkownik " + this.props.myAssetsDetails.owner.first_name + " " + this.props.myAssetsDetails.owner.last_name +
        " prosi o przesunięcie środka trwałego: \n" +
        "Model: " + this.props.myAssetsDetails.model.name + "\n" +
        "Opis: " + this.props.myAssetsDetails.model.description + "\n" +
        "Numer seryjny: " + tempSerialNumber + "\n" +
        "Numer produktu: " + tempPartNumber + "\n" +
        "Na użytkownika " + this.props.myAssetsDetails.transferowner.first_name + " " + this.props.myAssetsDetails.transferowner.last_name + "." +
        "Zaakceptuj lub odrzuć wniosek w module Środki Trwałe w EXTRANET."
    }

    this.props.patchAssetTransferLog(
      Number(this.props.myAssetsDetails.currentEquipmentTransferLog),
      bodytrl
    );
    this.props.patchMyAsset(
      this.props.myAssetsDetails.pk,
      this.props.myAssetsDetails.model.eqtype,
      bodyasset,
      false
    );
    //this.props.sendNotification(smsbodyemp);
    //this.props.sendNotification(smsbodyman);
    this.props.sendNewNotification(notifyBodyEMP);
    this.props.sendNewNotificationControler(notifyBodyMAN);
    this.setState({
      dialogOpenAssetTransfer: false,
    });
  };

  handleDeclineAsset = () => {
    const date = getFormattedDate(new Date());
    const assetbody = {
      transferowner: null,
      acceptowner: null,
      status: "iu",
      currentEquipmentTransferLog: null,
    };
    const assettransferlogbody = {
      toDate: date,
      toStatus: "de",
      acceptDate: date,
      acceptStatus: "au",
    };

    /*
    const smsbody = {
      recipient: this.props.myAssetsDetails.owner.phone,
      sender: "WYPOSAZENIE",
      message:
        "Uzytkownik " +
        this.props.myAssetsDetails.transferowner.username +
        " nie przyjal srodka trwalego " +
        this.props.myAssetsDetails.model.name +
        " " +
        this.props.myAssetsDetails.model.description,
      owner: this.props.auth.user.id,
      notificationType: "sms",
      isDelivered: true,
    };
    */

    var tempSerialNumber = "Nie podano";
    var tempPartNumber = "Nie podano";

    if (this.props.myAssetsDetails.serialNumber)
      tempSerialNumber = this.props.myAssetsDetails.serialNumber

    if (this.props.myAssetsDetails.partNumber)
      tempPartNumber = this.props.myAssetsDetails.partNumber

    const notifyBody = {
      recipient: this.props.myAssetsDetails.owner.email,
      module: "Środki Trwałe",
      topic: "Odrzucenie środka trwałego",
      message:
        "Użytkownik " + this.props.auth.user.first_name + " " + this.props.auth.user.last_name +
        " nie przyjął środka trwałego: \n" +
        "Model: " + this.props.myAssetsDetails.model.name + "\n" +
        "Opis: " + this.props.myAssetsDetails.model.description + "\n" +
        "Numer seryjny: " + tempSerialNumber + "\n" +
        "Numer produktu: " + tempPartNumber + "\n"
    }

    this.setState({
      dialogOpenAssetTransfer: false,
    });
    this.props.patchAssetTransferLog(
      Number(this.props.myAssetsDetails.currentEquipmentTransferLog),
      assettransferlogbody
    );
    this.props.patchMyAsset(
      this.props.myAssetsDetails.pk,
      this.props.myAssetsDetails.model.eqtype,
      assetbody,
      true
    );
    //this.props.sendNotification(smsbody);
    this.props.sendNewNotification(notifyBody);
  };

  handleShowScrapageRequestedFilter = (showScrapageRequestedFilter) => {
    //1. ktos rozwija liste
    //2. ktos zwija liste i bylo cos na niej zaznaczone
    //3. ktos zwija liste i nie bylo na niej nic zaznaczone

    if (showScrapageRequestedFilter === true) {
      this.setState({
        showScrapageRequestedFilter: showScrapageRequestedFilter,
      });
    } else if (
      showScrapageRequestedFilter === false &&
      this.state.activeListType === "MyAssetFilterListSR"
    ) {
      this.setState({
        checkedAssetPk: null,
        isAssetChecked: false,
        activeListType: null,
        showScrapageRequestedFilter: showScrapageRequestedFilter,
      });
    } else if (
      showScrapageRequestedFilter === false &&
      this.state.activeListType !== "MyAssetFilterListSR"
    ) {
      this.setState({
        showScrapageRequestedFilter: showScrapageRequestedFilter,
      });
    }
  };

  handleshowWaitForReturnFilter = (showWaitForReturnFilter) => {
    //1. ktos rozwija liste
    //2. ktos zwija liste i bylo cos na niej zaznaczone
    //3. ktos zwija liste i nie bylo na niej nic zaznaczone

    if (showWaitForReturnFilter === true) {
      this.setState({
        showWaitForReturnFilter: showWaitForReturnFilter,
      });
    } else if (
      showWaitForReturnFilter === false &&
      this.state.activeListType === "MyAssetFilterListWR"
    ) {
      this.setState({
        checkedAssetPk: null,
        isAssetChecked: false,
        activeListType: null,
        showWaitForReturnFilter: showWaitForReturnFilter,
      });
    } else if (
      showWaitForReturnFilter === false &&
      this.state.activeListType !== "MyAssetFilterListWR"
    ) {
      this.setState({
        showWaitForReturnFilter: showWaitForReturnFilter,
      });
    }
  };

  handleshowHistoryFilter = (showHistoryFilter) => {
    //1. ktos rozwija liste
    //2. ktos zwija liste i bylo cos na niej zaznaczone
    //3. ktos zwija liste i nie bylo na niej nic zaznaczone

    if (showHistoryFilter === true) {
      this.setState({
        showHistoryFilter: showHistoryFilter,
      });
    } else if (
      showHistoryFilter === false &&
      this.state.activeListType === "MyAssetHistoryList"
    ) {
      this.setState({
        checkedAssetPk: null,
        isAssetChecked: false,
        activeListType: null,
        showHistoryFilter: showHistoryFilter,
      });
    } else if (
      showHistoryFilter === false &&
      this.state.activeListType !== "MyAssetHistoryList"
    ) {
      this.setState({
        showHistoryFilter: showHistoryFilter,
      });
    }
  };

  handleScrapAssetOpen = () => {
    this.setState({
      dialogOpenScrapAsset: true,
    });
  };

  handleScrapAssetClose = () => {
    this.setState({
      dialogOpenScrapAsset: false,
    });
  };

  handleScrapAsset = () => {
    const scrapageReadyDate = getFormattedDate(new Date());
    const assetbody = {
      scrapageReadyDate: scrapageReadyDate,
      scrapagedManager: Number(this.props.auth.main_profile.manager),
      status: "sr",
      scrapageUserComment: this.state.scrapageUserComment,
    };

    //console.log(assetbody);
    //console.log(this.state.checkedAssetPk, this.state.eqTypeSelected);
    this.props.scrapMyAsset(
      this.state.checkedAssetPk,
      this.state.eqTypeSelected,
      assetbody,
      "request"
    );

    this.handleNotifyScrapAsset();

    this.setState({
      dialogOpenScrapAsset: false,
    });
  };

  handleCancelScrapAsset = () => {
    //const scrapageReadyDate = getFormattedDate(new Date());
    const assetbody = {
      scrapageReadyDate: null,
      scrapagedManager: null,
      status: "iu",
      scrapageUserComment: null,
    };

    this.props.scrapMyAsset(
      this.state.checkedAssetPk,
      this.props.myAssetsDetails.model.eqtype,
      assetbody,
      "cancel"
    );
  };

  triggerNotifyScrapAsset = () => {
    this.setState({
      dialogNotifyScrap: true,
    });
  };

  handleCloseNotifyScrapAsset = () => {
    this.setState({
      dialogNotifyScrap: false,
    });
  };

  handleNotifyScrapAsset = () => {
    /*
    const smsbody = {
      recipient: this.props.myAssetsDetails.scrapagedManager.phone,
      sender: "WYPOSAZENIE",
      message:
        "Uzytkownik " +
        this.props.myAssetsDetails.username +
        " zawnioskowal o zlomowanie Srodkow Trwalych. Zaakceptuj lub odrzuc zlomowanie wybranych Srodkow Trwalych.",
      owner: this.props.auth.user.id,
      notificationType: "sms",
      isDelivered: true,
    };
    */

    const notifyBody = {
      module: "Środki Trwałe",
      topic: "Złomowanie sprzętu",
      message:
        "Użytkownik " + this.props.auth.user.first_name + " " + this.props.auth.user.last_name +
        " zawnioskował o złomowanie środka trwałego. \n" +
        "Zaakceptuj lub odrzuć złomowanie wybranych środków trwałych."
    }

    //this.props.sendNotification(smsbody);
    this.props.sendNewNotificationControler(notifyBody);

    this.setState({
      dialogNotifyScrap: false,
    });

    this.setState({
      alertSMSOpen: true,
    });
  };

  triggerAddCommentScrapAsset = () => {
    this.setState({
      dialogAddCommentScrapAsset: true,
    });
  };

  handleCloseAddCommentScrapAsset = () => {
    this.setState({
      dialogAddCommentScrapAsset: false,
    });
  };

  handleAddCommentScrapAsset = () => {
    const assetbody = {
      scrapageUserComment: this.state.scrapageUserComment,
    };

    this.props.scrapMyAssetUpdate(
      this.state.checkedAssetPk,
      this.state.eqTypeSelected,
      assetbody
    );
    this.setState({
      dialogAddCommentScrapAsset: false,
    });
  };

  handleTextList = (name) => ({ target: { value } }) => {
    this.setState({
      ...this.state,
      [name]: value,
    });
  };

  setFilter = (filtername) => {
    this.setState({
      activeFilter: filtername,
    });

    if (
      this.state.isAssetChecked &&
      this.state.activeListType !== "MyAssetList"
    ) {
      this.setState({
        checkedAssetPk: null,
        isAssetChecked: false,
        activeListType: null,
      });
    }
  };

  handleAcceptInventory = () => {
    const data = {
      "status": "re",
      "inventoryEndDate": new Date().toISOString(),
      "signedUser": this.props.auth.user.id,
      "signedUserDate": new Date().toISOString(),
    }

    const myInvAl = this.props.myInvAl;
    const myInvNa = this.props.myInvNa;
    const myInvEl = this.props.myInvEl;
    const myInvIt = this.props.myInvIt;
    const myInvPo = this.props.myInvPo;

    const calcInventory = myInvAl.length + myInvNa.length + myInvEl.length + myInvIt.length + myInvPo.length;
    const calcNotOnEmp = myInvAl.filter(asset => asset.isOwned == false).length + myInvNa.filter(asset => asset.isOwned == false).length + myInvEl.filter(asset => asset.isOwned == false).length + myInvIt.filter(asset => asset.isOwned == false).length + myInvPo.filter(asset => asset.isOwned == false).length;
    const clacAdded = myInvAl.filter(asset => asset.equipment == null).length + myInvNa.filter(asset => asset.equipment == null).length + myInvEl.filter(asset => asset.equipment == null).length + myInvIt.filter(asset => asset.equipment == null).length + myInvPo.filter(asset => asset.equipment == null).length;
    const calcOnEmp = calcInventory - calcNotOnEmp - clacAdded;

    const notifyBody = {
      user: this.props.auth.user.id,
      module: "Środki Trwałe",
      topic: "Zakończenie inwentaryzacji przez pracownika",
      message:
        "Użytkownik " + this.props.auth.user.first_name + " " + this.props.auth.user.last_name +
        " zgłosił inwenturę do oceny: \n" +
        "Ilość środków trwałych: " + calcInventory + "\n" +
        "Posiadane środki trwałe: " + calcOnEmp + "\n" +
        "Nieposiadane środki trwałe: " + calcNotOnEmp + "\n" +
        "Dodane środki trwałe: " + clacAdded + "\n" +
        "Więcej informacji znajdziesz w module Środki Trwałe w EXTRANET."
    }

    this.props.reportInventory(this.props.activeInventory, data);
    this.props.sendNewNotificationKeepers(notifyBody);
    this.props.sendNewNotificationControler(notifyBody);
  };

  handleOpenStep1 = (eventObj) => {
    this.setState({
      openStep1: true,
    })
  }

  handleCloseStep1 = () => {
    this.setState({
      openStep1: false,
      pesel: '',
      peselValid: null,
      peselError: false,
    })
  }

  handleOpenStep2 = (eventObj) => {
    this.handleNewCode();
    this.setState({
      openStep2: true,
    })
  }

  handleCloseStep2 = () => {
    this.setState({
      openStep2: false,
      code: '',
    })
  }

  handleNewCode = () => {
    const body = {
      user: this.props.auth.user.id,
      recipient: this.props.auth.user.email,
    }

    this.props.sendCode(body);
  }

  handleCheckPesel = () => {
    if (this.state.pesel == this.props.auth.main_profile.pesel) {
      this.setState({
        peselValid: true,
        peselError: false,
      })
    }
    else {
      this.setState({
        peselValid: false,
        peselError: true,
      })
    }
  }

  handleCheckCode = () => {
    const body = {
      user: this.props.auth.user.id,
      code: this.state.code,
    }
    this.props.verifyCode(body);
  }

  handleOpenCommentDialog = () => {
    this.setState({
      openCommentDialog: true,
      comment: this.props.myInventoryDetails.userComment,
    });
  }

  handleCloseCommentDialog = () => {
    this.setState({
      openCommentDialog: false,
    });
  }

  handleCommentChange = (event) => {
    this.setState({
      comment: event.target.value,
    });
  }

  handleSubmitComment = () => {
    const body = {
      "userComment": this.state.comment,
    }
    this.props.updateMyInventoryDetails(this.state.checkedInventoryPk, body);
    this.setState({
      openCommentDialog: false,
    });
  }

  render() {
    const {
      isAssetChecked,
      alertOpen,
      alertSMSOpen,
      eqTypeSelected,
      dialogOpen,
      dialogNotifyScrap,
      dialogOpenAssetTransfer,
      dialogAddCommentScrapAsset,
      open,
      activeFilter,
      activeListType,
      scrapageUserComment,
      checkedAssetStatus,
      dialogNewInvAssetOpen,
      dialogNoInvAssetOpen,
      openStep1,
      openStep2,
      code,
      pesel,
      peselError,
      peselValid,
      comment,
      openCommentDialog,
      dialogOpenScrapAsset,
    } = this.state;
    const {
      classes,
      usersList,
      myAssetsAl,
      myAssetsNa,
      myAssetsEl,
      myAssetsIt,
      myAssetsPo,
      myInvAl,
      myInvNa,
      myInvEl,
      myInvIt,
      myInvPo,
      queryInProgress,
      userDetails,
      activeInventory,
    } = this.props;

    const loading = open && usersList.length === 0;

    var myAssets = [];
    var myInventory = [];
    var myInventoryAdded = [];

    var myAssetsDetails = {};
    var myInventoryDetails = {};

    var assetDetails = null;
    var assetTransfer = null;
    var assetTransferButtonEnabled = null;
    var assetTransferButtonDisabled = null;
    var history = null;
    var inventoryDetails = null;
    var newInvAssetDialogContent = null;

    if (!activeInventory) {
      if (eqTypeSelected === "al") {
        myAssets = myAssetsAl;
      } else if (eqTypeSelected === "na") {
        myAssets = myAssetsNa;
      } else if (eqTypeSelected === "el") {
        myAssets = myAssetsEl;
      } else if (eqTypeSelected === "it") {
        myAssets = myAssetsIt;
      } else if (eqTypeSelected === "po") {
        myAssets = myAssetsPo;
      }
    } else {
      if (eqTypeSelected === "al") {
        myInventory = myInvAl;
      } else if (eqTypeSelected === "na") {
        myInventory = myInvNa;
      } else if (eqTypeSelected === "el") {
        myInventory = myInvEl;
      } else if (eqTypeSelected === "it") {
        myInventory = myInvIt;
      } else if (eqTypeSelected === "po") {
        myInventory = myInvPo;
      }
    }

    if (
      (dialogOpen === true || dialogOpenAssetTransfer === true) &&
      this.props.myAssetsDetails.model !== undefined
    ) {
      console.log("przypisuje");
      myAssetsDetails = this.props.myAssetsDetails;

      assetDetails = (
        <Grid container className={classes.root} spacing={2}>
          <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
            <Card style={{ overflow: "auto", margin: 0 }}>
              <CardBody>
                <Typography className={classes.textType}>
                  {myAssetsDetails.model.eqtype_description}
                </Typography>
                <Typography className={classes.textName}>
                  <b>{myAssetsDetails.model.name}</b>
                </Typography>
                <Typography className={classes.textDescription}>
                  {myAssetsDetails.model.description}
                </Typography>
              </CardBody>
            </Card>
          </Grid>
          <Grid p={0} m={0} item xs={12} sm={6} md={3} lg={3}>
            <Card style={{ margin: 0 }}>
              <CardHeader
                title={
                  <Typography className={classes.textName2}>
                    Producent
                  </Typography>
                }
              ></CardHeader>
              <CardBody>
                <Typography className={classes.textDescription2}>
                  {myAssetsDetails.model.vendor}
                </Typography>
              </CardBody>
            </Card>
          </Grid>
          <Grid p={0} m={0} item xs={12} sm={6} md={3} lg={3}>
            <Card style={{ margin: 0 }}>
              <CardHeader
                title={
                  <Typography className={classes.textName2}>
                    Numer seryjny
                  </Typography>
                }
              ></CardHeader>
              <CardBody>
                <Typography className={classes.textDescription2}>
                  {myAssetsDetails.serialNumber === "" || myAssetsDetails.serialNumber === null
                    ? "-BRAK-"
                    : myAssetsDetails.serialNumber}
                </Typography>
              </CardBody>
            </Card>
          </Grid>
          <Grid p={0} m={0} item xs={12} sm={6} md={3} lg={3}>
            <Card style={{ margin: 0 }}>
              <CardHeader
                title={
                  <Typography className={classes.textName2}>
                    Numer produktu
                  </Typography>
                }
              ></CardHeader>
              <CardBody>
                <Typography className={classes.textDescription2}>
                  {myAssetsDetails.partNumber === "" || myAssetsDetails.partNumber === null
                    ? "-BRAK-"
                    : myAssetsDetails.partNumber}
                </Typography>
              </CardBody>
            </Card>
          </Grid>
          <Grid p={0} m={0} item xs={12} sm={6} md={3} lg={3}>
            <Card style={{ margin: 0 }}>
              <CardHeader
                title={
                  <Typography className={classes.textName2}>
                    Data zakupu
                  </Typography>
                }
              ></CardHeader>
              <CardBody>
                <Typography className={classes.textDescription2}>
                  {myAssetsDetails.purchaseDate === null
                    ? "-BRAK-"
                    : myAssetsDetails.purchaseDate}
                </Typography>
              </CardBody>
            </Card>
          </Grid>
          <Grid p={0} m={0} item xs={12} sm={6} md={3} lg={3}>
            <Card style={{ margin: 0 }}>
              <CardHeader
                title={
                  <Typography className={classes.textName2}>
                    Data przekazania
                  </Typography>
                }
              ></CardHeader>
              <CardBody>
                <Typography className={classes.textDescription2}>
                  {myAssetsDetails.assignDate}
                </Typography>
              </CardBody>
            </Card>
          </Grid>
          <Grid p={0} m={0} item xs={12} sm={6} md={6} lg={6}>
            <Card className={classes.info} style={{ margin: 0 }}>
              <CardBody>
                <Typography className={classes.textName2}>
                  Informacje dodatkowe
                </Typography>
                <Typography className={classes.textDescription}>
                  {myAssetsDetails.description === ""
                    ? "-BRAK-"
                    : myAssetsDetails.description}
                </Typography>
              </CardBody>
            </Card>
          </Grid>
          <Grid p={0} m={0} item xs={12} sm={6} md={3} lg={3}>
            <Card style={{ overflow: "auto", margin: 0 }}>
              <CardBody>
                <Grid container className={classes.root} spacing={2}>
                  <Grid item xs={12} m={1}>
                    <Typography className={classes.textName2}>
                      Właściciel środka trwałego:
                    </Typography>
                  </Grid>
                  <Grid item xs={2} sm={3} md={3} lg={2}>
                    <Avatar style={{ backgroundColor: "#AE9336" }}>
                      {myAssetsDetails.owner.username
                        .substr(0, 2)
                        .toUpperCase()}
                    </Avatar>
                  </Grid>
                  <Grid
                    item
                    xs={10}
                    sm={9}
                    md={9}
                    lg={10}
                    style={{ position: "relative" }}
                  >
                    <Typography
                      className={classes.textDescription2}
                      style={{
                        margin: 0,
                        position: "absolute",
                        top: "50%",
                        transform: "translate(0, -50%",
                      }}
                    >
                      &nbsp;{myAssetsDetails.owner.first_name}{" "}
                      {myAssetsDetails.owner.last_name}
                    </Typography>
                  </Grid>
                </Grid>
              </CardBody>
            </Card>
          </Grid>
        </Grid>
      );

      assetTransferButtonEnabled = (
        <Grid container className={classes.root} spacing={2} justify="center">
          <Button
            variant="contained"
            color="inherit"
            size="large"
            onClick={this.handleTransferAsset}
            style={{ marginTop: "20px" }}
            className={classes.buttonAccept}
          >
            Przekaż
          </Button>
        </Grid>
      );

      assetTransferButtonDisabled = (
        <Grid container className={classes.root} spacing={2} justify="center">
          <Button
            disabled
            variant="contained"
            size="large"
            style={{ marginTop: "20px" }}
          >
            Przekaż
          </Button>
        </Grid>
      );

      var assetTransferInfoFrom = (
        <Fragment>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            style={{ marginTop: "20px" }}
          >
            <Typography className={classes.textType2}>
              Przesunięcie tego środka trwałego zostało już zlecone. Poczekaj na
              akceptację pracownika przyjmującego środek trwały i kierownika,
              lub poproś go o odrzucenie przesunięcia...
            </Typography>
          </Grid>
        </Fragment>
      );

      var assetTransferInfoTo = (
        <Fragment>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            style={{ marginTop: "20px" }}
          >
            <Typography className={classes.textType2}>
              Już zaakceptowałeś przyjęcie tego środka trwałego. Poczekaj na
              ostateczną akceptację kierownika, lub poproś go o odrzucenie
              przesunięcia...
            </Typography>
          </Grid>
        </Fragment>
      );

      var assetTransferConfirmation = (
        <Fragment>
          <Grid
            container
            className={classes.root}
            spacing={2}
            justify="flex-start"
          >
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              style={{ marginTop: "20px" }}
            >
              <Typography className={classes.textType2}>
                Czy potwierdzasz przyjęcie środka trwałego na swój stan ?
              </Typography>
            </Grid>
            <ThemeProvider theme={materialTheme}>
              <Grid item style={{ marginTop: "20px" }}>
                <Button
                  color="primary"
                  variant="contained"
                  size="large"
                  onClick={this.handleConfirmAsset}
                  style={{ marginTop: "20px" }}
                >
                  Potwierdzam
                </Button>
              </Grid>
              <Grid item style={{ marginTop: "20px" }}>
                <Button
                  color="secondary"
                  size="large"
                  onClick={this.handleDeclineAsset}
                  style={{ marginTop: "20px" }}
                >
                  Odrzuć
                </Button>
              </Grid>
            </ThemeProvider>
          </Grid>
        </Fragment>
      );

      var assetTransferChoseEmployee = (
        <Fragment>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            style={{ marginTop: "20px" }}
          >
            <Typography className={classes.textType2}>
              Komu chcesz przekazać sprzęt ?
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Card style={{ margin: 0, overflow: "auto" }}>
              <CardBody>
                <Typography
                  style={{ marginBottom: "50px" }}
                  className={classes.textType}
                >
                  Wybierz pracownika któremu chcesz przekazać środek trwały
                </Typography>
                <Typography className={classes.textName}>Pracownik</Typography>
                <ThemeProvider theme={materialTheme}>
                  <Autocomplete
                    id="asynchronous-demo"
                    style={{ width: "300px" }}
                    open={open}
                    onOpen={this.setOpen}
                    onClose={this.setOpen}
                    onChange={this.getSelected}
                    getOptionSelected={(option, value) =>
                      option.last_name === value.last_name &&
                      option.first_name === value.first_name
                    }
                    getOptionLabel={(option) =>
                      option.first_name + " " + option.last_name
                    }
                    options={usersList}
                    loading={loading}
                    renderInput={(params) => (
                      <CssTextField
                        {...params}
                        label="wybierz z listy..."
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <React.Fragment>
                              {loading ? (
                                <CircularProgress color="inherit" size={20} />
                              ) : null}
                              {params.InputProps.endAdornment}
                            </React.Fragment>
                          ),
                        }}
                      />
                    )}
                  />
                </ThemeProvider>
              </CardBody>
            </Card>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            {!queryInProgress && userDetails.pk
              ? assetTransferButtonEnabled
              : ""}
            {!queryInProgress && !userDetails.pk
              ? assetTransferButtonDisabled
              : ""}
          </Grid>
        </Fragment>
      );

      assetTransfer = (
        <Grid container className={classes.root} spacing={2} justify="center">
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            style={{ marginTop: "20px" }}
          >
            <Typography className={classes.textType2}>
              Szczegóły przekazywanego środka trwałego
            </Typography>
          </Grid>

          <Grid p={0} m={0} item xs={12} sm={6} md={3} lg={3}>
            <Card style={{ margin: 0 }}>
              <CardBody>
                <Typography className={classes.textType}>
                  {myAssetsDetails.model.eqtype_description}
                </Typography>
                <Typography className={classes.textName}>
                  <b>{myAssetsDetails.model.name}</b>
                </Typography>
                <Typography className={classes.textDescription}>
                  {myAssetsDetails.model.description}
                </Typography>
              </CardBody>
            </Card>
          </Grid>

          <Grid p={0} m={0} item xs={12} sm={6} md={3} lg={3}>
            <Card style={{ margin: 0 }}>
              <CardHeader
                title={
                  <Typography className={classes.textName2}>
                    Producent
                  </Typography>
                }
              ></CardHeader>
              <CardBody>
                <Typography className={classes.textDescription2}>
                  {myAssetsDetails.model.vendor}
                </Typography>
              </CardBody>
            </Card>
          </Grid>
          <Grid p={0} m={0} item xs={12} sm={6} md={3} lg={3}>
            <Card style={{ margin: 0 }}>
              <CardHeader
                title={
                  <Typography className={classes.textName2}>
                    Numer seryjny
                  </Typography>
                }
              ></CardHeader>
              <CardBody>
                <Typography className={classes.textDescription2}>
                  {myAssetsDetails.serialNumber === ""
                    ? "-BRAK-"
                    : myAssetsDetails.serialNumber}
                </Typography>
              </CardBody>
            </Card>
          </Grid>
          <Grid p={0} m={0} item xs={12} sm={6} md={3} lg={3}>
            <Card style={{ margin: 0 }}>
              <CardHeader
                title={
                  <Typography className={classes.textName2}>
                    Numer produktu
                  </Typography>
                }
              ></CardHeader>
              <CardBody>
                <Typography className={classes.textDescription2}>
                  {myAssetsDetails.partNumber === ""
                    ? "-BRAK-"
                    : myAssetsDetails.partNumber}
                </Typography>
              </CardBody>
            </Card>
          </Grid>
          {this.props.myAssetsDetails.status === "iu"
            ? assetTransferChoseEmployee
            : ""}
          {(this.props.myAssetsDetails.status === "tr" ||
            this.props.myAssetsDetails.status === "ac") &&
            this.props.myAssetsDetails.owner.pk === this.props.auth.user.id
            ? assetTransferInfoFrom
            : ""}
          {this.props.myAssetsDetails.status === "tr" &&
            this.props.myAssetsDetails.transferowner.pk ===
            this.props.auth.user.id
            ? assetTransferConfirmation
            : ""}
          {this.props.myAssetsDetails.status === "ac" &&
            this.props.myAssetsDetails.transferowner.pk ===
            this.props.auth.user.id
            ? assetTransferInfoTo
            : ""}
        </Grid>
      );

      history = (
        <Fragment>
          <TransferLog assetsDetails={this.props.myAssetsDetails} />
        </Fragment>
      );
    } else {
      assetDetails = <Fragment></Fragment>;

      assetTransfer = <Fragment></Fragment>;

      assetTransferButtonEnabled = <Fragment></Fragment>;

      assetTransferButtonDisabled = <Fragment></Fragment>;

      history = <Fragment></Fragment>;
    }

    if (
      (dialogOpen === true) &&
      this.props.myInventoryDetails.pk !== undefined
    ) {
      inventoryDetails = (
        <MyInventoryDetails myInventoryDetails={this.props.myInventoryDetails}></MyInventoryDetails>
      );
    } else {
      inventoryDetails = <Fragment></Fragment>;
    }

    if (dialogNewInvAssetOpen) {
      newInvAssetDialogContent = (
        <NewAssetDialog closeDialog={this.handleClickCloseNewInvDialog} invId={activeInventory} invType={eqTypeSelected}></NewAssetDialog>
      );
    } else {
      newInvAssetDialogContent = <Fragment></Fragment>;
    }

    const alert = (
      <Snackbar
        open={alertOpen}
        autoHideDuration={6000}
        onClose={this.handleAlertClose}
      >
        <Alert variant="filled" severity="info">
          Brak danych do zaimportowania!
        </Alert>
      </Snackbar>
    );

    const alertSMS = (
      <Snackbar
        open={alertSMSOpen}
        autoHideDuration={6000}
        onClose={this.handleAlertSMSClose}
      >
        <Alert variant="filled" severity="info">
          Wysłano SMS do przełożonego!
        </Alert>
      </Snackbar>
    );

    const circle = (
      <Grid
        container
        className={classes.root}
        spacing={0}
        justify="center"
        alignItems="center"
      >
        <CircularProgress color="secondary" />
      </Grid>
    );

    const myAssetFilterList = (
      <MyAssetFilterList
        checked={this.handleCheckedAsset}
        activeListType={activeListType}
        activeFilter={activeFilter}
      />
    );

    const myAssetHistoryList = (
      <MyAssetHistoryList
        checked={this.handleCheckedAsset}
        activeListType={activeListType}
        activeFilter={activeFilter}
      />
    );

    const previewButtonDi = (
      <Fab
        disabled
        color="inherit"
        className={classes.fabblue}
        size="small"
        aria-label="scroll back to top"
      >
        <VisibilityIcon />
      </Fab>
    );

    const previewButtonEn = (
      <Fab
        color="inherit"
        className={classes.fabblue}
        size="small"
        aria-label="scroll back to top"
      >
        <VisibilityIcon />
      </Fab>
    );

    const notifyButtonDi = (
      <Fab
        disabled
        color="inherit"
        className={classes.fabgreen}
        size="small"
        aria-label="scroll back to top"
      >
        <NotificationsActiveIcon />
      </Fab>
    );

    const notifyButtonEn = (
      <Fab
        color="inherit"
        className={classes.fabgreen}
        size="small"
        aria-label="scroll back to top"
      >
        <NotificationsActiveIcon />
      </Fab>
    );

    var dialogComment = "";
    if (dialogAddCommentScrapAsset === true) {
      dialogComment = (
        <Dialog
          open={dialogAddCommentScrapAsset}
          onClose={this.handleCloseAddCommentScrapAsset}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            Komentarz do {this.props.myAssetsDetails.model.name} |{" "}
            {this.props.myAssetsDetails.model.description}
          </DialogTitle>
          <DialogContent>
            <Typography className={classes.textDescription3}>
              Dodaj krótki komentarz, uzasadniający wniosek o złomowanie.
            </Typography>
            <br></br>
            <Typography className={classes.textDescription4}>
              (max 30 znaków)
            </Typography>
            <ThemeProvider theme={materialTheme}>
              <TextField
                autoFocus
                margin="dense"
                id="name"
                label="uzasadnienie"
                fullWidth
                inputProps={{ maxLength: 30 }}
                value={scrapageUserComment || ""}
                onChange={this.handleTextList("scrapageUserComment")}
              />
            </ThemeProvider>
          </DialogContent>
          <ThemeProvider theme={materialTheme}>
            <DialogActions>
              <Button
                onClick={this.handleCloseAddCommentScrapAsset}
                size="large"
                color="default"
              >
                Anuluj
              </Button>
              <Button
                onClick={this.handleAddCommentScrapAsset}
                color="primary"
                variant="contained"
                autoFocus
                size="large"
              >
                Dodaj
              </Button>
            </DialogActions>
          </ThemeProvider>
        </Dialog>
      );
    }

    const scrapcard = (
      <MyAssetScrapageRequestedCard
        //showScrapageRequestedFilter={this.handleShowScrapageRequestedFilter}
        //waitForReturnFilter={showWaitForReturnFilter}
        //historyFilter={showHistoryFilter}
        setFilter={this.setFilter}
        activeFilter={activeFilter}
      />
    );

    const returncard = (
      <MyAssetWaitForReturnCard
        //showWaitForReturnFilter={this.handleshowWaitForReturnFilter}
        //scrapageRequestedFilter={showScrapageRequestedFilter}
        //historyFilter={showHistoryFilter}
        setFilter={this.setFilter}
        activeFilter={activeFilter}
      />
    );

    const historycard = (
      <MyAssetHistoryCard
        //showHistoryFilter={this.handleshowHistoryFilter}
        //scrapageRequestedFilter={showScrapageRequestedFilter}
        //waitForReturnFilter={showWaitForReturnFilter}
        setFilter={this.setFilter}
        activeFilter={activeFilter}
      />
    );

    const acceptInventoryEnabled = (
      <Button
        onClick={this.handleOpenStep1}
        variant="contained"
        color="primary"
        fullWidth
        style={{ padding: "12.5px 8px" }}
      >
        Zgłoś inwentaryzacje
      </Button>
    );

    const acceptInventoryDisabled = (
      <Button
        variant="contained"
        color="primary"
        fullWidth
        disabled
        style={{ padding: "12.5px 8px" }}
      >
        Zgłoś inwentaryzacje
      </Button>
    );

    const calcInventory = myInvAl.length + myInvNa.length + myInvEl.length + myInvIt.length + myInvPo.length;
    const calcDecided = myInvAl.filter(asset => asset.isOwned != null).length + myInvNa.filter(asset => asset.isOwned != null).length + myInvEl.filter(asset => asset.isOwned != null).length + myInvIt.filter(asset => asset.isOwned != null).length + myInvPo.filter(asset => asset.isOwned != null).length;
    const clacAdded = myInvAl.filter(asset => asset.equipment == null).length + myInvNa.filter(asset => asset.equipment == null).length + myInvEl.filter(asset => asset.equipment == null).length + myInvIt.filter(asset => asset.equipment == null).length + myInvPo.filter(asset => asset.equipment == null).length;

    const activeInventoryInfo = (
      <ThemeProvider theme={materialTheme}>
        <Grid container justify="center">
          <Grid item xs={12} sm={12} md={12}>
            <Card style={{ overflow: "auto", margin: "16px 0px" }}>
              <CardBody>
                <Typography style={{ fontWeight: "bold" }}>
                  UWAGA!
                </Typography>
                <Typography>
                  Aktywna inwentaryzacja. Wszystkie operacje na środkach trwałych są zablokowane.
                </Typography>
                <br></br>
                <Typography>
                  Liczba środków trwałych w tej kategorii: {myInventory.length}
                </Typography>
                <Typography>
                  Środki trwałe pozostałe do sprawdzenia: {myInventory.filter(asset => asset.isOwned == null).length}
                </Typography>
                <br></br>
                <Typography>
                  Postęp ogółem: {(calcInventory != 0 ? (calcDecided / calcInventory * 100) : 0).toFixed(2)}%
                </Typography>
                <Typography>
                  Dodane środki trwałe: {clacAdded}
                </Typography>
                <div style={{ marginBottom: "16px" }}></div>
                {calcInventory == calcDecided ? acceptInventoryEnabled : acceptInventoryDisabled}
              </CardBody>
            </Card>
          </Grid>
        </Grid>
      </ThemeProvider>
    )

    var myAssetDetailsDialog = (
      <DialogContent style={{ background: "#f5f5f5" }}>
        {queryInProgress ? circle : assetDetails}
        <div style={{ marginTop: 20 }}></div>
        {!queryInProgress ? history : ""}
      </DialogContent>
    );

    var myInventoryDetailsDialog = (
      <DialogContent style={{ background: "#f5f5f5" }}>
        {queryInProgress ? circle : inventoryDetails}
      </DialogContent>
    );

    const diaolgPesel = (
      <Dialog open={openStep1} onClose={this.handleCloseStep1}>
        <DialogTitle>Autoryzacja wniosku: Krok 1 - PESEL</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Podaj swój numer PESEL
          </DialogContentText>
          <TextField
            margin="dense"
            id="pesel"
            label="Wprowadź PESEL"
            type="text"
            fullWidth
            variant="standard"
            value={pesel}
            onChange={this.handleTextList("pesel")}
            onKeyPress={this.handleKeyPress}
            error={peselError}
            helperText={peselError ? "Nieprawidłowy numer PESEL" : ""}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleCloseStep1}>Anuluj</Button>
          <Button onClick={this.handleCheckPesel}>Dalej</Button>
        </DialogActions>
      </Dialog>
    )

    const contact_string = 'mailto:it@radioline.com.pl?subject=Uzupełnienie danych - ' + this.props.auth.user.first_name + ' ' + this.props.auth.user.last_name;

    const diaolgNoPesel = (
      <Dialog open={openStep1} onClose={this.handleCloseStep1}>
        <DialogTitle>Błąd - brak wymaganych danych w systemie</DialogTitle>
        <DialogContent>
          <DialogContentText>
            W celu uzupełnienia danych skontaktuj się z działem IT.
          </DialogContentText>
          <DialogContentText>
            Kontakt: <a href={contact_string}>it@radioline.com.pl</a>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleCloseStep1}>Zamknij</Button>
        </DialogActions>
      </Dialog>
    )

    return (
      <Fragment>
        <div className={classes.section}>
          <Box m={2}>
            <Grid container justify="flex-start" spacing={2}>
              {scrapcard}
              {returncard}
              {historycard}
              {activeFilter === "scrapfilter" || activeFilter === "returnfilter"
                ? myAssetFilterList
                : ""}
              {activeFilter === "historyfilter" ? myAssetHistoryList : ""}
            </Grid>
            {activeInventory ? activeInventoryInfo : <div style={{ marginTop: "30px" }}></div>}
            <AssetNavigation eqTypeSelected={this.handleEqTypeSelected} />
            {activeInventory ?
              <MyInventoryList
                assets={myInventory}
                addedAssets={myInventoryAdded}
                checked={this.handleCheckedInventory}
                downloadTrigger={this.handleDownload}
                openDialogTrigger={this.handleOpenNewInvDialog}
                eqTypeSelected={eqTypeSelected}
                activeListType={activeListType} /> :
              <MyAssetList
                assets={myAssets}
                checked={this.handleCheckedAsset}
                downloadTrigger={this.handleDownload}
                eqTypeSelected={eqTypeSelected}
                activeListType={activeListType}
              />}
          </Box>
        </div>
        <Zoom in={isAssetChecked}>
          <div
            onClick={this.handleClickPreview}
            role="presentation"
            className={classes.fb2}
          >
            <Tooltip title="Pokaż szczegóły" placement="left">
              {queryInProgress ? previewButtonDi : previewButtonEn}
            </Tooltip>
          </div>
        </Zoom>
        <Zoom in={isAssetChecked && activeInventory && this.props.myInventoryDetails.equipment != null}>
          <div
            onClick={this.handleClickInvNo}
            role="presentation"
            className={classes.fb1}
          >
            <Tooltip title="Nie posiadam" placement="left">
              <Fab
                color="inherit"
                className={classes.fabred}
                size="small"
                aria-label="scroll back to top"
              >
                <CloseIcon />
              </Fab>
            </Tooltip>
          </div>
        </Zoom>
        <Zoom in={isAssetChecked && activeInventory && this.props.myInventoryDetails.equipment != null}>
          <div
            onClick={this.handleClickInvYes}
            role="presentation"
            className={classes.fb3}
          >
            <Tooltip title="Posiadam" placement="left">
              <Fab
                color="inherit"
                className={classes.fabgreen}
                size="small"
                aria-label="scroll back to top"
              >
                <CheckIcon />
              </Fab>
            </Tooltip>
          </div>
        </Zoom>
        <Zoom in={isAssetChecked && activeInventory && this.props.myInventoryDetails.equipment == null}>
          <div
            onClick={this.handleClickInvDelete}
            role="presentation"
            className={classes.fb6}
          >
            <Tooltip title="Usuń" placement="left">
              <Fab
                color="inherit"
                className={classes.fabred}
                size="small"
                aria-label="scroll back to top"
              >
                <DeleteForeverIcon />
              </Fab>
            </Tooltip>
          </div>
        </Zoom>
        <Zoom
          in={
            isAssetChecked &&
            activeListType === "MyAssetList" &&
            (checkedAssetStatus === "iu" || checkedAssetStatus === "tr")
          }
        >
          <div
            onClick={this.triggerTransferAsset}
            role="presentation"
            className={classes.fb5}
          >
            <Tooltip title="Przekaż/Przyjmij" placement="left">
              <Fab
                color="inherit"
                className={classes.fabgreen}
                size="small"
                aria-label="scroll back to top"
              >
                <SupervisorAccountIcon />
              </Fab>
            </Tooltip>
          </div>
        </Zoom>
        <Zoom
          in={
            isAssetChecked &&
            activeListType === "MyAssetList" &&
            checkedAssetStatus === "iu"
          }
        >
          <div
            onClick={this.handleScrapAssetOpen}
            role="presentation"
            className={classes.fb6}
          >
            <Tooltip title="Wnioskuj o złomowanie" placement="left">
              <Fab
                color="inherit"
                className={classes.fabred}
                size="small"
                aria-label="scroll back to top"
              >
                <DeleteSweepIcon />
              </Fab>
            </Tooltip>
          </div>
        </Zoom>
        <Zoom in={isAssetChecked && activeListType === "MyAssetFilterListSR"}>
          <div
            onClick={this.handleCancelScrapAsset}
            role="presentation"
            className={classes.fb5}
          >
            <Tooltip title="Usuń z wniosku o złomowanie" placement="left">
              <Fab
                color="inherit"
                className={classes.fabgray}
                size="small"
                aria-label="scroll back to top"
              >
                <CloseIcon />
              </Fab>
            </Tooltip>
          </div>
        </Zoom>
        <Dialog
          open={dialogOpenScrapAsset}
          onClose={this.handleScrapAssetClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            Wymagany komentarz
          </DialogTitle>
          <DialogContent>
            <Typography className={classes.textDescription3}>
              Dodaj krótki komentarz, uzasadniający wniosek o złomowanie.
            </Typography>
            <br></br>
            <Typography className={classes.textDescription4}>
              (max 30 znaków)
            </Typography>
            <ThemeProvider theme={materialTheme}>
              <TextField
                autoFocus
                margin="dense"
                id="name"
                label="uzasadnienie"
                fullWidth
                inputProps={{ maxLength: 30 }}
                value={scrapageUserComment || ""}
                onChange={this.handleTextList("scrapageUserComment")}
              />
            </ThemeProvider>
          </DialogContent>
          <ThemeProvider theme={materialTheme}>
            <DialogActions>
              <Button
                onClick={this.handleScrapAssetClose}
                size="large"
                color="default"
              >
                Anuluj
              </Button>
              <Button
                onClick={this.handleScrapAsset}
                color="primary"
                variant="contained"
                autoFocus
                size="large"
              >
                Dodaj
              </Button>
            </DialogActions>
          </ThemeProvider>
        </Dialog>

        <Zoom in={isAssetChecked && activeListType === "MyAssetFilterListSR"}>
          <div
            onClick={this.triggerAddCommentScrapAsset}
            role="presentation"
            className={classes.fb6}
          >
            <Tooltip title="Dodaj komentarz" placement="left">
              <Fab
                color="inherit"
                className={classes.fabblue}
                size="small"
                aria-label="scroll back to top"
              >
                <CommentIcon />
              </Fab>
            </Tooltip>
          </div>
        </Zoom>
        <Zoom in={isAssetChecked && activeListType === "MyAssetFilterListSR"}>
          <div
            onClick={this.triggerNotifyScrapAsset}
            role="presentation"
            className={classes.fb1}
          >
            <Tooltip title="Poproś o akceptację wniosku" placement="left">
              {queryInProgress ? notifyButtonDi : notifyButtonEn}
            </Tooltip>
          </div>
        </Zoom>
        {alert}
        {alertSMS}

        <Zoom in={isAssetChecked && activeInventory}>
          <div
            onClick={this.handleOpenCommentDialog}
            role="presentation"
            className={classes.fb5}
          >
            <Tooltip title="Dodaj komentarz" placement="left">
              <Fab
                color="inherit"
                className={classes.fabblue}
                size="small"
                aria-label="scroll back to top"
              >
                <CommentIcon />
              </Fab>
            </Tooltip>
          </div>
        </Zoom>
        <Dialog open={openCommentDialog} onClose={this.handleCloseCommentDialog} fullWidth maxWidth="md">
          <DialogTitle>Dodaj komentarz</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              variant="outlined"
              label="Komentarz"
              type="text"
              fullWidth
              value={comment}
              onChange={this.handleCommentChange}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleCloseCommentDialog} color="primary">
              Anuluj
            </Button>
            <Button onClick={this.handleSubmitComment} color="primary">
              Dodaj
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          fullScreen
          open={dialogOpen}
          onClose={this.handleClickCloseDialog}
          aria-labelledby="form-dialog-title"
        >
          <AppBar position="static" style={{ background: "#0071BC" }}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={this.handleClickCloseDialog}
                aria-label="close"
                className={classes.menuButton}
              >
                <CloseIcon />
              </IconButton>
              <Typography variant="h6" className={classes.title}>
                Szczegóły środka trwałego
              </Typography>
            </Toolbar>
          </AppBar>
          {activeInventory ? myInventoryDetailsDialog : myAssetDetailsDialog}
        </Dialog>

        <Dialog
          fullScreen
          open={dialogNewInvAssetOpen}
          onClose={this.handleClickCloseNewInvDialog}
          aria-labelledby="form-dialog-title"
        >
          <AppBar position="static" style={{ background: "#0071BC" }}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={this.handleClickCloseNewInvDialog}
                aria-label="close"
                className={classes.menuButton}
              >
                <CloseIcon />
              </IconButton>
              <Typography variant="h6" className={classes.title}>
                Dodaj brakujący środek trwały
              </Typography>
            </Toolbar>
          </AppBar>
          <DialogContent style={{ background: "#f5f5f5" }}>
            {newInvAssetDialogContent}
          </DialogContent>
        </Dialog>

        <Dialog
          open={dialogNoInvAssetOpen}
          onClose={this.handleClickCloseNoInvDialog}
          aria-labelledby="form-dialog-title"
          fullWidth maxWidth="md"
        >
          <DialogTitle>Podaj powód braku sprzętu</DialogTitle>
          <DialogContent>
            <TextField
              name="assetDescription"
              variant="outlined"
              margin="dense"
              fullWidth
              value={this.state.assetDescription}
              onChange={this.handleTextChange}
              placeholder="np. skradziony, zgubiony..."
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClickCloseNoInvDialog} color="primary">
              Anuluj
            </Button>
            <Button onClick={this.handleNoInvAsset} color="primary" disabled={this.state.assetDescription === ""}>
              Dodaj
            </Button>
          </DialogActions>
        </Dialog>

        {this.props.auth.main_profile.pesel != null ? diaolgPesel : diaolgNoPesel}

        <Dialog open={openStep2} onClose={this.handleCloseStep2}>
          <DialogTitle>Autoryzacja wniosku: Krok 2 - Kod E-Mail</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Na twój adres e-mail został wysłany 6-cyfrowy kod autoryzacyjny.
            </DialogContentText>
            <TextField
              margin="dense"
              id="code"
              label="Wprowadź kod"
              type="text"
              fullWidth
              variant="standard"
              value={code}
              onChange={this.handleTextList("code")}
              onKeyPress={this.handleKeyPress}
            />
            <DialogContentText></DialogContentText>
            <DialogContentText>
              <Button onClick={this.handleNewCode}>Wyślij nowy kod</Button>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleCloseStep2}>Anuluj</Button>
            <Button onClick={this.handleCheckCode}>Podpisz</Button>
          </DialogActions>
        </Dialog>

        <Dialog
          fullScreen
          open={dialogOpenAssetTransfer}
          onClose={this.handleClickCloseDialogAssetTransfer}
          aria-labelledby="form-dialog-title"
        >
          <AppBar position="static" style={{ background: "#0071BC" }}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={this.handleClickCloseDialogAssetTransfer}
                aria-label="close"
                className={classes.menuButton}
              >
                <CloseIcon />
              </IconButton>
              <Typography variant="h6" className={classes.title}>
                Przekaż środek trwały
              </Typography>
            </Toolbar>
          </AppBar>
          <DialogContent style={{ background: "#f5f5f5" }}>
            {queryInProgress ? circle : assetTransfer}
            <div style={{ marginTop: 20 }}></div>
          </DialogContent>
        </Dialog>

        <Dialog
          open={dialogNotifyScrap}
          onClose={this.handleCloseNotifyScrapAsset}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Czy wysłać prośbę o złomowanie ?"}
          </DialogTitle>
          <DialogContent>
            <Typography className={classes.textDescription3}>
              Po kliknięciu przycisku POWIADOM, twój przełożony otrzyma wiadomość z
              prośbą o złomowanie Środków Trwałych z listy.
            </Typography>
            <br></br>
            <Typography className={classes.textDescription4}>
              UWAGA: Powiadomienie wystarczy wysłać raz i nie musisz wysyłać go
              dla każdego Środka Trwałego osobno - chyba że dodasz nowy środek
              trwały do listy złomowania!
            </Typography>
          </DialogContent>
          <ThemeProvider theme={materialTheme}>
            <DialogActions>
              <Button
                onClick={this.handleCloseNotifyScrapAsset}
                color="default"
                size="large"
              >
                Zamknij
              </Button>
              <Button
                onClick={this.handleNotifyScrapAsset}
                color="primary"
                variant="contained"
                autoFocus
                size="large"
              >
                Powiadom
              </Button>
            </DialogActions>
          </ThemeProvider>
        </Dialog>

        {dialogComment}
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  myAssetsAl: state.assets.myAssetsAl,
  myAssetsNa: state.assets.myAssetsNa,
  myAssetsEl: state.assets.myAssetsEl,
  myAssetsIt: state.assets.myAssetsIt,
  myAssetsPo: state.assets.myAssetsPo,
  myAssetsByStatusSr: state.assets.myAssetsByStatusSr,
  myAssetsByStatusWr: state.assets.myAssetsByStatusWr,
  myAssetsDetails: state.assets.myAssetsDetails,
  currentEquipmentTransferLog: state.assets.currentEquipmentTransferLog,
  queryInProgress: state.assets.queryInProgress,
  queryMySrInProgress: state.assets.queryMySrInProgress,
  queryMyWrInProgress: state.assets.queryMyWrInProgress,
  auth: state.auth,
  usersList: state.common.users,
  userDetails: state.common.suser,
  myManagerDetailsV2: state.employee.myManagerDetailsV2,
  queryInProgressUL: state.common.queryInProgress,
  activeInventory: state.assets.activeInventory,
  myInvAl: state.assets.myInvAl,
  myInvNa: state.assets.myInvNa,
  myInvEl: state.assets.myInvEl,
  myInvIt: state.assets.myInvIt,
  myInvPo: state.assets.myInvPo,
  myInventoryDetails: state.assets.myInventoryDetails,
  codeValid: state.verifycodes.valid,
});

export default connect(
  mapStateToProps,
  {
    getMyAssets,
    getMyAssetsByStatus,
    getUsersList,
    getUserDetails,
    getMyAssetsDetails,
    clearUserDetails,
    patchMyAsset,
    scrapMyAsset,
    scrapMyAssetUpdate,
    addAssetTransferLog,
    getMyManagerDetailsV2,
    clearCurrentEquipmentTransferLog,
    patchAssetTransferLog,
    sendNotification,
    sendNewNotification,
    sendNewNotificationControler,
    sendNewNotificationManager,
    sendNewNotificationBHP,
    sendNewNotificationKeepers,
    getActiveInventory,
    getMyInventory,
    getMyInventoryDetails,
    updateMyInventoryDetails,
    deleteMyInventoryDetails,
    reportInventory,
    sendCode,
    verifyCode,
    clearCode,
  }
)(withStyles(styles, { withTheme: true })(MyAssetView));
