import React, { Component, Fragment } from "react";
import EuroCard from "./EuroCard";
import PlnCard from "./PlnCard";
import SekCard from "./SekCard";
import NokCard from "./NokCard";
import ButtonWithdraw from "./ButtonWithdraw";
import ButtonCardPay from "./ButtonCardPay";
import ButtonCashPay from "./ButtonCashPay";
import ButtonOwnCashPay from "./ButtonOwnCashPay";
import { RangeSlider } from "../Common/RangeSlider";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { styles } from "../../common/radiolineStyles";
import Typography from "@material-ui/core/Typography";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import AddWithdrawTransactionForm from "./AddWithdrawTransactionForm";
import AddCashTransactionForm from "./AddCashTransactionForm";
import AddOwnCashTransactionForm from "./AddOwnCashTransactionForm";
import AddCardTransactionForm from "./AddCardTransactionForm";
import { getTransactions } from "../../Redux/actions/accounting";
import { patchProfile } from "../../Redux/actions/auth";
import { getMyManagerDetailsV2 } from "../../Redux/actions/employee";

export class EmployeeBalanceView extends Component {
  state = {
    operations: [],
    dialogAddOwnCashTransaction: false,
    dialogAddWithdrawTransaction: false,
    dialogAddCardTransaction: false,
    dialogAddCashTransaction: false,
    thisYear: true,
    today: new Date(),
  };

  static propTypes = {
    auth: PropTypes.object.isRequired,
    getTransactions: PropTypes.func.isRequired,
    patchProfile: PropTypes.func.isRequired,
    balanceEUR: PropTypes.object.isRequired,
    balancePLN: PropTypes.object.isRequired,
    balanceSEK: PropTypes.object.isRequired,
    balanceNOK: PropTypes.object.isRequired,
    getMyManagerDetailsV2: PropTypes.func.isRequired,
  };

  componentDidMount() {
    if (
      !this.props.balanceEUR.sum &&
      !this.props.balancePLN.sum &&
      !this.props.balanceSEK.sum &&
      !this.props.balanceNOK.sum
    ) {
      const thisYear = this.props.auth.main_profile.accountingThisYearView.toString();
      const thisYearFormatted =
        thisYear.charAt(0).toUpperCase() + thisYear.slice(1);

      this.props.getTransactions(
        this.props.auth.user.id,
        "eur",
        thisYearFormatted
      );
      this.props.getTransactions(
        this.props.auth.user.id,
        "pln",
        thisYearFormatted
      );
      this.props.getTransactions(
        this.props.auth.user.id,
        "sek",
        thisYearFormatted
      );
      this.props.getTransactions(
        this.props.auth.user.id,
        "nok",
        thisYearFormatted
      );
    }

    this.props.getMyManagerDetailsV2(
      Number(this.props.auth.main_profile.manager)
    );
  }

  componentDidUpdate(prevProps) {
    if (this.props.auth.main_profile.defaultCompany != prevProps.auth.main_profile.defaultCompany) {
      if (
        !this.props.balanceEUR.sum &&
        !this.props.balancePLN.sum &&
        !this.props.balanceSEK.sum &&
        !this.props.balanceNOK.sum
      ) {
        const thisYear = this.props.auth.main_profile.accountingThisYearView.toString();
        const thisYearFormatted =
          thisYear.charAt(0).toUpperCase() + thisYear.slice(1);

        this.props.getTransactions(
          this.props.auth.user.id,
          "eur",
          thisYearFormatted
        );
        this.props.getTransactions(
          this.props.auth.user.id,
          "pln",
          thisYearFormatted
        );
        this.props.getTransactions(
          this.props.auth.user.id,
          "sek",
          thisYearFormatted
        );
        this.props.getTransactions(
          this.props.auth.user.id,
          "nok",
          thisYearFormatted
        );
      }

      this.props.getMyManagerDetailsV2(
        Number(this.props.auth.main_profile.manager)
      );
    }
  }

  handleCloseDialogAddWithdrawTransaction = () => {
    this.setState({
      dialogAddWithdrawTransaction: false,
    });
  };

  handleOpenDialogAddWithdrawTransaction = () => {
    this.setState({
      dialogAddWithdrawTransaction: true,
    });
  };

  handleCloseDialogAddCashTransaction = () => {
    this.setState({
      dialogAddCashTransaction: false,
    });
  };

  handleOpenDialogAddCashTransaction = () => {
    this.setState({
      dialogAddCashTransaction: true,
    });
  };

  handleCloseDialogAddCardTransaction = () => {
    this.setState({
      dialogAddCardTransaction: false,
    });
  };

  handleOpenDialogAddCardTransaction = () => {
    this.setState({
      dialogAddCardTransaction: true,
    });
  };

  handleCloseDialogAddOwnCashTransaction = () => {
    this.setState({
      dialogAddOwnCashTransaction: false,
    });
  };

  handleOpenDialogAddOwnCashTransaction = () => {
    this.setState({
      dialogAddOwnCashTransaction: true,
    });
  };

  handlethisYearFilter = (thisYear) => {
    const thisYearString = thisYear.toString();
    const thisYearFlag =
      thisYearString.charAt(0).toUpperCase() + thisYearString.slice(1);

    this.setState({
      thisYear: thisYear,
    });
    this.props.getTransactions(this.props.auth.user.id, "eur", thisYearFlag);
    this.props.getTransactions(this.props.auth.user.id, "pln", thisYearFlag);
    this.props.getTransactions(this.props.auth.user.id, "sek", thisYearFlag);
    this.props.getTransactions(this.props.auth.user.id, "nok", thisYearFlag);
    //
    const profileBody = {
      accountingThisYearView: thisYear,
    };

    this.props.patchProfile(profileBody, this.props.auth.main_profile.pk);
  };

  render() {
    const { classes, auth } = this.props;
    const {
      dialogAddWithdrawTransaction,
      dialogAddCardTransaction,
      dialogAddCashTransaction,
      dialogAddOwnCashTransaction,
      today,
    } = this.state;

    const transactionsCardAvailable = (
      <Fragment>
        <ButtonWithdraw
          triggerDialogOpen={this.handleOpenDialogAddWithdrawTransaction}
        />
        <ButtonCardPay
          triggerDialogOpen={this.handleOpenDialogAddCardTransaction}
        />
        <ButtonCashPay
          triggerDialogOpen={this.handleOpenDialogAddCashTransaction}
        />
        <ButtonOwnCashPay
          triggerDialogOpen={this.handleOpenDialogAddOwnCashTransaction}
        />
      </Fragment>
    );

    const transactionsCardUnavailable = (
      <Fragment>
        <ButtonCashPay
          triggerDialogOpen={this.handleOpenDialogAddCashTransaction}
        />
        <ButtonOwnCashPay
          triggerDialogOpen={this.handleOpenDialogAddOwnCashTransaction}
        />
      </Fragment>
    );

    return (
      <Fragment>
        <div className={classes.section}>
          <Box m={2}>
            <Typography className={classes.cardsGroup}>
              Zarejestruj transakcję
            </Typography>
            <Grid container justify="flex-start" spacing={2}>
              {auth.additional_profile.businesscards.filter(card => card.isValid === true).length > 0
                ? transactionsCardAvailable
                : transactionsCardUnavailable}
            </Grid>
            <Typography
              style={{ marginBottom: "30px" }}
              className={classes.cardsGroup}
            >
              Wybrany okres rozliczeniowy pracownika
            </Typography>
            <RangeSlider
              thisYear={this.state.thisYear}
              thisYearFilter={this.handlethisYearFilter}
              isActive={true}
            />
            <Typography className={classes.cardsGroup}>
              Saldo (
              {auth.main_profile.accountingThisYearView
                ? today.getFullYear()
                : today.getFullYear() - 1}
              )
            </Typography>
            <Grid container justify="flex-start" spacing={2}>
              <EuroCard />
              <PlnCard />
              <SekCard />
              <NokCard />
            </Grid>
          </Box>
        </div>

        {/*
Dialogi
        */}
        <Dialog
          fullScreen
          open={dialogAddWithdrawTransaction}
          onClose={this.handleCloseDialogAddWithdrawTransaction}
          aria-labelledby="form-dialog-title"
        >
          <AppBar position="static" style={{ background: "#0071BC" }}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={this.handleCloseDialogAddWithdrawTransaction}
                aria-label="close"
                className={classes.menuButton}
              >
                <CloseIcon />
              </IconButton>
              <Typography variant="h6" className={classes.title}>
                Zarejestruj wypłatę z bankomatu
              </Typography>
            </Toolbar>
          </AppBar>
          <DialogContent style={{ background: "#f5f5f5" }}>
            <AddWithdrawTransactionForm
              closeDialog={this.handleCloseDialogAddWithdrawTransaction}
            />
          </DialogContent>
        </Dialog>

        <Dialog
          fullScreen
          open={dialogAddCardTransaction}
          onClose={this.handleCloseDialogAddCardTransaction}
          aria-labelledby="form-dialog-title"
        >
          <AppBar position="static" style={{ background: "#0071BC" }}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={this.handleCloseDialogAddCardTransaction}
                aria-label="close"
                className={classes.menuButton}
              >
                <CloseIcon />
              </IconButton>
              <Typography variant="h6" className={classes.title}>
                Zarejestruj płatność kartą służbową
              </Typography>
            </Toolbar>
          </AppBar>
          <DialogContent style={{ background: "#f5f5f5" }}>
            <AddCardTransactionForm
              closeDialog={this.handleCloseDialogAddCardTransaction}
            />
          </DialogContent>
        </Dialog>

        <Dialog
          fullScreen
          open={dialogAddCashTransaction}
          onClose={this.handleCloseDialogAddCashTransaction}
          aria-labelledby="form-dialog-title"
        >
          <AppBar position="static" style={{ background: "#0071BC" }}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={this.handleCloseDialogAddCashTransaction}
                aria-label="close"
                className={classes.menuButton}
              >
                <CloseIcon />
              </IconButton>
              <Typography variant="h6" className={classes.title}>
                Zarejestruj płatność gotówką służbową
              </Typography>
            </Toolbar>
          </AppBar>
          <DialogContent style={{ background: "#f5f5f5" }}>
            <AddCashTransactionForm
              closeDialog={this.handleCloseDialogAddCashTransaction}
            />
          </DialogContent>
        </Dialog>

        <Dialog
          fullScreen
          open={dialogAddOwnCashTransaction}
          onClose={this.handleCloseDialogAddOwnCashTransaction}
          aria-labelledby="form-dialog-title"
        >
          <AppBar position="static" style={{ background: "#0071BC" }}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={this.handleCloseDialogAddOwnCashTransaction}
                aria-label="close"
                className={classes.menuButton}
              >
                <CloseIcon />
              </IconButton>
              <Typography variant="h6" className={classes.title}>
                Zarejestruj płatność ze środków własnych
              </Typography>
            </Toolbar>
          </AppBar>
          <DialogContent style={{ background: "#f5f5f5" }}>
            <AddOwnCashTransactionForm
              closeDialog={this.handleCloseDialogAddOwnCashTransaction}
            />
          </DialogContent>
        </Dialog>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  balanceEUR: state.accounting.balanceEUR,
  balancePLN: state.accounting.balancePLN,
  balanceSEK: state.accounting.balanceSEK,
  balanceNOK: state.accounting.balanceNOK,
});

export default connect(
  mapStateToProps,
  { getTransactions, patchProfile, getMyManagerDetailsV2 }
)(withStyles(styles, { withTheme: true })(EmployeeBalanceView));
