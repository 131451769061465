import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { connect } from "react-redux";
import { TabPanel } from "../../common/TabPanel.js";

import { getMyApplicationKids, getMyApplicationIncome, getMyApplicationSWO } from "../../Redux/actions/zfss.js";
import DochodyViewer from "./DochodyViewer.js";
import PaczkiViewer from "./PaczkiViewer.js";
import SWOViewer from "./SWOViewer.js";

export class MyViewer extends Component {
  state = {
    value: 0,
  };

  static propTypes = {
    auth: PropTypes.object.isRequired,
    getMyApplicationIncome: PropTypes.func.isRequired,
    getMyApplicationKids: PropTypes.func.isRequired,
    getMyApplicationSWO: PropTypes.func.isRequired,
  };

  componentDidMount() {
  }

  handleChange = (event, newValue) => {
    this.setState({
      value: newValue,
    });
  };

  render() {
    const { value } = this.state;

    var subViewsConfig;
    subViewsConfig = (
      <Fragment>
        <Tabs
          value={value}
          onChange={this.handleChange}
          aria-label="simple tabs example"
        >
          <Tab label="Oświadczenia" />
          <Tab label="Wnioski - paczka" />
          <Tab label="Wnioski - świadczenia" />
        </Tabs>
        <TabPanel value={value} index={0}>
          <DochodyViewer />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <PaczkiViewer />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <SWOViewer />
        </TabPanel>
      </Fragment>
    );

    return <div>{subViewsConfig}</div>;
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(
  mapStateToProps,
  { getMyApplicationKids, getMyApplicationIncome, getMyApplicationSWO }
)(MyViewer);
