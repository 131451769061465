import React, { Component, Fragment } from "react";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import { withStyles, fade } from "@material-ui/core/styles";
import { createMuiTheme } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import InputBase from "@material-ui/core/InputBase";
import Container from "@material-ui/core/Container";
import HolidaysManagerHolidays from "./HolidaysManagerHolidays";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import { ThemeProvider } from "@material-ui/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import DateFnsUtils from "@date-io/date-fns";
import { pl } from "date-fns/locale";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from "@material-ui/pickers";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Autocomplete from "@material-ui/lab/Autocomplete";

import {
  getEmployeeLeaveOnLeave,
  getEmployeeLeaveAccept,
  getEmployeeLeavesSearch,
  getEmployeeLeavePlanned,
  addEmployeeLeave
} from "../Redux/actions/holidays-manager";
import { getManagerDetails } from "../Redux/actions/manager";
import { getLeaveConfig } from "../Redux/actions/holidays";

const materialTheme = createMuiTheme({
  overrides: {
    MuiFormControl: {
      root: {
        width: 300,
        marginTop: 50
      },
      marginNormal: {
        marginTop: "50px"
      }
    },
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: "#0071BC"
      }
    },
    MuiPickersDay: {
      day: {
        color: "#0071BC"
      },
      daySelected: {
        backgroundColor: "#0071BC",
        "&:hover": {
          backgroundColor: "#0071BC"
        }
      },

      dayDisabled: {
        color: "gray"
      },
      current: {
        color: "#0071BC"
      }
    },
    MuiFormLabel: {
      root: {
        "&.Mui-focused": {
          color: "#0071BC"
        }
      }
    },
    MuiInput: {
      underline: {
        "&:after": {
          borderBottomColor: "#0071bc"
        }
      }
    },
    MuiButton: {
      root: {},
      textPrimary: {
        color: "#0071BC"
      },
      containedPrimary: {
        backgroundColor: "#1BAB7F",
        "&:hover": {
          backgroundColor: "#188E6A"
        }
      }
    }
  }
});

const styles = theme => ({
  appBar: {
    top: "auto",
    bottom: 0,
    background: "#0071BC"
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: fade(theme.palette.common.white, 0.25)
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(3),
      width: "auto"
    }
  },
  searchIcon: {
    width: theme.spacing(7),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  inputRoot: {
    color: "inherit"
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 7),
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: 200
    }
  },
  grow: {
    flexGrow: 1
  },
  fabButton: {
    position: "absolute",
    zIndex: 1,
    top: -30,
    left: 0,
    right: 0,
    margin: "0 auto"
  }
});

function getBusinessDays(startDate, endDate, holidaysDays) {
  let count = 0;
  let currentDate = startDate;
  let holiday = false;

  while (endDate >= currentDate) {
    const dayOfWeek = currentDate.getDay();
    //sprawdza czy data nie jest na liście świąt, jeżeli jest to ustawia flagę
    holidaysDays.forEach(function (item) {
      if (
        currentDate.getFullYear() +
        "-" +
        currentDate.getMonth() +
        "-" +
        currentDate.getDate() ===
        item.getFullYear() + "-" + item.getMonth() + "-" + item.getDate()
      ) {
        holiday = true;
      }
    });
    //inkrementuje licznik tylko wtedy kiedy nie sobota i nie niedziela
    if (!(dayOfWeek === 6 || dayOfWeek === 0) && !holiday) count++;
    currentDate.setDate(currentDate.getDate() + 1);
    holiday = false;
  }
  return count;
}

class HolidaysManagerViewer extends Component {
  state = {
    search: null,
    dialogCreate: false,
    holidaysStartDateErrorFlag: false,
    holidaysEndDateErrorFlag: false,
    holidaysStartDate: null,
    holidaysStartDateFormatted: null,
    holidaysEndDate: null,
    holidaysEndDateFormatted: null,
    leaveTypeErrorFlag: false,
    leaveType: null,
    remarks: "",
    employeeList: [],
    employee: {},
    holidaysDays: [new Date(2019, 11, 25), new Date(2019, 11, 26)]
  };
  //integracja z reduxem
  static propTypes = {
    auth: PropTypes.object.isRequired,
    holidaysManager: PropTypes.object.isRequired,
    holidays: PropTypes.object.isRequired,
    manager: PropTypes.object.isRequired,
    getEmployeeLeaveOnLeave: PropTypes.func.isRequired,
    getEmployeeLeaveAccept: PropTypes.func.isRequired,
    getManagerDetails: PropTypes.func.isRequired,
    addEmployeeLeave: PropTypes.func.isRequired
  };

  componentDidMount() {
    const today = new Date();
    this.props.getLeaveConfig(today.getFullYear());
    this.props.getEmployeeLeaveOnLeave();
    this.props.getEmployeeLeaveAccept();
    this.props.getEmployeeLeavePlanned();
    this.props.getManagerDetails(this.props.auth.user.managers[0]);
  }

  componentDidUpdate(prevProps) {
    //wykrycie pobrania leaveConfig i wyliczenie dat do zablokowania
    if (
      prevProps.holidays.isGettingConfig === true &&
      this.props.holidays.isGettingConfig === false
    ) {
      var datesToBeBlocked = [];
      //this.props.holidays.leaveConfig[0].holidays.forEach(element => {
      //  datesToBeBlocked = [...datesToBeBlocked, new Date(element)];
      //});
      this.setState({
        holidaysDays: datesToBeBlocked
      });
    }
  }

  disableDates = date => {
    //to trzeba zaczytać ze state a samą inicjalizacje tej tablicy zrobić w didMount lub gdzieś na początku ładowania strony
    const datesToBeBlocked = this.state.holidaysDays;
    var idx1 = -1;
    datesToBeBlocked.forEach(function (item, index) {
      if (date.getTime() === item.getTime()) {
        idx1 = index;
      }
    });
    return idx1 !== -1 || date.getDay() === 0 || date.getDay() === 6;
  };

  handleInputChange = name => ({ target: { value } }) => {
    this.setState({
      ...this.state,
      [name]: value
    });
  };

  handleSearch = () => {
    const surname = this.state.search;
    this.props.getEmployeeLeavesSearch(surname);
  };

  openDialogCreate = () => {
    this.setState({
      dialogCreate: true,
      employeeList: this.props.manager.managerDetails.employees
    });
  };

  handleTextList = name => ({ target: { value } }) => {
    this.setState({
      ...this.state,
      [name]: value
    });
  };

  handleClickCloseDialogCreate = () => {
    this.setState({
      dialogCreate: false,
      holidaysStartDate: null,
      holidaysStartDateFormatted: null,
      holidaysEndDate: null,
      holidaysEndDateFormatted: null,
      leaveType: "",
      remarks: "",
      leaveTypeErrorFlag: false,
      holidaysStartDateErrorFlag: false,
      holidaysEndDateErrorFlag: false
    });
  };

  handleStartDate = date => {
    if (date !== null) {
      const dd = date.getDate();
      const mm = date.getMonth() + 1;
      const yyyy = date.getFullYear();
      const fullDate = yyyy + "-" + mm + "-" + dd;
      this.setState({
        holidaysStartDate: date,
        holidaysStartDateFormatted: fullDate
      });
    } else {
      this.setState({
        holidaysStartDate: null,
        holidaysStartDateFormatted: null
      });
    }
  };

  handleEndDate = date => {
    if (date !== null) {
      const dd = date.getDate();
      const mm = date.getMonth() + 1;
      const yyyy = date.getFullYear();
      const fullDate = yyyy + "-" + mm + "-" + dd;
      this.setState({
        holidaysEndDate: date,
        holidaysEndDateFormatted: fullDate
      });
    } else {
      this.setState({
        holidaysEndDate: null,
        holidaysEndDateFormatted: null
      });
    }
  };

  handleEmployee = (event, value) => {
    this.setState({
      employee: value
    });
  };

  handleCreateLeave = () => {
    const startDate = new Date(this.state.holidaysStartDate.getTime());
    const endDate = new Date(this.state.holidaysEndDate.getTime());
    const holidaysDays = this.state.holidaysDays;

    const numOfBusinessDays = getBusinessDays(startDate, endDate, holidaysDays);

    var leaveBody;
    if (this.state.leaveType === "SI" || this.state.leaveType === "SP") {
      leaveBody = {
        startDate: this.state.holidaysStartDateFormatted,
        endDate: this.state.holidaysEndDateFormatted,
        daysCount: numOfBusinessDays,
        waitForDoc: "true",
        leaveType: this.state.leaveType,
        status: "ZA",
        remarks: this.state.remarks,
        owner: this.state.employee.owner,
        employee: this.state.employee.pk,
        ownersManager: this.props.auth.user.id
      };
    } else {
      leaveBody = {
        startDate: this.state.holidaysStartDateFormatted,
        endDate: this.state.holidaysEndDateFormatted,
        daysCount: numOfBusinessDays,
        leaveType: this.state.leaveType,
        status: "ZA",
        remarks: this.state.remarks,
        owner: this.state.employee.owner,
        employee: this.state.employee.pk,
        ownersManager: this.props.auth.user.id
      };
    }

    this.props.addEmployeeLeave(leaveBody);
    this.setState({
      search: null,
      dialogCreate: false,
      holidaysStartDateErrorFlag: false,
      holidaysEndDateErrorFlag: false,
      holidaysStartDate: null,
      holidaysStartDateFormatted: null,
      holidaysEndDate: null,
      holidaysEndDateFormatted: null,
      leaveTypeErrorFlag: false,
      leaveType: null,
      remarks: "",
      employee: {}
    });
  };

  render() {
    const { classes } = this.props;
    const {
      search,
      dialogCreate,
      holidaysStartDateErrorFlag,
      holidaysEndDateErrorFlag,
      holidaysStartDate,
      holidaysEndDate,
      leaveTypeErrorFlag,
      leaveType,
      remarks,
      employeeList
    } = this.state;

    const defaultProps = {
      options: employeeList,
      getOptionLabel: option => option.first_name + " " + option.last_name
    };

    return (
      <Fragment>
        <Container>
          <HolidaysManagerHolidays label="Aktualne nieobecności"></HolidaysManagerHolidays>
          <div style={{ marginBottom: 70 }}></div>
          <HolidaysManagerHolidays label="Urlopy do zatwierdzenia"></HolidaysManagerHolidays>
          <HolidaysManagerHolidays label="Nadchodzące urlopy"></HolidaysManagerHolidays>
          <HolidaysManagerHolidays label="Filtr"></HolidaysManagerHolidays>
        </Container>
        <div style={{ marginBottom: 70 }}></div>

        <AppBar position="fixed" color="primary" className={classes.appBar}>
          <Toolbar>
            <div className={classes.search}>
              <div className={classes.searchIcon}>
                <SearchIcon />
              </div>
              <InputBase
                placeholder="Nazwisko ..."
                classes={{
                  root: classes.inputRoot,
                  input: classes.inputInput
                }}
                inputProps={{ "aria-label": "search" }}
                value={search || ""}
                onChange={this.handleInputChange("search")}
                onKeyPress={ev => {
                  if (ev.key === "Enter") {
                    ev.preventDefault();
                    this.handleSearch();
                  }
                }}
              />
            </div>
            <Fab
              color="secondary"
              aria-label="add"
              className={classes.fabButton}
              onClick={this.openDialogCreate}
            >
              <AddIcon fontSize="default" />
            </Fab>
          </Toolbar>
        </AppBar>

        <Dialog
          fullScreen
          open={dialogCreate}
          onClose={this.handleClickCloseDialogCreate}
          aria-labelledby="form-dialog-title"
        >
          <AppBar position="static" style={{ background: "#0071BC" }}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={this.handleClickCloseDialogCreate}
                aria-label="close"
                className={classes.menuButton}
              >
                <CloseIcon />
              </IconButton>
              <Typography variant="h6" className={classes.title2}>
                Wystaw urlop
              </Typography>
            </Toolbar>
          </AppBar>
          <DialogContent>
            <form
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center"
              }}
            >
              <ThemeProvider theme={materialTheme}>
                <Autocomplete
                  {...defaultProps}
                  id="combo-box-demo"
                  onChange={this.handleEmployee}
                  style={{ width: 300 }}
                  renderInput={params => (
                    <TextField
                      {...params}
                      label="wybierz pracownika..."
                      fullWidth
                    />
                  )}
                />
              </ThemeProvider>
              <MuiPickersUtilsProvider
                utils={DateFnsUtils}
                locale={pl}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center"
                }}
              >
                <ThemeProvider theme={materialTheme}>
                  <KeyboardDatePicker
                    error={holidaysStartDateErrorFlag}
                    className={classes.textField}
                    margin="normal"
                    label="Od"
                    format="yyyy/MM/dd"
                    minDate={new Date()}
                    value={holidaysStartDate}
                    onChange={this.handleStartDate}
                    shouldDisableDate={this.disableDates}
                  />
                </ThemeProvider>
                <ThemeProvider theme={materialTheme}>
                  <KeyboardDatePicker
                    error={holidaysEndDateErrorFlag}
                    className={classes.textField}
                    margin="normal"
                    label="Do"
                    format="yyyy/MM/dd"
                    emptyLabel=""
                    minDate={new Date()}
                    value={holidaysEndDate}
                    onChange={this.handleEndDate}
                    shouldDisableDate={this.disableDates}
                  />
                </ThemeProvider>
              </MuiPickersUtilsProvider>
              <div style={{ marginTop: 16 }}></div>
              <ThemeProvider theme={materialTheme}>
                <FormControl required>
                  <InputLabel error={leaveTypeErrorFlag} htmlFor="leaveType">
                    Rodzaj urlopu
                  </InputLabel>
                  <Select
                    name="leaveType"
                    value={leaveType}
                    onChange={this.handleTextList("leaveType")}
                  >
                    <MenuItem value="PA">Płatny</MenuItem>
                    <MenuItem value="UN">Bezpłatny</MenuItem>
                    <MenuItem value="SI">L4</MenuItem>
                  </Select>
                </FormControl>
              </ThemeProvider>
              <ThemeProvider theme={materialTheme}>
                <TextField
                  multiline
                  rowsMax="3"
                  label="Komentarz"
                  inputProps={{ maxLength: 100 }}
                  margin="normal"
                  value={remarks}
                  onChange={this.handleTextList("remarks")}
                />
              </ThemeProvider>
              <div style={{ marginTop: 30 }}></div>
              <ThemeProvider theme={materialTheme}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={this.handleCreateLeave}
                >
                  Utwórz wniosek
                </Button>
              </ThemeProvider>
            </form>
            <div style={{ marginTop: 50 }}></div>
          </DialogContent>
        </Dialog>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
  holidaysManager: state.holidaysManager,
  holidays: state.holidays,
  manager: state.manager
});

export default connect(
  mapStateToProps,
  {
    getEmployeeLeaveOnLeave,
    getEmployeeLeaveAccept,
    getEmployeeLeavesSearch,
    getEmployeeLeavePlanned,
    getManagerDetails,
    getLeaveConfig,
    addEmployeeLeave
  }
)(withStyles(styles, { withTheme: true })(HolidaysManagerViewer));
