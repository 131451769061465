import React, { Component, Fragment } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import Card from "../../components/Card/Card.js";
import CardBody from "../../components/Card/CardBody.js";
import { Avatar } from "@material-ui/core";
//import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
import CloseIcon from "@material-ui/icons/Close";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import CircularProgress from "@material-ui/core/CircularProgress";
import CardHeader from "@material-ui/core/CardHeader";
import CardActions from "@material-ui/core/CardActions";
import SearchIcon from "@material-ui/icons/Search";
//import InputBase from "@material-ui/core/InputBase";
import TextField from "@material-ui/core/TextField";
import { createMuiTheme } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { getEmployeeAssetsByName } from "../../Redux/actions/assets";

const CssTextField = withStyles({
  root: {
    width: "100%",
    flexWrap: "wrap",
    "& label.Mui-focused": {
      color: "#0071bc",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#0071bc",
    },
  },
})(TextField);

const materialTheme = createMuiTheme({
  palette: {
    primary: {
      main: "#0071BC",
    },
  },
  overrides: {
    MuiFormLabel: {
      root: {
        MuiFocused: {
          color: "#0071BC",
        },
      },
    },
  },
});

const styles = (theme) => ({
  avatar: {
    backgroundColor: "#0071bc",
    height: 30,
    width: 30,
  },
  CardTitle: {
    textAlign: "left",
    fontFamily: "Lato, Open Sans, sans-serif",
    fontSize: "1em",
    fontWeight: "300",
    color: "#999999",
  },
  CardDescription: {
    textAlign: "center",
    fontFamily: "Lato, Open Sans, sans-serif",
    fontSize: "2em",
    fontWeight: "700",
    color: "#81c784",
  },
});

export class FindCard extends Component {
  state = {
    activeFilter: null,
    findWord: null,
    dialogFind: false,
  };

  static propTypes = {
    queryFilteredFiInProgress: PropTypes.bool.isRequired,
    employeeAssetsByName: PropTypes.array.isRequired,
    getEmployeeAssetsByName: PropTypes.func.isRequired,
  };

  componentWillReceiveProps(nextProps) {
    if (
      this.props.queryFilteredFiInProgress === true &&
      nextProps.queryFilteredFiInProgress === false
    ) {
      const activeFilter = this.state.activeFilter;
      if (activeFilter === null || activeFilter !== "findfilter") {
        this.props.setFilter("findfilter");
        this.setState({
          activeFilter: "findfilter",
        });
      } else if (activeFilter === "findfilter") {
        this.props.setFilter(null);
      }
    }

    if (nextProps.activeFilter !== this.state.activeFilter) {
      this.setState({
        activeFilter: nextProps.activeFilter,
      });
    }
  }

  handleFilterClick = () => {
    const findWord = this.state.findWord;
    const activeFilter = this.state.activeFilter;
    if (
      (findWord === null || findWord === "" || findWord.length < 2) &&
      activeFilter !== "findfilter"
    ) {
      this.setState({
        dialogFind: true,
      });
    } else if (
      findWord !== null &&
      findWord !== "" &&
      findWord.length >= 2 &&
      activeFilter !== "findfilter"
    ) {
      this.props.getEmployeeAssetsByName(findWord);
      //reszta procedury w component will receive props
    } else {
      if (activeFilter !== "findfilter") {
        this.props.setFilter("findfilter");
        this.setState({
          activeFilter: "findfilter",
          findWord: null,
        });
      } else if (activeFilter === "findfilter") {
        this.props.setFilter(null);
        this.setState({
          activeFilter: null,
          findWord: null,
        });
      }
    }
  };

  handleCloseDialog = () => {
    this.setState({
      dialogFind: false,
    });
  };

  handleTextList = (name) => ({ target: { value } }) => {
    //console.log(name);
    this.setState({
      ...this.state,
      [name]: value,
      activeFilter: null,
    });
  };

  render() {
    const { activeFilter, dialogFind, findWord } = this.state;
    const { classes, queryFilteredFiInProgress } = this.props;

    const arrow = (
      <IconButton aria-label="delete" onClick={this.handleFilterClick}>
        <ArrowForwardIcon />
      </IconButton>
    );
    const cancel = (
      <IconButton
        color="secondary"
        aria-label="delete"
        onClick={this.handleFilterClick}
      >
        <CloseIcon />
      </IconButton>
    );

    const circle = (
      <IconButton aria-label="settings">
        <CircularProgress color="secondary" size="1em" />
      </IconButton>
    );

    return (
      <Fragment>
        <Grid item xs={12} sm={8} md={3} lg={2}>
          <Card style={{ marginTop: 0, marginBottom: 0 }}>
            <CardHeader
              style={{ padding: "5px" }}
              avatar={
                <Avatar className={classes.avatar}>
                  <SearchIcon />
                </Avatar>
              }
              action={
                <Fragment>
                  {queryFilteredFiInProgress ? circle : ""}
                  {activeFilter !== "findfilter" && !queryFilteredFiInProgress
                    ? arrow
                    : ""}
                  {activeFilter === "findfilter" && !queryFilteredFiInProgress
                    ? cancel
                    : ""}
                </Fragment>
              }
            />
            <CardBody style={{ position: "relative", padding: 0 }}>
              <ThemeProvider theme={materialTheme}>
                <CssTextField
                  id="standard-search"
                  type="search"
                  size="small"
                  variant="filled"
                  placeholder="Czego szukasz?"
                  value={findWord || ""}
                  onChange={this.handleTextList("findWord")}
                />
              </ThemeProvider>
            </CardBody>
            <CardActions>
              <Typography className={classes.CardTitle}>
                szukaj po nazwie
              </Typography>
            </CardActions>
          </Card>
        </Grid>

        <Dialog
          open={dialogFind}
          onClose={this.handleCloseDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Info"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Najpierw podaj szukaną frazę i później wciśnij przycisk
              wyszukiwania. Pamiętaj, że minimalna długość szukanego tekstu to
              przynajmniej 2 znaki.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleCloseDialog} color="primary">
              OK
            </Button>
          </DialogActions>
        </Dialog>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  queryFilteredFiInProgress: state.assets.queryFilteredFiInProgress,
  employeeAssetsByName: state.assets.employeeAssetsByName,
});

export default connect(
  mapStateToProps,
  { getEmployeeAssetsByName }
)(withStyles(styles, { withTheme: true })(FindCard));
