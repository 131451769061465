import React, { Component } from "react";
import PropTypes from "prop-types";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepConnector from "@material-ui/core/StepConnector";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import EditIcon from "@material-ui/icons/Edit";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import SearchIcon from "@material-ui/icons/Search";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Card from "../../components/Card/Card.js";
import CardBody from "../../components/Card/CardBody.js";
import { ThemeProvider } from "@material-ui/styles";
import { createMuiTheme } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CircularProgress from "@material-ui/core/CircularProgress";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import { connect } from "react-redux";
import {
  getEquipmentModelsListGrouped,
  getVendorsListFiltered,
  clearEquipmentModelsList,
  clearVendorsList,
} from "../../Redux/actions/assets";
import {
  getUsersList,
  getUserDetails,
  clearUserDetails,
} from "../../Redux/actions/common";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";

const CssTextField = withStyles({
  root: {
    width: 280,
    flexWrap: "wrap",
    marginBottom: "50px",

    "& label.Mui-focused": {
      color: "#0071bc",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#0071bc",
    },
  },
})(TextField);

const materialTheme = createMuiTheme({
  palette: {
    primary: {
      main: "#0071BC",
    },
  },
  overrides: {
    MuiTextField: {
      root: {
        width: "auto",
      },
    },
    MuiFormLabel: {
      root: {
        color: "#0071bc",
      },
    },
  },
});

const styles = (theme) => ({
  root: {
    width: "100%",
  },
  button: {
    marginRight: theme.spacing(1),
  },
  buttonNext: {
    marginRight: theme.spacing(1),
    color: theme.palette.common.white,
    backgroundColor: "#4791db",
    "&:hover": {
      backgroundColor: "#1976d2",
    },
  },
  buttonAdd: {
    marginRight: theme.spacing(1),
    color: theme.palette.common.white,
    backgroundColor: "#4caf50",
    "&:hover": {
      backgroundColor: "#388e3c",
    },
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  textField: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
    //width: "25ch"
  },
  textSumary: {
    color: "#888888",
    textAlign: "left",
    fontFamily: "Lato, Open Sans, sans-serif",
    fontSize: "1em",
    fontWeight: "300",
  },
  textBold: {
    fontWeight: "700",
  },
});

const ColorlibConnector = withStyles({
  alternativeLabel: {
    top: 22,
  },
  active: {
    "& $line": {
      background: "#1976d2",
    },
  },
  completed: {
    "& $line": {
      background: "#1976d2",
    },
  },
  line: {
    height: 3,
    border: 0,
    backgroundColor: "#eaeaf0",
    borderRadius: 1,
  },
})(StepConnector);

const useColorlibStepIconStyles = makeStyles({
  root: {
    backgroundColor: "#ccc",
    zIndex: 1,
    color: "#fff",
    width: 50,
    height: 50,
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
  },
  active: {
    backgroundImage:
      "linear-gradient( 136deg, rgb(25,118,210) 0%, rgb(25,118,210) 50%, rgb(71,145,219) 100%)",
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
  },
  completed: {
    backgroundImage:
      "linear-gradient( 136deg, rgb(25,118,210) 0%, rgb(25,118,210) 50%, rgb(71,145,219) 100%)",
  },
});

function ColorlibStepIcon(props) {
  const classes = useColorlibStepIconStyles();
  const { active, completed } = props;

  const icons = {
    1: <SearchIcon />,
    2: <EditIcon />,
    3: <PersonAddIcon />,
  };

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {icons[String(props.icon)]}
    </div>
  );
}

ColorlibStepIcon.propTypes = {
  active: PropTypes.bool,
  completed: PropTypes.bool,
  icon: PropTypes.node,
};

function getSteps() {
  return ["wybierz Model", "dodaj szczegóły", "przypisz pracownika"];
}

function getStepContent(step) {
  switch (step) {
    case 0:
      return "";
    case 1:
      return "";
    case 2:
      return "";
    default:
      return "";
  }
}

export class AddAssetForm extends Component {
  state = {
    activeStep: 0,
    openVendor: false,
    openModel: false,
    openEmployee: false,
    vendorSelected: null,
    modelSelected: null,
    detectVendorToggle: 1,
    purchaseDate: new Date(),
    purchaseDateFormatted: null,
    assignDate: new Date(),
    assignDateFormatted: null,
    serialNumber: "",
    productNumber: "",
    description: "",
    employeeSelected: null,
    eqValue: null,
    isST: false,
    fvNumber: "",
  };

  static propTypes = {
    equipmentModels: PropTypes.array.isRequired,
    queryInProgress: PropTypes.bool.isRequired,
    vendorsList: PropTypes.array.isRequired,
    getUsersList: PropTypes.func.isRequired,
    getUserDetails: PropTypes.func.isRequired,
    clearUserDetails: PropTypes.func.isRequired,
    usersList: PropTypes.array.isRequired,
    userDetails: PropTypes.object.isRequired,
    queryInProgressCommon: PropTypes.bool.isRequired,
    clearVendorsList: PropTypes.func.isRequired,
  };

  componentDidMount() {
    const date = new Date();
    const dd = date.getDate();
    const mm = date.getMonth() + 1;
    const yyyy = date.getFullYear();
    const fullDate = yyyy + "-" + mm + "-" + dd;
    this.setState({
      assignDateFormatted: fullDate,
      purchaseDateFormatted: fullDate,
    });
  }

  handleNext = () => {
    const activeStep = this.state.activeStep;
    this.setState({
      activeStep: activeStep + 1,
    });
  };

  handleBack = () => {
    const activeStep = this.state.activeStep;
    this.setState({
      activeStep: activeStep - 1,
    });
  };

  setOpenVendor = () => {
    const openVendor = this.state.openVendor;
    const modelSelectedVendorsPks = this.state.modelSelected.vendors;
    if (openVendor === false) {
      this.props.getVendorsListFiltered(modelSelectedVendorsPks);
    }
    this.setState({
      openVendor: !openVendor,
    });
  };

  setOpenModel = () => {
    const openModel = this.state.openModel;
    if (openModel === false && this.props.equipmentModels.length === 0) {
      this.props.getEquipmentModelsListGrouped();
    }
    this.setState({
      openModel: !openModel,
    });
  };

  getSelectedVendor = (event, value) => {
    this.setState({
      vendorSelected: value,
    });
    if (value !== null) {
      this.setState({
        vendorSelected: value,
      });
    } else {
      this.setState({
        vendorSelected: null,
        detectVendorToggle: 0,
      });
      //this.props.clearEquipmentModelsList();
      this.props.clearVendorsList();
    }
  };

  getSelectedModel = (event, value) => {
    this.setState({
      modelSelected: value,
      vendorSelected: null,
    });
  };

  handlePurchaseDateChange = (date) => {
    if (date !== null) {
      const dd = date.getDate();
      const mm = date.getMonth() + 1;
      const yyyy = date.getFullYear();
      const fullDate = yyyy + "-" + mm + "-" + dd;
      this.setState({
        purchaseDate: date,
        purchaseDateFormatted: fullDate,
      });
    } else {
      this.setState({
        purchaseDate: null,
        purchaseDateFormatted: null,
      });
    }
  };

  handleAssignDateChange = (date) => {
    if (date !== null) {
      const dd = date.getDate();
      const mm = date.getMonth() + 1;
      const yyyy = date.getFullYear();
      const fullDate = yyyy + "-" + mm + "-" + dd;
      this.setState({
        assignDate: date,
        assignDateFormatted: fullDate,
      });
    } else {
      this.setState({
        assignDate: null,
        assignDateFormatted: null,
      });
    }
  };

  handleTextList = (name) => ({ target: { value } }) => {
    this.setState({
      ...this.state,
      [name]: value,
    });
  };

  handleCheckboxChange = (name) => (event) => {
    this.setState({
      ...this.state,
      [name]: event.target.checked
    });
  };

  setOpenEmployee = () => {
    const openEmployee = this.state.openEmployee;
    if (openEmployee === false && this.props.usersList.length === 0) {
      this.props.getUsersList();
    }
    this.setState({
      openEmployee: !openEmployee,
    });
  };

  getSelectedEmployee = (event, value) => {
    this.setState({
      employeeSelected: value,
    });
    if (value !== null) {
      this.props.getUserDetails(value.id, "s");
    } else {
      this.props.clearUserDetails("s");
    }
  };

  handleAddAsset = () => {
    const serialNumber = this.state.serialNumber;
    const partNumber = this.state.productNumber;
    const fvNumber = this.state.fvNumber;
    const eqValue = this.state.eqValue;

    //console.log(this.state.modelSelected);
    const selectedVendorPk = this.state.vendorSelected.pk;
    const vendorIndex = this.state.modelSelected.vendors.findIndex(vendor => vendor === selectedVendorPk);
    const modelId = this.state.modelSelected.models_ids[vendorIndex];

    const assetbody = {
      partNumber: partNumber === "" ? "" : partNumber,
      serialNumber: serialNumber === "" ? "" : serialNumber,
      status: "iu",
      description: this.state.description,
      assignDate: this.state.assignDateFormatted,
      purchaseDate: this.state.purchaseDateFormatted,
      owner: this.state.employeeSelected.id,
      transferowner: null,
      acceptowner: null,
      currentEquipmentTransferLog: null,
      model: modelId,
      scrapageChargeStatus: "nr",
      fvNumber: fvNumber ?? null,
      eqValue: eqValue ?? null,
      isST: this.state.isST,
    };

    this.props.addAsset(assetbody, this.state.modelSelected);
  };

  render() {
    const { classes, equipmentModels, vendorsList, usersList } = this.props;
    const {
      activeStep,
      openVendor,
      openModel,
      openEmployee,
      vendorSelected,
      modelSelected,
      employeeSelected,
      detectVendorToggle,
      purchaseDate,
      purchaseDateFormatted,
      assignDate,
      assignDateFormatted,
      serialNumber,
      productNumber,
      description,
      eqValue,
      isST,
      fvNumber,
    } = this.state;
    const steps = getSteps();

    const loadingVendor = openVendor && vendorsList.length === 0;
    const loadingModel = openModel && equipmentModels.length === 0;
    const loadingEmployee = openEmployee && usersList.length === 0;

    const buttonNextEn = (
      <Button
        variant="contained"
        color="primary"
        onClick={this.handleNext}
        className={classes.buttonNext}
      >
        Dalej
      </Button>
    );

    const buttonNextDi = (
      <Button
        disabled
        variant="contained"
        color="primary"
        onClick={this.handleNext}
        className={classes.buttonNext}
      >
        Dalej
      </Button>
    );

    const buttonAddEn = (
      <Button
        variant="contained"
        color="primary"
        onClick={this.handleAddAsset}
        className={classes.buttonAdd}
      >
        Dodaj
      </Button>
    );

    const buttonAddDi = (
      <Button
        disabled
        variant="contained"
        color="primary"
        onClick={this.handleNext}
        className={classes.buttonAdd}
      >
        Dodaj
      </Button>
    );

    const autocompleteVendorDi = (
      <Autocomplete
        disabled
        value={vendorSelected}
        id="asynchronous-demo"
        style={{ width: "300px" }}
        open={openVendor}
        onOpen={this.setOpenVendor}
        onClose={this.setOpenVendor}
        onChange={this.getSelectedVendor}
        getOptionSelected={(option, value) => option.pk === value.pk}
        getOptionLabel={(option) => option.name}
        options={vendorsList}
        loading={loadingVendor}
        renderInput={(params) => (
          <CssTextField
            {...params}
            label="wybierz producenta z listy..."
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loadingVendor ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
      />
    );

    const autocompleteVendorEn = (
      <Autocomplete
        value={vendorSelected}
        id="asynchronous-demo"
        style={{ width: "300px" }}
        open={openVendor}
        onOpen={this.setOpenVendor}
        onClose={this.setOpenVendor}
        onChange={this.getSelectedVendor}
        getOptionSelected={(option, value) => option.pk === value.pk}
        getOptionLabel={(option) => option.name}
        options={vendorsList}
        loading={loadingVendor}
        renderInput={(params) => (
          <CssTextField
            {...params}
            label="wybierz producenta z listy..."
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loadingVendor ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
      />
    );

    const step0Form = (
      <Card style={{ overflow: "auto" }}>
        <CardBody>
          <Typography
            style={{
              marginBottom: "50px",
              color: "#888888",
              textAlign: "left",
              fontFamily: "Lato, Open Sans, sans-serif",
              fontSize: "1em",
              fontWeight: "300",
            }}
          >
            Wybierz producenta, dla którego mam filtrować modele. Jeżeli
            producent jest nieznany, wybierz opcję "BRAK"...
          </Typography>
          <ThemeProvider theme={materialTheme}>
            <Autocomplete
              value={modelSelected}
              id="asynchronous-demo"
              style={{ width: "300px" }}
              open={openModel}
              onOpen={this.setOpenModel}
              onClose={this.setOpenModel}
              onChange={this.getSelectedModel}
              getOptionSelected={(option, value) => option.pk === value.pk}
              getOptionLabel={(option) =>
                option.description
                  ? option.name + " (" + option.description + ")"
                  : option.name
              }
              //options={equipmentModels}
              groupBy={(option) => option.eqtype_description}
              options={equipmentModels.sort((a, b) => {
                if (a.eqtype_description < b.eqtype_description) return -1;
                if (a.eqtype_description > b.eqtype_description) return 1;
                return a.name.localeCompare(b.name);
              })}
              loading={loadingModel}
              renderInput={(params) => (
                <CssTextField
                  {...params}
                  label="wybierz model z listy..."
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {loadingModel ? (
                          <CircularProgress color="inherit" size={20} />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                />
              )}
            />
          </ThemeProvider>
          <Typography
            style={{
              marginBottom: "50px",
              color: "#888888",
              textAlign: "left",
              fontFamily: "Lato, Open Sans, sans-serif",
              fontSize: "1em",
              fontWeight: "300",
            }}
          >
            Wybierz model środka trwałego, który mam przypisać pracownikowi...
          </Typography>
          <ThemeProvider theme={materialTheme}>
            {modelSelected ? autocompleteVendorEn : autocompleteVendorDi}
          </ThemeProvider>
        </CardBody>
      </Card>
    );

    const step1Form = (
      <Card style={{ overflow: "auto" }}>
        <CardBody>
          <ThemeProvider theme={materialTheme}>
            <Typography
              style={{
                marginBottom: "50px",
                color: "#888888",
                textAlign: "left",
                fontFamily: "Lato, Open Sans, sans-serif",
                fontSize: "1em",
                fontWeight: "300",
              }}
            >
              Uzupełnij informacje dodatkowe ...
            </Typography>
            <TextField
              label="Numer seryjny"
              id="filled-margin-none"
              className={classes.textField}
              value={serialNumber || ""}
              onChange={this.handleTextList("serialNumber")}
            //variant="filled"
            />
            <TextField
              label="Numer produktu"
              id="filled-margin-none"
              className={classes.textField}
              value={productNumber || ""}
              onChange={this.handleTextList("productNumber")}
            //variant="filled"
            />
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                className={classes.textField}
                autoOk
                disableToolbar
                variant="inline"
                format="MM/dd/yyyy"
                margin="normal"
                id="date-picker-inline"
                label="Data zakupu"
                value={purchaseDate}
                onChange={this.handlePurchaseDateChange}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
              />
            </MuiPickersUtilsProvider>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                className={classes.textField}
                autoOk
                disableToolbar
                variant="inline"
                format="MM/dd/yyyy"
                margin="normal"
                id="date-picker-inline"
                label="Data przekazania"
                value={assignDate}
                onChange={this.handleAssignDateChange}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
              />
            </MuiPickersUtilsProvider>
            <TextField
              label="Opis dodatkowy"
              placeholder="Tutaj możesz wpisać dodatkowe informacje..."
              style={{ width: "100%" }}
              className={classes.textField}
              value={description || ""}
              onChange={this.handleTextList("description")}
              InputLabelProps={{
                shrink: true,
              }}
            //variant="filled"
            />
            <TextField
              label="Faktura"
              placeholder="Numer faktury"
              className={classes.textField}
              value={fvNumber}
              onChange={this.handleTextList("fvNumber")}
            />
            <TextField
              label="Wartość"
              placeholder="Kwota netto z faktury"
              className={classes.textField}
              value={eqValue}
              onChange={this.handleTextList("eqValue")}
            />
            <FormControlLabel
              style={{ margin: "26px 8px 12px 0px" }}
              control={
                <Checkbox
                  checked={isST}
                  onChange={this.handleCheckboxChange('isST')}
                  name="isST"
                  color="primary"
                />
              }
              label="Środek Trwały (księgowo)?"
            />
            <Typography
              className={classes.textSumary}
              style={{ margin: "8px" }}
            >
              {!isST ? "Środki trwałe w kontekście księgowym: sprzęt IT oraz przedmioty o wartości powyżej 10 000 zł" : ""}
            </Typography>
            <Typography
              className={classes.textSumary}
              style={{ margin: "8px" }}
            >
              {isST ? "W przypadku środka trwałego w kontekście księgowym podanie wartości i numeru faktury jest obowiązkowe" : ""}
            </Typography>
          </ThemeProvider>
        </CardBody>
      </Card>
    );

    const step2Form = (
      <Card style={{ overflow: "auto" }}>
        <CardBody>
          <Typography
            style={{
              marginBottom: "50px",
              color: "#888888",
              textAlign: "left",
              fontFamily: "Lato, Open Sans, sans-serif",
              fontSize: "1em",
              fontWeight: "300",
            }}
          >
            Wybierz pracownika, do którego ma byc przypisany środek trwały...
          </Typography>
          <ThemeProvider theme={materialTheme}>
            <Autocomplete
              value={employeeSelected}
              id="asynchronous-demo"
              style={{ width: "300px" }}
              open={openEmployee}
              onOpen={this.setOpenEmployee}
              onClose={this.setOpenEmployee}
              onChange={this.getSelectedEmployee}
              getOptionSelected={(option, value) =>
                option.last_name === value.last_name &&
                option.first_name === value.first_name
              }
              getOptionLabel={(option) =>
                option.first_name + " " + option.last_name
              }
              options={usersList}
              loading={loadingEmployee}
              renderInput={(params) => (
                <CssTextField
                  {...params}
                  label="wybierz z listy..."
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {loadingEmployee ? (
                          <CircularProgress color="inherit" size={20} />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                />
              )}
            />
          </ThemeProvider>
        </CardBody>
      </Card>
    );

    const step0Summary = (
      <Card style={{ overflow: "auto", minHeight: "135px" }}>
        <CardBody>
          <Typography className={classes.textSumary}>
            producent:{" "}
            <b className={classes.textBold}>
              {vendorSelected ? vendorSelected.name : ""}
            </b>
          </Typography>
          <Typography className={classes.textSumary}>
            model:
            <b className={classes.textBold}>
              {" "}
              {modelSelected && modelSelected.description
                ? modelSelected.name + " (" + modelSelected.description + ")"
                : ""}
              {modelSelected && !modelSelected.description
                ? modelSelected.name
                : ""}
            </b>
          </Typography>
        </CardBody>
      </Card>
    );

    const step1Summary = (
      <Card style={{ overflow: "auto", minHeight: "135px" }}>
        <CardBody>
          <Typography className={classes.textSumary}>
            numer seryjny:{" "}
            <b className={classes.textBold}>
              {serialNumber ? serialNumber : ""}
            </b>
          </Typography>
          <Typography className={classes.textSumary}>
            numer produktu:{" "}
            <b className={classes.textBold}>
              {productNumber ? productNumber : ""}
            </b>
          </Typography>
          <Typography className={classes.textSumary}>
            data zakupu:{" "}
            <b className={classes.textBold}>{purchaseDateFormatted}</b>
          </Typography>
          <Typography className={classes.textSumary}>
            data przekazania:{" "}
            <b className={classes.textBold}>{assignDateFormatted}</b>
          </Typography>
          <Typography className={classes.textSumary}>
            opis dodatkowy:{" "}
            <b className={classes.textBold}>{description ? description : ""}</b>
          </Typography>
        </CardBody>
      </Card>
    );

    const step2Summary = (
      <Card style={{ overflow: "auto", minHeight: "135px" }}>
        <CardBody>
          <Typography className={classes.textSumary}>
            pracownik:{" "}
            <b className={classes.textBold}>
              {employeeSelected
                ? employeeSelected.first_name + " " + employeeSelected.last_name
                : ""}
            </b>
          </Typography>
        </CardBody>
      </Card>
    );

    return (
      <Grid container justify="center" spacing={2}>
        <Grid item xs={12} sm={12} md={12}>
          <Card style={{ overflow: "auto" }}>
            <CardBody>
              <Stepper
                alternativeLabel
                activeStep={activeStep}
                connector={<ColorlibConnector />}
              >
                {steps.map((label) => (
                  <Step key={label}>
                    <StepLabel StepIconComponent={ColorlibStepIcon}>
                      {label}
                    </StepLabel>
                  </Step>
                ))}
              </Stepper>
            </CardBody>
          </Card>
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          {activeStep === 0 ? step0Form : null}
          {activeStep === 1 ? step1Form : null}
          {activeStep === 2 ? step2Form : null}
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <Button
            disabled={activeStep === 0}
            onClick={this.handleBack}
            className={classes.button}
          >
            Wstecz
          </Button>
          {/*activeStep === steps.length - 1 ? buttonAdd : buttonNext*/}
          {activeStep === 0 && vendorSelected && modelSelected
            ? buttonNextEn
            : (
              activeStep === 1 && assignDate && ((isST && eqValue && fvNumber) || !isST)
                ? buttonNextEn
                : (
                  activeStep === 2 && employeeSelected
                    ? buttonAddEn
                    : buttonAddDi
                )
            )
          }
        </Grid>
        <Grid item xs={12} sm={12} md={4}>
          {step0Summary}
        </Grid>
        <Grid item xs={12} sm={12} md={4}>
          {step1Summary}
        </Grid>
        <Grid item xs={12} sm={12} md={4}>
          {step2Summary}
        </Grid>

        <Grid item xs={12} sm={12} md={12}>
          <Typography className={classes.instructions}>
            {getStepContent(activeStep)}
          </Typography>
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = (state) => ({
  equipmentModels: state.assets.equipmentModels,
  vendorsList: state.assets.vendorsList,
  usersList: state.common.users,
  userDetails: state.common.suser,
  queryInProgress: state.assets.queryInProgress,
  queryInProgressCommon: state.common.queryInProgress,
});

export default connect(
  mapStateToProps,
  {
    getEquipmentModelsListGrouped,
    getVendorsListFiltered,
    clearEquipmentModelsList,
    getUsersList,
    getUserDetails,
    clearUserDetails,
    clearVendorsList,
  }
)(withStyles(styles, { withTheme: true })(AddAssetForm));
