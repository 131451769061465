import React, { Component, Fragment } from "react";
import { Redirect } from "react-router-dom";
import Container from "@material-ui/core/Container";
import { ThemeProvider } from "@material-ui/styles";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import { createMuiTheme } from "@material-ui/core";
import { sendCode, verifyCode, clearCode } from "../Redux/actions/verifycodes"
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { login } from "../Redux/actions/auth";
import CircularProgress from "@material-ui/core/CircularProgress";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const CssTextField = withStyles({
  root: {
    width: 300,
    flexWrap: "wrap",
    marginTop: "50px",
    marginLeft: "30px",
    marginRight: "30px",
    "& label.Mui-focused": {
      color: "#0071bc"
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#0071bc"
    }
  }
})(TextField);

const materialTheme = createMuiTheme({
  overrides: {
    MuiButton: {
      textPrimary: {
        color: "#0071BC"
      },
      containedPrimary: {
        backgroundColor: "#1BAB7F",
        "&:hover": {
          backgroundColor: "#188E6A"
        }
      }
    }
  }
});

export class Login extends Component {
  state = {
    username: "",
    password: "",
    usernameErrorFlag: false,
    passwordErrorFlag: false,
    openStep1: false,
    code: '',
  };

  static propTypes = {
    login: PropTypes.func.isRequired,
    isAuthenticated: PropTypes.bool,
    isLoading: PropTypes.bool,
    codeValid: PropTypes.any.isRequired,
    sendCode: PropTypes.func.isRequired,
    clearCode: PropTypes.func.isRequired,
    verifyCode: PropTypes.func.isRequired,
  };

  handleNewCode = () => {
    const body = {
      login: true,
      name: this.state.username,
    }

    this.props.sendCode(body);
  }

  handleTextList = name => ({ target: { value } }) => {
    this.setState({
      ...this.state,
      [name]: value,
    });
  };

  handleCheckCode = () => {
    const body = {
      login: true,
      name: this.state.username,
      code: this.state.code,
    }

    this.props.verifyCode(body);
  }

  handleOpenStep1 = (eventObj) => {
    this.handleNewCode();
    this.setState({
      openStep1: true,
    })
  }

  handleCloseStep1 = () => {
    this.setState({
      openStep1: false,
      code: '',
    })
  }

  componentDidUpdate() {
    if (this.props.codeValid) {
      this.props.login(this.state.username, this.state.password);
      this.props.clearCode();
      this.handleCloseStep1();
    }
  }

  sprawdzTextList = (value, label) => {
    //username, password
    switch (label) {
      case "username":
        if (value === "") {
          this.setState({
            usernameErrorFlag: true
          });
          return true;
        } else {
          this.setState({
            usernameErrorFlag: false
          });
          return false;
        }
      case "password":
        if (value === "") {
          this.setState({
            passwordErrorFlag: true
          });
          return true;
        } else {
          this.setState({
            passwordErrorFlag: false
          });
          return false;
        }
      default:
        return false;
    }
  };

  handleTextFieldChange = name => ({ target: { value } }) => {
    this.setState({
      ...this.state,
      [name]: value
    });
  };

  onSubmit = e => {
    e.preventDefault();
    //dane do walidacji
    const {
      username,
      password
    } = this.state;

    //walidacja pól tekstowych i list
    const usernameErrorFlag = this.sprawdzTextList(username, "username");
    const passwordErrorFlag = this.sprawdzTextList(password, "password");

    if (
      usernameErrorFlag === true ||
      passwordErrorFlag === true
    ) {
    } else {
      this.handleOpenStep1();
    }
  };

  render() {
    if (this.props.isAuthenticated) {
      return <Redirect to="/" />;
    }

    const { username, password, usernameErrorFlag, passwordErrorFlag, openStep1, code } = this.state;
    const { isLoading } = this.props;

    const circle = (
      <CircularProgress color="secondary" />
    );
    const button = (
      <Button type="submit" variant="contained" color="primary">
        Zaloguj się
      </Button>
    );

    return (
      <Fragment>
        <Dialog open={openStep1} onClose={this.handleCloseStep1}>
          <DialogTitle>Autoryzacja logowania - Kod E-Mail</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Na twój adres e-mail został wysłany 6-cyfrowy kod autoryzacyjny.
            </DialogContentText>
            <TextField
              margin="dense"
              id="code"
              label="Wprowadź kod"
              type="text"
              fullWidth
              variant="standard"
              value={code}
              onChange={this.handleTextList("code")}
              onKeyPress={this.handleKeyPress}
            />
            <DialogContentText></DialogContentText>
            <DialogContentText>
              <Button onClick={this.handleNewCode}>Wyślij nowy kod</Button>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleCloseStep1}>Anuluj</Button>
            <Button onClick={this.handleCheckCode}>Zaloguj</Button>
          </DialogActions>
        </Dialog>

        <Container maxWidth="sm">
          <h1>Zaloguj się do systemu</h1>
        </Container>
        <Container
          maxWidth={false}
          style={{
            backgroundColor: "#eeeeee",
            marginTop: "50px",
            marginBottom: "50px"
          }}
        >
          <form
            onSubmit={this.onSubmit}
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            <CssTextField
              error={usernameErrorFlag}
              label="Login"
              value={username}
              inputProps={{ maxLength: 20 }}
              margin="normal"
              onChange={this.handleTextFieldChange("username")}
            />
            <CssTextField
              error={passwordErrorFlag}
              label="Hasło"
              value={password}
              type="password"
              inputProps={{ maxLength: 20 }}
              margin="normal"
              onChange={this.handleTextFieldChange("password")}
            />
            <div style={{ height: "80px" }}></div>
            <ThemeProvider theme={materialTheme}>
              {isLoading ? circle : button}
            </ThemeProvider>
            <div style={{ height: "80px" }}></div>
          </form>
        </Container>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated,
  isLoading: state.auth.isLoading,
  codeValid: state.verifycodes.valid,
});

export default connect(
  mapStateToProps,
  { login, sendCode, verifyCode, clearCode }
)(Login);
