import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import EmployeeBalanceView from "./Employee/EmployeeBalanceView";
import EmployeeTransactionsView from "./Employee/EmployeeTransactionsView";
import ClearanceView from "./ManagerAccountant/ClearanceView";
import AcceptanceView from "./ManagerAccountant/AcceptanceView";
import CardsView from "./ManagerAccountant/CardsView";
import { connect } from "react-redux";
import { patchProfile } from "../Redux/actions/auth";
import { TabPanel } from "../common/TabPanel";

export class AccountingViewer extends Component {
  state = {
    value: null,
  };

  static propTypes = {
    auth: PropTypes.object.isRequired,
    patchProfile: PropTypes.func.isRequired,
  };

  static getDerivedStateFromProps(props, state) {
    if (props.auth.user !== null && state.value === null) {
      return {
        value: props.auth.main_profile.accountingDefaultTab,
      };
    } else {
      return null;
    }
  }

  handleChange = (event, newValue) => {
    this.setState({
      value: newValue,
    });
    const profileViewBody = {
      accountingDefaultTab: newValue,
    };

    this.props.patchProfile(profileViewBody, this.props.auth.main_profile.pk);
  };

  render() {
    const { value } = this.state;
    const {
      accounting_saldo_tab,
      accounting_transactions_tab,
      accounting_cards_tab,
      accounting_acceptance_tab,
      accounting_clearance_tab,
    } = this.props.auth.permissions;
    const isSuperUser = this.props.auth.user.is_superuser;

    var subViewsConfig;
    subViewsConfig = (
      <Fragment>
        <Tabs
          value={value}
          onChange={this.handleChange}
          aria-label="simple tabs example"
        >
          <Tab
            label="Moje saldo"
            disabled={!accounting_saldo_tab && !isSuperUser}
          />
          <Tab
            label="Transakcje"
            disabled={!accounting_transactions_tab && !isSuperUser}
          />
          <Tab label="Karty" disabled={!accounting_cards_tab && !isSuperUser} />
          <Tab
            label="Akceptacje i Zwroty"
            disabled={!accounting_acceptance_tab && !isSuperUser}
          />
          <Tab
            label="rozliczenia"
            disabled={!accounting_clearance_tab && !isSuperUser}
          />
        </Tabs>
        <TabPanel value={value} index={0}>
          <EmployeeBalanceView />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <EmployeeTransactionsView />
        </TabPanel>
        <TabPanel value={value} index={3}>
          <AcceptanceView />
        </TabPanel>
        <TabPanel value={value} index={4}>
          <ClearanceView />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <CardsView />
        </TabPanel>
      </Fragment>
    );

    return <div>{subViewsConfig}</div>;
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(
  mapStateToProps,
  { patchProfile }
)(AccountingViewer);
