import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  getTripDetails,
  patchTrip,
  updateTrip,
  clearTripDetails,
  addTimestamp
} from "../Redux/actions/trips";
import { sendNotification, sendNewNotification } from "../Redux/actions/employee";
import { getEmployeeSecureDetails } from "../Redux/actions/employee";
import "../Style.css";
import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Chip from "@material-ui/core/Chip";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Fab from "@material-ui/core/Fab";
import SendIcon from "@material-ui/icons/Send";
import SaveIcon from "@material-ui/icons/Save";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import IconButton from "@material-ui/core/IconButton";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContentText from "@material-ui/core/DialogContentText";
import ReadOnly from "./ReadOnly";
import ReadWrite from "./ReadWrite";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import DialogContent from "@material-ui/core/DialogContent";
import Comment from "../Trips-manager/Comment";
import Timestamp from "../Trips-manager/Timestamp";
import EuroIcon from "@material-ui/icons/Euro";

//--------------------Style dla Material-UI-----------------------------------//

const styles = theme => ({
  text: {
    padding: theme.spacing(2, 2, 0)
  },
  paper: {
    paddingBottom: 50
  },
  list: {
    marginBottom: theme.spacing(2)
  },
  subheader: {
    backgroundColor: theme.palette.background.paper
  },
  appBar: {
    top: "auto",
    bottom: 0,
    background: "#0071BC"
  },
  grow: {
    flexGrow: 1
  },
  fabButton: {
    position: "absolute",
    zIndex: 1,
    top: -30,
    left: 0,
    right: 0,
    margin: "0 auto"
  },
  chipNW: {
    background: "#1bab7f",
    color: "#ffffff"
  },
  chipCA: {
    background: "#c2a33a",
    color: "#ffffff"
  },
  chipZA: {
    background: "#0071BC",
    color: "#ffffff"
  },
  chipRZ: {
    background: "#b35340",
    color: "#ffffff"
  },
  chipZM: {
    background: "#aaaaaa",
    color: "#ffffff"
  }
});

class TripsDetails extends Component {
  state = {
    dialogSendOpen: false,
    dialogAccountingOpen: false,
    dialogEndDateOpen: false,
    dialogTimestampsOpen: false,
    sendTripId: null,
    saveTripId: null,
    dialogSaveOpen: false,
    place: null,
    editFlag: false,
    saveTrigger: false,
    //
    anchorEl: null,
    tripId: null,
    remarks: "",
    changeEndDateFlag: false,
    saveEndDateFlag: false,
    displayCommentsFlag: true,
    displayTimestampsFlag: false,
    dialogAddTimestamp: false,
    description: "",
    selectedDateTime: null,
    selectedDateTimeFormatted: null,
    timestampType: ""
  };

  static propTypes = {
    auth: PropTypes.object.isRequired,
    tripDetails: PropTypes.object.isRequired,
    getTripDetails: PropTypes.func.isRequired,
    addTimestamp: PropTypes.func.isRequired,
    clearTripDetails: PropTypes.func.isRequired,
    patchTrip: PropTypes.func.isRequired,
    updateTrip: PropTypes.func.isRequired,
    getEmployeeSecureDetails: PropTypes.func.isRequired,
    employeeSecureDetails: PropTypes.object.isRequired,
    sendNotification: PropTypes.func.isRequired,
    sendNewNotification: PropTypes.func.isRequired,
  };

  componentWillUnmount() {
    this.props.clearTripDetails();
  }

  componentDidMount() {
    this.props.getTripDetails(this.props.match.params.pk);
    this.props.getEmployeeSecureDetails(this.props.auth.user.id);
  }

  handleClick = event => {
    this.setState({
      anchorEl: event.currentTarget
    });
  };

  handleCloseMenu = () => {
    this.setState({
      anchorEl: null
    });
  };

  handleOpenSendWarning = tripId => {
    this.setState({
      dialogSendOpen: true,
      sendTripId: tripId
    });
  };

  handleCloseSendWarning = () => {
    this.setState({
      dialogSendOpen: false,
      sendTripId: null
    });
  };

  handleOpenAccountingWarning = tripId => {
    //sprawdź czy data zakończenia delegacji jest uzupełniona//sprawdź czy timestampy zostały zdefiniowane

    if (this.props.tripDetails.endDate === null) {
      this.setState({
        dialogEndDateOpen: true
      });
    } else if (
      this.props.tripDetails.timestamps.length < 2 &&
      this.props.tripDetails.country === "PL"
    ) {
      this.setState({
        dialogTimestampsOpen: true
      });
    } else if (
      this.props.tripDetails.timestamps.length < 4 &&
      this.props.tripDetails.country !== "PL"
    ) {
      this.setState({
        dialogTimestampsOpen: true
      });
    } else {
      this.setState({
        dialogAccountingOpen: true,
        tripId: tripId
      });
    }
  };

  handleCloseAccountingWarning = () => {
    this.setState({
      dialogAccountingOpen: false,
      tripId: null
    });
  };

  handleCloseDialogEndDate = () => {
    this.setState({
      dialogEndDateOpen: false
    });
  };

  handleCloseDialogTimestamps = () => {
    this.setState({
      dialogTimestampsOpen: false
    });
  };

  handleSendTrip = () => {
    const TripToBeSent = this.state.sendTripId;
    const message = "Delegacja wysłana do akceptacji";
    //przygotowanie body
    const trip = {
      tripStatus: "CA"
    };
    this.props.patchTrip(trip, TripToBeSent, message);
    //wysyłanie SMSa
    /*
    const recipient = "48501600544";
    const sender = "DELEGACJE";
    const smsmessage =
      "Delegacja uzytkownika " +
      this.props.auth.user.username +
      " o numerze " +
      this.props.tripDetails.tripIdentifier +
      " czeka na twoja akceptacje!";
    const owner = this.props.auth.user.id;
    const notificationType = "sms";
    const isDelivered = true;
    //przygotowanie body
    const body = {
      recipient,
      sender,
      message: smsmessage,
      owner,
      notificationType,
      isDelivered
    };
    this.props.sendNotification(body);
    */

    /*
    const notifyBody = {
      recipient: this.props.tripDetails.manager.email,
      module: "Delegacje",
      topic: "Delegacja oczekuje na akceptację",
      message:
        "Delegacja o numerze " + this.props.employeeTripsRZ[index].tripIdentifier + " oczekuje na akceptację."
    }
    this.props.sendNewNotification(notifyBody);
    */

    this.setState({
      dialogSendOpen: false,
      sendTripId: null
    });
  };

  handleSendAccountingTrip = () => {
    const TripToBeSent = this.state.tripId;
    const message = "Delegacja wysłana do księgowości";
    //przygotowanie body
    const trip = {
      tripStatus: "RZ"
    };
    this.props.patchTrip(trip, TripToBeSent, message);
    this.setState({
      dialogAccountingOpen: false,
      tripId: null
    });
  };

  handleSaveTrip = trip => {
    //tutaj robię zapis do bazy
    const TripToBeSave = this.state.saveTripId;
    this.props.updateTrip(trip, TripToBeSave);
    //tutaj zmieniam button z powrotem na SEND
    this.setState({
      editFlag: false,
      saveTripId: null,
      saveTrigger: false
    });
  };

  handleChangeEndDate = endDate => {
    const tripId = this.state.tripId;
    const message = "data zakończenia została zaktualizowana";
    //przygotowanie body
    const tripBody = {
      endDate
    };
    this.props.patchTrip(tripBody, tripId, message);

    this.setState({
      tripId: null,
      changeEndDateFlag: false,
      saveEndDateFlag: false
    });
  };

  triggerChangeEndDate = tripId => {
    this.setState({
      tripId: tripId,
      changeEndDateFlag: true,
      anchorEl: null
    });
  };

  triggerSaveEndDate = tripId => {
    this.setState({
      saveEndDateFlag: true
    });
  };

  triggerSaveTrip = tripId => {
    this.setState({
      saveTrigger: true,
      saveTripId: tripId
    });
  };

  triggerEditMode = tripId => {
    this.setState({
      editFlag: true,
      tripId: tripId,
      anchorEl: null
    });
  };

  handleTextList = name => ({ target: { value } }) => {
    this.setState({
      ...this.state,
      [name]: value
    });
  };

  handleDateTimeChange = datetime => {
    if (datetime !== null) {
      const dd = datetime.getDate();
      const mm = datetime.getMonth() + 1;
      const yyyy = datetime.getFullYear();
      const HH = datetime.getHours();
      const MM = datetime.getMinutes();
      const date = yyyy + "-" + mm + "-" + dd + "T" + HH + ":" + MM;

      this.setState({
        selectedDateTime: datetime,
        selectedDateTimeFormatted: date
      });
    } else {
      this.setState({
        selectedDateTime: null,
        selectedDateTimeFormatted: null
      });
    }
  };

  handleNavigationElement = navigationElement => {
    if (navigationElement === "comments") {
      this.setState({
        displayCommentsFlag: true,
        displayTimestampsFlag: false
      });
    } else if (navigationElement === "timestamps") {
      this.setState({
        displayCommentsFlag: false,
        displayTimestampsFlag: true
      });
    }
  };

  render() {
    const { classes } = this.props;
    const { tripStatus, pk } = this.props.tripDetails;
    const {
      dialogSendOpen,
      editFlag,
      saveTrigger,
      saveEndDateFlag,
      anchorEl,
      changeEndDateFlag,
      dialogAccountingOpen,
      dialogEndDateOpen,
      dialogTimestampsOpen
    } = this.state;

    const readOnly = (
      <ReadOnly
        tripDetails={this.props.tripDetails}
        changeEndDateFlag={changeEndDateFlag}
        saveEndDateTrigger={saveEndDateFlag}
        saveEndDate={this.handleChangeEndDate}
      />
    );
    const readWrite = (
      <ReadWrite
        tripDetails={this.props.tripDetails}
        saveTrigger={saveTrigger}
        saveData={this.handleSaveTrip}
      />
    );

    var styleCSS;
    var mainAction;
    var moreMenu;
    var tripFooter;

    if (tripStatus === "NW") {
      styleCSS = classes.chipNW;
      mainAction = (
        <Fragment>
          <Fab
            onClick={this.handleOpenSendWarning.bind(this, pk)}
            color="secondary"
            aria-label="add"
            className={classes.fabButton}
          >
            <SendIcon />
          </Fab>
        </Fragment>
      );
      moreMenu = (
        <Fragment>
          <div className={classes.grow} />
          <IconButton color="inherit" edge="end" onClick={this.handleClick}>
            <MoreVertIcon />
          </IconButton>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={this.handleCloseMenu}
          >
            <MenuItem
              onClick={this.triggerEditMode.bind(
                this,
                this.props.tripDetails.pk
              )}
            >
              edytuj
            </MenuItem>
          </Menu>
        </Fragment>
      );
      tripFooter = (
        <Fragment>
          <Comment tripDetails={this.props.tripDetails} />
        </Fragment>
      );
    } else if (tripStatus === "CA") {
      styleCSS = classes.chipCA;
      mainAction = <Fragment></Fragment>;
      moreMenu = (
        <Fragment>
          <div className={classes.grow} />
          <IconButton color="inherit" edge="end" onClick={this.handleClick}>
            <MoreVertIcon />
          </IconButton>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={this.handleCloseMenu}
          >
            <MenuItem
              onClick={this.triggerEditMode.bind(
                this,
                this.props.tripDetails.pk
              )}
            >
              edytuj
            </MenuItem>
          </Menu>
        </Fragment>
      );
      tripFooter = (
        <Fragment>
          <Comment tripDetails={this.props.tripDetails} />
        </Fragment>
      );
    } else if (tripStatus === "ZA") {
      styleCSS = classes.chipZA;
      mainAction = (
        <Fragment>
          <Fab
            onClick={this.handleOpenAccountingWarning.bind(this, pk)}
            color="secondary"
            aria-label="add"
            className={classes.fabButton}
          >
            <EuroIcon />
          </Fab>
        </Fragment>
      );
      moreMenu = (
        <Fragment>
          <div className={classes.grow} />
          <IconButton color="inherit" edge="end" onClick={this.handleClick}>
            <MoreVertIcon />
          </IconButton>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={this.handleCloseMenu}
          >
            <MenuItem
              onClick={this.triggerChangeEndDate.bind(
                this,
                this.props.tripDetails.pk
              )}
            >
              zmień datę zakończenia
            </MenuItem>
          </Menu>
        </Fragment>
      );
      tripFooter = (
        <Fragment>
          <Comment tripDetails={this.props.tripDetails} />
          <Timestamp tripDetails={this.props.tripDetails} />
        </Fragment>
      );
    } else if (tripStatus === "RZ") {
      styleCSS = classes.chipRZ;
      mainAction = <Fragment></Fragment>;
      moreMenu = (
        <Fragment>
          <div className={classes.grow} />
          <IconButton color="inherit" edge="end" onClick={this.handleClick}>
            <MoreVertIcon />
          </IconButton>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={this.handleCloseMenu}
          >
            <MenuItem
              onClick={this.triggerChangeEndDate.bind(
                this,
                this.props.tripDetails.pk
              )}
            >
              zmień datę zakończenia
            </MenuItem>
          </Menu>
        </Fragment>
      );
      tripFooter = (
        <Fragment>
          <Comment tripDetails={this.props.tripDetails} />
          <Timestamp tripDetails={this.props.tripDetails} />
        </Fragment>
      );
    } else if (tripStatus === "ZM") {
      styleCSS = classes.chipZM;
      mainAction = <Fragment></Fragment>;
      moreMenu = <Fragment></Fragment>;
    }

    const saveButton = (
      <Fab
        onClick={this.triggerSaveTrip.bind(this, pk)}
        aria-label="add"
        className={classes.fabButton}
      >
        <SaveIcon />
      </Fab>
    );

    const saveEndDateButton = (
      <Fab
        onClick={this.triggerSaveEndDate.bind(this, this.props.tripDetails.pk)}
        aria-label="add"
        className={classes.fabButton}
      >
        <SaveIcon />
      </Fab>
    );

    const chip = (
      <Chip
        className={styleCSS}
        label={this.props.tripDetails.tripStatusDescription}
      />
    );

    return (
      <Fragment>
        <CssBaseline />
        <Container maxWidth="sm">
          <h1>
            szczegóły delegacji Nr: {this.props.tripDetails.tripIdentifier}
          </h1>
        </Container>
        <div style={{ marginBottom: 30 }}></div>
        {chip}
        {editFlag ? readWrite : readOnly}
        {tripFooter}
        <div style={{ marginBottom: 70 }}></div>
        <AppBar position="fixed" color="primary" className={classes.appBar}>
          <Toolbar>
            <IconButton
              color="inherit"
              edge="end"
              component={Link}
              to={"/trips"}
            >
              <ArrowBackIcon />
            </IconButton>
            {mainAction}
            {editFlag ? saveButton : ""}
            {changeEndDateFlag ? saveEndDateButton : ""}
            <div className={classes.grow} />
            {moreMenu}
          </Toolbar>
        </AppBar>

        <Dialog
          open={dialogSendOpen}
          onClose={this.handleCloseSendWarning}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Delegacja zostanie wysłana do twojego przełożonego..."}
          </DialogTitle>
          <DialogActions>
            <Button onClick={this.handleCloseSendWarning} color="primary">
              Anuluj
            </Button>
            <Button
              onClick={this.handleSendTrip}
              //this.props.deleteTrip.bind(this, trip.id)
              color="primary"
              autoFocus
            >
              Wyślij
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={dialogAccountingOpen}
          onClose={this.handleCloseAccountingWarning}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Delegacja zostanie wysłana do księgowości..."}
          </DialogTitle>
          <DialogActions>
            <Button onClick={this.handleCloseAccountingWarning} color="primary">
              Anuluj
            </Button>
            <Button
              onClick={this.handleSendAccountingTrip}
              //this.props.deleteTrip.bind(this, trip.id)
              color="primary"
              autoFocus
            >
              Wyślij
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={dialogEndDateOpen}
          onClose={this.handleCloseDialogEndDate}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Info</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Wygląda na to że nie uzupełniłeś daty zakończenia delegacji. Przed
              przekazaniem delegacji do księgowości wszystkie dane muszą byc
              uzupełnione.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleCloseDialogEndDate} color="primary">
              OK
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={dialogTimestampsOpen}
          onClose={this.handleCloseDialogTimestamps}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Info</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Wygląda na to że nie uzupełniłeś sekcji{" "}
              <b>Rozliczenie czasu delegacji</b>. Przed przekazaniem delegacji
              do księgowości wszystkie dane muszą byc uzupełnione.
              <p>
                Pamiętaj że dla <u>delegacji zagranicznej</u>, obowiązkowo
                musisz podać:
              </p>
              <ul>
                <li>Czas i miejsce rozpoczęcia podróży</li>
                <li>Czas i miejsce wyjazdu z Polski</li>
                <li>Czas i miejsce powrotu do Polski</li>
                <li>Czas i miejsce zakończenia podróży</li>
              </ul>
              <p>
                W przypadku <u>delegacji krajowej</u> obowiązkowo musisz podać:
              </p>
              <ul>
                <li>Czas i miejsce rozpoczęcia podróży</li>
                <li>Czas i miejsce zakończenia podróży</li>
              </ul>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleCloseDialogTimestamps} color="primary">
              OK
            </Button>
          </DialogActions>
        </Dialog>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  tripDetails: state.tripsReducer.tripDetails,
  auth: state.auth,
  employeeSecureDetails: state.employee.employeeSecureDetails,
});

export default connect(
  mapStateToProps,
  {
    getTripDetails,
    clearTripDetails,
    patchTrip,
    updateTrip,
    getEmployeeSecureDetails,
    addTimestamp,
    sendNotification,
    sendNewNotification,
  }
)(withStyles(styles, { withTheme: true })(TripsDetails));
