import React, { Component } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import { ThemeProvider } from "@material-ui/styles";
import { styles, materialTheme } from "../../common/radiolineStyles";
import Grid from "@material-ui/core/Grid";
import Card from "../../components/Card/Card.js";
import CardBody from "../../components/Card/CardBody.js";
import CardHeader from "@material-ui/core/CardHeader";
import { getFormattedDate } from "../../common/commonFunctions";
import { transferAsset_admin } from "../../Redux/actions/assets";
import { connect } from "react-redux";
import PropTypes from "prop-types";

export class DialogMoveToWarehouse extends Component {
  state = {
    description: "",
    today: getFormattedDate(new Date()),
  };

  static propTypes = {
    auth: PropTypes.object.isRequired,
    transferAsset_admin: PropTypes.func.isRequired,
    suserDetails: PropTypes.object.isRequired,
  };

  componentDidMount() {
    if (this.state.description === "") {
      this.setState({
        description: this.props.assetsDetails.description,
      });
    }
  }

  triggerCloseDialog = () => {
    this.props.closeDialog();
  };

  handleTextList = (name) => ({ target: { value } }) => {
    this.setState({
      ...this.state,
      [name]: value,
    });
  };

  handleMoveAsset = () => {
    const assettransferlogbody = {
      fromUser: this.props.assetsDetails.owner.username,
      toUser: "magazyn",
      acceptUser: this.props.auth.user.username,
      acceptDate: this.state.today,
      toStatus: "ta",
      acceptStatus: "ac",
      equipment: this.props.assetsDetails.pk,
    };

    const ssmsbody = {
      recipient: this.props.suserDetails.email,
      //sender: "WYPOSAZENIE",
      module: "Środki Trwałe",
      topic: "Przeniesienie środka trwałego do magazynu",
      message:
        "Użytkownik " +
        this.props.auth.user.first_name + " " + this.props.auth.user.last_name +
        " przeniósł twój środek trwały " +
        this.props.assetsDetails.model.name +
        " " +
        this.props.assetsDetails.model.description +
        " do magazynu.",
      //owner: this.props.auth.user.id,
      //notificationType: "sms",
      //isDelivered: true,
    };

    const assetbody = {
      status: "is",
      description: this.state.description,
      assignDate: this.state.today,
      scrapageChargeStatus: "nr",
      owner: null,
      transferowner: null,
      acceptowner: null,
    };

    this.props.transferAsset_admin(
      this.props.assetsDetails.pk,
      assetbody,
      assettransferlogbody,
      ssmsbody,
      null,
      this.props.assetsDetails.model.eqtype,
      "u2w"
    );
    this.props.closeDialog();
  };

  render() {
    const { classes, dialogMoveToWarehouse, assetsDetails } = this.props;
    const { description } = this.state;

    const assetMoveButtonEnabled = (
      <Grid container className={classes.root} spacing={2} justify="center">
        <Button
          variant="contained"
          color="inherit"
          size="large"
          onClick={this.handleMoveAsset}
          style={{ marginTop: "20px" }}
          className={classes.buttonAccept}
        >
          Przesuń
        </Button>
      </Grid>
    );

    const assetContent = (
      <Grid container className={classes.root} spacing={2} justify="center">
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          style={{ marginTop: "20px" }}
        >
          <Typography className={classes.textType2}>
            Szczegóły przekazywanego środka trwałego
          </Typography>
        </Grid>

        <Grid p={0} m={0} item xs={12} sm={6} md={3} lg={3}>
          <Card style={{ margin: 0 }}>
            <CardBody>
              <Typography className={classes.textType}>
                {assetsDetails.model.eqtype_description}
              </Typography>
              <Typography className={classes.textName}>
                <b>{assetsDetails.model.name}</b>
              </Typography>
              <Typography className={classes.textDescription}>
                {assetsDetails.model.description !== ""
                  ? assetsDetails.model.description
                  : "model nieznany"}
              </Typography>
            </CardBody>
          </Card>
        </Grid>

        <Grid p={0} m={0} item xs={12} sm={6} md={3} lg={3}>
          <Card style={{ margin: 0 }}>
            <CardHeader
              title={
                <Typography className={classes.textName2}>Producent</Typography>
              }
            ></CardHeader>
            <CardBody>
              <Typography className={classes.textDescription2}>
                {assetsDetails.model.vendor}
              </Typography>
            </CardBody>
          </Card>
        </Grid>
        <Grid p={0} m={0} item xs={12} sm={6} md={3} lg={3}>
          <Card style={{ margin: 0 }}>
            <CardHeader
              title={
                <Typography className={classes.textName2}>
                  Numer seryjny
                </Typography>
              }
            ></CardHeader>
            <CardBody>
              <Typography className={classes.textDescription2}>
                {assetsDetails.serialNumber === ""
                  ? "-BRAK-"
                  : assetsDetails.serialNumber}
              </Typography>
            </CardBody>
          </Card>
        </Grid>
        <Grid p={0} m={0} item xs={12} sm={6} md={3} lg={3}>
          <Card style={{ margin: 0 }}>
            <CardHeader
              title={
                <Typography className={classes.textName2}>
                  Numer produktu
                </Typography>
              }
            ></CardHeader>
            <CardBody>
              <Typography className={classes.textDescription2}>
                {assetsDetails.partNumber === ""
                  ? "-BRAK-"
                  : assetsDetails.partNumber}
              </Typography>
            </CardBody>
          </Card>
        </Grid>
      </Grid>
    );

    const assetDescription = (
      <Grid container className={classes.root} spacing={2} justify="center">
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          style={{ marginTop: "20px" }}
        >
          <Typography className={classes.textType2}>
            Czy masz jakieś uwagi ?
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <ThemeProvider theme={materialTheme}>
            <TextField
              label="Opis dodatkowy"
              placeholder="Tutaj możesz wpisać dodatkowe informacje..."
              style={{ width: "100%" }}
              className={classes.textField}
              value={description || ""}
              onChange={this.handleTextList("description")}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </ThemeProvider>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          {assetMoveButtonEnabled}
        </Grid>
      </Grid>
    );

    return (
      <Dialog
        fullScreen
        open={dialogMoveToWarehouse}
        onClose={this.triggerCloseDialog}
        aria-labelledby="form-dialog-title"
      >
        <AppBar position="static" style={{ background: "#0071BC" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={this.triggerCloseDialog}
              aria-label="close"
              className={classes.menuButton}
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              Przesuń do magazynu
            </Typography>
          </Toolbar>
        </AppBar>
        <DialogContent style={{ background: "#f5f5f5" }}>
          {assetContent}
          {assetDescription}
        </DialogContent>
      </Dialog>
    );
  }
}

const mapStateToProps = (state) => ({
  suserDetails: state.common.suser,
  auth: state.auth,
});

export default connect(
  mapStateToProps,
  {
    transferAsset_admin,
  }
)(withStyles(styles, { withTheme: true })(DialogMoveToWarehouse));
