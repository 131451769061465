import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import DocumentListView from "./DocumentListView.js";
import { connect } from "react-redux";
import { TabPanel } from "../common/TabPanel.js";

export class DocumentViewer extends Component {
  state = {
    value: 0,
  };

  static propTypes = {
    auth: PropTypes.object.isRequired
  };

  handleChange = (event, newValue) => {
    this.setState({
      value: newValue,
    });
  };

  render() {
    const { value } = this.state;
    var subViewsConfig;

    subViewsConfig = (
      <Fragment>
        <Tabs
          value={value}
          onChange={this.handleChange}
          aria-label="Typy dokumentów"
        >
          <Tab label="Regulaminy" />
          <Tab label="Procedury" />
          <Tab label="Wnioski" />
          <Tab label="Zarządzenia" />
        </Tabs>
        <TabPanel value={value} index={0}>
          <DocumentListView type="regulation" />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <DocumentListView type="procedure" />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <DocumentListView type="application" />
        </TabPanel>
        <TabPanel value={value} index={3}>
          <DocumentListView type="preordaining" />
        </TabPanel>
      </Fragment>
    );

    return <div>{subViewsConfig}</div>;
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(
  mapStateToProps,
  {}
)(DocumentViewer);