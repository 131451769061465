import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { lighten } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import TextField from "@material-ui/core/TextField";
import Table from "@material-ui/core/Table";
import Button from "@material-ui/core/Button";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Checkbox from "@material-ui/core/Checkbox";
import Check from "@material-ui/icons/Check";
import IconButton from "@material-ui/core/IconButton";
import Badge from "components/Badge/Badge.js";
import Tooltip from "@material-ui/core/Tooltip";
import CircularProgress from "@material-ui/core/CircularProgress";
import { ThemeProvider } from "@material-ui/styles";

import DoneIcon from "@material-ui/icons/Done";
import VisibilityIcon from "@material-ui/icons/Visibility";
import SmsIcon from "@material-ui/icons/Sms";
import GetAppIcon from "@material-ui/icons/GetApp";
import { createMuiTheme } from "@material-ui/core";
import {
  patchAccountingTrip,
  getEmployeeTripDetails
} from "../Redux/actions/trips-manager";
import { sendNotification, sendNewNotification } from "../Redux/actions/employee";

import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { getEmployeeTripsFiltered } from "../Redux/actions/trips-manager";

const CssTextField = withStyles({
  root: {
    margin: 0,
    width: 250,
    flexWrap: "wrap",
    "& label.Mui-focused": {
      color: "#0071bc"
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#0071bc"
    }
  }
})(TextField);

const materialTheme = createMuiTheme({
  palette: {
    secondary: {
      main: "#0071BC"
    }
  }
});

const styles = theme => ({
  root: {
    width: "100%"
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2)
  },
  table: {
    minWidth: 750
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1
  },
  checkedIcon: {
    width: "20px",
    height: "20px",
    border: "1px solid rgba(0, 0, 0, 0.84)",
    borderRadius: "3px"
  },
  uncheckedIcon: {
    width: "0px",
    height: "0px",
    padding: "9px",
    border: "1px solid rgba(0, 0, 0, .54)",
    borderRadius: "3px"
  },
  checked: {
    color: "#0071BC"
  },
  checkRoot: {
    padding: "14px",
    "&:hover": {
      backgroundColor: "unset"
    }
  },
  roottb: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1)
  },
  highlighttb:
    theme.palette.type === "light"
      ? {
        color: "#0071BC",
        backgroundColor: lighten("#338dc9", 0.85)
      }
      : {
        color: "#0071BC",
        backgroundColor: "#004f83"
      },
  titletb: {
    flex: "1 1 100%",
    textAlign: "left"
  }
});

var pobierzWlasciwyTimestamp = {
  RP: null,
  RPkom: null,
  WP: null,
  WPkom: null,
  PP: null,
  PPkom: null,
  ZP: null,
  ZPkom: null,

  dodaj: function (element) {
    if (element.timestampType === "RP") {
      this.RP = element.value;
      this.RPkom = element.description;
    } else if (element.timestampType === "WP") {
      this.WP = element.value;
      this.WPkom = element.description;
    } else if (element.timestampType === "PP") {
      this.PP = element.value;
      this.PPkom = element.description;
    } else if (element.timestampType === "ZP") {
      this.ZP = element.value;
      this.ZPkom = element.description;
    }
  },
  zapiszRP: function (element) {
    return this.RP;
  },
  zapiszRPk: function (element) {
    return this.RPkom;
  },
  zapiszWP: function (element) {
    return this.WP;
  },
  zapiszWPk: function (element) {
    return this.WPkom;
  },
  zapiszPP: function (element) {
    return this.PP;
  },
  zapiszPPk: function (element) {
    return this.PPkom;
  },
  zapiszZP: function (element) {
    return this.ZP;
  },
  zapiszZPk: function (element) {
    return this.ZPkom;
  },
  czysc: function (element) {
    this.RP = null;
    this.RPkom = null;
    this.WP = null;
    this.WPkom = null;
    this.PP = null;
    this.PPkom = null;
    this.ZP = null;
    this.ZPkom = null;
  }
};

var daneDoTabeli = {
  //zmienne tabeli
  trippk: [],
  pracownik: [],
  numerdelegacji: [],
  datarozpoczecia: [],
  datazakonczenia: [],
  kraj: [],
  projekt: [],
  miejscedelegacji: [],
  srodektransportu: [],
  statusdelegacji: [],
  rpkom: [],
  wpkom: [],
  ppkom: [],
  zpkom: [],
  czasrozpoczeciadelegacji: [],
  rpkom: [],
  wpkom: [],
  ppkom: [],
  zpkom: [],
  pi: [],
  czaswyjazduzpolski: [],
  czaspowrotudopolski: [],
  czaszakonczeniadelegacji: [],
  lacznyczasdelegacjikrajowej: [],
  lacznyczasdelegacjizagranicznej: [],
  rows: [],

  dodaj: function (element) {
    this.trippk.push(element.pk);
    this.pracownik.push(
      element.employee.last_name + " " + element.employee.first_name
    );
    this.numerdelegacji.push(element.tripIdentifier);
    this.datarozpoczecia.push(element.startDate);
    this.datazakonczenia.push(element.endDate);
    this.kraj.push(element.countryDescription);

    var project = "";
    const projekty = element.project.map((proj) => proj.name);
    for (var i = 0; i < projekty.length; i++) {
      if (i != projekty.length - 1) {
        projekty[i] += ', ';
      }
      project += projekty[i];
    }
    this.projekt.push(project);

    this.miejscedelegacji.push(element.place);
    var transport = "";
    if (element.tVehicle) transport += "Samochód, ";
    if (element.tPlane) transport += "Samolot, ";
    if (element.tTrain) transport += "Pociąg, ";
    if (element.tShip) transport += "Statek";
    transport = transport.trim();
    if (transport.charAt(transport.length - 1) == ',') transport = transport.substring(0, transport.length - 1);
    this.srodektransportu.push(transport);

    //obsługa timestampów
    element.timestamps.forEach(
      pobierzWlasciwyTimestamp.dodaj,
      pobierzWlasciwyTimestamp
    );
    //dopasowanie formatu dat
    const RP = new Date(pobierzWlasciwyTimestamp.zapiszRP());
    const RPdd = RP.getDate();
    const RPmm = RP.getMonth() + 1;
    const RPyyyy = RP.getFullYear();
    const RPHH = RP.getHours();
    const RPMM = (RP.getMinutes() < 10 ? "0" : "") + RP.getMinutes();
    const RPdate = RPyyyy + "-" + RPmm + "-" + RPdd + " " + RPHH + ":" + RPMM;
    this.czasrozpoczeciadelegacji.push(RPdate);
    this.rpkom.push(pobierzWlasciwyTimestamp.zapiszRPk());
    const WP = new Date(pobierzWlasciwyTimestamp.zapiszWP());
    const WPdd = WP.getDate();
    const WPmm = WP.getMonth() + 1;
    const WPyyyy = WP.getFullYear();
    const WPHH = WP.getHours();
    const WPMM = (WP.getMinutes() < 10 ? "0" : "") + WP.getMinutes();
    const WPdate = WPyyyy + "-" + WPmm + "-" + WPdd + " " + WPHH + ":" + WPMM;
    this.czaswyjazduzpolski.push(WPdate);
    this.wpkom.push(pobierzWlasciwyTimestamp.zapiszWPk());
    const PP = new Date(pobierzWlasciwyTimestamp.zapiszPP());
    const PPdd = PP.getDate();
    const PPmm = PP.getMonth() + 1;
    const PPyyyy = PP.getFullYear();
    const PPHH = PP.getHours();
    const PPMM = (PP.getMinutes() < 10 ? "0" : "") + PP.getMinutes();
    const PPdate = PPyyyy + "-" + PPmm + "-" + PPdd + " " + PPHH + ":" + PPMM;
    this.czaspowrotudopolski.push(PPdate);
    this.ppkom.push(pobierzWlasciwyTimestamp.zapiszPPk());
    const ZP = new Date(pobierzWlasciwyTimestamp.zapiszZP());
    const ZPdd = ZP.getDate();
    const ZPmm = ZP.getMonth() + 1;
    const ZPyyyy = ZP.getFullYear();
    const ZPHH = ZP.getHours();
    const ZPMM = (ZP.getMinutes() < 10 ? "0" : "") + ZP.getMinutes();
    const ZPdate = ZPyyyy + "-" + ZPmm + "-" + ZPdd + " " + ZPHH + ":" + ZPMM;
    this.czaszakonczeniadelegacji.push(ZPdate);
    this.zpkom.push(pobierzWlasciwyTimestamp.zapiszZPk());

    //sprawdzenie czy delegacja gotowa do rozliczenia czy wymaga ręcznej weryfikacji
    if (element.timestamps.length !== 4) {
      this.statusdelegacji.push("wymaga weryfikacji");
    } else if (
      pobierzWlasciwyTimestamp.zapiszRP() === null ||
      pobierzWlasciwyTimestamp.zapiszWP() === null ||
      pobierzWlasciwyTimestamp.zapiszPP() === null ||
      pobierzWlasciwyTimestamp.zapiszZP() === null
    ) {
      this.statusdelegacji.push("wymaga weryfikacji");
    } else {
      this.statusdelegacji.push("gotowa do rozliczenia!");
    }
    //inicjalizacja zmiennych do wyliczenia czasów
    const msMinute = 60 * 1000;
    const msHours = 60 * 60 * 1000;
    const msDay = 60 * 60 * 24 * 1000;
    //wylicz czas delegacji zagranicznej
    const dzstart = new Date(pobierzWlasciwyTimestamp.zapiszWP());
    const dzend = new Date(pobierzWlasciwyTimestamp.zapiszPP());
    const dd = Math.floor((dzend - dzstart) / msDay);
    const hh = Math.floor(((dzend - dzstart) % msDay) / msHours);
    const mm = Math.floor((((dzend - dzstart) % msDay) % msHours) / msMinute);
    const dzczas = dd + " dni, " + hh + " godzin, " + mm + " minut";
    this.lacznyczasdelegacjizagranicznej.push(dzczas);
    //wylicz czas delegacji krajowej
    const dkstartwyjazd = new Date(pobierzWlasciwyTimestamp.zapiszRP());
    const dkendwyjazd = new Date(pobierzWlasciwyTimestamp.zapiszWP());
    const dkstartpowrot = new Date(pobierzWlasciwyTimestamp.zapiszPP());
    const dkendpowrot = new Date(pobierzWlasciwyTimestamp.zapiszZP());
    const ddwyjazd = Math.floor((dkendwyjazd - dkstartwyjazd) / msDay);
    const hhwyjazd = Math.floor(
      ((dkendwyjazd - dkstartwyjazd) % msDay) / msHours
    );
    const mmwyjazd = Math.floor(
      (((dkendwyjazd - dkstartwyjazd) % msDay) % msHours) / msMinute
    );
    const ddpowrot = Math.floor((dkendpowrot - dkstartpowrot) / msDay);
    const hhpowrot = Math.floor(
      ((dkendpowrot - dkstartpowrot) % msDay) / msHours
    );
    const mmpowrot = Math.floor(
      (((dkendpowrot - dkstartpowrot) % msDay) % msHours) / msMinute
    );
    const dkczas =
      ddwyjazd +
      ddpowrot +
      " dni, " +
      (hhwyjazd + hhpowrot) +
      " godzin, " +
      (mmwyjazd + mmpowrot) +
      " minut";
    this.lacznyczasdelegacjikrajowej.push(dkczas);
    //
    pobierzWlasciwyTimestamp.czysc();
  },
  zapiszMacierzTrippk: function (element) {
    return this.trippk;
  },
  zapiszMacierzPracownik: function (element) {
    return this.pracownik;
  },
  zapiszMacierzNumerDelegacji: function (element) {
    return this.numerdelegacji;
  },
  zapiszMacierzDataRozpoczecia: function (element) {
    return this.datarozpoczecia;
  },
  zapiszMacierzDataZakonczenia: function (element) {
    return this.datazakonczenia;
  },
  zapiszMacierzKraj: function (element) {
    return this.kraj;
  },
  zapiszMacierzProjekt: function (element) {
    return this.projekt;
  },
  zapiszMacierzMiejsceDelegacji: function (element) {
    return this.miejscedelegacji;
  },
  zapiszMacierzSrodekTransportu: function (element) {
    return this.srodektransportu;
  },
  zapiszMacierzStatusDelegacji: function (element) {
    return this.statusdelegacji;
  },
  zapiszMacierzCzasRozpoczeciaDelegacji: function (element) {
    return this.czasrozpoczeciadelegacji;
  },
  zapiszMacierzCzasRozpoczeciaDelegacjiM: function (element) {
    return this.rpkom;
  },
  zapiszMacierzCzasWyjazduZPolski: function (element) {
    return this.czaswyjazduzpolski;
  },
  zapiszMacierzCzasWyjazduZPolskiM: function (element) {
    return this.wpkom;
  },
  zapiszMacierzCzasPowrotuDoPolski: function (element) {
    return this.czaspowrotudopolski;
  },
  zapiszMacierzCzasPowrotuDoPolskiM: function (element) {
    return this.ppkom;
  },
  zapiszMacierzCzasZakonczeniaDelegacji: function (element) {
    return this.czaszakonczeniadelegacji;
  },
  zapiszMacierzCzasZakonczeniaDelegacjiM: function (element) {
    return this.zpkom;
  },
  zapiszMacierzLacznyCzasDelegacjiZagranicznej: function (element) {
    return this.lacznyczasdelegacjizagranicznej;
  },
  zapiszMacierzLacznyCzasDelegacjiKrajowej: function (element) {
    return this.lacznyczasdelegacjikrajowej;
  },
  przygotujWiersze: function (element) {
    for (var i = 0; i < this.pracownik.length; i++) {
      const element = {};
      element["pracownik"] = this.pracownik[i];
      element["numerdelegacji"] = this.numerdelegacji[i];
      element["datarozpoczecia"] = this.datarozpoczecia[i];
      element["datazakonczenia"] = this.datazakonczenia[i];
      element["kraj"] = this.kraj[i];
      element["projekt"] = this.projekt[i];
      element["miejscedelegacji"] = this.miejscedelegacji[i];
      element["srodektransportu"] = this.srodektransportu[i];
      element["statusdelegacji"] = this.statusdelegacji[i];
      element["czasrozpoczeciadelegacji"] = this.czasrozpoczeciadelegacji[i];
      element["rpkom"] = this.rpkom[i];
      element["czaswyjazduzpolski"] = this.czaswyjazduzpolski[i];
      element["wpkom"] = this.wpkom[i];
      element["czaspowrotudopolski"] = this.czaspowrotudopolski[i];
      element["ppkom"] = this.ppkom[i];
      element["czaszakonczeniadelegacji"] = this.czaszakonczeniadelegacji[i];
      element["zpkom"] = this.zpkom[i];
      element["lacznyczasdelegacjikrajowej"] = this.lacznyczasdelegacjikrajowej[i];
      element["lacznyczasdelegacjizagranicznej"] = this.lacznyczasdelegacjizagranicznej[i];
      element["trippk"] = this.trippk[i];
      this.rows.push(element);
    }
    return this.rows;
  },
  inicjalizacja: function (element) {
    this.trippk = [];
    this.pracownik = [];
    this.numerdelegacji = [];
    this.datarozpoczecia = [];
    this.datazakonczenia = [];
    this.kraj = [];
    this.projekt = [];
    this.miejscedelegacji = [];
    this.srodektransportu = [];
    this.statusdelegacji = [];
    this.rpkom = [];
    this.wpkom = [];
    this.ppkom = [];
    this.zpkom = [];
    this.czasrozpoczeciadelegacji = [];
    this.rpkom = [];
    this.wpkom = [];
    this.ppkom = [];
    this.zpkom = [];
    this.pi = [];
    this.czaswyjazduzpolski = [];
    this.czaspowrotudopolski = [];
    this.czaszakonczeniadelegacji = [];
    this.lacznyczasdelegacjikrajowej = [];
    this.lacznyczasdelegacjizagranicznej = [];
    this.rows = [];
  }
};

function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
  return order === "desc"
    ? (a, b) => desc(a, b, orderBy)
    : (a, b) => -desc(a, b, orderBy);
}

const headCells = [
  { id: "pracownik", label: "Pracownik", minWidth: 150 },
  { id: "numerdelegacji", label: "Numer delegacji", minWidth: 100 },
  {
    id: "datarozpoczecia",
    label: "Data rozpoczecia",
    minWidth: 120,
    format: value => value.toLocaleString()
  },
  {
    id: "datazakonczenia",
    label: "Data zakonczenia",
    minWidth: 120,
    format: value => value.toLocaleString()
  },
  {
    id: "kraj",
    label: "Kraj",
    minWidth: 100,
    format: value => value.toFixed(2)
  },
  {
    id: "projekt",
    label: "Projekt",
    minWidth: 100,
    format: value => value.toFixed(2)
  },
  {
    id: "miejscedelegacji",
    label: "Miejsce delegacji",
    minWidth: 150,
    format: value => value.toFixed(2)
  },
  {
    id: "srodektransportu",
    label: "Środek transportu",
    minWidth: 150,
    format: value => value.toFixed(2)
  },
  {
    id: "statusdelegacji",
    label: "Status delegacji",
    minWidth: 150,
    format: value => value.toFixed(2)
  },
  {
    id: "czasrozpoczeciadelegacji",
    label: "Czas rozpoczecia delegacji",
    minWidth: 200,
    format: value => value.toFixed(2)
  },
  {
    id: "czaswyjazduzpolski",
    label: "Czas wyjazdu z Polski",
    minWidth: 180,
    format: value => value.toFixed(2)
  },
  {
    id: "czaspowrotudopolski",
    label: "Czas powrotu do Polski",
    minWidth: 180,
    format: value => value.toFixed(2)
  },
  {
    id: "czaszakonczeniadelegacji",
    label: "Czas zakonczenia delegacji",
    minWidth: 200,
    format: value => value.toFixed(2)
  },
  {
    id: "lacznyczasdelegacjikrajowej",
    label: "Łączny czas delegacji krajowej",
    minWidth: 180,
    format: value => value.toFixed(2)
  },
  {
    id: "lacznyczasdelegacjizagranicznej",
    label: "Łączny czas delegacji zagranicznej",
    minWidth: 180,
    format: value => value.toFixed(2)
  }
];

function EnhancedTableHead(props) {
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox"></TableCell>
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "default"}
            sortDirection={orderBy === headCell.id ? order : false}
            style={{ minWidth: headCell.minWidth }}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired
};

export class AccountingTable extends Component {
  state = {
    dialogSMS: false,
    smsmessage: null,
    order: "asc",
    orderBy: "pracownik",
    selected: [],
    page: 0,
    rowsPerPage: 5,
    trippk: [],
    pracownik: [],
    numerdelegacji: [],
    datarozpoczecia: [],
    datazakonczenia: [],
    kraj: [],
    projekt: [],
    miejscedelegacji: [],
    srodektransportu: [],
    statusdelegacji: [],
    rpkom: [],
    wpkom: [],
    ppkom: [],
    zpkom: [],
    czasrozpoczeciadelegacji: [],
    rpkom: [],
    wpkom: [],
    ppkom: [],
    zpkom: [],
    pi: [],
    czaswyjazduzpolski: [],
    czaspowrotudopolski: [],
    czaszakonczeniadelegacji: [],
    lacznyczasdelegacjikrajowej: [],
    lacznyczasdelegacjizagranicznej: [],
    rows: []
  };

  static propTypes = {
    employeeTripsRZ: PropTypes.array.isRequired,
    getEmployeeTripsFiltered: PropTypes.func.isRequired,
    patchAccountingTrip: PropTypes.func.isRequired,
    employeeTripDetails: PropTypes.object.isRequired,
    getEmployeeTripDetails: PropTypes.func.isRequired,
    sendNotification: PropTypes.func.isRequired,
    sendNewNotification: PropTypes.func.isRequired,
    bulkQueryInProgressRZ: PropTypes.bool.isRequired,
    auth: PropTypes.object.isRequired
  };

  componentDidMount() {
    if (this.props.employeeTripsRZ.length === 0) {
      this.props.getEmployeeTripsFiltered("tripStatus", "RZ");
    } else {
      daneDoTabeli.inicjalizacja();
      this.props.employeeTripsRZ.forEach(daneDoTabeli.dodaj, daneDoTabeli);
      this.setState({
        trippk: daneDoTabeli.zapiszMacierzTrippk(),
        pracownik: daneDoTabeli.zapiszMacierzPracownik(),
        numerdelegacji: daneDoTabeli.zapiszMacierzNumerDelegacji(),
        datarozpoczecia: daneDoTabeli.zapiszMacierzDataRozpoczecia(),
        datazakonczenia: daneDoTabeli.zapiszMacierzDataZakonczenia(),
        kraj: daneDoTabeli.zapiszMacierzKraj(),
        projekt: daneDoTabeli.zapiszMacierzProjekt(),
        miejscedelegacji: daneDoTabeli.zapiszMacierzMiejsceDelegacji(),
        srodektransportu: daneDoTabeli.zapiszMacierzSrodekTransportu(),
        statusdelegacji: daneDoTabeli.zapiszMacierzStatusDelegacji(),
        czasrozpoczeciadelegacji: daneDoTabeli.zapiszMacierzCzasRozpoczeciaDelegacji(),
        rpkom: daneDoTabeli.zapiszMacierzCzasRozpoczeciaDelegacjiM(),
        czaswyjazduzpolski: daneDoTabeli.zapiszMacierzCzasWyjazduZPolski(),
        wp: daneDoTabeli.zapiszMacierzCzasWyjazduZPolskiM(),
        czaspowrotudopolski: daneDoTabeli.zapiszMacierzCzasPowrotuDoPolski(),
        ppkom: daneDoTabeli.zapiszMacierzCzasPowrotuDoPolskiM(),
        czaszakonczeniadelegacji: daneDoTabeli.zapiszMacierzCzasZakonczeniaDelegacji(),
        zpkom: daneDoTabeli.zapiszMacierzCzasZakonczeniaDelegacjiM(),
        lacznyczasdelegacjizagranicznej: daneDoTabeli.zapiszMacierzLacznyCzasDelegacjiZagranicznej(),
        lacznyczasdelegacjikrajowej: daneDoTabeli.zapiszMacierzLacznyCzasDelegacjiKrajowej(),
        rows: daneDoTabeli.przygotujWiersze()
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.employeeTripsRZ.length !== this.props.employeeTripsRZ.length
    ) {
      daneDoTabeli.inicjalizacja();
      this.props.employeeTripsRZ.forEach(daneDoTabeli.dodaj, daneDoTabeli);
      this.setState({
        trippk: daneDoTabeli.zapiszMacierzTrippk(),
        pracownik: daneDoTabeli.zapiszMacierzPracownik(),
        numerdelegacji: daneDoTabeli.zapiszMacierzNumerDelegacji(),
        datarozpoczecia: daneDoTabeli.zapiszMacierzDataRozpoczecia(),
        datazakonczenia: daneDoTabeli.zapiszMacierzDataZakonczenia(),
        kraj: daneDoTabeli.zapiszMacierzKraj(),
        projekt: daneDoTabeli.zapiszMacierzProjekt(),
        miejscedelegacji: daneDoTabeli.zapiszMacierzMiejsceDelegacji(),
        srodektransportu: daneDoTabeli.zapiszMacierzSrodekTransportu(),
        statusdelegacji: daneDoTabeli.zapiszMacierzStatusDelegacji(),
        czasrozpoczeciadelegacji: daneDoTabeli.zapiszMacierzCzasRozpoczeciaDelegacji(),
        rpkom: daneDoTabeli.zapiszMacierzCzasRozpoczeciaDelegacjiM(),
        czaswyjazduzpolski: daneDoTabeli.zapiszMacierzCzasWyjazduZPolski(),
        wp: daneDoTabeli.zapiszMacierzCzasWyjazduZPolskiM(),
        czaspowrotudopolski: daneDoTabeli.zapiszMacierzCzasPowrotuDoPolski(),
        ppkom: daneDoTabeli.zapiszMacierzCzasPowrotuDoPolskiM(),
        czaszakonczeniadelegacji: daneDoTabeli.zapiszMacierzCzasZakonczeniaDelegacji(),
        zpkom: daneDoTabeli.zapiszMacierzCzasZakonczeniaDelegacjiM(),
        lacznyczasdelegacjizagranicznej: daneDoTabeli.zapiszMacierzLacznyCzasDelegacjiZagranicznej(),
        lacznyczasdelegacjikrajowej: daneDoTabeli.zapiszMacierzLacznyCzasDelegacjiKrajowej(),
        rows: daneDoTabeli.przygotujWiersze()
      });
    }
    if (this.props.auth.main_profile.defaultCompany != prevProps.auth.main_profile.defaultCompany) {
      this.props.getEmployeeTripsFiltered("tripStatus", "RZ");
      daneDoTabeli.inicjalizacja();
      this.props.employeeTripsRZ.forEach(daneDoTabeli.dodaj, daneDoTabeli);
      this.setState({
        trippk: daneDoTabeli.zapiszMacierzTrippk(),
        pracownik: daneDoTabeli.zapiszMacierzPracownik(),
        numerdelegacji: daneDoTabeli.zapiszMacierzNumerDelegacji(),
        datarozpoczecia: daneDoTabeli.zapiszMacierzDataRozpoczecia(),
        datazakonczenia: daneDoTabeli.zapiszMacierzDataZakonczenia(),
        kraj: daneDoTabeli.zapiszMacierzKraj(),
        projekt: daneDoTabeli.zapiszMacierzProjekt(),
        miejscedelegacji: daneDoTabeli.zapiszMacierzMiejsceDelegacji(),
        srodektransportu: daneDoTabeli.zapiszMacierzSrodekTransportu(),
        statusdelegacji: daneDoTabeli.zapiszMacierzStatusDelegacji(),
        czasrozpoczeciadelegacji: daneDoTabeli.zapiszMacierzCzasRozpoczeciaDelegacji(),
        rpkom: daneDoTabeli.zapiszMacierzCzasRozpoczeciaDelegacjiM(),
        czaswyjazduzpolski: daneDoTabeli.zapiszMacierzCzasWyjazduZPolski(),
        wp: daneDoTabeli.zapiszMacierzCzasWyjazduZPolskiM(),
        czaspowrotudopolski: daneDoTabeli.zapiszMacierzCzasPowrotuDoPolski(),
        ppkom: daneDoTabeli.zapiszMacierzCzasPowrotuDoPolskiM(),
        czaszakonczeniadelegacji: daneDoTabeli.zapiszMacierzCzasZakonczeniaDelegacji(),
        zpkom: daneDoTabeli.zapiszMacierzCzasZakonczeniaDelegacjiM(),
        lacznyczasdelegacjizagranicznej: daneDoTabeli.zapiszMacierzLacznyCzasDelegacjiZagranicznej(),
        lacznyczasdelegacjikrajowej: daneDoTabeli.zapiszMacierzLacznyCzasDelegacjiKrajowej(),
        rows: daneDoTabeli.przygotujWiersze()
      });
    }
  }

  handleRequestSort = (event, property) => {
    const isAsc = this.state.orderBy === property && this.state.order === "asc";
    this.setState({
      order: isAsc ? "desc" : "asc"
    });
    this.setState({
      orderBy: property
    });
  };

  handleTextList = name => ({ target: { value } }) => {
    this.setState({
      ...this.state,
      [name]: value
    });
  };

  handleSelectAllClick = event => {
    const rows = this.state.rows;
    if (event.target.checked) {
      const newSelecteds = rows.map(n => n.numerdelegacji);
      this.setState({
        selected: newSelecteds
      });
      return;
    }
    this.setState({
      selected: []
    });
  };

  handleClick = (trippk, event) => {
    //wykryj czy zaznaczyłem czy odznaczyłem
    //tutaj trzeba wykryc kliknięcie na juz zaznaczony wiersz
    const selected = this.state.selected[0];
    if (selected === undefined) {
      let newSelected = [];
      newSelected.push(trippk);
      this.setState({
        selected: newSelected
      });
      this.props.checked(true);
    } else if (selected !== trippk) {
      this.setState({
        selected: []
      });
      let newSelected = [];
      newSelected.push(trippk);
      this.setState({
        selected: newSelected
      });
      this.props.checked(true);
    } else {
      this.setState({
        selected: []
      });
      this.props.checked(false);
    }
  };

  handleChangePage = (event, newPage) => {
    this.setState({
      page: newPage
    });
  };

  handleChangeRowsPerPage = event => {
    this.setState({
      rowsPerPage: parseInt(event.target.value, 10)
    });

    this.setState({
      page: 0
    });
  };

  handleDoneClick = () => {
    const tripId = this.state.selected[0];
    const message = "delegacja została zamknięta";
    //przygotowanie body
    const tripBody = {
      tripStatus: "ZM"
    };
    this.props.patchAccountingTrip(tripBody, tripId, message);

    //znajdz index zaznaczonej delegacji w macierzy this.props.employeeTripsRZ
    const index = this.props.employeeTripsRZ.findIndex(x => x.pk === tripId);
    /*
    const body = {
      recipient: this.props.employeeTripsRZ[index].employee.phone,
      sender: "DELEGACJE",
      message: "Delegacja o numerze " +
        this.props.employeeTripsRZ[index].tripIdentifier +
        " zostala ZAMKNIETA.",
      owner: this.props.auth.user.id,
      notificationType: "sms",
      isDelivered: true
    };
    */

    const notifyBody = {
      recipient: this.props.employeeTripsRZ[index].employee.email,
      module: "Delegacje",
      topic: "Delegacja zamknięta",
      message:
        "Delegacja o numerze " + this.props.employeeTripsRZ[index].tripIdentifier + " została zamknięta."
    }

    //this.props.sendNotification(body);
    this.props.sendNewNotification(notifyBody);
  };

  handlePreviewClick = () => {
    //wykonaj geta o tripDetails
    this.props.getEmployeeTripDetails(this.state.selected[0]);
    this.props.tripIdentifierpk(this.state.selected[0]);
  };

  handleCloseDialogSMS = () => {
    this.setState({
      dialogSMS: false
    });
  };

  handleOpenDialogSMS = () => {
    this.setState({
      dialogSMS: true
    });
  };

  handleSMSClick = () => {
    const rows = this.state.rows;
    const trippk = this.state.selected[0];
    const index = rows.findIndex(x => x.trippk === trippk);
    /*
    const recipient = this.props.employeeTripsRZ[index].employee.phone;
    const sender = "DELEGACJE";
    const message = this.state.smsmessage;
    const owner = this.props.employeeTripsRZ[index].owner;
    const notificationType = "sms";
    const isDelivered = true;

    //przygotowanie body
    const body = {
      recipient,
      sender,
      message,
      owner,
      notificationType,
      isDelivered
    };
    */

    const notifyBody = {
      recipient: this.props.employeeTripsRZ[index].employee.email,
      module: "Delegacje",
      topic: "Delegacja " + this.props.employeeTripsRZ[index].tripIdentifier,
      message: this.state.smsmessage,
    }

    //this.props.sendNotification(body);
    this.props.sendNewNotification(notifyBody);
    this.setState({
      dialogSMS: false
    });
  };

  handleDownload = () => {
    var csvRow = [];
    var A = [
      [
        "Pracownik",
        "Numer delegacji",
        "Data rozpoczecia",
        "Data zakonczenia",
        "Kraj",
        "Projekt",
        "Miejsce delegacji",
        "Środek Transportu",
        "Status delegacji",
        "Czas rozpoczecia delegacji",
        "Miejsce rozpoczecia delegacji",
        "Czas wyjazdu z Polski",
        "Miejsce wyjazdu z Polski",
        "Czas powrotu do Polski",
        "Miejsce powrotu do Polski",
        "Czas zakonczenia delegacji",
        "Miejsce zakonczenia delegacji",
        "Łączny czas delegacji krajowej",
        "Łączny czas delegacji zagranicznej",
      ]
    ];
    var re = this.state.rows;

    for (var item = 0; item < re.length; item++) {
      A.push([
        re[item].pracownik,
        re[item].numerdelegacji,
        re[item].datarozpoczecia,
        re[item].datazakonczenia,
        re[item].kraj,
        re[item].projekt,
        re[item].miejscedelegacji,
        re[item].srodektransportu,
        re[item].statusdelegacji,
        re[item].czasrozpoczeciadelegacji,
        re[item].rpkom,
        re[item].czaswyjazduzpolski,
        re[item].wpkom,
        re[item].czaspowrotudopolski,
        re[item].ppkom,
        re[item].czaszakonczeniadelegacji,
        re[item].zpkom,
        re[item].lacznyczasdelegacjikrajowej,
        re[item].lacznyczasdelegacjizagranicznej,
      ]);
    }

    for (var i = 0; i < A.length; ++i) {
      csvRow.push(A[i].join(";"));
    }

    var csvString = csvRow.join("\n");
    //var a = document.createElement("a");
    var link = window.document.createElement("a");
    link.setAttribute(
      "href",
      "data:text/csv;charset=utf-8,%EF%BB%BF" + encodeURI(csvString)
    );
    link.setAttribute("download", "RaportDelegacji.csv");
    link.click();
  };

  render() {
    const {
      order,
      orderBy,
      selected,
      page,
      rowsPerPage,
      rows,
      smsmessage,
      dialogSMS
    } = this.state;
    const { classes, bulkQueryInProgressRZ } = this.props;

    const circle = <CircularProgress color="secondary" />;

    const isSelected = trippk => selected.indexOf(trippk) !== -1;
    const emptyRows =
      rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

    const enhancedTableToolbar = (
      <ThemeProvider theme={materialTheme}>
        <Toolbar
          className={clsx(classes.roottb, {
            [classes.highlighttb]: selected.length > 0
          })}
        >
          {selected.length > 0 ? (
            <Typography
              className={classes.titletb}
              color="inherit"
              variant="subtitle1"
            >
              co chciałbyś zrobic ?
            </Typography>
          ) : (
            <Fragment>
              <Typography
                className={classes.titletb}
                variant="h6"
                id="tableTitle"
              >
                Delegacje do rozliczenia
              </Typography>
            </Fragment>
          )}

          {selected.length > 0 ? (
            <Fragment>
              <Tooltip title="Zamknij">
                <IconButton aria-label="done" onClick={this.handleDoneClick}>
                  <DoneIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Podgląd">
                <IconButton
                  aria-label="preview"
                  onClick={this.handlePreviewClick}
                >
                  <VisibilityIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Wyślij SMS">
                <IconButton
                  aria-label="done"
                  onClick={this.handleOpenDialogSMS}
                >
                  <SmsIcon />
                </IconButton>
              </Tooltip>
            </Fragment>
          ) : (
            <Fragment>
              {bulkQueryInProgressRZ ? circle : ""}
              <Tooltip title="Eksport do pliku">
                <IconButton
                  aria-label="filter list"
                  onClick={this.handleDownload}
                >
                  <GetAppIcon />
                </IconButton>
              </Tooltip>
            </Fragment>
          )}
        </Toolbar>
      </ThemeProvider>
    );

    return (
      <Fragment>
        <div className={classes.root}>
          <Paper className={classes.paper}>
            {enhancedTableToolbar}
            <TableContainer>
              <Table
                className={classes.table}
                aria-labelledby="tableTitle"
                size={"medium"}
                aria-label="enhanced table"
              >
                <EnhancedTableHead
                  classes={classes}
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={this.handleSelectAllClick}
                  onRequestSort={this.handleRequestSort}
                  rowCount={rows.length}
                />
                <TableBody>
                  {stableSort(rows, getSorting(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      const isItemSelected = isSelected(row.trippk);
                      const labelId = `enhanced-table-checkbox-${index}`;

                      return (
                        <ThemeProvider theme={materialTheme}>
                          <TableRow
                            hover
                            onClick={this.handleClick.bind(this, row.trippk)}
                            role="checkbox"
                            aria-checked={isItemSelected}
                            tabIndex={-1}
                            key={row.trippk}
                            selected={isItemSelected}
                          >
                            <TableCell padding="checkbox">
                              <ThemeProvider theme={materialTheme}>
                                <Checkbox
                                  checked={isItemSelected}
                                  inputProps={{ "aria-labelledby": labelId }}
                                  checkedIcon={
                                    <Check className={classes.checkedIcon} />
                                  }
                                  icon={
                                    <Check className={classes.uncheckedIcon} />
                                  }
                                  classes={{
                                    checked: classes.checked,
                                    root: classes.checkRoot
                                  }}
                                />
                              </ThemeProvider>
                            </TableCell>
                            <TableCell
                              component="th"
                              id={labelId}
                              scope="row"
                              padding="none"
                            >
                              {row.pracownik}
                            </TableCell>
                            <TableCell>
                              <Badge color="gray">{row.numerdelegacji}</Badge>
                            </TableCell>
                            <TableCell>{row.datarozpoczecia}</TableCell>
                            <TableCell>{row.datazakonczenia}</TableCell>
                            <TableCell>{row.kraj}</TableCell>
                            <TableCell>{row.projekt}</TableCell>
                            <TableCell>{row.miejscedelegacji}</TableCell>
                            <TableCell>{row.srodektransportu}</TableCell>
                            <TableCell>
                              {(() => {
                                if (
                                  row.statusdelegacji ===
                                  "gotowa do rozliczenia!"
                                ) {
                                  return (
                                    <Badge color="success">
                                      {row.statusdelegacji}
                                    </Badge>
                                  );
                                } else {
                                  return (
                                    <Badge color="danger">
                                      {row.statusdelegacji}
                                    </Badge>
                                  );
                                }
                              })()}
                            </TableCell>
                            <TableCell>{row.czasrozpoczeciadelegacji}</TableCell>
                            <TableCell>{row.czaswyjazduzpolski}</TableCell>
                            <TableCell>{row.czaspowrotudopolski}</TableCell>
                            <TableCell>{row.czaszakonczeniadelegacji}</TableCell>
                            <TableCell>{row.lacznyczasdelegacjikrajowej}</TableCell>
                            <TableCell>{row.lacznyczasdelegacjizagranicznej}</TableCell>
                          </TableRow>
                        </ThemeProvider>
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={this.handleChangePage}
              onChangeRowsPerPage={this.handleChangeRowsPerPage}
            />
          </Paper>
          <div style={{ marginBottom: 70 }}></div>
        </div>

        <Dialog
          open={dialogSMS}
          onClose={this.handleCloseDialogSMS}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <CssTextField
              required
              label="treść SMSa"
              multiline
              rows="4"
              inputProps={{ maxLength: 150 }}
              margin="normal"
              value={smsmessage || ""}
              onChange={this.handleTextList("smsmessage")}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleCloseDialogSMS} color="primary">
              Anuluj
            </Button>
            <Button onClick={this.handleSMSClick} color="primary" autoFocus>
              Wyślij SMS
            </Button>
          </DialogActions>
        </Dialog>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  employeeTripsRZ: state.tripsManagerReducer.employeeTripsRZ,
  employeeTripDetails: state.tripsManagerReducer.employeeTripDetails,
  bulkQueryInProgressRZ: state.tripsManagerReducer.bulkQueryInProgressRZ,
  auth: state.auth
});

export default connect(
  mapStateToProps,
  {
    getEmployeeTripsFiltered,
    patchAccountingTrip,
    getEmployeeTripDetails,
    sendNotification,
    sendNewNotification
  }
)(withStyles(styles, { withTheme: true })(AccountingTable));
