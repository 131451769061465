import axios from "axios";
import { createMessages, returnErrors } from "./messages";
import { serverurl, getDatabaseUrl } from "./databaseServerURL";
import { tokenConfig, tokenConfigForm } from "./auth";

import {
  GET_TRANSACTIONS_EUR,
  GET_TRANSACTIONS_PLN,
  GET_TRANSACTIONS_SEK,
  GET_TRANSACTIONS_NOK,
  UPDATE_TRANSACTIONS_EUR,
  UPDATE_TRANSACTIONS_PLN,
  UPDATE_TRANSACTIONS_SEK,
  UPDATE_TRANSACTIONS_NOK,
  RELOAD_TRANSACTIONS,
  CLEAR_TRANSACTIONS,
  QUERY_IN_PROGRESS_TR_EUR,
  QUERY_IN_PROGRESS_TR_PLN,
  QUERY_IN_PROGRESS_TR_SEK,
  QUERY_IN_PROGRESS_TR_NOK,
  GET_TRANSACTION_DETAILS,
  QUERY_IN_PROGRESS_TR_DETAILS,
  PATCH_TRANSACTION_DETAILS,
  ADD_TRANSACTION_WI,
  ADD_TRANSACTION_CP,
  ADD_TRANSACTION_CH,
  ADD_TRANSACTION_PB,
  ADD_TRANSACTION_DT,
  ADD_TRANSACTION_RT,
  ADD_TRANSACTION_OC,
  GET_TRANSACTIONS_AK,
  GET_TRANSACTIONS_RT,
  GET_TRANSACTIONS_PB,
  GET_TRANSACTIONS_DT,
  RS_TRANSACTIONS_AK,
  RS_TRANSACTIONS_RT,
  RS_TRANSACTIONS_PB,
  RS_TRANSACTIONS_DT,
  QUERY_IN_PROGRESS_AK,
  QUERY_IN_PROGRESS_RT,
  QUERY_IN_PROGRESS_PB,
  QUERY_IN_PROGRESS_DT,
  GET_CARDS,
  QUERY_IN_PROGRESS_CARDS,
  GET_CARDS_DETAILS,
  QUERY_IN_PROGRESS_CARDSDETAILS,
  ADD_BUSINESSCARDFILE,
  LOAD_IN_PROGRESS,
  DATA_CHANGE_IN_PROGRESS,
  //stat messages
  GET_TRANSACTIONS_STAT_AK,
  GET_TRANSACTIONS_STAT_AR,
  GET_TRANSACTIONS_STAT_NOIN,
  QUERY_IN_PROGRESS_STAT_AK,
  QUERY_IN_PROGRESS_STAT_AR,
  QUERY_IN_PROGRESS_STAT_NOIN,
} from "./types";

export const addBusinessCardFile = (body) => (dispatch, getState) => {
  dispatch({ type: LOAD_IN_PROGRESS });
  axios
    .post(serverurl + getDatabaseUrl() + `/businesscardsfile/`, body, tokenConfigForm(getState))
    .then((res) => {
      dispatch({
        type: ADD_BUSINESSCARDFILE,
        payload: res.data,
      });
      dispatch(
        createMessages({
          addBusinessCardFile: "Plik został przesłany",
          type: "success",
        })
      );
    })
    .catch((err) =>
      dispatch(returnErrors(err.response.data.detail, err.response.status))
    );
};

export const getCardsDetails = (pk) => (dispatch, getState) => {
  dispatch({ type: QUERY_IN_PROGRESS_CARDSDETAILS });
  axios
    .get(serverurl + getDatabaseUrl() + `/businesscards-full/${pk}`, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: GET_CARDS_DETAILS,
        payload: res.data,
      });
    })
    .catch((err) =>
      dispatch(returnErrors(err.response.data.detail, err.response.status))
    );
};

export const getCards = () => (dispatch, getState) => {
  dispatch({ type: QUERY_IN_PROGRESS_CARDS });
  axios
    .get(serverurl + getDatabaseUrl() + `/businesscards-full/`, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: GET_CARDS,
        payload: res.data,
      });
    })
    .catch((err) =>
      dispatch(returnErrors(err.response.data.detail, err.response.status))
    );
};

export const getTransactionsStat = (statname, toDate) => (
  dispatch,
  getState
) => {
  if (statname === "ak") {
    dispatch({ type: QUERY_IN_PROGRESS_STAT_AK });
    axios
      .get(
        serverurl + getDatabaseUrl() + `/transactions-stat/?transactionStatus=ak&toDate=${toDate}`,
        tokenConfig(getState)
      )
      .then((res) => {
        dispatch({
          type: GET_TRANSACTIONS_STAT_AK,
          payload: res.data,
        });
      })
      .catch((err) =>
        dispatch(returnErrors(err.response.data.detail, err.response.status))
      );
  } else if (statname === "ar") {
    dispatch({ type: QUERY_IN_PROGRESS_STAT_AR });
    axios
      .get(
        serverurl + getDatabaseUrl() + `/transactions-stat/?transactionStatus=ar&toDate=${toDate}`,
        tokenConfig(getState)
      )
      .then((res) => {
        dispatch({
          type: GET_TRANSACTIONS_STAT_AR,
          payload: res.data,
        });
      })
      .catch((err) =>
        dispatch(returnErrors(err.response.data.detail, err.response.status))
      );
  } else if (statname === "noin") {
    dispatch({ type: QUERY_IN_PROGRESS_STAT_NOIN });
    axios
      .get(
        serverurl + getDatabaseUrl() + `/transactions-stat/?noInvoice=True&toDate=${toDate}`,
        tokenConfig(getState)
      )
      .then((res) => {
        dispatch({
          type: GET_TRANSACTIONS_STAT_NOIN,
          payload: res.data,
        });
      })
      .catch((err) =>
        dispatch(returnErrors(err.response.data.detail, err.response.status))
      );
  }
};

export const getTransactionsByStatus = (transactionfilter) => (
  dispatch,
  getState
) => {
  if (transactionfilter === "costsaccept") {
    dispatch({ type: QUERY_IN_PROGRESS_AK });
    axios
      .get(
        serverurl + getDatabaseUrl() + `/transactions/?transactionfilter=${transactionfilter}`,
        tokenConfig(getState)
      )
      .then((res) => {
        dispatch({
          type: GET_TRANSACTIONS_AK,
          payload: res.data,
        });
      })
      .catch((err) =>
        dispatch(returnErrors(err.response.data.detail, err.response.status))
      );
  } else if (transactionfilter === "payback") {
    dispatch({ type: QUERY_IN_PROGRESS_PB });
    axios
      .get(
        serverurl + getDatabaseUrl() + `/transactions/?transactionfilter=${transactionfilter}`,
        tokenConfig(getState)
      )
      .then((res) => {
        dispatch({
          type: GET_TRANSACTIONS_PB,
          payload: res.data,
        });
      })
      .catch((err) =>
        dispatch(returnErrors(err.response.data.detail, err.response.status))
      );
  } else if (transactionfilter === "return") {
    dispatch({ type: QUERY_IN_PROGRESS_RT });
    axios
      .get(
        serverurl + getDatabaseUrl() + `/transactions/?transactionfilter=${transactionfilter}`,
        tokenConfig(getState)
      )
      .then((res) => {
        dispatch({
          type: GET_TRANSACTIONS_RT,
          payload: res.data,
        });
      })
      .catch((err) =>
        dispatch(returnErrors(err.response.data.detail, err.response.status))
      );
  } else if (transactionfilter === "deduce") {
    dispatch({ type: QUERY_IN_PROGRESS_DT });
    axios
      .get(
        serverurl + getDatabaseUrl() + `/transactions/?transactionfilter=${transactionfilter}`,
        tokenConfig(getState)
      )
      .then((res) => {
        dispatch({
          type: GET_TRANSACTIONS_DT,
          payload: res.data,
        });
      })
      .catch((err) =>
        dispatch(returnErrors(err.response.data.detail, err.response.status))
      );
  }
};

export const getTransactionsByYear = (owner, currency, year) => (
  dispatch,
  getState
) => {
  if (currency === "eur") {
    dispatch({ type: QUERY_IN_PROGRESS_TR_EUR });
    axios
      .get(
        serverurl + getDatabaseUrl() + `/transactions/?owner=${owner}&currency=${currency}&year=${year}`,
        tokenConfig(getState)
      )
      .then((res) => {
        dispatch({
          type: GET_TRANSACTIONS_EUR,
          payload: res.data,
        });
      })
      .catch((err) =>
        dispatch(returnErrors(err.response.data.detail, err.response.status))
      );
  } else if (currency === "pln") {
    dispatch({ type: QUERY_IN_PROGRESS_TR_PLN });
    axios
      .get(
        serverurl + getDatabaseUrl() + `/transactions/?owner=${owner}&currency=${currency}&year=${year}`,
        tokenConfig(getState)
      )
      .then((res) => {
        dispatch({
          type: GET_TRANSACTIONS_PLN,
          payload: res.data,
        });
      })
      .catch((err) =>
        dispatch(returnErrors(err.response.data.detail, err.response.status))
      );
  } else if (currency === "sek") {
    dispatch({ type: QUERY_IN_PROGRESS_TR_SEK });
    axios
      .get(
        serverurl + getDatabaseUrl() + `/transactions/?owner=${owner}&currency=${currency}&year=${year}`,
        tokenConfig(getState)
      )
      .then((res) => {
        dispatch({
          type: GET_TRANSACTIONS_SEK,
          payload: res.data,
        });
      })
      .catch((err) =>
        dispatch(returnErrors(err.response.data.detail, err.response.status))
      );
  } else if (currency === "nok") {
    dispatch({ type: QUERY_IN_PROGRESS_TR_NOK });
    axios
      .get(
        serverurl + getDatabaseUrl() + `/transactions/?owner=${owner}&currency=${currency}&year=${year}`,
        tokenConfig(getState)
      )
      .then((res) => {
        dispatch({
          type: GET_TRANSACTIONS_NOK,
          payload: res.data,
        });
      })
      .catch((err) =>
        dispatch(returnErrors(err.response.data.detail, err.response.status))
      );
  }
};

export const getTransactions = (owner, currency, thisYear) => (
  dispatch,
  getState
) => {
  if (currency === "eur") {
    dispatch({ type: QUERY_IN_PROGRESS_TR_EUR });
    axios
      .get(
        serverurl + getDatabaseUrl() + `/transactions/?owner=${owner}&currency=${currency}&thisYear=${thisYear}`,
        tokenConfig(getState)
      )
      .then((res) => {
        dispatch({
          type: GET_TRANSACTIONS_EUR,
          payload: res.data,
        });
      })
      .catch((err) =>
        dispatch(returnErrors(err.response.data.detail, err.response.status))
      );
  } else if (currency === "pln") {
    dispatch({ type: QUERY_IN_PROGRESS_TR_PLN });
    axios
      .get(
        serverurl + getDatabaseUrl() + `/transactions/?owner=${owner}&currency=${currency}&thisYear=${thisYear}`,
        tokenConfig(getState)
      )
      .then((res) => {
        dispatch({
          type: GET_TRANSACTIONS_PLN,
          payload: res.data,
        });
      })
      .catch((err) =>
        dispatch(returnErrors(err.response.data.detail, err.response.status))
      );
  } else if (currency === "sek") {
    dispatch({ type: QUERY_IN_PROGRESS_TR_SEK });
    axios
      .get(
        serverurl + getDatabaseUrl() + `/transactions/?owner=${owner}&currency=${currency}&thisYear=${thisYear}`,
        tokenConfig(getState)
      )
      .then((res) => {
        dispatch({
          type: GET_TRANSACTIONS_SEK,
          payload: res.data,
        });
      })
      .catch((err) =>
        dispatch(returnErrors(err.response.data.detail, err.response.status))
      );
  } else if (currency === "nok") {
    dispatch({ type: QUERY_IN_PROGRESS_TR_NOK });
    axios
      .get(
        serverurl + getDatabaseUrl() + `/transactions/?owner=${owner}&currency=${currency}&thisYear=${thisYear}`,
        tokenConfig(getState)
      )
      .then((res) => {
        dispatch({
          type: GET_TRANSACTIONS_NOK,
          payload: res.data,
        });
      })
      .catch((err) =>
        dispatch(returnErrors(err.response.data.detail, err.response.status))
      );
  }
};

export const clearTransactions = () => (dispatch, getState) => {
  dispatch({
    type: CLEAR_TRANSACTIONS,
  });
};

//GET transaction DETAILS
export const getTransactionDetails = (pk) => (dispatch, getState) => {
  dispatch({ type: QUERY_IN_PROGRESS_TR_DETAILS });
  axios
    .get(serverurl + getDatabaseUrl() + `/transactions/${pk}`, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: GET_TRANSACTION_DETAILS,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch(returnErrors(err.response.data.detail, err.response.status));
    });
};

//PATCH_TRANSACTION
export const patchTransactionDetails = (pk, body, mode) => (
  dispatch,
  getState
) => {
  axios
    .patch(serverurl + getDatabaseUrl() + `/transactions/${pk}`, body, tokenConfigForm(getState))
    .then((res) => {
      dispatch(
        createMessages({
          patchTransaction: "Zaktualizowano dane o transakcji...",
          type: "info",
        })
      );
      dispatch({
        type: PATCH_TRANSACTION_DETAILS,
        payload: res.data,
      });
      if (mode === "accepttransaction" || mode === "declinetransaction") {
        dispatch({
          type: RS_TRANSACTIONS_AK,
          payload: res.data,
        });
      } else if (mode === "acceptpb") {
        dispatch({
          type: RS_TRANSACTIONS_PB,
          payload: res.data,
        });
      } else if (mode === "acceptrt") {
        dispatch({
          type: RS_TRANSACTIONS_RT,
          payload: res.data,
        });
      } else if (mode === "acceptdt") {
        dispatch({
          type: RS_TRANSACTIONS_DT,
          payload: res.data,
        });
      }
    })
    .catch((err) =>
      dispatch(returnErrors(err.response.data.detail, err.response.status))
    );
};

//Reload TRANSACTIONS VIEW

export const reloadTransactions = (setresetflag) => (dispatch, getState) => {
  dispatch({
    type: RELOAD_TRANSACTIONS,
    flag: setresetflag,
  });
};

//PATCH_TRANSACTION (Clearance User)
export const patchTransaction_admin = (
  pk,
  transactionbody,
  smsbody,
  currency,
  operation
) => (dispatch, getState) => {
  if (
    operation === "decline" ||
    operation === "accept" ||
    operation === "monit"
  ) {
    const request1 = axios.patch(
      serverurl + getDatabaseUrl() + `/transactions/${pk}`,
      transactionbody,
      tokenConfigForm(getState)
    );
    /*
    const request4 = axios.post(
      serverurl + getDatabaseUrl() + "/notificationsms/",
      smsbody,
      tokenConfig(getState)
    );
    */
    const request4 = axios.post(
      serverurl + getDatabaseUrl() + "/send_notify",
      smsbody,
      tokenConfig(getState)
    );

    axios
      .all([request1, request4])
      .then(
        axios.spread((...responses) => {
          const response1 = responses[0];
          //const response4 = responses[1];

          //dispatch reducer messages
          dispatch({
            type: PATCH_TRANSACTION_DETAILS,
            payload: response1.data,
          });

          if (currency === "eur") {
            dispatch({
              type: UPDATE_TRANSACTIONS_EUR,
              payload: response1.data,
              operation: operation,
            });
          } else if (currency === "pln") {
            dispatch({
              type: UPDATE_TRANSACTIONS_PLN,
              payload: response1.data,
              operation: operation,
            });
          } else if (currency === "sek") {
            dispatch({
              type: UPDATE_TRANSACTIONS_SEK,
              payload: response1.data,
              operation: operation,
            });
          } else if (currency === "nok") {
            dispatch({
              type: UPDATE_TRANSACTIONS_NOK,
              payload: response1.data,
              operation: operation,
            });
          }
          dispatch(
            createMessages({
              patchTransaction: "Zaktualizowano dane o transakcji...",
              type: "info",
            })
          );
          dispatch({
            type: RELOAD_TRANSACTIONS,
            flag: true,
          });
        })
      )
      .catch((err) => {
        // react on errors.
        dispatch(
          returnErrors(err.response.data.detail, err.response.status, "error")
        );
      });
  }
};

//ADD_TRANSACTION
export const addTransaction = (body, transactionType) => (
  dispatch,
  getState
) => {
  if (transactionType === "wi") {
    axios
      .post(serverurl + getDatabaseUrl() + `/transactions/`, body, tokenConfigForm(getState))
      .then((res) => {
        dispatch(
          createMessages({
            addTransaction: "Zarejestrowano nową transakcję...",
            type: "success",
          })
        );
        dispatch({
          type: ADD_TRANSACTION_WI,
          payload: res.data,
        });
      })
      .catch((err) =>
        dispatch(returnErrors(err.response.data.detail, err.response.status))
      );
  } else if (transactionType === "cp") {
    axios
      .post(serverurl + getDatabaseUrl() + `/transactions/`, body, tokenConfigForm(getState))
      .then((res) => {
        dispatch(
          createMessages({
            addTransaction: "Zarejestrowano nową transakcję...",
            type: "success",
          })
        );
        dispatch({
          type: ADD_TRANSACTION_CP,
          payload: res.data,
        });
      })
      .catch((err) =>
        dispatch(returnErrors(err.response.data.detail, err.response.status))
      );
  } else if (transactionType === "ch") {
    axios
      .post(serverurl + getDatabaseUrl() + `/transactions/`, body, tokenConfigForm(getState))
      .then((res) => {
        dispatch(
          createMessages({
            addTransaction: "Zarejestrowano nową transakcję...",
            type: "success",
          })
        );
        dispatch({
          type: ADD_TRANSACTION_CH,
          payload: res.data,
        });
      })
      .catch((err) =>
        dispatch(returnErrors(err.response.data.detail, err.response.status))
      );
  } else if (transactionType === "pb") {
    axios
      .post(serverurl + getDatabaseUrl() + `/transactions/`, body, tokenConfigForm(getState))
      .then((res) => {
        dispatch(
          createMessages({
            addTransaction: "Zarejestrowano nową transakcję...",
            type: "success",
          })
        );
        dispatch({
          type: ADD_TRANSACTION_PB,
          payload: res.data,
        });
      })
      .catch((err) =>
        dispatch(returnErrors(err.response.data.detail, err.response.status))
      );
  } else if (transactionType === "dt") {
    axios
      .post(serverurl + getDatabaseUrl() + `/transactions/`, body, tokenConfigForm(getState))
      .then((res) => {
        dispatch(
          createMessages({
            addTransaction: "Zarejestrowano nową transakcję...",
            type: "success",
          })
        );
        dispatch({
          type: ADD_TRANSACTION_DT,
          payload: res.data,
        });
      })
      .catch((err) =>
        dispatch(returnErrors(err.response.data.detail, err.response.status))
      );
  } else if (transactionType === "rt") {
    axios
      .post(serverurl + getDatabaseUrl() + `/transactions/`, body, tokenConfigForm(getState))
      .then((res) => {
        dispatch(
          createMessages({
            addTransaction: "Zarejestrowano nową transakcję...",
            type: "success",
          })
        );
        dispatch({
          type: ADD_TRANSACTION_RT,
          payload: res.data,
        });
      })
      .catch((err) =>
        dispatch(returnErrors(err.response.data.detail, err.response.status))
      );
  } else if (transactionType === "oc") {
    axios
      .post(serverurl + getDatabaseUrl() + `/transactions/`, body, tokenConfigForm(getState))
      .then((res) => {
        dispatch(
          createMessages({
            addTransaction: "Zarejestrowano nową transakcję...",
            type: "success",
          })
        );
        dispatch({
          type: ADD_TRANSACTION_OC,
          payload: res.data,
        });
      })
      .catch((err) =>
        dispatch(returnErrors(err.response.data.detail, err.response.status))
      );
  }
};

//Change Transaction Book Date (Clearance User)
export const changeTransactionBookDate = (
  transactionpk,
  body,
  userid,
  thisYearFlag,
  toDate
) => (dispatch, getState) => {
  dispatch({
    type: DATA_CHANGE_IN_PROGRESS,
    flag: true,
  });

  axios
    .patch(
      serverurl + getDatabaseUrl() + `/transactions/${transactionpk}`,
      body,
      tokenConfigForm(getState)
    )
    .then((res) => {
      //refresh transactions for user
      const request1 = axios.get(
        serverurl + getDatabaseUrl() + `/transactions/?owner=${userid}&currency=eur&thisYear=${thisYearFlag}`,
        tokenConfig(getState)
      );
      const request2 = axios.get(
        serverurl + getDatabaseUrl() + `/transactions/?owner=${userid}&currency=pln&thisYear=${thisYearFlag}`,
        tokenConfig(getState)
      );
      const request3 = axios.get(
        serverurl + getDatabaseUrl() + `/transactions/?owner=${userid}&currency=sek&thisYear=${thisYearFlag}`,
        tokenConfig(getState)
      );
      const request7 = axios.get(
        serverurl + getDatabaseUrl() + `/transactions/?owner=${userid}&currency=nok&thisYear=${thisYearFlag}`,
        tokenConfig(getState)
      );

      dispatch({ type: QUERY_IN_PROGRESS_TR_EUR });
      dispatch({ type: QUERY_IN_PROGRESS_TR_PLN });
      dispatch({ type: QUERY_IN_PROGRESS_TR_SEK });
      dispatch({ type: QUERY_IN_PROGRESS_TR_NOK });

      //refresh statistics cards
      const request4 = axios.get(
        serverurl + getDatabaseUrl() + `/transactions-stat/?transactionStatus=ak&toDate=${toDate}`,
        tokenConfig(getState)
      );
      const request5 = axios.get(
        serverurl + getDatabaseUrl() + `/transactions-stat/?transactionStatus=ar&toDate=${toDate}`,
        tokenConfig(getState)
      );
      const request6 = axios.get(
        serverurl + getDatabaseUrl() + `/transactions-stat/?transactionStatus=noin&toDate=${toDate}`,
        tokenConfig(getState)
      );

      dispatch({ type: QUERY_IN_PROGRESS_STAT_AK });
      dispatch({ type: QUERY_IN_PROGRESS_STAT_AR });
      dispatch({ type: QUERY_IN_PROGRESS_STAT_NOIN });

      axios
        .all([request1, request2, request3, request4, request5, request6, request7])
        .then(
          axios.spread((...responses) => {
            const response1 = responses[0];
            const response2 = responses[1];
            const response3 = responses[2];
            const response4 = responses[3];
            const response5 = responses[4];
            const response6 = responses[5];
            const response7 = responses[6];

            dispatch({
              type: GET_TRANSACTIONS_EUR,
              payload: response1.data,
            });
            dispatch({
              type: GET_TRANSACTIONS_PLN,
              payload: response2.data,
            });
            dispatch({
              type: GET_TRANSACTIONS_SEK,
              payload: response3.data,
            });
            dispatch({
              type: GET_TRANSACTIONS_NOK,
              payload: response7.data,
            });
            dispatch({
              type: GET_TRANSACTIONS_STAT_AK,
              payload: response4.data,
            });
            dispatch({
              type: GET_TRANSACTIONS_STAT_AR,
              payload: response5.data,
            });
            dispatch({
              type: GET_TRANSACTIONS_STAT_NOIN,
              payload: response6.data,
            });
            dispatch(
              createMessages({
                patchTransaction: "Zaktualizowano dane o transakcji...",
                type: "info",
              })
            );
            dispatch({
              type: PATCH_TRANSACTION_DETAILS,
              payload: res.data,
            });
            dispatch({
              type: DATA_CHANGE_IN_PROGRESS,
              flag: false,
            });
          })
        )
        .catch((err1) => {
          // react on errors.
          dispatch(
            returnErrors(
              err1.response.data.detail,
              err1.response.status,
              "error"
            )
          );
        });
    })
    .catch((err) =>
      dispatch(returnErrors(err.response.data.detail, err.response.status))
    );
};
