import React, { Component, Fragment } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import Card from "../../components/Card/Card.js";
import CardBody from "../../components/Card/CardBody.js";
import Typography from "@material-ui/core/Typography";
import { createMuiTheme } from "@material-ui/core";

import PropTypes from "prop-types";
import { connect } from "react-redux";

import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";
import { List, ListItem, ListItemIcon, ListItemText } from "@material-ui/core";

const materialTheme = createMuiTheme({
  palette: {
    primary: {
      main: "#0071BC",
    },
  },
  overrides: {
    MuiChip: {
      root: {
        color: "#ffffff",
        "& $avatar": {
          color: "#ffffff",
        },
      },
      icon: {
        color: "#ffffff",
      },
    },
    MuiCardActions: {
      root: {
        background: "#aaaaaa",
      },
    },
    MuiIconButton: {
      root: {
        color: "#fff",
      },
    },
  },
});

const styles = (theme) => ({
  avatar: {
    backgroundColor: "#0071BC",
    height: 50,
    width: 50,
  },
  Address: {
    textAlign: "left",
    fontFamily: "Lato, Open Sans, sans-serif",
    fontSize: "1em",
    fontWeight: "300",
    color: "#444444",
  },
  Position: {
    textAlign: "left",
    fontFamily: "Lato, Open Sans, sans-serif",
    fontSize: "1em",
    fontWeight: "400",
    color: "#0071BC",
  },
  Name: {
    textAlign: "left",
    fontFamily: "Lato, Open Sans, sans-serif",
    fontSize: "2em",
    fontWeight: "700",
    color: "#444444",
  },
  ProfileBar: {
    textAlign: "left",
    fontFamily: "Lato, Open Sans, sans-serif",
    fontSize: "1em",
    fontWeight: "400",
    color: "#444444",
  },
  Country: {
    textAlign: "left",
    fontFamily: "Lato, Open Sans, sans-serif",
    fontSize: "1em",
    fontWeight: "300",
    color: "#444444",
    textTransform: "uppercase",
  },
  large: {
    width: "90px",
    height: "90px",
  },
});

export class InspectionDocumentsCard extends Component {
  static propTypes = {
    inspectionDetails: PropTypes.object.isRequired,
  };

  render() {
    const { classes, inspectionDetails } = this.props;

    function getFileName(url) {
      return new URL(url).pathname.split('/').pop();
    }

    return (
      <Fragment>
        {inspectionDetails.files.length > 0 && (
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Card style={{ marginTop: "0px", marginBottom: "0px" }}>
              <CardBody>
                <Grid container justify="space-between" alignItems="center" spacing={2}>
                  <Grid item xs={11} sm={11} md={11} lg={11}>
                    <Typography className={classes.Name}>
                      Dokumenty / Pliki
                    </Typography>
                    <br></br>
                    <List>
                      {inspectionDetails.files.map((file, index) => (
                        <ListItem key={index}>
                          <ListItemIcon>
                            <InsertDriveFileIcon />
                          </ListItemIcon>
                          <ListItemText
                            primary={
                              <a href={file.file} download={getFileName(file.file)}>
                                {getFileName(file.file)}
                              </a>
                            }
                          />
                        </ListItem>
                      ))}
                    </List>
                  </Grid>
                </Grid>
              </CardBody>
            </Card>
          </Grid>
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  inspectionDetails: state.fleet.inspectionDetails,
});

export default connect(
  mapStateToProps,
  {}
)(withStyles(styles, { withTheme: true })(InspectionDocumentsCard));
