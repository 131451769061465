import React, { Component, Fragment } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import Card from "../components/Card/Card.js";
import CardBody from "../components/Card/CardBody.js";
import Typography from "@material-ui/core/Typography";
//import Button from "@material-ui/core/Button";
import { createMuiTheme } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";
//import LinearProgress from "@material-ui/core/LinearProgress";
//import Box from "@material-ui/core/Box";
import TextField from "@material-ui/core/TextField";

import PropTypes from "prop-types";
import { connect } from "react-redux";

const materialTheme = createMuiTheme({
  palette: {
    primary: {
      main: "#0071BC",
    },
  },
  overrides: {
    MuiChip: {
      root: {
        color: "#ffffff",
        "& $avatar": {
          color: "#ffffff",
        },
      },
      icon: {
        color: "#ffffff",
      },
    },
    MuiCardActions: {
      root: {
        background: "#aaaaaa",
      },
    },
    MuiIconButton: {
      root: {
        color: "#fff",
      },
    },
  },
});

const styles = (theme) => ({
  avatar: {
    backgroundColor: "#0071BC",
    height: 50,
    width: 50,
  },
  Address: {
    textAlign: "left",
    fontFamily: "Lato, Open Sans, sans-serif",
    fontSize: "1em",
    fontWeight: "300",
    color: "#444444",
  },
  Name: {
    textAlign: "left",
    fontFamily: "Lato, Open Sans, sans-serif",
    fontSize: "1em",
    fontWeight: "700",
    color: "#444444",
  },
  ProfileBar: {
    textAlign: "left",
    fontFamily: "Lato, Open Sans, sans-serif",
    fontSize: "1em",
    fontWeight: "400",
    color: "#444444",
  },
  Country: {
    textAlign: "left",
    fontFamily: "Lato, Open Sans, sans-serif",
    fontSize: "1em",
    fontWeight: "300",
    color: "#444444",
    textTransform: "uppercase",
  },
  large: {
    width: "70px",
    height: "70px",
  },
});

export class BankingCard extends Component {
  state = {};

  static propTypes = {
    auth: PropTypes.object.isRequired,
  };

  render() {
    const { classes, auth } = this.props;

    return (
      <Fragment>
        <Grid item xs={12} sm={12} md={6} lg={4}></Grid>
        <Grid item xs={12} sm={12} md={6} lg={8}>
          <Card style={{ marginBottom: "0px" }}>
            <CardBody>
              <ThemeProvider theme={materialTheme}>
                <Grid
                  container
                  justify="space-between"
                  alignItems="center"
                  spacing={2}
                >
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Typography className={classes.Name}>
                      Profil finansowy
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={4} md={4} lg={3}>
                    <TextField
                      label="Nazwa banku"
                      id="outlined-margin-normal"
                      value={auth.additional_profile.salaryBankName}
                      fullWidth
                      className={classes.textField}
                      margin="normal"
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12} sm={8} md={8} lg={9}>
                    <TextField
                      label="Numer konta"
                      id="outlined-margin-normal"
                      value={auth.additional_profile.salaryAccountNumber}
                      fullWidth
                      className={classes.textField}
                      margin="normal"
                      variant="outlined"
                    />
                  </Grid>
                </Grid>
              </ThemeProvider>
            </CardBody>
          </Card>
        </Grid>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(
  mapStateToProps,
  {}
)(withStyles(styles, { withTheme: true })(BankingCard));
