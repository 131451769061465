import React, { Component } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import {
  styles,
  materialTheme,
  CssTextField,
} from "../../common/radiolineStyles";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Card from "../../components/Card/Card.js";
import CardBody from "../../components/Card/CardBody.js";
import CardHeader from "@material-ui/core/CardHeader";
import { ThemeProvider } from "@material-ui/styles";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "@material-ui/core/Button";
import {
  getUsersList,
  getUserDetails,
  clearUserDetails,
} from "../../Redux/actions/common";
import { transferAsset_admin } from "../../Redux/actions/assets";
import { getFormattedDate } from "../../common/commonFunctions";

export class DialogAssetTransfer extends Component {
  state = {
    open: false,
    tEmployeeSelected: null,
  };

  static propTypes = {
    auth: PropTypes.object.isRequired,
    getUserDetails: PropTypes.func.isRequired,
    clearUserDetails: PropTypes.func.isRequired,
    usersList: PropTypes.array.isRequired,
    suserDetails: PropTypes.object.isRequired,
    tuserDetails: PropTypes.object.isRequired,
    queryInProgressUL: PropTypes.bool.isRequired,
    queryInProgress: PropTypes.bool.isRequired,
    transferAsset_admin: PropTypes.func.isRequired,
  };

  triggerCloseDialogAssetTransfer = () => {
    this.props.closeDialogAssetTransfer();
  };

  setOpen = () => {
    const open = this.state.open;
    if (open === false && this.props.usersList.length === 0) {
      this.props.getUsersList();
    }
    this.setState({
      open: !open,
    });
  };

  getSelected = (event, value) => {
    this.setState({
      tEmployeeSelected: value,
    });
    if (value !== null) {
      this.props.getUserDetails(value.id, "t");
    } else {
      this.props.clearUserDetails("t");
    }
  };

  handleTransferAsset = () => {
    const fromDate = getFormattedDate(new Date());
    const assetbody = {
      owner: this.props.tuserDetails.pk,
      status: "iu",
      assignDate: fromDate,
    };

    var assettransferlogbody = null;
    var ssmsbody = null;
    var tsmsbody = null;

    if (this.props.mode === "u2u") {
      assettransferlogbody = {
        fromUser: this.props.assetsDetails.owner.username,
        toUser: this.props.tuserDetails.username,
        acceptUser: this.props.auth.user.username,
        acceptDate: fromDate,
        toStatus: "ta",
        acceptStatus: "ac",
        equipment: this.props.assetsDetails.pk,
      };
      ssmsbody = {
        recipient: this.props.suserDetails.email,
        //sender: "WYPOSAZENIE",
        module: "Środki Trwałe",
        topic: "Przekazanie środka trwałego",
        message:
          "Użytkownik " +
          this.props.auth.user.first_name + " " + this.props.auth.user.last_name +
          " przeniósł Twój środek trwały " +
          this.props.assetsDetails.model.name +
          " " +
          this.props.assetsDetails.model.description +
          " na użytkownika " +
          this.props.tuserDetails.first_name + " " + this.props.tuserDetails.last_name,
        //owner: this.props.auth.user.id,
        //notificationType: "sms",
        //isDelivered: true,
      };
      tsmsbody = {
        recipient: this.props.tuserDetails.email,
        //sender: "WYPOSAZENIE",
        module: "Środki Trwałe",
        topic: "Otrzymanie środka trwałego",
        message:
          "Użytkownik " +
          this.props.auth.user.first_name + " " + this.props.auth.user.last_name +
          " przeniósł na Ciebie środek trwały " +
          this.props.assetsDetails.model.name +
          " " +
          this.props.assetsDetails.model.description +
          " użytkownika " +
          this.props.suserDetails.first_name + " " + this.props.suserDetails.last_name,
        //owner: this.props.auth.user.id,
        //notificationType: "sms",
        //isDelivered: true,
      };
    } else if (this.props.mode === "w2u") {
      assettransferlogbody = {
        fromUser: "magazyn",
        toUser: this.props.tuserDetails.username,
        acceptUser: this.props.auth.user.username,
        acceptDate: fromDate,
        toStatus: "ta",
        acceptStatus: "ac",
        equipment: this.props.assetsDetails.pk,
      };
      tsmsbody = {
        recipient: this.props.tuserDetails.email,
        //sender: "WYPOSAZENIE",
        module: "Środki Trwałe",
        topic: "Otrzymanie środka trwałego",
        message:
          "Użytkownik " +
          this.props.auth.user.first_name + " " + this.props.auth.user.last_name +
          " przeniósł na Ciebie środek trwały " +
          this.props.assetsDetails.model.name +
          " " +
          this.props.assetsDetails.model.description +
          " z zasobów magazynowych.",
        //owner: this.props.auth.user.id,
        //notificationType: "sms",
        //isDelivered: true,
      };
    }

    this.props.transferAsset_admin(
      this.props.assetsDetails.pk,
      assetbody,
      assettransferlogbody,
      ssmsbody,
      tsmsbody,
      this.props.assetsDetails.model.eqtype,
      this.props.mode
    );

    this.props.closeDialogAssetTransfer(true);
  };

  render() {
    const { open } = this.state;
    const {
      classes,
      dialogOpenAssetTransfer,
      assetsDetails,
      usersList,
      tuserDetails,
      queryInProgress,
    } = this.props;

    const loading = open && usersList.length === 0;

    const assetTransferButtonEnabled = (
      <Grid container className={classes.root} spacing={2} justify="center">
        <Button
          variant="contained"
          color="inherit"
          size="large"
          onClick={this.handleTransferAsset}
          style={{ marginTop: "20px" }}
          className={classes.buttonAccept}
        >
          Przekaż
        </Button>
      </Grid>
    );

    const assetTransferButtonDisabled = (
      <Grid container className={classes.root} spacing={2} justify="center">
        <Button
          disabled
          variant="contained"
          size="large"
          style={{ marginTop: "20px" }}
        >
          Przekaż
        </Button>
      </Grid>
    );

    const assetContent = (
      <Grid container className={classes.root} spacing={2} justify="center">
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          style={{ marginTop: "20px" }}
        >
          <Typography className={classes.textType2}>
            Szczegóły przekazywanego środka trwałego
          </Typography>
        </Grid>

        <Grid p={0} m={0} item xs={12} sm={6} md={3} lg={3}>
          <Card style={{ margin: 0 }}>
            <CardBody>
              <Typography className={classes.textType}>
                {assetsDetails.model.eqtype_description}
              </Typography>
              <Typography className={classes.textName}>
                <b>{assetsDetails.model.name}</b>
              </Typography>
              <Typography className={classes.textDescription}>
                {assetsDetails.model.description !== ""
                  ? assetsDetails.model.description
                  : "model nieznany"}
              </Typography>
            </CardBody>
          </Card>
        </Grid>

        <Grid p={0} m={0} item xs={12} sm={6} md={3} lg={3}>
          <Card style={{ margin: 0 }}>
            <CardHeader
              title={
                <Typography className={classes.textName2}>Producent</Typography>
              }
            ></CardHeader>
            <CardBody>
              <Typography className={classes.textDescription2}>
                {assetsDetails.model.vendor}
              </Typography>
            </CardBody>
          </Card>
        </Grid>
        <Grid p={0} m={0} item xs={12} sm={6} md={3} lg={3}>
          <Card style={{ margin: 0 }}>
            <CardHeader
              title={
                <Typography className={classes.textName2}>
                  Numer seryjny
                </Typography>
              }
            ></CardHeader>
            <CardBody>
              <Typography className={classes.textDescription2}>
                {assetsDetails.serialNumber === ""
                  ? "-BRAK-"
                  : assetsDetails.serialNumber}
              </Typography>
            </CardBody>
          </Card>
        </Grid>
        <Grid p={0} m={0} item xs={12} sm={6} md={3} lg={3}>
          <Card style={{ margin: 0 }}>
            <CardHeader
              title={
                <Typography className={classes.textName2}>
                  Numer produktu
                </Typography>
              }
            ></CardHeader>
            <CardBody>
              <Typography className={classes.textDescription2}>
                {assetsDetails.partNumber === ""
                  ? "-BRAK-"
                  : assetsDetails.partNumber}
              </Typography>
            </CardBody>
          </Card>
        </Grid>
      </Grid>
    );

    const assetTransferChoseEmployee = (
      <Grid container className={classes.root} spacing={2} justify="center">
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          style={{ marginTop: "20px" }}
        >
          <Typography className={classes.textType2}>
            Komu chcesz przekazać sprzęt ?
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Card style={{ margin: 0, overflow: "auto" }}>
            <CardBody>
              <Typography
                style={{ marginBottom: "50px" }}
                className={classes.textType}
              >
                Wybierz pracownika któremu chcesz przekazać środek trwały
              </Typography>
              <Typography className={classes.textName}>Pracownik</Typography>
              <ThemeProvider theme={materialTheme}>
                <Autocomplete
                  id="asynchronous-demo"
                  style={{ width: "300px" }}
                  open={open}
                  onOpen={this.setOpen}
                  onClose={this.setOpen}
                  onChange={this.getSelected}
                  getOptionSelected={(option, value) =>
                    option.last_name === value.last_name &&
                    option.first_name === value.first_name
                  }
                  getOptionLabel={(option) =>
                    option.first_name + " " + option.last_name
                  }
                  options={usersList}
                  loading={loading}
                  renderInput={(params) => (
                    <CssTextField
                      {...params}
                      label="wybierz z listy..."
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {loading ? (
                              <CircularProgress color="inherit" size={20} />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                    />
                  )}
                />
              </ThemeProvider>
            </CardBody>
          </Card>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          {!queryInProgress && tuserDetails.pk
            ? assetTransferButtonEnabled
            : ""}
          {!queryInProgress && !tuserDetails.pk
            ? assetTransferButtonDisabled
            : ""}
        </Grid>
      </Grid>
    );

    return (
      <Dialog
        fullScreen
        open={dialogOpenAssetTransfer}
        onClose={this.triggerCloseDialogAssetTransfer}
        aria-labelledby="form-dialog-title"
      >
        <AppBar position="static" style={{ background: "#0071BC" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={this.triggerCloseDialogAssetTransfer}
              aria-label="close"
              className={classes.menuButton}
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              Przekaż środek trwały
            </Typography>
          </Toolbar>
        </AppBar>
        <DialogContent style={{ background: "#f5f5f5" }}>
          {assetContent}
          {assetTransferChoseEmployee}
        </DialogContent>
      </Dialog>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  usersList: state.common.users,
  suserDetails: state.common.suser,
  tuserDetails: state.common.tuser,
  queryInProgressUL: state.common.queryInProgress,
  queryInProgress: state.assets.queryInProgress,
});

export default connect(
  mapStateToProps,
  {
    getUsersList,
    clearUserDetails,
    getUserDetails,
    transferAsset_admin,
  }
)(withStyles(styles, { withTheme: true })(DialogAssetTransfer));
