import { correctDateFormat } from "../Common/correctDateFormat";

export function generate(doc, info) {
  doc.setProperties({
    title: 'Wniosek o świadczenie okolicznościowe',
    author: 'Radio Line',
    creator: 'Radio Line EXTRANET',
  });

  var signature = correctDateFormat(info.date) + " " + info.firstName + " " + info.lastName;

  var xOffset = doc.internal.pageSize.width / 2;
  var xSignature = doc.internal.pageSize.width / 5 * 3;
  var xStart = 20;
  var xEnd = 20;
  var yOffset = 25;
  doc.setLineWidth(0.25);

  //Clearing
  doc.deletePage(1);
  doc.deletePage(1);
  doc.addPage();

  function genLine(lineStart, lineHeight, lineLength) {
    doc.setLineDashPattern([0.25, 0.5], 0);
    doc.line(lineStart, lineHeight, lineStart + lineLength, lineHeight);
    doc.setLineDashPattern([], 0);
  }

  function genLineText(text) {
    var lineStart = doc.getTextWidth(text) + xStart;
    var lineLength = doc.internal.pageSize.width - xEnd;
    doc.setLineDashPattern([0.25, 0.5], 0);
    doc.line(lineStart, yOffset + 0.5, lineLength, yOffset + 0.5);
    doc.setLineDashPattern([], 0);
  }

  function genLineText2(text) {
    var lineEnd = doc.getTextWidth(text) + 10;
    doc.setLineDashPattern([0.25, 0.5], 0);
    doc.line(xStart, yOffset + 0.5, lineEnd, yOffset + 0.5);
    doc.setLineDashPattern([], 0);
  }

  function genSignature() {
    var lineLength = 60;
    var signatureOffset = xSignature + (lineLength - doc.getTextWidth(signature)) / 2;
    doc.text(signature, signatureOffset, yOffset);
    yOffset += 4;
    doc.setFontSize(8);
    signatureOffset = xSignature + (lineLength - doc.getTextWidth("Autoryzowano kodem")) / 2;
    doc.text("Autoryzowano kodem", signatureOffset, yOffset);
    doc.setFontSize(11);
    yOffset += 2;
    genLine(xSignature, yOffset, lineLength);
    yOffset += 5;
    signatureOffset = xSignature + (lineLength - doc.getTextWidth("(data i podpis wnioskodawcy)")) / 2;
    doc.text("(data i podpis wnioskodawcy)", signatureOffset, yOffset);
    yOffset += 30;
  }

  doc.setFontSize(11);
  doc.setFont('DejaVuSansCondensed', 'bold');
  doc.text("Wniosek o świadczenie okolicznościowe z Zakładowego Funduszu Świadczeń", xOffset, yOffset, 'center');
  yOffset += 6;
  doc.text("Socjalnych w Radio Line Sp. z o.o.", xOffset, yOffset, 'center');
  yOffset += 15;

  doc.setFont('DejaVuSansCondensed', 'normal');
  doc.text(info.firstName + " " + info.lastName, xStart, yOffset);
  genLineText2("(imię i nazwisko osoby uprawnionej wg. Regulaminu ZFŚS)");
  yOffset += 5;
  doc.text("(imię i nazwisko osoby uprawnionej wg. Regulaminu ZFŚS)", xStart, yOffset);
  yOffset += 10;

  doc.text("1. Ja, " + info.firstName + " " + info.lastName + " zwracam się z prośbą o przyznanie mi świadczenia", xStart, yOffset);
  yOffset += 5;
  xStart += doc.getTextWidth("1. ");
  doc.text("okolicznościowego.", xStart, yOffset);
  yOffset += 7;
  xStart -= doc.getTextWidth("1. ");
  doc.text("2. Deklaruję dochód na osobę w rodzinie zgodny z oświadczeniem o dochodach", xStart, yOffset);
  yOffset += 5;
  xStart += doc.getTextWidth("2. ");
  doc.text("przypadających na osobę gospodarstwie domowym, złożonym przeze mnie Komisji ", xStart, yOffset);
  yOffset += 5;
  doc.text("Socjalnej w dniu " + correctDateFormat(info.inc), xStart, yOffset);
  xStart -= doc.getTextWidth("2. ");

  yOffset += 15;
  genSignature();

  doc.save("wniosek_o_swiadczenie_okolicznosciowe.pdf");
}