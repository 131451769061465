import {
  GET_EMPLOYEE_TRIPS_CA,
  GET_EMPLOYEE_TRIPS_ZA,
  GET_EMPLOYEE_TRIPS_RZ,
  GET_EMPLOYEE_TRIPS_SEARCH,
  GET_EMPLOYEE_TRIP_DETAILS,
  CLEAR_EMPLOYEE_TRIP_DETAILS,
  PATCH_EMPLOYEE_TRIP,
  PATCH_ACCOUNTING_TRIP,
  ADD_TRIP_COMMENT_M,
  ADD_TIMESTAMP_M,
  DELETE_TIMESTAMP_M,
  T_QUERY_IN_PROGRESS,
  T_BULK_QUERY_IN_PROGRESS_CA,
  T_BULK_QUERY_IN_PROGRESS_ZA,
  T_BULK_QUERY_IN_PROGRESS_RZ,
  T_QUERY_FAILED
} from "../actions/types";

import { repairTimestamp } from "./trips.js";

const initialState = {
  queryInProgress: false,
  bulkQueryInProgressRZ: false,
  bulkQueryInProgressCA: false,
  bulkQueryInProgressZA: false,
  employeeTripsCA: [],
  employeeTripsZA: [],
  employeeTripsRZ: [],
  employeeTripsSEARCH: [],
  employeeTripDetails: {}
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_EMPLOYEE_TRIPS_CA:
      return {
        ...state,
        bulkQueryInProgressCA: false,
        employeeTripsCA: action.payload
      };
    case GET_EMPLOYEE_TRIPS_ZA:
      return {
        ...state,
        bulkQueryInProgressZA: false,
        employeeTripsZA: action.payload
      };
    case GET_EMPLOYEE_TRIPS_RZ:
      return {
        ...state,
        bulkQueryInProgressRZ: false,
        employeeTripsRZ: action.payload
      };
    case T_QUERY_IN_PROGRESS:
      return {
        ...state,
        queryInProgress: true
      };
    case T_BULK_QUERY_IN_PROGRESS_RZ:
      return {
        ...state,
        bulkQueryInProgressRZ: true
      };
    case T_BULK_QUERY_IN_PROGRESS_CA:
      return {
        ...state,
        bulkQueryInProgressCA: true
      };
    case T_BULK_QUERY_IN_PROGRESS_ZA:
      return {
        ...state,
        bulkQueryInProgressZA: true
      };
    case T_QUERY_FAILED:
      return {
        ...state,
        queryInProgress: false,
        bulkQueryInProgressRZ: false,
        bulkQueryInProgressCA: false,
        bulkQueryInProgressZA: false
      };
    case GET_EMPLOYEE_TRIPS_SEARCH:
      return {
        ...state,
        queryInProgress: false,
        employeeTripsSEARCH: action.payload
      };
    case GET_EMPLOYEE_TRIP_DETAILS:
      return {
        ...state,
        queryInProgress: false,
        employeeTripDetails: action.payload
      };
    case CLEAR_EMPLOYEE_TRIP_DETAILS:
      return {
        ...state,
        employeeTripDetails: {}
      };
    case PATCH_EMPLOYEE_TRIP:
      return {
        ...state,
        employeeTripDetails: action.payload
      };
    case PATCH_ACCOUNTING_TRIP:
      return {
        ...state,
        employeeTripsRZ: state.employeeTripsRZ.filter(
          trip => trip.pk !== action.payload.pk
        )
      };
    case ADD_TRIP_COMMENT_M:
      return {
        ...state,
        employeeTripDetails: {
          ...state.employeeTripDetails,
          comments: [...state.employeeTripDetails.comments, action.payload]
        }
      };
    case ADD_TIMESTAMP_M:
      var payload = action.payload;
      payload = repairTimestamp(payload);

      return {
        ...state,
        employeeTripDetails: {
          ...state.employeeTripDetails,
          timestamps: [...state.employeeTripDetails.timestamps, payload]
        }
      };
    case DELETE_TIMESTAMP_M:
      return {
        ...state,
        employeeTripDetails: {
          ...state.employeeTripDetails,
          timestamps: state.employeeTripDetails.timestamps.filter(
            timestamp => timestamp.pk !== action.payload
          )
        }
      };
    default:
      return state;
  }
}
