import {
  GET_EMPLOYEE_ASSETS_AL,
  GET_EMPLOYEE_ASSETS_NA,
  GET_EMPLOYEE_ASSETS_EL,
  GET_EMPLOYEE_ASSETS_IT,
  GET_EMPLOYEE_ASSETS_PO,
  GET_EMPLOYEE_ASSETS_DETAILS_AL,
  GET_EMPLOYEE_ASSETS_DETAILS_NA,
  GET_EMPLOYEE_ASSETS_DETAILS_EL,
  GET_EMPLOYEE_ASSETS_DETAILS_IT,
  GET_EMPLOYEE_ASSETS_DETAILS_PO,
  GET_ASSET_DETAILS,
  GET_EMPLOYEE_ASSETS_BYSTATUS_AC,
  GET_EMPLOYEE_ASSETS_BYSTATUS_TR,
  GET_EMPLOYEE_ASSETS_BYSTATUS_SR,
  GET_EMPLOYEE_ASSETS_BYSTATUS_WR,
  GET_EMPLOYEE_ASSETS_BYNAME,
  PATCH_EMPLOYEE_ASSET,
  ASSET_RS_AL,
  ASSET_RS_NA,
  ASSET_RS_EL,
  ASSET_RS_IT,
  ASSET_RS_PO,
  ADD_ASSET_AL,
  ADD_ASSET_NA,
  ADD_ASSET_EL,
  ADD_ASSET_IT,
  ADD_ASSET_PO,
  GET_MY_ASSETS_AL,
  GET_MY_ASSETS_NA,
  GET_MY_ASSETS_EL,
  GET_MY_ASSETS_IT,
  GET_MY_ASSETS_PO,
  GET_WAR_ASSETS_AL,
  GET_WAR_ASSETS_NA,
  GET_WAR_ASSETS_EL,
  GET_WAR_ASSETS_IT,
  GET_WAR_ASSETS_PO,
  GET_MY_ASSETS_DETAILS,
  GET_MY_ASSETS_BYSTATUS_SD,
  GET_MY_ASSETS_BYSTATUS_SR,
  GET_MY_ASSETS_BYSTATUS_WR,
  A_QUERY_MY_SD_IN_PROGRESS,
  A_QUERY_MY_SR_IN_PROGRESS,
  A_QUERY_MY_WR_IN_PROGRESS,
  A_QUERY_FAILED,
  A_QUERY_IN_PROGRESS,
  A_QUERY_FILTEREDAC_IN_PROGRESS,
  A_QUERY_FILTEREDTR_IN_PROGRESS,
  A_QUERY_FILTEREDFI_IN_PROGRESS,
  A_QUERY_FILTEREDSR_IN_PROGRESS,
  A_QUERY_FILTEREDWR_IN_PROGRESS,
  A_QUERY_AL_IN_PROGRESS,
  A_QUERY_EL_IN_PROGRESS,
  A_QUERY_IT_IN_PROGRESS,
  A_QUERY_NA_IN_PROGRESS,
  A_QUERY_PO_IN_PROGRESS,
  CLEAR_EMPLOYEE_ASSETS_STATE,
  CLEAR_EMPLOYEE_ASSETS_BYSTATUS,
  ADD_ASSET_TRANSFER_LOG,
  PATCH_MY_ASSET_AL,
  PATCH_MY_ASSET_NA,
  PATCH_MY_ASSET_EL,
  PATCH_MY_ASSET_IT,
  PATCH_MY_ASSET_PO,
  PATCH_MY_ASSET_AL_RS,
  PATCH_MY_ASSET_NA_RS,
  PATCH_MY_ASSET_EL_RS,
  PATCH_MY_ASSET_IT_RS,
  PATCH_MY_ASSET_PO_RS,
  CLEAR_CUR_EQ_TR_LOG,
  PATCH_ASSET_TRANSFER_LOG,
  GET_EQMODELS_LIST,
  GET_VENDORS_LIST,
  CLEAR_EQMODELS_LIST,
  ADD_ASSET_MODEL,
  ADD_VENDOR,
  CLEAR_ASSETS_DETAILS,
  //SCRAP
  SCRAP_MY_ASSET_AL_REQ,
  SCRAP_MY_ASSET_NA_REQ,
  SCRAP_MY_ASSET_EL_REQ,
  SCRAP_MY_ASSET_IT_REQ,
  SCRAP_MY_ASSET_PO_REQ,
  SCRAP_MY_ASSET_AL_CAN,
  SCRAP_MY_ASSET_NA_CAN,
  SCRAP_MY_ASSET_EL_CAN,
  SCRAP_MY_ASSET_IT_CAN,
  SCRAP_MY_ASSET_PO_CAN,
  SCRAP_MY_ASSET_AL_UPD,
  SCRAP_MY_ASSET_NA_UPD,
  SCRAP_MY_ASSET_EL_UPD,
  SCRAP_MY_ASSET_IT_UPD,
  SCRAP_MY_ASSET_PO_UPD,
  SCRAP_EMP_ASSET_SD,
  SCRAP_EMP_ASSET_WR,
  //INVENTORY
  START_INVENTORY,
  REPORT_INVENTORY,
  STOP_INVENTORY,
  GET_ACTIVE_INVENTORY,
  GET_INVENTORY,
  CLEAR_INVENTORY,
  GET_MY_INV_AL,
  GET_MY_INV_NA,
  GET_MY_INV_EL,
  GET_MY_INV_IT,
  GET_MY_INV_PO,
  INV_QUERY_AL_IN_PROGRESS,
  INV_QUERY_NA_IN_PROGRESS,
  INV_QUERY_EL_IN_PROGRESS,
  INV_QUERY_IT_IN_PROGRESS,
  INV_QUERY_PO_IN_PROGRESS,
  GET_MY_INV_DETAILS,
  UPDATE_MY_INV_DETAILS,
  UPDATE_EMP_INV_DETAILS,
  DELETE_MY_INV_DETAILS,
  ADD_MY_INV_DETAILS,
  GET_INVENTORY_IP,
  GET_INVENTORY_RE,
  GET_INVENTORY_BH,
  GET_INVENTORY_MA,
  GET_INVENTORY_FI,
  GET_EMPLOYEE_INV_ASSETS_AL,
  GET_EMPLOYEE_INV_ASSETS_EL,
  GET_EMPLOYEE_INV_ASSETS_IT,
  GET_EMPLOYEE_INV_ASSETS_NA,
  GET_EMPLOYEE_INV_ASSETS_PO,
  GET_INVENTORY_ASSETS,
  CLEAR_INVENTORY_ASSETS,
  RAPORT_QUERY_IN_PROGRESS,
  RAPORT_QUERY_IN_PROGRESS2,
  GET_INVENTORY_DETAILS,
  CLEAR_INVENTORY_EMP_ASSETS,
  CLEAR_EMP_INVENTORIES,
  CLEAR_VENDORS_LIST,
} from "../actions/types";

const initialState = {
  queryInProgress: false,
  queryFilteredAcInProgress: false,
  queryFilteredTrInProgress: false,
  queryFilteredFiInProgress: false,
  queryFilteredSrInProgress: false,
  queryFilteredWrInProgress: false,
  queryMySrInProgress: false,
  queryMyWrInProgress: false,
  queryMySdInProgress: false,
  queryInProgressAl: false,
  queryInProgressNa: false,
  queryInProgressEl: false,
  queryInProgressIt: false,
  queryInProgressPo: false,
  employeeAssetsAl: [],
  employeeAssetsNa: [],
  employeeAssetsEl: [],
  employeeAssetsIt: [],
  employeeAssetsPo: [],
  employeeAssetsByStatusAc: [],
  employeeAssetsByStatusTr: [],
  employeeAssetsByStatusSr: [],
  employeeAssetsByStatusWr: [],
  employeeAssetsByName: [],
  assetDetails: {},
  myAssetsAl: [],
  myAssetsNa: [],
  myAssetsEl: [],
  myAssetsIt: [],
  myAssetsPo: [],
  myInvAl: [],
  myInvNa: [],
  myInvEl: [],
  myInvIt: [],
  myInvPo: [],
  warAssetsAl: [],
  warAssetsNa: [],
  warAssetsEl: [],
  warAssetsIt: [],
  warAssetsPo: [],
  myAssetsByStatusSr: [],
  myAssetsByStatusWr: [],
  myAssetsByStatusSd: [],
  myAssetsDetails: {},
  currentEquipmentTransferLog: null,
  equipmentModels: [],
  vendorsList: [],
  activeInventory: null,
  inventory: [],
  myInventoryDetails: {},
  inventoryIP: [],
  inventoryRE: [],
  inventoryBH: [],
  inventoryMA: [],
  inventoryFI: [],
  employeeInventoryAl: [],
  employeeInventoryNa: [],
  employeeInventoryEl: [],
  employeeInventoryIt: [],
  employeeInventoryPo: [],
  inventoryAssets: [],
  inventoryDetails: [],
  raportQueryInProgress: false,
  raportQueryInProgress2: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_EMPLOYEE_ASSETS_AL:
      return {
        ...state,
        employeeAssetsAl: action.payload,
        queryInProgressAl: false,
      };
    case GET_EMPLOYEE_ASSETS_NA:
      return {
        ...state,
        employeeAssetsNa: action.payload,
        queryInProgressNa: false,
      };
    case GET_EMPLOYEE_ASSETS_EL:
      return {
        ...state,
        employeeAssetsEl: action.payload,
        queryInProgressEl: false,
      };
    case GET_EMPLOYEE_ASSETS_IT:
      return {
        ...state,
        employeeAssetsIt: action.payload,
        queryInProgressIt: false,
      };
    case GET_EMPLOYEE_ASSETS_PO:
      return {
        ...state,
        employeeAssetsPo: action.payload,
        queryInProgressPo: false,
      };
    case GET_EMPLOYEE_ASSETS_DETAILS_AL:
      return {
        ...state,
        employeeAssetsAlDetails: action.payload,
        queryInProgress: false,
      };
    case GET_EMPLOYEE_ASSETS_DETAILS_NA:
      return {
        ...state,
        employeeAssetsNaDetails: action.payload,
        queryInProgress: false,
      };
    case GET_EMPLOYEE_ASSETS_DETAILS_EL:
      return {
        ...state,
        employeeAssetsElDetails: action.payload,
        queryInProgress: false,
      };
    case GET_EMPLOYEE_ASSETS_DETAILS_IT:
      return {
        ...state,
        employeeAssetsItDetails: action.payload,
        queryInProgress: false,
      };
    case GET_EMPLOYEE_ASSETS_DETAILS_PO:
      return {
        ...state,
        employeeAssetsPoDetails: action.payload,
        queryInProgress: false,
      };
    case GET_ASSET_DETAILS:
      return {
        ...state,
        assetDetails: action.payload,
        queryInProgress: false,
      };
    case GET_EMPLOYEE_ASSETS_BYSTATUS_AC:
      return {
        ...state,
        employeeAssetsByStatusAc: action.payload,
        queryFilteredAcInProgress: false,
      };
    case GET_EMPLOYEE_ASSETS_BYSTATUS_TR:
      return {
        ...state,
        employeeAssetsByStatusTr: action.payload,
        queryFilteredTrInProgress: false,
      };
    case GET_EMPLOYEE_ASSETS_BYSTATUS_SR:
      return {
        ...state,
        employeeAssetsByStatusSr: action.payload,
        queryFilteredSrInProgress: false,
      };
    case GET_EMPLOYEE_ASSETS_BYSTATUS_WR:
      return {
        ...state,
        employeeAssetsByStatusWr: action.payload,
        queryFilteredWrInProgress: false,
      };
    case GET_EMPLOYEE_ASSETS_BYNAME:
      return {
        ...state,
        employeeAssetsByName: action.payload,
        queryFilteredFiInProgress: false,
      };
    case PATCH_EMPLOYEE_ASSET:
      return {
        ...state,
        employeeAssetsByStatusAc: state.employeeAssetsByStatusAc.filter(
          (asset) => asset.pk !== action.payload.pk
        ),
      };
    case ASSET_RS_AL:
      if (action.mode === "u2u") {
        return {
          ...state,
          employeeAssetsAl: state.employeeAssetsAl.filter(
            (asset) => asset.pk !== action.payload.pk
          ),
          assetDetails: {},
        };
      } else if (action.mode === "w2u") {
        return {
          ...state,
          warAssetsAl: state.warAssetsAl.filter(
            (asset) => asset.pk !== action.payload.pk
          ),
          assetDetails: {},
        };
      } else if (action.mode === "u2w") {
        return {
          ...state,
          employeeAssetsAl: state.employeeAssetsAl.filter(
            (asset) => asset.pk !== action.payload.pk
          ),
          warAssetsAl: [...state.warAssetsAl, action.payload],
          assetDetails: {},
        };
      }
      break;
    case ASSET_RS_NA:
      if (action.mode === "u2u") {
        return {
          ...state,
          employeeAssetsNa: state.employeeAssetsNa.filter(
            (asset) => asset.pk !== action.payload.pk
          ),
          assetDetails: {},
        };
      } else if (action.mode === "w2u") {
        return {
          ...state,
          warAssetsNa: state.warAssetsNa.filter(
            (asset) => asset.pk !== action.payload.pk
          ),
          assetDetails: {},
        };
      } else if (action.mode === "u2w") {
        return {
          ...state,
          employeeAssetsNa: state.employeeAssetsNa.filter(
            (asset) => asset.pk !== action.payload.pk
          ),
          warAssetsNa: [...state.warAssetsNa, action.payload],
          assetDetails: {},
        };
      }
      break;
    case ASSET_RS_EL:
      if (action.mode === "u2u") {
        return {
          ...state,
          employeeAssetsEl: state.employeeAssetsEl.filter(
            (asset) => asset.pk !== action.payload.pk
          ),
          assetDetails: {},
        };
      } else if (action.mode === "w2u") {
        return {
          ...state,
          warAssetsEl: state.warAssetsEl.filter(
            (asset) => asset.pk !== action.payload.pk
          ),
          assetDetails: {},
        };
      } else if (action.mode === "u2w") {
        return {
          ...state,
          employeeAssetsEl: state.employeeAssetsEl.filter(
            (asset) => asset.pk !== action.payload.pk
          ),
          warAssetsEl: [...state.warAssetsEl, action.payload],
          assetDetails: {},
        };
      }
      break;
    case ASSET_RS_IT:
      if (action.mode === "u2u") {
        return {
          ...state,
          employeeAssetsIt: state.employeeAssetsIt.filter(
            (asset) => asset.pk !== action.payload.pk
          ),
          assetDetails: {},
        };
      } else if (action.mode === "w2u") {
        return {
          ...state,
          warAssetsIt: state.warAssetsIt.filter(
            (asset) => asset.pk !== action.payload.pk
          ),
          assetDetails: {},
        };
      } else if (action.mode === "u2w") {
        return {
          ...state,
          employeeAssetsIt: state.employeeAssetsIt.filter(
            (asset) => asset.pk !== action.payload.pk
          ),
          warAssetsIt: [...state.warAssetsIt, action.payload],
          assetDetails: {},
        };
      }
      break;
    case ASSET_RS_PO:
      if (action.mode === "u2u") {
        return {
          ...state,
          employeeAssetsPo: state.employeeAssetsPo.filter(
            (asset) => asset.pk !== action.payload.pk
          ),
          assetDetails: {},
        };
      } else if (action.mode === "w2u") {
        return {
          ...state,
          warAssetsPo: state.warAssetsPo.filter(
            (asset) => asset.pk !== action.payload.pk
          ),
          assetDetails: {},
        };
      } else if (action.mode === "u2w") {
        return {
          ...state,
          employeeAssetsPo: state.employeeAssetsPo.filter(
            (asset) => asset.pk !== action.payload.pk
          ),
          warAssetsPo: [...state.warAssetsPo, action.payload],
          assetDetails: {},
        };
      }
      break;
    case GET_MY_ASSETS_AL:
      return {
        ...state,
        myInvAl: [],
        myAssetsAl: action.payload,
        queryInProgressAl: false,
      };
    case GET_MY_ASSETS_NA:
      return {
        ...state,
        myInvNa: [],
        myAssetsNa: action.payload,
        queryInProgressNa: false,
      };
    case GET_MY_ASSETS_EL:
      return {
        ...state,
        myInvEl: [],
        myAssetsEl: action.payload,
        queryInProgressEl: false,
      };
    case GET_MY_ASSETS_IT:
      return {
        ...state,
        myInvIt: [],
        myAssetsIt: action.payload,
        queryInProgressIt: false,
      };
    case GET_MY_ASSETS_PO:
      return {
        ...state,
        myInvPo: [],
        myAssetsPo: action.payload,
        queryInProgressPo: false,
      };
    case GET_WAR_ASSETS_AL:
      return {
        ...state,
        warAssetsAl: action.payload,
        queryInProgressAl: false,
      };
    case GET_WAR_ASSETS_NA:
      return {
        ...state,
        warAssetsNa: action.payload,
        queryInProgressNa: false,
      };
    case GET_WAR_ASSETS_EL:
      return {
        ...state,
        warAssetsEl: action.payload,
        queryInProgressEl: false,
      };
    case GET_WAR_ASSETS_IT:
      return {
        ...state,
        warAssetsIt: action.payload,
        queryInProgressIt: false,
      };
    case GET_WAR_ASSETS_PO:
      return {
        ...state,
        warAssetsPo: action.payload,
        queryInProgressPo: false,
      };
    case GET_MY_ASSETS_BYSTATUS_SR:
      return {
        ...state,
        myAssetsByStatusSr: action.payload,
        queryMySrInProgress: false,
      };
    case GET_MY_ASSETS_BYSTATUS_WR:
      return {
        ...state,
        myAssetsByStatusWr: action.payload,
        queryMyWrInProgress: false,
      };
    case GET_MY_ASSETS_BYSTATUS_SD:
      return {
        ...state,
        myAssetsByStatusSd: action.payload,
        queryMySdInProgress: false,
      };
    case GET_MY_ASSETS_DETAILS:
      return {
        ...state,
        myAssetsDetails: action.payload,
        queryInProgress: false,
      };
    case A_QUERY_IN_PROGRESS:
      return {
        ...state,
        queryInProgress: true,
      };
    case A_QUERY_MY_SR_IN_PROGRESS:
      return {
        ...state,
        queryMySrInProgress: true,
      };
    case A_QUERY_MY_WR_IN_PROGRESS:
      return {
        ...state,
        queryMyWrInProgress: true,
      };
    case A_QUERY_MY_SD_IN_PROGRESS:
      return {
        ...state,
        queryMySdInProgress: true,
      };
    case A_QUERY_FILTEREDAC_IN_PROGRESS:
      return {
        ...state,
        queryFilteredAcInProgress: true,
      };
    case A_QUERY_FILTEREDTR_IN_PROGRESS:
      return {
        ...state,
        queryFilteredTrInProgress: true,
      };
    case A_QUERY_FILTEREDFI_IN_PROGRESS:
      return {
        ...state,
        queryFilteredFiInProgress: true,
      };
    case A_QUERY_FILTEREDSR_IN_PROGRESS:
      return {
        ...state,
        queryFilteredSrInProgress: true,
      };
    case A_QUERY_FILTEREDWR_IN_PROGRESS:
      return {
        ...state,
        queryFilteredWrInProgress: true,
      };
    case A_QUERY_AL_IN_PROGRESS:
      return {
        ...state,
        queryInProgressAl: true,
      };
    case A_QUERY_NA_IN_PROGRESS:
      return {
        ...state,
        queryInProgressNa: true,
      };
    case A_QUERY_EL_IN_PROGRESS:
      return {
        ...state,
        queryInProgressEl: true,
      };
    case A_QUERY_IT_IN_PROGRESS:
      return {
        ...state,
        queryInProgressIt: true,
      };
    case A_QUERY_PO_IN_PROGRESS:
      return {
        ...state,
        queryInProgressPo: true,
      };
    case A_QUERY_FAILED:
      return {
        ...state,
        queryInProgress: false,
      };
    case CLEAR_EMPLOYEE_ASSETS_STATE:
      return {
        ...state,
        employeeAssetsAl: [],
        employeeAssetsNa: [],
        employeeAssetsEl: [],
        employeeAssetsIt: [],
        employeeAssetsPo: [],
      };
    case CLEAR_EMPLOYEE_ASSETS_BYSTATUS:
      return {
        ...state,
        employeeAssetsByStatus: [],
      };
    case ADD_ASSET_TRANSFER_LOG:
      return {
        ...state,
        currentEquipmentTransferLog: action.payload.pk,
      };
    case PATCH_MY_ASSET_AL_RS:
      //znajdź index
      return {
        ...state,
        myAssetsAl: state.myAssetsAl.filter(
          (asset) => asset.pk !== action.payload.pk
        ),
      };
    case PATCH_MY_ASSET_NA_RS:
      //znajdź index
      return {
        ...state,
        myAssetsNa: state.myAssetsNa.filter(
          (asset) => asset.pk !== action.payload.pk
        ),
      };
    case PATCH_MY_ASSET_EL_RS:
      //znajdź index
      return {
        ...state,
        myAssetsEl: state.myAssetsEl.filter(
          (asset) => asset.pk !== action.payload.pk
        ),
      };
    case PATCH_MY_ASSET_IT_RS:
      //znajdź index
      return {
        ...state,
        myAssetsIt: state.myAssetsIt.filter(
          (asset) => asset.pk !== action.payload.pk
        ),
      };
    case PATCH_MY_ASSET_PO_RS:
      //znajdź index
      return {
        ...state,
        myAssetsPo: state.myAssetsPo.filter(
          (asset) => asset.pk !== action.payload.pk
        ),
      };
    case PATCH_MY_ASSET_AL:
      //znajdź index
      return {
        ...state,
        myAssetsAl: state.myAssetsAl.map((asset) =>
          asset.pk === action.payload.pk ? action.payload : asset
        ),
      };
    case PATCH_MY_ASSET_NA:
      return {
        ...state,
        myAssetsNa: state.myAssetsNa.map((asset) =>
          asset.pk === action.payload.pk ? action.payload : asset
        ),
      };
    case PATCH_MY_ASSET_EL:
      return {
        ...state,
        myAssetsEl: state.myAssetsEl.map((asset) =>
          asset.pk === action.payload.pk ? action.payload : asset
        ),
      };
    case PATCH_MY_ASSET_IT:
      return {
        ...state,
        myAssetsIt: state.myAssetsIt.map((asset) =>
          asset.pk === action.payload.pk ? action.payload : asset
        ),
      };
    case PATCH_MY_ASSET_PO:
      return {
        ...state,
        myAssetsPo: state.myAssetsPo.map((asset) =>
          asset.pk === action.payload.pk ? action.payload : asset
        ),
      };
    case CLEAR_CUR_EQ_TR_LOG:
      return {
        ...state,
        currentEquipmentTransferLog: null,
      };
    case PATCH_ASSET_TRANSFER_LOG:
      return {
        ...state,
      };
    case GET_EQMODELS_LIST:
      return {
        ...state,
        queryInProgress: false,
        equipmentModels: action.payload,
      };
    case CLEAR_EQMODELS_LIST:
      return {
        ...state,
        equipmentModels: [],
      };
    case GET_VENDORS_LIST:
      return {
        ...state,
        queryInProgress: false,
        vendorsList: action.payload,
      };
    case CLEAR_VENDORS_LIST:
      return {
        ...state,
        vendorsList: [],
      };
    case ADD_ASSET_AL:
      //znajdź index
      if (action.payload.status === "iu") {
        return {
          ...state,
          employeeAssetsAl: [...state.employeeAssetsAl, action.payload],
        };
      } else if (action.payload.status === "is") {
        return {
          ...state,
          warAssetsAl: [...state.warAssetsAl, action.payload],
        };
      }
      break;
    case ADD_ASSET_NA:
      //znajdź index
      if (action.payload.status === "iu") {
        return {
          ...state,
          employeeAssetsNa: [...state.employeeAssetsNa, action.payload],
        };
      } else if (action.payload.status === "is") {
        return {
          ...state,
          warAssetsNa: [...state.warAssetsNa, action.payload],
        };
      }
      break;
    case ADD_ASSET_EL:
      //znajdź index
      if (action.payload.status === "iu") {
        return {
          ...state,
          employeeAssetsEl: [...state.employeeAssetsEl, action.payload],
        };
      } else if (action.payload.status === "is") {
        return {
          ...state,
          warAssetsEl: [...state.warAssetsEl, action.payload],
        };
      }
      break;
    case ADD_ASSET_IT:
      //znajdź index
      if (action.payload.status === "iu") {
        return {
          ...state,
          employeeAssetsIt: [...state.employeeAssetsIt, action.payload],
        };
      } else if (action.payload.status === "is") {
        return {
          ...state,
          warAssetsIt: [...state.warAssetsIt, action.payload],
        };
      }
      break;
    case ADD_ASSET_PO:
      //znajdź index
      if (action.payload.status === "iu") {
        return {
          ...state,
          employeeAssetsPo: [...state.employeeAssetsPo, action.payload],
        };
      } else if (action.payload.status === "is") {
        return {
          ...state,
          warAssetsPo: [...state.warAssetsPo, action.payload],
        };
      }
      break;
    case ADD_ASSET_MODEL:
      //znajdź index
      return {
        ...state,
      };
    case ADD_VENDOR:
      //znajdź index
      return {
        ...state,
        vendorsList: [...state.vendorsList, action.payload],
      };
    //SCRAP
    case SCRAP_MY_ASSET_AL_REQ:
      //znajdź index
      return {
        ...state,
        myAssetsAl: state.myAssetsAl.map((asset) =>
          asset.pk === action.payload.pk ? action.payload : asset
        ),
        myAssetsByStatusSr: [...state.myAssetsByStatusSr, action.payload],
      };
    case SCRAP_MY_ASSET_NA_REQ:
      //znajdź index
      return {
        ...state,
        myAssetsNa: state.myAssetsNa.map((asset) =>
          asset.pk === action.payload.pk ? action.payload : asset
        ),
        myAssetsByStatusSr: [...state.myAssetsByStatusSr, action.payload],
      };
    case SCRAP_MY_ASSET_EL_REQ:
      //znajdź index
      return {
        ...state,
        myAssetsEl: state.myAssetsEl.map((asset) =>
          asset.pk === action.payload.pk ? action.payload : asset
        ),
        myAssetsByStatusSr: [...state.myAssetsByStatusSr, action.payload],
      };
    case SCRAP_MY_ASSET_IT_REQ:
      //znajdź index
      return {
        ...state,
        myAssetsIt: state.myAssetsIt.map((asset) =>
          asset.pk === action.payload.pk ? action.payload : asset
        ),
        myAssetsByStatusSr: [...state.myAssetsByStatusSr, action.payload],
      };
    case SCRAP_MY_ASSET_PO_REQ:
      //znajdź index
      return {
        ...state,
        myAssetsPo: state.myAssetsPo.map((asset) =>
          asset.pk === action.payload.pk ? action.payload : asset
        ),
        myAssetsByStatusSr: [...state.myAssetsByStatusSr, action.payload],
      };

    case SCRAP_MY_ASSET_AL_CAN:
      //znajdź index
      return {
        ...state,
        myAssetsAl: state.myAssetsAl.map((asset) =>
          asset.pk === action.payload.pk ? action.payload : asset
        ),
        myAssetsByStatusSr: state.myAssetsByStatusSr.filter(
          (asset) => asset.pk !== action.payload.pk
        ),
      };
    case SCRAP_MY_ASSET_NA_CAN:
      //znajdź index
      return {
        ...state,
        myAssetsNa: state.myAssetsNa.map((asset) =>
          asset.pk === action.payload.pk ? action.payload : asset
        ),
        myAssetsByStatusSr: state.myAssetsByStatusSr.filter(
          (asset) => asset.pk !== action.payload.pk
        ),
      };
    case SCRAP_MY_ASSET_EL_CAN:
      //znajdź index
      return {
        ...state,
        myAssetsEl: state.myAssetsEl.map((asset) =>
          asset.pk === action.payload.pk ? action.payload : asset
        ),
        myAssetsByStatusSr: state.myAssetsByStatusSr.filter(
          (asset) => asset.pk !== action.payload.pk
        ),
      };
    case SCRAP_MY_ASSET_IT_CAN:
      //znajdź index
      return {
        ...state,
        myAssetsIt: state.myAssetsIt.map((asset) =>
          asset.pk === action.payload.pk ? action.payload : asset
        ),
        myAssetsByStatusSr: state.myAssetsByStatusSr.filter(
          (asset) => asset.pk !== action.payload.pk
        ),
      };
    case SCRAP_MY_ASSET_PO_CAN:
      //znajdź index
      return {
        ...state,
        myAssetsPo: state.myAssetsPo.map((asset) =>
          asset.pk === action.payload.pk ? action.payload : asset
        ),
        myAssetsByStatusSr: state.myAssetsByStatusSr.filter(
          (asset) => asset.pk !== action.payload.pk
        ),
      };

    case SCRAP_MY_ASSET_AL_UPD:
      //znajdź index
      return {
        ...state,
        myAssetsAl: state.myAssetsAl.map((asset) =>
          asset.pk === action.payload.pk ? action.payload : asset
        ),
        myAssetsByStatusSr: state.myAssetsByStatusSr.map((asset) =>
          asset.pk === action.payload.pk ? action.payload : asset
        ),
      };
    case SCRAP_MY_ASSET_NA_UPD:
      //znajdź index
      return {
        ...state,
        myAssetsNa: state.myAssetsNa.map((asset) =>
          asset.pk === action.payload.pk ? action.payload : asset
        ),
        myAssetsByStatusSr: state.myAssetsByStatusSr.map((asset) =>
          asset.pk === action.payload.pk ? action.payload : asset
        ),
      };
    case SCRAP_MY_ASSET_EL_UPD:
      //znajdź index
      return {
        ...state,
        myAssetsEl: state.myAssetsEl.map((asset) =>
          asset.pk === action.payload.pk ? action.payload : asset
        ),
        myAssetsByStatusSr: state.myAssetsByStatusSr.map((asset) =>
          asset.pk === action.payload.pk ? action.payload : asset
        ),
      };
    case SCRAP_MY_ASSET_IT_UPD:
      //znajdź index
      return {
        ...state,
        myAssetsIt: state.myAssetsIt.map((asset) =>
          asset.pk === action.payload.pk ? action.payload : asset
        ),
        myAssetsByStatusSr: state.myAssetsByStatusSr.map((asset) =>
          asset.pk === action.payload.pk ? action.payload : asset
        ),
      };
    case SCRAP_MY_ASSET_PO_UPD:
      //znajdź index
      return {
        ...state,
        myAssetsPo: state.myAssetsPo.map((asset) =>
          asset.pk === action.payload.pk ? action.payload : asset
        ),
        myAssetsByStatusSr: state.myAssetsByStatusSr.map((asset) =>
          asset.pk === action.payload.pk ? action.payload : asset
        ),
      };
    case SCRAP_EMP_ASSET_SD:
      //znajdź index
      return {
        ...state,
        employeeAssetsByStatusSr: state.employeeAssetsByStatusSr.filter(
          (asset) => asset.pk !== action.payload.pk
        ),
        employeeAssetsByStatusWr: state.employeeAssetsByStatusWr.filter(
          (asset) => asset.pk !== action.payload.pk
        ),
      };
    case SCRAP_EMP_ASSET_WR:
      //znajdź index
      return {
        ...state,
        employeeAssetsByStatusWr: [
          ...state.employeeAssetsByStatusWr,
          action.payload,
        ],
        employeeAssetsByStatusSr: state.employeeAssetsByStatusSr.filter(
          (asset) => asset.pk !== action.payload.pk
        ),
      };
    case START_INVENTORY:
      return {
        ...state,
        activeInventory: action.payload.pk
      };
    case STOP_INVENTORY:
      return {
        ...state,
        activeInventory: null,
      };
    case GET_ACTIVE_INVENTORY:
      if (!action.payload.valid) {
        return {
          ...state,
          activeInventory: false,
        };
      }
      return {
        ...state,
        activeInventory: action.payload.id
      };
    case GET_INVENTORY:
      return {
        ...state,
        inventory: action.payload,
        queryInProgress: false,
      };
    case CLEAR_INVENTORY:
      return {
        ...state,
        inventory: []
      };
    case GET_MY_INV_AL:
      return {
        ...state,
        myInvAl: action.payload,
        myAssetsAl: [],
        queryInProgressAl: false,
      };
    case GET_MY_INV_NA:
      return {
        ...state,
        myInvNa: action.payload,
        myAssetsNa: [],
        queryInProgressNa: false,
      };
    case GET_MY_INV_EL:
      return {
        ...state,
        myInvEl: action.payload,
        myAssetsEl: [],
        queryInProgressEl: false,
      };
    case GET_MY_INV_IT:
      return {
        ...state,
        myInvIt: action.payload,
        myAssetsIt: [],
        queryInProgressIt: false,
      };
    case GET_MY_INV_PO:
      return {
        ...state,
        myInvPo: action.payload,
        myAssetsPo: [],
        queryInProgressPo: false,
      };
    case INV_QUERY_AL_IN_PROGRESS:
      return {
        ...state,
        queryInProgressAl: true,
      };
    case INV_QUERY_NA_IN_PROGRESS:
      return {
        ...state,
        queryInProgressNa: true,
      };
    case INV_QUERY_EL_IN_PROGRESS:
      return {
        ...state,
        queryInProgressEl: true,
      };
    case INV_QUERY_IT_IN_PROGRESS:
      return {
        ...state,
        queryInProgressIt: true,
      };
    case INV_QUERY_PO_IN_PROGRESS:
      return {
        ...state,
        queryInProgressPo: true,
      };
    case GET_MY_INV_DETAILS:
      return {
        ...state,
        myInventoryDetails: action.payload,
        queryInProgress: false,
      };
    case UPDATE_MY_INV_DETAILS:
      if (action.payload.eqtype == "al") {
        return {
          ...state,
          myInvAl: state.myInvAl.map((asset) => asset.pk == action.payload.pk ? action.payload : asset),
          myInventoryDetails: action.payload,
        };
      }
      else if (action.payload.eqtype == "na") {
        return {
          ...state,
          myInvNa: state.myInvNa.map((asset) => asset.pk == action.payload.pk ? action.payload : asset),
          myInventoryDetails: action.payload,
        };
      }
      else if (action.payload.eqtype == "el") {
        return {
          ...state,
          myInvEl: state.myInvEl.map((asset) => asset.pk == action.payload.pk ? action.payload : asset),
          myInventoryDetails: action.payload,
        };
      }
      else if (action.payload.eqtype == "it") {
        return {
          ...state,
          myInvIt: state.myInvIt.map((asset) => asset.pk == action.payload.pk ? action.payload : asset),
          myInventoryDetails: action.payload,
        };
      }
      else if (action.payload.eqtype == "po") {
        return {
          ...state,
          myInvPo: state.myInvPo.map((asset) => asset.pk == action.payload.pk ? action.payload : asset),
          myInventoryDetails: action.payload,
        };
      }
    case UPDATE_EMP_INV_DETAILS:
      if (action.payload.eqtype == "al") {
        return {
          ...state,
          employeeInventoryAl: state.employeeInventoryAl.map((asset) => asset.pk == action.payload.pk ? action.payload : asset),
          myInventoryDetails: action.payload,
        };
      }
      else if (action.payload.eqtype == "na") {
        return {
          ...state,
          employeeInventoryNa: state.employeeInventoryNa.map((asset) => asset.pk == action.payload.pk ? action.payload : asset),
          myInventoryDetails: action.payload,
        };
      }
      else if (action.payload.eqtype == "el") {
        return {
          ...state,
          employeeInventoryEl: state.employeeInventoryEl.map((asset) => asset.pk == action.payload.pk ? action.payload : asset),
          myInventoryDetails: action.payload,
        };
      }
      else if (action.payload.eqtype == "it") {
        return {
          ...state,
          employeeInventoryIt: state.employeeInventoryIt.map((asset) => asset.pk == action.payload.pk ? action.payload : asset),
          myInventoryDetails: action.payload,
        };
      }
      else if (action.payload.eqtype == "po") {
        return {
          ...state,
          employeeInventoryPo: state.employeeInventoryPo.map((asset) => asset.pk == action.payload.pk ? action.payload : asset),
          myInventoryDetails: action.payload,
        };
      }
    case DELETE_MY_INV_DETAILS:
      if (action.payload.eqtype == "al") {
        return {
          ...state,
          myInvAl: state.myInvAl.filter((asset) => asset.pk != action.payload.pk),
          myInventoryDetails: {},
        };
      }
      else if (action.payload.eqtype == "na") {
        return {
          ...state,
          myInvNa: state.myInvNa.filter((asset) => asset.pk != action.payload.pk),
          myInventoryDetails: {},
        };
      }
      else if (action.payload.eqtype == "el") {
        return {
          ...state,
          myInvEl: state.myInvEl.filter((asset) => asset.pk != action.payload.pk),
          myInventoryDetails: {}
        };
      }
      else if (action.payload.eqtype == "it") {
        return {
          ...state,
          myInvIt: state.myInvIt.filter((asset) => asset.pk != action.payload.pk),
          myInventoryDetails: {},
        };
      }
      else if (action.payload.eqtype == "po") {
        return {
          ...state,
          myInvPo: state.myInvPo.filter((asset) => asset.pk != action.payload.pk),
          myInventoryDetails: {},
        };
      }
    case ADD_MY_INV_DETAILS:
      if (action.payload.eqtype == "al") {
        return {
          ...state,
          myInvAl: [...state.myInvAl, action.payload],
          myInventoryDetails: action.payload,
        };
      }
      else if (action.payload.eqtype == "na") {
        return {
          ...state,
          myInvNa: [...state.myInvNa, action.payload],
          myInventoryDetails: action.payload,
        };
      }
      else if (action.payload.eqtype == "el") {
        return {
          ...state,
          myInvEl: [...state.myInvEl, action.payload],
          myInventoryDetails: action.payload,
        };
      }
      else if (action.payload.eqtype == "it") {
        return {
          ...state,
          myInvIt: [...state.myInvIt, action.payload],
          myInventoryDetails: action.payload,
        };
      }
      else if (action.payload.eqtype == "po") {
        return {
          ...state,
          myInvPo: [...state.myInvPo, action.payload],
          myInventoryDetails: action.payload,
        };
      }
    case REPORT_INVENTORY:
      const { pk, status } = action.payload;
      var invKey = '';
      var invItem = null;

      ['IP', 'RE', 'BH', 'MA', 'FI'].forEach((key) => {
        const inv = state[`inventory${key}`];
        const invIndex = inv.findIndex((item) => item.pk === pk);
        if (invIndex !== -1) {
          invItem = inv[invIndex];
          invKey = key;
          state[`inventory${key}`] = [...inv.slice(0, invIndex), ...inv.slice(invIndex + 1)];
        }
      });

      if (invItem) {
        invItem.status = status;
        invItem.inventoryEndDate = action.payload.inventoryEndDate;
        invItem.status_description = action.payload.status_description;
        invItem.signedUser = action.payload.signedUser;
        invItem.signedUserDate = action.payload.signedUserDate;
        invItem.signedBHP = action.payload.signedBHP;
        invItem.signedBHPDate = action.payload.signedBHPDate;
        invItem.signedManager = action.payload.signedManager;
        invItem.signedManagerDate = action.payload.signedManagerDate;
        invItem.signedController = action.payload.signedController;
        invItem.signedControllerDate = action.payload.signedControllerDate;

        const newKey = status.toUpperCase();
        state[`inventory${newKey}`] = [...state[`inventory${newKey}`], invItem];
      }

      return {
        ...state,
        activeInventory: null,
        employeeInventoryAl: [],
        employeeInventoryNa: [],
        employeeInventoryEl: [],
        employeeInventoryIt: [],
        employeeInventoryPo: [],
      };
    case CLEAR_ASSETS_DETAILS:
      return {
        ...state,
        assetDetails: {},
      };
    case GET_INVENTORY_IP:
      return {
        ...state,
        inventoryIP: action.payload,
        queryInProgress: false,
      };
    case GET_INVENTORY_RE:
      return {
        ...state,
        inventoryRE: action.payload,
        queryInProgress: false,
      };
    case GET_INVENTORY_BH:
      return {
        ...state,
        inventoryBH: action.payload,
        queryInProgress: false,
      };
    case GET_INVENTORY_MA:
      return {
        ...state,
        inventoryMA: action.payload,
        queryInProgress: false,
      };
    case GET_INVENTORY_FI:
      return {
        ...state,
        inventoryFI: action.payload,
        queryInProgress: false,
      };
    case GET_EMPLOYEE_INV_ASSETS_AL:
      return {
        ...state,
        employeeInventoryAl: action.payload,
        queryInProgressAl: false,
      };
    case GET_EMPLOYEE_INV_ASSETS_NA:
      return {
        ...state,
        employeeInventoryNa: action.payload,
        queryInProgressNa: false,
      };
    case GET_EMPLOYEE_INV_ASSETS_EL:
      return {
        ...state,
        employeeInventoryEl: action.payload,
        queryInProgressEl: false,
      };
    case GET_EMPLOYEE_INV_ASSETS_IT:
      return {
        ...state,
        employeeInventoryIt: action.payload,
        queryInProgressIt: false,
      };
    case GET_EMPLOYEE_INV_ASSETS_PO:
      return {
        ...state,
        employeeInventoryPo: action.payload,
        queryInProgressPo: false,
      };
    case GET_INVENTORY_ASSETS:
      return {
        ...state,
        inventoryAssets: action.payload,
        raportQueryInProgress: false,
      };
    case CLEAR_INVENTORY_ASSETS:
      return {
        ...state,
        inventoryAssets: [],
        inventoryDetails: [],
      };
    case CLEAR_INVENTORY_EMP_ASSETS:
      return {
        ...state,
        employeeInventoryAl: [],
        employeeInventoryNa: [],
        employeeInventoryEl: [],
        employeeInventoryIt: [],
        employeeInventoryPo: [],
      };
    case CLEAR_EMP_INVENTORIES:
      return {
        ...state,
        inventoryIP: [],
        inventoryRE: [],
        inventoryBH: [],
        inventoryMA: [],
        inventoryFI: [],
      };
    case RAPORT_QUERY_IN_PROGRESS:
      return {
        ...state,
        raportQueryInProgress: true,
      };
    case RAPORT_QUERY_IN_PROGRESS2:
      return {
        ...state,
        raportQueryInProgress2: true,
      };
    case GET_INVENTORY_DETAILS:
      return {
        ...state,
        inventoryDetails: action.payload,
        raportQueryInProgress2: false,
      };
    default:
      return state;
  }
}
