import {
  GET_EMPLOYEE_LEAVES_ONLEAVE,
  GET_EMPLOYEE_LEAVES_ACCEPT,
  H_QUERY_FAILED,
  H_QUERY_IN_PROGRESS,
  GET_EMPLOYEE_LEAVES_SEARCH,
  GET_EMPLOYEE_LEAVES_PLANNED,
  GET_EMPLOYEE_LEAVES_DETAILS,
  CLEAR_EMPLOYEE_LEAVES_DETAILS,
  PATCH_EMPLOYEE_LEAVE,
  ADD_EMPLOYEE_LEAVE
} from "../actions/types";

const initialState = {
  employeeLeavesOnLeave: [],
  employeeLeavesCA: [],
  employeeLeavesPlanned: [],
  employeeLeavesSEARCH: [],
  employeeLeavesDetails: {},
  queryInProgress: false
};

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_EMPLOYEE_LEAVES_ONLEAVE:
      return {
        ...state,
        employeeLeavesOnLeave: action.payload
      };
    case GET_EMPLOYEE_LEAVES_ACCEPT:
      return {
        ...state,
        employeeLeavesCA: action.payload
      };
    case GET_EMPLOYEE_LEAVES_PLANNED:
      return {
        ...state,
        employeeLeavesPlanned: action.payload
      };
    case H_QUERY_IN_PROGRESS:
      return {
        ...state,
        queryInProgress: true
      };
    case H_QUERY_FAILED:
      return {
        ...state,
        queryInProgress: false
      };
    case GET_EMPLOYEE_LEAVES_SEARCH:
      return {
        ...state,
        queryInProgress: false,
        employeeLeavesSEARCH: action.payload
      };
    case GET_EMPLOYEE_LEAVES_DETAILS:
      return {
        ...state,
        employeeLeavesDetails: action.payload
      };
    case CLEAR_EMPLOYEE_LEAVES_DETAILS:
      return {
        ...state,
        employeeLeavesDetails: {}
      };
    case PATCH_EMPLOYEE_LEAVE:
      return {
        ...state,
        employeeLeavesDetails: action.payload
      };
    case ADD_EMPLOYEE_LEAVE:
      return {
        ...state
      };
    default:
      return state;
  }
}
