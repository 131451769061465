import axios from "axios";
import { createMessages, returnErrors } from "./messages";
import { tokenConfig } from "./auth";
import { serverurl, getDatabaseUrl } from "./databaseServerURL";

import {
  GET_ROOM_EVENTS,
  DELETE_ROOM_EVENT,
  ADD_ROOM_EVENT,
  UPDATE_ROOM_EVENT,
  CLEAR_ROOM_EVENTS,
} from "./types";

//GET
export const getRoomEvents = () => (dispatch, getState) => {
  axios
    .get(serverurl + "/roomEvent", tokenConfig(getState))
    .then(res => {
      dispatch({
        type: GET_ROOM_EVENTS,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch(returnErrors(err.response.data.detail, err.response.status))
    );
};

export const clearRoomEvents = () => (dispatch, getState) => {
  dispatch({ type: CLEAR_ROOM_EVENTS });
};

//DELETE
export const deleteRoomEvent = id => (dispatch, getState) => {
  axios
    .delete(serverurl + `/roomEvent/${id}`, tokenConfig(getState))
    .then(res => {
      dispatch(createMessages({ deleteProject: "Wpis został usunięty" }));
      dispatch({
        type: DELETE_ROOM_EVENT,
        payload: id
      });
    })
    .catch(err =>
      dispatch(returnErrors(err.response.data.detail, err.response.status))
    );
};

//ADD
export const addRoomEvent = body => (dispatch, getState) => {
  axios
    .post(serverurl + "/roomEvent", body, tokenConfig(getState))
    .then(res => {
      dispatch(createMessages({ addProject: "Wpis został dodany" }));
      dispatch({
        type: ADD_ROOM_EVENT,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch(returnErrors(err.response.data.detail, err.response.status))
    );
};

//UPDATE
export const updateRoomEvent = (body, pk) => (dispatch, getState) => {
  axios
    .put(serverurl + `/roomEvent/${pk}`, body, tokenConfig(getState))
    .then(res => {
      dispatch(createMessages({ patchProject: "Wpis został zapisany" }));
      dispatch({
        type: UPDATE_ROOM_EVENT,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch(returnErrors(err.response.data.detail, err.response.status))
    );
};
