import React, { Component, Fragment } from "react";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import { createMuiTheme } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";
import FormLabel from "@material-ui/core/FormLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import Checkbox from "@material-ui/core/Checkbox";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

import { connect } from "react-redux";
import { getProjects, clearProjects } from "../Redux/actions/project.js"
import PropTypes from "prop-types";
import ListItemText from "@material-ui/core/ListItemText";

const CssTextField = withStyles({
  root: {
    width: 300,
    flexWrap: "wrap",
    marginTop: "50px",
    marginLeft: "30px",
    marginRight: "30px",
    "& label.Mui-focused": {
      color: "#0071bc"
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#0071bc"
    }
  }
})(TextField);

const materialTheme = createMuiTheme({
  overrides: {
    MuiFormControl: {
      root: {
        width: 300,
        marginTop: 50
      },
      marginNormal: {
        marginTop: "50px"
      }
    },
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: "#0071BC"
      }
    },
    MuiButton: {
      root: {},
      textPrimary: {
        color: "#0071BC"
      },
      containedPrimary: {
        backgroundColor: "#1BAB7F",
        "&:hover": {
          backgroundColor: "#188E6A"
        }
      }
    },
    MuiFormLabel: {
      root: {
        MuiFocused: {
          color: "0071BC"
        }
      }
    },
    MuiPickersDay: {
      day: {
        color: "#0071BC"
      },
      daySelected: {
        backgroundColor: "#0071BC",
        "&:hover": {
          backgroundColor: "#0071BC"
        }
      },

      dayDisabled: {
        color: "gray"
      },
      current: {
        color: "#0071BC"
      }
    },
    MuiInput: {
      underline: {
        "&:after": {
          borderBottomColor: "#0071bc"
        }
      }
    },
    MuiInputBase: {
      root: {
        textAlign: "left"
      }
    },
    MuiCheckbox: {
      colorPrimary: {
        "&.Mui-checked": {
          color: "#0071bc"
        }
      }
    },
    MuiFormGroup: {
      root: {
        marginTop: "10px"
      }
    }
  }
});

export class ReadOnly extends Component {
  state = {
    place: null,
    country: null,
    project: null,
    tVehicle: undefined,
    tTrain: undefined,
    tPlane: undefined,
    tShip: undefined,
    isAccommodationNeeded: undefined,
    remarks: null,
    startDate: null,
    endDate: null,
    firstName: "",
    surname: ""
  };

  static propTypes = {
    getProjects: PropTypes.func.isRequired,
    clearProjects: PropTypes.func.isRequired,
  }

  componentWillUnmount() {
    this.props.clearProjects();
  }

  componentDidMount() {
    this.props.getProjects();
  }

  componentDidUpdate() {
    if (this.props.saveTrigger === true) {
      //tutaj muszę daty jeszcze raz obrobić jeżeli trzeba

      const endDate = this.state.endDate;
      this.props.saveEndDate(endDate);
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (props.tripDetails.employee !== undefined && state.place === null) {
      return {
        place: props.tripDetails.place,
        country: props.tripDetails.country,
        project: props.tripDetails.project,
        tVehicle: props.tripDetails.tVehicle,
        tTrain: props.tripDetails.tTrain,
        tPlane: props.tripDetails.tPlane,
        tShip: props.tripDetails.tShip,
        isAccommodationNeeded: props.tripDetails.isAccommodationNeeded,
        remarks: props.tripDetails.remarks,
        startDate: props.tripDetails.startDate,
        endDate: props.tripDetails.endDate,
        firstName: props.tripDetails.employee.first_name,
        surname: props.tripDetails.employee.last_name
      };
    } else {
      return null;
    }
  }

  handleEndDate = date => {
    if (date !== null) {
      const dd = date.getDate();
      const mm = date.getMonth() + 1;
      const yyyy = date.getFullYear();
      const fullDate = yyyy + "-" + mm + "-" + dd;
      this.setState({
        endDate: fullDate
      });
    } else {
      this.setState({
        endDate: null
      });
    }
  };

  render() {
    const {
      place,
      country,
      project,
      tPlane,
      tTrain,
      tShip,
      tVehicle,
      isAccommodationNeeded,
      remarks,
      startDate,
      endDate,
      firstName,
      surname
    } = this.state;

    const { editFlag } = this.props;

    var project_id = [];
    if (project != null) {
      project_id = project.map((proj) => proj.pk);
    }

    const CallendarsRO = (
      <Fragment>
        <MuiPickersUtilsProvider
          utils={DateFnsUtils}
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <ThemeProvider theme={materialTheme}>
            <KeyboardDatePicker
              margin="normal"
              label="Data wyjazdu"
              format="yyyy/MM/dd"
              value={startDate}
              disabled
            />
          </ThemeProvider>
          <ThemeProvider theme={materialTheme}>
            <KeyboardDatePicker
              margin="normal"
              label="Data powrotu"
              format="yyyy/MM/dd"
              emptyLabel=""
              value={endDate}
              disabled
            />
          </ThemeProvider>
        </MuiPickersUtilsProvider>
      </Fragment>
    );

    const CallendarsRW = (
      <Fragment>
        <MuiPickersUtilsProvider
          utils={DateFnsUtils}
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <ThemeProvider theme={materialTheme}>
            <KeyboardDatePicker
              margin="normal"
              label="Data wyjazdu"
              format="yyyy/MM/dd"
              value={startDate}
              disabled
            />
          </ThemeProvider>
          <ThemeProvider theme={materialTheme}>
            <KeyboardDatePicker
              margin="normal"
              label="Data powrotu"
              format="yyyy/MM/dd"
              emptyLabel=""
              value={endDate}
              onChange={this.handleEndDate}
            />
          </ThemeProvider>
        </MuiPickersUtilsProvider>
      </Fragment>
    );

    return (
      <Fragment>
        <form
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <CssTextField
            required
            multiline
            rowsMax="3"
            label="Miejsce/Obszar"
            inputProps={{ maxLength: 100 }}
            margin="normal"
            value={place || ""}
            InputProps={{
              readOnly: true
            }}
          />
          {editFlag ? CallendarsRW : CallendarsRO}
          <ThemeProvider theme={materialTheme}>
            <FormControl required>
              <InputLabel htmlFor="country">Kraj</InputLabel>
              <Select
                name="country"
                value={country || ""}
                inputProps={{
                  name: "name",
                  id: "name-readonly",
                  readOnly: true
                }}
              >
                <MenuItem value="DK">Dania</MenuItem>
                <MenuItem value="FI">Finlandia</MenuItem>
                <MenuItem value="DE">Niemcy</MenuItem>
                <MenuItem value="PL">Polska</MenuItem>
                <MenuItem value="SE">Szwecja</MenuItem>
                <MenuItem value="NL">Holandia</MenuItem>
              </Select>
            </FormControl>
            <FormControl required>
              <InputLabel htmlFor="project">
                Projekt
              </InputLabel>
              <Select
                name="project"
                multiple
                inputProps={{
                  name: "name",
                  id: "name-readonly",
                  readOnly: true
                }}
                value={project_id}
                renderValue={(selected) => selected.map((id) => {
                  const project = this.props.projects.find((p) => p.pk === id);
                  return project ? project.name : '';
                }).join(', ')}
              >
                {this.props.projects.map((item) => {
                  return (
                    <MenuItem key={item.pk} value={item.pk}>
                      <ListItemText primary={item.name} />
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <FormControl required component="fieldset">
              <FormLabel component="legend">Transport</FormLabel>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={Boolean(tPlane)}
                      value={Boolean(tPlane)}
                      disabled
                    />
                  }
                  label="Samolot"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={Boolean(tVehicle)}
                      value={tVehicle}
                      disabled
                    />
                  }
                  label="Samochód"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={Boolean(tTrain)}
                      value={tTrain}
                      disabled
                    />
                  }
                  label="Pociąg"
                />
                <FormControlLabel
                  control={
                    <Checkbox checked={Boolean(tShip)} value={tShip} disabled />
                  }
                  label="Prom"
                />
              </FormGroup>
              <FormHelperText>
                Zaznacz przynajmniej jedną z opcji
              </FormHelperText>
            </FormControl>
            <FormControl>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={Boolean(isAccommodationNeeded)}
                      value={isAccommodationNeeded}
                      disabled
                    />
                  }
                  label="Zakwaterowanie"
                />
              </FormGroup>
            </FormControl>
          </ThemeProvider>
          <CssTextField
            required
            label="Opis delegacji"
            multiline
            rows="4"
            margin="normal"
            value={remarks || ""}
            InputProps={{
              readOnly: true
            }}
          />
          <CssTextField
            required
            label="Pracownik"
            margin="normal"
            value={firstName + " " + surname || ""}
            InputProps={{
              readOnly: true
            }}
          />
          <div style={{ marginBottom: 50 }}></div>
        </form>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  projects: state.project.projects,
});

export default connect(
  mapStateToProps,
  {
    getProjects, clearProjects
  }
)(ReadOnly);