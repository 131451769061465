import {
  GET_EVENTS,
  GET_ROOM_EVENTS,
  GET_EVENT_TYPES,
  DELETE_EVENT,
  DELETE_ROOM_EVENT,
  ADD_EVENT,
  ADD_ROOM_EVENT,
  UPDATE_EVENT,
  CLEAR_EVENTS,
  CLEAR_ROOM_EVENTS,
  CLEAR_EVENT_TYPES,
  GET_EVENT_RESOURCES,
  CLEAR_EVENT_RESOURCES,
} from "../actions/types";

const initialState = {
  events: [],
  types: [],
  resources: [],
  roomEvents: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_EVENTS:
      return {
        ...state,
        events: action.payload,
      };
    case GET_ROOM_EVENTS:
      return {
        ...state,
        roomEvents: action.payload,
      };
    case GET_EVENT_TYPES:
      return {
        ...state,
        types: action.payload,
      };
    case GET_EVENT_RESOURCES:
      return {
        ...state,
        resources: action.payload,
      };
    case CLEAR_EVENTS:
      return {
        ...state,
        events: [],
      }
    case CLEAR_ROOM_EVENTS:
      return {
        ...state,
        roomEvents: [],
      }
    case CLEAR_EVENT_TYPES:
      return {
        ...state,
        types: [],
      }
    case CLEAR_EVENT_RESOURCES:
      return {
        ...state,
        resources: [],
      }
    case DELETE_EVENT:
      return {
        ...state,
        events: state.events.filter(event => event.pk !== action.payload)
      }
    case DELETE_ROOM_EVENT:
      return {
        ...state,
        roomEvents: state.roomEvents.filter(roomEvent => roomEvent.pk !== action.payload)
      }
    case ADD_EVENT:
      return {
        ...state,
        events: [...state.events, action.payload]
      };
    case ADD_ROOM_EVENT:
      return {
        ...state,
        roomEvents: [...state.roomEvents, action.payload]
      };
    default:
      return state;
  }
}
