import React, { Component, Fragment } from "react";
import "./Style.css";

export class SessionExpired extends Component {
    render() {
        return (
            <Fragment>
                <div className="Title">
                    <b>Error 401</b>:<p>Wygląda na to że twoja sesja wygasła. Zaloguj się ponownie, aby korzystać z aplikacji.</p>
                </div>
            </Fragment>
        );
    }
}

export default SessionExpired;
