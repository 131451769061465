import axios from "axios";
import { createMessages, returnErrors } from "./messages";
import { tokenConfig } from "./auth";
import { serverurl, getDatabaseUrl } from "./databaseServerURL";

import {
  GET_DOCUMENTS,
  GET_DOCUMENT_DETAIL,
  DELETE_DOCUMENT,
  ADD_DOCUMENT,
  UPDATE_DOCUMENT,
  PATCH_DOCUMENT,
  GET_COMPANIES
} from "./types";

//GET COMPANIES
export const getCompanies = () => (dispatch, getState) => {
  axios
    .get(serverurl + "/document/company", tokenConfig(getState))
    .then(res => {
      dispatch({
        type: GET_COMPANIES,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch(returnErrors(err.response.data.detail, err.response.status))
    );
};

//GET DOCUMENTS
export const getDocuments = (type) => (dispatch, getState) => {
  console.log(type)
  axios
    .get(serverurl + "/document/list" + (type ? "?type=" + type : ""), tokenConfig(getState))
    .then(res => {
      dispatch({
        type: GET_DOCUMENTS,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch(returnErrors(err.response.data.detail, err.response.status))
    );
};

//DELETE DOCUMENT
export const deleteDocument = id => (dispatch, getState) => {
  axios
    .delete(serverurl + `/document/${id}`, tokenConfig(getState))
    .then(res => {
      dispatch(createMessages({ deleteDocument: "Dokument został usunięty" }));
      dispatch({
        type: DELETE_DOCUMENT,
        payload: id
      });
    })
    .catch(err =>
      dispatch(returnErrors(err.response.data.detail, err.response.status))
    );
};

//ADD DOCUMENT
export const addDocument = trip => (dispatch, getState) => {
  axios
    .post(serverurl + "/document", trip, tokenConfig(getState))
    .then(res => {
      dispatch(createMessages({ addDocument: "Dokument został dodany" }));
      dispatch({
        type: ADD_DOCUMENT,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch(returnErrors(err.response.data.detail, err.response.status))
    );
};

//PATCH DOCUMENT
export const patchDocument = (trip, pk, message) => (dispatch, getState) => {
  axios
    .patch(serverurl + `/document/${pk}`, trip, tokenConfig(getState))
    .then(res => {
      dispatch(createMessages({ patchDocument: message }));
      dispatch({
        type: PATCH_DOCUMENT,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch(returnErrors(err.response.data.detail, err.response.status))
    );
};

//UPDATE DOCUMENT
export const updateDocument = (trip, pk) => (dispatch, getState) => {
  axios
    .put(serverurl + `/document/${pk}`, trip, tokenConfig(getState))
    .then(res => {
      dispatch(createMessages({ patchDocument: "Dokument został zapisany" }));
      dispatch({
        type: UPDATE_DOCUMENT,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch(returnErrors(err.response.data.detail, err.response.status))
    );
};
