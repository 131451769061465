import React, { Component, Fragment } from "react";
import "./Style.css";

export class NoMatch extends Component {
  render() {
    return (
      <Fragment>
        <div className="Title">
          <b>Error 404</b>:<p>brak uprawnień do wyświetlenia żądanej strony</p>
        </div>
      </Fragment>
    );
  }
}

export default NoMatch;
