import {
  SEND_CODE,
  VERIFY_CODE,
  CLEAR_CODE,
} from "../actions/types";

const initialState = {
  valid: false,
  message: [],
  inProgress: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SEND_CODE:
      return {
        ...state,
        inProgress: true,
      };
    case VERIFY_CODE:
      var inP = true;
      if (action.payload.valid) inP = false;
      return {
        ...state,
        valid: action.payload.valid,
        message: action.payload.message,
        inProgress: inP,
      };
    case CLEAR_CODE:
      return {
        ...state,
        valid: false,
        message: [],
        inProgress: false,
      };
    default:
      return state;
  }
}
