import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Employee from "./Employee.js";
import EmployeesBook from "./EmployeesBook.js";
import EmployeesDetail from "./EmployeesDetail.js";
import { connect } from "react-redux";
import { patchProfile } from "../Redux/actions/auth";
import { TabPanel } from "../common/TabPanel.js";

export class EmployeeViewer extends Component {
  state = {
    value: null,
  };

  static propTypes = {
    auth: PropTypes.object.isRequired,
    patchProfile: PropTypes.func.isRequired,
  };

  static getDerivedStateFromProps(props, state) {
    if (props.auth.user !== null && state.value === null) {
      return {
        value: props.auth.main_profile.employeeDefaultTab,
      };
    } else {
      return null;
    }
  }

  handleChange = (event, newValue) => {
    this.setState({
      value: newValue,
    });
    const profileViewBody = {
      employeeDefaultTab: newValue,
    };

    this.props.patchProfile(profileViewBody, this.props.auth.main_profile.pk);
  };

  render() {
    const { value } = this.state;
    const { module_employee, module_employee_admin } = this.props.auth.permissions;
    const isSuperUser = this.props.auth.user.is_superuser;

    var subViewsConfig;
    if (module_employee && !module_employee_admin) {
      subViewsConfig = (
        <Fragment>
          <Tabs
            value={value}
            onChange={this.handleChange}
            aria-label="simple tabs example"
          >
            <Tab label="Moje dane" />
            <Tab label="Książka adresowa" />
          </Tabs>
          <TabPanel value={value} index={0}>
            <Employee />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <EmployeesBook />
          </TabPanel>
        </Fragment>
      );
    } else if ((module_employee && module_employee_admin) || isSuperUser) {
      subViewsConfig = (
        <Fragment>
          <Tabs
            value={value}
            onChange={this.handleChange}
            aria-label="simple tabs example"
          >
            <Tab label="Moje dane" />
            <Tab label="Książka adresowa" />
            <Tab label="Pracownicy" />
          </Tabs>
          <TabPanel value={value} index={0}>
            <Employee />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <EmployeesBook />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <EmployeesDetail />
          </TabPanel>
        </Fragment>
      );
    }

    return <div>{subViewsConfig}</div>;
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(
  mapStateToProps,
  { patchProfile }
)(EmployeeViewer);
