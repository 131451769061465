import React, { Fragment, Component } from "react";
import "../Style.css";
import Container from "@material-ui/core/Container";
import { serverurl } from "../Redux/actions/databaseServerURL";

import {
  withStyles,
  makeStyles,
  createStyles,
  Theme
} from "@material-ui/core/styles";

import TextField from "@material-ui/core/TextField";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from "@material-ui/pickers";

import { createMuiTheme } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";
import Checkbox from "@material-ui/core/Checkbox";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Button from "@material-ui/core/Button";
import axios from "axios";
import ListItemText from "@material-ui/core/ListItemText";

//========================================Style dla pól tekstowych=============================================================
const CssTextField = withStyles({
  root: {
    width: 300,
    flexWrap: "wrap",
    marginTop: "50px",
    marginLeft: "30px",
    marginRight: "30px",
    "& label.Mui-focused": {
      color: "#0071bc"
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#0071bc"
    }
  }
})(TextField);

//========================================Style dla pól wyboru dat=======================================================================

const materialTheme = createMuiTheme({
  overrides: {
    MuiFormControl: {
      root: {
        width: 300,
        marginTop: 50
      },
      marginNormal: {
        marginTop: "50px"
      }
    },
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: "#0071BC"
      }
    },
    MuiButton: {
      root: {
        marginTop: 80,
        marginBottom: 50
      },
      textPrimary: {
        color: "#0071BC"
      },
      containedPrimary: {
        backgroundColor: "#1BAB7F",
        "&:hover": {
          backgroundColor: "#188E6A"
        }
      }
    },
    MuiFormLabel: {
      root: {
        MuiFocused: {
          color: "0071BC"
        }
      }
    },
    MuiPickersDay: {
      day: {
        color: "#0071BC"
      },
      daySelected: {
        backgroundColor: "#0071BC",
        "&:hover": {
          backgroundColor: "#0071BC"
        }
      },

      dayDisabled: {
        color: "gray"
      },
      current: {
        color: "#0071BC"
      }
    },
    MuiInput: {
      underline: {
        "&:after": {
          borderBottomColor: "#0071bc"
        }
      }
    },
    MuiCheckbox: {
      colorPrimary: {
        "&.Mui-checked": {
          color: "#0071bc"
        }
      }
    },
    MuiFormGroup: {
      root: {
        marginTop: "50px"
      }
    }
  }
});

//========================Pozostałe style==================================================================================
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexWrap: "wrap"
    },
    container: {
      display: "flex",
      flexWrap: "wrap"
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: 300
    },
    dense: {
      marginTop: 19
    },
    menu: {
      width: 300
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 300,
      maxWidth: 300,
      marginTop: "50px",
      textAlign: "left"
    },
    selectEmpty: {
      marginTop: theme.spacing(2)
    },
    chips: {
      display: "flex",
      flexWrap: "wrap"
    },
    chip: {
      margin: 2
    },
    noLabel: {
      marginTop: theme.spacing(3)
    }
  })
);

//==================================================================================================
//==================================================================================================
export default class TripForm extends Component {
  state = {
    startDate: new Date(),
    endDate: new Date(),
    country: "",
    project: "",
    transport: [],
    tripStatus: "",
    place: "",
    acommodations: "",
    employeeId: "",
    tripIdentifier: "",
    tripsFromMonth: []
  };

  getState() {
    return this.state;
  }

  handleTextList = name => ({ target: { value } }) => {
    this.setState({
      ...this.state,
      [name]: value
    });
  };

  handleStartDate = date => {
    this.setState({
      startDate: date
    });
  };

  handleEndDate = date => {
    this.setState({
      endDate: date
    });
  };

  handleCheckbox = name => ({ target: { checked } }) => {
    this.setState({
      ...this.state,
      [name]: checked
    });
  };

  FormValidator = async () => {
    //wylicz numer miesiąca
    const MonthNbr = this.state.startDate.getMonth() + 1;

    //pobierz delegację dla danego miesiąca i wylicz kolejny numer porządkowy delegacji
    await axios.get(serverurl + `/trip?search=RA%2F${MonthNbr}`).then(res => {
      this.setState({ tripsFromMonth: res.data });
    });
    const NextTripNbr = this.state.tripsFromMonth.length + 1;
    //wylicz identyfikator Delegacji (TripIdentifier)
    const TripId = "RA/" + MonthNbr + "/" + NextTripNbr;
    this.setState({
      tripIdentifier: TripId
    });
    //wyślij dane na serwer

    //poinformuj użytkownika że sukces lub porażka

    //przejdź do widoku delegacji
  };

  render() {
    const {
      place,
      remarks,
      country,
      project,
      startDate,
      endDate,
      acommodations,
      transport
    } = this.state;
    const classes = useStyles;

    var project_id = [];
    if (project != null) {
      project_id = project;
    }

    return (
      <Fragment>
        <Container maxWidth="sm">
          <h1>nowa delegacja</h1>
        </Container>
        <Container
          maxWidth={false}
          style={{
            backgroundColor: "#eeeeee",
            marginTop: "50px",
            marginBottom: "50px"
          }}
        >
          <form
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            <CssTextField
              multiline
              rowsMax="3"
              label="Miejsce/Obszar"
              value={place}
              onChange={this.handleTextList("place")}
              inputProps={{ maxLength: 100 }}
              helperText="Miejsce wykonywanej pracy."
              margin="normal"
            />
            <MuiPickersUtilsProvider
              utils={DateFnsUtils}
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center"
              }}
            >
              <ThemeProvider theme={materialTheme}>
                <KeyboardDatePicker
                  className={classes.textField}
                  margin="normal"
                  label="Data wyjazdu"
                  value={startDate}
                  onChange={this.handleStartDate}
                  format="yyyy/MM/dd"
                  minDate={new Date()}
                />
              </ThemeProvider>
              <ThemeProvider theme={materialTheme}>
                <KeyboardDatePicker
                  className={classes.textField}
                  margin="normal"
                  label="Data powrotu"
                  value={endDate}
                  onChange={this.handleEndDate}
                  format="yyyy/MM/dd"
                  emptyLabel=""
                  minDate={new Date()}
                />
              </ThemeProvider>
            </MuiPickersUtilsProvider>
            <FormControl required className={classes.formControl}>
              <InputLabel htmlFor="country">Kraj</InputLabel>
              <Select
                name="country"
                value={country}
                onChange={this.handleTextList("country")}
                className={classes.selectEmpty}
              >
                <MenuItem value="DK">Dania</MenuItem>
                <MenuItem value="FI">Finlandia</MenuItem>
                <MenuItem value="DE">Niemcy</MenuItem>
                <MenuItem value="PL">Polska</MenuItem>
                <MenuItem value="SE">Szwecja</MenuItem>
                <MenuItem value="NL">Holandia</MenuItem>
              </Select>
            </FormControl>
            <ThemeProvider theme={materialTheme}>
              <FormControl required className={classes.formControl}>
                <InputLabel htmlFor="project">
                  Projekt
                </InputLabel>
                <Select
                  name="project"
                  className={classes.selectEmpty}
                  multiple
                  value={project_id}
                  onChange={this.handleTextList("project")}
                  renderValue={(selected) => selected.map((id) => {
                    const project = this.props.projects.find((p) => p.pk === id);
                    return project ? project.name : '';
                  }).join(', ')}
                >
                  {this.props.projects.map((item) => {
                    return (
                      <MenuItem key={item.pk} value={item.pk}>
                        <ListItemText primary={item.name} />
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </ThemeProvider>
            <FormControl>
              <InputLabel htmlFor="transport">Transport</InputLabel>
              <Select
                multiple
                value={transport}
                onChange={this.handleTextList("transport")}
                input={<Input id="transport" />}
              >
                <MenuItem value="Samochod">Samochód</MenuItem>
                <MenuItem value="Samolot">Samolot</MenuItem>
                <MenuItem value="Prom">Prom</MenuItem>
                <MenuItem value="Pociag">Pociąg</MenuItem>
              </Select>
            </FormControl>

            <ThemeProvider theme={materialTheme}>
              <FormGroup row>
                <FormControlLabel
                  control={
                    <Checkbox
                      value={acommodations}
                      color="primary"
                      onChange={this.handleCheckbox("acommodations")}
                    />
                  }
                  label="Zakwaterowanie"
                />
              </FormGroup>
            </ThemeProvider>

            <CssTextField
              label="Opis delegacji"
              multiline
              rows="4"
              defaultValue=""
              margin="normal"
              value={remarks}
              onChange={this.handleTextList("remarks")}
            />
            <ThemeProvider theme={materialTheme}>
              <Button
                variant="contained"
                color="primary"
                className={classes.button}
                onClick={this.FormValidator}
              >
                Utwórz delegację
              </Button>
            </ThemeProvider>
          </form>
        </Container>
      </Fragment>
    );
  }
}