import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import Card from "../../components/Card/Card.js";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
//import IconButton from "@material-ui/core/IconButton";
import { cardStyles } from "../../common/radiolineStyles";
import WiBtn from "../../graphics/withdrawButton.svg";
//import AtmIcon from "@material-ui/icons/Atm";

export class ButtonWithdraw extends Component {
  state = {};

  handleOpenDialog = () => {
    this.props.triggerDialogOpen();
  };

  render() {
    const { classes } = this.props;

    return (
      <Grid item xs={12} sm={12} md={4} lg={3}>
        <Card>
          <CardActionArea onClick={this.handleOpenDialog}>
            <CardMedia
              style={{ background: "#64b5f6" }}
              component="img"
              alt="Wypłata z bankomatu"
              image={WiBtn}
              title="Contemplative Reptile"
            />
            <CardContent>
              <Typography
                className={`${classes.CardButtonLabel} ${classes.textColorBlueMain}`}
              >
                Wypłata z bankomatu / Zaliczka
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
      </Grid>
    );
  }
}

export default withStyles(cardStyles, { withTheme: true })(ButtonWithdraw);
