import React, { Fragment } from 'react';
import FullCalendar from '@fullcalendar/react';
import resourceTimelinePlugin from '@fullcalendar/resource-timeline'
import Grid from "@material-ui/core/Grid";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { getEvents, getEventTypes, getEventResources, clearEvents, clearEventTypes, clearEventResources, addEvent, deleteEvent } from "../../Redux/actions/scheduler.js"

import { ThemeProvider } from "@material-ui/styles";
import { createMuiTheme } from "@material-ui/core";

import Button from '@material-ui/core/Button';
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const materialTheme = createMuiTheme({
  overrides: {
    MuiFormControl: {
      marginNormal: {
        marginTop: "50px"
      }
    },
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: "#0071BC"
      }
    },
    MuiButton: {
      root: {},
      textPrimary: {
        color: "#0071BC"
      },
      containedPrimary: {
        backgroundColor: "#1BAB7F",
        "&:hover": {
          backgroundColor: "#188E6A"
        }
      }
    },
    MuiFormLabel: {
      root: {
        "&.Mui-focused": {
          color: "#0071BC"
        }
      }
    },
    MuiInput: {
      underline: {
        "&:after": {
          borderBottomColor: "#0071bc"
        }
      }
    },
    MuiPickersDay: {
      day: {
        color: "#0071BC"
      },
      daySelected: {
        backgroundColor: "#0071BC",
        "&:hover": {
          backgroundColor: "#0071BC"
        }
      },

      dayDisabled: {
        color: "gray"
      },
      current: {
        color: "#0071BC"
      }
    },
    MuiCheckbox: {
      colorPrimary: {
        "&.Mui-checked": {
          color: "#0071bc"
        }
      }
    },
    MuiFormGroup: {
      root: {
        marginTop: "10px"
      }
    }
  }
});


class MainManagerView extends React.Component {
  state = {
    detailsOpen: false,
    dateMsg: '',
    startDateString: '',
    title: '',
    startDate: null,
    endDate: null,
    allDay: false,
    eventType: 2,
    eventPK: null,
  };

  static propTypes = {
    auth: PropTypes.object.isRequired,
    events: PropTypes.any.isRequired,
    eventTypes: PropTypes.any.isRequired,
    resources: PropTypes.any.isRequired,
    getEvents: PropTypes.func.isRequired,
    getEventTypes: PropTypes.func.isRequired,
    getEventResources: PropTypes.func.isRequired,
    addEvent: PropTypes.func.isRequired,
    clearEvents: PropTypes.func.isRequired,
    clearEventTypes: PropTypes.func.isRequired,
    clearEventResources: PropTypes.func.isRequired,
    deleteEvent: PropTypes.func.isRequired,
  };

  componentWillMount() {
    this.props.getEvents();
    this.props.getEventTypes();
    this.props.getEventResources();
  }

  componentWillUnmount() {
    this.props.clearEvents();
    this.props.clearEventTypes();
    this.props.clearEventResources();
  }

  handleDetailsOpen = (eventObj) => {
    var startD = new Date(eventObj.start);
    var endD = new Date(eventObj.end);
    var roznica = endD - startD;
    var startDateString = startD.getDate() + '.' + (startD.getMonth() + 1) + '.' + startD.getFullYear();
    var dateMsg = "";

    if (roznica % 86400000 == 0) {
      var days = roznica / 86400000;
      if (days == 1) {
        dateMsg = '1 dzień';
      }
      else {
        dateMsg = days + ' dni';
      }
    }
    else {
      dateMsg = startD.toTimeString().split(' ')[0] + ' - ' + endD.toTimeString().split(' ')[0];
    }

    this.setState({
      detailsOpen: true,
      title: eventObj.title,
      startDateString,
      dateMsg,
      eventPK: eventObj.extendedProps.pk,
    })
  }

  handleDetailsClose = () => {
    this.setState({
      detailsOpen: false,
      title: '',
      startDateString: '',
      dateMsg: '',
      eventPK: null,
    })
  }

  handleEventClick = (clickInfo) => {
    var eventObj = clickInfo.event;
    clickInfo.jsEvent.preventDefault();
    this.handleDetailsOpen(eventObj);
  }

  handleDelete = () => {
    this.props.deleteEvent(this.state.eventPK);
    this.handleDetailsClose();
  }

  handleTextList = name => ({ target: { value } }) => {
    this.setState({
      ...this.state,
      [name]: value
    });
  };

  render() {
    const {
      detailsOpen,
      dateMsg,
      title,
      startDateString,
    } = this.state;
    const {
      id
    } = this.props.auth.user;

    const deleteBtn = (
      <Button onClick={this.handleDelete}>Usuń</Button>
    )

    return (
      <Fragment>
        <ThemeProvider theme={materialTheme}>
          <Dialog open={detailsOpen} onClose={this.handleDetailsClose}>
            <DialogTitle>Info</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Informacje dotyczące wydarzenia:
              </DialogContentText>
              <DialogContentText>
                Typ: {title}
              </DialogContentText>
              <DialogContentText>
                Rozpoczęcie: {startDateString}
              </DialogContentText>
              <DialogContentText>
                Okres trwania: {dateMsg}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              {id == 121 ? deleteBtn : ''}
              <Button onClick={this.handleDetailsClose}>Zamknij</Button>
            </DialogActions>
          </Dialog>
        </ThemeProvider>

        <Grid container justify="flex-start" style={{ width: "100%", padding: "1% 10%" }}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <FullCalendar
              schedulerLicenseKey='0332943699-fcs-1696488100'
              plugins={[resourceTimelinePlugin]}
              locale='plLocale'
              firstDay='1'
              headerToolbar={{
                right: 'prev,next today',
                center: 'title',
                left: 'resourceTimelineMonth,resourceTimelineWeek,resourceTimelineDay'
              }}
              aspectRatio='2'
              resourceOrder='title'
              events={this.props.events}
              resources={this.props.resources}
              views={{
                resourceTimelineMonth: {
                  eventMaxStack: 1,
                  slotMinWidth: 100
                },
                resourceTimelineWeek: {
                  eventMaxStack: 1
                },
                resourceTimelineDay: {
                  dayMaxEventeventMaxStack: 2,
                  slotMinWidth: 20
                },
              }}
              resourceAreaWidth='250px'
              resourceAreaColumns={[
                {
                  field: 'title',
                  headerContent: 'Nazwisko i imię'
                },
              ]}
              buttonText={{
                today: 'Dzisiaj',
                month: 'Miesiąc',
                week: 'Tydzień',
                day: 'Dzień',
                list: 'Lista',
              }}
              initialView='resourceTimelineMonth'
              eventClick={this.handleEventClick}
            />
          </Grid>
        </Grid>
      </Fragment >
    )
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
  events: state.scheduler.events,
  eventTypes: state.scheduler.types,
  resources: state.scheduler.resources,
});

export default connect(
  mapStateToProps,
  {
    getEvents,
    getEventResources,
    getEventTypes,
    addEvent,
    clearEvents,
    clearEventTypes,
    clearEventResources,
    deleteEvent,
  }
)(MainManagerView);
