import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { connect } from "react-redux";
import { TabPanel } from "../common/TabPanel.js";
import FleetListViewer from "./FleetListViewer";
import InspectionListViewer from "./InspectionListViewer.js";
import { getFleet } from "../Redux/actions/fleet.js";

export class FleetViewer extends Component {
  state = {
    value: 0,
  };

  static propTypes = {
    fleet: PropTypes.array.isRequired,
    queryInProgressFleet: PropTypes.bool.isRequired,
    getFleet: PropTypes.func.isRequired,
  };

  componentDidMount() {
    if (this.props.fleet.length === 0) {
      this.props.getFleet();
    }
  }

  handleChange = (event, newValue) => {
    this.setState({
      value: newValue,
    });
  };

  render() {
    const { value } = this.state;

    var subViewsConfig;
    subViewsConfig = (
      <Fragment>
        <Tabs
          value={value}
          onChange={this.handleChange}
          aria-label="simple tabs example"
        >
          <Tab label="Flota" />
          <Tab label="Przeglądy" />
          <Tab label="Naprawy" />
        </Tabs>
        <TabPanel value={value} index={0}>
          <FleetListViewer />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <InspectionListViewer entryType={'inspec'} />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <InspectionListViewer entryType={'repair'} />
        </TabPanel>
      </Fragment>
    );

    return <div>{subViewsConfig}</div>;
  }
}

const mapStateToProps = (state) => ({
  fleet: state.fleet.fleet,
  queryInProgressFleet: state.fleet.queryInProgressFleet,
});

export default connect(
  mapStateToProps,
  { getFleet }
)(FleetViewer);
