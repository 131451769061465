import React, { Component, Fragment } from "react";
import { getMyRemote, clearRemoteDetails } from "../../Redux/actions/remote";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import { createMuiTheme } from "@material-ui/core";
import { lighten } from "@material-ui/core/styles";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import CircularProgress from "@material-ui/core/CircularProgress";

import Grid from "@material-ui/core/Grid";
import Card from "../../components/Card/Card.js";
import CardBody from "../../components/Card/CardBody.js";
import CardHeader from "@material-ui/core/CardHeader";

import { NothingToDisplay, WaitToDisplay } from "../Common/infoData.js";
import { calcTime, inMonth, secToTime } from "../Common/calcTime.js";
import { getWeekdaysInMonth } from "../Common/calcDays.js";

import FormHelperText from "@material-ui/core/FormHelperText";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { getUsersList } from "../../Redux/actions/common";
import TextField from "@material-ui/core/TextField";

import Button from '@material-ui/core/Button';
import Tooltip from "@material-ui/core/Tooltip";
import GetAppIcon from "@material-ui/icons/GetApp";
import IconButton from "@material-ui/core/IconButton";

const materialTheme = createMuiTheme({
  palette: {
    primary: {
      main: "#0071BC",
    },
  },
  overrides: {
    MuiChip: {
      root: {
        color: "#ffffff",
        "& $avatar": {
          color: "#ffffff",
        },
      },
      icon: {
        color: "#ffffff",
      },
    },
    MuiCardActions: {
      root: {
        background: "#aaaaaa",
      },
    },
  },
});

const CssTextField = withStyles({
  root: {
    width: 280,
    flexWrap: "wrap",
    marginBottom: "50px",

    "& label.Mui-focused": {
      color: "#0071bc",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#0071bc",
    },
  },
})(TextField);

const styles = (theme) => ({
  root: {
    width: "100%",
  },
  cardsGroup: {
    textAlign: "left",
    color: "#444444",
    fontFamily: "Lato, Open Sans, sans-serif",
    fontWeight: "700",
    marginTop: "30px",
    marginBottom: "10px",
  },
  Name: {
    textAlign: "left",
    fontFamily: "Lato, Open Sans, sans-serif",
    fontSize: "1em",
    fontWeight: "700",
    color: "#444444",
  },
  Name2: {
    textAlign: "left",
    fontFamily: "Lato, Open Sans, sans-serif",
    fontSize: "1.1em",
    fontWeight: "700",
    color: "#444444",
  },
  Position: {
    textAlign: "left",
    fontFamily: "Lato, Open Sans, sans-serif",
    fontSize: "1em",
    fontWeight: "400",
    color: "#aaaaaa",
  },
  chipA: {
    background: "#81c784",
    color: "#ffffff",
  },
  chipN: {
    background: "#bbbbbb",
    color: "#ffffff",
  },
  header: {
    textAlign: "left",
    backgroundColor: lighten("#338dc9", 0.85),
  },
  avatar: {
    backgroundColor: "#0071bc",
  },
  headertitle: {
    fontFamily: "Lato, Open Sans, sans-serif",
    fontSize: "1.2em",
    fontWeight: "700",
    color: "#444444",
  },
  info: {
    fontFamily: "Lato, Open Sans, sans-serif",
    fontSize: "1.5em",
    fontWeight: "300",
    color: "#0071BC",
  },
  fabblue: {
    color: theme.palette.common.white,
    backgroundColor: "#2196f3",
    "&:hover": {
      backgroundColor: "#1976d2",
    },
  },
  fb1: {
    position: "fixed",
    bottom: theme.spacing(8),
    right: theme.spacing(5),
  },
  inline: {
    display: "inline",
  },
  button: {
    width: '100%',
    height: '50vh',
    fontSize: '3em',
  },
  alarm: {
    padding: '0 0 0 10px',
    fontSize: '1em',
  },
  red: {
    color: 'red',
  },
  none: {
    display: 'none',
  },
  center: {
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
  }
});

export class MainManagerView extends Component {
  static propTypes = {
    remoteMyLog: PropTypes.array.isRequired,
    getMyRemote: PropTypes.func.isRequired,
    queryInProgressMyLOG: PropTypes.bool.isRequired,
    clearRemoteDetails: PropTypes.func.isRequired,
    usersList: PropTypes.array.isRequired,
    getUsersList: PropTypes.func.isRequired,
  };

  state = {
    open: false,
    openDialog: false,
    newEmployeeSelected: null,
    value: 0,
    viewMonth: null,
    selectedIndex: null,
    isItemSelected: false,
    month_log: [],
  };

  componentDidMount() {
    this.props.clearRemoteDetails();
  }

  componentWillUnmount() {
    this.props.clearRemoteDetails();
  }

  setOpen = () => {
    const open = this.state.open;
    if (open === false && this.props.usersList.length === 0) {
      this.props.getUsersList();
    }
    this.setState({
      open: !open,
    });
  };

  getSelected = (event, value) => {
    this.setState({
      newEmployeeSelected: value,
    });
    if (value !== null) {
      this.props.getMyRemote(value.id);
    } else {
      this.props.clearRemoteDetails();
    }
  };

  handleChange = (event, newValue) => {
    this.setState({
      value: newValue,
    });
  };

  handleListItemClick = (event, index, month) => {
    const selectedIndex = this.state.selectedIndex;
    if (selectedIndex !== index) {
      this.setState({
        selectedIndex: index,
        isItemSelected: true,
        viewMonth: month,
        month_log: inMonth(this.props.remoteMyLog, month),
      });
    } else {
      this.setState({
        selectedIndex: null,
        isItemSelected: false,
        viewMonth: null,
        month_log: [],
      });
    }
  };

  handleDownload = () => {
    var csvRow = [];
    var A = [
      [
        "Status",
        "Data rozpoczęcia",
        "Data zakończenia",
        "Czas pracy",
        "Wartość kontrolna",
        "LAT",
        "LONG",
        "Link",
      ]
    ];
    var re = this.state.month_log;

    for (var item = 0; item < re.length; item++) {
      var start = new Date(re[item].logStart);
      var stop = new Date(re[item].logStop);

      A.push([
        re[item].status,
        start.toLocaleString(),
        stop.toLocaleString(),
        secToTime(Math.floor(Math.abs(stop - start) / 1000)),
        re[item].ctrl,
        re[item].latitude,
        re[item].longitude,
        'https://maps.google.com/?q=' + re[item].latitude + ',' + re[item].longitude,
      ]);
    }

    for (var i = 0; i < A.length; ++i) {
      csvRow.push(A[i].join(";"));
    }

    var csvString = csvRow.join("\n");
    var link = window.document.createElement("a");
    link.setAttribute(
      "href",
      "data:text/csv;charset=utf-8,%EF%BB%BF" + encodeURI(csvString)
    );
    link.setAttribute("download", this.state.newEmployeeSelected.last_name + " " + this.state.newEmployeeSelected.first_name + " " + this.state.viewMonth);
    link.click();
  };

  render() {
    const { classes, remoteMyLog, queryInProgressMyLOG, usersList } = this.props;
    const { newEmployeeSelected, open, month_log, selectedIndex, isItemSelected } = this.state;

    const loading = open && usersList.length === 0;

    var weekdays = 0;
    var hours = 0;
    var progress = 0;
    var log = null;
    var worktime_months = null;
    var work_months = null;
    var downloadBttn = null;
    const queryInProgress = <CircularProgress color="secondary" />;
    if (remoteMyLog !== undefined) {
      if (!queryInProgressMyLOG) {
        worktime_months = calcTime(remoteMyLog, 1);
      }
      if (isItemSelected && remoteMyLog.length > 0 && month_log.length > 0) {
        if (month_log[0] != undefined) {
          downloadBttn = (
            <Tooltip title="Eksport do pliku">
              <IconButton aria-label="filter list" onClick={this.handleDownload} style={{ padding: '0', float: "right" }}>
                <GetAppIcon />
              </IconButton>
            </Tooltip>
          );
        }

        log = (
          <List component="nav" aria-label="main mailbox folders" style={{ paddingTop: "0" }}>
            <ListItem style={{ backgroundColor: "rgba(224, 237, 246, 0.4)" }}>
              <Grid container style={{ textAlign: "center" }}>
                <Grid item xs={2} sm={2} md={2} lg={2}>
                  <Typography component="span" className={`${classes.inline} ${classes.Name2}`}>
                    Status
                  </Typography>
                </Grid>
                <Grid item xs={4} sm={4} md={4} lg={4}>
                  <Typography component="span" className={`${classes.inline} ${classes.Name2}`}>
                    Godzina rozpoczęcia
                  </Typography>
                </Grid>
                <Grid item xs={4} sm={4} md={4} lg={4}>
                  <Typography component="span" className={`${classes.inline} ${classes.Name2}`}>
                    Godzina zakończenia
                  </Typography>
                </Grid>
                <Grid item xs={2} sm={2} md={2} lg={2}>
                  <Typography component="span" className={`${classes.inline} ${classes.Name2}`}>
                    Lokalizacja
                  </Typography>
                </Grid>
              </Grid>
            </ListItem>
            {
              month_log.length === 0 && !queryInProgressMyLOG
                ? <div className={classes.info}>Wybierz miesiąc ...</div>
                : ""
            }
            {
              month_log.map((singlelog) => (
                <ListItem key={singlelog.pk} button>
                  <Grid container style={{ textAlign: "center" }}>
                    <Grid item xs={2} sm={2} md={2} lg={2} className={classes.center}>
                      <Typography component="span" className={`${classes.inline} ${classes.Name}`}>
                        <span className={singlelog.ctrl != '' ? classes.red : ''}>{singlelog.status == 'START' ? 'Aktywny' : 'Nieaktywny'}</span>
                      </Typography>
                    </Grid>
                    <Grid item xs={4} sm={4} md={4} lg={4} className={classes.center}>
                      <Typography component="span" className={`${classes.inline} ${classes.Name}`}>
                        <span className={singlelog.ctrl != '' ? classes.red : ''}>{new Date(singlelog.logStart).toLocaleString()}</span>
                      </Typography>
                    </Grid>
                    <Grid item xs={4} sm={4} md={4} lg={4} className={classes.center}>
                      <Typography component="span" className={`${classes.inline} ${classes.Name}`}>
                        <span className={singlelog.ctrl != '' ? classes.red : ''}>{singlelog.logStop ? new Date(singlelog.logStop).toLocaleString() : 'Nie zakończono'}</span>
                      </Typography>
                    </Grid>
                    <Grid item xs={2} sm={2} md={2} lg={2} className={classes.center}>
                      <a href={'https://maps.google.com/?q=' + singlelog.latitude + ',' + singlelog.longitude} target="_blank">
                        <Button size="small" variant="contained">
                          <Typography component="span" className={`${classes.inline} ${classes.Name}`}>Pokaż</Typography>
                        </Button>
                      </a>
                    </Grid>
                  </Grid>
                </ListItem>
              ))
            }
          </List >
        );
      }
      else {
        log = (
          <List component="nav" aria-label="main mailbox folders">
            {remoteMyLog.length != 0 && !isItemSelected
              ? <div className={classes.info}>Wybierz miesiąc ...</div>
              : ""}
            {remoteMyLog.length === 0 && !queryInProgressMyLOG
              ? <NothingToDisplay classes={classes.info} />
              : ""}
            {remoteMyLog.length === 0 && queryInProgressMyLOG
              ? <WaitToDisplay classes={classes.info} />
              : ""}
          </List>
        )
      }

      if (worktime_months != null) {
        work_months = (
          <List component="nav" aria-label="main mailbox folders">
            {worktime_months.length === 0 && !queryInProgressMyLOG
              ? <NothingToDisplay classes={classes.info} />
              : ""}
            {worktime_months.length === 0 && queryInProgressMyLOG
              ? <WaitToDisplay classes={classes.info} />
              : ""}
            {worktime_months.map((time, index) => (
              <ListItem
                key={time[0]}
                button
                selected={selectedIndex === index}
                onClick={(event) => this.handleListItemClick(event, index, time[0])}
              >
                <span className={classes.none}>
                  {weekdays = getWeekdaysInMonth(new Date(time[0] + "-01"))}
                  {hours = Number(time[1].slice(0, -3)) + Number('0.' + time[1].slice(-2))}
                  {progress = (hours) / (weekdays * 8) * 100}
                </span>
                <ListItemText
                  primary={
                    <Fragment>
                      <Typography
                        component="span"
                        className={`${classes.inline} ${classes.Name}`}
                      >
                        <span className={time[2] != '' ? classes.red : ""}>
                          {time[1]}
                          {' / ' + weekdays * 8 + ''}
                          {time[2] == 'ZM' ? " - wymaga weryfikacji" : time[2] == 'CP' ? ' - przekroczono 260h ' : ''}
                        </span>
                      </Typography>
                    </Fragment>
                  }
                  secondary={
                    <Fragment>
                      <ThemeProvider theme={materialTheme}>
                        <Typography
                          component="span"
                          className={classes.Position}
                        >
                          {time[0]}
                        </Typography>
                      </ThemeProvider>
                    </Fragment>
                  }
                />
                <ListItemAvatar>
                  <Avatar className={classes.avatar} style={{ width: '3.75rem', height: '3.75rem' }}>
                    {progress.toFixed(0) + '%'}
                  </Avatar>
                </ListItemAvatar>
              </ListItem>
            ))}
          </List>
        )
      }
      else {
        work_months = (
          <List component="nav" aria-label="main mailbox folders">
            {remoteMyLog.length === 0 && !queryInProgressMyLOG
              ? <NothingToDisplay classes={classes.info} />
              : ""}
            {remoteMyLog.length === 0 && queryInProgressMyLOG
              ? <WaitToDisplay classes={classes.info} />
              : ""}
          </List>
        )
      }
    } else {
      log = <List className={classes.root}></List>;
      work_months = <List className={classes.root}></List>;
    }

    return (
      <Fragment>
        <Grid container justify="flex-start" style={{ width: "100%", padding: "1%" }}>
          <Grid item xs={12} sm={12} md={12} lg={12} style={{ justifyItems: "center", display: "grid" }}>
            <FormHelperText>
              <Typography style={{ marginTop: "5px" }} component="span" className={classes.cardsGroup}>
                Wybierz pracownika z listy aby załadować listę jego obecności
              </Typography>
            </FormHelperText>
            <ThemeProvider theme={materialTheme}>
              <Autocomplete
                value={newEmployeeSelected}
                id="asynchronous-demo"
                style={{ width: "350px", height: "48px" }}
                open={open}
                onOpen={this.setOpen}
                onClose={this.setOpen}
                onChange={this.getSelected}
                getOptionSelected={(option, value) =>
                  option.last_name === value.last_name &&
                  option.first_name === value.first_name
                }
                getOptionLabel={(option) =>
                  option.last_name + " " + option.first_name
                }
                options={usersList}
                loading={loading}
                renderInput={(params) => (
                  <CssTextField
                    {...params}
                    label="wybierz z listy..."
                    style={{ width: "330px" }}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {loading ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                )}
              />
            </ThemeProvider>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} style={{ paddingRight: "10px" }}>
            <Card style={{ overflow: "auto", maxHeight: "70vh", marginTop: "48px" }}>
              <CardBody style={{ padding: 0 }}>
                <CardHeader
                  className={classes.header}
                  avatar={
                    <Avatar aria-label="recipe" className={classes.avatar}>
                      Cz
                    </Avatar>
                  }
                  action={queryInProgressMyLOG ? queryInProgress : ""}
                  title={<div className={classes.headertitle}>Czas Pracy</div>
                  }
                />
                {work_months}
              </CardBody>
            </Card>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} style={{ paddingLeft: "10px" }}>
            <Card style={{ overflow: "auto", maxHeight: "70vh", marginTop: "48px" }}>
              <CardBody style={{ padding: 0 }}>
                <CardHeader
                  className={classes.header}
                  avatar={
                    <Avatar aria-label="recipe" className={classes.avatar}>
                      Rz
                    </Avatar>
                  }
                  action={queryInProgressMyLOG ? queryInProgress : ""}
                  title={<div className={classes.headertitle}>Rejestr zdarzeń{downloadBttn}</div>}
                />
                {log}
              </CardBody>
            </Card>
          </Grid>
        </Grid>
      </Fragment >
    );
  }
}

const mapStateToProps = (state) => ({
  remoteMyLog: state.remote.remoteMyLog,
  queryInProgressMyLOG: state.remote.queryInProgressMyLOG,
  usersList: state.common.users,
});

export default connect(
  mapStateToProps,
  { getUsersList, getMyRemote, clearRemoteDetails }
)(withStyles(styles, { withTheme: true })(MainManagerView));
