import React, { Component, Fragment } from 'react';
import { createMuiTheme } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Badge from '@material-ui/core/Badge';
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { ThemeProvider } from "@material-ui/styles";
import DateFnsUtils from "@date-io/date-fns";
import { getFormattedDateTime } from "../common/commonFunctions";
import { pl } from "date-fns/locale";
import {
    MuiPickersUtilsProvider,
    KeyboardDateTimePicker
} from "@material-ui/pickers";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import ListItemText from '@material-ui/core/ListItemText';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { addTimestamp as addTimestampE } from "../Redux/actions/trips";
import { addTimestamp as addTimestampM } from "../Redux/actions/trips-manager";

const materialTheme = createMuiTheme({
    palette: {
        primary: {
            main: '#0071BC',
        },
    },
    overrides: {
        MuiFormControl: {
            root: {
                width: 300,
                marginTop: 50
            },
            marginNormal: {
                marginTop: "50px"
            }
        },
        MuiPickersToolbar: {
            toolbar: {
                backgroundColor: "#0071BC"
            }
        },
        MuiButton: {
            root: {
            },
            textPrimary: {
                color: "#0071BC"
            },
            containedPrimary: {
                backgroundColor: "#1BAB7F",
                "&:hover": {
                    backgroundColor: "#188E6A"
                }
            }
        },
        MuiTab: {
            root: {
                backgroundColor: "#0071BC"
            },
        },
        MuiPickersClockPointer: {
            pointer: {
                backgroundColor: "#0071BC"
            },
            thumb: {
                border: "14px solid #0071BC"
            },
        },
        MuiFormLabel: {
            root: {
                MuiFocused: {
                    color: "0071BC"
                }
            }
        },
        MuiPickersDay: {
            day: {
                color: "#0071BC"
            },
            daySelected: {
                backgroundColor: "#0071BC",
                "&:hover": {
                    backgroundColor: "#0071BC"
                }
            },

            dayDisabled: {
                color: "gray"
            },
            current: {
                color: "#0071BC"
            }
        },
        MuiInput: {
            underline: {
                "&:after": {
                    borderBottomColor: "#0071bc"
                }
            }
        },
        MuiCheckbox: {
            colorPrimary: {
                "&.Mui-checked": {
                    color: "#0071bc"
                }
            }
        },
        MuiFormGroup: {
            root: {
                marginTop: "10px"
            }
        }
    }
});

export class TimestampRO extends Component {

    state = {
        dialogInfo: false,
        dialogAddTimestamp: false,
        timestampType: "",
        selectedDateTime: null,
        selectedDateTimeErrorFlag: null,
        selectedDateTimeFormatted: null,
        description: "",
        tripId: null,
        pickersErrorFlag: false
    }

    static propTypes = {
        auth: PropTypes.object.isRequired,
        //tripDetails: PropTypes.object.isRequired,
        addTimestampE: PropTypes.func.isRequired,
        addTimestampM: PropTypes.func.isRequired,
    };

    triggerAddTimestamp = (tripId, timestampType) => {
        this.setState({
            tripId: tripId,
            dialogAddTimestamp: true,
            timestampType: timestampType,
        });
    };

    handleClickInfo = () => {
        this.setState({
            dialogInfo: true
        });
    }

    handleCloseDialogInfo = () => {
        this.setState({
            dialogInfo: false
        });
    }

    handleAddTimestamp = () => {
        const url = window.location.href;
        const isManagerView = url.includes("trips-manager");

        const trip = this.state.tripId;
        const timestampType = this.state.timestampType;
        const description = this.state.description;
        const selectedDateTime = this.state.selectedDateTime;
        const selectedDateTimeFormatted = this.state.selectedDateTimeFormatted;
        const author =
            this.props.employeeSecureDetails.owner.first_name +
            " " +
            this.props.employeeSecureDetails.owner.last_name;
        if (isNaN(selectedDateTime) || selectedDateTime === null) {
            this.setState({
                pickersErrorFlag: true
            });
        } else {
            //przygotowanie body
            const timestampBody = {
                value: selectedDateTimeFormatted,
                timestampType: timestampType,
                description: description,
                author: author,
                trip: trip
            };
            if (isManagerView === false) {
                this.props.addTimestampE(timestampBody);
            } else {
                this.props.addTimestampM(timestampBody);
            }
            //console.log(timestampBody);
            this.setState({
                dialogAddTimestamp: false,
                tripId: null,
                description: "",
                selectedDateTime: null,
                selectedDateTimeFormatted: null,
                timestampType: "",
                pickersErrorFlag: false
            });
        }

    };

    handleCloseDialogAddTimestamp = () => {
        this.setState({
            dialogAddTimestamp: false,
            tripId: null,
            description: "",
            timestampType: "",
            selectedDateTime: null
        });
    };

    handleTextList = name => ({ target: { value } }) => {
        this.setState({
            ...this.state,
            [name]: value
        });
    };

    handleDateTimeChange = datetime => {
        if (datetime !== null) {
            const dd = datetime.getDate();
            const mm = datetime.getMonth() + 1;
            const yyyy = datetime.getFullYear();
            const HH = datetime.getHours();
            const MM = datetime.getMinutes();
            const date = yyyy + "-" + mm + "-" + dd + "T" + HH + ":" + MM;

            this.setState({
                selectedDateTime: datetime,
                selectedDateTimeFormatted: date
            });
        } else {
            this.setState({
                selectedDateTime: null,
                selectedDateTimeFormatted: null
            });
        }
    };

    render() {
        const { tripDetails } = this.props;
        const tripDetailTimestamps = tripDetails.timestamps;
        const { dialogInfo, dialogAddTimestamp, selectedDateTime, description, pickersErrorFlag } = this.state;

        var timestampsQuantity;
        var timestampRPContent;
        var timestampWPContent;
        var timestampPPContent;
        var timestampZPContent;
        var timestampPIContent;

        if (tripDetailTimestamps === undefined) {
            timestampsQuantity = 0;
            timestampRPContent = (
                <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="center"
                    alignItems="center"
                    p={1}
                    m={0}
                    bgcolor="background.paper"
                >
                </Box>
            );
            timestampWPContent = (
                <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="center"
                    alignItems="center"
                    p={1}
                    m={0}
                    bgcolor="background.paper"
                >
                </Box>
            );
            timestampPPContent = (
                <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="center"
                    alignItems="center"
                    p={1}
                    m={0}
                    bgcolor="background.paper"
                >
                </Box>
            );
            timestampZPContent = (
                <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="center"
                    alignItems="center"
                    p={1}
                    m={0}
                    bgcolor="background.paper"
                >
                </Box>
            );
            timestampPIContent = (
                <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="center"
                    alignItems="center"
                    p={1}
                    m={0}
                    bgcolor="background.paper"
                >
                </Box>
            );
        } else if (tripDetailTimestamps.length === 0) {
            timestampsQuantity = tripDetailTimestamps.length;
            timestampRPContent = (
                <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="center"
                    alignItems="center"
                    p={1}
                    m={0}
                    bgcolor="background.paper"
                >
                    <h3>
                        brak wpisów
                    </h3>
                </Box>
            );
            timestampWPContent = (
                <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="center"
                    alignItems="center"
                    p={1}
                    m={0}
                    bgcolor="background.paper"
                >
                    <h3>
                        brak wpisów
                    </h3>
                </Box>
            );
            timestampPPContent = (
                <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="center"
                    alignItems="center"
                    p={1}
                    m={0}
                    bgcolor="background.paper"
                >
                    <h3>
                        brak wpisów
                    </h3>
                </Box>
            );
            timestampZPContent = (
                <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="center"
                    alignItems="center"
                    p={1}
                    m={0}
                    bgcolor="background.paper"
                >
                    <h3>
                        brak wpisów
                    </h3>
                </Box>
            );
            timestampPIContent = (
                <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="center"
                    alignItems="center"
                    p={1}
                    m={0}
                    bgcolor="background.paper"
                >
                    <h3>
                        brak wpisów
                    </h3>
                </Box>
            );
        } else {
            const timestampsRP = [];
            const timestampsWP = [];
            const timestampsPP = [];
            const timestampsZP = [];
            const timestampsPI = [];

            tripDetailTimestamps.map(timestamp => {
                if (timestamp.timestampType === "RP") {
                    timestampsRP.push(timestamp);
                } else if (timestamp.timestampType === "WP") {
                    timestampsWP.push(timestamp);
                } else if (timestamp.timestampType === "PP") {
                    timestampsPP.push(timestamp);
                } else if (timestamp.timestampType === "ZP") {
                    timestampsZP.push(timestamp);
                } else if (timestamp.timestampType === "PI") {
                    timestampsPI.push(timestamp);
                }
            });

            timestampsQuantity = tripDetailTimestamps.length;

            //warunkowa definicja timestampRPContent
            if (timestampsRP.length === 0) {
                timestampRPContent = (
                    <Box
                        display="flex"
                        flexDirection="row"
                        justifyContent="center"
                        alignItems="center"
                        p={1}
                        m={0}
                        bgcolor="background.paper"
                    >
                        <h3>
                            brak wpisów
                        </h3>
                    </Box>
                );
            } else {
                timestampRPContent = (
                    <Box
                        display="flex"
                        flexDirection="row"
                        alignItems="center"
                        p={1}
                        m={0}
                        bgcolor="background.paper"
                    >
                        <List>
                            {timestampsRP.map(timestamp => {
                                const dateC = new Date(timestamp.value);
                                const dateCFormatted = getFormattedDateTime(dateC);
                                return (
                                    <ListItem key={timestamp.pk}>
                                        <ListItemAvatar>
                                            <Avatar>
                                                <LocationOnIcon />
                                            </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText
                                            primary={dateCFormatted}
                                            secondary={timestamp.description}
                                        />
                                    </ListItem>
                                )
                            })}
                        </List>
                    </Box>
                );
            }

            //warunkowa definicja timestampWPContent
            if (timestampsWP.length === 0) {
                timestampWPContent = (
                    <Box
                        display="flex"
                        flexDirection="row"
                        justifyContent="center"
                        alignItems="center"
                        p={1}
                        m={0}
                        bgcolor="background.paper"
                    >
                        <h3>
                            brak wpisów
                        </h3>
                    </Box>
                );
            } else {
                timestampWPContent = (
                    <Box
                        display="flex"
                        flexDirection="row"
                        alignItems="center"
                        p={1}
                        m={0}
                        bgcolor="background.paper"
                    >
                        <List>
                            {timestampsWP.map(timestamp => {
                                const dateC = new Date(timestamp.value);
                                const dateCFormatted = getFormattedDateTime(dateC);
                                return (
                                    <ListItem key={timestamp.pk}>
                                        <ListItemAvatar>
                                            <Avatar>
                                                <LocationOnIcon />
                                            </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText
                                            primary={dateCFormatted}
                                            secondary={timestamp.description}
                                        />
                                    </ListItem>
                                )
                            })}
                        </List>
                    </Box>
                );
            }
            //warunkowa definicja timestampPPContent
            if (timestampsPP.length === 0) {
                timestampPPContent = (
                    <Box
                        display="flex"
                        flexDirection="row"
                        justifyContent="center"
                        alignItems="center"
                        p={1}
                        m={0}
                        bgcolor="background.paper"
                    >
                        <h3>
                            brak wpisów
                        </h3>
                    </Box>
                );
            } else {
                timestampPPContent = (
                    <Box
                        display="flex"
                        flexDirection="row"
                        alignItems="center"
                        p={1}
                        m={0}
                        bgcolor="background.paper"
                    >
                        <List>
                            {timestampsPP.map(timestamp => {
                                const dateC = new Date(timestamp.value);
                                const dateCFormatted = getFormattedDateTime(dateC);
                                return (
                                    <ListItem key={timestamp.pk}>
                                        <ListItemAvatar>
                                            <Avatar>
                                                <LocationOnIcon />
                                            </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText
                                            primary={dateCFormatted}
                                            secondary={timestamp.description}
                                        />
                                    </ListItem>
                                )
                            })}
                        </List>
                    </Box>
                );
            }
            //warunkowa definicja timestampZPContent
            if (timestampsZP.length === 0) {
                timestampZPContent = (
                    <Box
                        display="flex"
                        flexDirection="row"
                        justifyContent="center"
                        alignItems="center"
                        p={1}
                        m={0}
                        bgcolor="background.paper"
                    >
                        <h3>
                            brak wpisów
                        </h3>
                    </Box>
                );
            } else {
                timestampZPContent = (
                    <Box
                        display="flex"
                        flexDirection="row"
                        alignItems="center"
                        p={1}
                        m={0}
                        bgcolor="background.paper"
                    >
                        <List>
                            {timestampsZP.map(timestamp => {
                                const dateC = new Date(timestamp.value);
                                const dateCFormatted = getFormattedDateTime(dateC);
                                return (
                                    <ListItem key={timestamp.pk}>
                                        <ListItemAvatar>
                                            <Avatar>
                                                <LocationOnIcon />
                                            </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText
                                            primary={dateCFormatted}
                                            secondary={timestamp.description}
                                        />
                                    </ListItem>
                                )
                            })}
                        </List>
                    </Box>
                );
            }
            //warunkowa definicja timestampPIContent
            if (timestampsPI.length === 0) {
                timestampPIContent = (
                    <Box
                        display="flex"
                        flexDirection="row"
                        justifyContent="center"
                        alignItems="center"
                        p={1}
                        m={0}
                        bgcolor="background.paper"
                    >
                        <h3>
                            brak wpisów
                        </h3>
                    </Box>
                );
            } else {
                timestampPIContent = (
                    <Box
                        display="flex"
                        flexDirection="row"
                        alignItems="center"
                        p={1}
                        m={0}
                        bgcolor="background.paper"
                    >
                        <List>
                            {timestampsPI.map(timestamp => {
                                const dateC = new Date(timestamp.value);
                                const dateCFormatted = getFormattedDateTime(dateC);
                                return (
                                    <ListItem key={timestamp.pk}>
                                        <ListItemAvatar>
                                            <Avatar>
                                                <LocationOnIcon />
                                            </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText
                                            primary={dateCFormatted}
                                            secondary={timestamp.description}
                                        />
                                    </ListItem>
                                )
                            })}
                        </List>
                    </Box>
                );
            }
        }


        return (
            <Fragment>
                <div style={{ width: '100%' }}>
                    <Box
                        display="flex"
                        flexDirection="row"
                        justifyContent="center"
                        alignItems="center"
                        p={0}
                        m={0}
                        bgcolor="background.paper"
                    >
                        <Box p={1}>
                            <h2>
                                <Badge badgeContent={timestampsQuantity} color="secondary">
                                    Rozliczenie czasu delegacji
                                </Badge>
                            </h2>
                        </Box>
                    </Box>
                    <Box
                        display="flex"
                        alignItems="center"
                        p={1}
                        m={0}
                        bgcolor="background.paper"
                    >
                        <Box p={1} flexGrow={1}>
                            <h3 style={{ textAlign: "left" }}>Rozpoczęcie podróży</h3>
                        </Box>
                    </Box>
                    {timestampRPContent}
                    <Box
                        display="flex"
                        alignItems="center"
                        p={1}
                        m={0}
                        bgcolor="background.paper"
                    >
                        <Box p={1} flexGrow={1}>
                            <h3 style={{ textAlign: "left" }}>Wyjazd z Polski</h3>
                        </Box>
                    </Box>
                    {timestampWPContent}
                    <Box
                        display="flex"
                        alignItems="center"
                        p={1}
                        m={0}
                        bgcolor="background.paper"
                    >
                        <Box p={1} flexGrow={1}>
                            <h3 style={{ textAlign: "left" }}>Powrót do Polski</h3>
                        </Box>
                    </Box>
                    {timestampPPContent}
                    <Box
                        display="flex"
                        alignItems="center"
                        p={1}
                        m={0}
                        bgcolor="background.paper"
                    >
                        <Box p={1} flexGrow={1}>
                            <h3 style={{ textAlign: "left" }}>Zakończenie podróży </h3>
                        </Box>
                    </Box>
                    {timestampZPContent}
                    <Box
                        display="flex"
                        alignItems="center"
                        p={1}
                        m={0}
                        bgcolor="background.paper"
                    >
                        <Box p={1} flexGrow={1}>
                            <h3 style={{ textAlign: "left" }}>Przekroczenie granicy innego państwa</h3>
                        </Box>
                    </Box>
                    {timestampPIContent}
                </div>

                <Dialog
                    open={dialogInfo}
                    onClose={this.handleCloseDialogInfo}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        Info
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Tutaj należy dodać informację o czasie rozpoczęcia
                            podróży/przekroczenia granicy/zakończenia podróży. Informacja ta
                            jest niezbędna do poprawnego rozliczenia delegacji.
                            <p>
                                <b>Rozpoczęcie podróży</b> - czas wyjazdu z miejsca zamieszkania
                            </p>
                            <p>
                                <b>Wyjazd z Polski</b> - czas fizycznego przekroczenia granicy
                                PL, jeżeli podróżujesz samochodem / czas wylotu samolotu lub
                                czas wypłynięcia promu z PL
                            </p>
                            <p>
                                <b>Powrót do Polski</b> - czas fizycznego przekroczenia granicy
                                PL, jeżeli podróżujesz samochodem / czas lądowania samolotu lub
                                czas przypłynięcia promu do PL
                            </p>
                            <p>
                                <b>Zakończenie podróży</b> - czas przyjazdu do miejsca zamieszkania
                            </p>
                            <p>
                                <b>Przekroczenie granicy innego państwa</b> - wypełnij tylko
                                jeżeli w czasie danej delegacji pracujesz w dwóch lub więcej
                                państwach. Np. Delegacja Polska-Szwecja-Finlandia-Polska
                            </p>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleCloseDialogInfo} color="primary">
                            OK
                        </Button>
                    </DialogActions>
                </Dialog>

                <Dialog
                    open={dialogAddTimestamp}
                    onClose={this.handleCloseDialogAddTimestamp}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogContent>
                        <form
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "center"
                            }}
                        >
                            <MuiPickersUtilsProvider
                                utils={DateFnsUtils}
                                locale={pl}
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    alignItems: "center"
                                }}
                            >
                                <ThemeProvider theme={materialTheme}>
                                    <KeyboardDateTimePicker
                                        ampm={false}
                                        label="Uzupełnij czas"
                                        value={selectedDateTime}
                                        onChange={this.handleDateTimeChange}
                                        error={pickersErrorFlag}
                                        format="yyyy/MM/dd HH:mm"
                                    />
                                </ThemeProvider>
                            </MuiPickersUtilsProvider>
                            <ThemeProvider theme={materialTheme}>
                                <TextField
                                    multiline
                                    label="Miejsce"
                                    rowsMax="3"
                                    margin="normal"
                                    value={description || ""}
                                    inputProps={{ maxLength: 50 }}
                                    onChange={this.handleTextList("description")}
                                />
                            </ThemeProvider>
                        </form>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleCloseDialogAddTimestamp} color="primary">
                            Anuluj
                        </Button>
                        <Button
                            onClick={this.handleAddTimestamp}
                            color="primary"
                            autoFocus
                        >
                            Zapisz
                        </Button>
                    </DialogActions>
                </Dialog>
            </Fragment>
        );
    }
}

const mapStateToProps = state => ({
    //tripDetails: state.tripsReducer.tripDetails,
    //employeeTripDetails: state.tripsManagerReducer.employeeTripDetails,
    auth: state.auth,
    employeeSecureDetails: state.employee.employeeSecureDetails
});

export default connect(mapStateToProps, {
    addTimestampE, addTimestampM
})(TimestampRO);