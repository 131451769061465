import React, { Component, Fragment } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import { Avatar } from "@material-ui/core";
import Card from "../../components/Card/Card.js";
import CardBody from "../../components/Card/CardBody.js";
import Typography from "@material-ui/core/Typography";
import { createMuiTheme } from "@material-ui/core";
import { lighten } from "@material-ui/core/styles";

import CardHeader from "@material-ui/core/CardHeader";
import { ThemeProvider } from "@material-ui/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import StopIcon from "@material-ui/icons/Stop";

import PropTypes from "prop-types";
import { connect } from "react-redux";

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Box from "@material-ui/core/Box";

const materialTheme = createMuiTheme({
  palette: {
    primary: {
      main: "#0071BC",
    },
  },
  overrides: {
    MuiChip: {
      root: {
        color: "#ffffff",
        "& $avatar": {
          color: "#ffffff",
        },
      },
      icon: {
        color: "#ffffff",
      },
    },
    MuiCardActions: {
      root: {
        background: "#aaaaaa",
      },
    },
    MuiIconButton: {
      root: {
        color: "#fff",
      },
    },
  },
});

const styles = (theme) => ({
  avatar: {
    backgroundColor: "#0071BC",
    height: 50,
    width: 50,
  },
  Address: {
    textAlign: "left",
    fontFamily: "Lato, Open Sans, sans-serif",
    fontSize: "1em",
    fontWeight: "300",
    color: "#444444",
  },
  Position: {
    textAlign: "left",
    fontFamily: "Lato, Open Sans, sans-serif",
    fontSize: "1em",
    fontWeight: "400",
    color: "#0071BC",
  },
  Name: {
    textAlign: "left",
    fontFamily: "Lato, Open Sans, sans-serif",
    fontSize: "2em",
    fontWeight: "700",
    color: "#444444",
  },
  Name2: {
    textAlign: "left",
    fontFamily: "Lato, Open Sans, sans-serif",
    fontSize: "1em",
    fontWeight: "700",
    color: "#444444",
  },
  info: {
    fontFamily: "Lato, Open Sans, sans-serif",
    fontSize: "1.5em",
    fontWeight: "300",
    color: "#0071BC",
  },
  ProfileBar: {
    textAlign: "left",
    fontFamily: "Lato, Open Sans, sans-serif",
    fontSize: "1em",
    fontWeight: "400",
    color: "#444444",
  },
  Country: {
    textAlign: "left",
    fontFamily: "Lato, Open Sans, sans-serif",
    fontSize: "1em",
    fontWeight: "300",
    color: "#444444",
    textTransform: "uppercase",
  },
  large: {
    width: "90px",
    height: "90px",
  },
  header: {
    textAlign: "left",
    backgroundColor: lighten("#338dc9", 0.85),
  },
  avatar: {
    backgroundColor: "#0071bc",
  },
  headertitle: {
    fontFamily: "Lato, Open Sans, sans-serif",
    fontSize: "1.2em",
    fontWeight: "700",
    color: "#444444",
  },
  inline: {
    display: "inline",
  },
});

export class CarEmployeeCard extends Component {
  static propTypes = {
    carEmployeeList: PropTypes.array.isRequired,
  };

  state = {
    selectedIndex: null,
    openNewDialog: false,
  };

  render() {
    const { classes, carEmployeeList } = this.props;
    const { selectedIndex, openNewDialog } = this.state;

    var employeeList = null;
    const nothingToDisplay = (
      <div className={classes.info} style={{ textAlign: "center" }}>Brak użytkoników do wyświetlenia ...</div>
    );

    if (carEmployeeList !== undefined) {
      employeeList = (
        <List component="nav" aria-label="main mailbox folders">
          {carEmployeeList.length === 0
            ? nothingToDisplay
            : ""}
          {carEmployeeList.map((car, index) => (
            <ListItem
              key={car.pk}
              button
              selected={selectedIndex === index}
            >
              <ListItemAvatar>
                <Avatar>
                  {car.employee.last_name.substr(0, 1) + car.employee.first_name.substr(0, 1)}
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={
                  <Fragment>
                    <Typography
                      component="span"
                      className={`${classes.inline} ${classes.Name2}`}
                    >
                      {car.employee.last_name + " " + car.employee.first_name}
                    </Typography>
                  </Fragment>
                }
                secondary={
                  <Fragment>
                    <ThemeProvider theme={materialTheme}>
                      <span style={{ display: "flex", alignItems: "center" }}>
                        <PlayArrowIcon color="primary" />
                        <Typography
                          component="span"
                          className={classes.Position}
                        >
                          {" | Od: " + new Date(car.startDate).toLocaleString()}
                        </Typography>
                      </span>
                      <span style={{ display: "flex", alignItems: "center" }}>
                        <StopIcon color="primary" />
                        <Typography
                          component="span"
                          className={classes.Position}
                        >
                          {car.endDate != null ? " | Do: " + new Date(car.endDate).toLocaleString() : " | Do: brak podanej daty"}
                        </Typography>
                      </span>
                    </ThemeProvider>
                  </Fragment>
                }
              />
            </ListItem>
          ))}
        </List>
      );
    } else {
      employeeList = <List className={classes.root}></List>;
    }

    return (
      <Fragment>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Card style={{ marginBottom: "0px" }}>
            <CardBody style={{ padding: 0 }}>
              <CardHeader
                className={classes.header}
                avatar={
                  <Avatar aria-label="recipe" className={classes.avatar}>
                    Up
                  </Avatar>
                }
                title={
                  <div className={classes.headertitle}>Używany przez</div>
                }
              />
              {employeeList}
            </CardBody>
          </Card>
        </Grid>
      </Fragment >
    );
  }
}

const mapStateToProps = (state) => ({
  carEmployeeList: state.fleet.carEmployeeList,
});

export default connect(
  mapStateToProps,
  {}
)(withStyles(styles, { withTheme: true })(CarEmployeeCard));
