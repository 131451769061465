import React, { Component, Fragment } from "react";
import { withStyles } from "@material-ui/core/styles";
import Card from "../../components/Card/Card.js";
import CardBody from "../../components/Card/CardBody.js";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { cardStyles } from "../../common/radiolineStyles";
import { Doughnut } from "react-chartjs-2";
import CardHeader from "@material-ui/core/CardHeader";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { patchProfile } from "../../Redux/actions/auth";
import { patchSUserProfile } from "../../Redux/actions/common";

export class BalanceCard extends Component {
  state = {
    showMenu: false,
    anchorEl: null,
    //selectedCurrency: "eur",
  };

  static propTypes = {
    balanceEUR: PropTypes.object.isRequired,
    balancePLN: PropTypes.object.isRequired,
    balanceSEK: PropTypes.object.isRequired,
    balanceNOK: PropTypes.object.isRequired,
    patchProfile: PropTypes.func.isRequired,
    patchSUserProfile: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    suser: PropTypes.object.isRequired,
  };

  handleClick = (event) => {
    this.setState({
      showMenu: true,
      anchorEl: event.currentTarget,
    });
  };

  handleClose = () => {
    this.setState({
      showMenu: false,
      anchorEl: null,
    });
  };

  handleChangeCurrency = (currency, view) => {
    this.setState({
      showMenu: false,
      anchorEl: null,
      //selectedCurrency: currency,
    });

    if (view === "clearanceView") {
      const profileBody = {
        accountingSelectedCurrencyClearanceView: currency,
      };
      this.props.patchSUserProfile(profileBody, this.props.suser.pk);
    } else {
      const profileBody = {
        accountingSelectedCurrency: currency,
      };
      this.props.patchProfile(profileBody, this.props.auth.main_profile.pk);
    }

    this.props.changeCurrency(currency);
  };

  render() {
    const {
      classes,
      balanceEUR,
      balancePLN,
      balanceSEK,
      balanceNOK,
      selectedCurrency,
      view,
    } = this.props;
    const { showMenu, anchorEl } = this.state;

    var balance = {
      sum: 0,
      operacjenakarcie: 0,
      wyplatydlapracownika: 0,
      potracenia: 0,
      transakcjezaakceptowane: 0,
      zaakceptowaneOC: 0,
      zwrotydokasy: 0,
    };

    var piearray = [];
    var piedata = {};

    //przypisanie wyliczeń sumy tansakcji dla wybranej waluty

    if (selectedCurrency === "eur") {
      balance = {
        sum: parseFloat(balanceEUR.sum).toFixed(2),
        operacjenakarcie: parseFloat(balanceEUR.wb_pk).toFixed(2),
        wyplatydlapracownika: parseFloat(balanceEUR.zpb).toFixed(2),
        potracenia: parseFloat(balanceEUR.zdt).toFixed(2),
        transakcjezaakceptowane: parseFloat(
          balanceEUR.zpk + balanceEUR.zpg
        ).toFixed(2),
        zaakceptowaneOC: parseFloat(balanceEUR.zoc).toFixed(2),
        zwrotydokasy: parseFloat(balanceEUR.zrt).toFixed(2),
      };
      piearray = [
        parseInt(balanceEUR.wb_pk),
        parseInt(balanceEUR.zpb),
        parseInt(balanceEUR.zdt),
        parseInt(balanceEUR.zpk + balanceEUR.zpg),
        parseInt(balanceEUR.zrt),
        parseInt(balanceEUR.zoc),
      ];
    } else if (selectedCurrency === "pln") {
      balance = {
        sum: parseFloat(balancePLN.sum).toFixed(2),
        operacjenakarcie: parseFloat(balancePLN.wb_pk).toFixed(2),
        wyplatydlapracownika: parseFloat(balancePLN.zpb).toFixed(2),
        potracenia: parseFloat(balancePLN.zdt).toFixed(2),
        transakcjezaakceptowane: parseFloat(
          balancePLN.zpk + balancePLN.zpg
        ).toFixed(2),
        zaakceptowaneOC: parseFloat(balancePLN.zoc).toFixed(2),
        zwrotydokasy: parseFloat(balancePLN.zrt).toFixed(2),
      };
      piearray = [
        parseInt(balancePLN.wb_pk),
        parseInt(balancePLN.zpb),
        parseInt(balancePLN.zdt),
        parseInt(balancePLN.zpk + balancePLN.zpg),
        parseInt(balancePLN.zrt),
        parseInt(balancePLN.zoc),
      ];
    } else if (selectedCurrency === "sek") {
      balance = {
        sum: parseFloat(balanceSEK.sum).toFixed(2),
        operacjenakarcie: parseFloat(balanceSEK.wb_pk).toFixed(2),
        wyplatydlapracownika: parseFloat(balanceSEK.zpb).toFixed(2),
        potracenia: parseFloat(balanceSEK.zdt).toFixed(2),
        transakcjezaakceptowane: parseFloat(
          balanceSEK.zpk + balanceSEK.zpg
        ).toFixed(2),
        zaakceptowaneOC: parseFloat(balanceSEK.zoc).toFixed(2),
        zwrotydokasy: parseFloat(balanceSEK.zrt).toFixed(2),
      };
      piearray = [
        parseInt(balanceSEK.wb_pk),
        parseInt(balanceSEK.zpb),
        parseInt(balanceSEK.zdt),
        parseInt(balanceSEK.zpk + balanceSEK.zpg),
        parseInt(balanceSEK.zrt),
        parseInt(balanceSEK.zoc),
      ];
    } else if (selectedCurrency === "nok") {
      balance = {
        sum: parseFloat(balanceNOK.sum).toFixed(2),
        operacjenakarcie: parseFloat(balanceNOK.wb_pk).toFixed(2),
        wyplatydlapracownika: parseFloat(balanceNOK.zpb).toFixed(2),
        potracenia: parseFloat(balanceNOK.zdt).toFixed(2),
        transakcjezaakceptowane: parseFloat(
          balanceNOK.zpk + balanceNOK.zpg
        ).toFixed(2),
        zaakceptowaneOC: parseFloat(balanceNOK.zoc).toFixed(2),
        zwrotydokasy: parseFloat(balanceNOK.zrt).toFixed(2),
      };
      piearray = [
        parseInt(balanceNOK.wb_pk),
        parseInt(balanceNOK.zpb),
        parseInt(balanceNOK.zdt),
        parseInt(balanceNOK.zpk + balanceNOK.zpg),
        parseInt(balanceNOK.zrt),
        parseInt(balanceNOK.zoc),
      ];
    }

    //przygotowanie wykresu

    if (
      balance.operacjenakarcie === 0 &&
      balance.transakcjezaakceptowane === 0 &&
      balance.zaakceptowaneOC === 0 &&
      balance.wyplatydlapracownika === 0 &&
      balance.zwrotydokasy === 0 &&
      balance.potracenia === 0
    ) {
      piedata = {
        labels: ["Brak transakcji do analizy"],
        datasets: [
          {
            data: [1],
            backgroundColor: ["#2196f3"],
            hoverBackgroundColor: ["#2196f3"],
            borderWidth: 5,
          },
        ],
      };
    } else {
      piedata = {
        labels: [
          "Operacje na karcie | razem",
          "Wypłaty dla pracownika | zaakcep.",
          "Potrącenia | zaakcep.",
          "Pł. kart/got | zaakcep.",
          "Zwroty do kasy | potwierdzone",
          "Pł. środki własne | zaakcep",
        ],
        datasets: [
          {
            data: piearray,
            //backgroundColor: ["#2196f3", "#4caf50", "#81c784", "#dc004e"],
            backgroundColor: [
              "#aaaaaa",
              "#2196f3",
              "#dc004e",
              "#4caf50",
              "#81c784",
              "#4caf50",
            ],
            hoverBackgroundColor: [
              "#aaaaaa",
              "#2196f3",
              "#dc004e",
              "#4caf50",
              "#81c784",
              "#4caf50",
            ],
            borderWidth: 5,
          },
        ],
      };
    }

    //console.log(Math.ceil(balance.wb_pk));

    var options = {
      cutoutPercentage: 80,
      legend: {
        display: false,
      },
    };

    return (
      <Fragment>
        <Card
          style={{ height: "650px", marginTop: "0px", marginBottom: "0px" }}
        >
          <CardHeader
            title={
              <Typography className={classes.CardHeader}>
                Saldo / {selectedCurrency}
              </Typography>
            }
            action={
              <IconButton aria-label="settings" onClick={this.handleClick}>
                <MoreVertIcon />
              </IconButton>
            }
          />
          <CardBody style={{ position: "relative" }}>
            <Doughnut data={piedata} options={options} />
            <Typography
              className={`${classes.textCenterXU} ${classes.textInternalU}`}
            >
              {balance.sum}
            </Typography>
            <Typography
              className={`${classes.textCenterXL} ${classes.textInternalL}`}
            >
              stan konta
            </Typography>

            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>
                    <b>Transakcja</b>
                  </TableCell>
                  <TableCell align="right">
                    <b>Suma</b>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow key={"WB+PK"}>
                  <TableCell
                    className={classes.textColorGrayMain}
                    component="th"
                    scope="row"
                  >
                    Operacje na karcie | razem
                  </TableCell>
                  <TableCell align="right">
                    {balance.operacjenakarcie}
                  </TableCell>
                </TableRow>
                <TableRow key={"PB"}>
                  <TableCell
                    className={classes.textColorBlueMain}
                    component="th"
                    scope="row"
                  >
                    Wypłaty dla pracownika | zaakcep.
                  </TableCell>
                  <TableCell align="right">
                    {balance.wyplatydlapracownika}
                  </TableCell>
                </TableRow>
                <TableRow key={"DT"}>
                  <TableCell
                    className={classes.textColorRedMain}
                    component="th"
                    scope="row"
                  >
                    Potrącenia | zaakcep.
                  </TableCell>
                  <TableCell align="right">{balance.potracenia}</TableCell>
                </TableRow>
                <TableRow key={"ZPK+ZPG"}>
                  <TableCell
                    className={classes.textColorGreenMain}
                    component="th"
                    scope="row"
                  >
                    Pł. kart/got | zaakcep.
                  </TableCell>
                  <TableCell align="right">
                    {balance.transakcjezaakceptowane}
                  </TableCell>
                </TableRow>
                <TableRow key={"RT"}>
                  <TableCell
                    className={classes.textColorGreenLight}
                    component="th"
                    scope="row"
                  >
                    Zwroty do kasy | potwierdzone
                  </TableCell>
                  <TableCell align="right">{balance.zwrotydokasy}</TableCell>
                </TableRow>
                <TableRow key={"OC"}>
                  <TableCell
                    className={classes.textColorGreenLight}
                    component="th"
                    scope="row"
                  >
                    Pł. środki własne | zaakcep.
                  </TableCell>
                  <TableCell align="right">{balance.zaakceptowaneOC}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </CardBody>
        </Card>

        {/*//menu for card*/}
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          //anchorOrigin={{ vertical: "bottom" }}
          keepMounted
          getContentAnchorEl={null}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          transformOrigin={{ vertical: "top", horizontal: "center" }}
          open={showMenu}
          onClose={this.handleClose}
        >
          <MenuItem onClick={this.handleChangeCurrency.bind(this, "eur", view)}>
            EUR
          </MenuItem>
          <MenuItem onClick={this.handleChangeCurrency.bind(this, "pln", view)}>
            PLN
          </MenuItem>
          <MenuItem onClick={this.handleChangeCurrency.bind(this, "sek", view)}>
            SEK
          </MenuItem>
          <MenuItem onClick={this.handleChangeCurrency.bind(this, "nok", view)}>
            NOK
          </MenuItem>
        </Menu>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  balanceEUR: state.accounting.balanceEUR,
  balancePLN: state.accounting.balancePLN,
  balanceSEK: state.accounting.balanceSEK,
  balanceNOK: state.accounting.balanceNOK,
  auth: state.auth,
  suser: state.common.suser,
});

export default connect(
  mapStateToProps,
  { patchProfile, patchSUserProfile }
)(withStyles(cardStyles, { withTheme: true })(BalanceCard));
