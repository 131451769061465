import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import MainManagerView from "./ManagerView/MainManagerView.js";
import { connect } from "react-redux";
import { TabPanel } from "./Common/TabPanel.js";
import MainEmployeeView from "./EmployeeView/MainEmployeeView.js";
import MainRoomView from "./RoomView/MainRoomView.js";

export class SchedulerViewer extends Component {
  state = {
    value: 0,
  };

  static propTypes = {
    auth: PropTypes.object.isRequired,
  };

  handleChange = (event, newValue) => {
    this.setState({
      value: newValue,
    });
  };

  render() {
    const { value } = this.state;

    var subViewsConfig;
    subViewsConfig = (
      <Fragment>
        <Tabs
          value={value}
          onChange={this.handleChange}
          aria-label="simple tabs example"
        >
          <Tab label="Moje" />
          <Tab label="Pracownicy" />
          <Tab label="Salka" />
        </Tabs>
        <TabPanel value={value} index={0}>
          <MainEmployeeView />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <MainManagerView />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <MainRoomView />
        </TabPanel>
      </Fragment>
    );

    return <div>{subViewsConfig}</div>;
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(
  mapStateToProps,
  {}
)(SchedulerViewer);
