import {
  GET_TRIPS,
  DELETE_TRIP,
  ADD_TRIP,
  GET_TRIP_DETAILS,
  CLEAR_TRIP_DETAILS,
  PATCH_TRIP,
  UPDATE_TRIP,
  ADD_TRIP_COMMENT_E,
  ADD_TIMESTAMP_E,
  DELETE_TIMESTAMP_E
} from "../actions/types";

const initialState = {
  trips: [],
  tripDetails: {}
};

function pad(number) {
  if (number < 10) {
    return '0' + number;
  }
  return number;
}

export function repairTimestamp(payload) {
  var match = payload.value.match(/(\d{4})-(\d{1,2})-(\d{1,2})T(\d{1,2}):(\d{2})([+-]\d{2}):?(\d{2})?/);

  if (match && match.length >= 7) {
    var year = Number(match[1]);
    var month = Number(match[2]) - 1;
    var day = Number(match[3]);
    var hour = Number(match[4]);
    var minute = Number(match[5]);
    var timezone = match[6] + ':' + match[7];

    if (!isNaN(year) && !isNaN(month) && !isNaN(day) && !isNaN(hour) && !isNaN(minute)) {
      var date = new Date(Date.UTC(year, month, day, hour, minute));
      if (!timezone) {
        timezone = 'Z';
      }

      payload.value = `${date.getUTCFullYear()}-${pad(date.getUTCMonth() + 1)}-${pad(date.getUTCDate())}T${pad(date.getUTCHours())}:${pad(date.getUTCMinutes())}:${pad(date.getUTCSeconds())}${timezone}`;
    } else {
      console.error("Invalid date components: ", match);
    }
  } else {
    console.error("Invalid date format: ", payload.value);
  }

  return payload;
}

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_TRIPS:
      return {
        ...state,
        trips: action.payload
      };
    case GET_TRIP_DETAILS:
      return {
        ...state,
        tripDetails: action.payload
      };
    case DELETE_TRIP:
      return {
        ...state,
        trips: state.trips.filter(trip => trip.pk !== action.payload)
      };
    case ADD_TRIP:
      return {
        ...state,
        trips: [...state.trips, action.payload]
      };
    case PATCH_TRIP:
      return {
        ...state,
        tripDetails: action.payload
      };
    case UPDATE_TRIP:
      return {
        ...state,
        tripDetails: action.payload
      };
    case ADD_TRIP_COMMENT_E:
      return {
        ...state,
        tripDetails: {
          ...state.tripDetails,
          comments: [...state.tripDetails.comments, action.payload]
        }
      };
    case ADD_TIMESTAMP_E:
      var payload = action.payload;
      payload = repairTimestamp(payload);

      return {
        ...state,
        tripDetails: {
          ...state.tripDetails,
          timestamps: [...state.tripDetails.timestamps, payload]
        }
      };
    case DELETE_TIMESTAMP_E:
      return {
        ...state,
        tripDetails: {
          ...state.tripDetails,
          timestamps: state.tripDetails.timestamps.filter(
            timestamp => timestamp.pk !== action.payload
          )
        }
      };
    case CLEAR_TRIP_DETAILS:
      return {
        ...state,
        tripDetails: {}
      };
    default:
      return state;
  }
}
