import React, { Fragment } from 'react';
import Grid from "@material-ui/core/Grid";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';

import { doc } from '../../PDFGen/DejaVuSansCondensed'
import { generate } from './SwiadczeniaODocument';

import Dialog from "@material-ui/core/Dialog";
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from "@material-ui/core/TextField";

import { sendCode, verifyCode, clearCode } from "../../Redux/actions/verifycodes"
import { addApplication, getLastSWO } from '../../Redux/actions/zfss';
import { correctDateFormat } from '../Common/correctDateFormat';

class MainSwiadczeniaOView extends React.Component {
  state = {
    openStep1: false,
    openStep2: false,
    code: '',
    pesel: '',
    peselValid: null,
    peselError: false,
    swoData: null,
    placed: false,
  };

  static propTypes = {
    auth: PropTypes.object.isRequired,
    codeValid: PropTypes.any.isRequired,
    sendCode: PropTypes.func.isRequired,
    clearCode: PropTypes.func.isRequired,
    verifyCode: PropTypes.func.isRequired,
    addApplication: PropTypes.func.isRequired,
    last: PropTypes.any.isRequired,
    lastSWO: PropTypes.any.isRequired,
    getLastSWO: PropTypes.func.isRequired,
  };

  componentWillMount() {
    this.props.getLastSWO(this.props.auth.user.id)
  }

  componentDidUpdate() {
    if (this.props.codeValid) {
      this.handleSign();
      this.props.clearCode();
      this.handleCloseStep2();
    }
    if (this.state.peselValid) {
      this.handleOpenStep2();
      this.handleCloseStep1();
    }
  }

  handleSign = (even) => {
    const user = this.props.auth.user;
    var firstName = user.first_name;
    var lastName = user.last_name;

    const data = {
      id: user.id,
      firstName,
      lastName,
      inc: this.props.last[0].application.date,
      date: new Date(),
    }

    const number = 'SWO/' + data.date.getFullYear() + "/" + data.id;

    const app = {
      owner: data.id,
      type: 3, //SWO
      status: 1, //Utworzony
      number,
      date: "2023-01-01T00:00:00Z",
      //SWO
      income_date: data.inc.split('T')[0],
    }

    generate(doc, data);
    this.props.addApplication(app);
  }

  handleOpenStep1 = (eventObj) => {
    this.setState({
      openStep1: true,
    })
  }

  handleCloseStep1 = () => {
    this.setState({
      openStep1: false,
      pesel: '',
      peselValid: null,
      peselError: false,
    })
  }

  handleOpenStep2 = (eventObj) => {
    this.handleNewCode();
    this.setState({
      openStep2: true,
    })
  }

  handleCloseStep2 = () => {
    this.setState({
      openStep2: false,
      code: '',
    })
  }

  handleNewCode = () => {
    const body = {
      user: this.props.auth.user.id,
      recipient: this.props.auth.user.email,
    }

    this.props.sendCode(body);
  }

  handleCheckPesel = () => {
    if (this.state.pesel == this.props.auth.main_profile.pesel) {
      this.setState({
        peselValid: true,
        peselError: false,
      })
    }
    else {
      this.setState({
        peselValid: false,
        peselError: true,
      })
    }
  }

  handleCheckCode = () => {
    const body = {
      user: this.props.auth.user.id,
      code: this.state.code,
    }
    this.props.verifyCode(body);
  }

  handleTextList = name => ({ target: { value } }) => {
    this.setState({
      ...this.state,
      [name]: value,
    });
  };

  handleKeyPress = (event) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    if (!/^\d+$/.test(keyValue))
      event.preventDefault();
  };

  preventDefault = (event) => event.preventDefault();

  render() {
    const { openStep1, openStep2, code, pesel, peselError, placed } = this.state;

    const buttonEn = (<Button onClick={this.handleOpenStep1} color="primary" variant="contained">Wygeneruj i podpisz</Button>);
    const buttonDis = (<Button disabled>Złożyłeś już wniosek</Button>);

    var valid = false;
    if (this.props.lastSWO[0] != undefined) {
      if (Date.now() - Date.parse(this.props.lastSWO[0].date) < 14 * (24 * 60 * 60 * 1000)) {
        valid = true;
      }
    }
    if (placed) {
      valid = true;
    }

    const diaolgPesel = (
      <Dialog open={openStep1} onClose={this.handleCloseStep1}>
        <DialogTitle>Autoryzacja wniosku: Krok 1 - PESEL</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Podaj swój numer PESEL
          </DialogContentText>
          <TextField
            margin="dense"
            id="pesel"
            label="Wprowadź PESEL"
            type="text"
            fullWidth
            variant="standard"
            value={pesel}
            onChange={this.handleTextList("pesel")}
            onKeyPress={this.handleKeyPress}
            error={peselError}
            helperText={peselError ? "Nieprawidłowy numer PESEL" : ""}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleCloseStep1}>Anuluj</Button>
          <Button onClick={this.handleCheckPesel}>Dalej</Button>
        </DialogActions>
      </Dialog>
    )

    const contact_string = 'mailto:it@radioline.com.pl?subject=Uzupełnienie danych - ' + this.props.auth.user.first_name + ' ' + this.props.auth.user.last_name;

    const diaolgNoPesel = (
      <Dialog open={openStep1} onClose={this.handleCloseStep1}>
        <DialogTitle>Błąd - brak wymaganych danych w systemie</DialogTitle>
        <DialogContent>
          <DialogContentText>
            W celu uzupełnienia danych skontaktuj się z działem IT.
          </DialogContentText>
          <DialogContentText>
            Kontakt: <a href={contact_string}>it@radioline.com.pl</a>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleCloseStep1}>Zamknij</Button>
        </DialogActions>
      </Dialog>
    )

    return (
      <Fragment>
        {this.props.auth.main_profile.pesel != null ? diaolgPesel : diaolgNoPesel}

        <Dialog open={openStep2} onClose={this.handleCloseStep2}>
          <DialogTitle>Autoryzacja wniosku: Krok 2 - Kod E-Mail</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Na twój adres e-mail został wysłany 6-cyfrowy kod autoryzacyjny.
            </DialogContentText>
            <TextField
              margin="dense"
              id="code"
              label="Wprowadź kod"
              type="text"
              fullWidth
              variant="standard"
              value={code}
              onChange={this.handleTextList("code")}
              onKeyPress={this.handleKeyPress}
            />
            <DialogContentText></DialogContentText>
            <DialogContentText>
              <Button onClick={this.handleNewCode}>Wyślij nowy kod</Button>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleCloseStep2}>Anuluj</Button>
            <Button onClick={this.handleCheckCode}>Podpisz</Button>
          </DialogActions>
        </Dialog>

        <Grid container justify="center" style={{ width: "100%", padding: "1% 10%" }}>
          <Grid item xs={12} sm={12} md={12} lg={10} xl={8} style={{ justifyItems: "center" }}>
            <Box textAlign="center" mb={2}>
              <Typography variant="h5">Wniosek</Typography>
              <Typography variant="h6">o świadczenie okolicznościowe z Zakładowego Funduszu Świadczeń</Typography>
              <Typography variant="h6">Socjalnych w Radio Line Sp. z o.o.</Typography>
            </Box>
            <Typography>
              1. Ja, {this.props.auth.user.first_name + " " + this.props.auth.user.last_name} zwracam się z prośbą o przyznanie mi świadczenia okolicznościowego.
            </Typography>
            <Typography>
              2. Deklaruję dochód na osobę w rodzinie zgodny z oświadczeniem o dochodach przypadających na osobę gospodarstwie domowym, złożonym przeze mnie Komisji Socjalnej w dniu {correctDateFormat(this.props.last[0].application.date)}
            </Typography>
            <br /><br />
            {valid ? buttonDis : buttonEn}
          </Grid>
        </Grid>
      </Fragment >
    )
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
  codeValid: state.verifycodes.valid,
  last: state.zfss.last,
  lastSWO: state.zfss.lastSWO,
});

export default connect(
  mapStateToProps,
  { sendCode, verifyCode, clearCode, addApplication, getLastSWO }
)(MainSwiadczeniaOView);
