import React, { Component, Fragment } from "react";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import { withStyles } from "@material-ui/core/styles";
import { styles } from "../../common/radiolineStyles";

import wait from "../../graphics/wait.svg";
import warning from "../../graphics/warning.svg";
import success from "../../graphics/success.svg";

var wa = <img style={{ height: "26px" }} src={wait} alt="wa"></img>;
var de = <img style={{ height: "26px" }} src={warning} alt="de"></img>;
var au = <img style={{ height: "26px" }} src={warning} alt="au"></img>;
var ac = <img style={{ height: "26px" }} src={success} alt="ac"></img>;

export class TransferLog extends Component {
  render() {
    const { classes, assetsDetails } = this.props;

    var transferlogtitle = (
      <Fragment>
        <Typography className={classes.textType2}>
          Historia przesunięć
        </Typography>
      </Fragment>
    );

    var transferlogcontent = <Fragment></Fragment>;

    if (assetsDetails !== undefined) {
      transferlogcontent = (
        <Fragment>
          <List component="nav" aria-label="main mailbox folders">
            {assetsDetails.equipmenttransferlogs.map((asset, index) => (
              <ListItem key={asset.pk}>
                <ListItemAvatar>{eval(asset.acceptStatus)}</ListItemAvatar>
                <ListItemText
                  primary={asset.fromUser + " do " + asset.toUser}
                  secondary={
                    <Fragment>
                      <Typography
                        className={classes.listTextSecondary}
                        component="span"
                      >
                        {asset.fromDate !== null &&
                        asset.toDate !== null &&
                        asset.acceptDate !== null
                          ? asset.fromDate +
                            " | " +
                            asset.toDate +
                            " | " +
                            asset.acceptDate
                          : ""}
                        {asset.fromDate !== null &&
                        asset.toDate === null &&
                        asset.acceptDate === null
                          ? asset.fromDate + " | - | - "
                          : ""}
                        {asset.fromDate !== null &&
                        asset.toDate !== null &&
                        asset.acceptDate === null
                          ? asset.fromDate + " | " + asset.toDate + " | -"
                          : ""}
                        {asset.fromDate === null &&
                        asset.toDate === null &&
                        asset.acceptDate !== null
                          ? "- | - | " + asset.acceptDate
                          : ""}
                      </Typography>
                      <Typography
                        className={classes.listTextSecondary}
                        component="span"
                      >
                        {"akceptujący: " + asset.acceptUser}
                      </Typography>
                    </Fragment>
                  }
                />
              </ListItem>
            ))}
          </List>
        </Fragment>
      );
    }

    return (
      <Fragment>
        {transferlogtitle}
        {transferlogcontent}
      </Fragment>
    );
  }
}

export default withStyles(styles, { withTheme: true })(TransferLog);
