import {
  GET_FLEET,
  DELETE_FLEET,
  ADD_FLEET,
  UPDATE_FLEET,
  FLEET_QUERY_FAILED,
  FLEET_QUERY_IN_PROGRESS,
  GET_FLEET_DETAILS,
  FLEET_DETAILS_QUERY_FAILED,
  FLEET_DETAILS_QUERY_IN_PROGRESS,
  CLEAR_FLEET_DETAILS,
  GET_FLEET_DETAILS2,
  GET_INSPECTIONS,
  INSPECTIONS_QUERY_IN_PROGRESS,
  GET_INSPECTION_DETAILS,
  CLEAR_INSPECTION_DETAILS,
  INSPECTION_DETAILS_QUERY_IN_PROGRESS,
} from "../actions/types";

const initialState = {
  fleet: [],
  inspections: [],
  inspectionDetails: [],
  carFull: [],
  carEmployeeList: [],
  queryInProgressFleet: false,
  queryInProgressInspections: false,
  queryInProgressFleetDetails: false,
  queryInProgressInspectionDetails: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_FLEET:
      return {
        ...state,
        fleet: action.payload,
        queryInProgressFleet: false,
      };
    case FLEET_QUERY_IN_PROGRESS:
      return {
        ...state,
        queryInProgressFleet: true,
      };
    case FLEET_QUERY_FAILED:
      return {
        ...state,
        queryInProgressFleet: false,
      };
    case GET_FLEET_DETAILS:
      return {
        ...state,
        carFull: action.payload,
        queryInProgressFleetDetails: false,
      };
    case GET_FLEET_DETAILS2:
      return {
        ...state,
        carEmployeeList: action.payload,
        queryInProgressFleetDetails: false,
      };
    case FLEET_DETAILS_QUERY_IN_PROGRESS:
      return {
        ...state,
        queryInProgressFleetDetails: true,
      };
    case FLEET_DETAILS_QUERY_FAILED:
      return {
        ...state,
        queryInProgressFleetDetails: false,
      };
    case CLEAR_FLEET_DETAILS:
      return {
        ...state,
        carFull: [],
        carEmployeeList: [],
        queryInProgressFleetDetails: false,
      };
    case GET_INSPECTIONS:
      return {
        ...state,
        inspections: action.payload,
        queryInProgressInspections: false,
      };
    case INSPECTIONS_QUERY_IN_PROGRESS:
      return {
        ...state,
        queryInProgressInspections: true,
      };
    case GET_INSPECTION_DETAILS:
      return {
        ...state,
        inspectionDetails: action.payload,
        queryInProgressInspectionDetails: false,
      };
    case INSPECTION_DETAILS_QUERY_IN_PROGRESS:
      return {
        ...state,
        queryInProgressInspectionDetails: true,
      };
    case CLEAR_INSPECTION_DETAILS:
      return {
        ...state,
        inspectionDetails: [],
        queryInProgressInspectionDetails: false,
      };
    default:
      return state;
  }
}
