import React, { Fragment, Component } from "react";
import Box from "@material-ui/core/Box";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  getMyInventoryDetails,
  getEmployeeInventoryAssets,
  scrapEmployeeAsset,
  addAsset,
  getAssetDetails,
  clearAssetDetails,
} from "../../Redux/actions/assets";
import { clearUserDetails } from "../../Redux/actions/common";
import { withStyles } from "@material-ui/core/styles";
import AssetNavigation from "../Common/AssetNavigation";
import EmployeeInventoryList from "./EmployeeInventoryList";
import Typography from "@material-ui/core/Typography";
import { styles, materialTheme } from "../../common/radiolineStyles";
import { sendNewNotification, sendNewNotificationKeepers, sendNewNotificationNoMail, sendNewNotificationControler, sendNewNotificationAccounting, sendNewNotificationManager } from "../../Redux/actions/employee";
import { createMuiTheme, Menu, MenuItem } from "@material-ui/core";
import Zoom from "@material-ui/core/Zoom";
import Tooltip from "@material-ui/core/Tooltip";
import Fab from "@material-ui/core/Fab";
import VisibilityIcon from "@material-ui/icons/Visibility";
import CloseIcon from "@material-ui/icons/Close";
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { ThemeProvider } from "@material-ui/styles";
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";
import MyInventoryDetails from "../EmployeeView/MyInventoryDetails.js";
import InventoryDetailsCompareAndEdit from "./InventoryDetailsCompareAndEdit.js";
import Card from "../../components/Card/Card.js";
import CardBody from "../../components/Card/CardBody.js";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContentText from '@material-ui/core/DialogContentText';
import { updateEmpInventoryDetails, reportInventory } from "../../Redux/actions/assets.js";
import { sendCode, verifyCode, clearCode } from "../../Redux/actions/verifycodes"
import { correctDateFormat2 } from "../Common/correctDateFormat.js";
import AddAssetInteractiveForm from "./AddAssetInteractiveForm.js";
import CreateIcon from '@material-ui/icons/Create';
import CommentIcon from '@material-ui/icons/Comment';
import ShuffleIcon from '@material-ui/icons/Shuffle';
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
import { transferAsset_admin } from "../../Redux/actions/assets";
import { getFormattedDate } from "../../common/commonFunctions";
import DialogAssetTransfer from "../Common/DialogAssetTransfer";
import { getUserDetails } from "../../Redux/actions/common";

const materialThemeNext = createMuiTheme({
  palette: {
    primary: {
      main: "#0071BC",
    },
  },
  overrides: {
    MuiTextField: {
      root: {
        width: "auto",
      },
    },
    MuiInputBase: {
      root: {
        width: "300px",
      },
    },
    MuiFormLabel: {
      root: {
        color: "#0071bc",
      },
    },
  },
});

export class InventoryControlerView extends Component {
  state = {
    checkedAssetPk: null,
    isAssetChecked: false,
    eqTypeSelected: "al",
    open: false,
    dialogOpen: false,
    dialogCompareOpen: false,
    chargeEmployeeFlag: false,
    alertOpen: false,
    activeListType: null,
    pesel: '',
    peselValid: null,
    peselError: false,
    code: '',
    openStep1: false,
    openStep2: false,
    assetDescription: '',
    dialogOpenAddAsset: false,
    openConfirmDialog: false,
    openCommentDialog: false,
    comment: '',
    newStatus: '',
    commentRequired: false,
    openTransferFromDialog: false,
    openTransferToDialog: false,
    extranetID: null,
    idRequired: false,
    queryEnd: false,
  };

  static propTypes = {
    auth: PropTypes.object.isRequired,
    queryInProgressCommon: PropTypes.bool.isRequired,
    queryInProgress: PropTypes.bool.isRequired,
    userDetails: PropTypes.object.isRequired,
    addAsset: PropTypes.func.isRequired,
    scrapEmployeeAsset: PropTypes.func.isRequired,
    sendNewNotification: PropTypes.func.isRequired,
    getEmployeeInventoryAssets: PropTypes.func.isRequired,
    getMyInventoryDetails: PropTypes.func.isRequired,
    updateEmpInventoryDetails: PropTypes.func.isRequired,
    reportInventory: PropTypes.func.isRequired,
    codeValid: PropTypes.any.isRequired,
    sendCode: PropTypes.func.isRequired,
    verifyCode: PropTypes.func.isRequired,
    clearCode: PropTypes.func.isRequired,
    sendNewNotificationNoMail: PropTypes.func.isRequired,
    sendNewNotificationKeepers: PropTypes.func.isRequired,
    sendNewNotificationControler: PropTypes.func.isRequired,
    sendNewNotificationAccounting: PropTypes.func.isRequired,
    sendNewNotificationManager: PropTypes.func.isRequired,
    transferAsset_admin: PropTypes.func.isRequired,
    getAssetDetails: PropTypes.func.isRequired,
    clearAssetDetails: PropTypes.func.isRequired,
    suserDetails: PropTypes.object.isRequired,
    assetsDetails: PropTypes.object.isRequired,
    getUserDetails: PropTypes.func.isRequired,
    clearUserDetails: PropTypes.func.isRequired,
  };

  componentDidMount() {
    this.props.clearUserDetails("s");

    const ownerId = this.props.invData.inventoryUser.pk;

    this.props.getUserDetails(ownerId, "s");

    this.props.getEmployeeInventoryAssets(this.props.inv, ownerId, "al");
    this.props.getEmployeeInventoryAssets(this.props.inv, ownerId, "na");
    this.props.getEmployeeInventoryAssets(this.props.inv, ownerId, "el");
    this.props.getEmployeeInventoryAssets(this.props.inv, ownerId, "it");
    this.props.getEmployeeInventoryAssets(this.props.inv, ownerId, "po");
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.userDetails.pk !== undefined &&
      nextProps.userDetails.pk !== this.props.userDetails.pk
    ) {
      const ownerId = this.props.invData.inventoryUser.pk;

      this.props.getEmployeeInventoryAssets(this.props.inv, ownerId, "al");
      this.props.getEmployeeInventoryAssets(this.props.inv, ownerId, "na");
      this.props.getEmployeeInventoryAssets(this.props.inv, ownerId, "el");
      this.props.getEmployeeInventoryAssets(this.props.inv, ownerId, "it");
      this.props.getEmployeeInventoryAssets(this.props.inv, ownerId, "po");
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.codeValid) {
      this.handleAcceptInventory();
      this.props.clearCode();
      this.handleCloseStep2();
    }
    if (this.state.peselValid) {
      this.handleOpenStep2();
      this.handleCloseStep1();
    }

    if (prevProps.queryInProgress == true && this.props.queryInProgress == false) {
      this.setState({
        queryEnd: true,
      });
    }
  }

  handleTextList = (name) => ({ target: { value } }) => {
    this.setState({
      //...this.state,
      [name]: value,
    });
  };

  handleChecked = (isChecked, assetpk, listtype) => {
    this.setState({
      isAssetChecked: isChecked,
      checkedAssetPk: assetpk,
      activeListType: listtype,
    });

    if (isChecked === true) {
      this.props.getMyInventoryDetails(assetpk);
    }
  };

  handleEqTypeSelected = (eqtypeselected) => {
    this.props.getEmployeeInventoryAssets(this.props.inv, this.props.invData.inventoryUser.pk, eqtypeselected);
    this.setState({
      eqTypeSelected: eqtypeselected,
      checkedAssetPk: null,
      isAssetChecked: false,
    });
  };

  handleEmployeeSelected = (employeepk) => {
    this.setState({
      employeeSelected: employeepk,
    });
  };

  handleClickPreview = (event) => {
    this.setState({
      dialogOpen: true,
    });
  };

  handleAlertClose = () => {
    this.setState({
      alertOpen: false,
    });
  };

  handleClickCloseDialog = () => {
    this.setState({
      dialogOpen: false,
    });
  };

  handleClickCloseDialogCompare = () => {
    this.setState({
      dialogCompareOpen: false,
    });
  };

  handleTextChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  handleOpenConfirmDialog = () => {
    var required = false;
    if (this.props.myInventoryDetails.itemStatus != "do" && this.props.myInventoryDetails.itemStatus != "po") {
      required = true;
    }

    this.setState({
      openConfirmDialog: true,
      newStatus: this.props.myInventoryDetails.itemStatus,
      commentRequired: required,
    });
  }

  handleCloseConfirmDialog = () => {
    this.setState({
      openConfirmDialog: false,
      commentRequired: false,
    });
  }

  handleDownload = (eqtype) => {
    var assetsToBeDownloaded = [];

    if (eqtype === "al") {
      assetsToBeDownloaded = this.props.employeeAssetsAl;
    } else if (eqtype === "na") {
      assetsToBeDownloaded = this.props.employeeAssetsNa;
    } else if (eqtype === "el") {
      assetsToBeDownloaded = this.props.employeeAssetsEl;
    } else if (eqtype === "it") {
      assetsToBeDownloaded = this.props.employeeAssetsIt;
    } else if (eqtype === "po") {
      assetsToBeDownloaded = this.props.employeeAssetsPo;
    } else if (eqtype === "ws") {
      assetsToBeDownloaded = this.props.employeeAssetsAl.concat(
        this.props.employeeAssetsNa,
        this.props.employeeAssetsEl,
        this.props.employeeAssetsIt,
        this.props.employeeAssetsPo
      );
    }

    if (assetsToBeDownloaded.length !== 0) {
      var csvRow = [];
      var A = [
        [
          "Grupa",
          "Nazwa",
          "Model",
          "Producent",
          "Numer seryjny",
          "Numer Produktu",
          "Data zakupu",
          "Data przekazania",
          "Opis dodatkowy",
          "Właściciel środka trwałego",
        ],
      ];
      var re = assetsToBeDownloaded;

      for (var item = 0; item < re.length; item++) {
        A.push([
          re[item].model.eqtype_description,
          re[item].model.name,
          re[item].model.description,
          re[item].model.vendor,
          re[item].serialNumber,
          re[item].partNumber,
          re[item].purchaseDate,
          re[item].assignDate,
          re[item].description,
          re[item].owner.first_name + " " + re[item].owner.last_name,
        ]);
      }

      for (var i = 0; i < A.length; ++i) {
        csvRow.push(A[i].join(";"));
      }

      var csvString = csvRow.join("\n");
      //var a = document.createElement("a");
      var link = window.document.createElement("a");
      link.setAttribute(
        "href",
        "data:text/csv;charset=utf-8,%EF%BB%BF" + encodeURI(csvString)
      );
      if (eqtype === "ws") {
        link.setAttribute(
          "download",
          `SrodkiTrwale${re[0].owner.first_name + re[0].owner.last_name}.csv`
        );
      } else {
        link.setAttribute(
          "download",
          `SrodkiTrwale${re[0].owner.first_name + re[0].owner.last_name + "_"}${re[0].model.eqtype_description
          }.csv`
        );
      }
      link.click();
    } else {
      this.setState({
        alertOpen: true,
      });
    }
  };

  handleClearEmployee = () => {
    this.setState({
      //eqTypeSelected: null,
      checkedAssetPk: null,
      isAssetChecked: false,
    });
  };

  setFilter = (filtername) => {
    this.setState({
      activeFilter: filtername,
    });

    if (
      this.state.isAssetChecked &&
      this.state.activeListType !== "AssetListManager"
    ) {
      this.setState({
        checkedAssetPk: null,
        isAssetChecked: false,
        activeListType: null,
      });
    }
  };

  handleClickPreview = (event) => {
    this.setState({
      dialogOpen: true,
    });
  };

  handleClickInvAdd = (event) => {
    this.setState({
      dialogOpenAddAsset: true,
    });
  };

  handleClickInvYes = (event) => {
    this.setState({
      dialogCompareOpen: true,
    });
  };

  handleClickInvScrap = (eqID, snapID) => {
    const invNumber = `IST/${new Date(this.props.invData.inventoryStartDate).getFullYear()}/${String(this.props.invData.pk).padStart(4, '0')}/${String(this.props.invData.inventoryUser.pk).padStart(3, '0')}`;

    var body = {
      "status": "sd",
      "scrapageAcceptDate": correctDateFormat2(new Date()),
      "scrapagedDate": correctDateFormat2(new Date()),
      "scrapageManagerComment": "Zezłomowano podczas inwentury " + invNumber,
    }
    var snapshotBody = {
      "itemStatus": "zl",
    }
    this.props.scrapEmployeeAsset(eqID, body, "sd");
    this.props.updateEmpInventoryDetails(snapID, snapshotBody);

    this.setState({
      openCommentDialog: true,
      commentRequired: true,
    });
  };

  handleClickInvDone = (event) => {
    this.setState({
      openConfirmDialog: false,
      commentRequired: false,
    });

    var body = {
      "itemStatus": this.state.newStatus,
    }
    if (this.state.comment != "") {
      body.controlComment = this.state.comment;
    }
    this.props.updateEmpInventoryDetails(this.state.checkedAssetPk, body);
  };

  handleAcceptInventory = (event) => {
    var stage = null;
    const status = this.props.invData.status;
    const isSuperUser = this.props.auth.user.is_superuser;
    if ((this.props.auth.permissions.bhp || isSuperUser) && status == "re") {
      stage = "manager";
    }
    else if ((this.props.auth.permissions.controlling || isSuperUser) && status == "ma") {
      stage = "admin";
    }

    if (stage === "admin") {
      const {
        employeeInventoryAl,
        employeeInventoryNa,
        employeeInventoryEl,
        employeeInventoryIt,
        employeeInventoryPo,
      } = this.props;
      const employeeInventoryAssets = employeeInventoryAl.concat(
        employeeInventoryNa,
        employeeInventoryEl,
        employeeInventoryIt,
        employeeInventoryPo
      );

      const invNumber = `IST/${new Date(this.props.invData.inventoryStartDate).getFullYear()}/${String(this.props.invData.pk).padStart(4, '0')}/${String(this.props.invData.inventoryUser.pk).padStart(3, '0')}`;

      const data = {
        "status": "fi",
        "inventoryEndDate": new Date().toISOString(),
        "signedController": this.props.auth.user.id,
        "signedControllerDate": new Date().toISOString(),
      }

      const notifyBody = {
        user: this.props.invData.inventoryUser.pk,
        module: "Środki Trwałe",
        topic: "Inwnentura pracownika została zaakceptowana przez kontroling",
        message:
          "Użytkownik " + this.props.auth.user.first_name + " " + this.props.auth.user.last_name +
          " zaakceptował inwenturę nr. " + invNumber + "\n" +
          "Więcej informacji znajdziesz w module Środki Trwałe w EXTRANET.\n" +
          "Możesz pobrać raport z tej inwentury w EXTRANET."
      }

      const notifyBodyEMP = {
        recipient: this.props.invData.inventoryUser.pk,
        module: "Środki Trwałe",
        topic: "Twoja inwnentura została zaakceptowana przez kontroling",
        message:
          "Użytkownik " + this.props.auth.user.first_name + " " + this.props.auth.user.last_name +
          " zaakceptował twoją inwenturę nr. " + invNumber + "\n" +
          "Więcej informacji znajdziesz w module Środki Trwałe w EXTRANET.\n" +
          "Teraz możesz pobrać raport z inwentury w EXTRANET."
      }

      var notifyBodyAcc = {
        recipient: this.props.invData.inventoryUser.pk,
        html: true,
        module: "Środki Trwałe",
        topic: "Inwnentura pracownika została zaakceptowana przez kontroling",
        message:
          "Użytkownik " + this.props.auth.user.first_name + " " + this.props.auth.user.last_name +
          " zaakceptował inwenturę nr. " + invNumber + "<br>" +
          "Możesz pobrać raport z tej inwentury w module Środki Trwałe w EXTRANET.<br><br>" +
          "Podsumowanie środków trwałych pracownika:<br>"
      }

      notifyBodyAcc.message += `
        <style>
          table {
            width: 100%;
            border-collapse: collapse;
          }
          th, td {
            border: 1px solid #ddd;
            padding: 8px;
            text-align: left;
          }
          th {
            background-color: #f2f2f2;
          }
          .not-owned {
            background-color: #ffcccc;
          }
          .added {
            background-color: #ccffcc;
          }
        </style>
        <table>
          <tr>
            <th>Nazwa</th>
            <th>Producent</th>
            <th>Model</th>
            <th>Typ</th>
            <th>Opis</th>
            <th>Wartość</th>
            <th>Numer faktury</th>
            <th>Komentarz kontrolingu</th>
            <th>Status</th>
          </tr>`;

      const eqTypes = {
        "al": "BHP",
        "na": "Narzędzia",
        "el": "Elektro-narzędzia",
        "it": "IT",
        "po": "Pozostałe",
      }

      const eqStatus = {
        "po": "Potwierdzony",
        "do": "Dodany",
        "zl": "Zezłomowany",
        "dc": "Do sprawdzenia",
        "pr": "Przekazany",
      }

      const getStatusClass = (status) => {
        switch (status) {
          case 'do':
            return 'added';
          case 'zl':
            return 'not-owned';
          default:
            return '';
        }
      };

      employeeInventoryAssets.forEach(asset => {
        if (asset.isST || !asset.equipment) {
          notifyBodyAcc.message += `<tr>
                <td>${asset.itemName}</td>
                <td>${asset.vendor}</td>
                <td>${asset.model}</td>
                <td>${eqTypes[asset.eqtype]}</td>
                <td>${asset.description}</td>
                <td>${asset.value != null ? asset.value : "-"}</td>
                <td>${asset.fvNumber != null ? asset.fvNumber : "-"}</td>
                <td>${asset.controlComment != null ? asset.controlComment : "-"}</td>
                <td class=${getStatusClass(asset.itemStatus)}>${eqStatus[asset.itemStatus]}</td>
              </tr>`;
        }
      });

      notifyBodyAcc.message += `</table>`;

      this.props.reportInventory(this.props.inv, data);
      this.props.sendNewNotificationManager(notifyBody);
      this.props.sendNewNotificationKeepers(notifyBody);
      this.props.sendNewNotificationNoMail(notifyBodyEMP);
      this.props.sendNewNotificationAccounting(notifyBodyAcc);
      this.props.sendNewNotificationControler(notifyBodyAcc);
      this.props.closeViewDialog();
    }
  }

  handleDeclineInventory = (event) => {
    var stage = null;
    const status = this.props.invData.status;
    const isSuperUser = this.props.auth.user.is_superuser;
    if ((this.props.auth.permissions.bhp || isSuperUser) && status == "re") {
      stage = "manager";
    }
    else if ((this.props.auth.permissions.controlling || isSuperUser) && status == "ma") {
      stage = "admin";
    }
    if (stage === "admin") {
      const invNumber = `IST/${new Date(this.props.invData.inventoryStartDate).getFullYear()}/${String(this.props.invData.pk).padStart(4, '0')}/${String(this.props.invData.inventoryUser.pk).padStart(3, '0')}`;

      const data = {
        "status": "ip",
        "inventoryEndDate": new Date().toISOString(),
      }

      const notifyBodyEMP = {
        recipient: this.props.invData.inventoryUser.pk,
        module: "Środki Trwałe",
        topic: "Twoja inwnentura została wycofana do poprawy",
        message:
          "Użytkownik " + this.props.auth.user.first_name + " " + this.props.auth.user.last_name +
          " wycofał twoją inwenturę nr. " + invNumber + "\n" +
          "Teraz możesz ją poprawić w module Środki Trwałe w EXTRANET."
      }

      this.props.reportInventory(this.props.inv, data);
      this.props.sendNewNotificationNoMail(notifyBodyEMP);
      this.props.closeViewDialog();
    }
  }

  handleOpenStep1 = (eventObj) => {
    this.setState({
      openStep1: true,
    })
  }

  handleCloseStep1 = () => {
    this.setState({
      openStep1: false,
      pesel: '',
      peselValid: null,
      peselError: false,
    })
  }

  handleOpenStep2 = (eventObj) => {
    this.handleNewCode();
    this.setState({
      openStep2: true,
    })
  }

  handleCloseStep2 = () => {
    this.setState({
      openStep2: false,
      code: '',
    })
  }

  handleNewCode = () => {
    const body = {
      user: this.props.auth.user.id,
      recipient: this.props.auth.user.email,
    }

    this.props.sendCode(body);
  }

  handleCheckPesel = () => {
    if (this.state.pesel == this.props.auth.main_profile.pesel) {
      this.setState({
        peselValid: true,
        peselError: false,
      })
    }
    else {
      this.setState({
        peselValid: false,
        peselError: true,
      })
    }
  }

  handleCheckCode = () => {
    const body = {
      user: this.props.auth.user.id,
      code: this.state.code,
    }
    this.props.verifyCode(body);
  }

  handleCloseDialogAddAsset = () => {
    this.setState({
      dialogOpenAddAsset: false,
    });
  };

  handleAddAsset = (assetBody, modelSelected) => {
    this.props.addAsset(assetBody, modelSelected.eqtype);
    this.setState({
      dialogOpenAddAsset: false,
    });
    var body = {
      "itemStatus": "do",
    }
    this.props.updateEmpInventoryDetails(this.state.checkedAssetPk, body);
  };

  handleOpenCommentDialog = () => {
    this.setState({
      openCommentDialog: true,
      comment: this.props.myInventoryDetails.controlComment,
    });
  }

  handleCloseCommentDialog = () => {
    this.setState({
      openCommentDialog: false,
    });
  }

  handleCommentChange = (event) => {
    this.setState({
      comment: event.target.value,
    });
  }

  handleSubmitComment = () => {
    const body = {
      "controlComment": this.state.comment,
    }
    this.props.updateEmpInventoryDetails(this.state.checkedAssetPk, body);
    this.setState({
      openCommentDialog: false,
      commentRequired: false,
    });
  }

  handleStatusChange = (event) => {
    this.setState({
      newStatus: event.target.value,
      commentRequired: event.target.value != "po" && event.target.value != "do",
    });
  }

  handleClickInvTransferFrom = () => {
    this.setState({
      openTransferFromDialog: true,
    });
  }

  handleCloseTransferFrom = () => {
    this.setState({
      openTransferFromDialog: false,
    });
  }

  handleExtIDChange = (event) => {
    this.setState({
      extranetID: event.target.value,
      idRequired: event.target.value != "",
    });
    this.props.clearAssetDetails();
  }

  handleSearchExtID = () => {
    this.props.getAssetDetails(this.state.extranetID);
  }

  handleTransferFrom = () => {
    const fromDate = getFormattedDate(new Date());

    const assetbody = {
      owner: this.props.suserDetails.pk,
      status: "iu",
      assignDate: fromDate,
    };

    const assettransferlogbody = {
      fromUser: this.props.assetsDetails.owner.username,
      toUser: this.props.suserDetails.username,
      acceptUser: this.props.auth.user.username,
      acceptDate: fromDate,
      toStatus: "ta",
      acceptStatus: "ac",
      equipment: this.state.extranetID,
    };

    const ssmsbody = {
      recipient: this.props.assetsDetails.owner.email,
      module: "Środki Trwałe",
      topic: "Przekazanie środka trwałego",
      message:
        "Użytkownik " +
        this.props.auth.user.first_name + " " + this.props.auth.user.last_name +
        " przeniósł Twój środek trwały " +
        this.props.assetsDetails.model.name +
        " " +
        this.props.assetsDetails.model.description +
        " na użytkownika " +
        this.props.suserDetails.first_name + " " + this.props.suserDetails.last_name,
    };
    const tsmsbody = {
      recipient: this.props.suserDetails.email,
      module: "Środki Trwałe",
      topic: "Otrzymanie środka trwałego",
      message:
        "Użytkownik " +
        this.props.auth.user.first_name + " " + this.props.auth.user.last_name +
        " przeniósł na Ciebie środek trwały " +
        this.props.assetsDetails.model.name +
        " " +
        this.props.assetsDetails.model.description +
        " użytkownika " +
        this.props.assetsDetails.owner.first_name + " " + this.props.assetsDetails.owner.last_name,
    };

    this.props.transferAsset_admin(
      this.state.extranetID,
      assetbody,
      assettransferlogbody,
      ssmsbody,
      tsmsbody,
      this.props.assetsDetails.model.eqtype,
      "u2u"
    );

    const body = {
      "itemStatus": "pr",
    }

    this.props.updateEmpInventoryDetails(this.state.checkedAssetPk, body);

    this.setState({
      openTransferFromDialog: false,
    });
  }

  handleClickInvTransferTo = () => {
    this.setState({
      openTransferToDialog: true,
      queryEnd: false,
    });
    this.props.getAssetDetails(this.props.myInventoryDetails.equipment);
  }

  handleCloseTransferTo = (success) => {
    if (success) {
      const body = {
        "itemStatus": "pr",
      }
      this.props.updateEmpInventoryDetails(this.state.checkedAssetPk, body);
    }

    this.setState({
      openTransferToDialog: false,
    });
  }

  render() {
    const {
      eqTypeSelected,
      dialogOpen,
      activeListType,
      isAssetChecked,
      openStep1,
      openStep2,
      pesel,
      peselError,
      code,
      dialogOpenAddAsset,
      dialogCompareOpen,
      openConfirmDialog,
      comment,
      openCommentDialog,
      newStatus,
      commentRequired,
      openTransferFromDialog,
      openTransferToDialog,
      extranetID,
      idRequired,
    } = this.state;
    const {
      classes,
      employeeInventoryAl,
      employeeInventoryNa,
      employeeInventoryEl,
      employeeInventoryIt,
      employeeInventoryPo,
      queryInProgress,
      myInventoryDetails,
    } = this.props;

    var employeeInventoryAssets = [];
    //var employeeAssetTransfer = null;

    if (eqTypeSelected === "al") {
      employeeInventoryAssets = employeeInventoryAl;
    } else if (eqTypeSelected === "na") {
      employeeInventoryAssets = employeeInventoryNa;
    } else if (eqTypeSelected === "el") {
      employeeInventoryAssets = employeeInventoryEl;
    } else if (eqTypeSelected === "it") {
      employeeInventoryAssets = employeeInventoryIt;
    } else if (eqTypeSelected === "po") {
      employeeInventoryAssets = employeeInventoryPo;
    }

    var inventoryDetails = null;
    var inventoryDetailsCompare = null;

    const circle = (
      <Grid
        container
        className={classes.root}
        spacing={0}
        justify="center"
        alignItems="center"
      >
        <CircularProgress color="secondary" />
      </Grid>
    );

    if (
      (dialogOpen === true) &&
      this.props.myInventoryDetails.pk !== undefined
    ) {
      inventoryDetails = (
        <MyInventoryDetails myInventoryDetails={this.props.myInventoryDetails}></MyInventoryDetails>
      );
    } else {
      inventoryDetails = <Fragment></Fragment>;
    }

    if (
      (dialogCompareOpen === true) &&
      this.props.myInventoryDetails.pk !== undefined
    ) {
      inventoryDetailsCompare = (
        <InventoryDetailsCompareAndEdit myInventoryDetails={this.props.myInventoryDetails}></InventoryDetailsCompareAndEdit>
      );
    } else {
      inventoryDetailsCompare = <Fragment></Fragment>;
    }

    const previewButtonDi = (
      <Fab
        disabled
        color="inherit"
        className={classes.fabblue}
        size="small"
        aria-label="scroll back to top"
      >
        <VisibilityIcon />
      </Fab>
    );

    const previewButtonEn = (
      <Fab
        color="inherit"
        className={classes.fabblue}
        size="small"
        aria-label="scroll back to top"
      >
        <VisibilityIcon />
      </Fab>
    );

    const diaolgPesel = (
      <Dialog open={openStep1} onClose={this.handleCloseStep1}>
        <DialogTitle>Autoryzacja wniosku: Krok 1 - PESEL</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Podaj swój numer PESEL
          </DialogContentText>
          <TextField
            margin="dense"
            id="pesel"
            label="Wprowadź PESEL"
            type="text"
            fullWidth
            variant="standard"
            value={pesel}
            onChange={this.handleTextList("pesel")}
            onKeyPress={this.handleKeyPress}
            error={peselError}
            helperText={peselError ? "Nieprawidłowy numer PESEL" : ""}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleCloseStep1}>Anuluj</Button>
          <Button onClick={this.handleCheckPesel}>Dalej</Button>
        </DialogActions>
      </Dialog>
    )

    const contact_string = 'mailto:it@radioline.com.pl?subject=Uzupełnienie danych - ' + this.props.auth.user.first_name + ' ' + this.props.auth.user.last_name;

    const diaolgNoPesel = (
      <Dialog open={openStep1} onClose={this.handleCloseStep1}>
        <DialogTitle>Błąd - brak wymaganych danych w systemie</DialogTitle>
        <DialogContent>
          <DialogContentText>
            W celu uzupełnienia danych skontaktuj się z działem IT.
          </DialogContentText>
          <DialogContentText>
            Kontakt: <a href={contact_string}>it@radioline.com.pl</a>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleCloseStep1}>Zamknij</Button>
        </DialogActions>
      </Dialog>
    )

    const calcInventory = employeeInventoryAl.length + employeeInventoryNa.length + employeeInventoryEl.length + employeeInventoryIt.length + employeeInventoryPo.length;
    const clacAdded = employeeInventoryAl.filter(asset => asset.equipment == null).length + employeeInventoryNa.filter(asset => asset.equipment == null).length + employeeInventoryEl.filter(asset => asset.equipment == null).length + employeeInventoryIt.filter(asset => asset.equipment == null).length + employeeInventoryPo.filter(asset => asset.equipment == null).length;
    const calcToDo = employeeInventoryAl.filter(asset => asset.itemStatus == "dc").length + employeeInventoryNa.filter(asset => asset.itemStatus == "dc").length + employeeInventoryEl.filter(asset => asset.itemStatus == "dc").length + employeeInventoryIt.filter(asset => asset.itemStatus == "dc").length + employeeInventoryPo.filter(asset => asset.itemStatus == "dc").length;

    const activeInventoryInfo = (
      <ThemeProvider theme={materialTheme}>
        <Grid container justify="center" style={{ textAlign: "center" }}>
          <Grid item xs={12} sm={12} md={12}>
            <Typography>
              Liczba środków trwałych w tej kategorii: {employeeInventoryAssets.length}
            </Typography>
            <Typography>
              Środki trwałe pozostałe do weryfikacji: {employeeInventoryAssets.filter(asset => asset.accepted == null).length}
            </Typography>
            <Typography>
              Środki trwałe pozostałe do zsynchronizowania: {employeeInventoryAssets.filter(asset => asset.itemStatus == "dc").length}
            </Typography>
            <br></br>
            <Typography>
              Dodane środki trwałe: {clacAdded}
            </Typography>
            <Typography>
              Postęp ogółem: {(calcInventory != 0 ? ((calcInventory - calcToDo) / calcInventory * 100) : 100).toFixed(2)}%
            </Typography>
          </Grid>
        </Grid>
      </ThemeProvider>
    )

    const disabledEndInventoryButton = (
      <Button
        variant="contained"
        fullWidth
        disabled
        style={{ margin: "8px", padding: "8px" }}
      >Zakończ</Button>
    );

    const enabledEndInventoryButton = (
      <Button
        variant="contained"
        color="primary"
        fullWidth
        onClick={this.handleOpenStep1}
        style={{ margin: "8px", padding: "8px", backgroundColor: "#4caf50" }}
      >Zakończ</Button>
    );

    const notFinishedInventory = (
      <div>
        <Grid container justify="center">
          <Grid item xs={12} sm={12} md={12}>
            <Card style={{ overflow: "auto", marginTop: 0, marginBottom: "16px" }}>
              <CardBody>
                <Typography
                  style={{ marginBottom: "20px", textAlign: "center" }}
                  className={classes.textType}
                >
                  Zakończ lub wycofaj inwenturę pracownika
                </Typography>
                {activeInventoryInfo}
                <Grid container style={{ textAlign: "center" }}>
                  <Grid item xs={1} sm={2} md={2}></Grid>
                  <Grid item xs={10} sm={2} md={2}>
                    {calcToDo > 0 ? disabledEndInventoryButton : enabledEndInventoryButton}
                  </Grid>
                  <Grid item xs={1} sm={4} md={4}></Grid>
                  <Grid item xs={10} sm={2} md={2}>
                    <Button
                      variant="contained"
                      color="primary"
                      fullWidth
                      onClick={this.handleDeclineInventory}
                      style={{ margin: "8px", padding: "8px", backgroundColor: "#dc004e" }}
                    >Wycofaj do poprawy</Button>
                  </Grid>
                  <Grid item xs={1} sm={2} md={2}></Grid>
                </Grid>
              </CardBody>
            </Card>
          </Grid>
        </Grid>
      </div>
    );

    var isAdded = false;
    var isDone = false;
    var isDeleted = false;
    var equipmentID = null;
    if (myInventoryDetails != null) {
      if (myInventoryDetails.isOwned == false) {
        isDeleted = true;
      }
      if (myInventoryDetails.equipment == null) {
        isAdded = true;
      }
      if (myInventoryDetails.equipment != null) {
        equipmentID = myInventoryDetails.equipment;
      }
      if (myInventoryDetails.itemStatus != "dc") {
        isDone = true;
      }
    }

    const dialogAssetTransfer = (
      <DialogAssetTransfer
        dialogOpenAssetTransfer={openTransferToDialog}
        closeDialogAssetTransfer={this.handleCloseTransferTo}
        assetsDetails={this.props.assetsDetails}
        mode="u2u"
      />
    );

    return (
      <Fragment>
        <div className={classes.section}>
          <Box m={2}>
            {this.props.invData.status != "fi" ? notFinishedInventory : <Fragment></Fragment>}
            <AssetNavigation
              eqTypeSelected={this.handleEqTypeSelected}
            />
            <div style={{ marginTop: "30px" }}></div>
            <EmployeeInventoryList
              assets={employeeInventoryAssets}
              checked={this.handleChecked}
              downloadTrigger={this.handleDownload}
              eqTypeSelected={eqTypeSelected}
              activeListType={activeListType}
              status={this.props.invData.status}
              owner={this.props.invData.inventoryUser}
            />
          </Box>

          <Zoom in={isAssetChecked}>
            <div
              onClick={this.handleClickPreview}
              role="presentation"
              className={classes.fb2}
            >
              <Tooltip title="Pokaż szczegóły" placement="left">
                {queryInProgress ? previewButtonDi : previewButtonEn}
              </Tooltip>
            </div>
          </Zoom>
          <Zoom in={isAssetChecked && isDeleted && !isDone && this.props.invData.status != "fi"}>
            <div
              onClick={() => this.handleClickInvScrap(equipmentID, this.state.checkedAssetPk)}
              role="presentation"
              className={classes.fb1}
            >
              <Tooltip title="Zezłomuj" placement="left">
                <Fab
                  color="inherit"
                  className={classes.fabred}
                  size="small"
                  aria-label="scroll back to top"
                >
                  <DeleteIcon />
                </Fab>
              </Tooltip>
            </div>
          </Zoom>
          <Zoom in={isAssetChecked && !isAdded && !isDeleted && !isDone && this.props.invData.status != "fi"}>
            <div
              onClick={this.handleClickInvYes}
              role="presentation"
              className={classes.fb6}
            >
              <Tooltip title="Edytuj" placement="left">
                <Fab
                  color="inherit"
                  className={classes.fabgreen}
                  size="small"
                  aria-label="scroll back to top"
                >
                  <CreateIcon />
                </Fab>
              </Tooltip>
            </div>
          </Zoom>

          <Zoom in={isAssetChecked && this.props.invData.status != "fi"}>
            <div
              onClick={this.handleOpenConfirmDialog}
              role="presentation"
              className={classes.fb3}
            >
              <Tooltip title="Zmień status" placement="left">
                <Fab
                  color="inherit"
                  className={classes.fabgreen}
                  size="small"
                  aria-label="scroll back to top"
                >
                  <ShuffleIcon />
                </Fab>
              </Tooltip>
            </div>
          </Zoom>
          <Dialog
            open={openConfirmDialog}
            onClose={this.handleCloseConfirmDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth="sm"
            fullWidth
          >
            <DialogTitle id="alert-dialog-title">{"Zmień Status"}</DialogTitle>
            <DialogContent>
              <TextField
                select
                label="Zmień status na"
                value={newStatus}
                onChange={this.handleStatusChange}
                fullWidth
                margin="dense"
              >

                <MenuItem key="po" value="po">Potwierdzony</MenuItem>
                <MenuItem key="do" value="do">Dodany</MenuItem>
                <MenuItem key="zl" value="zl">Zezłomowany</MenuItem>
                <MenuItem key="pr" value="pr">Przekazany</MenuItem>
                <MenuItem key="dc" value="dc">Do sprawdzenia</MenuItem>
              </TextField>
              <TextField
                margin="dense"
                variant="outlined"
                label="Komentarz"
                type="text"
                fullWidth
                value={comment}
                onChange={this.handleCommentChange}
                required={commentRequired}
                style={{ marginTop: "20px" }}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={this.handleCloseConfirmDialog} color="primary">
                Anuluj
              </Button>
              <Button onClick={this.handleClickInvDone} color="primary" autoFocus disabled={commentRequired && !comment}>
                Potwierdź
              </Button>
            </DialogActions>
          </Dialog>

          <Zoom in={isAssetChecked && this.props.invData.status != "fi"}>
            <div
              onClick={this.handleOpenCommentDialog}
              role="presentation"
              className={classes.fb5}
            >
              <Tooltip title="Dodaj komentarz" placement="left">
                <Fab
                  color="inherit"
                  className={classes.fabblue}
                  size="small"
                  aria-label="scroll back to top"
                >
                  <CommentIcon />
                </Fab>
              </Tooltip>
            </div>
          </Zoom>
          <Dialog open={openCommentDialog} onClose={this.handleCloseCommentDialog} fullWidth maxWidth="md">
            <DialogTitle>Dodaj komentarz</DialogTitle>
            <DialogContent>
              <TextField
                autoFocus
                margin="dense"
                variant="outlined"
                label="Komentarz"
                type="text"
                fullWidth
                value={comment}
                onChange={this.handleCommentChange}
                required={commentRequired}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={this.handleCloseCommentDialog} color="primary" disabled={commentRequired}>
                Anuluj
              </Button>
              <Button onClick={this.handleSubmitComment} color="primary" disabled={commentRequired && !comment}>
                Dodaj
              </Button>
            </DialogActions>
          </Dialog>

          <Zoom in={isAssetChecked && isAdded && !isDone && this.props.invData.status != "fi"}>
            <div
              onClick={this.handleClickInvAdd}
              role="presentation"
              className={classes.fb1}
            >
              <Tooltip title="Dodaj" placement="left">
                <Fab
                  color="inherit"
                  className={classes.fabgreen}
                  size="small"
                  aria-label="scroll back to top"
                >
                  <AddIcon />
                </Fab>
              </Tooltip>
            </div>
          </Zoom>

          <Zoom in={isAssetChecked && isAdded && !isDone && this.props.invData.status != "fi"}>
            <div
              onClick={this.handleClickInvTransferFrom}
              role="presentation"
              className={classes.fb6}
            >
              <Tooltip title="Przenieś od innego pracownika" placement="left">
                <Fab
                  color="inherit"
                  className={classes.fabgreen}
                  size="small"
                  aria-label="scroll back to top"
                >
                  <SupervisorAccountIcon />
                </Fab>
              </Tooltip>
            </div>
          </Zoom>
          <Dialog open={openTransferFromDialog} onClose={this.handleCloseTransferFrom} fullWidth maxWidth="md">
            <DialogTitle>Przeniesienie na stan obecnego pracownika</DialogTitle>
            <DialogContent>
              <Box display="flex" alignItems="center">
                <TextField
                  autoFocus
                  margin="dense"
                  variant="outlined"
                  label="Extranet ID"
                  type="text"
                  fullWidth
                  value={extranetID}
                  onChange={this.handleExtIDChange}
                  required={!idRequired}
                  inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                  onInput={(e) => {
                    e.target.value = e.target.value.replace(/[^0-9]/g, '');
                  }}
                />
                <Button onClick={this.handleSearchExtID} color="primary" style={{ marginLeft: '10px' }}>
                  Szukaj
                </Button>
              </Box>
            </DialogContent>
            <DialogActions>
              <Button onClick={this.handleCloseTransferFrom} color="primary">
                Anuluj
              </Button>
              <Button onClick={this.handleTransferFrom} color="primary" disabled={!(idRequired && Object.keys(this.props.assetsDetails).length != 0)}>
                Dodaj
              </Button>
            </DialogActions>
          </Dialog>

          <Zoom in={isAssetChecked && isDeleted && !isDone && this.props.invData.status != "fi"}>
            <div
              onClick={this.handleClickInvTransferTo}
              role="presentation"
              className={classes.fb6}
            >
              <Tooltip title="Przenieś do innego pracownika" placement="left">
                <Fab
                  color="inherit"
                  className={classes.fabgreen}
                  size="small"
                  aria-label="scroll back to top"
                >
                  <SupervisorAccountIcon />
                </Fab>
              </Tooltip>
            </div>
          </Zoom>

          {openTransferToDialog && !queryInProgress && this.state.queryEnd ? dialogAssetTransfer : null}

          <Dialog
            fullScreen
            open={dialogOpen}
            onClose={this.handleClickCloseDialog}
            aria-labelledby="form-dialog-title"
          >
            <AppBar position="static" style={{ background: "#0071BC" }}>
              <Toolbar>
                <IconButton
                  edge="start"
                  color="inherit"
                  onClick={this.handleClickCloseDialog}
                  aria-label="close"
                  className={classes.menuButton}
                >
                  <CloseIcon />
                </IconButton>
                <Typography variant="h6" className={classes.title}>
                  Szczegóły środka trwałego
                </Typography>
              </Toolbar>
            </AppBar>
            <DialogContent style={{ background: "#f5f5f5" }}>
              {queryInProgress ? circle : inventoryDetails}
            </DialogContent>
          </Dialog>

          <Dialog
            fullScreen
            open={dialogCompareOpen}
            onClose={this.handleClickCloseDialogCompare}
            aria-labelledby="form-dialog-title"
          >
            <AppBar position="static" style={{ background: "#0071BC" }}>
              <Toolbar>
                <IconButton
                  edge="start"
                  color="inherit"
                  onClick={this.handleClickCloseDialogCompare}
                  aria-label="close"
                  className={classes.menuButton}
                >
                  <CloseIcon />
                </IconButton>
                <Typography variant="h6" className={classes.title}>
                  Porównanie środka trwałego
                </Typography>
              </Toolbar>
            </AppBar>
            <DialogContent style={{ background: "#f5f5f5" }}>
              {queryInProgress ? circle : inventoryDetailsCompare}
            </DialogContent>
          </Dialog>

          {this.props.auth.main_profile.pesel != null ? diaolgPesel : diaolgNoPesel}

          <Dialog open={openStep2} onClose={this.handleCloseStep2}>
            <DialogTitle>Autoryzacja wniosku: Krok 2 - Kod E-Mail</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Na twój adres e-mail został wysłany 6-cyfrowy kod autoryzacyjny.
              </DialogContentText>
              <TextField
                margin="dense"
                id="code"
                label="Wprowadź kod"
                type="text"
                fullWidth
                variant="standard"
                value={code}
                onChange={this.handleTextList("code")}
                onKeyPress={this.handleKeyPress}
              />
              <DialogContentText></DialogContentText>
              <DialogContentText>
                <Button onClick={this.handleNewCode}>Wyślij nowy kod</Button>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={this.handleCloseStep2}>Anuluj</Button>
              <Button onClick={this.handleCheckCode}>Podpisz</Button>
            </DialogActions>
          </Dialog>

          <Dialog
            fullScreen
            open={dialogOpenAddAsset}
            onClose={this.handleCloseDialogAddAsset}
            aria-labelledby="form-dialog-title"
          >
            <AppBar position="static" style={{ background: "#0071BC" }}>
              <Toolbar>
                <IconButton
                  edge="start"
                  color="inherit"
                  onClick={this.handleCloseDialogAddAsset}
                  aria-label="close"
                  className={classes.menuButton}
                >
                  <CloseIcon />
                </IconButton>
                <Typography variant="h6" className={classes.title}>
                  Dodaj nowy Środek Trwały
                </Typography>
              </Toolbar>
            </AppBar>
            <DialogContent style={{ background: "#f5f5f5" }}>
              <AddAssetInteractiveForm addAsset={this.handleAddAsset} assetData={myInventoryDetails} owner={this.props.invData.inventoryUser.pk} ownerFirstName={this.props.invData.inventoryUser.first_name} ownerLastName={this.props.invData.inventoryUser.last_name} />
            </DialogContent>
          </Dialog>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  employeeInventoryAl: state.assets.employeeInventoryAl,
  employeeInventoryNa: state.assets.employeeInventoryNa,
  employeeInventoryEl: state.assets.employeeInventoryEl,
  employeeInventoryIt: state.assets.employeeInventoryIt,
  employeeInventoryPo: state.assets.employeeInventoryPo,
  queryInProgress: state.assets.queryInProgress,
  auth: state.auth,
  usersList: state.common.users,
  userDetails: state.common.suser,
  queryInProgressCommon: state.common.queryInProgress,
  myInventoryDetails: state.assets.myInventoryDetails,
  codeValid: state.verifycodes.valid,
  suserDetails: state.common.suser,
  assetsDetails: state.assets.assetDetails,
});

export default connect(
  mapStateToProps,
  {
    getEmployeeInventoryAssets,
    clearUserDetails,
    scrapEmployeeAsset,
    sendNewNotification,
    addAsset,
    getMyInventoryDetails,
    sendNewNotificationNoMail,
    sendNewNotificationAccounting,
    sendNewNotificationControler,
    sendNewNotificationManager,
    sendNewNotificationKeepers,
    updateEmpInventoryDetails,
    reportInventory,
    sendCode,
    verifyCode,
    clearCode,
    scrapEmployeeAsset,
    transferAsset_admin,
    getAssetDetails,
    clearAssetDetails,
    getUserDetails,
  }
)(withStyles(styles, { withTheme: true })(InventoryControlerView));
