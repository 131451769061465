import React, { Component, Fragment } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import { Avatar } from "@material-ui/core";
import Card from "../components/Card/Card.js";
import CardBody from "../components/Card/CardBody.js";
//import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
import Typography from "@material-ui/core/Typography";
//import Divider from "@material-ui/core/Divider";
//import Button from "@material-ui/core/Button";
import { createMuiTheme } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";
import LinearProgress from "@material-ui/core/LinearProgress";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { startInventory, stopInventory } from "../Redux/actions/assets.js";
import { sendNewNotification } from "../Redux/actions/employee.js";
import auth from "../Redux/reducers/auth.js";

//import Dialog from "@material-ui/core/Dialog";

const materialTheme = createMuiTheme({
  palette: {
    primary: {
      main: "#0071BC",
    },
  },
  overrides: {
    MuiChip: {
      root: {
        color: "#ffffff",
        "& $avatar": {
          color: "#ffffff",
        },
      },
      icon: {
        color: "#ffffff",
      },
    },
    MuiCardActions: {
      root: {
        background: "#aaaaaa",
      },
    },
    MuiIconButton: {
      root: {
        color: "#fff",
      },
    },
  },
});

const styles = (theme) => ({
  avatar: {
    backgroundColor: "#0071BC",
    height: 50,
    width: 50,
  },
  Address: {
    textAlign: "left",
    fontFamily: "Lato, Open Sans, sans-serif",
    fontSize: "1em",
    fontWeight: "300",
    color: "#444444",
  },
  Position: {
    textAlign: "left",
    fontFamily: "Lato, Open Sans, sans-serif",
    fontSize: "1em",
    fontWeight: "400",
    color: "#0071BC",
  },
  Name: {
    textAlign: "left",
    fontFamily: "Lato, Open Sans, sans-serif",
    fontSize: "2em",
    fontWeight: "700",
    color: "#444444",
  },
  ProfileBar: {
    textAlign: "left",
    fontFamily: "Lato, Open Sans, sans-serif",
    fontSize: "1em",
    fontWeight: "400",
    color: "#444444",
  },
  Country: {
    textAlign: "left",
    fontFamily: "Lato, Open Sans, sans-serif",
    fontSize: "1em",
    fontWeight: "300",
    color: "#444444",
    textTransform: "uppercase",
  },
  large: {
    width: "90px",
    height: "90px",
  },
});

function LinearProgressWithLabel(props) {
  return (
    <Box display="flex" alignItems="center">
      <Box width="100%" mr={1}>
        <ThemeProvider theme={materialTheme}>
          <LinearProgress variant="determinate" {...props} />
        </ThemeProvider>
      </Box>
      <Box minWidth={35}>
        <Typography variant="body2" color="textSecondary">{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

export class NameCardE extends Component {
  state = {
    progress: 0,
    dialogRemovePicture: false,
    dialogAddPicture: false,
    profileImage: null,
    file: null,
    fileName: null,
  };

  static propTypes = {
    userFull: PropTypes.object.isRequired,
    activeInventory: PropTypes.any.isRequired,
    sendNewNotification: PropTypes.func.isRequired,
  };

  componentDidMount() {
    const procentProfilu = 5.882353;
    const procentInit = 7 * procentProfilu;
    var procentTotal = procentInit;

    if (
      this.props.userFull.additional_profile.phone !== "" &&
      this.props.userFull.additional_profile.phone !== null
    ) {
      procentTotal = procentTotal + procentProfilu;
    }
    if (
      this.props.userFull.additional_profile.idNumber !== "" &&
      this.props.userFull.additional_profile.idNumber !== null
    ) {
      procentTotal = procentTotal + procentProfilu;
    }
    if (
      this.props.userFull.main_profile.pesel !== "" &&
      this.props.userFull.main_profile.pesel !== null
    ) {
      procentTotal = procentTotal + procentProfilu;
    }
    if (
      this.props.userFull.main_profile.birthDate !== "" &&
      this.props.userFull.main_profile.birthDate !== null
    ) {
      procentTotal = procentTotal + procentProfilu;
    }
    if (
      this.props.userFull.main_profile.birthPlace !== "" &&
      this.props.userFull.main_profile.birthPlace !== null
    ) {
      procentTotal = procentTotal + procentProfilu;
    }
    if (
      this.props.userFull.additional_profile.addrCity !== "" &&
      this.props.userFull.additional_profile.addrCity !== null
    ) {
      procentTotal = procentTotal + procentProfilu;
    }
    if (
      this.props.userFull.additional_profile.addrStreet !== "" &&
      this.props.userFull.additional_profile.addrStreet !== null
    ) {
      procentTotal = procentTotal + procentProfilu;
    }
    if (
      this.props.userFull.additional_profile.addrPostalCode !== "" &&
      this.props.userFull.additional_profile.addrPostalCode !== null
    ) {
      procentTotal = procentTotal + procentProfilu;
    }
    if (
      this.props.userFull.additional_profile.addrCountry !== "" &&
      this.props.userFull.additional_profile.addrCountry !== null
    ) {
      procentTotal = procentTotal + procentProfilu;
    }
    if (
      this.props.userFull.main_profile.profileImage !== "" &&
      this.props.userFull.main_profile.profileImage !== null
    ) {
      procentTotal = procentTotal + procentProfilu;
    }

    this.setState({
      progress: Math.floor(procentTotal),
      profileImage: this.props.userFull.main_profile.profileImage,
    });
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.userFull.main_profile.profileImage !==
      this.props.userFull.main_profile.profileImage
    ) {
      this.setState({
        profileImage: nextProps.userFull.main_profile.profileImage,
      });
    }
  }

  handleStartInventory = () => {

    const data = {
      "inventoryUser": this.props.userFull.additional_profile.pk,
      "status": "ip",
      "inventoryStartDate": new Date().toISOString(),
    }

    const notifyBody = {
      recipient: this.props.userFull.additional_profile.email,
      module: "Środki Trwałe",
      topic: "Rozpoczęcie inwentaryzacji",
      message:
        "Użytkownik " + this.props.auth.user.first_name + " " + this.props.auth.user.last_name +
        " rozpoczął inwentaryzację Twojego sprzętu. \n" +
        "Przejdź do modułu Środki Trwałe w EXTRANET i wypełnij inwenturę."
    }

    this.props.startInventory(data);
    this.props.sendNewNotification(notifyBody);
  };

  render() {
    const { classes, userFull, activeInventory } = this.props;
    const {
      progress,
      //dialogRemovePicture,
      //dialogAddPicture,
      //profileImage,
      //fileName,
      //file,
    } = this.state;

    const startInventory = (
      <Button
        onClick={this.handleStartInventory}
        color="primary"
        autoFocus
        variant="contained"
        fullWidth
        style={{ padding: "12.5px 8px" }}
      >
        Rozpocznij inwentaryzację
      </Button>
    );

    const endInventory = (
      <Button
        color="primary"
        disabled
        autoFocus
        variant="contained"
        fullWidth
        style={{ padding: "12.5px 8px" }}
      >
        W trakcie inwentaryzacji
      </Button>
    );

    return (
      <Fragment>
        <Grid item xs={12} sm={12} md={6} lg={4}>
          <Card style={{ marginBottom: "0px" }}>
            <CardBody>
              <Grid
                container
                justify="space-between"
                alignItems="center"
                spacing={2}
              >
                <Grid item xs={7} sm={10} md={6} lg={9}>
                  <Typography className={classes.Name}>
                    {userFull.user.first_name + " " + userFull.user.last_name}
                  </Typography>
                  <Typography className={classes.Position}>
                    {userFull.additional_profile.position}
                  </Typography>
                  <Typography className={classes.Address}>
                    {userFull.additional_profile.addrStreet}
                  </Typography>
                  <Typography className={classes.Address}>
                    {userFull.additional_profile.addrPostalCode +
                      " " +
                      userFull.additional_profile.addrCity}
                  </Typography>
                  <Typography className={classes.Country}>
                    {userFull.additional_profile.addrCountry}
                  </Typography>
                </Grid>
                <Grid item xs={3} sm={2} md={4} lg={3}>
                  <Avatar
                    className={classes.large}
                    alt={userFull.user.first_name + " " + userFull.user.last_name}
                    src={userFull.main_profile.profileImage}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.ProfileBar}>
                    Kompletność profilu
                  </Typography>
                  <LinearProgressWithLabel value={progress} />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <ThemeProvider theme={materialTheme}>
                    <Typography className={classes.ProfileBar}>
                      Inwentura
                    </Typography>
                    {activeInventory ? endInventory : startInventory}
                  </ThemeProvider>
                </Grid>
              </Grid>
            </CardBody>
          </Card>
        </Grid>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  userFull: state.common.userFull,
  activeInventory: state.assets.activeInventory,
});

export default connect(
  mapStateToProps,
  {
    startInventory,
    stopInventory,
    sendNewNotification
  }
)(withStyles(styles, { withTheme: true })(NameCardE));
