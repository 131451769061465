import React, { Component, Fragment } from 'react'
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
    getMyLeaveDetails
} from "../Redux/actions/holidays";
import { pl } from "date-fns/locale";
import {
    withStyles,
} from "@material-ui/core/styles";
import { createMuiTheme } from "@material-ui/core";
import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";
import Chip from "@material-ui/core/Chip";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import IconButton from "@material-ui/core/IconButton";
import TextField from "@material-ui/core/TextField";
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { ThemeProvider } from "@material-ui/styles";

const styles = theme => ({
    appBar: {
        top: "auto",
        bottom: 0,
        background: "#0071BC"
    },
    chipNW: {
        background: "#1bab7f",
        color: "#ffffff"
    },
    chipCA: {
        background: "#c2a33a",
        color: "#ffffff"
    },
    chipZA: {
        background: "#0071BC",
        color: "#ffffff"
    }
});

const materialTheme = createMuiTheme({
    overrides: {
        MuiFormControl: {
            root: {
                width: 300,
                marginTop: 50
            },
            marginNormal: {
                marginTop: "50px"
            }
        },
        MuiPickersToolbar: {
            toolbar: {
                backgroundColor: "#0071BC"
            }
        },
        MuiPickersDay: {
            day: {
                color: "#0071BC"
            },
            daySelected: {
                backgroundColor: "#0071BC",
                "&:hover": {
                    backgroundColor: "#0071BC"
                }
            },

            dayDisabled: {
                color: "gray"
            },
            current: {
                color: "#0071BC"
            }
        },
        MuiFormLabel: {
            root: {
                "&.Mui-focused": {
                    color: "#0071BC"
                }
            }
        },
        MuiInput: {
            underline: {
                "&:after": {
                    borderBottomColor: "#0071bc"
                }
            }
        },
        MuiInputBase: {
            root: {
                textAlign: "left"
            }
        },
        MuiButton: {
            root: {},
            textPrimary: {
                color: "#0071BC"
            },
            containedPrimary: {
                backgroundColor: "#1BAB7F",
                "&:hover": {
                    backgroundColor: "#188E6A"
                }
            }
        }
    }
});

export class HolidaysViewerDetails extends Component {

    state = {

    }

    static propTypes = {
        holidays: PropTypes.object.isRequired,
        getMyLeaveDetails: PropTypes.func.isRequired,
    };

    componentDidMount() {
        this.props.getMyLeaveDetails(this.props.match.params.pk);
    }

    render() {
        const { classes } = this.props;
        const { leaveDetails } = this.props.holidays;

        var styleCSS;

        if (leaveDetails.status === "NW") {
            styleCSS = classes.chipNW;
        } else if (leaveDetails.status === "CA") {
            styleCSS = classes.chipCA;
        } else if (leaveDetails.status === "ZA") {
            styleCSS = classes.chipZA;
        }

        const chip = (
            <Chip
                className={styleCSS}
                label={leaveDetails.statusDescription}
            />
        );
        return (
            <Fragment>

                <CssBaseline />
                <Container maxWidth="sm">
                    <h1>
                        Wniosek urlopowy - szczegóły
                    </h1>
                </Container>
                <div style={{ marginBottom: 30 }}></div>
                {chip}

                <form
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center"
                    }}
                >
                    <MuiPickersUtilsProvider
                        utils={DateFnsUtils}
                        locale={pl}
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center"
                        }}
                    >
                        <ThemeProvider theme={materialTheme}>
                            <KeyboardDatePicker
                                margin="normal"
                                label="Od"
                                format="yyyy/MM/dd"
                                value={leaveDetails.startDate}
                                disabled
                            />
                        </ThemeProvider>
                        <ThemeProvider theme={materialTheme}>
                            <KeyboardDatePicker
                                margin="normal"
                                label="Do"
                                format="yyyy/MM/dd"
                                value={leaveDetails.endDate}
                                disabled
                            />
                        </ThemeProvider>
                    </MuiPickersUtilsProvider>
                    <div style={{ marginTop: 16 }}></div>
                    <ThemeProvider theme={materialTheme}>
                        <TextField
                            label="Liczba dni urlopu"
                            value={leaveDetails.daysCount || ""}
                            InputProps={{
                                readOnly: true
                            }}
                        />
                    </ThemeProvider>
                    <ThemeProvider theme={materialTheme}>
                        <FormControl required>
                            <InputLabel htmlFor="leaveType">
                                Rodzaj urlopu
                            </InputLabel>
                            <Select
                                name="leaveType"
                                value={leaveDetails.leaveType || ""}
                                inputProps={{
                                    name: "name",
                                    id: "name-readonly",
                                    readOnly: true
                                }}
                            >
                                <MenuItem value="RE">Wypoczynkowy</MenuItem>
                                <MenuItem value="ON">Na żądanie</MenuItem>
                                <MenuItem value="SP">Okolicznościowy</MenuItem>
                                <MenuItem value="SI">Chorobowy</MenuItem>
                                <MenuItem value="PA">Płatny</MenuItem>
                                <MenuItem value="UN">Bezpłatny</MenuItem>
                            </Select>
                        </FormControl>
                    </ThemeProvider>
                    <ThemeProvider theme={materialTheme}>
                        <TextField
                            multiline
                            rowsMax="3"
                            label="Komentarz"
                            inputProps={{ maxLength: 100 }}
                            margin="normal"
                            value={leaveDetails.remarks || ""}
                            InputProps={{
                                readOnly: true
                            }}
                        />
                    </ThemeProvider>
                </form>
                <div style={{ marginTop: 100 }}></div>
                <AppBar position="fixed" color="primary" className={classes.appBar}>
                    <Toolbar>
                        <IconButton
                            color="inherit"
                            edge="end"
                            component={Link}
                            to={"/holidays"}
                        >
                            <ArrowBackIcon />
                        </IconButton>
                    </Toolbar>
                </AppBar>
            </Fragment>
        )
    }
}

const mapStateToProps = state => ({
    holidays: state.holidays,
});

export default connect(mapStateToProps, {
    getMyLeaveDetails
})(withStyles(styles, { withTheme: true })(HolidaysViewerDetails))
