import React, { Component, Fragment } from "react";
import { withStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import AssetNavigation from "../Common/AssetNavigation";
import DialogAssetDetails from "../Common/DialogAssetDetails";
import DialogAddAsset from "../Common/DialogAddAsset";
import DialogAddAssetModel from "../Common/DialogAddAssetModel";
import DialogAssetTransfer from "../Common/DialogAssetTransfer";
import { connect } from "react-redux";
import AssetListWarehouse from "../Common/AssetListWarehouse";
import {
  getWarAssets,
  getAssetDetails,
  clearEquipmentModelsList,
} from "../../Redux/actions/assets";
import { styles } from "../../common/radiolineStyles";
import PropTypes from "prop-types";
import Zoom from "@material-ui/core/Zoom";
import Tooltip from "@material-ui/core/Tooltip";
import Fab from "@material-ui/core/Fab";
import VisibilityIcon from "@material-ui/icons/Visibility";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
import AddIcon from "@material-ui/icons/Add";
import PlaylistAddSharpIcon from "@material-ui/icons/PlaylistAddSharp";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";

export class MainWarehouseView extends Component {
  state = {
    dialogOpen: false,
    dialogOpenAddAsset: false,
    dialogOpenAddAssetModel: false,
    dialogOpenAssetTransfer: false,
    alertOpen: false,
    eqTypeSelected: "al",
    checkedAssetPk: null,
    isAssetChecked: false,
    activeListType: null,
  };

  static propTypes = {
    auth: PropTypes.object.isRequired,
    warAssetsAl: PropTypes.array.isRequired,
    warAssetsNa: PropTypes.array.isRequired,
    warAssetsEl: PropTypes.array.isRequired,
    warAssetsIt: PropTypes.array.isRequired,
    warAssetsPo: PropTypes.array.isRequired,
    getWarAssets: PropTypes.func.isRequired,
    getAssetDetails: PropTypes.func.isRequired,
    clearEquipmentModelsList: PropTypes.func.isRequired,
    assetDetails: PropTypes.object.isRequired,
    queryInProgress: PropTypes.bool.isRequired,
  };

  componentDidMount() {
    //this.props.clearUserDetails("s");
    //this.props.clearEmployeeAssetsState();
    this.props.getWarAssets("is", "al");
    this.props.getWarAssets("is", "na");
    this.props.getWarAssets("is", "el");
    this.props.getWarAssets("is", "it");
    this.props.getWarAssets("is", "po");
    //dorobic zapytanie do pobrania danych o oczekujace na złomowanie i oczekujące na zwrot do magazynu
  }

  componentDidUpdate(prevProps) {
    if (this.props.auth.main_profile.defaultCompany != prevProps.auth.main_profile.defaultCompany) {
      this.props.getWarAssets("is", "al");
      this.props.getWarAssets("is", "na");
      this.props.getWarAssets("is", "el");
      this.props.getWarAssets("is", "it");
      this.props.getWarAssets("is", "po");
    }
  }

  handleEqTypeSelected = (eqtypeselected) => {
    this.setState({
      eqTypeSelected: eqtypeselected,
      checkedAssetPk: null,
      isAssetChecked: false,
    });
  };

  handleChecked = (isChecked, assetpk) => {
    this.setState({
      isAssetChecked: isChecked,
      checkedAssetPk: assetpk,
    });

    if (isChecked === true) {
      this.props.getAssetDetails(assetpk);
    }
  };

  handleClickPreview = (event) => {
    this.setState({
      dialogOpen: true,
    });
  };

  handleClickCloseDialog = () => {
    this.setState({
      dialogOpen: false,
    });
  };

  handleAlertClose = () => {
    this.setState({
      alertOpen: false,
    });
  };

  triggerAddNewAsset = () => {
    this.setState({
      dialogOpenAddAsset: true,
    });
  };

  handleCloseDialogAddAsset = () => {
    this.setState({
      dialogOpenAddAsset: false,
    });

    this.props.clearEquipmentModelsList();
  };

  triggerAddNewAssetModel = () => {
    this.setState({
      dialogOpenAddAssetModel: true,
    });
  };

  handleCloseDialogAddAssetModel = () => {
    this.setState({
      dialogOpenAddAssetModel: false,
    });
  };

  triggerTransferAsset = (event) => {
    this.setState({
      dialogOpenAssetTransfer: true,
    });
  };

  handleCloseDialogAssetTransfer = () => {
    this.setState({
      dialogOpenAssetTransfer: false,
    });
  };

  handleDownload = (eqtype) => {
    var assetsToBeDownloaded = [];

    if (eqtype === "al") {
      assetsToBeDownloaded = this.props.warAssetsAl;
    } else if (eqtype === "na") {
      assetsToBeDownloaded = this.props.warAssetsNa;
    } else if (eqtype === "el") {
      assetsToBeDownloaded = this.props.warAssetsEl;
    } else if (eqtype === "it") {
      assetsToBeDownloaded = this.props.warAssetsIt;
    } else if (eqtype === "po") {
      assetsToBeDownloaded = this.props.warAssetsPo;
    } else if (eqtype === "ws") {
      assetsToBeDownloaded = this.props.warAssetsAl.concat(
        this.props.warAssetsNa,
        this.props.warAssetsEl,
        this.props.warAssetsIt,
        this.props.warAssetsPo
      );
    }

    if (assetsToBeDownloaded.length !== 0) {
      var csvRow = [];
      var A = [
        [
          "Grupa",
          "Nazwa",
          "Model",
          "Producent",
          "Numer seryjny",
          "Numer Produktu",
          "Data zakupu",
          "Data przekazania",
          "Opis dodatkowy",
          "Właściciel środka trwałego",
        ],
      ];
      var re = assetsToBeDownloaded;

      for (var item = 0; item < re.length; item++) {
        A.push([
          re[item].model.eqtype_description,
          re[item].model.name,
          re[item].model.description,
          re[item].model.vendor,
          re[item].serialNumber,
          re[item].partNumber,
          re[item].purchaseDate,
          re[item].assignDate,
          re[item].description,
          re[item].owner.first_name + " " + re[item].owner.last_name,
        ]);
      }

      for (var i = 0; i < A.length; ++i) {
        csvRow.push(A[i].join(";"));
      }

      var csvString = csvRow.join("\n");
      //var a = document.createElement("a");
      var link = window.document.createElement("a");
      link.setAttribute(
        "href",
        "data:text/csv;charset=utf-8,%EF%BB%BF" + encodeURI(csvString)
      );
      if (eqtype === "ws") {
        link.setAttribute(
          "download",
          `SrodkiTrwale_Magazyn.csv`
        );
      } else {
        link.setAttribute(
          "download",
          `SrodkiTrwale_Magazyn_${re[0].model.eqtype_description}.csv`
        );
      }
      link.click();
    } else {
      this.setState({
        alertOpen: true,
      });
    }
  };

  render() {
    const {
      classes,
      warAssetsAl,
      warAssetsEl,
      warAssetsIt,
      warAssetsNa,
      warAssetsPo,
      assetDetails,
      queryInProgress,
    } = this.props;

    const {
      equipment_warehouse_add_asset,
      equipment_warehouse_add_assetmodel,
      equipment_warehouse_view_asset,
      equipment_warehouse_movto_employee,
    } = this.props.auth.permissions;

    const isSuperUser = this.props.auth.user.is_superuser;

    const {
      eqTypeSelected,
      isAssetChecked,
      dialogOpen,
      dialogOpenAddAsset,
      dialogOpenAddAssetModel,
      dialogOpenAssetTransfer,
      alertOpen,
    } = this.state;

    const alert = (
      <Snackbar
        open={alertOpen}
        autoHideDuration={6000}
        onClose={this.handleAlertClose}
      >
        <Alert variant="filled" severity="info">
          Brak danych do zaimportowania!
        </Alert>
      </Snackbar>
    );

    const previewButtonDi = (
      <Fab
        disabled
        color="inherit"
        className={classes.fabblue}
        size="small"
        aria-label="scroll back to top"
      >
        <VisibilityIcon />
      </Fab>
    );

    const previewButtonEn = (
      <Fab
        onClick={this.handleClickPreview}
        color="inherit"
        className={classes.fabblue}
        size="small"
        aria-label="scroll back to top"
      >
        <VisibilityIcon />
      </Fab>
    );

    const transferAssetDi = (
      <Fab
        disabled
        color="inherit"
        className={classes.fabgreen}
        size="small"
        aria-label="scroll back to top"
      >
        <SupervisorAccountIcon />
      </Fab>
    );

    const transferAssetEn = (
      <Fab
        color="inherit"
        className={classes.fabgreen}
        size="small"
        aria-label="scroll back to top"
      >
        <SupervisorAccountIcon />
      </Fab>
    );

    const addnewassetmodelButtonDi = (
      <Fab
        disabled
        color="inherit"
        className={classes.fabgreen}
        size="small"
        aria-label="scroll back to top"
      >
        <PlaylistAddSharpIcon />
      </Fab>
    );

    const addnewassetmodelButtonEn = (
      <Fab
        onClick={this.triggerAddNewAssetModel}
        color="inherit"
        className={classes.fabgreen}
        size="small"
        aria-label="scroll back to top"
      >
        <PlaylistAddSharpIcon />
      </Fab>
    );

    const addnewassetButtonDi = (
      <Fab
        disabled
        color="inherit"
        className={classes.fabgreen}
        size="small"
        aria-label="scroll back to top"
      >
        <AddIcon />
      </Fab>
    );

    const addnewassetButtonEn = (
      <Fab
        onClick={this.triggerAddNewAsset}
        color="inherit"
        className={classes.fabgreen}
        size="small"
        aria-label="scroll back to top"
      >
        <AddIcon />
      </Fab>
    );

    var assets = [];

    if (eqTypeSelected === "al") {
      assets = warAssetsAl;
    } else if (eqTypeSelected === "na") {
      assets = warAssetsNa;
    } else if (eqTypeSelected === "el") {
      assets = warAssetsEl;
    } else if (eqTypeSelected === "it") {
      assets = warAssetsIt;
    } else if (eqTypeSelected === "po") {
      assets = warAssetsPo;
    }

    //inicjalizacja dialogdetails
    var dialogassetdetails = null;
    if (dialogOpen === true) {
      dialogassetdetails = (
        <DialogAssetDetails
          assetDetails={assetDetails}
          dialogOpen={dialogOpen}
          closeDialog={this.handleClickCloseDialog}
        />
      );
    }

    var dialogaddasset = null;
    if (dialogOpenAddAsset === true) {
      dialogaddasset = (
        <DialogAddAsset
          dialogOpenAddAsset={dialogOpenAddAsset}
          closeDialog={this.handleCloseDialogAddAsset}
          who="warehouse"
        />
      );
    }

    const dialogaddassetmodel = (
      <DialogAddAssetModel
        dialogOpenAddAssetModel={dialogOpenAddAssetModel}
        closeDialogAddAssetModel={this.handleCloseDialogAddAssetModel}
      />
    );

    var dialogassettransfer = null;
    if (dialogOpenAssetTransfer === true) {
      dialogassettransfer = (
        <DialogAssetTransfer
          dialogOpenAssetTransfer={dialogOpenAssetTransfer}
          closeDialogAssetTransfer={this.handleCloseDialogAssetTransfer}
          assetsDetails={this.props.assetDetails}
          mode="w2u"
        />
      );
    }

    return (
      <Fragment>
        <div className={classes.section}>
          <Box m={2}>
            <AssetNavigation eqTypeSelected={this.handleEqTypeSelected} />
            <AssetListWarehouse
              assets={assets}
              checked={this.handleChecked}
              downloadTrigger={this.handleDownload}
              eqTypeSelected={eqTypeSelected}
            />
          </Box>
        </div>
        {
          //common ACTIONS
        }
        <Zoom in={isAssetChecked}>
          <div role="presentation" className={classes.fb6}>
            <Tooltip title="Pokaż szczegóły" placement="left">
              {!queryInProgress && (equipment_warehouse_view_asset || isSuperUser)
                ? previewButtonEn
                : previewButtonDi}
            </Tooltip>
          </div>
        </Zoom>
        {
          //Transfer asset ACTIONS
        }
        <Zoom in={isAssetChecked}>
          <div
            onClick={this.triggerTransferAsset}
            role="presentation"
            className={classes.fb3}
          >
            <Tooltip title="Przekaż" placement="left">
              {!queryInProgress && (equipment_warehouse_movto_employee || isSuperUser)
                ? transferAssetEn
                : transferAssetDi}
            </Tooltip>
          </div>
        </Zoom>
        {
          //new asset/model ACTIONS
        }
        <Zoom in={true}>
          <div role="presentation" className={classes.fb5}>
            <Tooltip title="Dodaj model środka trwałego" placement="left">
              {equipment_warehouse_add_assetmodel || isSuperUser
                ? addnewassetmodelButtonEn
                : addnewassetmodelButtonDi}
            </Tooltip>
          </div>
        </Zoom>
        <Zoom in={true}>
          <div role="presentation" className={classes.fb2}>
            <Tooltip title="Dodaj środek trwały" placement="left">
              {equipment_warehouse_add_asset || isSuperUser
                ? addnewassetButtonEn
                : addnewassetButtonDi}
            </Tooltip>
          </div>
        </Zoom>
        {alert}

        {dialogassetdetails}
        {dialogaddasset}
        {dialogaddassetmodel}
        {dialogassettransfer}
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  warAssetsAl: state.assets.warAssetsAl,
  warAssetsNa: state.assets.warAssetsNa,
  warAssetsEl: state.assets.warAssetsEl,
  warAssetsIt: state.assets.warAssetsIt,
  warAssetsPo: state.assets.warAssetsPo,
  assetDetails: state.assets.assetDetails,
  queryInProgress: state.assets.queryInProgress,
  auth: state.auth,
});

export default connect(
  mapStateToProps,
  { getWarAssets, getAssetDetails, clearEquipmentModelsList }
)(withStyles(styles, { withTheme: true })(MainWarehouseView));
