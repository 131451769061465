import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import HolidaysEditForm from "./HolidaysEditForm";
import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Button from "@material-ui/core/Button";
import Chip from "@material-ui/core/Chip";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  getEmployeeLeavesDetails,
  clearEmployeeLeavesDetails,
  patchEmployeeLeave
} from "../Redux/actions/holidays-manager";
import {
  sendNotification,
  sendNewNotification
} from "../Redux/actions/employee";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Fab from "@material-ui/core/Fab";
import DoneIcon from "@material-ui/icons/Done";
import CloseIcon from "@material-ui/icons/Close";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import SaveIcon from "@material-ui/icons/Save";

const styles = theme => ({
  appBar: {
    top: "auto",
    bottom: 0,
    background: "#0071BC"
  },
  chipCA: {
    background: "#c2a33a",
    color: "#ffffff"
  },
  chipZA: {
    background: "#0071BC",
    color: "#ffffff"
  },
  grow: {
    flexGrow: 1
  },
  fabButton: {
    position: "absolute",
    zIndex: 1,
    top: -30,
    left: 0,
    right: 0,
    margin: "0 auto"
  }
});

function sprawdzCzySieRozpoczal(holidaysStartDate, holidaysEndDate) {
  //przekształć daty do array
  const startDate = holidaysStartDate.split("-");
  const endDate = holidaysEndDate.split("-");
  //usuń zera z początku miesiąca lub dnia jeżeli istnieją

  if (startDate[1].charAt(0) === "0") {
    startDate[1] = startDate[1].substr(1);
  }
  if (startDate[2].charAt(0) === "0") {
    startDate[2] = startDate[2].substr(1);
  }
  if (endDate[1].charAt(0) === "0") {
    endDate[1] = endDate[1].substr(1);
  }
  if (endDate[2].charAt(0) === "0") {
    endDate[2] = endDate[2].substr(1);
  }

  const today = new Date();
  const startDateObj = new Date(startDate[0], startDate[1] - 1, startDate[2]);
  const endDateObj = new Date(endDate[0], endDate[1] - 1, endDate[2]);

  if (startDateObj > today) {
    return 0; //urlop się jeszcze nie rozpoczął
  } else if (today >= startDateObj && endDateObj > today) {
    return 1; //urlop trwa
  } else {
    return 2; //urlop sie już skończył
  }
}

export class HolidaysManagerViewerDetails extends Component {
  state = {
    anchorEl: null,
    leaveId: null,
    dialogAccept: false,
    dialogDiscard: false,
    dialogEdit: false,
    dialogEditWarningInProgress: false,
    dialogEditWarningFinished: false,
    dialogCancel: false,
    editFlag: false,
    saveFlag: false
  };

  static propTypes = {
    auth: PropTypes.object.isRequired,
    holidaysManager: PropTypes.object.isRequired,
    getEmployeeLeavesDetails: PropTypes.func.isRequired,
    patchEmployeeLeave: PropTypes.func.isRequired,
    sendNotification: PropTypes.func.isRequired,
    sendNewNotification: PropTypes.func.isRequired,
  };

  componentWillUnmount() {
    this.props.clearEmployeeLeavesDetails();
  }

  componentDidMount() {
    this.props.getEmployeeLeavesDetails(this.props.match.params.pk);
  }

  triggerEdit = leaveId => {
    this.setState({
      leaveId: leaveId,
      anchorEl: null,
      editFlag: true,
      dialogEdit: true
    });
  };

  triggerDiscard = leaveId => {
    this.setState({
      leaveId: leaveId,
      anchorEl: null,
      dialogDiscard: true
    });
  };

  triggerCancel = leaveId => {
    this.setState({
      leaveId: leaveId,
      anchorEl: null,
      dialogCancel: true
    });
  };

  triggerAcceptLeave = leaveId => {
    this.setState({
      leaveId: leaveId,
      dialogAccept: true
    });
  };

  triggerSaveLeave = () => {
    this.setState({
      saveFlag: true,
      editFlag: false
    });
  };

  handleClick = event => {
    this.setState({
      anchorEl: event.currentTarget
    });
  };

  handleCloseMenu = () => {
    this.setState({
      anchorEl: null
    });
  };

  handleCloseDialogAccept = () => {
    this.setState({
      dialogAccept: false,
      leaveId: null
    });
  };

  handleCloseDialogDiscard = () => {
    this.setState({
      dialogDiscard: false,
      leaveId: null
    });
  };

  handleCloseDialogCancel = () => {
    this.setState({
      dialogCancel: false,
      leaveId: null
    });
  };

  handleCloseDialogEdit = () => {
    this.setState({
      dialogEdit: false,
      dialogEditWarningFinished: false,
      dialogEditWarningInProgress: false
    });
  };

  handleAcceptLeave = () => {
    const leaveId = this.state.leaveId;
    const message = "urlop został zaakceptowany";
    //przygotowanie body
    const leaveBody = {
      status: "ZA"
    };
    this.props.patchEmployeeLeave(leaveBody, leaveId, message);
    this.setState({
      dialogAccept: false,
      leaveId: null
    });

    //wysyłanie SMSa
    const recipient = this.props.holidaysManager.employeeLeavesDetails.employee.phone;
    const sender = "URLOPY";
    const smsmessage = "Twoj wniosek urlopowy zostal zaakceptowany!";
    const owner = this.props.auth.user.id;
    const notificationType = "sms"
    const isDelivered = true;
    //przygotowanie body
    const smsbody = {
      recipient,
      sender,
      message: smsmessage,
      owner,
      notificationType,
      isDelivered
    };
    this.props.sendNotification(smsbody);
  };

  handleDiscardLeave = () => {
    const leaveId = this.state.leaveId;
    const message = "urlop został odrzucony";
    //przygotowanie body
    const leaveBody = {
      status: "OD"
    };
    this.props.patchEmployeeLeave(leaveBody, leaveId, message);

    this.setState({
      dialogDiscard: false,
      leaveId: null
    });
    //wysyłanie SMSa
    const recipient = this.props.holidaysManager.employeeLeavesDetails.employee.phone;
    const sender = "URLOPY";
    const smsmessage = "Twoj wniosek urlopowy zostal odrzucony! Skontaktuj sie ze swoim przelozonym.";
    const owner = this.props.auth.user.id;
    const notificationType = "sms"
    const isDelivered = true;
    //przygotowanie body
    const smsbody = {
      recipient,
      sender,
      message: smsmessage,
      owner,
      notificationType,
      isDelivered
    };
    this.props.sendNotification(smsbody);
  };

  handleSaveLeave = (holidaysStartDate, holidaysEndDate, remarks) => {
    const leaveId = this.state.leaveId;
    const message = "wniosek został zapisany";
    //przygotowanie body
    const leaveBody = {
      startDate: holidaysStartDate,
      endDate: holidaysEndDate,
      remarks: remarks
    };
    this.props.patchEmployeeLeave(leaveBody, leaveId, message);

    this.setState({
      saveFlag: false,
      leaveId: null
    });
  };

  handleCancelLeave = () => {
    const holidaysStartDate = this.props.holidaysManager.employeeLeavesDetails
      .startDate;
    const holidaysEndDate = this.props.holidaysManager.employeeLeavesDetails
      .endDate;

    //czy urlop się rozpoczął / nie rozpoczął
    const inprogress = sprawdzCzySieRozpoczal(
      holidaysStartDate,
      holidaysEndDate
    );

    if (inprogress === 0) {
      const leaveId = this.state.leaveId;
      const message = "urlop został anulowany";
      const leaveBody = {
        status: "OD"
      };
      this.props.patchEmployeeLeave(leaveBody, leaveId, message);
      //wysyłanie SMSa
      const recipient = this.props.holidaysManager.employeeLeavesDetails.employee.phone;
      const sender = "URLOPY";
      const smsmessage = "Twoj urlop zostal anulowany! Skontaktuj sie ze swoim przelozonym.";
      const owner = this.props.auth.user.id;
      const notificationType = "sms"
      const isDelivered = true;
      //przygotowanie body
      const smsbody = {
        recipient,
        sender,
        message: smsmessage,
        owner,
        notificationType,
        isDelivered
      };
      this.props.sendNotification(smsbody);
    } else if (inprogress === 1) {
      this.setState({
        dialogEditWarningInProgress: true
      });
    } else {
      this.setState({
        dialogEditWarningFinished: false
      });
    }

    this.setState({
      dialogCancel: false,
      leaveId: null
    });
  };

  render() {
    const { classes } = this.props;
    const {
      anchorEl,
      dialogAccept,
      dialogDiscard,
      dialogEdit,
      dialogCancel,
      dialogEditWarningInProgress,
      dialogEditWarningFinished,
      editFlag,
      saveFlag
    } = this.state;

    var saveAction = (
      <Fragment>
        <Fab
          aria-label="add"
          className={classes.fabButton}
          onClick={this.triggerSaveLeave.bind(
            this,
            this.props.holidaysManager.employeeLeavesDetails.pk
          )}
        >
          <SaveIcon />
        </Fab>
      </Fragment>
    );

    var styleCSS;
    var mainAction;
    var moreMenu;

    if (this.props.holidaysManager.employeeLeavesDetails.status === "CA") {
      styleCSS = classes.chipCA;
      mainAction = (
        <Fragment>
          <Fab
            color="secondary"
            aria-label="add"
            className={classes.fabButton}
            onClick={this.triggerAcceptLeave.bind(
              this,
              this.props.holidaysManager.employeeLeavesDetails.pk
            )}
          >
            <DoneIcon />
          </Fab>
        </Fragment>
      );
      moreMenu = (
        <Fragment>
          <div className={classes.grow} />
          <IconButton color="inherit" edge="end" onClick={this.handleClick}>
            <MoreVertIcon />
          </IconButton>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={this.handleCloseMenu}
          >
            <MenuItem
              onClick={this.triggerEdit.bind(
                this,
                this.props.holidaysManager.employeeLeavesDetails.pk
              )}
            >
              edytuj
            </MenuItem>
            <MenuItem
              onClick={this.triggerDiscard.bind(
                this,
                this.props.holidaysManager.employeeLeavesDetails.pk
              )}
            >
              odrzuć
            </MenuItem>
          </Menu>
        </Fragment>
      );
    } else if (
      this.props.holidaysManager.employeeLeavesDetails.status === "ZA"
    ) {
      styleCSS = classes.chipZA;
      mainAction = (
        <Fragment>
          <Fab
            color="secondary"
            aria-label="add"
            className={classes.fabButton}
            onClick={this.triggerCancel.bind(
              this,
              this.props.holidaysManager.employeeLeavesDetails.pk
            )}
          >
            <CloseIcon />
          </Fab>
        </Fragment>
      );
      moreMenu = (
        <Fragment>
          <div className={classes.grow} />
          <IconButton color="inherit" edge="end" onClick={this.handleClick}>
            <MoreVertIcon />
          </IconButton>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={this.handleCloseMenu}
          >
            <MenuItem
              onClick={this.triggerEdit.bind(
                this,
                this.props.holidaysManager.employeeLeavesDetails.pk
              )}
            >
              edytuj
            </MenuItem>
          </Menu>
        </Fragment>
      );
    }

    const chip = (
      <Chip
        className={styleCSS}
        label={
          this.props.holidaysManager.employeeLeavesDetails.statusDescription
        }
      />
    );

    return (
      <Fragment>
        <CssBaseline />
        <Container maxWidth="sm">
          <h1>szczegóły wniosku urlopowego</h1>
        </Container>
        <div style={{ marginBottom: 30 }}></div>
        {chip}
        <HolidaysEditForm
          leavesDetails={this.props.holidaysManager.employeeLeavesDetails}
          editFlag={editFlag}
          saveTrigger={saveFlag}
          saveLeave={this.handleSaveLeave}
        />
        <div style={{ marginBottom: 50 }}></div>

        <AppBar position="fixed" color="primary" className={classes.appBar}>
          <Toolbar>
            <IconButton
              color="inherit"
              edge="end"
              component={Link}
              to={"/holidays/"}
            >
              <ArrowBackIcon />
            </IconButton>
            {editFlag ? saveAction : mainAction}
            {moreMenu}
          </Toolbar>
        </AppBar>

        <Dialog
          open={dialogAccept}
          onClose={this.handleCloseDialogAccept}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Czy zaakceptować urlop ?"}
          </DialogTitle>
          <DialogActions>
            <Button onClick={this.handleCloseDialogAccept} color="primary">
              Anuluj
            </Button>
            <Button onClick={this.handleAcceptLeave} color="primary" autoFocus>
              Zaakceptuj
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={dialogDiscard}
          onClose={this.handleCloseDialogDiscard}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Czy odrzucić wniosek o urlop ?"}
          </DialogTitle>
          <DialogActions>
            <Button onClick={this.handleCloseDialogDiscard} color="primary">
              Anuluj
            </Button>
            <Button onClick={this.handleDiscardLeave} color="primary" autoFocus>
              Odrzuć
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={dialogCancel}
          onClose={this.handleCloseDialogCancel}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Czy anulować urlop ?"}
          </DialogTitle>
          <DialogActions>
            <Button onClick={this.handleCloseDialogCancel} color="primary">
              Nie
            </Button>
            <Button onClick={this.handleCancelLeave} color="primary" autoFocus>
              Tak
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={dialogEdit}
          onClose={this.handleCloseDialogEdit}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Edycja wniosku"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Możesz edytować datę rozpoczęcia i zakończenia urlopu oraz pole
              komentarz...
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={this.handleCloseDialogEdit}
              color="primary"
              autoFocus
            >
              OK
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={dialogEditWarningInProgress}
          onClose={this.handleCloseDialogEdit}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Edycja wniosku"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Urlop trwa ... Jeżeli chcesz skrócić urlop pracownika, przejdź do
              edycji wniosku i zmień datę zakończenia urlopu.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={this.handleCloseDialogEdit}
              color="primary"
              autoFocus
            >
              OK
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={dialogEditWarningFinished}
          onClose={this.handleCloseDialogEdit}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Edycja wniosku"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Urlop już się zakończył ...
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={this.handleCloseDialogEdit}
              color="primary"
              autoFocus
            >
              OK
            </Button>
          </DialogActions>
        </Dialog>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
  holidaysManager: state.holidaysManager
});

export default connect(mapStateToProps, {
  getEmployeeLeavesDetails,
  clearEmployeeLeavesDetails,
  patchEmployeeLeave,
  sendNotification,
  sendNewNotification
})(withStyles(styles, { withTheme: true })(HolidaysManagerViewerDetails));
