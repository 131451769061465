import React, { Component, Fragment } from "react";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { lighten } from "@material-ui/core/styles";
import { createMuiTheme } from "@material-ui/core";
import { getMyInventories, clearInventory } from "../../Redux/actions/assets";
import InventoryList from "./InventoryList";

const materialTheme = createMuiTheme({
  palette: {
    primary: {
      main: "#0071BC",
    },
    secondary: {
      main: "#0071BC"
    }
  },
  overrides: {
    MuiChip: {
      root: {
        color: "#ffffff",
        "& $avatar": {
          color: "#ffffff",
        },
      },
      icon: {
        color: "#ffffff",
      },
    },
    MuiCardActions: {
      root: {
        background: "#aaaaaa",
      },
    },
  },
});

const styles = theme => ({
  root: {
    width: "100%"
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2)
  },
  table: {
    minWidth: 750
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1
  },
  checkedIcon: {
    width: "20px",
    height: "20px",
    border: "1px solid rgba(0, 0, 0, 0.84)",
    borderRadius: "3px"
  },
  uncheckedIcon: {
    width: "0px",
    height: "0px",
    padding: "9px",
    border: "1px solid rgba(0, 0, 0, .54)",
    borderRadius: "3px"
  },
  checked: {
    color: "#0071BC"
  },
  checkRoot: {
    padding: "14px",
    "&:hover": {
      backgroundColor: "unset"
    }
  },
  roottb: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1)
  },
  highlighttb:
    theme.palette.type === "light"
      ? {
        color: "#0071BC",
        backgroundColor: lighten("#338dc9", 0.85)
      }
      : {
        color: "#0071BC",
        backgroundColor: "#004f83"
      },
  titletb: {
    flex: "1 1 100%",
    textAlign: "left"
  }
});

export class MyInventoryViewer extends Component {
  static propTypes = {
    auth: PropTypes.object.isRequired,
    getMyInventories: PropTypes.func.isRequired,
    clearInventory: PropTypes.func.isRequired,
    inventory: PropTypes.any.isRequired,
    queryInProgressInventory: PropTypes.bool.isRequired,
  };

  componentDidMount() {
    this.props.getMyInventories(this.props.auth.user.id);
  }

  componentWillUnmount() {
    this.props.clearInventory();
  }

  render() {
    const { classes, inventory, queryInProgressInventory } = this.props;

    return (
      <Fragment>
        <InventoryList
          user={true}
          inventory={inventory}
          queryInProgressInventory={queryInProgressInventory}
        />
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  inventory: state.assets.inventory,
  queryInProgressInventory: state.assets.queryInProgress,
});

export default connect(
  mapStateToProps,
  { getMyInventories, clearInventory }
)(withStyles(styles, { withTheme: true })(MyInventoryViewer));
