import {
  GET_REMOTE,
  GET_MY_REMOTE,
  ADD_REMOTE,
  PATCH_REMOTE,
  DELETE_REMOTE,
  LOG_QUERY_FAILED,
  MY_LOG_QUERY_FAILED,
  LOG_QUERY_IN_PROGRESS,
  MY_LOG_QUERY_IN_PROGRESS,
  CLEAR_REMOTE_DETAILS,
  CLEAR_REMOTE
} from "../actions/types";

const initialState = {
  remoteLog: [],
  remoteMyLog: [],
  queryInProgressLOG: false,
  queryInProgressMyLOG: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_REMOTE:
      return {
        ...state,
        remoteLog: action.payload,
        queryInProgressLOG: false,
      };
    case GET_MY_REMOTE:
      return {
        ...state,
        remoteMyLog: action.payload,
        queryInProgressMyLOG: false,
      };
    case DELETE_REMOTE:
      return {
        ...state,
        remoteLog: state.remoteLog.filter(remote => remote.pk !== action.payload)
      };
    case ADD_REMOTE:
      return {
        ...state,
        remoteMyLog: [action.payload, ...state.remoteMyLog],
      };
    case PATCH_REMOTE:
      return {
        ...state,
      };
    case LOG_QUERY_IN_PROGRESS:
      return {
        ...state,
        queryInProgressLOG: true,
      };
    case LOG_QUERY_FAILED:
      return {
        ...state,
        queryInProgressLOG: false,
      };
    case MY_LOG_QUERY_IN_PROGRESS:
      return {
        ...state,
        queryInProgressMyLOG: true,
      };
    case MY_LOG_QUERY_FAILED:
      return {
        ...state,
        queryInProgressMyLOG: false,
      };
    case CLEAR_REMOTE_DETAILS:
      return {
        ...state,
        remoteMyLog: [],
        queryInProgressMyLOG: false,
      };
    case CLEAR_REMOTE:
      return {
        ...state,
        remoteLog: [],
        queryInProgressLOG: false,
      };
    default:
      return state;
  }
}
