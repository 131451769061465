import React from 'react';
import Collapse from "@material-ui/core/Collapse";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

import { ThemeProvider } from "@material-ui/styles";
import { createMuiTheme } from "@material-ui/core";

const materialTheme = createMuiTheme({
  palette: {
    primary: {
      main: "#0071BC",
    },
    secondary: {
      main: "#0071BC"
    }
  },
  overrides: {
    MuiTable: {
      root: {
        background: "rgba(0, 113, 188, 0.08)",
      },
    },
  },
});

class KidsTable extends React.Component {
  render() {
    const { kids, expanded } = this.props;

    return (
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <ThemeProvider theme={materialTheme}>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">Nazwisko dziecka</TableCell>
                  <TableCell align="center">Imię dziecka</TableCell>
                  <TableCell align="center">Data urodzenia</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {kids.map((row) => (
                  <TableRow
                    hover
                    key={row.id}
                  >
                    <TableCell align="center">{row.last_name}</TableCell>
                    <TableCell align="center">{row.first_name}</TableCell>
                    <TableCell align="center">{row.birth_date}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </ThemeProvider>
      </Collapse>
    );
  }
}

export default KidsTable;
