import axios from "axios";
import { createMessages, returnErrors } from "./messages";
import { tokenConfig } from "./auth";
import { serverurl, getDatabaseUrl } from "./databaseServerURL";

import {
  GET_EMPLOYEE_LEAVES_ONLEAVE,
  GET_EMPLOYEE_LEAVES_ACCEPT,
  GET_EMPLOYEE_LEAVES_SEARCH,
  GET_EMPLOYEE_LEAVES_PLANNED,
  H_QUERY_IN_PROGRESS,
  H_QUERY_FAILED,
  GET_EMPLOYEE_LEAVES_DETAILS,
  CLEAR_EMPLOYEE_LEAVES_DETAILS,
  PATCH_EMPLOYEE_LEAVE,
  ADD_EMPLOYEE_LEAVE
} from "./types";

//GET EMPLOYEES LEAVES (ON LEAVE)
export const getEmployeeLeaveOnLeave = () => (dispatch, getState) => {
  axios
    .get(serverurl + getDatabaseUrl() + `/myemployeeleave/?onLeave=True`, tokenConfig(getState))
    .then(res => {
      dispatch({
        type: GET_EMPLOYEE_LEAVES_ONLEAVE,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch(returnErrors(err.response.data.detail, err.response.status))
    );
};

export const getEmployeeLeavePlanned = () => (dispatch, getState) => {
  axios
    .get(serverurl + getDatabaseUrl() + `/myemployeeleave/?planned=True`, tokenConfig(getState))
    .then(res => {
      dispatch({
        type: GET_EMPLOYEE_LEAVES_PLANNED,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch(returnErrors(err.response.data.detail, err.response.status))
    );
};

//GET EMPLOYEES LEAVES (ACCEPT)
export const getEmployeeLeaveAccept = () => (dispatch, getState) => {
  axios
    .get(
      serverurl + getDatabaseUrl() + `/myemployeeleave/?waitForAcceptance=True`,
      tokenConfig(getState)
    )
    .then(res => {
      dispatch({
        type: GET_EMPLOYEE_LEAVES_ACCEPT,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch(returnErrors(err.response.data.detail, err.response.status))
    );
};

//GET EMPLOYEE LEAVES Search
export const getEmployeeLeavesSearch = surname => (dispatch, getState) => {
  dispatch({ type: H_QUERY_IN_PROGRESS });
  axios
    .get(
      serverurl + getDatabaseUrl() + `/myemployeeleave/?surname=${surname}&thisYear=True`,
      tokenConfig(getState)
    )
    .then(res => {
      dispatch({
        type: GET_EMPLOYEE_LEAVES_SEARCH,
        payload: res.data
      });
    })
    .catch(err => {
      dispatch(returnErrors(err.response.data.detail, err.response.status));
      dispatch({ type: H_QUERY_FAILED });
    });
};

//GET EMPLOYEE LEAVES DETAILS
export const getEmployeeLeavesDetails = pk => (dispatch, getState) => {
  axios
    .get(serverurl + getDatabaseUrl() + `/myemployeeleave/${pk}`, tokenConfig(getState))
    .then(res => {
      dispatch({
        type: GET_EMPLOYEE_LEAVES_DETAILS,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch(returnErrors(err.response.data.detail, err.response.status))
    );
};

//CLEAR EMPLOYEE LEAVES DETAILS
export const clearEmployeeLeavesDetails = () => (dispatch, getState) => {
  dispatch({ type: CLEAR_EMPLOYEE_LEAVES_DETAILS });
};

//PATCH EMPLOYEE LEAVE
export const patchEmployeeLeave = (leaveBody, pk, message) => (
  dispatch,
  getState
) => {
  axios
    .patch(
      serverurl + getDatabaseUrl() + `/myemployeeleave/${pk}`,
      leaveBody,
      tokenConfig(getState)
    )
    .then(res => {
      dispatch(createMessages({ patchEmployeeLeave: message }));
      dispatch({
        type: PATCH_EMPLOYEE_LEAVE,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch(returnErrors(err.response.data.detail, err.response.status))
    );
};

//ADD EMPLOYEE LEAVE
export const addEmployeeLeave = leave => (dispatch, getState) => {
  axios
    .post(serverurl + getDatabaseUrl() + "/myemployeeleave2/", leave, tokenConfig(getState))
    .then(res => {
      dispatch(createMessages({ addLeave: "Wniosek został wystawiony" }));
      dispatch({
        type: ADD_EMPLOYEE_LEAVE,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch(returnErrors(err.response.data.detail, err.response.status))
    );
};
