import React, { Component, Fragment } from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";

import { createMuiTheme } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";
import Box from "@material-ui/core/Box";
import Badge from "@material-ui/core/Badge";
import { getFormattedDateTime } from "../common/commonFunctions";

import IconButton from "@material-ui/core/IconButton";
import InfoIcon from "@material-ui/icons/Info";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { addTripComment as addTripCommentE } from "../Redux/actions/trips";
import { addTripComment as addTripCommentM } from "../Redux/actions/trips-manager";

const styles = theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: "white"
  },
  card: {
    width: "100%",
    marginTop: 10,
    backgroundColor: "#fafafa"
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)"
  },
  title: {
    fontSize: 12,
    fontFamily: "Lato, Open Sans, sans-serif",
    color: "#909090",
    fontWeight: "300"
  },
  content: {
    fontSize: 14,
    fontFamily: "Lato, Open Sans, sans-serif",
    fontWeight: "300"
  },
  date: {
    fontSize: 12,
    fontFamily: "Lato, Open Sans, sans-serif",
    marginTop: 5,
    textAlign: "right",
    fontWeight: "300",
    color: "#0071BC"
  }
});

const materialTheme = createMuiTheme({
  overrides: {
    MuiCardContent: {
      root: {
        textAlign: "left"
      }
    }
  }
});

const CssTextField = withStyles({
  root: {
    margin: 0,
    width: 250,
    flexWrap: "wrap",
    "& label.Mui-focused": {
      color: "#0071bc"
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#0071bc"
    }
  }
})(TextField);

export class Comment extends Component {
  state = {
    dialogInfo: false,
    tripId: null,
    dialogAddComment: false,
    anchorEl: null,
    remarks: ""
  };

  static propTypes = {
    auth: PropTypes.object.isRequired,
    addTripCommentE: PropTypes.func.isRequired,
    addTripCommentM: PropTypes.func.isRequired
  };

  handleClickInfo = () => {
    this.setState({
      dialogInfo: true
    });
  };

  handleCloseDialogInfo = () => {
    this.setState({
      dialogInfo: false
    });
  };

  triggerAddComment = tripId => {
    this.setState({
      tripId: tripId,
      dialogAddComment: true,
      anchorEl: null
    });
  };

  handleCloseDialogAddComment = () => {
    this.setState({
      dialogAddComment: false,
      tripId: null,
      remarks: ""
    });
  };

  handleTextList = name => ({ target: { value } }) => {
    this.setState({
      ...this.state,
      [name]: value
    });
  };

  handleAddTripComment = () => {
    const url = window.location.href;
    const isManagerView = url.includes("employees");

    const trip = this.state.tripId;
    const remarks = this.state.remarks;
    const author =
      this.props.employeeSecureDetails.owner.first_name +
      " " +
      this.props.employeeSecureDetails.owner.last_name;
    //przygotowanie body
    const tripBody = {
      trip,
      remarks,
      author
    };
    if (isManagerView === false) {
      this.props.addTripCommentE(tripBody);
    } else {
      this.props.addTripCommentM(tripBody);
    }
    this.setState({
      dialogAddComment: false,
      tripId: null,
      remarks: ""
    });
  };

  render() {
    const { classes, tripDetails } = this.props;
    const tripDetailComments = tripDetails.comments;
    const { dialogInfo, dialogAddComment, remarks } = this.state;

    var commentQuantity;
    var commentsContent;
    if (tripDetailComments === undefined) {
      commentQuantity = 0;
      commentsContent = (
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="center"
          alignItems="center"
          p={1}
          m={0}
          bgcolor="background.paper"
          style={{ marginBottom: "10px" }}
        ></Box>
      );
    } else if (tripDetailComments.length === 0) {
      commentQuantity = tripDetailComments.length;
      commentsContent = (
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="center"
          alignItems="center"
          p={1}
          m={0}
          bgcolor="background.paper"
          style={{ marginBottom: "10px" }}
        >
          <h3>nie ma jeszcze żadnych komentarzy</h3>
        </Box>
      );
    } else {
      commentQuantity = tripDetailComments.length;
      commentsContent = (
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          p={1}
          m={0}
          bgcolor="background.paper"
          style={{ marginBottom: "10px" }}
        >
          <ThemeProvider theme={materialTheme}>
            {tripDetailComments.map(comment => {
              const dateC = new Date(comment.date);
              const dateCFormatted = getFormattedDateTime(dateC);

              return (
                <Fragment key={"Fragment" + comment.pk}>
                  <Card key={"Card" + comment.pk} className={classes.card}>
                    <CardContent>
                      <Typography className={classes.title} gutterBottom>
                        {comment.author}
                      </Typography>
                      <Typography variant="body2" className={classes.content}>
                        {comment.remarks}
                      </Typography>
                    </CardContent>
                  </Card>
                  <div style={{ width: "100%" }}>
                    <Typography className={classes.date}>
                      {dateCFormatted}
                    </Typography>
                  </div>
                </Fragment>
              );
            })}
          </ThemeProvider>
        </Box>
      );
    }

    return (
      <Fragment>
        <div style={{ width: "100%" }}>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="center"
            alignItems="center"
            p={0}
            m={0}
            bgcolor="background.paper"
          >
            <Box p={1}>
              <h2>
                <Badge badgeContent={commentQuantity} color="secondary">
                  Komentarze
                </Badge>
              </h2>
            </Box>

            <Box p={1}>
              <IconButton
                color="primary"
                aria-label="upload picture"
                component="span"
                onClick={this.handleClickInfo}
              >
                <InfoIcon fontSize="large" style={{ color: "#0071bc" }} />
              </IconButton>
            </Box>
          </Box>
          <Box
            display="flex"
            alignItems="center"
            p={1}
            m={0}
            bgcolor="background.paper"
          >
            <Box p={1} flexGrow={1}>
              <h3 style={{ textAlign: "left" }}>Dodaj nowy komentarz</h3>
            </Box>
            <Box p={1}>
              <IconButton
                color="default"
                aria-label="upload picture"
                component="span"
                onClick={this.triggerAddComment.bind(
                  this,
                  this.props.tripDetails.pk
                )}
              >
                <AddCircleOutlineIcon fontSize="large" />
              </IconButton>
            </Box>
          </Box>
          {commentsContent}
        </div>

        <Dialog
          open={dialogInfo}
          onClose={this.handleCloseDialogInfo}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Info</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Tutaj możesz dodać komentarz do delegacji. Twój przełożony również
              może zamieszczać komentarze, dlatego w kluczowych etapach
              procesowania delegacji warto sprawdzić, czy nie ma tutaj dla
              Ciebie jakiś ważnych informacji.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleCloseDialogInfo} color="primary">
              OK
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={dialogAddComment}
          onClose={this.handleCloseDialogAddComment}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <CssTextField
              required
              label="komentarz do delegacji"
              multiline
              rows="4"
              margin="normal"
              value={remarks || ""}
              onChange={this.handleTextList("remarks")}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleCloseDialogAddComment} color="primary">
              Anuluj
            </Button>
            <Button
              onClick={this.handleAddTripComment}
              color="primary"
              autoFocus
            >
              Zapisz
            </Button>
          </DialogActions>
        </Dialog>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
  employeeSecureDetails: state.employee.employeeSecureDetails
});

export default connect(
  mapStateToProps,
  {
    addTripCommentE,
    addTripCommentM
  }
)(withStyles(styles, { withTheme: true })(Comment));
