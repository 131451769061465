import {
  GET_TRANSACTIONS_EUR,
  GET_TRANSACTIONS_PLN,
  GET_TRANSACTIONS_SEK,
  GET_TRANSACTIONS_NOK,
  UPDATE_TRANSACTIONS_EUR,
  UPDATE_TRANSACTIONS_PLN,
  UPDATE_TRANSACTIONS_SEK,
  UPDATE_TRANSACTIONS_NOK,
  RELOAD_TRANSACTIONS,
  CLEAR_TRANSACTIONS,
  QUERY_IN_PROGRESS_TR_EUR,
  QUERY_IN_PROGRESS_TR_PLN,
  QUERY_IN_PROGRESS_TR_SEK,
  QUERY_IN_PROGRESS_TR_NOK,
  GET_TRANSACTIONS_AK,
  GET_TRANSACTIONS_RT,
  GET_TRANSACTIONS_PB,
  GET_TRANSACTIONS_DT,
  RS_TRANSACTIONS_AK,
  RS_TRANSACTIONS_RT,
  RS_TRANSACTIONS_PB,
  RS_TRANSACTIONS_DT,
  QUERY_IN_PROGRESS_AK,
  QUERY_IN_PROGRESS_RT,
  QUERY_IN_PROGRESS_PB,
  QUERY_IN_PROGRESS_DT,
  GET_TRANSACTION_DETAILS,
  QUERY_IN_PROGRESS_TR_DETAILS,
  PATCH_TRANSACTION_DETAILS,
  ADD_TRANSACTION_WI,
  ADD_TRANSACTION_CP,
  ADD_TRANSACTION_CH,
  ADD_TRANSACTION_PB,
  ADD_TRANSACTION_DT,
  ADD_TRANSACTION_RT,
  ADD_TRANSACTION_OC,
  GET_CARDS,
  QUERY_IN_PROGRESS_CARDS,
  GET_CARDS_DETAILS,
  QUERY_IN_PROGRESS_CARDSDETAILS,
  ADD_BUSINESSCARDFILE,
  LOAD_IN_PROGRESS,
  DATA_CHANGE_IN_PROGRESS,
  //stat messages
  GET_TRANSACTIONS_STAT_AK,
  GET_TRANSACTIONS_STAT_AR,
  GET_TRANSACTIONS_STAT_NOIN,
  QUERY_IN_PROGRESS_STAT_AK,
  QUERY_IN_PROGRESS_STAT_AR,
  QUERY_IN_PROGRESS_STAT_NOIN,
} from "../actions/types";
import { getBalance } from "../../Accounting/Common/calculateBalance";

const initialState = {
  queryInProgress_tr_eur: false,
  queryInProgress_tr_pln: false,
  queryInProgress_tr_sek: false,
  queryInProgress_tr_nok: false,
  transactions_eur: [],
  transactions_pln: [],
  transactions_sek: [],
  transactions_nok: [],
  transactions_ak: [],
  transactions_rt: [],
  transactions_pb: [],
  transactions_dt: [],
  cards: [],
  cardsDetails: {},
  queryInProgress_cards: false,
  queryInProgress_cardsdetails: false,
  loadInProgress: false,
  reload_transactions: false,
  reload_balance: false,
  queryInProgress_ak: false,
  queryInProgress_rt: false,
  queryInProgress_pb: false,
  queryInProgress_dt: false,
  queryInProgress_tr_details: false,
  transactionDetails: {},
  balanceEUR: {
    wb_pk: 0,
    zpk: 0,
    zpg: 0,
    tnz: 0,
    pb: 0,
    dt: 0,
    rt: 0,
    zpb: 0,
    zdt: 0,
    zrt: 0,
    sum: 0,
  },
  balancePLN: {
    wb_pk: 0,
    zpk: 0,
    zpg: 0,
    tnz: 0,
    pb: 0,
    dt: 0,
    rt: 0,
    zpb: 0,
    zdt: 0,
    zrt: 0,
    sum: 0,
  },
  balanceSEK: {
    wb_pk: 0,
    zpk: 0,
    zpg: 0,
    tnz: 0,
    pb: 0,
    dt: 0,
    rt: 0,
    zpb: 0,
    zdt: 0,
    zrt: 0,
    sum: 0,
  },
  balanceNOK: {
    wb_pk: 0,
    zpk: 0,
    zpg: 0,
    tnz: 0,
    pb: 0,
    dt: 0,
    rt: 0,
    zpb: 0,
    zdt: 0,
    zrt: 0,
    sum: 0,
  },
  transactionsStat_ak: [],
  transactionsStat_ar: [],
  transactionsStat_noin: [],
  queryInProgress_stat_ak: false,
  queryInProgress_stat_ar: false,
  queryInProgress_stat_noin: false,
  dataChangeInProgress: false,
};

//var balance = "";

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_TRANSACTIONS_EUR:
      return {
        ...state,
        transactions_eur: action.payload,
        balanceEUR: getBalance(action.payload),
        queryInProgress_tr_eur: false,
      };
    case GET_TRANSACTIONS_PLN:
      return {
        ...state,
        transactions_pln: action.payload,
        balancePLN: getBalance(action.payload),
        queryInProgress_tr_pln: false,
      };
    case GET_TRANSACTIONS_SEK:
      return {
        ...state,
        transactions_sek: action.payload,
        balanceSEK: getBalance(action.payload),
        queryInProgress_tr_sek: false,
      };
    case GET_TRANSACTIONS_NOK:
      return {
        ...state,
        transactions_nok: action.payload,
        balanceNOK: getBalance(action.payload),
        queryInProgress_tr_nok: false,
      };
    case UPDATE_TRANSACTIONS_EUR:
      if (action.operation !== "monit") {
        return {
          ...state,
          transactions_eur: state.transactions_eur.map((transaction) =>
            transaction.pk === action.payload.pk ? action.payload : transaction
          ),
          balanceEUR: getBalance(
            state.transactions_eur.map((transaction) =>
              transaction.pk === action.payload.pk
                ? action.payload
                : transaction
            )
          ),
        };
      } else {
        return {
          ...state,
          transactions_eur: state.transactions_eur.map((transaction) =>
            transaction.pk === action.payload.pk ? action.payload : transaction
          ),
        };
      }
    case UPDATE_TRANSACTIONS_PLN:
      if (action.operation !== "monit") {
        return {
          ...state,
          transactions_pln: state.transactions_pln.map((transaction) =>
            transaction.pk === action.payload.pk ? action.payload : transaction
          ),
          balancePLN: getBalance(
            state.transactions_pln.map((transaction) =>
              transaction.pk === action.payload.pk
                ? action.payload
                : transaction
            )
          ),
        };
      } else {
        return {
          ...state,
          transactions_pln: state.transactions_pln.map((transaction) =>
            transaction.pk === action.payload.pk ? action.payload : transaction
          ),
        };
      }

    case UPDATE_TRANSACTIONS_SEK:
      if (action.operation !== "monit") {
        return {
          ...state,
          transactions_sek: state.transactions_sek.map((transaction) =>
            transaction.pk === action.payload.pk ? action.payload : transaction
          ),
          balanceSEK: getBalance(
            state.transactions_sek.map((transaction) =>
              transaction.pk === action.payload.pk
                ? action.payload
                : transaction
            )
          ),
        };
      } else {
        return {
          ...state,
          transactions_sek: state.transactions_sek.map((transaction) =>
            transaction.pk === action.payload.pk ? action.payload : transaction
          ),
        };
      }

    case UPDATE_TRANSACTIONS_NOK:
      if (action.operation !== "monit") {
        return {
          ...state,
          transactions_nok: state.transactions_nok.map((transaction) =>
            transaction.pk === action.payload.pk ? action.payload : transaction
          ),
          balanceNOK: getBalance(
            state.transactions_nok.map((transaction) =>
              transaction.pk === action.payload.pk
                ? action.payload
                : transaction
            )
          ),
        };
      } else {
        return {
          ...state,
          transactions_nok: state.transactions_nok.map((transaction) =>
            transaction.pk === action.payload.pk ? action.payload : transaction
          ),
        };
      }

    case RELOAD_TRANSACTIONS:
      return {
        ...state,
        reload_transactions: action.flag,
      };
    case CLEAR_TRANSACTIONS:
      return {
        ...state,
        transactions_eur: [],
        transactions_pln: [],
        transactions_sek: [],
        transactions_nok: [],
        balanceEUR: {
          wb_pk: 0,
          zpk: 0,
          zpg: 0,
          tnz: 0,
          pb: 0,
          dt: 0,
          rt: 0,
          sum: 0,
        },
        balancePLN: {
          wb_pk: 0,
          zpk: 0,
          zpg: 0,
          tnz: 0,
          pb: 0,
          dt: 0,
          rt: 0,
          sum: 0,
        },
        balanceSEK: {
          wb_pk: 0,
          zpk: 0,
          zpg: 0,
          tnz: 0,
          pb: 0,
          dt: 0,
          rt: 0,
          sum: 0,
        },
        balanceNOK: {
          wb_pk: 0,
          zpk: 0,
          zpg: 0,
          tnz: 0,
          pb: 0,
          dt: 0,
          rt: 0,
          sum: 0,
        },
      };
    case QUERY_IN_PROGRESS_TR_EUR:
      return {
        ...state,
        queryInProgress_tr_eur: true,
      };
    case QUERY_IN_PROGRESS_TR_PLN:
      return {
        ...state,
        queryInProgress_tr_pln: true,
      };
    case QUERY_IN_PROGRESS_TR_SEK:
      return {
        ...state,
        queryInProgress_tr_sek: true,
      };
    case QUERY_IN_PROGRESS_TR_NOK:
      return {
        ...state,
        queryInProgress_tr_nok: true,
      };
    case GET_TRANSACTION_DETAILS:
      return {
        ...state,
        transactionDetails: action.payload,
        queryInProgress_tr_details: false,
      };
    case QUERY_IN_PROGRESS_TR_DETAILS:
      return {
        ...state,
        queryInProgress_tr_details: true,
      };
    case PATCH_TRANSACTION_DETAILS:
      return {
        ...state,
        transactionDetails: action.payload,
      };
    case ADD_TRANSACTION_WI:
      if (action.payload.currency === "eur") {
        return {
          ...state,
          transactions_eur: [action.payload, ...state.transactions_eur],
          balanceEUR: {
            ...state.balanceEUR,
            wb_pk: state.balanceEUR.wb_pk + Number(action.payload.value),
            sum: state.balanceEUR.sum - Number(action.payload.value),
          },
        };
      } else if (action.payload.currency === "pln") {
        return {
          ...state,
          transactions_pln: [action.payload, ...state.transactions_pln],
          balancePLN: {
            ...state.balancePLN,
            wb_pk: state.balancePLN.wb_pk + Number(action.payload.value),
            sum: state.balancePLN.sum - Number(action.payload.value),
          },
        };
      } else if (action.payload.currency === "sek") {
        return {
          ...state,
          transactions_sek: [action.payload, ...state.transactions_sek],
          balanceSEK: {
            ...state.balanceSEK,
            wb_pk: state.balanceSEK.wb_pk + Number(action.payload.value),
            sum: state.balanceSEK.sum - Number(action.payload.value),
          },
        };
      } else if (action.payload.currency === "nok") {
        return {
          ...state,
          transactions_nok: [action.payload, ...state.transactions_nok],
          balanceNOK: {
            ...state.balanceNOK,
            wb_pk: state.balanceNOK.wb_pk + Number(action.payload.value),
            sum: state.balanceNOK.sum - Number(action.payload.value),
          },
        };
      }
      break;
    case ADD_TRANSACTION_CP:
      if (action.payload.currency === "eur") {
        return {
          ...state,
          transactions_eur: [action.payload, ...state.transactions_eur],
          balanceEUR: {
            ...state.balanceEUR,
            wb_pk: state.balanceEUR.wb_pk + Number(action.payload.value),
            tnz: state.balanceEUR.tnz + Number(action.payload.value),
            sum: state.balanceEUR.sum - Number(action.payload.value),
          },
        };
      } else if (action.payload.currency === "pln") {
        return {
          ...state,
          transactions_pln: [action.payload, ...state.transactions_pln],
          balancePLN: {
            ...state.balancePLN,
            wb_pk: state.balancePLN.wb_pk + Number(action.payload.value),
            tnz: state.balancePLN.tnz + Number(action.payload.value),
            sum: state.balancePLN.sum - Number(action.payload.value),
          },
        };
      } else if (action.payload.currency === "sek") {
        return {
          ...state,
          transactions_sek: [action.payload, ...state.transactions_sek],
          balanceSEK: {
            ...state.balanceSEK,
            wb_pk: state.balanceSEK.wb_pk + Number(action.payload.value),
            tnz: state.balanceSEK.tnz + Number(action.payload.value),
            sum: state.balanceSEK.sum - Number(action.payload.value),
          },
        };
      } else if (action.payload.currency === "nok") {
        return {
          ...state,
          transactions_nok: [action.payload, ...state.transactions_nok],
          balanceNOK: {
            ...state.balanceNOK,
            wb_pk: state.balanceNOK.wb_pk + Number(action.payload.value),
            tnz: state.balanceNOK.tnz + Number(action.payload.value),
            sum: state.balanceNOK.sum - Number(action.payload.value),
          },
        };
      }
      break;
    case ADD_TRANSACTION_OC:
      if (action.payload.currency === "eur") {
        return {
          ...state,
          transactions_eur: [action.payload, ...state.transactions_eur],
          balanceEUR: {
            ...state.balanceEUR,
            tnz: state.balanceEUR.tnz + Number(action.payload.value),
          },
        };
      } else if (action.payload.currency === "pln") {
        return {
          ...state,
          transactions_pln: [action.payload, ...state.transactions_pln],
          balancePLN: {
            ...state.balancePLN,
            tnz: state.balancePLN.tnz + Number(action.payload.value),
          },
        };
      } else if (action.payload.currency === "sek") {
        return {
          ...state,
          transactions_sek: [action.payload, ...state.transactions_sek],
          balanceSEK: {
            ...state.balanceSEK,
            tnz: state.balanceSEK.tnz + Number(action.payload.value),
          },
        };
      } else if (action.payload.currency === "nok") {
        return {
          ...state,
          transactions_nok: [action.payload, ...state.transactions_nok],
          balanceNOK: {
            ...state.balanceNOK,
            tnz: state.balanceNOK.tnz + Number(action.payload.value),
          },
        };
      }
      break;
    case ADD_TRANSACTION_CH:
      if (action.payload.currency === "eur") {
        return {
          ...state,
          transactions_eur: [action.payload, ...state.transactions_eur],
          balanceEUR: {
            ...state.balanceEUR,
            tnz: state.balanceEUR.tnz + Number(action.payload.value),
          },
        };
      } else if (action.payload.currency === "pln") {
        return {
          ...state,
          transactions_pln: [action.payload, ...state.transactions_pln],
          balancePLN: {
            ...state.balancePLN,
            tnz: state.balancePLN.tnz + Number(action.payload.value),
          },
        };
      } else if (action.payload.currency === "sek") {
        return {
          ...state,
          transactions_sek: [action.payload, ...state.transactions_sek],
          balanceSEK: {
            ...state.balanceSEK,
            tnz: state.balanceSEK.tnz + Number(action.payload.value),
          },
        };
      } else if (action.payload.currency === "nok") {
        return {
          ...state,
          transactions_nok: [action.payload, ...state.transactions_nok],
          balanceNOK: {
            ...state.balanceNOK,
            tnz: state.balanceNOK.tnz + Number(action.payload.value),
          },
        };
      }
      break;
    case ADD_TRANSACTION_PB:
      if (action.payload.currency === "eur") {
        return {
          ...state,
          transactions_eur: [action.payload, ...state.transactions_eur],
          balanceEUR: {
            ...state.balanceEUR,
            zpb: state.balanceEUR.zpb + Number(action.payload.value),
            sum: state.balanceEUR.sum - Number(action.payload.value),
          },
        };
      } else if (action.payload.currency === "pln") {
        return {
          ...state,
          transactions_pln: [action.payload, ...state.transactions_pln],
          balancePLN: {
            ...state.balancePLN,
            zpb: state.balancePLN.zpb + Number(action.payload.value),
            sum: state.balancePLN.sum - Number(action.payload.value),
          },
        };
      } else if (action.payload.currency === "sek") {
        return {
          ...state,
          transactions_sek: [action.payload, ...state.transactions_sek],
          balanceSEK: {
            ...state.balanceSEK,
            zpb: state.balanceSEK.zpb + Number(action.payload.value),
            sum: state.balanceSEK.sum - Number(action.payload.value),
          },
        };
      } else if (action.payload.currency === "nok") {
        return {
          ...state,
          transactions_nok: [action.payload, ...state.transactions_nok],
          balanceNOK: {
            ...state.balanceNOK,
            zpb: state.balanceNOK.zpb + Number(action.payload.value),
            sum: state.balanceNOK.sum - Number(action.payload.value),
          },
        };
      }
      break;
    case ADD_TRANSACTION_DT:
      if (action.payload.currency === "eur") {
        return {
          ...state,
          transactions_eur: [action.payload, ...state.transactions_eur],
          balanceEUR: {
            ...state.balanceEUR,
            zdt: state.balanceEUR.zdt + Number(action.payload.value),
            sum: state.balanceEUR.sum - Number(action.payload.value),
          },
        };
      } else if (action.payload.currency === "pln") {
        return {
          ...state,
          transactions_pln: [action.payload, ...state.transactions_pln],
          balancePLN: {
            ...state.balancePLN,
            zdt: state.balancePLN.zdt + Number(action.payload.value),
            sum: state.balancePLN.sum - Number(action.payload.value),
          },
        };
      } else if (action.payload.currency === "sek") {
        return {
          ...state,
          transactions_sek: [action.payload, ...state.transactions_sek],
          balanceSEK: {
            ...state.balanceSEK,
            zdt: state.balanceSEK.zdt + Number(action.payload.value),
            sum: state.balanceSEK.sum - Number(action.payload.value),
          },
        };
      } else if (action.payload.currency === "nok") {
        return {
          ...state,
          transactions_nok: [action.payload, ...state.transactions_nok],
          balanceNOK: {
            ...state.balanceNOK,
            zdt: state.balanceNOK.zdt + Number(action.payload.value),
            sum: state.balanceNOK.sum - Number(action.payload.value),
          },
        };
      }
      break;
    case ADD_TRANSACTION_RT:
      if (action.payload.currency === "eur") {
        return {
          ...state,
          transactions_eur: [action.payload, ...state.transactions_eur],
          balanceEUR: {
            ...state.balanceEUR,
            zrt: state.balanceEUR.zrt + Number(action.payload.value),
            sum: state.balanceEUR.sum + Number(action.payload.value),
          },
        };
      } else if (action.payload.currency === "pln") {
        return {
          ...state,
          transactions_pln: [action.payload, ...state.transactions_pln],
          balancePLN: {
            ...state.balancePLN,
            zrt: state.balancePLN.zrt + Number(action.payload.value),
            sum: state.balancePLN.sum + Number(action.payload.value),
          },
        };
      } else if (action.payload.currency === "sek") {
        return {
          ...state,
          transactions_sek: [action.payload, ...state.transactions_sek],
          balanceSEK: {
            ...state.balanceSEK,
            zrt: state.balanceSEK.zrt + Number(action.payload.value),
            sum: state.balanceSEK.sum + Number(action.payload.value),
          },
        };
      } else if (action.payload.currency === "nok") {
        return {
          ...state,
          transactions_nok: [action.payload, ...state.transactions_nok],
          balanceNOK: {
            ...state.balanceNOK,
            zrt: state.balanceNOK.zrt + Number(action.payload.value),
            sum: state.balanceNOK.sum + Number(action.payload.value),
          },
        };
      }
      break;
    case GET_TRANSACTIONS_AK:
      return {
        ...state,
        transactions_ak: action.payload,
        queryInProgress_ak: false,
      };
    case GET_TRANSACTIONS_RT:
      return {
        ...state,
        transactions_rt: action.payload,
        queryInProgress_rt: false,
      };
    case GET_TRANSACTIONS_PB:
      return {
        ...state,
        transactions_pb: action.payload,
        queryInProgress_pb: false,
      };
    case GET_TRANSACTIONS_DT:
      return {
        ...state,
        transactions_dt: action.payload,
        queryInProgress_dt: false,
      };
    case QUERY_IN_PROGRESS_AK:
      return {
        ...state,
        queryInProgress_ak: true,
      };
    case QUERY_IN_PROGRESS_RT:
      return {
        ...state,
        queryInProgress_rt: true,
      };
    case QUERY_IN_PROGRESS_PB:
      return {
        ...state,
        queryInProgress_pb: true,
      };
    case QUERY_IN_PROGRESS_DT:
      return {
        ...state,
        queryInProgress_dt: true,
      };

    case RS_TRANSACTIONS_AK:
      return {
        ...state,
        transactions_ak: state.transactions_ak.filter(
          (transaction) => transaction.pk !== action.payload.pk
        ),
        transactionDetails: {},
      };
    case RS_TRANSACTIONS_RT:
      return {
        ...state,
        transactions_rt: state.transactions_rt.filter(
          (transaction) => transaction.pk !== action.payload.pk
        ),
        transactionDetails: {},
      };
    case RS_TRANSACTIONS_PB:
      return {
        ...state,
        transactions_pb: state.transactions_pb.filter(
          (transaction) => transaction.pk !== action.payload.pk
        ),
        transactionDetails: {},
      };
    case RS_TRANSACTIONS_DT:
      return {
        ...state,
        transactions_dt: state.transactions_dt.filter(
          (transaction) => transaction.pk !== action.payload.pk
        ),
        transactionDetails: {},
      };

    case QUERY_IN_PROGRESS_STAT_AK:
      return {
        ...state,
        queryInProgress_stat_ak: true,
      };
    case QUERY_IN_PROGRESS_STAT_AR:
      return {
        ...state,
        queryInProgress_stat_ar: true,
      };
    case QUERY_IN_PROGRESS_STAT_NOIN:
      return {
        ...state,
        queryInProgress_stat_noin: true,
      };
    case GET_TRANSACTIONS_STAT_AK:
      return {
        ...state,
        transactionsStat_ak: action.payload,
        queryInProgress_stat_ak: false,
      };
    case GET_TRANSACTIONS_STAT_AR:
      return {
        ...state,
        transactionsStat_ar: action.payload,
        queryInProgress_stat_ar: false,
      };
    case GET_TRANSACTIONS_STAT_NOIN:
      return {
        ...state,
        transactionsStat_noin: action.payload,
        queryInProgress_stat_noin: false,
      };
    case GET_CARDS:
      return {
        ...state,
        cards: action.payload,
        queryInProgress_cards: false,
      };
    case QUERY_IN_PROGRESS_CARDS:
      return {
        ...state,
        queryInProgress_cards: true,
      };
    case GET_CARDS_DETAILS:
      return {
        ...state,
        cardsDetails: action.payload,
        queryInProgress_cardsdetails: false,
      };
    case QUERY_IN_PROGRESS_CARDSDETAILS:
      return {
        ...state,
        queryInProgress_cardsdetails: true,
      };
    case LOAD_IN_PROGRESS:
      return {
        ...state,
        loadInProgress: true,
      };
    case ADD_BUSINESSCARDFILE:
      return {
        ...state,
        cardsDetails: {
          ...state.cardsDetails,
          businesscardfiles: [
            action.payload,
            ...state.cardsDetails.businesscardfiles,
          ],
        },
        loadInProgress: false,
      };
    case DATA_CHANGE_IN_PROGRESS:
      return {
        ...state,
        dataChangeInProgress: action.flag,
      };
    default:
      return state;
  }
}
