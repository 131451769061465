import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";

export class Alerts2 extends Component {
  state = {
    alertOpen: false,
    message: "",
    type: "",
  };

  static propTypes = {
    error: PropTypes.object.isRequired,
    message: PropTypes.object.isRequired,
  };

  componentDidUpdate(prevProps) {
    const { error, message } = this.props;

    if (error !== prevProps.error) {
      if (error.status === 401) {
        this.setState({
          alertOpen: true,
          message: "Czekam na poprawne dane użytkownika ...",
          type: "info",
        });
      } else if (error.status == 'Lk:1') {
        this.setState({
          alertOpen: true,
          message: "Brak możliwości lokalizacji urządzenia",
          type: "error",
        });
      } else {
        this.setState({
          alertOpen: true,
          message: "Upps, coś poszło nie tak!",
          type: "error",
        });
      }
    }

    if (message !== prevProps.message) {
      //Trips
      if (message.deleteTrip) {
        this.setState({
          alertOpen: true,
          message: message.deleteTrip,
          type: message.type,
        });
      }
      if (message.addTrip) {
        this.setState({
          alertOpen: true,
          message: message.addTrip,
          type: message.type,
        });
      }
      if (message.patchTrip) {
        this.setState({
          alertOpen: true,
          message: message.patchTrip,
          type: message.type,
        });
      }
      if (message.patchEmployeeTrip) {
        this.setState({
          alertOpen: true,
          message: message.patchEmployeeTrip,
          type: message.type,
        });
      }
      if (message.patchAccountingTrip) {
        this.setState({
          alertOpen: true,
          message: message.patchAccountingTrip,
          type: message.type,
        });
      }
      if (message.addTripComment) {
        this.setState({
          alertOpen: true,
          message: message.addTripComment,
          type: message.type,
        });
      }
      if (message.addTimestamp) {
        this.setState({
          alertOpen: true,
          message: message.addTimestamp,
          type: message.type,
        });
      }
      if (message.deleteTimestamp) {
        this.setState({
          alertOpen: true,
          message: message.deleteTimestamp,
          type: message.type,
        });
      }

      //Holidays
      if (message.addLeave) {
        this.setState({
          alertOpen: true,
          message: message.addLeave,
          type: message.type,
        });
      }
      if (message.deleteLeave) {
        this.setState({
          alertOpen: true,
          message: message.deleteLeave,
          type: message.type,
        });
      }
      if (message.patchEmployeeLeave) {
        this.setState({
          alertOpen: true,
          message: message.patchEmployeeLeave,
          type: message.type,
        });
      }
      if (message.addEmployeeLeave) {
        this.setState({
          alertOpen: true,
          message: message.addEmployeeLeave,
          type: message.type,
        });
      }

      //Assets
      if (message.patchEmployeeAsset) {
        this.setState({
          alertOpen: true,
          message: message.patchEmployeeAsset,
          type: message.type,
        });
      }
      if (message.patchMyAsset) {
        this.setState({
          alertOpen: true,
          message: message.patchMyAsset,
          type: message.type,
        });
      }
      if (message.scrapMyAsset) {
        this.setState({
          alertOpen: true,
          message: message.scrapMyAsset,
          type: message.type,
        });
      }
      if (message.scrapMyAssetUpdate) {
        this.setState({
          alertOpen: true,
          message: message.scrapMyAssetUpdate,
          type: message.type,
        });
      }
      if (message.scrapEmployeeAsset) {
        this.setState({
          alertOpen: true,
          message: message.scrapEmployeeAsset,
          type: message.type,
        });
      }
      if (message.patchAssetTransferLog) {
        this.setState({
          alertOpen: true,
          message: message.patchAssetTransferLog,
          type: message.type,
        });
      }
      if (message.addAsset) {
        this.setState({
          alertOpen: true,
          message: message.addAsset,
          type: message.type,
        });
      }
      if (message.addAssetModel) {
        this.setState({
          alertOpen: true,
          message: message.addAssetModel,
          type: message.type,
        });
      }
      if (message.addVendor) {
        this.setState({
          alertOpen: true,
          message: message.addVendor,
          type: message.type,
        });
      }
      if (message.addPurchase) {
        this.setState({
          alertOpen: true,
          message: message.addPurchase,
          type: message.type,
        });
      }
      if (message.patchPurchase) {
        this.setState({
          alertOpen: true,
          message: message.patchPurchase,
          type: message.type,
        });
      }
      if (message.startInventory) {
        this.setState({
          alertOpen: true,
          message: message.startInventory,
          type: message.type,
        });
      }
      if (message.stopInventory) {
        this.setState({
          alertOpen: true,
          message: message.stopInventory,
          type: message.type,
        });
      }

      //Accounting
      if (message.patchTransaction) {
        this.setState({
          alertOpen: true,
          message: message.patchTransaction,
          type: message.type,
        });
      }
      if (message.addTransaction) {
        this.setState({
          alertOpen: true,
          message: message.addTransaction,
          type: message.type,
        });
      }
      if (message.addBusinessCardFile) {
        this.setState({
          alertOpen: true,
          message: message.addBusinessCardFile,
          type: message.type,
        });
      }

      //Employee
      if (message.patchEmployee) {
        this.setState({
          alertOpen: true,
          message: message.patchEmployee,
          type: message.type,
        });
      }
      if (message.changeUserPassword) {
        this.setState({
          alertOpen: true,
          message: message.changeUserPassword,
          type: message.type,
        });
      }
      if (message.patchProfile2) {
        this.setState({
          alertOpen: true,
          message: message.patchProfile2,
          type: message.type,
        });
      }
      if (message.patchProfileEm) {
        this.setState({
          alertOpen: true,
          message: message.patchProfileEm,
          type: message.type,
        });
      }
      if (message.changeCompany) {
        this.setState({
          alertOpen: true,
          message: message.changeCompany,
          type: message.type,
        });
      }

      //Remote
      if (message.startRemote) {
        this.setState({
          alertOpen: true,
          message: message.startRemote,
          type: message.type,
        });
      }
      if (message.stopRemote) {
        this.setState({
          alertOpen: true,
          message: message.stopRemote,
          type: message.type,
        });
      }
      if (message.deleteRemote) {
        this.setState({
          alertOpen: true,
          message: message.deleteRemote,
          type: message.type,
        });
      }

      //Projects
      if (message.deleteProject) {
        this.setState({
          alertOpen: true,
          message: message.deleteProject,
          type: message.type,
        });
      }
      if (message.addProject) {
        this.setState({
          alertOpen: true,
          message: message.addProject,
          type: message.type,
        });
      }
      if (message.patchProject) {
        this.setState({
          alertOpen: true,
          message: message.patchProject,
          type: message.type,
        });
      }

      //Fleet
      if (message.deleteFleet) {
        this.setState({
          alertOpen: true,
          message: message.deleteFleet,
          type: message.type,
        });
      }
      if (message.addFleet) {
        this.setState({
          alertOpen: true,
          message: message.addFleet,
          type: message.type,
        });
      }
      if (message.patchFleet) {
        this.setState({
          alertOpen: true,
          message: message.patchFleet,
          type: message.type,
        });
      }

      //Document
      if (message.deleteDocument) {
        this.setState({
          alertOpen: true,
          message: message.deleteDocument,
          type: message.type,
        });
      }
      if (message.addDocument) {
        this.setState({
          alertOpen: true,
          message: message.addDocument,
          type: message.type,
        });
      }
      if (message.patchDocument) {
        this.setState({
          alertOpen: true,
          message: message.patchDocument,
          type: message.type,
        });
      }

      //Codes
      if (message.sendCode) {
        this.setState({
          alertOpen: true,
          message: message.sendCode,
          type: message.type,
        });
      }
      if (message.verifyCode) {
        this.setState({
          alertOpen: true,
          message: message.verifyCode,
          type: message.type,
        });
      }
      if (message.errorCode) {
        this.setState({
          alertOpen: true,
          message: message.errorCode,
          type: 'error',
        });
      }

      //ZFSS
      if (message.addApplication) {
        this.setState({
          alertOpen: true,
          message: message.addApplication,
          type: message.type,
        });
      }
    }
  }

  handleAlertClose = () => {
    this.setState({
      alertOpen: false,
    });
  };

  render() {
    const { alertOpen, message, type } = this.state;
    const alert = (
      <Snackbar
        open={alertOpen}
        autoHideDuration={6000}
        onClose={this.handleAlertClose}
      >
        <Alert variant="filled" severity={type}>
          {message}
        </Alert>
      </Snackbar>
    );

    return <Fragment>{alert}</Fragment>;
  }
}

const mapStateToProps = (state) => ({
  error: state.errors,
  message: state.messages,
});

export default connect(mapStateToProps)(Alerts2);
