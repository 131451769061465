import React, { Component, Fragment } from "react";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import CardsList from "./CardsList";
import CardDetailsCard from "./CardDetailsCard";
import { styles, materialTheme } from "../../common/radiolineStyles";
import Zoom from "@material-ui/core/Zoom";
import Tooltip from "@material-ui/core/Tooltip";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Fab from "@material-ui/core/Fab";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import CustomFileInput from "components/CustomFileInput/CustomFileInput.js";
import AttachFile from "@material-ui/icons/AttachFile";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { getFormattedDate } from "../../common/commonFunctions";

import {
  getCards,
  getCardsDetails,
  addBusinessCardFile,
} from "../../Redux/actions/accounting";

export class CardsView extends Component {
  state = {
    isCardChecked: false,
    cardSelected: null,
    dialogAttachFile: false,
    file: null,
  };

  static propTypes = {
    selectedCompany: PropTypes.any.isRequired,
    queryInProgress_cards: PropTypes.bool.isRequired,
    cards: PropTypes.array.isRequired,
    queryInProgress_cardsdetails: PropTypes.bool.isRequired,
    loadInProgress: PropTypes.bool.isRequired,
    cardsDetails: PropTypes.object.isRequired,
    getCards: PropTypes.func.isRequired,
    getCardsDetails: PropTypes.func.isRequired,
    addBusinessCardFile: PropTypes.func.isRequired,
  };

  componentDidMount() {
    if (this.props.cards.length === 0) {
      this.props.getCards();
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.selectedCompany != prevProps.selectedCompany) {
      this.props.getCards();
    }
  }

  handleChecked = (isCardChecked, cardSelected) => {
    this.setState({
      isCardChecked: isCardChecked,
      cardSelected: cardSelected,
    });

    if (isCardChecked === true) {
      this.props.getCardsDetails(cardSelected);
    }
  };

  triggerAttachFile = () => {
    this.setState({
      dialogAttachFile: true,
    });
  };

  handleCloseDialogAttachFile = () => {
    this.setState({
      dialogAttachFile: false,
      file: null,
    });
  };

  updateAttachFileFilename = (inputFiles, inputFileNames) => {
    this.setState({
      file: inputFiles[0],
    });
  };

  handleAttachFile = () => {
    const body = new FormData();
    const file = this.state.file;
    const fileextension = file.name.substring(file.name.lastIndexOf(".") + 1);
    const cardlastdigits = this.props.cardsDetails.cardNumber.substring(
      this.props.cardsDetails.cardNumber.lastIndexOf("-") + 1
    );
    const today = getFormattedDate(new Date());
    const filename =
      "Wyciag_" + cardlastdigits + "_" + today + "." + fileextension;
    body.append("filepath", file);
    body.append("filename", filename);
    body.append("businesscard", this.props.cardsDetails.pk);

    this.props.addBusinessCardFile(body);
    this.setState({
      dialogAttachFile: false,
      file: null,
    });
  };

  render() {
    const {
      classes,
      cardsDetails,
      queryInProgress_cardsdetails,
      loadInProgress,
    } = this.props;
    const { isCardChecked, dialogAttachFile, file } = this.state;

    const attachButtonDi = (
      <Fab
        disabled
        size="small"
        aria-label="scroll back to top"
        className={classes.fabgreen}
      >
        <AttachFileIcon />
      </Fab>
    );

    const attachButtonEn = (
      <Fab
        size="small"
        aria-label="scroll back to top"
        className={classes.fabgreen}
      >
        <AttachFileIcon />
      </Fab>
    );

    const attachFileButtonEn = (
      <Button
        variant="contained"
        color="inherit"
        size="large"
        onClick={this.handleAttachFile}
        style={{ marginTop: "20px" }}
        className={classes.buttonAccept}
      >
        Załącz
      </Button>
    );

    const attachFileButtonDi = (
      <Button
        disabled
        variant="contained"
        color="inherit"
        size="large"
        style={{ marginTop: "20px" }}
        className={classes.buttonAccept}
      >
        Załącz
      </Button>
    );

    return (
      <Fragment>
        <div className={classes.section}>
          <Box m={2}>
            <Grid container justify="flex-start" spacing={4}>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <CardsList checked={this.handleChecked} />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <CardDetailsCard
                  isCardChecked={isCardChecked}
                  queryInProgress_cardsdetails={queryInProgress_cardsdetails}
                  loadInProgress={loadInProgress}
                  cardsDetails={cardsDetails}
                />
              </Grid>
            </Grid>
          </Box>

          <Zoom in={isCardChecked}>
            <div
              onClick={this.triggerAttachFile}
              role="presentation"
              className={classes.fb2}
            >
              <Tooltip title="Załącz dokument" placement="left">
                {queryInProgress_cardsdetails ? attachButtonDi : attachButtonEn}
              </Tooltip>
            </div>
          </Zoom>

          <Dialog
            fullScreen
            open={dialogAttachFile}
            onClose={this.handleCloseDialogAttachFile}
            aria-labelledby="form-dialog-title"
          >
            <AppBar position="static" style={{ background: "#0071BC" }}>
              <Toolbar>
                <IconButton
                  edge="start"
                  color="inherit"
                  onClick={this.handleCloseDialogAttachFile}
                  aria-label="close"
                  className={classes.menuButton}
                >
                  <CloseIcon />
                </IconButton>
                <Typography variant="h6" className={classes.title}>
                  Załącz dokument
                </Typography>
              </Toolbar>
            </AppBar>
            <DialogContent style={{ background: "#f5f5f5" }}>
              <CustomFileInput
                onChange={this.updateAttachFileFilename}
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  placeholder: "wybierz plik ...",
                }}
                endButton={{
                  buttonProps: {
                    round: true,
                    color: "primary",
                    justIcon: true,
                    fileButton: true,
                  },
                  icon: <AttachFile />,
                }}
              />
              {file ? attachFileButtonEn : attachFileButtonDi}
            </DialogContent>
          </Dialog>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  cards: state.accounting.cards,
  queryInProgress_cards: state.accounting.queryInProgress_cards,
  cardsDetails: state.accounting.cardsDetails,
  queryInProgress_cardsdetails: state.accounting.queryInProgress_cardsdetails,
  loadInProgress: state.accounting.loadInProgress,
  selectedCompany: state.auth.main_profile.defaultCompany,
});

export default connect(
  mapStateToProps,
  { getCards, getCardsDetails, addBusinessCardFile }
)(withStyles(styles, { withTheme: true })(CardsView));
