import React, { Component, Fragment } from "react";
import { withStyles } from "@material-ui/core/styles";
import { pl } from "date-fns/locale";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import { createMuiTheme } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

const styles = theme => ({
  root: {
    flexGrow: 1,
    overflow: "hidden",
    padding: theme.spacing(0, 3)
  },
  title: {
    fontSize: 12,
    fontFamily: "Lato, Open Sans, sans-serif",
    color: "#909090",
    fontWeight: "300",
    textAlign: "right",
    paddingRight: "10px"
  },
  title2: {
    flexGrow: 1
  },
  content: {
    fontSize: 14,
    fontFamily: "Lato, Open Sans, sans-serif",
    fontWeight: "300",
    textAlign: "left",
    paddingLeft: "10px"
  },
  appBar: {
    top: "auto",
    bottom: 0,
    background: "#0071BC"
  },
  grow: {
    flexGrow: 1
  },
  fabButton: {
    position: "absolute",
    zIndex: 1,
    top: -30,
    left: 0,
    right: 0,
    margin: "0 auto"
  }
});

const materialTheme = createMuiTheme({
  overrides: {
    MuiFormControl: {
      root: {
        width: 300,
        marginTop: 50
      },
      marginNormal: {
        marginTop: "50px"
      }
    },
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: "#0071BC"
      }
    },
    MuiButton: {
      root: {},
      textPrimary: {
        color: "#0071BC"
      },
      containedPrimary: {
        backgroundColor: "#1BAB7F",
        "&:hover": {
          backgroundColor: "#188E6A"
        }
      }
    },
    MuiFormLabel: {
      root: {
        MuiFocused: {
          color: "0071BC"
        }
      }
    },
    MuiPickersDay: {
      day: {
        color: "#0071BC"
      },
      daySelected: {
        backgroundColor: "#0071BC",
        "&:hover": {
          backgroundColor: "#0071BC"
        }
      },

      dayDisabled: {
        color: "gray"
      },
      current: {
        color: "#0071BC"
      }
    },
    MuiInput: {
      underline: {
        "&:after": {
          borderBottomColor: "#0071bc"
        }
      }
    },
    MuiInputBase: {
      root: {
        textAlign: "left"
      }
    },
    MuiCheckbox: {
      colorPrimary: {
        "&.Mui-checked": {
          color: "#0071bc"
        }
      }
    },
    MuiFormGroup: {
      root: {
        marginTop: "10px"
      }
    }
  }
});

export class HolidaysEditForm extends Component {
  state = {
    holidaysStartDateErrorFlag: false,
    holidaysEndDateErrorFlag: false,
    holidaysStartDate: null,
    holidaysEndDate: null,
    leaveTypeErrorFlag: false,
    remarks: "",
    leaveType: "",
    firstName: "",
    surname: ""
  };

  static getDerivedStateFromProps(props, state) {
    if (props.leavesDetails.employee !== undefined && state.leaveType === "") {
      return {
        holidaysStartDate: props.leavesDetails.startDate,
        holidaysEndDate: props.leavesDetails.endDate,
        remarks: props.leavesDetails.remarks,
        leaveType: props.leavesDetails.leaveType,
        firstName: props.leavesDetails.employee.first_name,
        surname: props.leavesDetails.employee.last_name,
      };
    } else {
      return null;
    }
  }

  componentDidUpdate() {
    if (this.props.saveTrigger === true) {
      const holidaysStartDate = this.state.holidaysStartDate;
      const holidaysEndDate = this.state.holidaysEndDate;
      const remarks = this.state.remarks;
      this.props.saveLeave(holidaysStartDate, holidaysEndDate, remarks);
    }
  }

  handleTextList = name => ({ target: { value } }) => {
    this.setState({
      ...this.state,
      [name]: value
    });
  };

  handleHolidaysStartDate = date => {
    if (date !== null) {
      const dd = date.getDate();
      const mm = date.getMonth() + 1;
      const yyyy = date.getFullYear();
      const fullDate = yyyy + "-" + mm + "-" + dd;
      this.setState({
        holidaysStartDate: fullDate
      });
    } else {
      this.setState({
        holidaysStartDate: null
      });
    }
  };

  handleHolidaysEndDate = date => {
    if (date !== null) {
      const dd = date.getDate();
      const mm = date.getMonth() + 1;
      const yyyy = date.getFullYear();
      const fullDate = yyyy + "-" + mm + "-" + dd;
      this.setState({
        holidaysEndDate: fullDate
      });
    } else {
      this.setState({
        holidaysEndDate: null
      });
    }
  };

  render() {
    const { classes, editFlag } = this.props;
    const {
      firstName,
      surname,
      leaveType,
      remarks,
      leaveTypeErrorFlag,
      holidaysStartDateErrorFlag,
      holidaysEndDateErrorFlag,
      holidaysStartDate,
      holidaysEndDate
    } = this.state;

    const CallendarsRO = (
      <Fragment>
        <ThemeProvider theme={materialTheme}>
          <KeyboardDatePicker
            error={holidaysStartDateErrorFlag}
            className={classes.textField}
            margin="normal"
            label="Od"
            format="yyyy/MM/dd"
            value={holidaysStartDate}
            shouldDisableDate={this.disableDates}
            disabled
          />
        </ThemeProvider>
        <ThemeProvider theme={materialTheme}>
          <KeyboardDatePicker
            error={holidaysEndDateErrorFlag}
            className={classes.textField}
            margin="normal"
            label="Do"
            format="yyyy/MM/dd"
            emptyLabel=""
            value={holidaysEndDate}
            shouldDisableDate={this.disableDates}
            disabled
          />
        </ThemeProvider>
      </Fragment>
    );

    const CallendarsRW = (
      <Fragment>
        <ThemeProvider theme={materialTheme}>
          <KeyboardDatePicker
            error={holidaysStartDateErrorFlag}
            className={classes.textField}
            margin="normal"
            label="Od"
            format="yyyy/MM/dd"
            value={holidaysStartDate}
            shouldDisableDate={this.disableDates}
            onChange={this.handleHolidaysStartDate}
          />
        </ThemeProvider>
        <ThemeProvider theme={materialTheme}>
          <KeyboardDatePicker
            error={holidaysEndDateErrorFlag}
            className={classes.textField}
            margin="normal"
            label="Do"
            format="yyyy/MM/dd"
            emptyLabel=""
            value={holidaysEndDate}
            shouldDisableDate={this.disableDates}
            onChange={this.handleHolidaysEndDate}
          />
        </ThemeProvider>
      </Fragment>
    );

    const KomentarzRO = (
      <TextField
        multiline
        rowsMax="3"
        label="Komentarz"
        inputProps={{ maxLength: 100 }}
        margin="normal"
        value={remarks}
        InputProps={{
          readOnly: true
        }}
      />
    );
    const KomentarzRW = (
      <TextField
        multiline
        rowsMax="3"
        label="Komentarz"
        inputProps={{ maxLength: 100 }}
        margin="normal"
        value={remarks}
        onChange={this.handleTextList("remarks")}
      />
    );

    return (
      <Fragment>
        <form
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <ThemeProvider theme={materialTheme}>
            <TextField
              label="Pracownik"
              inputProps={{ maxLength: 100 }}
              margin="normal"
              value={firstName + " " + surname || ""}
              InputProps={{
                readOnly: true
              }}
            />
          </ThemeProvider>
          <MuiPickersUtilsProvider
            utils={DateFnsUtils}
            locale={pl}
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            {editFlag ? CallendarsRW : CallendarsRO}
          </MuiPickersUtilsProvider>
          <div style={{ marginTop: 16 }}></div>
          <ThemeProvider theme={materialTheme}>
            <FormControl required>
              <InputLabel error={leaveTypeErrorFlag} htmlFor="leaveType">
                Rodzaj urlopu
              </InputLabel>
              <Select
                name="leaveType"
                value={leaveType}
                inputProps={{
                  name: "name",
                  id: "name-readonly",
                  readOnly: true
                }}
              >
                <MenuItem value="RE">Wypoczynkowy</MenuItem>
                <MenuItem value="ON">Na żądanie</MenuItem>
                <MenuItem value="SP">Okolicznościowy</MenuItem>
                <MenuItem value="CA">Opieka nad dzieckiem</MenuItem>
                <MenuItem value="PA">Płatny</MenuItem>
                <MenuItem value="UN">Bezpłatny</MenuItem>
                <MenuItem value="SI">L4</MenuItem>
              </Select>
            </FormControl>
          </ThemeProvider>
          <ThemeProvider theme={materialTheme}>
            {editFlag ? KomentarzRW : KomentarzRO}
          </ThemeProvider>
          <div style={{ marginTop: 30 }}></div>
        </form>
      </Fragment>
    );
  }
}

export default withStyles(styles, { withTheme: true })(HolidaysEditForm);
