import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import Card from "../../components/Card/Card.js";
import CardBody from "../../components/Card/CardBody.js";
import { Avatar } from "@material-ui/core";
//import MoneyIcon from "@material-ui/icons/Money";
//import CloseIcon from "@material-ui/icons/Close";
//import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import Typography from "@material-ui/core/Typography";
//import IconButton from "@material-ui/core/IconButton";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import CircularProgress from "@material-ui/core/CircularProgress";
import { cardStyles } from "../../common/radiolineStyles";

export class SekCard extends Component {
  state = {};

  static propTypes = {
    balanceSEK: PropTypes.object.isRequired,
    queryInProgress_tr_sek: PropTypes.bool.isRequired,
  };

  render() {
    const { classes, queryInProgress_tr_sek, balanceSEK } = this.props;

    const circle = <CircularProgress color="secondary" />;

    return (
      <Grid item xs={12} sm={12} md={4} lg={3}>
        <Card style={{ height: "120px" }}>
          <CardBody style={{ position: "relative" }}>
            <Grid
              style={{ margin: "0 auto" }}
              container
              justify="space-between"
              alignItems="center"
              spacing={2}
            >
              <Grid item xs={3} sm={4} md={4} lg={3}>
                <Avatar className={classes.avatarHistory}>SEK</Avatar>
              </Grid>
              <Grid item xs={7} sm={6} md={6} lg={7}>
                <Typography className={classes.CardTitle}>
                  Stan konta w Koronach
                </Typography>
                <Typography className={classes.CardDescriptionHistory}>
                  {queryInProgress_tr_sek ? "..." : balanceSEK.sum.toFixed(2)}
                </Typography>
              </Grid>
              <Grid item xs={2} sm={2} md={2} lg={2}>
                {queryInProgress_tr_sek ? circle : ""}
              </Grid>
            </Grid>
          </CardBody>
        </Card>
      </Grid>
    );
  }
}

const mapStateToProps = (state) => ({
  balanceSEK: state.accounting.balanceSEK,
  queryInProgress_tr_sek: state.accounting.queryInProgress_tr_sek,
});

export default connect(
  mapStateToProps,
  {}
)(withStyles(cardStyles, { withTheme: true })(SekCard));
