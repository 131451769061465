import React, { Component, Fragment } from "react";
import { lighten } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import Grid from "@material-ui/core/Grid";
import Card from "../../components/Card/Card.js";
import CardBody from "../../components/Card/CardBody.js";
import CardHeader from "@material-ui/core/CardHeader";
import CircularProgress from "@material-ui/core/CircularProgress";
import PropTypes from "prop-types";
//import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
import PanToolIcon from "@material-ui/icons/PanTool";
//import DevicesOtherIcon from "@material-ui/icons/DevicesOther";
import DeleteSweepIcon from "@material-ui/icons/DeleteSweep";
//integracja z reduxem
import { connect } from "react-redux";
import IconButton from "@material-ui/core/IconButton";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { getRemainingDays } from "../../common/commonFunctions";

const styles = (theme) => ({
  avatarSR: {
    backgroundColor: "#dc004e",
    height: 50,
    width: 50,
  },
  avatarWR: {
    backgroundColor: "#ff9800",
    height: 50,
    width: 50,
  },
  headertitle: {
    fontFamily: "Lato, Open Sans, sans-serif",
    fontSize: "1.2em",
    fontWeight: "700",
    color: "#444444",
  },
  headerSR: {
    textAlign: "left",
    backgroundColor: lighten("#dc004e", 0.85),
  },
  headerWR: {
    textAlign: "left",
    backgroundColor: lighten("#ff9800", 0.85),
  },
  info: {
    fontFamily: "Lato, Open Sans, sans-serif",
    fontSize: "1.5em",
    fontWeight: "300",
    color: "#0071BC",
  },
  listTextSecondary: {
    textAlign: "left",
    color: "#444444",
    fontFamily: "Lato, Open Sans, sans-serif",
    fontSize: "1em",
    fontWeight: "300",
    display: "block",
  },
  listTextSecondaryComment: {
    textAlign: "left",
    color: "#999999",
    fontFamily: "Lato, Open Sans, sans-serif",
    fontSize: "1em",
    fontWeight: "300",
    display: "block",
  },
  listTextSecondaryError: {
    textAlign: "left",
    color: "#dc004e",
    fontFamily: "Lato, Open Sans, sans-serif",
    fontSize: "1em",
    fontWeight: "300",
    display: "block",
  },
});

//sortowanie assetsów z listy
function compare(a, b) {
  if (a.nazwa.toUpperCase() < b.nazwa.toUpperCase()) {
    return -1;
  }
  if (a.nazwa.toUpperCase() > b.nazwa.toUpperCase()) {
    return 1;
  }
  return 0;
}

var wyplaszcz = {
  myAssetsFlattened: [],

  dodajElement: function(element) {
    if (element.status === "sr") {
      this.myAssetsFlattened.push({
        pk: element.pk,
        status: element.status,
        grupa: element.model.eqtype_description,
        typ: element.model.eqtype,
        nazwa: element.model.name,
        model: element.model.description,
        producent: element.model.vendor,
        numerseryjny: element.serialNumber,
        numerproduktu: element.partNumber,
        datazakupu: element.purchaseDate,
        dataprzekazania: element.assignDate,
        opisdodatkowy: element.description,
        wlasciciel: element.owner.first_name + " " + element.owner.last_name,
        wlascicielDo: element.transferowner,
        scrapageUserComment: element.scrapageUserComment,
        scrapageReadyDate: element.scrapageReadyDate,
        scrapageAcceptDate: element.scrapageAcceptDate,
        scrapageChargeValue: element.scrapageChargeValue,
        scrapageChargeStatus: element.scrapageChargeStatus_description,
      });
    } else if (element.status === "wr") {
      this.myAssetsFlattened.push({
        pk: element.pk,
        status: element.status,
        grupa: element.model.eqtype_description,
        typ: element.model.eqtype,
        nazwa: element.model.name,
        model: element.model.description,
        producent: element.model.vendor,
        numerseryjny: element.serialNumber,
        numerproduktu: element.partNumber,
        datazakupu: element.purchaseDate,
        dataprzekazania: element.assignDate,
        opisdodatkowy: element.description,
        wlasciciel: element.owner.first_name + " " + element.owner.last_name,
        wlascicielDo: element.transferowner,
        scrapageUserComment: element.scrapageUserComment,
        scrapageManagerComment: element.scrapageManagerComment,
        daysLefForReturn: getRemainingDays(element.scrapageAcceptDate, 60),
        scrapageAcceptDate: element.scrapageAcceptDate,
        scrapageReadyDate: element.scrapageReadyDate,
        scrapageChargeValue: element.scrapageChargeValue,
        scrapageChargeStatus: element.scrapageChargeStatus_description,
      });
    }
  },
  zapiszMacierz: function(element) {
    return this.myAssetsFlattened;
  },
  inicjalizacja: function(element) {
    this.myAssetsFlattened = [];
  },
};

class MyAssetFilterList extends Component {
  state = {
    selectedIndex: null,
    myAssets: [],
    myAssetsFlatened: [],
    myAssetSelected: null,
    value: 0,
    index: null,
  };

  static propTypes = {
    myAssetsByStatusSr: PropTypes.array.isRequired,
    myAssetsByStatusWr: PropTypes.array.isRequired,
  };

  componentDidMount() {
    if (
      this.props.activeFilter === "scrapfilter" &&
      this.props.myAssetsByStatusSr.length !== 0
    ) {
      wyplaszcz.inicjalizacja();
      this.props.myAssetsByStatusSr.forEach(wyplaszcz.dodajElement, wyplaszcz);
      const macierz = wyplaszcz.zapiszMacierz();
      macierz.sort(compare);
      this.setState({
        myAssets: this.props.myAssetsByStatusSr,
        myAssetsFlatened: macierz,
      });
      macierz.sort(compare);
    } else if (
      this.props.activeFilter === "returnfilter" &&
      this.props.myAssetsByStatusWr.length !== 0
    ) {
      wyplaszcz.inicjalizacja();
      this.props.myAssetsByStatusWr.forEach(wyplaszcz.dodajElement, wyplaszcz);
      const macierz = wyplaszcz.zapiszMacierz();
      macierz.sort(compare);
      this.setState({
        myAssets: this.props.myAssetsByStatusWr,
        myAssetsFlatened: macierz,
      });
      macierz.sort(compare);
    }
  }

  componentDidUpdate(prevProps) {
    //jeżeli aktywny filtr i zmienił sie store
    if (
      this.props.activeFilter === "scrapfilter" &&
      this.props.myAssetsByStatusSr !== this.state.myAssets
    ) {
      this.setState({
        selectedIndex: null,
        myAssetSelected: null,
      });
      this.props.checked(false, null, null);

      wyplaszcz.inicjalizacja();
      this.props.myAssetsByStatusSr.forEach(wyplaszcz.dodajElement, wyplaszcz);
      const macierz = wyplaszcz.zapiszMacierz();
      macierz.sort(compare);
      this.setState({
        myAssets: this.props.myAssetsByStatusSr,
        myAssetsFlatened: macierz,
      });

      macierz.sort(compare);
    }

    if (
      this.props.activeFilter === "returnfilter" &&
      this.props.myAssetsByStatusWr !== this.state.myAssets
    ) {
      this.setState({
        selectedIndex: null,
        myAssetSelected: null,
      });
      this.props.checked(false, null, null);

      wyplaszcz.inicjalizacja();
      this.props.myAssetsByStatusWr.forEach(wyplaszcz.dodajElement, wyplaszcz);
      const macierz = wyplaszcz.zapiszMacierz();
      macierz.sort(compare);
      this.setState({
        myAssets: this.props.myAssetsByStatusWr,
        myAssetsFlatened: macierz,
      });

      macierz.sort(compare);
    }

    //jeżeli zmienił się filtr

    if (prevProps.activeFilter !== this.props.activeFilter) {
      console.log("zmienił sie filtr");
    }
  }

  /*   componentWillReceiveProps(nextProps) {
    console.log("will receive props");
    if (
      this.props.activeFilter === "scrapfilter" &&
      nextProps.myAssetsByStatusSr !== this.state.myAssets
    ) {
      this.setState({
        selectedIndex: null,
        myAssetSelected: null,
      });
      this.props.checked(false, null, null);

      wyplaszcz.inicjalizacja();
      nextProps.myAssetsByStatusSr.forEach(wyplaszcz.dodajElement, wyplaszcz);
      const macierz = wyplaszcz.zapiszMacierz();
      macierz.sort(compare);
      this.setState({
        myAssets: nextProps.myAssetsByStatusSr,
        myAssetsFlatened: macierz,
      });

      macierz.sort(compare);
    } else if (
      this.props.activeFilter === "returnfilter" &&
      nextProps.myAssetsByStatusWr !== this.state.myAssets
    ) {
      console.log("returnfilter");
      this.setState({
        selectedIndex: null,
        myAssetSelected: null,
      });
      this.props.checked(false, null, null);

      wyplaszcz.inicjalizacja();
      nextProps.myAssetsByStatusWr.forEach(wyplaszcz.dodajElement, wyplaszcz);
      const macierz = wyplaszcz.zapiszMacierz();
      macierz.sort(compare);
      this.setState({
        myAssets: nextProps.myAssetsByStatusWr,
        myAssetsFlatened: macierz,
      });

      macierz.sort(compare);
    }

    if (
      nextProps.activeListType !== "MyAssetFilterListSR" &&
      nextProps.activeListType !== "MyAssetFilterListWR" &&
      nextProps.activeListType !== null
    ) {
      this.setState({
        selectedIndex: null,
        myAssetSelected: null,
      });
    }
  } */

  handleListItemClick = (event, index, pk) => {
    const selectedIndex = this.state.selectedIndex;

    if (selectedIndex !== index) {
      this.setState({
        selectedIndex: index,
        myAssetSelected: pk,
      });
      if (this.props.activeFilter === "scrapfilter") {
        this.props.checked(true, pk, "MyAssetFilterListSR");
      } else if (this.props.activeFilter === "returnfilter") {
        this.props.checked(true, pk, "MyAssetFilterListWR");
      }
    } else {
      this.setState({
        selectedIndex: null,
        myAssetSelected: null,
      });
      this.props.checked(false, null, null);
    }
  };

  handleDisplayAssetDetails = (assetpk) => {};

  render() {
    const { myAssetsFlatened, selectedIndex } = this.state;
    const { classes, queryInProgress, activeFilter } = this.props;

    const scrapicon = (
      <ListItemAvatar>
        <Avatar style={{ backgroundColor: "#dc004e" }}>
          <DeleteSweepIcon />
        </Avatar>
      </ListItemAvatar>
    );

    const waiticon = (
      <ListItemAvatar>
        <Avatar style={{ backgroundColor: "#ff9800" }}>
          <PanToolIcon />
        </Avatar>
      </ListItemAvatar>
    );

    var nothingToDisplay = (
      <div className={classes.info}>
        Nie ma żadnych środków trwałych spełniających podane kryteria ...
      </div>
    );

    const waitToDisplay = (
      <div className={classes.info}>Czekaj, pobieram dane z serwera ...</div>
    );

    const circle = (
      <IconButton aria-label="settings">
        <CircularProgress color="secondary" />
      </IconButton>
    );

    const headerSR = (
      <CardHeader
        className={classes.headerSR}
        avatar={
          <Avatar aria-label="recipe" className={classes.avatarSR}>
            WZ
          </Avatar>
        }
        action={queryInProgress ? circle : ""}
        title={
          <div className={classes.headertitle}>
            środki trwałe wybrane do złomowania
          </div>
        }
      />
    );

    const headerWR = (
      <CardHeader
        className={classes.headerWR}
        avatar={
          <Avatar aria-label="recipe" className={classes.avatarWR}>
            ZM
          </Avatar>
        }
        action={queryInProgress ? circle : ""}
        title={
          <div className={classes.headertitle}>
            środki trwałe oczekujące na zwrot do magazynu
          </div>
        }
      />
    );

    return (
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Card style={{ overflow: "auto" }}>
          <CardBody style={{ padding: 0 }}>
            {this.props.activeFilter === "scrapfilter" ? headerSR : ""}
            {this.props.activeFilter === "returnfilter" ? headerWR : ""}
            <List component="nav" aria-label="main mailbox folders">
              {myAssetsFlatened.length === 0 && !queryInProgress
                ? nothingToDisplay
                : ""}
              {myAssetsFlatened.length === 0 && queryInProgress
                ? waitToDisplay
                : ""}
              {myAssetsFlatened.map((asset, index) => (
                <ListItem
                  key={asset.typ + asset.pk}
                  button
                  alignItems="flex-start"
                  selected={selectedIndex === index}
                  onClick={(event) =>
                    this.handleListItemClick(event, index, asset.pk)
                  }
                >
                  {this.props.activeFilter === "scrapfilter" ? scrapicon : ""}
                  {this.props.activeFilter === "returnfilter" ? waiticon : ""}
                  <ListItemText
                    primary={asset.nazwa}
                    secondary={
                      <Fragment>
                        <Typography
                          className={classes.listTextSecondary}
                          component="span"
                        >
                          {asset.model || "model nieznany"}
                        </Typography>
                        <Typography
                          className={classes.listTextSecondary}
                          component="span"
                        >
                          {asset.datazakupu ? "Zakup: " + asset.datazakupu : ""}
                        </Typography>
                        <Typography
                          className={classes.listTextSecondary}
                          component="span"
                        >
                          {asset.status === "wr"
                            ? "Akceptacja złomowania: " +
                              asset.scrapageAcceptDate
                            : "Wniosek o złomowanie: " +
                              asset.scrapageReadyDate}
                        </Typography>
                        <Typography
                          className={classes.listTextSecondaryComment}
                          component="span"
                        >
                          {asset.scrapageUserComment
                            ? "Komentarz użytkownika: " +
                              asset.scrapageUserComment
                            : ""}
                        </Typography>
                        <Typography
                          className={classes.listTextSecondaryComment}
                          component="span"
                        >
                          {asset.scrapageManagerComment
                            ? "Komentarz kierownika: " +
                              asset.scrapageManagerComment
                            : ""}
                        </Typography>
                        <Typography
                          className={classes.listTextSecondaryError}
                          component="span"
                        >
                          {asset.scrapageChargeStatus !== "nie zażądano"
                            ? "Obciążenie: " + asset.scrapageChargeStatus
                            : ""}
                        </Typography>
                        <Typography
                          className={classes.listTextSecondary}
                          component="span"
                        >
                          {asset.scrapageChargeStatus !== "nie zażądano"
                            ? "Wartość obciążenia: " + asset.scrapageChargeValue
                            : ""}
                        </Typography>
                        <Typography
                          className={classes.listTextSecondaryComment}
                          component="span"
                        >
                          {activeFilter === "returnfilter"
                            ? "Pozostało do zwrotu: " +
                              asset.daysLefForReturn +
                              " dni"
                            : ""}
                        </Typography>
                      </Fragment>
                    }
                  />
                </ListItem>
              ))}
            </List>
          </CardBody>
        </Card>
      </Grid>
    );
  }
}

const mapStateToProps = (state) => ({
  myAssetsByStatusSr: state.assets.myAssetsByStatusSr,
  myAssetsByStatusWr: state.assets.myAssetsByStatusWr,
});

export default connect(
  mapStateToProps,
  {}
)(withStyles(styles, { withTheme: true })(MyAssetFilterList));
