import React, { Component, Fragment } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { withStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  getTransactionsByStatus,
  getTransactionDetails,
  patchTransactionDetails,
} from "../../Redux/actions/accounting";
import { styles, materialTheme } from "../../common/radiolineStyles";
import VisibilityIcon from "@material-ui/icons/Visibility";
import Zoom from "@material-ui/core/Zoom";
import Tooltip from "@material-ui/core/Tooltip";
import Fab from "@material-ui/core/Fab";
import ListAccept from "./ListAccept";
import ListPayback from "./ListPayback";
import ListReturn from "./ListReturn";
import ListDeduce from "./ListDeduce";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import CloseIcon from "@material-ui/icons/Close";
import GetAppIcon from "@material-ui/icons/GetApp";
import cashIconW from "../../graphics/cashIconW.svg";
import cashIconD from "../../graphics/cashIconD.svg";
import cashIconA from "../../graphics/cashIconA.svg";
import cashIconAA from "../../graphics/cashIconAA.svg";
import cardIconW from "../../graphics/cardIconW.svg";
import cardIconD from "../../graphics/cardIconD.svg";
import cardIconA from "../../graphics/cardIconA.svg";
import cardIconAA from "../../graphics/cardIconAA.svg";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import transferIconMinus from "../../graphics/transferIconMinus.svg";
import transferIconMinusW from "../../graphics/transferIconMinusW.svg";
import transferIconMinusD from "../../graphics/transferIconMinusD.svg";
import transferIconMinusAA from "../../graphics/transferIconMinusAA.svg";
import transferIconPlus from "../../graphics/transferIconPlus.svg";
import transferIconPlusD from "../../graphics/transferIconPlusD.svg";
import transferIconPlusW from "../../graphics/transferIconPlusW.svg";
import transferIconPlusAA from "../../graphics/transferIconPlusAA.svg";
import returnIcon from "../../graphics/returnIcon.svg";
import returnIconD from "../../graphics/returnIconD.svg";
import returnIconW from "../../graphics/returnIconW.svg";
import returnIconAA from "../../graphics/returnIconAA.svg";
import DoneIcon from "@material-ui/icons/Done";
import DialogTitle from "@material-ui/core/DialogTitle";
import { ThemeProvider } from "@material-ui/styles";
import TextField from "@material-ui/core/TextField";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import FormHelperText from "@material-ui/core/FormHelperText";
import { getFormattedDate } from "../../common/commonFunctions";
import CustomFileInput from "components/CustomFileInput/CustomFileInput.js";
import AttachFile from "@material-ui/icons/AttachFile";

import ownCashIconW from "../../graphics/ownCashIconW.svg";
import ownCashIconD from "../../graphics/ownCashIconD.svg";
import ownCashIconA from "../../graphics/ownCashIconA.svg";
import ownCashIconAA from "../../graphics/ownCashIconAA.svg";

var ocak = <img style={{ height: "50px" }} src={ownCashIconW} alt="ocak"></img>;
var ocod = <img style={{ height: "50px" }} src={ownCashIconD} alt="ocod"></img>;
var ocar = <img style={{ height: "50px" }} src={ownCashIconA} alt="ocar"></img>;
var ocro = <img style={{ height: "50px" }} src={ownCashIconAA} alt="ocro"></img>;

var cpak = <img style={{ height: "50px" }} src={cardIconW} alt="cpak"></img>;
var cpod = <img style={{ height: "50px" }} src={cardIconD} alt="cpod"></img>;
var cpar = <img style={{ height: "50px" }} src={cardIconA} alt="cpar"></img>;
var cpro = <img style={{ height: "50px" }} src={cardIconAA} alt="cpro"></img>;
var chak = <img style={{ height: "50px" }} src={cashIconW} alt="chak"></img>;
var chod = <img style={{ height: "50px" }} src={cashIconD} alt="chod"></img>;
var char = <img style={{ height: "50px" }} src={cashIconA} alt="char"></img>;
var chro = <img style={{ height: "50px" }} src={cashIconAA} alt="chro"></img>;
var pbap = (
  <img style={{ height: "50px" }} src={transferIconPlusW} alt="pbap"></img>
);
var pbpc = (
  <img style={{ height: "50px" }} src={transferIconPlusAA} alt="pbpc"></img>
);
var dtap = (
  <img style={{ height: "50px" }} src={transferIconMinusW} alt="dtap"></img>
);
var dtpc = (
  <img style={{ height: "50px" }} src={transferIconMinusAA} alt="dtpc"></img>
);
var rtap = <img style={{ height: "50px" }} src={returnIconW} alt="rtap"></img>;
var rtpc = <img style={{ height: "50px" }} src={returnIconAA} alt="rtpc"></img>;

export class AcceptanceView extends Component {
  state = {
    checkedTransactionPk: null,
    isTransactionChecked: false,
    activeList: null,
    dialogOpen: false,
    dialogOpenAccept: false,
    dialogOpenDecline: false,
    dialogAttachFile: false,
    dialogConfirmPbTransaction: false,
    dialogConfirmRtTransaction: false,
    dialogConfirmDtTransaction: false,
    costsOwnerComment: null,
    assignAssetRequestFlag: false,
    invoice: null,
  };

  static propTypes = {
    getTransactionsByStatus: PropTypes.func.isRequired,
    queryInProgress_tr_details: PropTypes.bool.isRequired,
    transactionDetails: PropTypes.object.isRequired,
    getTransactionDetails: PropTypes.func.isRequired,
    patchTransactionDetails: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
  };

  componentDidMount() {
    this.props.getTransactionsByStatus("costsaccept");
    this.props.getTransactionsByStatus("payback");
    this.props.getTransactionsByStatus("return");
    this.props.getTransactionsByStatus("deduce");
  }

  componentDidUpdate(prevProps) {
    if (this.props.auth.main_profile.defaultCompany != prevProps.auth.main_profile.defaultCompany) {
      this.props.getTransactionsByStatus("costsaccept");
      this.props.getTransactionsByStatus("payback");
      this.props.getTransactionsByStatus("return");
      this.props.getTransactionsByStatus("deduce");
    }
  }

  handleChecked = (isChecked, transactionpk, activeList) => {
    this.setState({
      isTransactionChecked: isChecked,
      checkedTransactionPk: transactionpk,
      activeList: activeList,
    });

    if (isChecked === true) {
      this.props.getTransactionDetails(transactionpk);
    }
  };

  handleClickPreview = (event) => {
    this.setState({
      dialogOpen: true,
    });
  };

  handleClickCloseDialog = () => {
    this.setState({
      dialogOpen: false,
    });
  };

  handleDownloadInvoice = () => {
    const file = this.props.transactionDetails.invoice;
    window.location.href = file;
  };

  handleTextList = (name) => ({ target: { value } }) => {
    this.setState({
      ...this.state,
      [name]: value,
    });
  };

  handleCloseAcceptDialog = () => {
    this.setState({
      dialogOpenAccept: false,
    });
  };

  triggerAcceptTransaction = () => {
    this.setState({
      dialogOpenAccept: true,
    });
  };

  handleAcceptTransaction = () => {
    const body = new FormData();
    const checkedTransactionPk = this.state.checkedTransactionPk;
    const transactionCategory = this.props.transactionDetails
      .transactionCategory;

    if (transactionCategory === "wp") {
      body.append("transactionStatus", "ar");
      body.append("assignAssetRequestFlag", this.state.assignAssetRequestFlag);
      body.append("costsOwnerComment", this.state.costsOwnerComment);
      body.append("costsOwnerCommentDate", getFormattedDate(new Date()));
      body.append(
        "costsOwner",
        this.props.auth.user.first_name + " " + this.props.auth.user.last_name
      );
    } else {
      body.append("transactionStatus", "ar");
      body.append("costsOwnerComment", this.state.costsOwnerComment);
      body.append("costsOwnerCommentDate", getFormattedDate(new Date()));
      body.append(
        "costsOwner",
        this.props.auth.user.first_name + " " + this.props.auth.user.last_name
      );
    }

    this.setState({
      dialogOpenAccept: false,
      costsOwnerComment: null,
      assignAssetRequestFlag: false,
      checkedTransactionPk: null,
      isTransactionChecked: false,
    });

    this.props.patchTransactionDetails(
      checkedTransactionPk,
      body,
      "accepttransaction"
    );
  };

  handleChangeAssetFlag = () => {
    this.setState({
      assignAssetRequestFlag: !this.state.assignAssetRequestFlag,
    });
  };

  handleCloseDeclineDialog = () => {
    this.setState({
      dialogOpenDecline: false,
    });
  };

  triggerDeclineTransaction = () => {
    this.setState({
      dialogOpenDecline: true,
    });
  };

  handleDeclineTransaction = () => {
    const body = new FormData();
    const checkedTransactionPk = this.state.checkedTransactionPk;
    //const transactionCategory = this.props.transactionDetails
    //  .transactionCategory;

    body.append("transactionStatus", "od");
    body.append("costsOwnerComment", this.state.costsOwnerComment);
    body.append("costsOwnerCommentDate", getFormattedDate(new Date()));
    body.append(
      "costsOwner",
      this.props.auth.user.first_name + " " + this.props.auth.user.last_name
    );

    this.setState({
      dialogOpenDecline: false,
      costsOwnerComment: null,
      assignAssetRequestFlag: false,
      checkedTransactionPk: null,
      isTransactionChecked: false,
    });

    this.props.patchTransactionDetails(
      checkedTransactionPk,
      body,
      "declinetransaction"
    );
  };

  triggerAttachInvoice = () => {
    this.setState({
      dialogAttachFile: true,
    });
  };

  handleCloseDialogAttachFile = () => {
    this.setState({
      dialogAttachFile: false,
      invoice: null,
    });
  };

  handleAttachInvoice = () => {
    const body = new FormData();
    const invoiceUploadDate = getFormattedDate(new Date());
    body.append("invoice", this.state.invoice);
    body.append("invoiceUploadDate", invoiceUploadDate);

    //console.log(body);

    this.props.patchTransactionDetails(
      this.state.checkedTransactionPk,
      body,
      "attachinvoice"
    );
    this.setState({
      dialogAttachFile: false,
      invoice: null,
    });
  };

  updateAttachInvoiceFilename = (inputFiles, inputFileNames) => {
    this.setState({
      invoice: inputFiles[0],
    });
  };

  triggerConfirmPbTransaction = () => {
    this.setState({
      dialogConfirmPbTransaction: true,
    });
  };

  triggerConfirmRtTransaction = () => {
    this.setState({
      dialogConfirmRtTransaction: true,
    });
  };

  triggerConfirmDtTransaction = () => {
    this.setState({
      dialogConfirmDtTransaction: true,
    });
  };

  handleCloseDialogConfirmPbTransaction = () => {
    this.setState({
      dialogConfirmPbTransaction: false,
    });
  };

  handleCloseDialogConfirmRtTransaction = () => {
    this.setState({
      dialogConfirmRtTransaction: false,
    });
  };

  handleCloseDialogConfirmDtTransaction = () => {
    this.setState({
      dialogConfirmDtTransaction: false,
    });
  };

  handleConfirmPbRtDtTransaction = (transactiontype) => {
    const body = new FormData();
    const payDate = getFormattedDate(new Date());
    const costsOwnerComment = this.state.costsOwnerComment;
    const costsOwnerCommentDate = getFormattedDate(new Date());

    if (costsOwnerComment !== null) {
      body.append("payDate", payDate);
      body.append("transactionStatus", "pc");
      body.append("costsOwnerComment", costsOwnerComment);
      body.append("costsOwnerCommentDate", getFormattedDate(new Date()));
      body.append(
        "costsOwner",
        this.props.auth.user.first_name + " " + this.props.auth.user.last_name
      );
    } else {
      body.append("payDate", payDate);
      body.append("transactionStatus", "pc");
      body.append(
        "costsOwner",
        this.props.auth.user.first_name + " " + this.props.auth.user.last_name
      );
    }

    this.props.patchTransactionDetails(
      this.state.checkedTransactionPk,
      body,
      transactiontype
    );

    this.setState({
      dialogConfirmPbTransaction: false,
      dialogConfirmRtTransaction: false,
      dialogConfirmDtTransaction: false,
      costsOwnerComment: null,
      checkedTransactionPk: null,
      isTransactionChecked: false,
    });
  };

  render() {
    const {
      classes,
      queryInProgress_tr_details,
      transactionDetails,
    } = this.props;

    const {
      accounting_acceptance_preview,
      accounting_acceptance_accept,
      accounting_acceptance_decline,
      accounting_acceptance_confirm_payback,
      accounting_acceptance_confirm_return,
      accounting_acceptance_confirm_deduct,
      accounting_clearance_attach,
    } = this.props.auth.permissions;
    const isSuperUser = this.props.auth.user.is_superuser;

    const {
      isTransactionChecked,
      dialogOpen,
      dialogOpenAccept,
      dialogOpenDecline,
      dialogConfirmPbTransaction,
      dialogConfirmRtTransaction,
      dialogConfirmDtTransaction,
      costsOwnerComment,
      assignAssetRequestFlag,
      activeList,
      dialogAttachFile,
      invoice,
    } = this.state;

    const previewButtonDi = (
      <Fab
        disabled
        color="inherit"
        className={classes.fabblue}
        size="small"
        aria-label="scroll back to top"
      >
        <VisibilityIcon />
      </Fab>
    );

    const previewButtonEn = (
      <Fab
        onClick={this.handleClickPreview}
        color="inherit"
        className={classes.fabblue}
        size="small"
        aria-label="scroll back to top"
      >
        <VisibilityIcon />
      </Fab>
    );

    const discardButtonDisabled = (
      <Fab
        disabled
        color="inherit"
        className={classes.fabred}
        size="small"
        aria-label="scroll back to top"
      >
        <CloseIcon />
      </Fab>
    );

    const discardButtonEnabled = (
      <Fab
        onClick={this.triggerDeclineTransaction}
        color="inherit"
        className={classes.fabred}
        size="small"
        aria-label="scroll back to top"
      >
        <CloseIcon />
      </Fab>
    );

    const acceptButtonDisabled = (
      <Fab
        disabled
        color="inherit"
        className={classes.fabgreen}
        size="small"
        aria-label="scroll back to top"
      >
        <DoneIcon />
      </Fab>
    );

    const acceptButtonEnabled = (
      <Fab
        onClick={this.triggerAcceptTransaction}
        color="inherit"
        className={classes.fabgreen}
        size="small"
        aria-label="scroll back to top"
      >
        <DoneIcon />
      </Fab>
    );

    const confirmButtonDisabled = (
      <Fab
        disabled
        color="inherit"
        className={classes.fabgreen}
        size="small"
        aria-label="scroll back to top"
      >
        <DoneIcon />
      </Fab>
    );

    const pbconfirmButtonEnabled = (
      <Fab
        onClick={this.triggerConfirmPbTransaction}
        color="inherit"
        className={classes.fabgreen}
        size="small"
        aria-label="scroll back to top"
      >
        <DoneIcon />
      </Fab>
    );

    const rtconfirmButtonEnabled = (
      <Fab
        onClick={this.triggerConfirmRtTransaction}
        color="inherit"
        className={classes.fabgreen}
        size="small"
        aria-label="scroll back to top"
      >
        <DoneIcon />
      </Fab>
    );

    const dtconfirmButtonEnabled = (
      <Fab
        onClick={this.triggerConfirmDtTransaction}
        color="inherit"
        className={classes.fabgreen}
        size="small"
        aria-label="scroll back to top"
      >
        <DoneIcon />
      </Fab>
    );

    const declineButtonDi = (
      <Button
        disabled
        color="secondary"
        variant="contained"
        autoFocus
        size="large"
      >
        Odrzuć
      </Button>
    );

    const declineButtonEn = (
      <Button
        onClick={this.handleDeclineTransaction}
        color="secondary"
        variant="contained"
        autoFocus
        size="large"
      >
        Odrzuć
      </Button>
    );

    const attachButtonDi = (
      <Fab
        disabled
        size="small"
        aria-label="scroll back to top"
        className={classes.fabgreen}
      >
        <AttachFileIcon />
      </Fab>
    );

    const attachButtonEn = (
      <Fab
        size="small"
        aria-label="scroll back to top"
        className={classes.fabgreen}
      >
        <AttachFileIcon />
      </Fab>
    );

    const attachInvoiceButtonEn = (
      <Button
        variant="contained"
        color="inherit"
        size="large"
        onClick={this.handleAttachInvoice}
        style={{ marginTop: "20px" }}
        className={classes.buttonAccept}
      >
        Załącz
      </Button>
    );

    const attachInvoiceButtonDi = (
      <Button
        disabled
        variant="contained"
        color="inherit"
        size="large"
        style={{ marginTop: "20px" }}
        className={classes.buttonAccept}
      >
        Załącz
      </Button>
    );

    const pbButton = (
      <Button
        variant="contained"
        color="inherit"
        size="large"
        onClick={this.handleConfirmPbRtDtTransaction.bind(this, "acceptpb")}
        className={classes.buttonAccept}
      >
        Potwierdź
      </Button>
    );

    const rtButton = (
      <Button
        variant="contained"
        color="inherit"
        size="large"
        onClick={this.handleConfirmPbRtDtTransaction.bind(this, "acceptrt")}
        className={classes.buttonAccept}
      >
        Potwierdź
      </Button>
    );

    const dtButton = (
      <Button
        variant="contained"
        color="secondary"
        size="large"
        onClick={this.handleConfirmPbRtDtTransaction.bind(this, "acceptdt")}
      >
        Potwierdź
      </Button>
    );

    var transactionContent = null;
    var msspecificContent = null;
    var wpspecificContent = null;
    var esspecificContent = null;
    var purchaseListContent = null;
    var invoiceLink = null;

    if (transactionDetails.pk !== undefined && !queryInProgress_tr_details) {
      switch (transactionDetails.transactionType) {
        case "cp":
          purchaseListContent = (
            <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
              <Typography className={classes.textDescription7}>
                lista zakupów
              </Typography>
              <Typography className={classes.textDescription6}>
                {transactionDetails.purchaseList}
              </Typography>
            </Grid>
          );
          msspecificContent = (
            <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
              <Typography className={classes.textDescription7}>
                identyfikator stacji
              </Typography>
              <Typography className={classes.textDescription6}>
                {transactionDetails.baseStationIdentifier}
              </Typography>
            </Grid>
          );
          if (transactionDetails.car != null) {
            esspecificContent = (
              <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                <Typography className={classes.textDescription7}>
                  Numer rejestracyjny
                </Typography>
                <Typography className={classes.textDescription6}>
                  {transactionDetails.car.regNumber}
                </Typography>
              </Grid>
            );
          }
          wpspecificContent = (
            <Fragment>
              <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                <Typography className={classes.textDescription7}>
                  dla pracownika:
                </Typography>
                <Typography className={classes.textDescription6}>
                  {(transactionDetails.transactionEmployee == '' || transactionDetails.transactionEmployee == null) ? "nie podano" : transactionDetails.transactionEmployee}
                </Typography>
              </Grid>
              <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                <Typography className={classes.textDescription7}>
                  przypisać na stan Środków Trwałych
                </Typography>
                <Typography className={classes.textDescription6}>
                  {transactionDetails.assignAssetRequestFlag ? "tak" : "nie"}
                </Typography>
              </Grid>
              <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                <Typography className={classes.textDescription7}>
                  przypisano na stan Środków Trwałych
                </Typography>
                <Typography className={classes.textDescription6}>
                  {transactionDetails.assetAssignedFlag ? "tak" : "nie"}
                </Typography>
              </Grid>
            </Fragment>
          );
          invoiceLink = (
            <IconButton
              style={{ position: "relative", top: "-3px" }}
              aria-label="delete"
              onClick={this.handleDownloadInvoice}
            >
              <GetAppIcon />
            </IconButton>
          );
          transactionContent = (
            <Fragment>
              <Typography style={{ marginTop: "30px" }}></Typography>
              <Grid container className={classes.root} spacing={2}>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={1}>
                  {eval(
                    transactionDetails.transactionType +
                    transactionDetails.transactionStatus
                  )}
                </Grid>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={11}>
                  <Typography className={classes.textDescription5}>
                    {transactionDetails.bookDate +
                      " | " +
                      transactionDetails.owner_details.first_name +
                      " " +
                      transactionDetails.owner_details.last_name}
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.transactionDescription}
                  </Typography>
                </Grid>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textName}>
                    {transactionDetails.value +
                      " " +
                      transactionDetails.currency_description}
                  </Typography>
                </Grid>
                <div className={classes.border} />
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    typ transakcji
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.transactionType_description}
                  </Typography>
                </Grid>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    projekt
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.project.name}
                  </Typography>
                </Grid>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    kategoria transakcji
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.transactionCategory_description}
                  </Typography>
                </Grid>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    status
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.transactionStatus_description}
                  </Typography>
                </Grid>
                <div className={classes.border} />
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    waluta
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.currency_description}
                  </Typography>
                </Grid>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    kwota
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.value}
                  </Typography>
                </Grid>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    numer karty płatniczej
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.card}
                  </Typography>
                </Grid>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    data transakcji
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.transactionDate}
                  </Typography>
                </Grid>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    data zarejestrowania transakcji
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.createDate}
                  </Typography>
                </Grid>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    data księgowania
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.bookDate}
                  </Typography>
                </Grid>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    tytułem
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.transactionDescription}
                  </Typography>
                </Grid>
                {purchaseListContent}
                {transactionDetails.transactionCategory === "es"
                  ? esspecificContent
                  : ""}
                {transactionDetails.transactionCategory === "ms"
                  ? msspecificContent
                  : ""}
                <div className={classes.border} />
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    właścicel budżetu
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.costsOwner === null
                      ? "-"
                      : transactionDetails.costsOwner}
                  </Typography>
                </Grid>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    komentarz właścicela budżetu
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.costsOwnerComment === null
                      ? "-"
                      : transactionDetails.costsOwnerComment}
                  </Typography>
                </Grid>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    data akceptacji kosztów
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.costsOwnerCommentDate === null
                      ? "-"
                      : transactionDetails.costsOwnerCommentDate}
                  </Typography>
                </Grid>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    rozliczający
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.clearanceOwner === null
                      ? "-"
                      : transactionDetails.clearanceOwner}
                  </Typography>
                </Grid>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    komentarz rozliczającego
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.clearanceOwnerComment === null
                      ? "-"
                      : transactionDetails.clearanceOwnerComment}
                  </Typography>
                </Grid>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    data rozliczenia
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.clearanceOwnerDate === null
                      ? "-"
                      : transactionDetails.clearanceOwnerDate}
                  </Typography>
                </Grid>
                <div className={classes.border} />
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    faktura
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.invoice === null ? "-" : invoiceLink}
                  </Typography>
                </Grid>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    data zamieszczenia faktury
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.invoiceUploadDate === null
                      ? "-"
                      : transactionDetails.invoiceUploadDate}
                  </Typography>
                </Grid>
                {transactionDetails.transactionCategory === "wp"
                  ? wpspecificContent
                  : ""}
              </Grid>
            </Fragment>
          );
          break;
        case "oc":
          purchaseListContent = (
            <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
              <Typography className={classes.textDescription7}>
                lista zakupów
              </Typography>
              <Typography className={classes.textDescription6}>
                {transactionDetails.purchaseList}
              </Typography>
            </Grid>
          );
          msspecificContent = (
            <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
              <Typography className={classes.textDescription7}>
                identyfikator stacji
              </Typography>
              <Typography className={classes.textDescription6}>
                {transactionDetails.baseStationIdentifier}
              </Typography>
            </Grid>
          );
          if (transactionDetails.car != null) {
            esspecificContent = (
              <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                <Typography className={classes.textDescription7}>
                  Numer rejestracyjny
                </Typography>
                <Typography className={classes.textDescription6}>
                  {transactionDetails.car.regNumber}
                </Typography>
              </Grid>
            );
          }
          wpspecificContent = (
            <Fragment>
              <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                <Typography className={classes.textDescription7}>
                  dla pracownika:
                </Typography>
                <Typography className={classes.textDescription6}>
                  {(transactionDetails.transactionEmployee == '' || transactionDetails.transactionEmployee == null) ? "nie podano" : transactionDetails.transactionEmployee}
                </Typography>
              </Grid>
              <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                <Typography className={classes.textDescription7}>
                  przypisać na stan Środków Trwałych
                </Typography>
                <Typography className={classes.textDescription6}>
                  {transactionDetails.assignAssetRequestFlag ? "tak" : "nie"}
                </Typography>
              </Grid>
              <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                <Typography className={classes.textDescription7}>
                  przypisano na stan Środków Trwałych
                </Typography>
                <Typography className={classes.textDescription6}>
                  {transactionDetails.assetAssignedFlag ? "tak" : "nie"}
                </Typography>
              </Grid>
            </Fragment>
          );
          invoiceLink = (
            <IconButton
              style={{ position: "relative", top: "-3px" }}
              aria-label="delete"
              onClick={this.handleDownloadInvoice}
            >
              <GetAppIcon />
            </IconButton>
          );
          transactionContent = (
            <Fragment>
              <Typography style={{ marginTop: "30px" }}></Typography>
              <Grid container className={classes.root} spacing={2}>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={1}>
                  {eval(
                    transactionDetails.transactionType +
                    transactionDetails.transactionStatus
                  )}
                </Grid>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={11}>
                  <Typography className={classes.textDescription5}>
                    {transactionDetails.bookDate +
                      " | " +
                      transactionDetails.owner_details.first_name +
                      " " +
                      transactionDetails.owner_details.last_name}
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.transactionDescription}
                  </Typography>
                </Grid>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textName}>
                    {transactionDetails.value +
                      " " +
                      transactionDetails.currency_description}
                  </Typography>
                </Grid>
                <div className={classes.border} />
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    typ transakcji
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.transactionType_description}
                  </Typography>
                </Grid>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    projekt
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.project.name}
                  </Typography>
                </Grid>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    kategoria transakcji
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.transactionCategory_description}
                  </Typography>
                </Grid>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    status
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.transactionStatus_description}
                  </Typography>
                </Grid>
                <div className={classes.border} />
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    waluta
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.currency_description}
                  </Typography>
                </Grid>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    kwota
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.value}
                  </Typography>
                </Grid>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    data transakcji
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.transactionDate}
                  </Typography>
                </Grid>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    data zarejestrowania transakcji
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.createDate}
                  </Typography>
                </Grid>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    data księgowania
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.bookDate}
                  </Typography>
                </Grid>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    tytułem
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.transactionDescription}
                  </Typography>
                </Grid>
                {purchaseListContent}
                {transactionDetails.transactionCategory === "es"
                  ? esspecificContent
                  : ""}
                {transactionDetails.transactionCategory === "ms"
                  ? msspecificContent
                  : ""}
                <div className={classes.border} />
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    właścicel budżetu
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.costsOwner === null
                      ? "-"
                      : transactionDetails.costsOwner}
                  </Typography>
                </Grid>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    komentarz właścicela budżetu
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.costsOwnerComment === null
                      ? "-"
                      : transactionDetails.costsOwnerComment}
                  </Typography>
                </Grid>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    data akceptacji kosztów
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.costsOwnerCommentDate === null
                      ? "-"
                      : transactionDetails.costsOwnerCommentDate}
                  </Typography>
                </Grid>
                <div className={classes.border} />
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    rozliczający
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.clearanceOwner === null
                      ? "-"
                      : transactionDetails.clearanceOwner}
                  </Typography>
                </Grid>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    komentarz rozliczającego
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.clearanceOwnerComment === null
                      ? "-"
                      : transactionDetails.clearanceOwnerComment}
                  </Typography>
                </Grid>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    data rozliczenia
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.clearanceOwnerCommentDate === null
                      ? "-"
                      : transactionDetails.clearanceOwnerCommentDate}
                  </Typography>
                </Grid>
                <div className={classes.border} />
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    faktura
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.invoice === null ? "-" : invoiceLink}
                  </Typography>
                </Grid>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    data zamieszczenia faktury
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.invoiceUploadDate === null
                      ? "-"
                      : transactionDetails.invoiceUploadDate}
                  </Typography>
                </Grid>
                {transactionDetails.transactionCategory === "wp"
                  ? wpspecificContent
                  : ""}
              </Grid>
            </Fragment>
          );
          break;
        case "ch":
          purchaseListContent = (
            <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
              <Typography className={classes.textDescription7}>
                lista zakupów
              </Typography>
              <Typography className={classes.textDescription6}>
                {transactionDetails.purchaseList}
              </Typography>
            </Grid>
          );
          msspecificContent = (
            <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
              <Typography className={classes.textDescription7}>
                identyfikator stacji
              </Typography>
              <Typography className={classes.textDescription6}>
                {transactionDetails.baseStationIdentifier}
              </Typography>
            </Grid>
          );
          if (transactionDetails.car != null) {
            esspecificContent = (
              <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                <Typography className={classes.textDescription7}>
                  Numer rejestracyjny
                </Typography>
                <Typography className={classes.textDescription6}>
                  {transactionDetails.car.regNumber}
                </Typography>
              </Grid>
            );
          }
          wpspecificContent = (
            <Fragment>
              <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                <Typography className={classes.textDescription7}>
                  dla pracownika:
                </Typography>
                <Typography className={classes.textDescription6}>
                  {(transactionDetails.transactionEmployee == '' || transactionDetails.transactionEmployee == null) ? "nie podano" : transactionDetails.transactionEmployee}
                </Typography>
              </Grid>
              <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                <Typography className={classes.textDescription7}>
                  przypisać na stan Środków Trwałych
                </Typography>
                <Typography className={classes.textDescription6}>
                  {transactionDetails.assignAssetRequestFlag ? "tak" : "nie"}
                </Typography>
              </Grid>
              <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                <Typography className={classes.textDescription7}>
                  przypisano na stan Środków Trwałych
                </Typography>
                <Typography className={classes.textDescription6}>
                  {transactionDetails.assetAssignedFlag ? "tak" : "nie"}
                </Typography>
              </Grid>
            </Fragment>
          );
          invoiceLink = (
            <IconButton
              style={{ position: "relative", top: "-3px" }}
              aria-label="delete"
              onClick={this.handleDownloadInvoice}
            >
              <GetAppIcon />
            </IconButton>
          );
          transactionContent = (
            <Fragment>
              <Typography style={{ marginTop: "30px" }}></Typography>
              <Grid container className={classes.root} spacing={2}>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={1}>
                  {eval(
                    transactionDetails.transactionType +
                    transactionDetails.transactionStatus
                  )}
                </Grid>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={11}>
                  <Typography className={classes.textDescription5}>
                    {transactionDetails.bookDate +
                      " | " +
                      transactionDetails.owner_details.first_name +
                      " " +
                      transactionDetails.owner_details.last_name}
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.transactionDescription}
                  </Typography>
                </Grid>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textName}>
                    {transactionDetails.value +
                      " " +
                      transactionDetails.currency_description}
                  </Typography>
                </Grid>
                <div className={classes.border} />
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    typ transakcji
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.transactionType_description}
                  </Typography>
                </Grid>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    projekt
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.project.name}
                  </Typography>
                </Grid>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    kategoria transakcji
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.transactionCategory_description}
                  </Typography>
                </Grid>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    status
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.transactionStatus_description}
                  </Typography>
                </Grid>
                <div className={classes.border} />
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    waluta
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.currency_description}
                  </Typography>
                </Grid>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    kwota
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.value}
                  </Typography>
                </Grid>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    data transakcji
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.transactionDate}
                  </Typography>
                </Grid>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    data zarejestrowania transakcji
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.createDate}
                  </Typography>
                </Grid>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    data księgowania
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.bookDate}
                  </Typography>
                </Grid>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    tytułem
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.transactionDescription}
                  </Typography>
                </Grid>
                {purchaseListContent}
                {transactionDetails.transactionCategory === "es"
                  ? esspecificContent
                  : ""}
                {transactionDetails.transactionCategory === "ms"
                  ? msspecificContent
                  : ""}
                <div className={classes.border} />
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    właścicel budżetu
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.costsOwner === null
                      ? "-"
                      : transactionDetails.costsOwner}
                  </Typography>
                </Grid>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    komentarz właścicela budżetu
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.costsOwnerComment === null
                      ? "-"
                      : transactionDetails.costsOwnerComment}
                  </Typography>
                </Grid>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    data akceptacji kosztów
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.costsOwnerCommentDate === null
                      ? "-"
                      : transactionDetails.costsOwnerCommentDate}
                  </Typography>
                </Grid>
                <div className={classes.border} />
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    rozliczający
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.clearanceOwner === null
                      ? "-"
                      : transactionDetails.clearanceOwner}
                  </Typography>
                </Grid>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    komentarz rozliczającego
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.clearanceOwnerComment === null
                      ? "-"
                      : transactionDetails.clearanceOwnerComment}
                  </Typography>
                </Grid>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    data rozliczenia
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.clearanceOwnerCommentDate === null
                      ? "-"
                      : transactionDetails.clearanceOwnerCommentDate}
                  </Typography>
                </Grid>
                <div className={classes.border} />
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    faktura
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.invoice === null ? "-" : invoiceLink}
                  </Typography>
                </Grid>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    data zamieszczenia faktury
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.invoiceUploadDate === null
                      ? "-"
                      : transactionDetails.invoiceUploadDate}
                  </Typography>
                </Grid>
                {transactionDetails.transactionCategory === "wp"
                  ? wpspecificContent
                  : ""}
              </Grid>
            </Fragment>
          );
          break;
        case "pb":
          invoiceLink = (
            <IconButton
              style={{ position: "relative", top: "-3px" }}
              aria-label="delete"
              onClick={this.handleDownloadInvoice}
            >
              <GetAppIcon />
            </IconButton>
          );
          transactionContent = (
            <Fragment>
              <Typography style={{ marginTop: "30px" }}></Typography>
              <Grid container className={classes.root} spacing={2}>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={1}>
                  {eval(
                    transactionDetails.transactionType +
                    transactionDetails.transactionStatus
                  )}
                </Grid>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={11}>
                  <Typography className={classes.textDescription5}>
                    {transactionDetails.bookDate +
                      " | " +
                      transactionDetails.owner_details.first_name +
                      " " +
                      transactionDetails.owner_details.last_name}
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.transactionDescription}
                  </Typography>
                </Grid>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textName}>
                    {transactionDetails.value +
                      " " +
                      transactionDetails.currency_description}
                  </Typography>
                </Grid>
                <div className={classes.border} />
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    typ transakcji
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.transactionType_description}
                  </Typography>
                </Grid>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    projekt
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.project.name}
                  </Typography>
                </Grid>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    kategoria transakcji
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.transactionCategory_description}
                  </Typography>
                </Grid>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    status
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.transactionStatus_description}
                  </Typography>
                </Grid>
                <div className={classes.border} />
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    waluta
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.currency_description}
                  </Typography>
                </Grid>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    kwota
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.value}
                  </Typography>
                </Grid>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    data zarejestrowania transakcji
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.createDate}
                  </Typography>
                </Grid>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    tytułem
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.transactionDescription}
                  </Typography>
                </Grid>
                <div className={classes.border} />
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    rozliczający
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.clearanceOwner === null
                      ? "-"
                      : transactionDetails.clearanceOwner}
                  </Typography>
                </Grid>
                <div className={classes.border} />
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    dokonujący wypłaty
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.costsOwner === null
                      ? "-"
                      : transactionDetails.costsOwner}
                  </Typography>
                </Grid>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    komentarz wypłacającego
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.costsOwnerComment === null
                      ? "-"
                      : transactionDetails.costsOwnerComment}
                  </Typography>
                </Grid>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    data wypłaty
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.payDate === null
                      ? "-"
                      : transactionDetails.payDate}
                  </Typography>
                </Grid>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    potwierdzenie wypłaty
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.invoice === null ? "-" : invoiceLink}
                  </Typography>
                </Grid>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    data zamieszczenia potwierdzenia
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.invoiceUploadDate === null
                      ? "-"
                      : transactionDetails.invoiceUploadDate}
                  </Typography>
                </Grid>
              </Grid>
            </Fragment>
          );
          break;
        case "dt":
          invoiceLink = (
            <IconButton
              style={{ position: "relative", top: "-3px" }}
              aria-label="delete"
              onClick={this.handleDownloadInvoice}
            >
              <GetAppIcon />
            </IconButton>
          );
          transactionContent = (
            <Fragment>
              <Typography style={{ marginTop: "30px" }}></Typography>
              <Grid container className={classes.root} spacing={2}>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={1}>
                  {eval(
                    transactionDetails.transactionType +
                    transactionDetails.transactionStatus
                  )}
                </Grid>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={11}>
                  <Typography className={classes.textDescription5}>
                    {transactionDetails.bookDate +
                      " | " +
                      transactionDetails.owner_details.first_name +
                      " " +
                      transactionDetails.owner_details.last_name}
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.transactionDescription}
                  </Typography>
                </Grid>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textName}>
                    {transactionDetails.value +
                      " " +
                      transactionDetails.currency_description}
                  </Typography>
                </Grid>
                <div className={classes.border} />
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    typ transakcji
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.transactionType_description}
                  </Typography>
                </Grid>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    projekt
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.project.name}
                  </Typography>
                </Grid>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    kategoria transakcji
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.transactionCategory_description}
                  </Typography>
                </Grid>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    status
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.transactionStatus_description}
                  </Typography>
                </Grid>
                <div className={classes.border} />
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    waluta
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.currency_description}
                  </Typography>
                </Grid>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    kwota
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.value}
                  </Typography>
                </Grid>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    data zarejestrowania transakcji
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.createDate}
                  </Typography>
                </Grid>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    tytułem
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.transactionDescription}
                  </Typography>
                </Grid>
                <div className={classes.border} />
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    rozliczający
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.clearanceOwner === null
                      ? "-"
                      : transactionDetails.clearanceOwner}
                  </Typography>
                </Grid>
                <div className={classes.border} />
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    realizujący potrącenie
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.costsOwner === null
                      ? "-"
                      : transactionDetails.costsOwner}
                  </Typography>
                </Grid>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    komentarz realizującego potrącenie
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.costsOwnerComment === null
                      ? "-"
                      : transactionDetails.costsOwnerComment}
                  </Typography>
                </Grid>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    data potrącenia
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.payDate === null
                      ? "-"
                      : transactionDetails.payDate}
                  </Typography>
                </Grid>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    podstawa potrącenia
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.invoice === null ? "-" : invoiceLink}
                  </Typography>
                </Grid>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    data zamieszczenia podstawy
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.invoiceUploadDate === null
                      ? "-"
                      : transactionDetails.invoiceUploadDate}
                  </Typography>
                </Grid>
              </Grid>
            </Fragment>
          );
          break;
        case "rt":
          invoiceLink = (
            <IconButton
              style={{ position: "relative", top: "-3px" }}
              aria-label="delete"
              onClick={this.handleDownloadInvoice}
            >
              <GetAppIcon />
            </IconButton>
          );
          transactionContent = (
            <Fragment>
              <Typography style={{ marginTop: "30px" }}></Typography>
              <Grid container className={classes.root} spacing={2}>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={1}>
                  {eval(
                    transactionDetails.transactionType +
                    transactionDetails.transactionStatus
                  )}
                </Grid>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={11}>
                  <Typography className={classes.textDescription5}>
                    {transactionDetails.bookDate +
                      " | " +
                      transactionDetails.owner_details.first_name +
                      " " +
                      transactionDetails.owner_details.last_name}
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.transactionDescription}
                  </Typography>
                </Grid>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textName}>
                    {transactionDetails.value +
                      " " +
                      transactionDetails.currency_description}
                  </Typography>
                </Grid>
                <div className={classes.border} />
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    typ transakcji
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.transactionType_description}
                  </Typography>
                </Grid>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    projekt
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.project.name}
                  </Typography>
                </Grid>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    kategoria transakcji
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.transactionCategory_description}
                  </Typography>
                </Grid>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    status
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.transactionStatus_description}
                  </Typography>
                </Grid>
                <div className={classes.border} />
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    waluta
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.currency_description}
                  </Typography>
                </Grid>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    kwota
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.value}
                  </Typography>
                </Grid>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    data zarejestrowania transakcji
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.createDate}
                  </Typography>
                </Grid>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    tytułem
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.transactionDescription}
                  </Typography>
                </Grid>
                <div className={classes.border} />
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    rozliczający
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.clearanceOwner === null
                      ? "-"
                      : transactionDetails.clearanceOwner}
                  </Typography>
                </Grid>
                <div className={classes.border} />
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    przyjmujący zwrot
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.costsOwner === null
                      ? "-"
                      : transactionDetails.costsOwner}
                  </Typography>
                </Grid>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    komentarz przyjmującego zwrot
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.costsOwnerComment === null
                      ? "-"
                      : transactionDetails.costsOwnerComment}
                  </Typography>
                </Grid>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    data zwrotu
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.payDate === null
                      ? "-"
                      : transactionDetails.payDate}
                  </Typography>
                </Grid>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    potwierdzenie zwrotu
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.invoice === null ? "-" : invoiceLink}
                  </Typography>
                </Grid>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    data zamieszczenia potwierdzenia
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.invoiceUploadDate === null
                      ? "-"
                      : transactionDetails.invoiceUploadDate}
                  </Typography>
                </Grid>
              </Grid>
            </Fragment>
          );
          break;
        default:
      }
    }

    var dialogAccept = null;

    if (transactionDetails.transactionCategory === "wp") {
      dialogAccept = (
        <Dialog
          open={dialogOpenAccept}
          onClose={this.handleCloseAcceptDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Akceptacja kosztów</DialogTitle>
          <DialogContent>
            <Typography className={classes.textDescription3}>
              Możesz dodać krótki komentarz do akceptowanej transakcji ...
            </Typography>
            <br></br>
            <Typography className={classes.textDescription4}>
              (max 150 znaków)
            </Typography>
            <ThemeProvider theme={materialTheme}>
              <TextField
                autoFocus
                margin="dense"
                id="name"
                label="komentarz"
                fullWidth
                inputProps={{ maxLength: 150 }}
                multiline
                rowsMax={4}
                variant="filled"
                value={costsOwnerComment || ""}
                onChange={this.handleTextList("costsOwnerComment")}
              />
            </ThemeProvider>
            <FormHelperText>
              Jeżeli chcesz aby zakupiony sprzęt był przypisany pracownikowi
              jako Środek Trwały, zaznacz tą opcję:
            </FormHelperText>
            <FormControlLabel
              control={
                <Switch
                  checked={assignAssetRequestFlag}
                  onChange={this.handleChangeAssetFlag}
                  name="checkedA"
                />
              }
              label="Przypisz do pracownika jako Środek Trwały"
            />
          </DialogContent>
          <ThemeProvider theme={materialTheme}>
            <DialogActions>
              <Button
                onClick={this.handleCloseAcceptDialog}
                size="large"
                color="default"
              >
                Anuluj
              </Button>
              <Button
                onClick={this.handleAcceptTransaction}
                color="primary"
                variant="contained"
                autoFocus
                size="large"
              >
                Akceptuj
              </Button>
            </DialogActions>
          </ThemeProvider>
        </Dialog>
      );
    } else {
      dialogAccept = (
        <Dialog
          open={dialogOpenAccept}
          onClose={this.handleCloseAcceptDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Akceptacja kosztów</DialogTitle>
          <DialogContent>
            <Typography className={classes.textDescription3}>
              Możesz dodać krótki komentarz do akceptowanej transakcji ...
            </Typography>
            <br></br>
            <Typography className={classes.textDescription4}>
              (max 150 znaków)
            </Typography>
            <ThemeProvider theme={materialTheme}>
              <TextField
                autoFocus
                margin="dense"
                id="name"
                label="komentarz"
                fullWidth
                inputProps={{ maxLength: 150 }}
                multiline
                rowsMax={4}
                variant="filled"
                value={costsOwnerComment || ""}
                onChange={this.handleTextList("costsOwnerComment")}
              />
            </ThemeProvider>
          </DialogContent>
          <ThemeProvider theme={materialTheme}>
            <DialogActions>
              <Button
                onClick={this.handleCloseAcceptDialog}
                size="large"
                color="default"
              >
                Anuluj
              </Button>
              <Button
                onClick={this.handleAcceptTransaction}
                color="primary"
                variant="contained"
                autoFocus
                size="large"
              >
                Akceptuj
              </Button>
            </DialogActions>
          </ThemeProvider>
        </Dialog>
      );
    }

    return (
      <Fragment>
        <Box m={2}>
          <Grid container justify="flex-start" spacing={4}>
            <Grid item xs={12} sm={6} md={4} lg={6}>
              <ListAccept
                checked={this.handleChecked}
                isTransactionChecked={isTransactionChecked}
                activeList={activeList}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={6}>
              <ListPayback
                checked={this.handleChecked}
                isTransactionChecked={isTransactionChecked}
                activeList={activeList}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={6}>
              <ListReturn
                checked={this.handleChecked}
                isTransactionChecked={isTransactionChecked}
                activeList={activeList}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={6}>
              <ListDeduce
                checked={this.handleChecked}
                isTransactionChecked={isTransactionChecked}
                activeList={activeList}
              />
            </Grid>
          </Grid>
        </Box>

        {
          //common ACTIONS
        }
        <Zoom in={isTransactionChecked}>
          <div role="presentation" className={classes.fb2}>
            <Tooltip title="Pokaż szczegóły" placement="left">
              {!queryInProgress_tr_details && (accounting_acceptance_preview || isSuperUser)
                ? previewButtonEn
                : previewButtonDi}
            </Tooltip>
          </div>
        </Zoom>
        {
          //Transfer asset ACTIONS
        }
        <Zoom
          in={
            isTransactionChecked &&
            transactionDetails.transactionStatus === "ak"
          }
        >
          <div role="presentation" className={classes.fb1}>
            <Tooltip title="Brak akceptacji" placement="left">
              {!queryInProgress_tr_details && (accounting_acceptance_decline || isSuperUser)
                ? discardButtonEnabled
                : discardButtonDisabled}
            </Tooltip>
          </div>
        </Zoom>
        <Zoom
          in={
            isTransactionChecked &&
            transactionDetails.transactionStatus === "ak"
          }
        >
          <div role="presentation" className={classes.fb6}>
            <Tooltip title="Zaakceptuj transakcję" placement="left">
              {!queryInProgress_tr_details && (accounting_acceptance_accept || isSuperUser)
                ? acceptButtonEnabled
                : acceptButtonDisabled}
            </Tooltip>
          </div>
        </Zoom>

        <Zoom
          in={
            isTransactionChecked && transactionDetails.transactionType === "pb"
          }
        >
          <div role="presentation" className={classes.fb6}>
            <Tooltip title="Potwierdź zwrot dla pracownika" placement="left">
              {!queryInProgress_tr_details && (accounting_acceptance_confirm_payback || isSuperUser)
                ? pbconfirmButtonEnabled
                : confirmButtonDisabled}
            </Tooltip>
          </div>
        </Zoom>

        <Zoom
          in={
            isTransactionChecked && transactionDetails.transactionType === "rt"
          }
        >
          <div role="presentation" className={classes.fb6}>
            <Tooltip title="Potwierdź zwrot do Kasy" placement="left">
              {!queryInProgress_tr_details && (accounting_acceptance_confirm_return || isSuperUser)
                ? rtconfirmButtonEnabled
                : confirmButtonDisabled}
            </Tooltip>
          </div>
        </Zoom>

        <Zoom
          in={
            isTransactionChecked && transactionDetails.transactionType === "dt"
          }
        >
          <div role="presentation" className={classes.fb6}>
            <Tooltip title="Potwierdź potrącenie z pensji" placement="left">
              {!queryInProgress_tr_details && (accounting_acceptance_confirm_deduct || isSuperUser)
                ? dtconfirmButtonEnabled
                : confirmButtonDisabled}
            </Tooltip>
          </div>
        </Zoom>

        <Zoom
          //TU DODAĆ ŚRODKI WŁASNE
          in={
            isTransactionChecked &&
            transactionDetails.transactionType !== "cp" &&
            transactionDetails.transactionType !== "ch"
          }
        >
          <div
            onClick={this.triggerAttachInvoice}
            role="presentation"
            className={classes.fb5}
          >
            <Tooltip title="Załącz dokument" placement="left">
              {!queryInProgress_tr_details && (accounting_clearance_attach || isSuperUser)
                ? attachButtonEn
                : attachButtonDi}
            </Tooltip>
          </div>
        </Zoom>

        <Dialog
          fullScreen
          open={dialogOpen}
          onClose={this.handleClickCloseDialog}
          aria-labelledby="form-dialog-title"
        >
          <AppBar position="static" style={{ background: "#0071BC" }}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={this.handleClickCloseDialog}
                aria-label="close"
                className={classes.menuButton}
              >
                <CloseIcon />
              </IconButton>
              <Typography variant="h6" className={classes.title}>
                Szczegóły transakcji
              </Typography>
            </Toolbar>
          </AppBar>
          <DialogContent style={{ background: "#f5f5f5" }}>
            {transactionContent}
          </DialogContent>
        </Dialog>

        {dialogAccept}

        <Dialog
          open={dialogOpenDecline}
          onClose={this.handleCloseDeclineDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            Brak akceptacji kosztów
          </DialogTitle>
          <DialogContent>
            <Typography className={classes.textDescription3}>
              Dodaj komentarz uzasadniający powód braku akceptacji transakcji
              ...
            </Typography>
            <br></br>
            <Typography className={classes.textDescription4}>
              (max 150 znaków)
            </Typography>
            <ThemeProvider theme={materialTheme}>
              <TextField
                autoFocus
                margin="dense"
                id="name"
                label="komentarz"
                fullWidth
                inputProps={{ maxLength: 150 }}
                multiline
                rowsMax={4}
                variant="filled"
                value={costsOwnerComment || ""}
                onChange={this.handleTextList("costsOwnerComment")}
              />
            </ThemeProvider>
          </DialogContent>
          <ThemeProvider theme={materialTheme}>
            <DialogActions>
              <Button
                onClick={this.handleCloseDeclineDialog}
                size="large"
                color="default"
              >
                Anuluj
              </Button>
              {costsOwnerComment !== null ? declineButtonEn : declineButtonDi}
            </DialogActions>
          </ThemeProvider>
        </Dialog>

        <Dialog
          fullScreen
          open={dialogAttachFile}
          onClose={this.handleCloseDialogAttachFile}
          aria-labelledby="form-dialog-title"
        >
          <AppBar position="static" style={{ background: "#0071BC" }}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={this.handleCloseDialogAttachFile}
                aria-label="close"
                className={classes.menuButton}
              >
                <CloseIcon />
              </IconButton>
              <Typography variant="h6" className={classes.title}>
                Załącz dokument
              </Typography>
            </Toolbar>
          </AppBar>
          <DialogContent style={{ background: "#f5f5f5" }}>
            <CustomFileInput
              onChange={this.updateAttachInvoiceFilename}
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                placeholder: "wybierz plik ...",
              }}
              endButton={{
                buttonProps: {
                  round: true,
                  color: "primary",
                  justIcon: true,
                  fileButton: true,
                },
                icon: <AttachFile />,
              }}
            />
            {invoice ? attachInvoiceButtonEn : attachInvoiceButtonDi}
          </DialogContent>
        </Dialog>

        <Dialog
          open={dialogConfirmPbTransaction}
          onClose={this.handleCloseDialogConfirmPbTransaction}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            Potwierdź zwrot dla pracownika
          </DialogTitle>
          <DialogContent>
            <Typography className={classes.textDescription3}>
              Dodaj komentarz, jeżeli wymagany ...
            </Typography>
            <br></br>
            <Typography className={classes.textDescription4}>
              (max 150 znaków)
            </Typography>
            <ThemeProvider theme={materialTheme}>
              <TextField
                autoFocus
                margin="dense"
                id="name"
                label="komentarz"
                fullWidth
                inputProps={{ maxLength: 150 }}
                multiline
                rowsMax={4}
                variant="filled"
                value={costsOwnerComment || ""}
                onChange={this.handleTextList("costsOwnerComment")}
              />
            </ThemeProvider>
            <FormHelperText>
              Jeżeli chcesz dodać dokument potwierdzający wykonanie przelewu,
              zamknij to okno i kliknij na "załącz dokument". W każdym czasie
              możesz załączyć dokument do transakcji, przechodząc do zakładki{" "}
              <b>Rozliczenia</b>, gdzie po wybraniu pracownika należy odszukać
              wybraną transakcję.
            </FormHelperText>
          </DialogContent>
          <ThemeProvider theme={materialTheme}>
            <DialogActions>
              <Button
                onClick={this.handleCloseDialogConfirmPbTransaction}
                size="large"
                color="default"
              >
                Anuluj
              </Button>
              {pbButton}
            </DialogActions>
          </ThemeProvider>
        </Dialog>

        <Dialog
          open={dialogConfirmRtTransaction}
          onClose={this.handleCloseDialogConfirmRtTransaction}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            Potwierdź zwrot do kasy
          </DialogTitle>
          <DialogContent>
            <Typography className={classes.textDescription3}>
              Dodaj komentarz, jeżeli wymagany ...
            </Typography>
            <Typography className={classes.textDescription4}>
              (max 150 znaków)
            </Typography>
            <ThemeProvider theme={materialTheme}>
              <TextField
                autoFocus
                margin="dense"
                id="name"
                label="komentarz"
                fullWidth
                inputProps={{ maxLength: 150 }}
                multiline
                rowsMax={4}
                variant="filled"
                value={costsOwnerComment || ""}
                onChange={this.handleTextList("costsOwnerComment")}
              />
            </ThemeProvider>
            <FormHelperText>
              Jeżeli chcesz dodać dokument potwierdzający <b>zwrot do kasy</b>,
              zamknij to okno i kliknij na "załącz dokument". W każdym czasie
              możesz załączyć dokument do transakcji, przechodząc do zakładki{" "}
              <b>Rozliczenia</b>, gdzie po wybraniu pracownika należy odszukać
              wybraną transakcję.
            </FormHelperText>
          </DialogContent>
          <ThemeProvider theme={materialTheme}>
            <DialogActions>
              <Button
                onClick={this.handleCloseDialogConfirmRtTransaction}
                size="large"
                color="default"
              >
                Anuluj
              </Button>
              {rtButton}
            </DialogActions>
          </ThemeProvider>
        </Dialog>

        <Dialog
          open={dialogConfirmDtTransaction}
          onClose={this.handleCloseDialogConfirmDtTransaction}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            Potwierdź realizację potrącenia z pensji
          </DialogTitle>
          <DialogContent>
            <Typography className={classes.textDescription3}>
              Dodaj komentarz, jeżeli wymagany ...
            </Typography>
            <Typography className={classes.textDescription4}>
              (max 150 znaków)
            </Typography>
            <ThemeProvider theme={materialTheme}>
              <TextField
                autoFocus
                margin="dense"
                id="name"
                label="komentarz"
                fullWidth
                inputProps={{ maxLength: 150 }}
                multiline
                rowsMax={4}
                variant="filled"
                value={costsOwnerComment || ""}
                onChange={this.handleTextList("costsOwnerComment")}
              />
            </ThemeProvider>
            <FormHelperText>
              Jeżeli chcesz dodać dokument potwierdzający{" "}
              <b>wykonanie potrącenia</b>, zamknij to okno i kliknij na "załącz
              dokument". W każdym czasie możesz załączyć dokument do transakcji,
              przechodząc do zakładki <b>Rozliczenia</b>, gdzie po wybraniu
              pracownika należy odszukać wybraną transakcję.
            </FormHelperText>
          </DialogContent>
          <ThemeProvider theme={materialTheme}>
            <DialogActions>
              <Button
                onClick={this.handleCloseDialogConfirmDtTransaction}
                size="large"
                color="default"
              >
                Anuluj
              </Button>
              {dtButton}
            </DialogActions>
          </ThemeProvider>
        </Dialog>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  queryInProgress_tr_details: state.accounting.queryInProgress_tr_details,
  transactionDetails: state.accounting.transactionDetails,
  auth: state.auth,
});

export default connect(
  mapStateToProps,
  { getTransactionsByStatus, getTransactionDetails, patchTransactionDetails }
)(withStyles(styles, { withTheme: true })(AcceptanceView));
