import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Employee from './Employee.js';
import HolidaysManagerViewer from '../Holidays-manager/HolidaysManagerViewer';
import { connect } from "react-redux";
import { patchProfile } from "../Redux/actions/auth";
import { TabPanel } from "../common/TabPanel.js";

export class HolidaysViewer extends Component {
  state = {
    value: null
  }

  static propTypes = {
    auth: PropTypes.object.isRequired,
    patchProfile: PropTypes.func.isRequired
  };

  static getDerivedStateFromProps(props, state) {
    if (props.auth.user !== null && state.value === null) {
      return {
        value: props.auth.user.profile.holidaysDefaultTab,
      };
    } else {
      return null;
    }
  }

  handleChange = (event, newValue) => {
    this.setState({
      value: newValue
    });
    const profileViewBody = {
      holidaysDefaultTab: newValue
    };

    this.props.patchProfile(profileViewBody, this.props.auth.main_profile.pk);
  };

  render() {
    const { value } = this.state;
    const { employee, manager } = this.props.auth.user.profile;

    var subViewsConfig;
    if (employee === true && manager === false) {
      subViewsConfig = (
        <Fragment>
          <Employee />
        </Fragment>
      );
    } else if (employee === true && manager === true) {
      subViewsConfig = (
        <Fragment>
          <Tabs value={value} onChange={this.handleChange} aria-label="simple tabs example">
            <Tab label="Moje" />
            <Tab label="Pracownicy" />
          </Tabs>
          <TabPanel value={value} index={0}>
            <Employee />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <HolidaysManagerViewer />
          </TabPanel>
        </Fragment>
      );
    }

    return (
      <div>
        {subViewsConfig}
      </div>
    )
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { patchProfile })(HolidaysViewer);
