import React, { Fragment } from 'react';
import Grid from "@material-ui/core/Grid";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import { doc } from '../../PDFGen/DejaVuSansCondensed'
import { generate } from './DochodyDocument';

import Dialog from "@material-ui/core/Dialog";
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from "@material-ui/core/TextField";

import { sendCode, verifyCode, clearCode } from "../../Redux/actions/verifycodes"
import { addApplication, getLastIncome, getClausule } from '../../Redux/actions/zfss';

import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Link from '@material-ui/core/Link';

class MainDochodyView extends React.Component {
  state = {
    fin: undefined,
    openStep1: false,
    openStep2: false,
    code: '',
    pesel: '',
    peselValid: null,
    peselError: false,
    gospCount: undefined,
    incData: null,
    info: false,
    infoVisited: false,
    infoError: false,
  };

  static propTypes = {
    auth: PropTypes.object.isRequired,
    codeValid: PropTypes.any.isRequired,
    sendCode: PropTypes.func.isRequired,
    clearCode: PropTypes.func.isRequired,
    verifyCode: PropTypes.func.isRequired,
    lastIncome: PropTypes.any.isRequired,
    addApplication: PropTypes.func.isRequired,
    getLastIncome: PropTypes.func.isRequired,
    getClausule: PropTypes.func.isRequired,
    clausule: PropTypes.any.isRequired,
  };

  componentWillMount() {
    this.props.getClausule();
  }

  componentDidUpdate() {
    if (this.props.codeValid) {
      this.handleSign();
      this.props.clearCode();
      this.handleCloseStep2();
    }
    if (this.state.peselValid) {
      this.handleOpenStep2();
      this.handleCloseStep1();
    }
    if (this.props.lastIncome[0] != undefined) {
      if (this.state.gospCount == null) {
        this.setState({
          gospCount: this.props.lastIncome[0].householdCount,
        })
      }
      if (this.state.fin == null) {
        this.setState({
          fin: this.props.lastIncome[0].amount,
        })
      }
    }
  }

  handleSign = (event) => {
    const { gospCount, fin } = this.state;
    const user = this.props.auth.user;
    var firstName = user.first_name;
    var lastName = user.last_name;

    const data = {
      id: user.id,
      firstName,
      lastName,
      fin,
      gospCount,
      year: "2023", //ZMIENIAĆ CO ROKU
      date: new Date(),
    }

    const number = 'INC/' + data.year + "/" + data.id;

    const app = {
      owner: data.id,
      type: 1, //INC
      status: 1, //Utworzony
      number,
      date: "2023-01-01T00:00:00Z",
      //INCOME
      year: data.year,
      validTo: "2025-05-01T00:00:00Z", //ZMIENIAĆ CO ROKU
      amount: data.fin,
      householdCount: data.gospCount,
    }

    generate(doc, data);
    this.props.addApplication(app);
  }

  handleOpenStep1 = (eventObj) => {
    this.setState({
      openStep1: true,
    })
  }

  handleCloseStep1 = () => {
    this.setState({
      openStep1: false,
      pesel: '',
      peselValid: null,
      peselError: false,
    })
  }

  handleOpenStep2 = (eventObj) => {
    this.handleNewCode();
    this.setState({
      openStep2: true,
    })
  }

  handleCloseStep2 = () => {
    this.setState({
      openStep2: false,
      code: '',
    })
  }

  handleNewCode = () => {
    const body = {
      user: this.props.auth.user.id,
      recipient: this.props.auth.user.email,
    }

    this.props.sendCode(body);
  }

  handleCheckPesel = () => {
    if (this.state.pesel == this.props.auth.main_profile.pesel) {
      this.setState({
        peselValid: true,
        peselError: false,
      })
    }
    else {
      this.setState({
        peselValid: false,
        peselError: true,
      })
    }
  }

  handleCheckCode = () => {
    const body = {
      user: this.props.auth.user.id,
      code: this.state.code,
    }
    this.props.verifyCode(body);
  }

  handleRadioGroup = (event, value) => {
    this.setState({
      fin: value,
    })
  }

  handleOpenInfo = (event) => {
    if (this.props.clausule.length != 0) {
      window.open(this.props.clausule[0].file, '_blank').focus()
      this.setState({
        infoVisited: true,
        infoError: false,
      })
    }
  }

  handleChecked = (event, value) => {
    if (this.state.infoVisited) {
      this.setState({
        info: value,
      })
    }
    else {
      this.setState({
        infoError: true,
      })
    }
  }

  handleTextList = name => ({ target: { value } }) => {
    if (name == 'fin') {
      if (value < 0) value = 0;
    }
    if (name == 'gospCount') {
      if (value > 50) value = 50;
      else if (value < 1) value = 1;
    }
    this.setState({
      ...this.state,
      [name]: value,
    });
  };

  handleKeyPress = (event) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    if (!/^\d+$/.test(keyValue))
      event.preventDefault();
  };

  preventDefault = (event) => event.preventDefault();

  render() {
    const { fin, openStep1, openStep2, code, pesel, peselError, gospCount, info, infoError } = this.state;

    var infoCheck = info;
    var valid = false;
    if (this.props.lastIncome[0] != undefined) {
      if (Date.parse(this.props.lastIncome[0].validTo) > Date.now()) {
        valid = true;
        infoCheck = true;
      }
    }
    if (this.props.placed) {
      valid = true;
      infoCheck = true;
    }

    const buttonEn = (<Button onClick={this.handleOpenStep1} color="primary" variant="contained">Wygeneruj i podpisz</Button>);
    const buttonEnDis = (<Button color="primary" variant="contained" disabled>Wygeneruj i podpisz</Button>);
    const buttonDis = (<Button disabled>Złożyłeś już ważne oświadczenie</Button>);
    const infoErrorText = (<FormHelperText>Należy zapoznać się z klauzulą informacyjną o przetwarzaniu danych osobowych</FormHelperText>)

    const diaolgPesel = (
      <Dialog open={openStep1} onClose={this.handleCloseStep1}>
        <DialogTitle>Autoryzacja wniosku: Krok 1 - PESEL</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Podaj swój numer PESEL
          </DialogContentText>
          <TextField
            margin="dense"
            id="pesel"
            label="Wprowadź PESEL"
            type="text"
            fullWidth
            variant="standard"
            value={pesel}
            onChange={this.handleTextList("pesel")}
            onKeyPress={this.handleKeyPress}
            error={peselError}
            helperText={peselError ? "Nieprawidłowy numer PESEL" : ""}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleCloseStep1}>Anuluj</Button>
          <Button onClick={this.handleCheckPesel}>Dalej</Button>
        </DialogActions>
      </Dialog>
    )

    const contact_string = 'mailto:it@radioline.com.pl?subject=Uzupełnienie danych - ' + this.props.auth.user.first_name + ' ' + this.props.auth.user.last_name;

    const diaolgNoPesel = (
      <Dialog open={openStep1} onClose={this.handleCloseStep1}>
        <DialogTitle>Błąd - brak wymaganych danych w systemie</DialogTitle>
        <DialogContent>
          <DialogContentText>
            W celu uzupełnienia danych skontaktuj się z działem IT.
          </DialogContentText>
          <DialogContentText>
            Kontakt: <a href={contact_string}>it@radioline.com.pl</a>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleCloseStep1}>Zamknij</Button>
        </DialogActions>
      </Dialog>
    )

    return (
      <Fragment>
        {this.props.auth.main_profile.pesel != null ? diaolgPesel : diaolgNoPesel}

        <Dialog open={openStep2} onClose={this.handleCloseStep2}>
          <DialogTitle>Autoryzacja wniosku: Krok 2 - Kod E-Mail</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Na twój adres e-mail został wysłany 6-cyfrowy kod autoryzacyjny.
            </DialogContentText>
            <TextField
              margin="dense"
              id="code"
              label="Wprowadź kod"
              type="text"
              fullWidth
              variant="standard"
              value={code}
              onChange={this.handleTextList("code")}
              onKeyPress={this.handleKeyPress}
            />
            <DialogContentText></DialogContentText>
            <DialogContentText>
              <Button onClick={this.handleNewCode}>Wyślij nowy kod</Button>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleCloseStep2}>Anuluj</Button>
            <Button onClick={this.handleCheckCode}>Podpisz</Button>
          </DialogActions>
        </Dialog>

        <Grid container justify="center" style={{ width: "100%", padding: "1% 10%" }}>
          <Grid item xs={12} sm={12} md={12} lg={10} xl={8} style={{ justifyItems: "center" }}>
            <Box textAlign="center" mb={2}>
              <Typography variant="h5">Oświadczenie</Typography>
              <Typography variant="h6">
                w sprawie przecietnego miesięcznego dochodu brutto przypadającego na jedną osobę w gospodarstwie domowym w roku kalendarzowym 2023, składane w celu ubiegania się o świadczenia z Zakładowego Funduszu Świadczeń Socjalnych w Radio Line Sp. z o.o.
              </Typography>
            </Box>
            {/* ZMIENIAĆ CO ROKU wyżej i niżej */}
            <Typography variant="body1" align="left">
              Oświadczam, że:
            </Typography>
            <Grid container alignItems="center" spacing={2}>
              <Grid item>
                <Typography variant="body1" align="left">
                  1. mój dochód brutto* w roku kalendarzowym 2023 na 1 osobę w gospodarstwie domowym wynosi:
                </Typography>
              </Grid>
              <Grid item>
                <TextField
                  margin="dense"
                  id="fin"
                  type="number"
                  placeholder="0"
                  variant="standard"
                  value={fin}
                  disabled={valid}
                  InputProps={{ inputProps: { min: 0 } }}
                  onChange={this.handleTextList("fin")}
                  onKeyPress={this.handleKeyPress}
                />
              </Grid>
              <Grid item>
                <Typography variant="body1" align="left">
                  zł
                </Typography>
              </Grid>
            </Grid>
            <br />
            <Typography variant="body1" align="left">

            </Typography>
            <Grid container alignItems="center" spacing={2}>
              <Grid item>
                <Typography variant="body1" align="left">
                  2. liczba osób w moim gospodarstwie domowym (wspólnie zamiekszujących i wspólnie się utrzymujących) wynosi:
                </Typography>
              </Grid>
              <Grid item>
                <TextField
                  margin="dense"
                  id="gospCount"
                  type="number"
                  placeholder="0"
                  variant="standard"
                  value={gospCount}
                  disabled={valid}
                  onChange={this.handleTextList("gospCount")}
                  InputProps={{ inputProps: { min: 1, max: 50 } }}
                  onKeyPress={this.handleKeyPress}
                />
              </Grid>
            </Grid>
            <br />
            <Typography variant="body1" align="left">
              3. w przypadku złóżenia nieprawdziwego oświadczenia zobowiązuję się do zwrotu bezpodstawnie uzyskanych świadczeń w terminie 1 miesiąca od dnia wezwania przez Pracodawcę.
              Przyjmuję także do wiadomości, iż w takim przypadku tracę uprawnienie do ubiegania się o świadczenia z ZFŚS przez okres kolejnych 12 miesięcy kalendarzowych.
            </Typography>

            <br />
            <Typography variant="caption" color="textSecondary">
              Potwierdzam, że oświadczenie jest dokumentem w rozumieniu art. 245 kodeksu postępowania cywilnego, a prawdziwość danych
              w nim zawartych została potwierdzona podpisem i może być weryfikowana w trybie i na warunkach określonych w art. 253 k.p.c.
            </Typography>
            <br /><br />
            <Typography variant="caption" color="textSecondary">
              <span style={{ fontWeight: 'bold' }}>*Dochód brutto </span>
              oznacza łączny przychód (za rok poprzedzający rok, w którym składana jest informacja), który jest pomniejszony
              o koszty uzyskania przychodu, każdego członka rodziny prowadzącego wspólne gospodarstwo domowe i uprawnionego do korzystania z pomocy funduszu.
              <span style={{ fontWeight: 'bold' }}>Łączny dochód brutto w rodzinie /12 miesięcy /ilość członków rodziny = średni miesięczny dochód brutto.</span>
            </Typography>
            <br />
            <Typography variant="caption" color="textSecondary">
              Do dochodu wlicza się wszystkie uzyskane dochody osób zamieszkujących oraz utrzymujących się wspólnie (prowadzących
              wspólne gospodarstwo domowe), osiągnięte w roku poprzedzającym złożenie wniosku. <span style={{ fontWeight: 'bold' }}>UWAGA: </span>
              Dochód brutto należy ustalić na podstawie zeznania podatkowego PIT za rok poprzedni. Należy uwzględnić również dochody, które zostały
              rozliczone z fiskusem na zasadzie stałej wysokości ryczałtu oraz karty podatkowej. W przypadku osób osiągających dochody z tytułu pracy
              na gospodarstwie rolnym a nie zobowiązanych do tego, by wykazywać przychód do opodatkowania PIT bierze się pod uwagę
              wysokość dochodu z hektara przeliczeniowego.
            </Typography>
            <br /><br />

            <FormControl error={infoError} component="fieldset">
              <FormControlLabel
                control={
                  <Checkbox
                    name="info"
                    disabled={valid}
                    onChange={this.handleChecked}
                    checked={infoCheck}
                    color="primary"
                  />
                }
                label={
                  <Typography variant="body1" align="left">
                    Zapoznałem się i akceptuję treść
                    <Link href="#" style={{ margin: "0px 5px" }} onClick={this.handleOpenInfo}>
                      klauzuli informacyjnej
                    </Link>
                    o przetwarzaniu danych osobowych
                  </Typography>
                }
              />
              {infoError ? infoErrorText : ""}
            </FormControl><br />
            {valid ? buttonDis : (fin != null && gospCount != null && info ? buttonEn : buttonEnDis)}
          </Grid>
        </Grid>
      </Fragment >
    )
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
  codeValid: state.verifycodes.valid,
  lastIncome: state.zfss.last,
  placed: state.zfss.placed,
  clausule: state.zfss.clausule,
});

export default connect(
  mapStateToProps,
  { sendCode, verifyCode, clearCode, addApplication, getLastIncome, getClausule }
)(MainDochodyView);
