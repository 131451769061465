import axios from "axios";
import { createMessages, returnErrors } from "./messages";
import { tokenConfig } from "./auth";
import { serverurl, getDatabaseUrl } from "./databaseServerURL";

import {
  GET_EMPLOYEE_ASSETS_AL,
  GET_EMPLOYEE_ASSETS_NA,
  GET_EMPLOYEE_ASSETS_EL,
  GET_EMPLOYEE_ASSETS_IT,
  GET_EMPLOYEE_ASSETS_PO,
  ADD_ASSET_AL,
  ADD_ASSET_NA,
  ADD_ASSET_EL,
  ADD_ASSET_IT,
  ADD_ASSET_PO,
  GET_ASSET_DETAILS,
  GET_EMPLOYEE_ASSETS_BYSTATUS_AC,
  GET_EMPLOYEE_ASSETS_BYSTATUS_TR,
  GET_EMPLOYEE_ASSETS_BYSTATUS_SR,
  GET_EMPLOYEE_ASSETS_BYSTATUS_WR,
  GET_EMPLOYEE_ASSETS_BYNAME,
  PATCH_EMPLOYEE_ASSET,
  ASSET_RS_AL,
  ASSET_RS_NA,
  ASSET_RS_EL,
  ASSET_RS_IT,
  ASSET_RS_PO,
  GET_MY_ASSETS_AL,
  GET_MY_ASSETS_NA,
  GET_MY_ASSETS_EL,
  GET_MY_ASSETS_IT,
  GET_MY_ASSETS_PO,
  GET_WAR_ASSETS_AL,
  GET_WAR_ASSETS_NA,
  GET_WAR_ASSETS_EL,
  GET_WAR_ASSETS_IT,
  GET_WAR_ASSETS_PO,
  GET_MY_ASSETS_BYSTATUS_SR,
  GET_MY_ASSETS_BYSTATUS_WR,
  GET_MY_ASSETS_BYSTATUS_SD,
  GET_MY_ASSETS_DETAILS,
  A_QUERY_IN_PROGRESS,
  A_QUERY_MY_SR_IN_PROGRESS,
  A_QUERY_MY_WR_IN_PROGRESS,
  A_QUERY_MY_SD_IN_PROGRESS,
  A_QUERY_FILTEREDAC_IN_PROGRESS,
  A_QUERY_FILTEREDTR_IN_PROGRESS,
  A_QUERY_FILTEREDFI_IN_PROGRESS,
  A_QUERY_FILTEREDSR_IN_PROGRESS,
  A_QUERY_FILTEREDWR_IN_PROGRESS,
  A_QUERY_FAILED,
  A_QUERY_AL_IN_PROGRESS,
  A_QUERY_EL_IN_PROGRESS,
  A_QUERY_IT_IN_PROGRESS,
  A_QUERY_NA_IN_PROGRESS,
  A_QUERY_PO_IN_PROGRESS,
  CLEAR_EMPLOYEE_ASSETS_STATE,
  CLEAR_EMPLOYEE_ASSETS_BYSTATUS,
  PATCH_MY_ASSET_AL,
  PATCH_MY_ASSET_NA,
  PATCH_MY_ASSET_EL,
  PATCH_MY_ASSET_IT,
  PATCH_MY_ASSET_PO,
  PATCH_MY_ASSET_AL_RS,
  PATCH_MY_ASSET_NA_RS,
  PATCH_MY_ASSET_EL_RS,
  PATCH_MY_ASSET_IT_RS,
  PATCH_MY_ASSET_PO_RS,
  ADD_ASSET_TRANSFER_LOG,
  CLEAR_CUR_EQ_TR_LOG,
  PATCH_ASSET_TRANSFER_LOG,
  GET_EQMODELS_LIST,
  GET_VENDORS_LIST,
  CLEAR_EQMODELS_LIST,
  ADD_ASSET_MODEL,
  ADD_VENDOR,
  CLEAR_VENDORS_LIST,
  //SCRAP
  SCRAP_MY_ASSET_AL_REQ,
  SCRAP_MY_ASSET_NA_REQ,
  SCRAP_MY_ASSET_EL_REQ,
  SCRAP_MY_ASSET_IT_REQ,
  SCRAP_MY_ASSET_PO_REQ,
  SCRAP_MY_ASSET_AL_CAN,
  SCRAP_MY_ASSET_NA_CAN,
  SCRAP_MY_ASSET_EL_CAN,
  SCRAP_MY_ASSET_IT_CAN,
  SCRAP_MY_ASSET_PO_CAN,
  SCRAP_MY_ASSET_AL_UPD,
  SCRAP_MY_ASSET_NA_UPD,
  SCRAP_MY_ASSET_EL_UPD,
  SCRAP_MY_ASSET_IT_UPD,
  SCRAP_MY_ASSET_PO_UPD,
  SCRAP_EMP_ASSET_SD,
  SCRAP_EMP_ASSET_WR,
  //INVENTORY
  START_INVENTORY,
  STOP_INVENTORY,
  CLEAR_INVENTORY,
  GET_ACTIVE_INVENTORY,
  GET_INVENTORY,
  GET_MY_INV_AL,
  GET_MY_INV_NA,
  GET_MY_INV_EL,
  GET_MY_INV_IT,
  GET_MY_INV_PO,
  INV_QUERY_AL_IN_PROGRESS,
  INV_QUERY_NA_IN_PROGRESS,
  INV_QUERY_EL_IN_PROGRESS,
  INV_QUERY_IT_IN_PROGRESS,
  INV_QUERY_PO_IN_PROGRESS,
  GET_MY_INV_DETAILS,
  UPDATE_MY_INV_DETAILS,
  UPDATE_EMP_INV_DETAILS,
  DELETE_MY_INV_DETAILS,
  ADD_MY_INV_DETAILS,
  REPORT_INVENTORY,
  GET_INVENTORY_IP,
  GET_INVENTORY_RE,
  GET_INVENTORY_BH,
  GET_INVENTORY_MA,
  GET_INVENTORY_FI,
  GET_EMPLOYEE_INV_ASSETS_AL,
  GET_EMPLOYEE_INV_ASSETS_NA,
  GET_EMPLOYEE_INV_ASSETS_EL,
  GET_EMPLOYEE_INV_ASSETS_IT,
  GET_EMPLOYEE_INV_ASSETS_PO,
  GET_INVENTORY_ASSETS,
  GET_INVENTORY_DETAILS,
  CLEAR_INVENTORY_ASSETS,
  RAPORT_QUERY_IN_PROGRESS,
  RAPORT_QUERY_IN_PROGRESS2,
  CLEAR_INVENTORY_EMP_ASSETS,
  CLEAR_EMP_INVENTORIES,
  CLEAR_ASSETS_DETAILS
} from "./types";

//ADD VENDOR
export const addVendor = (body) => (dispatch, getState) => {
  axios
    .post(serverurl + getDatabaseUrl() + "/vendor/", body, tokenConfig(getState))
    .then((res) => {
      dispatch(
        createMessages({
          addVendor: "Dodano nowego producenta",
          type: "success",
        })
      );
      dispatch({
        type: ADD_VENDOR,
        payload: res.data,
      });
    })
    .catch((err) =>
      dispatch(
        returnErrors(err.response.data.detail, err.response.status, "error")
      )
    );
};

//ADD ASSET MODEL
export const addAssetModel = (body) => (dispatch, getState) => {
  axios
    .post(serverurl + getDatabaseUrl() + "/equipmentmodel/", body, tokenConfig(getState))
    .then((res) => {
      dispatch(
        createMessages({
          addAssetModel: "Dodano nowy model środka trwałego",
          type: "success",
        })
      );
      dispatch({
        type: ADD_ASSET_MODEL,
        payload: res.data,
      });
    })
    .catch((err) =>
      dispatch(
        returnErrors(err.response.data.detail, err.response.status, "error")
      )
    );
};

//START INVENOTRY
export const startInventory = (body) => (dispatch, getState) => {
  axios
    .post(serverurl + getDatabaseUrl() + "/inventory/", body, tokenConfig(getState))
    .then((res) => {
      dispatch(
        createMessages({
          startInventory: "Rozpoczęto inwentaryzację pracownika",
          type: "success",
        })
      );
      dispatch({
        type: START_INVENTORY,
        payload: res.data,
      });
    })
    .catch((err) =>
      dispatch(
        returnErrors(err.response.data.detail, err.response.status, "error")
      )
    );
};

//Report INVENTORY
export const reportInventory = (pk, body) => (dispatch, getState) => {
  axios
    .patch(serverurl + getDatabaseUrl() + `/inventory/${pk}`, body, tokenConfig(getState))
    .then((res) => {
      dispatch(
        createMessages({
          stopInventory: "Zmieniono status inwentaryzacji",
          type: "success",
        })
      );
      dispatch({
        type: REPORT_INVENTORY,
        payload: res.data,
      });
    })
    .catch((err) =>
      dispatch(
        returnErrors(err.response.data.detail, err.response.status, "error")
      )
    );
};

//STOP INVENTORY
export const stopInventory = (pk, body) => (dispatch, getState) => {
  axios
    .patch(serverurl + getDatabaseUrl() + `/inventory/${pk}`, body, tokenConfig(getState))
    .then((res) => {
      dispatch(
        createMessages({
          stopInventory: "Zakończono inwentaryzację pracownika",
          type: "success",
        })
      );
      dispatch({
        type: STOP_INVENTORY,
        payload: res.data,
      });
    })
    .catch((err) =>
      dispatch(
        returnErrors(err.response.data.detail, err.response.status, "error")
      )
    );
};

//GET ACTIVE INVENTORY
export const getActiveInventory = (empId) => (dispatch, getState) => {
  axios
    .get(serverurl + getDatabaseUrl() + `/activeinventory/?empId=${empId}&db=${getDatabaseUrl().substring(1)}`, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: GET_ACTIVE_INVENTORY,
        payload: res.data,
      });
    })
    .catch((err) =>
      dispatch(
        returnErrors(err.response.data.detail, err.response.status, "error")
      )
    );
};

//GET INVENTORY
export const getInventory = (body) => (dispatch, getState) => {
  axios
    .get(serverurl + getDatabaseUrl() + "/inventory/", body, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: GET_INVENTORY,
        payload: res.data,
      });
    })
    .catch((err) =>
      dispatch(
        returnErrors(err.response.data.detail, err.response.status, "error")
      )
    );
};

//GET INVENTORY
export const getInventoryByStatus = (status) => (dispatch, getState) => {
  axios
    .get(serverurl + getDatabaseUrl() + `/inventory/?status=${status}`, tokenConfig(getState))
    .then((res) => {
      if (status == 'ip') {
        dispatch({
          type: GET_INVENTORY_IP,
          payload: res.data,
        });
      }
      if (status == 're') {
        dispatch({
          type: GET_INVENTORY_RE,
          payload: res.data,
        });
      }
      if (status == 'bh') {
        dispatch({
          type: GET_INVENTORY_BH,
          payload: res.data,
        });
      }
      if (status == 'ma') {
        dispatch({
          type: GET_INVENTORY_MA,
          payload: res.data,
        });
      }
      if (status == 'fi') {
        dispatch({
          type: GET_INVENTORY_FI,
          payload: res.data,
        });
      }
    })
    .catch((err) =>
      dispatch(
        returnErrors(err.response.data.detail, err.response.status, "error")
      )
    );
};

//GET INVENTORY
export const getMyInventories = (pk) => (dispatch, getState) => {
  dispatch({ type: A_QUERY_IN_PROGRESS });
  axios
    .get(serverurl + getDatabaseUrl() + `/inventory/?user=${pk}`, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: GET_INVENTORY,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({ type: A_QUERY_FAILED });
      dispatch(
        returnErrors(err.response.data.detail, err.response.status, "error")
      );
    });
};

export const clearInventory = () => (dispatch, getState) => {
  dispatch({ type: CLEAR_INVENTORY });
}

//ADD ASSET
export const addAsset = (body, eqtype) => (dispatch, getState) => {
  axios
    .post(serverurl + getDatabaseUrl() + "/equipment/", body, tokenConfig(getState))
    .then((res) => {
      dispatch(
        createMessages({
          addAsset: "Środek Trwały został dodany",
          type: "success",
        })
      );
      if (eqtype === "al") {
        dispatch({
          type: ADD_ASSET_AL,
          payload: res.data,
        });
      } else if (eqtype === "na") {
        dispatch({
          type: ADD_ASSET_NA,
          payload: res.data,
        });
      } else if (eqtype === "el") {
        dispatch({
          type: ADD_ASSET_EL,
          payload: res.data,
        });
      } else if (eqtype === "it") {
        dispatch({
          type: ADD_ASSET_IT,
          payload: res.data,
        });
      } else if (eqtype === "po") {
        dispatch({
          type: ADD_ASSET_PO,
          payload: res.data,
        });
      }
    })
    .catch((err) =>
      dispatch(
        returnErrors(err.response.data.detail, err.response.status, "error")
      )
    );
};

//PATCH ASSET TRANSFER LOG
export const patchAssetTransferLog = (pk, asset) => (dispatch, getState) => {
  axios
    .patch(
      serverurl + getDatabaseUrl() + `/equipmenttransferlog/${pk}`,
      asset,
      tokenConfig(getState)
    )
    .then((res) => {
      dispatch(
        createMessages({
          patchAssetTransferLog: "Zaktualizowano historię środka trwałego...",
          type: "info",
        })
      );
      dispatch({
        type: PATCH_ASSET_TRANSFER_LOG,
        payload: res.data,
      });
    })
    .catch((err) =>
      dispatch(
        returnErrors(err.response.data.detail, err.response.status, "error")
      )
    );
};

//wyczyść flagę currentEquipmentTransferLog w store
export const clearCurrentEquipmentTransferLog = () => (dispatch, getState) => {
  dispatch({ type: CLEAR_CUR_EQ_TR_LOG });
};

//ADD ASSET TRANSFER LOG
export const addAssetTransferLog = (asset) => (dispatch, getState) => {
  axios
    .post(serverurl + getDatabaseUrl() + "/equipmenttransferlog/", asset, tokenConfig(getState))
    .then((res) => {
      dispatch(
        createMessages({
          addAssetTransferLog: "Zaktualizowano historię środka trwałego...",
          type: "success",
        })
      );
      dispatch({
        type: ADD_ASSET_TRANSFER_LOG,
        payload: res.data,
      });
    })
    .catch((err) =>
      dispatch(
        returnErrors(err.response.data.detail, err.response.status, "error")
      )
    );
};

//PATCH MY ASSET
export const patchMyAsset = (pk, eqtype, asset, removefromstore) => (
  dispatch,
  getState
) => {
  axios
    .patch(serverurl + getDatabaseUrl() + `/equipment/${pk}`, asset, tokenConfig(getState))
    .then((res) => {
      dispatch(
        createMessages({
          patchMyAsset: "Zaktualizowano dane o środku trwałym",
          type: "info",
        })
      );
      if (removefromstore === false) {
        if (eqtype === "al") {
          dispatch({
            type: PATCH_MY_ASSET_AL,
            payload: res.data,
          });
        } else if (eqtype === "na") {
          dispatch({
            type: PATCH_MY_ASSET_NA,
            payload: res.data,
          });
        } else if (eqtype === "el") {
          dispatch({
            type: PATCH_MY_ASSET_EL,
            payload: res.data,
          });
        } else if (eqtype === "it") {
          dispatch({
            type: PATCH_MY_ASSET_IT,
            payload: res.data,
          });
        } else if (eqtype === "po") {
          dispatch({
            type: PATCH_MY_ASSET_PO,
            payload: res.data,
          });
        }
      } else {
        if (eqtype === "al") {
          dispatch({
            type: PATCH_MY_ASSET_AL_RS,
            payload: res.data,
          });
        } else if (eqtype === "na") {
          dispatch({
            type: PATCH_MY_ASSET_NA_RS,
            payload: res.data,
          });
        } else if (eqtype === "el") {
          dispatch({
            type: PATCH_MY_ASSET_EL_RS,
            payload: res.data,
          });
        } else if (eqtype === "it") {
          dispatch({
            type: PATCH_MY_ASSET_IT_RS,
            payload: res.data,
          });
        } else if (eqtype === "po") {
          dispatch({
            type: PATCH_MY_ASSET_PO_RS,
            payload: res.data,
          });
        }
      }
    })
    .catch((err) =>
      dispatch(
        returnErrors(err.response.data.detail, err.response.status, "error")
      )
    );
};

//PATCH EMPLOYEE ASSET
export const patchEmployeeAsset = (pk, asset) => (dispatch, getState) => {
  axios
    .patch(serverurl + getDatabaseUrl() + `/equipment/${pk}`, asset, tokenConfig(getState))
    .then((res) => {
      dispatch(
        createMessages({
          patchEmployeeAsset: "Zaktualizowano dane o środku trwałym",
          type: "info",
        })
      );
      dispatch({
        type: PATCH_EMPLOYEE_ASSET,
        payload: res.data,
      });
    })
    .catch((err) =>
      dispatch(
        returnErrors(err.response.data.detail, err.response.status, "error")
      )
    );
};

//transfer Asset /for manager/warehouse admin
export const transferAsset_admin = (
  assetpk,
  assetbody,
  transferlogbody,
  ssmsbody,
  tsmsbody,
  eqtype,
  mode
) => (dispatch, getState) => {
  if (mode === "u2u") {
    const request1 = axios.patch(
      serverurl + getDatabaseUrl() + `/equipment/${assetpk}`,
      assetbody,
      tokenConfig(getState)
    );
    const request2 = axios.post(
      serverurl + getDatabaseUrl() + "/equipmenttransferlog/",
      transferlogbody,
      tokenConfig(getState)
    );

    /*
    const request3 = axios.post(
      serverurl + "/notificationsms/",
      ssmsbody,
      tokenConfig(getState)
    );
    const request4 = axios.post(
      serverurl + "/notificationsms/",
      tsmsbody,
      tokenConfig(getState)
    );
    */

    const request3 = axios.post(
      serverurl + getDatabaseUrl() + "/send_notify",
      ssmsbody,
      tokenConfig(getState)
    );
    const request4 = axios.post(
      serverurl + getDatabaseUrl() + "/send_notify",
      tsmsbody,
      tokenConfig(getState)
    );

    axios
      .all([request1, request2, request3, request4])
      //.all([request1, request2])
      .then(
        axios.spread((...responses) => {
          const response1 = responses[0];
          //const response2 = responses[1];
          //const response3 = responses[2];
          //const response4 = responses[3];

          if (eqtype === "al") {
            dispatch({
              type: ASSET_RS_AL,
              payload: response1.data,
              mode: "u2u",
            });
          } else if (eqtype === "na") {
            dispatch({
              type: ASSET_RS_NA,
              payload: response1.data,
              mode: "u2u",
            });
          } else if (eqtype === "el") {
            dispatch({
              type: ASSET_RS_EL,
              payload: response1.data,
              mode: "u2u",
            });
          } else if (eqtype === "it") {
            dispatch({
              type: ASSET_RS_IT,
              payload: response1.data,
              mode: "u2u",
            });
          } else if (eqtype === "po") {
            dispatch({
              type: ASSET_RS_PO,
              payload: response1.data,
              mode: "u2u",
            });
          }

          dispatch(
            createMessages({
              patchEmployeeAsset: "Zaktualizowano dane o środku trwałym",
              type: "info",
            })
          );

          // use/access the results
          //console.log(response1, response2 /*, response3, response4*/);
        })
      )
      .catch((err) => {
        // react on errors.
        dispatch(
          returnErrors(err.response.data.detail, err.response.status, "error")
        );
      });
  } else if (mode === "w2u") {
    const request1 = axios.patch(
      serverurl + getDatabaseUrl() + `/equipment/${assetpk}`,
      assetbody,
      tokenConfig(getState)
    );
    const request2 = axios.post(
      serverurl + getDatabaseUrl() + "/equipmenttransferlog/",
      transferlogbody,
      tokenConfig(getState)
    );
    /*
    const request4 = axios.post(
      serverurl + "/notificationsms/",
      tsmsbody,
      tokenConfig(getState)
    );
    */
    const request4 = axios.post(
      serverurl + getDatabaseUrl() + "/send_notify",
      tsmsbody,
      tokenConfig(getState)
    );

    axios
      .all([request1, request2, request4])
      .then(
        axios.spread((...responses) => {
          const response1 = responses[0];
          //const response2 = responses[1];
          //const response4 = responses[2];

          if (eqtype === "al") {
            dispatch({
              type: ASSET_RS_AL,
              payload: response1.data,
              mode: "w2u",
            });
          } else if (eqtype === "na") {
            dispatch({
              type: ASSET_RS_NA,
              payload: response1.data,
              mode: "w2u",
            });
          } else if (eqtype === "el") {
            dispatch({
              type: ASSET_RS_EL,
              payload: response1.data,
              mode: "w2u",
            });
          } else if (eqtype === "it") {
            dispatch({
              type: ASSET_RS_IT,
              payload: response1.data,
              mode: "w2u",
            });
          } else if (eqtype === "po") {
            dispatch({
              type: ASSET_RS_PO,
              payload: response1.data,
              mode: "w2u",
            });
          }

          dispatch(
            createMessages({
              patchEmployeeAsset: "Zaktualizowano dane o środku trwałym",
              type: "info",
            })
          );

          // use/access the results
          //console.log(response1, response2 /*, response3, response4*/);
        })
      )
      .catch((err) => {
        // react on errors.
        dispatch(
          returnErrors(err.response.data.detail, err.response.status, "error")
        );
      });
  } else if (mode === "u2w") {
    const request1 = axios.patch(
      serverurl + getDatabaseUrl() + `/equipment/${assetpk}`,
      assetbody,
      tokenConfig(getState)
    );
    const request2 = axios.post(
      serverurl + getDatabaseUrl() + "/equipmenttransferlog/",
      transferlogbody,
      tokenConfig(getState)
    );
    /*
    const request3 = axios.post(
      serverurl + "/notificationsms/",
      ssmsbody,
      tokenConfig(getState)
    );
    */
    const request3 = axios.post(
      serverurl + getDatabaseUrl() + "/send_notify",
      ssmsbody,
      tokenConfig(getState)
    );

    axios
      .all([request1, request2, request3])
      .then(
        axios.spread((...responses) => {
          const response1 = responses[0];
          //const response2 = responses[1];
          //const response3 = responses[2];

          if (eqtype === "al") {
            dispatch({
              type: ASSET_RS_AL,
              payload: response1.data,
              mode: "u2w",
            });
          } else if (eqtype === "na") {
            dispatch({
              type: ASSET_RS_NA,
              payload: response1.data,
              mode: "u2w",
            });
          } else if (eqtype === "el") {
            dispatch({
              type: ASSET_RS_EL,
              payload: response1.data,
              mode: "u2w",
            });
          } else if (eqtype === "it") {
            dispatch({
              type: ASSET_RS_IT,
              payload: response1.data,
              mode: "u2w",
            });
          } else if (eqtype === "po") {
            dispatch({
              type: ASSET_RS_PO,
              payload: response1.data,
              mode: "u2w",
            });
          }

          dispatch(
            createMessages({
              patchEmployeeAsset: "Zaktualizowano dane o środku trwałym",
              type: "info",
            })
          );

          // use/access the results
          //console.log(response1, response2 /*, response3, response4*/);
        })
      )
      .catch((err) => {
        // react on errors.
        dispatch(
          returnErrors(err.response.data.detail, err.response.status, "error")
        );
      });
  }
};

//GET_EMPLOYEE_ASSETS_AL/Na/El/It/Po
export const getEmployeeAssets = (owner, eqtype) => (dispatch, getState) => {
  if (eqtype === "al") {
    dispatch({ type: A_QUERY_AL_IN_PROGRESS });
    axios
      .get(
        serverurl + getDatabaseUrl() + `/equipment/?owner=${owner}&eqtype=${eqtype}`,
        tokenConfig(getState)
      )
      .then((res) => {
        dispatch({
          type: GET_EMPLOYEE_ASSETS_AL,
          payload: res.data,
        });
      })
      .catch((err) =>
        dispatch(
          returnErrors(err.response.data.detail, err.response.status, "error")
        )
      );
  } else if (eqtype === "na") {
    dispatch({ type: A_QUERY_NA_IN_PROGRESS });
    axios
      .get(
        serverurl + getDatabaseUrl() + `/equipment/?owner=${owner}&eqtype=${eqtype}`,
        tokenConfig(getState)
      )
      .then((res) => {
        dispatch({
          type: GET_EMPLOYEE_ASSETS_NA,
          payload: res.data,
        });
      })
      .catch((err) =>
        dispatch(
          returnErrors(err.response.data.detail, err.response.status, "error")
        )
      );
  } else if (eqtype === "el") {
    dispatch({ type: A_QUERY_EL_IN_PROGRESS });
    axios
      .get(
        serverurl + getDatabaseUrl() + `/equipment/?owner=${owner}&eqtype=${eqtype}`,
        tokenConfig(getState)
      )
      .then((res) => {
        dispatch({
          type: GET_EMPLOYEE_ASSETS_EL,
          payload: res.data,
        });
      })
      .catch((err) =>
        dispatch(
          returnErrors(err.response.data.detail, err.response.status, "error")
        )
      );
  } else if (eqtype === "it") {
    dispatch({ type: A_QUERY_IT_IN_PROGRESS });
    axios
      .get(
        serverurl + getDatabaseUrl() + `/equipment/?owner=${owner}&eqtype=${eqtype}`,
        tokenConfig(getState)
      )
      .then((res) => {
        dispatch({
          type: GET_EMPLOYEE_ASSETS_IT,
          payload: res.data,
        });
      })
      .catch((err) =>
        dispatch(
          returnErrors(err.response.data.detail, err.response.status, "error")
        )
      );
  } else if (eqtype === "po") {
    dispatch({ type: A_QUERY_PO_IN_PROGRESS });
    axios
      .get(
        serverurl + getDatabaseUrl() + `/equipment/?owner=${owner}&eqtype=${eqtype}`,
        tokenConfig(getState)
      )
      .then((res) => {
        dispatch({
          type: GET_EMPLOYEE_ASSETS_PO,
          payload: res.data,
        });
      })
      .catch((err) =>
        dispatch(
          returnErrors(err.response.data.detail, err.response.status, "error")
        )
      );
  }
};

//GET ASSET DETAILS
export const getAssetDetails = (pk) => (dispatch, getState) => {
  dispatch({ type: A_QUERY_IN_PROGRESS });
  axios
    .get(serverurl + getDatabaseUrl() + `/equipment/${pk}`, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: GET_ASSET_DETAILS,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch(
        returnErrors(err.response.data.detail, err.response.status, "error")
      );
      dispatch({ type: A_QUERY_FAILED });
    });
};

export const clearAssetDetails = () => (dispatch, getState) => {
  dispatch({ type: CLEAR_ASSETS_DETAILS });
}

//GET_MY_ASSETS_AL/Na/El/It/Po
export const getMyAssets = (owner, eqtype) => (dispatch, getState) => {
  if (eqtype === "al") {
    dispatch({ type: A_QUERY_AL_IN_PROGRESS });
    axios
      .get(
        serverurl + getDatabaseUrl() + `/equipment/?owner=${owner}&eqtype=${eqtype}`,
        tokenConfig(getState)
      )
      .then((res) => {
        dispatch({
          type: GET_MY_ASSETS_AL,
          payload: res.data,
        });
      })
      .catch((err) =>
        dispatch(
          returnErrors(err.response.data.detail, err.response.status, "error")
        )
      );
  } else if (eqtype === "na") {
    dispatch({ type: A_QUERY_NA_IN_PROGRESS });
    axios
      .get(
        serverurl + getDatabaseUrl() + `/equipment/?owner=${owner}&eqtype=${eqtype}`,
        tokenConfig(getState)
      )
      .then((res) => {
        dispatch({
          type: GET_MY_ASSETS_NA,
          payload: res.data,
        });
      })
      .catch((err) =>
        dispatch(
          returnErrors(err.response.data.detail, err.response.status, "error")
        )
      );
  } else if (eqtype === "el") {
    dispatch({ type: A_QUERY_EL_IN_PROGRESS });
    axios
      .get(
        serverurl + getDatabaseUrl() + `/equipment/?owner=${owner}&eqtype=${eqtype}`,
        tokenConfig(getState)
      )
      .then((res) => {
        dispatch({
          type: GET_MY_ASSETS_EL,
          payload: res.data,
        });
      })
      .catch((err) =>
        dispatch(
          returnErrors(err.response.data.detail, err.response.status, "error")
        )
      );
  } else if (eqtype === "it") {
    dispatch({ type: A_QUERY_IT_IN_PROGRESS });
    axios
      .get(
        serverurl + getDatabaseUrl() + `/equipment/?owner=${owner}&eqtype=${eqtype}`,
        tokenConfig(getState)
      )
      .then((res) => {
        dispatch({
          type: GET_MY_ASSETS_IT,
          payload: res.data,
        });
      })
      .catch((err) =>
        dispatch(
          returnErrors(err.response.data.detail, err.response.status, "error")
        )
      );
  } else if (eqtype === "po") {
    dispatch({ type: A_QUERY_PO_IN_PROGRESS });
    axios
      .get(
        serverurl + getDatabaseUrl() + `/equipment/?owner=${owner}&eqtype=${eqtype}`,
        tokenConfig(getState)
      )
      .then((res) => {
        dispatch({
          type: GET_MY_ASSETS_PO,
          payload: res.data,
        });
      })
      .catch((err) =>
        dispatch(
          returnErrors(err.response.data.detail, err.response.status, "error")
        )
      );
  }
};

//GET My ASSETS by status (SR/WR)
export const getMyAssetsByStatus = (owner, status) => (dispatch, getState) => {
  if (status === "sr") {
    dispatch({ type: A_QUERY_MY_SR_IN_PROGRESS });
    axios
      .get(
        serverurl + getDatabaseUrl() + `/equipment/?owner=${owner}&status=${status}`,
        tokenConfig(getState)
      )
      .then((res) => {
        dispatch({
          type: GET_MY_ASSETS_BYSTATUS_SR,
          payload: res.data,
        });
      })
      .catch((err) =>
        dispatch(
          returnErrors(err.response.data.detail, err.response.status, "error")
        )
      );
  } else if (status === "wr") {
    dispatch({ type: A_QUERY_MY_WR_IN_PROGRESS });
    axios
      .get(
        serverurl + getDatabaseUrl() + `/equipment/?owner=${owner}&status=${status}`,
        tokenConfig(getState)
      )
      .then((res) => {
        dispatch({
          type: GET_MY_ASSETS_BYSTATUS_WR,
          payload: res.data,
        });
      })
      .catch((err) =>
        dispatch(
          returnErrors(err.response.data.detail, err.response.status, "error")
        )
      );
  } else if (status === "sd") {
    dispatch({ type: A_QUERY_MY_SD_IN_PROGRESS });
    axios
      .get(
        serverurl + getDatabaseUrl() + `/equipment/?owner=${owner}&status=${status}`,
        tokenConfig(getState)
      )
      .then((res) => {
        dispatch({
          type: GET_MY_ASSETS_BYSTATUS_SD,
          payload: res.data,
        });
      })
      .catch((err) =>
        dispatch(
          returnErrors(err.response.data.detail, err.response.status, "error")
        )
      );
  }
};

//GET MY ASSET DETAILS
export const getMyAssetsDetails = (pk) => (dispatch, getState) => {
  dispatch({ type: A_QUERY_IN_PROGRESS });
  axios
    .get(serverurl + getDatabaseUrl() + `/equipment/${pk}`, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: GET_MY_ASSETS_DETAILS,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch(
        returnErrors(err.response.data.detail, err.response.status, "error")
      );
      dispatch({ type: A_QUERY_FAILED });
    });
};

//CLEAR EMPLOYEE ASSETS STATE
export const clearEmployeeAssetsState = () => (dispatch, getState) => {
  dispatch({ type: CLEAR_EMPLOYEE_ASSETS_STATE });
};

//GET ASSETS by status
export const getEmployeeAssetsByStatus = (status) => (dispatch, getState) => {
  if (status === "ac") {
    dispatch({ type: A_QUERY_FILTEREDAC_IN_PROGRESS });
    axios
      .get(serverurl + getDatabaseUrl() + `/equipment/?status=${status}`, tokenConfig(getState))
      .then((res) => {
        dispatch({
          type: GET_EMPLOYEE_ASSETS_BYSTATUS_AC,
          payload: res.data,
        });
      })
      .catch((err) =>
        dispatch(
          returnErrors(err.response.data.detail, err.response.status, "error")
        )
      );
  } else if (status === "tr") {
    dispatch({ type: A_QUERY_FILTEREDTR_IN_PROGRESS });
    axios
      .get(serverurl + getDatabaseUrl() + `/equipment/?status=${status}`, tokenConfig(getState))
      .then((res) => {
        dispatch({
          type: GET_EMPLOYEE_ASSETS_BYSTATUS_TR,
          payload: res.data,
        });
      })
      .catch((err) =>
        dispatch(
          returnErrors(err.response.data.detail, err.response.status, "error")
        )
      );
  } else if (status === "sr") {
    dispatch({ type: A_QUERY_FILTEREDSR_IN_PROGRESS });
    axios
      .get(serverurl + getDatabaseUrl() + `/equipment/?status=${status}`, tokenConfig(getState))
      .then((res) => {
        dispatch({
          type: GET_EMPLOYEE_ASSETS_BYSTATUS_SR,
          payload: res.data,
        });
      })
      .catch((err) =>
        dispatch(
          returnErrors(err.response.data.detail, err.response.status, "error")
        )
      );
  } else if (status === "wr") {
    dispatch({ type: A_QUERY_FILTEREDWR_IN_PROGRESS });
    axios
      .get(serverurl + getDatabaseUrl() + `/equipment/?status=${status}`, tokenConfig(getState))
      .then((res) => {
        dispatch({
          type: GET_EMPLOYEE_ASSETS_BYSTATUS_WR,
          payload: res.data,
        });
      })
      .catch((err) =>
        dispatch(
          returnErrors(err.response.data.detail, err.response.status, "error")
        )
      );
  }
};

//GET Employee ASSETS by name
export const getEmployeeAssetsByName = (name) => (dispatch, getState) => {
  dispatch({ type: A_QUERY_FILTEREDFI_IN_PROGRESS });
  axios
    .get(serverurl + getDatabaseUrl() + `/equipment/?name=${name}`, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: GET_EMPLOYEE_ASSETS_BYNAME,
        payload: res.data,
      });
    })
    .catch((err) =>
      dispatch(
        returnErrors(err.response.data.detail, err.response.status, "error")
      )
    );
};

//CLEAR EMPLOYEE ASSETS BY STATUS
export const clearEmployeeAssetsByStatus = () => (dispatch, getState) => {
  dispatch({ type: CLEAR_EMPLOYEE_ASSETS_BYSTATUS });
};

//GET EQUIPMENT MODELS LIST
export const getEquipmentModelsList = (modelpk) => (dispatch, getState) => {
  dispatch({ type: A_QUERY_IN_PROGRESS });
  axios
    .get(
      serverurl + getDatabaseUrl() + `/equipmentmodel/?vendor=${modelpk}`,
      tokenConfig(getState)
    )
    .then((res) => {
      dispatch({
        type: GET_EQMODELS_LIST,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch(
        returnErrors(err.response.data.detail, err.response.status, "error")
      );
      dispatch({ type: A_QUERY_FAILED });
    });
};

export const getEquipmentModelsListGrouped = () => (dispatch, getState) => {
  dispatch({ type: A_QUERY_IN_PROGRESS });
  axios
    .get(
      serverurl + getDatabaseUrl() + `/equipmentmodel/?short=true`,
      tokenConfig(getState)
    )
    .then((res) => {
      dispatch({
        type: GET_EQMODELS_LIST,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch(
        returnErrors(err.response.data.detail, err.response.status, "error")
      );
      dispatch({ type: A_QUERY_FAILED });
    });
};

//CLEAR Equipment Models list
export const clearEquipmentModelsList = () => (dispatch, getState) => {
  dispatch({ type: CLEAR_EQMODELS_LIST });
};

export const clearVendorsList = () => (dispatch, getState) => {
  dispatch({ type: CLEAR_VENDORS_LIST });
};

//GET VENDORS LIST
export const getVendorsList = () => (dispatch, getState) => {
  dispatch({ type: A_QUERY_IN_PROGRESS });
  axios
    .get(serverurl + getDatabaseUrl() + `/vendor/`, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: GET_VENDORS_LIST,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch(
        returnErrors(err.response.data.detail, err.response.status, "error")
      );
      dispatch({ type: A_QUERY_FAILED });
    });
};

export const getVendorsListFiltered = (vendors) => (dispatch, getState) => {
  dispatch({ type: A_QUERY_IN_PROGRESS });
  axios
    .get(serverurl + getDatabaseUrl() + `/vendor/?ids=${vendors}`, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: GET_VENDORS_LIST,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch(
        returnErrors(err.response.data.detail, err.response.status, "error")
      );
      dispatch({ type: A_QUERY_FAILED });
    });
};

//SCRAP MY ASSET
export const scrapMyAsset = (pk, eqtype, asset, action) => (
  dispatch,
  getState
) => {
  axios
    .patch(serverurl + getDatabaseUrl() + `/equipment/${pk}`, asset, tokenConfig(getState))
    .then((res) => {
      dispatch(
        createMessages({
          scrapMyAsset: "Zaktualizowano dane o środku trwałym",
          type: "info",
        })
      );
      if (action === "request") {
        if (eqtype === "al") {
          dispatch({
            type: SCRAP_MY_ASSET_AL_REQ,
            payload: res.data,
          });
        } else if (eqtype === "na") {
          dispatch({
            type: SCRAP_MY_ASSET_NA_REQ,
            payload: res.data,
          });
        } else if (eqtype === "el") {
          dispatch({
            type: SCRAP_MY_ASSET_EL_REQ,
            payload: res.data,
          });
        } else if (eqtype === "it") {
          dispatch({
            type: SCRAP_MY_ASSET_IT_REQ,
            payload: res.data,
          });
        } else if (eqtype === "po") {
          dispatch({
            type: SCRAP_MY_ASSET_PO_REQ,
            payload: res.data,
          });
        }
      } else if (action === "cancel") {
        if (eqtype === "al") {
          dispatch({
            type: SCRAP_MY_ASSET_AL_CAN,
            payload: res.data,
          });
        } else if (eqtype === "na") {
          dispatch({
            type: SCRAP_MY_ASSET_NA_CAN,
            payload: res.data,
          });
        } else if (eqtype === "el") {
          dispatch({
            type: SCRAP_MY_ASSET_EL_CAN,
            payload: res.data,
          });
        } else if (eqtype === "it") {
          dispatch({
            type: SCRAP_MY_ASSET_IT_CAN,
            payload: res.data,
          });
        } else if (eqtype === "po") {
          dispatch({
            type: SCRAP_MY_ASSET_PO_CAN,
            payload: res.data,
          });
        }
      }
    })
    .catch((err) =>
      dispatch(
        returnErrors(err.response.data.detail, err.response.status, "error")
      )
    );
};

//SCRAP MY ASSET UPDATE
export const scrapMyAssetUpdate = (pk, eqtype, asset) => (
  dispatch,
  getState
) => {
  axios
    .patch(serverurl + getDatabaseUrl() + `/equipment/${pk}`, asset, tokenConfig(getState))
    .then((res) => {
      dispatch(
        createMessages({
          scrapMyAssetUpdate: "Zaktualizowano dane o środku trwałym",
          type: "info",
        })
      );
      if (eqtype === "al") {
        dispatch({
          type: SCRAP_MY_ASSET_AL_UPD,
          payload: res.data,
        });
      } else if (eqtype === "na") {
        dispatch({
          type: SCRAP_MY_ASSET_NA_UPD,
          payload: res.data,
        });
      } else if (eqtype === "el") {
        dispatch({
          type: SCRAP_MY_ASSET_EL_UPD,
          payload: res.data,
        });
      } else if (eqtype === "it") {
        dispatch({
          type: SCRAP_MY_ASSET_IT_UPD,
          payload: res.data,
        });
      } else if (eqtype === "po") {
        dispatch({
          type: SCRAP_MY_ASSET_PO_UPD,
          payload: res.data,
        });
      }
    })
    .catch((err) =>
      dispatch(
        returnErrors(err.response.data.detail, err.response.status, "error")
      )
    );
};

//SCRAP EMPLOYEE ASSET
export const scrapEmployeeAsset = (pk, asset, status) => (
  dispatch,
  getState
) => {
  axios
    .patch(serverurl + getDatabaseUrl() + `/equipment/${pk}`, asset, tokenConfig(getState))
    .then((res) => {
      dispatch(
        createMessages({
          scrapEmployeeAsset: "Zaktualizowano dane o środku trwałym",
          type: "info",
        })
      );
      if (status === "sd") {
        dispatch({
          type: SCRAP_EMP_ASSET_SD,
          payload: res.data,
        });
      } else if (status === "wr") {
        dispatch({
          type: SCRAP_EMP_ASSET_WR,
          payload: res.data,
        });
      }
    })
    .catch((err) =>
      dispatch(
        returnErrors(err.response.data.detail, err.response.status, "error")
      )
    );
};

//GET_WARECHOUSE_ASSETS_AL/Na/El/It/Po
export const getWarAssets = (status, eqtype) => (dispatch, getState) => {
  if (eqtype === "al") {
    dispatch({ type: A_QUERY_AL_IN_PROGRESS });
    axios
      .get(
        serverurl + getDatabaseUrl() + `/equipment/?status=${status}&eqtype=${eqtype}`,
        tokenConfig(getState)
      )
      .then((res) => {
        dispatch({
          type: GET_WAR_ASSETS_AL,
          payload: res.data,
        });
      })
      .catch((err) =>
        dispatch(
          returnErrors(err.response.data.detail, err.response.status, "error")
        )
      );
  } else if (eqtype === "na") {
    dispatch({ type: A_QUERY_NA_IN_PROGRESS });
    axios
      .get(
        serverurl + getDatabaseUrl() + `/equipment/?status=${status}&eqtype=${eqtype}`,
        tokenConfig(getState)
      )
      .then((res) => {
        dispatch({
          type: GET_WAR_ASSETS_NA,
          payload: res.data,
        });
      })
      .catch((err) =>
        dispatch(
          returnErrors(err.response.data.detail, err.response.status, "error")
        )
      );
  } else if (eqtype === "el") {
    dispatch({ type: A_QUERY_EL_IN_PROGRESS });
    axios
      .get(
        serverurl + getDatabaseUrl() + `/equipment/?status=${status}&eqtype=${eqtype}`,
        tokenConfig(getState)
      )
      .then((res) => {
        dispatch({
          type: GET_WAR_ASSETS_EL,
          payload: res.data,
        });
      })
      .catch((err) =>
        dispatch(
          returnErrors(err.response.data.detail, err.response.status, "error")
        )
      );
  } else if (eqtype === "it") {
    dispatch({ type: A_QUERY_IT_IN_PROGRESS });
    axios
      .get(
        serverurl + getDatabaseUrl() + `/equipment/?status=${status}&eqtype=${eqtype}`,
        tokenConfig(getState)
      )
      .then((res) => {
        dispatch({
          type: GET_WAR_ASSETS_IT,
          payload: res.data,
        });
      })
      .catch((err) =>
        dispatch(
          returnErrors(err.response.data.detail, err.response.status, "error")
        )
      );
  } else if (eqtype === "po") {
    dispatch({ type: A_QUERY_PO_IN_PROGRESS });
    axios
      .get(
        serverurl + getDatabaseUrl() + `/equipment/?status=${status}&eqtype=${eqtype}`,
        tokenConfig(getState)
      )
      .then((res) => {
        dispatch({
          type: GET_WAR_ASSETS_PO,
          payload: res.data,
        });
      })
      .catch((err) =>
        dispatch(
          returnErrors(err.response.data.detail, err.response.status, "error")
        )
      );
  }
};

export const getInventoryAssets = (inventory) => (dispatch, getState) => {
  dispatch({ type: RAPORT_QUERY_IN_PROGRESS });
  axios
    .get(serverurl + getDatabaseUrl() + `/inventorysnapshot/?inv=${inventory}`, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: GET_INVENTORY_ASSETS,
        payload: res.data,
      });
    })
    .catch((err) =>
      dispatch(
        returnErrors(err.response.data.detail, err.response.status, "error")
      )
    );
};

export const getInventoryDetails = (inventory) => (dispatch, getState) => {
  dispatch({ type: RAPORT_QUERY_IN_PROGRESS2 });
  axios
    .get(serverurl + getDatabaseUrl() + `/inventory/${inventory}`, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: GET_INVENTORY_DETAILS,
        payload: res.data,
      });
    })
    .catch((err) =>
      dispatch(
        returnErrors(err.response.data.detail, err.response.status, "error")
      )
    );
};

export const clearInventoryAssets = () => (dispatch, getState) => {
  dispatch({ type: CLEAR_INVENTORY_ASSETS });
}

export const clearInventoryEmpAssets = () => (dispatch, getState) => {
  dispatch({ type: CLEAR_INVENTORY_EMP_ASSETS });
}

export const clearEmpInventories = () => (dispatch, getState) => {
  dispatch({ type: CLEAR_EMP_INVENTORIES });
}

//GET_MY_INVENTORY_AL/NA/EL/IT/PO
export const getMyInventory = (inventory, eqtype) => (dispatch, getState) => {
  if (eqtype === "al") {
    dispatch({ type: INV_QUERY_AL_IN_PROGRESS });
    axios
      .get(
        serverurl + getDatabaseUrl() + `/inventorysnapshot/?inv=${inventory}&eqtype=${eqtype}`,
        tokenConfig(getState)
      )
      .then((res) => {
        dispatch({
          type: GET_MY_INV_AL,
          payload: res.data,
        });
      })
      .catch((err) =>
        dispatch(
          returnErrors(err.response.data.detail, err.response.status, "error")
        )
      );
  } else if (eqtype === "na") {
    dispatch({ type: INV_QUERY_NA_IN_PROGRESS });
    axios
      .get(
        serverurl + getDatabaseUrl() + `/inventorysnapshot/?inv=${inventory}&eqtype=${eqtype}`,
        tokenConfig(getState)
      )
      .then((res) => {
        dispatch({
          type: GET_MY_INV_NA,
          payload: res.data,
        });
      })
      .catch((err) =>
        dispatch(
          returnErrors(err.response.data.detail, err.response.status, "error")
        )
      );
  } else if (eqtype === "el") {
    dispatch({ type: INV_QUERY_EL_IN_PROGRESS });
    axios
      .get(
        serverurl + getDatabaseUrl() + `/inventorysnapshot/?inv=${inventory}&eqtype=${eqtype}`,
        tokenConfig(getState)
      )
      .then((res) => {
        dispatch({
          type: GET_MY_INV_EL,
          payload: res.data,
        });
      })
      .catch((err) =>
        dispatch(
          returnErrors(err.response.data.detail, err.response.status, "error")
        )
      );
  } else if (eqtype === "it") {
    dispatch({ type: INV_QUERY_IT_IN_PROGRESS });
    axios
      .get(
        serverurl + getDatabaseUrl() + `/inventorysnapshot/?inv=${inventory}&eqtype=${eqtype}`,
        tokenConfig(getState)
      )
      .then((res) => {
        dispatch({
          type: GET_MY_INV_IT,
          payload: res.data,
        });
      })
      .catch((err) =>
        dispatch(
          returnErrors(err.response.data.detail, err.response.status, "error")
        )
      );
  } else if (eqtype === "po") {
    dispatch({ type: INV_QUERY_PO_IN_PROGRESS });
    axios
      .get(
        serverurl + getDatabaseUrl() + `/inventorysnapshot/?inv=${inventory}&eqtype=${eqtype}`,
        tokenConfig(getState)
      )
      .then((res) => {
        dispatch({
          type: GET_MY_INV_PO,
          payload: res.data,
        });
      })
      .catch((err) =>
        dispatch(
          returnErrors(err.response.data.detail, err.response.status, "error")
        )
      );
  }
};

//GET MY Inventory DETAILS
export const getMyInventoryDetails = (pk) => (dispatch, getState) => {
  dispatch({ type: A_QUERY_IN_PROGRESS });
  axios
    .get(serverurl + getDatabaseUrl() + `/inventorysnapshot/${pk}`, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: GET_MY_INV_DETAILS,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch(
        returnErrors(err.response.data.detail, err.response.status, "error")
      );
      dispatch({ type: A_QUERY_FAILED });
    });
};

//Update MY Inventory Status
export const updateMyInventoryDetails = (pk, body) => (dispatch, getState) => {
  axios
    .patch(serverurl + getDatabaseUrl() + `/inventorysnapshot/${pk}`, body, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: UPDATE_MY_INV_DETAILS,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch(
        returnErrors(err.response.data.detail, err.response.status, "error")
      );
    });
};

export const updateEmpInventoryDetails = (pk, body) => (dispatch, getState) => {
  axios
    .patch(serverurl + getDatabaseUrl() + `/inventorysnapshot/${pk}`, body, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: UPDATE_EMP_INV_DETAILS,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch(
        returnErrors(err.response.data.detail, err.response.status, "error")
      );
    });
};

//Delete My Inventory
export const deleteMyInventoryDetails = (pk) => (dispatch, getState) => {
  axios
    .delete(serverurl + getDatabaseUrl() + `/inventorysnapshot/${pk}`, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: DELETE_MY_INV_DETAILS,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch(
        returnErrors(err.response.data.detail, err.response.status, "error")
      );
    });
}

//Add My Inventory
export const addMyInventoryDetails = (body) => (dispatch, getState) => {
  axios
    .post(serverurl + getDatabaseUrl() + `/inventorysnapshot/`, body, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: ADD_MY_INV_DETAILS,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch(
        returnErrors(err.response.data.detail, err.response.status, "error")
      );
    });
}

export const getEmployeeInventoryAssets = (inv, owner, eqtype) => (dispatch, getState) => {
  if (eqtype === "al") {
    dispatch({ type: INV_QUERY_AL_IN_PROGRESS });
    axios
      .get(
        serverurl + getDatabaseUrl() + `/inventorysnapshot/?inv=${inv}&owner=${owner}&eqtype=${eqtype}`,
        tokenConfig(getState)
      )
      .then((res) => {
        dispatch({
          type: GET_EMPLOYEE_INV_ASSETS_AL,
          payload: res.data,
        });
      })
      .catch((err) =>
        dispatch(
          returnErrors(err.response.data.detail, err.response.status, "error")
        )
      );
  } else if (eqtype === "na") {
    dispatch({ type: INV_QUERY_NA_IN_PROGRESS });
    axios
      .get(
        serverurl + getDatabaseUrl() + `/inventorysnapshot/?inv=${inv}&owner=${owner}&eqtype=${eqtype}`,
        tokenConfig(getState)
      )
      .then((res) => {
        dispatch({
          type: GET_EMPLOYEE_INV_ASSETS_NA,
          payload: res.data,
        });
      })
      .catch((err) =>
        dispatch(
          returnErrors(err.response.data.detail, err.response.status, "error")
        )
      );
  } else if (eqtype === "el") {
    dispatch({ type: INV_QUERY_EL_IN_PROGRESS });
    axios
      .get(
        serverurl + getDatabaseUrl() + `/inventorysnapshot/?inv=${inv}&owner=${owner}&eqtype=${eqtype}`,
        tokenConfig(getState)
      )
      .then((res) => {
        dispatch({
          type: GET_EMPLOYEE_INV_ASSETS_EL,
          payload: res.data,
        });
      })
      .catch((err) =>
        dispatch(
          returnErrors(err.response.data.detail, err.response.status, "error")
        )
      );
  } else if (eqtype === "it") {
    dispatch({ type: INV_QUERY_IT_IN_PROGRESS });
    axios
      .get(
        serverurl + getDatabaseUrl() + `/inventorysnapshot/?inv=${inv}&owner=${owner}&eqtype=${eqtype}`,
        tokenConfig(getState)
      )
      .then((res) => {
        dispatch({
          type: GET_EMPLOYEE_INV_ASSETS_IT,
          payload: res.data,
        });
      })
      .catch((err) =>
        dispatch(
          returnErrors(err.response.data.detail, err.response.status, "error")
        )
      );
  } else if (eqtype === "po") {
    dispatch({ type: INV_QUERY_PO_IN_PROGRESS });
    axios
      .get(
        serverurl + getDatabaseUrl() + `/inventorysnapshot/?inv=${inv}&owner=${owner}&eqtype=${eqtype}`,
        tokenConfig(getState)
      )
      .then((res) => {
        dispatch({
          type: GET_EMPLOYEE_INV_ASSETS_PO,
          payload: res.data,
        });
      })
      .catch((err) =>
        dispatch(
          returnErrors(err.response.data.detail, err.response.status, "error")
        )
      );
  }
};