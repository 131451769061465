import { GET_MANAGER_DETAILS, M_QUERY_FAILED, M_QUERY_IN_PROGRESS } from "../actions/types";

const initialState = {
  queryInProgress: false,
  managerDetails: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_MANAGER_DETAILS:
      return {
        ...state,
        managerDetails: action.payload
      };
    case M_QUERY_IN_PROGRESS:
      return {
        ...state,
        queryInProgress: true
      };
    case M_QUERY_FAILED:
      return {
        ...state,
        queryInProgress: false
      };
    default:
      return state;
  }
}
