import React, { Component } from 'react';
import PropTypes from "prop-types";
import { Typography, IconButton, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Button } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';

export class EditableField extends Component {
  state = {
    open: false,
    editValue: "",
  };

  static propTypes = {
    value: PropTypes.string,
    label: PropTypes.string,
    onSave: PropTypes.func,
  };

  handleClickOpen = () => {
    this.setState({ editValue: this.props.value, open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  handleSave = () => {
    this.props.onSave(this.state.editValue);
    this.setState({ open: false });
  };

  handleChange = (e) => {
    this.setState({ editValue: e.target.value });
  };

  render() {
    const { classes, label, value, isBool, isBold } = this.props;
    const { open, editValue } = this.state;

    const checkIcon = (<CheckIcon style={{ marginTop: "-15px", marginBottom: "-7.5px" }} />);
    const closeIcon = (<CloseIcon style={{ marginTop: "-15px", marginBottom: "-7.5px" }} />);

    return (
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        {isBool ? (
          <Typography className={isBold ? classes.textDescription2 : classes.textDescription}>
            {value == true ? checkIcon : value == false ? closeIcon : "?"}
          </Typography>
        ) : (
          <Typography className={isBold ? classes.textDescription2 : classes.textDescription}>
            {value === "" || value == null ? "-BRAK-" : value}
          </Typography>
        )}
        {isBool ? (
          <div>
            <IconButton onClick={() => this.props.onSave(true)} style={{ padding: 4 }}>
              <CheckIcon />
            </IconButton>
            <IconButton onClick={() => this.props.onSave(false)} style={{ padding: 4 }}>
              <CloseIcon />
            </IconButton>
          </div >
        ) : (
          <IconButton onClick={this.handleClickOpen} style={{ padding: 4 }}>
            <EditIcon />
          </IconButton>
        )}
        <Dialog open={open} onClose={this.handleClose}>
          <DialogTitle>Edytuj {label}</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              label={label}
              type="text"
              fullWidth
              value={editValue}
              onChange={this.handleChange}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary">
              Anuluj
            </Button>
            <Button onClick={this.handleSave} color="primary">
              Zapisz
            </Button>
          </DialogActions>
        </Dialog>
      </div >
    );
  }
};

export default EditableField;