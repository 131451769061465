import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import Card from "../../components/Card/Card.js";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
//import IconButton from "@material-ui/core/IconButton";
import { cardStyles } from "../../common/radiolineStyles";
import OwnCashPayBtn from "../../graphics/OwnCashPayButton.svg";

export class ButtonCashPay extends Component {
  state = {};

  handleOpenDialog = () => {
    this.props.triggerDialogOpen();
  };

  render() {
    const { classes } = this.props;

    return (
      <Grid item xs={12} sm={12} md={4} lg={3}>
        <Card>
          <CardActionArea onClick={this.handleOpenDialog}>
            <CardMedia
              style={{ background: "#e5a0c6" }}
              component="img"
              alt="Płatność gotówką"
              image={OwnCashPayBtn}
              title="Contemplative Reptile"
            />
            <CardContent>
              <Typography
                className={`${classes.CardButtonLabel} ${classes.textColorPinkMain}`}
              >
                Płatność ze środków własnych
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
      </Grid>
    );
  }
}

export default withStyles(cardStyles, { withTheme: true })(ButtonCashPay);
