import React, { Component, Fragment } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import Card from "../../components/Card/Card.js";
import CardBody from "../../components/Card/CardBody.js";
import Typography from "@material-ui/core/Typography";
import { createMuiTheme } from "@material-ui/core";

import PropTypes from "prop-types";
import { connect } from "react-redux";

import { CardMedia, Dialog, DialogContent, DialogContentText } from '@material-ui/core';

const materialTheme = createMuiTheme({
  palette: {
    primary: {
      main: "#0071BC",
    },
  },
  overrides: {
    MuiChip: {
      root: {
        color: "#ffffff",
        "& $avatar": {
          color: "#ffffff",
        },
      },
      icon: {
        color: "#ffffff",
      },
    },
    MuiCardActions: {
      root: {
        background: "#aaaaaa",
      },
    },
    MuiIconButton: {
      root: {
        color: "#fff",
      },
    },
  },
});

const styles = (theme) => ({
  avatar: {
    backgroundColor: "#0071BC",
    height: 50,
    width: 50,
  },
  Address: {
    textAlign: "left",
    fontFamily: "Lato, Open Sans, sans-serif",
    fontSize: "1em",
    fontWeight: "300",
    color: "#444444",
  },
  Position: {
    textAlign: "left",
    fontFamily: "Lato, Open Sans, sans-serif",
    fontSize: "1em",
    fontWeight: "400",
    color: "#0071BC",
  },
  Name: {
    textAlign: "left",
    fontFamily: "Lato, Open Sans, sans-serif",
    fontSize: "2em",
    fontWeight: "700",
    color: "#444444",
  },
  ProfileBar: {
    textAlign: "left",
    fontFamily: "Lato, Open Sans, sans-serif",
    fontSize: "1em",
    fontWeight: "400",
    color: "#444444",
  },
  Country: {
    textAlign: "left",
    fontFamily: "Lato, Open Sans, sans-serif",
    fontSize: "1em",
    fontWeight: "300",
    color: "#444444",
    textTransform: "uppercase",
  },
  large: {
    width: "90px",
    height: "90px",
  },
});

export class InspectionReqPhotosCard extends Component {
  static propTypes = {
    inspectionDetails: PropTypes.object.isRequired,
  };

  state = {
    openPhotoDialog: false,
    selectedPhoto: null,
  };

  handleOpenDialog = (photo) => {
    this.setState({
      selectedPhoto: photo,
      openPhotoDialog: true,
    });
  };

  handleCloseDialog = () => {
    this.setState({
      selectedPhoto: null,
      openPhotoDialog: false,
    });
  }

  render() {
    const { classes, inspectionDetails } = this.props;
    const { openPhotoDialog, selectedPhoto } = this.state;

    return (
      <Fragment>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Card style={{ marginTop: "0px", marginBottom: "0px" }}>
            <CardBody>
              <Grid container justify="space-between" alignItems="center" spacing={2}>
                <Grid item xs={11} sm={11} md={11} lg={11}>
                  <Typography className={classes.Name}>
                    Zdjęcia obowiązkowe
                  </Typography>
                  <br></br>
                  <Grid container spacing={4}>
                    {inspectionDetails.photos.filter(photo => photo.photoType !== 'op').map((photo, index) => (
                      <Grid item xs={6} sm={4} md={3} lg={2} key={index}>
                        <CardMedia
                          component="img"
                          alt={photo.description}
                          height="140"
                          image={photo.file}
                          onClick={() => this.handleOpenDialog(photo)}
                        />
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              </Grid>
            </CardBody>
          </Card>
        </Grid>
        <Dialog
          fullScreen={true}
          open={openPhotoDialog}
          onClose={this.handleCloseDialog}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogContent>
            <img src={selectedPhoto?.file} alt={selectedPhoto?.description} style={{ width: '100%', height: 'auto' }} />
            <DialogContentText style={{ backgroundColor: '#000', color: '#fff', padding: '10px' }}>
              {selectedPhoto?.description}
            </DialogContentText>
          </DialogContent>
        </Dialog>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  inspectionDetails: state.fleet.inspectionDetails,
});

export default connect(
  mapStateToProps,
  {}
)(withStyles(styles, { withTheme: true })(InspectionReqPhotosCard));
