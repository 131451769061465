import React, { Component, Fragment } from "react";
import { lighten } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import Grid from "@material-ui/core/Grid";
import Card from "../../components/Card/Card.js";
import CardBody from "../../components/Card/CardBody.js";
import CardHeader from "@material-ui/core/CardHeader";
import PropTypes from "prop-types";
//import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
//import PanToolIcon from "@material-ui/icons/PanTool";
//import DevicesOtherIcon from "@material-ui/icons/DevicesOther";
import DeleteIcon from "@material-ui/icons/Delete";
//integracja z reduxem
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

const styles = (theme) => ({
  avatarSD: {
    backgroundColor: "#777777",
    height: 50,
    width: 50,
  },
  headertitle: {
    fontFamily: "Lato, Open Sans, sans-serif",
    fontSize: "1.2em",
    fontWeight: "700",
    color: "#444444",
  },
  headerSD: {
    textAlign: "left",
    backgroundColor: lighten("#777777", 0.85),
  },
  info: {
    fontFamily: "Lato, Open Sans, sans-serif",
    fontSize: "1.5em",
    fontWeight: "300",
    color: "#0071BC",
  },
  listTextSecondary: {
    textAlign: "left",
    color: "#444444",
    fontFamily: "Lato, Open Sans, sans-serif",
    fontSize: "1em",
    fontWeight: "300",
    display: "block",
  },
  listTextSecondaryComment: {
    textAlign: "left",
    color: "#999999",
    fontFamily: "Lato, Open Sans, sans-serif",
    fontSize: "1em",
    fontWeight: "300",
    display: "block",
  },
  listTextSecondaryError: {
    textAlign: "left",
    color: "#dc004e",
    fontFamily: "Lato, Open Sans, sans-serif",
    fontSize: "1em",
    fontWeight: "300",
    display: "block",
  },
});

//sortowanie assetsów z listy
function compare(a, b) {
  if (a.nazwa.toUpperCase() < b.nazwa.toUpperCase()) {
    return -1;
  }
  if (a.nazwa.toUpperCase() > b.nazwa.toUpperCase()) {
    return 1;
  }
  return 0;
}

var wyplaszcz = {
  myAssetsFlattened: [],

  dodajElement: function(element) {
    this.myAssetsFlattened.push({
      pk: element.pk,
      status: element.status,
      grupa: element.model.eqtype_description,
      typ: element.model.eqtype,
      nazwa: element.model.name,
      model: element.model.description,
      producent: element.model.vendor,
      numerseryjny: element.serialNumber,
      numerproduktu: element.partNumber,
      datazakupu: element.purchaseDate,
      dataprzekazania: element.assignDate,
      opisdodatkowy: element.description,
      wlasciciel: element.owner.first_name + " " + element.owner.last_name,
      wlascicielDo: element.transferowner,
      scrapageUserComment: element.scrapageUserComment,
      scrapagedDate: element.scrapagedDate,
      scrapageManagerComment: element.scrapageManagerComment,
      scrapageChargeValue: element.scrapageChargeValue,
      scrapageChargeStatus: element.scrapageChargeStatus_description,
    });
  },
  zapiszMacierz: function(element) {
    return this.myAssetsFlattened;
  },
  inicjalizacja: function(element) {
    this.myAssetsFlattened = [];
  },
};

class MyAssetHistoryList extends Component {
  state = {
    selectedIndex: null,
    myAssets: [],
    myAssetsFlatened: [],
    myAssetSelected: null,
    value: 0,
    index: null,
  };

  static propTypes = {
    myAssetsByStatusSd: PropTypes.array.isRequired,
  };

  componentDidMount() {
    if (
      this.props.activeFilter === "historyfilter" &&
      this.props.myAssetsByStatusSd.length !== 0
    ) {
      wyplaszcz.inicjalizacja();
      this.props.myAssetsByStatusSd.forEach(wyplaszcz.dodajElement, wyplaszcz);
      const macierz = wyplaszcz.zapiszMacierz();
      macierz.sort(compare);
      this.setState({
        myAssets: this.props.myAssetsByStatusSd,
        myAssetsFlatened: macierz,
      });
      macierz.sort(compare);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (
      this.props.activeFilter === "historyfilter" &&
      nextProps.myAssetsByStatusSd !== this.state.myAssets
    ) {
      this.setState({
        selectedIndex: null,
        myAssetSelected: null,
      });
      this.props.checked(false, null, null);

      wyplaszcz.inicjalizacja();
      nextProps.myAssetsByStatusSd.forEach(wyplaszcz.dodajElement, wyplaszcz);
      const macierz = wyplaszcz.zapiszMacierz();
      macierz.sort(compare);
      this.setState({
        myAssets: nextProps.myAssetsByStatusSd,
        myAssetsFlatened: macierz,
      });

      macierz.sort(compare);
    }

    if (
      nextProps.activeListType !== "MyAssetHistoryList" &&
      nextProps.activeListType !== null
    ) {
      this.setState({
        selectedIndex: null,
        myAssetSelected: null,
      });
    }
  }

  handleListItemClick = (event, index, pk) => {
    const selectedIndex = this.state.selectedIndex;

    if (selectedIndex !== index) {
      this.setState({
        selectedIndex: index,
        myAssetSelected: pk,
      });
      this.props.checked(true, pk, "MyAssetHistoryList");
    } else {
      this.setState({
        selectedIndex: null,
        myAssetSelected: null,
      });
      this.props.checked(false, null, null);
    }
  };

  handleDisplayAssetDetails = (assetpk) => {};

  render() {
    const { myAssetsFlatened, selectedIndex } = this.state;
    const { classes } = this.props;

    const scrapicon = (
      <ListItemAvatar>
        <Avatar style={{ backgroundColor: "#777777" }}>
          <DeleteIcon />
        </Avatar>
      </ListItemAvatar>
    );

    var nothingToDisplay = (
      <div className={classes.info}>
        Nie ma żadnych środków trwałych spełniających podane kryteria ...
      </div>
    );

    const headerSD = (
      <CardHeader
        className={classes.headerSD}
        avatar={
          <Avatar aria-label="recipe" className={classes.avatarSD}>
            HI
          </Avatar>
        }
        title={
          <div className={classes.headertitle}>Historia środków trwałych</div>
        }
      />
    );

    return (
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Card style={{ overflow: "auto" }}>
          <CardBody style={{ padding: 0 }}>
            {this.props.activeFilter === "historyfilter" ? headerSD : ""}
            <List component="nav" aria-label="main mailbox folders">
              {myAssetsFlatened.length === 0 ? nothingToDisplay : ""}
              {myAssetsFlatened.map((asset, index) => (
                <ListItem
                  key={asset.typ + asset.pk}
                  button
                  alignItems="flex-start"
                  selected={selectedIndex === index}
                  onClick={(event) =>
                    this.handleListItemClick(event, index, asset.pk)
                  }
                >
                  {this.props.activeFilter === "historyfilter" ? scrapicon : ""}
                  <ListItemText
                    primary={asset.nazwa}
                    secondary={
                      <Fragment>
                        <Typography
                          className={classes.listTextSecondary}
                          component="span"
                        >
                          {asset.model || "model nieznany"}
                        </Typography>
                        <Typography
                          className={classes.listTextSecondary}
                          component="span"
                        >
                          {asset.datazakupu ? "Zakup: " + asset.datazakupu : ""}
                        </Typography>
                        <Typography
                          className={classes.listTextSecondary}
                          component="span"
                        >
                          Złomowanie: {asset.scrapagedDate}
                        </Typography>
                        <Typography
                          className={classes.listTextSecondaryComment}
                          component="span"
                        >
                          {asset.scrapageUserComment
                            ? "Komentarz użytkownika: " +
                              asset.scrapageUserComment
                            : ""}
                        </Typography>
                        <Typography
                          className={classes.listTextSecondaryComment}
                          component="span"
                        >
                          {asset.scrapageManagerComment
                            ? "Komentarz kierownika: " +
                              asset.scrapageManagerComment
                            : ""}
                        </Typography>
                        <Typography
                          className={classes.listTextSecondaryError}
                          component="span"
                        >
                          {asset.scrapageChargeStatus !== "nie zażądano"
                            ? "Obciążenie: " + asset.scrapageChargeStatus
                            : ""}
                        </Typography>
                        <Typography
                          className={classes.listTextSecondary}
                          component="span"
                        >
                          {asset.scrapageChargeStatus !== "nie zażądano"
                            ? "Wartość obciążenia: " + asset.scrapageChargeValue
                            : ""}
                        </Typography>
                      </Fragment>
                    }
                  />
                </ListItem>
              ))}
            </List>
          </CardBody>
        </Card>
      </Grid>
    );
  }
}

const mapStateToProps = (state) => ({
  myAssetsByStatusSd: state.assets.myAssetsByStatusSd,
});

export default connect(
  mapStateToProps,
  {}
)(withStyles(styles, { withTheme: true })(MyAssetHistoryList));
