import React, { Component, Fragment } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import Card from "../../components/Card/Card.js";
import { Avatar } from "@material-ui/core";
//import CloseIcon from "@material-ui/icons/Close";
//import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import { connect } from "react-redux";
//import PropTypes from "prop-types";
import CircularProgress from "@material-ui/core/CircularProgress";
import CardHeader from "@material-ui/core/CardHeader";
import { cardStyles } from "../../common/radiolineStyles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import CardActions from "@material-ui/core/CardActions";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import clsx from "clsx";
import Collapse from "@material-ui/core/Collapse";
import CardContent from "@material-ui/core/CardContent";
import CompareArrowsIcon from "@material-ui/icons/CompareArrows";
import Badge from "@material-ui/core/Badge";

export class ClearanceCardPm extends Component {
  state = {
    expanded: false,
  };

  handleExpandClick = () => {
    this.setState({
      expanded: !this.state.expanded,
    });
  };

  render() {
    const {
      classes,
      queryInProgress,
      monthAndYearToDisplay,
      statsArray,
    } = this.props;
    const { expanded } = this.state;

    const circle = <CircularProgress color="secondary" size="1em" />;

    const comparearrows = <CompareArrowsIcon />;

    const headerOP = (
      <CardHeader
        className={classes.headerInfo1}
        title={
          <Typography className={classes.CardHeader}>
            Oczekujące na rozliczenie
          </Typography>
        }
        subheader={
          <Typography className={classes.CardSubheader}>
            {"do " +
              monthAndYearToDisplay.month +
              " " +
              monthAndYearToDisplay.year}
          </Typography>
        }
        action={
          <Badge
            badgeContent={statsArray.length}
            color="secondary"
            anchorOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
          >
            {queryInProgress ? circle : comparearrows}
          </Badge>
        }
      />
    );

    const table = (
      <Fragment>
        <TableHead>
          <TableRow>
            <TableCell padding="checkbox"></TableCell>
            <TableCell>
              <b>Imię Nazwisko</b>
            </TableCell>
            <TableCell align="right">
              <b>Liczba transakcji</b>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {statsArray.map((stat, index) => (
            <TableRow key={"tr_ak-" + index}>
              <TableCell padding="checkbox" component="th" scope="row">
                <Avatar
                  className={classes.rootTableAvatarSmall}
                  alt={stat.owner__username}
                  src={stat.owner__profile__profileImage}
                />
              </TableCell>
              <TableCell component="th" scope="row">
                {stat.owner__last_name + " " + stat.owner__first_name}
              </TableCell>
              <TableCell align="right">{stat.count}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Fragment>
    );

    return (
      <Grid item xs={12} sm={6} md={6} lg={4}>
        <Card style={{ marginTop: "0px", marginBottom: "0px" }}>
          {headerOP}
          <CardActions>
            <Typography className={classes.CardTitle}>
              Zestawienie transakcji które wymagają rozliczenia kosztów
            </Typography>
            <IconButton
              className={clsx(classes.expand, {
                [classes.expandOpen]: expanded,
              })}
              onClick={this.handleExpandClick}
              aria-expanded={expanded}
              aria-label="show more"
            >
              <ExpandMoreIcon />
            </IconButton>
          </CardActions>
          <Collapse in={expanded} timeout="auto" unmountOnExit>
            <CardContent className={classes.fixedheight250}>
              <TableContainer className={classes.rootTableWithSubheaders}>
                <Table
                  className={classes.rootTableWithSubheaders}
                  //stickyHeader
                  aria-label="simple table"
                //size="small"
                >
                  {table}
                </Table>
              </TableContainer>
            </CardContent>
          </Collapse>
        </Card>
      </Grid>
    );
  }
}

const mapStateToProps = (state) => ({});

export default connect(
  mapStateToProps,
  {}
)(withStyles(cardStyles, { withTheme: true })(ClearanceCardPm));
