import React, { Component, Fragment } from "react";
import NameCard from "./NameCard";
import ProfileCard from "./ProfileCard";
import BankingCard from "./BankingCard";
import PasswordChangeCard from "./PasswordChangeCard";
import { withStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";

const styles = (theme) => ({
  section: {
    backgroundPosition: "50%",
    backgroundSize: "cover",
    padding: 0,
  },

  textName2: {
    textAlign: "left",
    fontFamily: "Lato, Open Sans, sans-serif",
    fontSize: "1em",
    fontWeight: "300",
  },
});

export class Employee extends Component {
  render() {
    const { classes } = this.props;
    return (
      <Fragment>
        <div className={classes.section}>
          <Box m={2}>
            <Grid container justify="flex-start" spacing={2}>
              <NameCard />
              <ProfileCard />
              <BankingCard />
              <PasswordChangeCard />
            </Grid>
          </Box>
        </div>
      </Fragment>
    );
  }
}

export default withStyles(styles, { withTheme: true })(Employee);
