import React, { Component, Fragment } from "react";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import { withStyles, fade } from "@material-ui/core/styles";
import SearchIcon from "@material-ui/icons/Search";
import InputBase from "@material-ui/core/InputBase";
import TripsManagerTrips from "./TripsManagerTrips";

import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  getEmployeeTripsFiltered,
  getEmployeeTripsSearch
} from "../Redux/actions/trips-manager";

const styles = theme => ({
  appBar: {
    top: "auto",
    bottom: 0,
    background: "#0071BC"
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: fade(theme.palette.common.white, 0.25)
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(3),
      width: "auto"
    }
  },
  searchIcon: {
    width: theme.spacing(7),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  inputRoot: {
    color: "inherit"
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 7),
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: 200
    }
  }
});

class TripsManagerViewer extends Component {
  state = {
    search: null
  };
  //integracja z reduxem
  static propTypes = {
    auth: PropTypes.object.isRequired,
    employeeTripsCA: PropTypes.array.isRequired,
    employeeTripsZA: PropTypes.array.isRequired,
    employeeTripsRZ: PropTypes.array.isRequired,
    getEmployeeTripsFiltered: PropTypes.func.isRequired,
    getEmployeeTripsSearch: PropTypes.func.isRequired,
  };

  componentDidMount() {
    this.props.getEmployeeTripsFiltered("tripStatus", "CA");
    this.props.getEmployeeTripsFiltered("tripStatus", "ZA");
    this.props.getEmployeeTripsFiltered("tripStatus", "RZ");
  }

  componentDidUpdate(prevProps) {
    if (this.props.auth.main_profile.defaultCompany != prevProps.auth.main_profile.defaultCompany) {
      this.props.getEmployeeTripsFiltered("tripStatus", "CA");
      this.props.getEmployeeTripsFiltered("tripStatus", "ZA");
      this.props.getEmployeeTripsFiltered("tripStatus", "RZ");
    }
  }

  handleInputChange = name => ({ target: { value } }) => {
    this.setState({
      ...this.state,
      [name]: value
    });
  };

  handleSearch = () => {
    const surname = this.state.search;
    this.props.getEmployeeTripsSearch("surname", surname);
  };

  render() {
    const { classes } = this.props;
    const { search } = this.state;
    return (
      <Fragment>
        <TripsManagerTrips
          label="Oczekujące na akceptację"
          status="CA"
          employeeTrips={this.props.employeeTripsCA}
        ></TripsManagerTrips>
        <TripsManagerTrips
          label="Zaakceptowane"
          status="ZA"
          employeeTrips={this.props.employeeTripsZA}
        ></TripsManagerTrips>
        <TripsManagerTrips
          label="Oczekujące na rozliczenie"
          status="RZ"
          employeeTrips={this.props.employeeTripsRZ}
        ></TripsManagerTrips>
        <TripsManagerTrips
          label="Filtr"
          status="FILTERED"
          employeeTrips={this.props.employeeTripsSEARCH}
        ></TripsManagerTrips>
        <AppBar position="fixed" color="primary" className={classes.appBar}>
          <Toolbar>
            <div className={classes.search}>
              <div className={classes.searchIcon}>
                <SearchIcon />
              </div>
              <InputBase
                placeholder="Nazwisko ..."
                classes={{
                  root: classes.inputRoot,
                  input: classes.inputInput
                }}
                inputProps={{ "aria-label": "search" }}
                value={search || ""}
                onChange={this.handleInputChange("search")}
                onKeyPress={ev => {
                  if (ev.key === "Enter") {
                    ev.preventDefault();
                    this.handleSearch();
                  }
                }}
              />
            </div>
          </Toolbar>
        </AppBar>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
  employeeTripsCA: state.tripsManagerReducer.employeeTripsCA,
  employeeTripsZA: state.tripsManagerReducer.employeeTripsZA,
  employeeTripsRZ: state.tripsManagerReducer.employeeTripsRZ,
  employeeTripsSEARCH: state.tripsManagerReducer.employeeTripsSEARCH,
});

export default connect(mapStateToProps, {
  getEmployeeTripsFiltered,
  getEmployeeTripsSearch
})(withStyles(styles, { withTheme: true })(TripsManagerViewer));
