import React, { Component, Fragment } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import Card from "../components/Card/Card.js";
import CardBody from "../components/Card/CardBody.js";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { createMuiTheme } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";
import LinearProgress from "@material-ui/core/LinearProgress";
import Box from "@material-ui/core/Box";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import EditIcon from "@material-ui/icons/Edit";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { patchProfileEm } from "../Redux/actions/common";

import PropTypes from "prop-types";
import { connect } from "react-redux";

const materialTheme = createMuiTheme({
  palette: {
    primary: {
      main: "#0071BC",
    },
  },
  overrides: {
    MuiChip: {
      root: {
        color: "#ffffff",
        "& $avatar": {
          color: "#ffffff",
        },
      },
      icon: {
        color: "#ffffff",
      },
    },
    MuiCardActions: {
      root: {
        background: "#aaaaaa",
      },
    },
    MuiIconButton: {
      root: {
        color: "#fff",
      },
    },
  },
});

const styles = (theme) => ({
  avatar: {
    backgroundColor: "#0071BC",
    height: 50,
    width: 50,
  },
  Address: {
    textAlign: "left",
    fontFamily: "Lato, Open Sans, sans-serif",
    fontSize: "1em",
    fontWeight: "300",
    color: "#444444",
  },
  Name: {
    textAlign: "left",
    fontFamily: "Lato, Open Sans, sans-serif",
    fontSize: "1em",
    fontWeight: "700",
    color: "#444444",
  },
  ProfileBar: {
    textAlign: "left",
    fontFamily: "Lato, Open Sans, sans-serif",
    fontSize: "1em",
    fontWeight: "400",
    color: "#444444",
  },
  Country: {
    textAlign: "left",
    fontFamily: "Lato, Open Sans, sans-serif",
    fontSize: "1em",
    fontWeight: "300",
    color: "#444444",
    textTransform: "uppercase",
  },
  large: {
    width: "70px",
    height: "70px",
  },
});

export class BankingCardE extends Component {
  state = {
    salaryBankName: null,
    salaryAccountNumber: null,
    salaryBankNameFlag: false,
    salaryAccountNumberFlag: false,
    openDialog: false,
  };

  static propTypes = {
    userFull: PropTypes.object.isRequired,
    patchProfileEm: PropTypes.func.isRequired,
  };

  componentDidMount() {
    //inicjalizacja zmiennych edytowalnych
    this.setState({
      salaryBankName: this.props.userFull.additional_profile.salaryBankName,
      salaryAccountNumber: this.props.userFull.additional_profile.salaryAccountNumber,
    });
  }

  handleTextList = (name) => ({ target: { value } }) => {
    this.setState({
      ...this.state,
      [name]: value,
    });
  };

  triggerSaveBanking = () => {
    if (
      this.state.salaryBankName !==
      this.props.userFull.additional_profile.salaryBankName
    ) {
      this.setState({
        salaryBankNameFlag: true,
      });
    } else {
      this.setState({
        phoneFlag: false,
      });
    }

    if (
      this.state.salaryAccountNumber !==
      this.props.userFull.additional_profile.salaryAccountNumber
    ) {
      this.setState({
        salaryAccountNumberFlag: true,
      });
    } else {
      this.setState({
        salaryAccountNumberFlag: false,
      });
    }

    this.setState({
      openDialog: true,
    });
  };

  handleCloseDialog = () => {
    this.setState({
      openDialog: false,
    });
  };

  handleSaveBanking = () => {
    var profileBody = {
      salaryBankName: this.state.salaryBankName,
      salaryAccountNumber: this.state.salaryAccountNumber,
    };
    this.setState({
      openDialog: false,
    });
    this.props.patchProfileEm(profileBody, this.props.userFull.main_profile.pk, 1);
  };

  render() {
    const { classes } = this.props;
    const {
      salaryBankName,
      salaryAccountNumber,
      openDialog,
      salaryBankNameFlag,
      salaryAccountNumberFlag,
    } = this.state;

    const salaryBankNameInfo = (
      <TextField
        label="Nazwa banku (wypłata)"
        id="outlined-margin-normal"
        value={salaryBankName}
        fullWidth
        className={classes.textField}
        margin="normal"
        variant="outlined"
        size="small"
      />
    );
    const salaryAccountNumberInfo = (
      <Fragment>
        <TextField
          label="Numer konta (wypłata)"
          id="outlined-margin-normal"
          value={salaryAccountNumber}
          fullWidth
          className={classes.textField}
          margin="normal"
          variant="outlined"
          size="small"
        />
      </Fragment>
    );

    const nothingInfo = (
      <Fragment>
        <Typography>Brak danych do aktualizacji ...</Typography>
      </Fragment>
    );

    const saveButtonEn = (
      <Button
        onClick={this.handleSaveBanking}
        color="primary"
        autoFocus
        variant="contained"
      >
        Zapisz
      </Button>
    );

    const saveButtonDi = (
      <Button disabled color="primary" autoFocus variant="contained">
        Zapisz
      </Button>
    );

    return (
      <Fragment>
        <Grid item xs={12} sm={12} md={6} lg={4}></Grid>
        <Grid item xs={12} sm={12} md={6} lg={8}>
          <Card style={{ marginBottom: "0px" }}>
            <CardBody>
              <ThemeProvider theme={materialTheme}>
                <Grid
                  container
                  justify="space-between"
                  alignItems="center"
                  spacing={2}
                >
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Typography className={classes.Name}>
                      Profil finansowy
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={4} md={4} lg={3}>
                    <TextField
                      label="Nazwa banku (wypłata)"
                      id="outlined-margin-normal"
                      value={salaryBankName}
                      fullWidth
                      onChange={this.handleTextList("salaryBankName")}
                      className={classes.textField}
                      margin="normal"
                      variant="outlined"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <EditIcon />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={8} md={8} lg={9}>
                    <TextField
                      label="Numer konta (wypłata)"
                      id="outlined-margin-normal"
                      value={salaryAccountNumber}
                      fullWidth
                      onChange={this.handleTextList("salaryAccountNumber")}
                      className={classes.textField}
                      margin="normal"
                      variant="outlined"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <EditIcon />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <ThemeProvider theme={materialTheme}>
                    <Button
                      style={{
                        marginTop: "20px",
                        marginBottom: "40px",
                        marginLeft: "8px",
                      }}
                      variant="contained"
                      color="primary"
                      onClick={this.triggerSaveBanking}
                    >
                      Zapisz
                    </Button>
                  </ThemeProvider>
                </Grid>
              </ThemeProvider>
            </CardBody>
          </Card>
        </Grid>

        <Dialog
          open={openDialog}
          onClose={this.handleCloseDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Aktualizacja profilu bankowego użytkownika..."}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Następujące dane zostaną zaktualizowane:
            </DialogContentText>
            <ThemeProvider theme={materialTheme}>
              {salaryBankNameFlag ? salaryBankNameInfo : ""}
              {salaryAccountNumberFlag ? salaryAccountNumberInfo : ""}
              {!salaryBankNameFlag &&
                !salaryAccountNumberFlag
                ? nothingInfo
                : ""}
            </ThemeProvider>
          </DialogContent>
          <DialogActions>
            <ThemeProvider theme={materialTheme}>
              <Button onClick={this.handleCloseDialog} color="primary">
                Anuluj
              </Button>
              {!salaryBankNameFlag &&
                !salaryAccountNumberFlag
                ? saveButtonDi
                : saveButtonEn}
            </ThemeProvider>
          </DialogActions>
        </Dialog>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  userFull: state.common.userFull,
});

export default connect(
  mapStateToProps,
  { patchProfileEm }
)(withStyles(styles, { withTheme: true })(BankingCardE));
