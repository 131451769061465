//prepare purchase summary
export function getBalance(transactions) {
  balance.inicjalisation();

  if (transactions.length > 0) {
    transactions.forEach(balance.update_zpk_zpg_tnz, balance);
    transactions.forEach(balance.update_wb_pk, balance);
    transactions.forEach(balance.update_pb_dt_rt, balance);
    balance.calculateSum();

    const wb_pk = balance.pobierz_wb_pk();
    const zpk = balance.pobierz_zpk();
    const zoc = balance.pobierz_zoc();
    const zpg = balance.pobierz_zpg();
    const tnz = balance.pobierz_tnz();
    const pb = balance.pobierz_pb();
    const dt = balance.pobierz_dt();
    const rt = balance.pobierz_rt();
    const zpb = balance.pobierz_zpb();
    const zdt = balance.pobierz_zdt();
    const zrt = balance.pobierz_zrt();
    const sum = balance.pobierz_sum();

    //console.log(wb_pk, sum);

    const calculation = {
      wb_pk: wb_pk,
      zpk: zpk,
      zoc: zoc,
      zpg: zpg,
      tnz: tnz,
      pb: pb,
      dt: dt,
      rt: rt,
      zpb: zpb,
      zdt: zdt,
      zrt: zrt,
      sum: sum,
    };

    //console.log(calculation);

    return calculation;
  } else {
    const calculation = {
      wb_pk: 0,
      zpk: 0,
      zoc: 0,
      zpg: 0,
      tnz: 0,
      pb: 0,
      dt: 0,
      rt: 0,
      zpb: 0,
      zdt: 0,
      zrt: 0,
      sum: 0,
    };

    return calculation;
  }
}

var balance = {
  wb_pk: 0,
  zpk: 0,
  zoc: 0,
  zpg: 0,
  tnz: 0,
  pb: 0, //zlecony payback
  dt: 0, //zlecony deduct
  rt: 0, //zlecony return
  zpb: 0, //zaksięgowany payback
  zdt: 0, //zaksięgowany deduct
  zrt: 0, //zaksięgowany return
  sum: 0,

  update_zpk_zpg_tnz: function (element) {
    //zaakceptowane płatności kartą
    if (
      element.transactionType === "cp" &&
      element.transactionStatus === "ro"
    ) {
      this.zpk = this.zpk + Number(element.value);
    }
    //zaakceptowane płatności gotówką
    //TU DODAĆ ŚRODKI WŁASNE
    else if (
      element.transactionType === "ch" &&
      element.transactionStatus === "ro"
    ) {
      this.zpg = this.zpg + Number(element.value);
    }
    else if (
      element.transactionType === "oc" &&
      element.transactionStatus === "ro"
    ) {
      this.zoc = this.zoc + Number(element.value);
    }
    //transakcje nie zaakceptowane
    else if (
      element.transactionStatus !== "ro" &&
      element.transactionStatus !== "aa"
    ) {
      this.tnz = this.tnz + Number(element.value);
    }
  },

  update_wb_pk: function (element) {
    //wylicz pobrane srodki z karty
    if (element.transactionType === "cp" || element.transactionType === "wi") {
      this.wb_pk = this.wb_pk + Number(element.value);
    }
  },

  update_pb_dt_rt: function (element) {
    //wylicz sumę wypłat i potrąceń
    if (
      element.transactionType === "pb" &&
      element.transactionStatus === "ap"
    ) {
      this.pb = this.pb + Number(element.value);
    } else if (
      element.transactionType === "dt" &&
      element.transactionStatus === "ap"
    ) {
      this.dt = this.dt + Number(element.value);
    } else if (
      element.transactionType === "rt" &&
      element.transactionStatus === "ap"
    ) {
      this.rt = this.rt + Number(element.value);
    } else if (
      element.transactionType === "pb" &&
      element.transactionStatus === "pc"
    ) {
      this.zpb = this.zpb + Number(element.value);
    } else if (
      element.transactionType === "dt" &&
      element.transactionStatus === "pc"
    ) {
      this.zdt = this.zdt + Number(element.value);
    } else if (
      element.transactionType === "rt" &&
      element.transactionStatus === "pc"
    ) {
      this.zrt = this.zrt + Number(element.value);
    }
  },

  calculateSum: function (element) {
    this.sum = (this.zpk + this.zpg + this.zdt + this.zrt + this.zoc) - (this.wb_pk + this.zpb);
    if (this.sum.toFixed(2) === "-0.00") {
      this.sum = 0;
    }
  },

  pobierz_wb_pk: function (element) {
    return this.wb_pk;
  },
  pobierz_zpk: function (element) {
    return this.zpk;
  },
  pobierz_zoc: function (element) {
    return this.zoc;
  },
  pobierz_zpg: function (element) {
    return this.zpg;
  },
  pobierz_tnz: function (element) {
    return this.tnz;
  },
  pobierz_pb: function (element) {
    return this.pb;
  },
  pobierz_dt: function (element) {
    return this.dt;
  },
  pobierz_rt: function (element) {
    return this.rt;
  },
  pobierz_zpb: function (element) {
    return this.zpb;
  },
  pobierz_zdt: function (element) {
    return this.zdt;
  },
  pobierz_zrt: function (element) {
    return this.zrt;
  },
  pobierz_sum: function (element) {
    return this.sum;
  },
  inicjalisation: function (element) {
    this.wb_pk = 0;
    this.zpk = 0;
    this.zoc = 0;
    this.zpg = 0;
    this.tnz = 0;
    this.pb = 0;
    this.dt = 0;
    this.rt = 0;
    this.zpb = 0; //zaksięgowany payback
    this.zdt = 0; //zaksięgowany deduct
    this.zrt = 0; //zaksięgowany return
    this.sum = 0;
  },
};

export default getBalance;
