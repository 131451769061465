import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import TextField from "@material-ui/core/TextField";
import { createMuiTheme } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { logout, changeUserPassword, patchProfile, changeCompany } from "../Redux/actions/auth";
import { clearErrors } from "../Redux/actions/messages";
import Radioline from "../graphics/RadiolineRefreshed.svg";
import Avatar from "@material-ui/core/Avatar";
import useScrollTrigger from "@material-ui/core/useScrollTrigger";

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import CloseIcon from "@material-ui/icons/Close";
import Fab from "@material-ui/core/Fab";
import SaveIcon from "@material-ui/icons/Save";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";

import { Redirect } from "react-router-dom";
import DialogContentText from "@material-ui/core/DialogContentText";
import HomeIcon from "@material-ui/icons/Home";
import BusinessCenterIcon from "@material-ui/icons/BusinessCenter";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import DateRangeIcon from "@material-ui/icons/DateRange";
import DevicesIcon from "@material-ui/icons/Devices";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import PersonIcon from "@material-ui/icons/Person";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import LocalShippingIcon from "@material-ui/icons/LocalShipping";
import DescriptionIcon from '@material-ui/icons/Description';
import BeachAccessIcon from '@material-ui/icons/BeachAccess';

import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import Collapse from "@material-ui/core/Collapse";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Input from '@material-ui/core/Input';

const styles = (theme) => ({
  text: {
    padding: theme.spacing(2, 2, 0),
  },
  paper: {
    paddingBottom: 50,
  },
  //list: {
  //  marginBottom: theme.spacing(2)
  //},
  subheader: {
    backgroundColor: theme.palette.background.paper,
  },
  appBar: {
    top: "auto",
    bottom: 0,
    background: "#0071BC",
  },
  grow: {
    flexGrow: 1,
  },
  fabButton: {
    position: "absolute",
    zIndex: 1,
    top: -30,
    left: 0,
    right: 0,
    margin: "0 auto",
  },
  list: {
    width: 170,
  },
  fullList: {
    width: "auto",
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
});

const CssTextField = withStyles({
  root: {
    width: 300,
    flexWrap: "wrap",
    marginTop: "50px",
    marginLeft: "30px",
    marginRight: "30px",
    "& label.Mui-focused": {
      color: "#0071bc",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#0071bc",
    },
  },
})(TextField);

const materialTheme = createMuiTheme({
  overrides: {
    MuiButton: {
      root: {
        //textTransform: "none"
      },
    },
  },
});

const StyledInput = withStyles({
  underline: {
    '&:before': {
      borderBottomColor: 'white',
    },
    '&:after': {
      borderBottomColor: 'white',
    },
    '&:hover:not(.Mui-disabled):before': {
      borderBottomColor: 'white',
    },
  },
})(Input);

const StyledSelect = withStyles({
  root: {
    color: 'white',
  },
  icon: {
    fill: 'white',
  },
})(Select);

const StyledMenuItem = withStyles({
  root: {
    color: 'black',
    backgroundColor: 'white',
    '&:hover': {
      backgroundColor: '#f5f5f5',
    },
  },
})(MenuItem);

function ElevationScroll(props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}

ElevationScroll.propTypes = {
  children: PropTypes.element.isRequired,
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

const initialState = {
  dialogPanelUzytkownikaOpen: false,
  dialogEmployeeViewWarningOpen: false,
  username: null,
  email: null,
  oldPassword: "",
  oldPasswordErrorFlag: false,
  oldPasswordErrorMessage: "",
  newPassword: "",
  newPasswordErrorFlag: false,
  newPasswordErrorMessage: "",
  newPassword2: "",
  newPassword2ErrorFlag: false,
  newPassword2ErrorMessage: "",
  editFlag: false,
  editButtonLabel: "Zmień hasło",
  employeeView: null,
  redirect: false,
  drawer: false,
  collapse: false,
};

class Navbar extends Component {
  static propTypes = {
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
    logout: PropTypes.func.isRequired,
    changeUserPassword: PropTypes.func.isRequired,
    patchProfile: PropTypes.func.isRequired,
    clearErrors: PropTypes.func.isRequired,
    changeCompany: PropTypes.func.isRequired,
  };

  state = {
    dialogPanelUzytkownikaOpen: false,
    dialogEmployeeViewWarningOpen: false,
    dialogSessionExpiredOpen: false,
    username: null,
    email: null,
    oldPassword: "",
    oldPasswordErrorFlag: false,
    oldPasswordErrorMessage: "",
    newPassword: "",
    newPasswordErrorFlag: false,
    newPasswordErrorMessage: "",
    newPassword2: "",
    newPassword2ErrorFlag: false,
    newPassword2ErrorMessage: "",
    editFlag: false,
    editButtonLabel: "Zmień hasło",
    employeeView: null,
    redirect: false,
    drawer: false,
    collapse: false,
  };

  componentDidUpdate(prevProps) {
    if (
      prevProps.auth.isLoggingOut === true &&
      this.props.auth.isLoggingOut === false
    ) {
      this.setState(initialState);
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (
      props.auth.user !== null &&
      props.auth.user.username !== state.username
    ) {
      return {
        username: props.auth.user.username,
        email: props.auth.user.email,
        //employeeView: props.auth.user.profile.employeeView,
      };
    } else {
      return null;
    }
  }

  sprawdzZgodnoscHasel = (newPassword, newPassword2) => {
    if (newPassword === newPassword2) {
      this.setState({
        newPassword2ErrorFlag: false,
        newPassword2ErrorMessage: "",
      });
      return false;
    } else {
      this.setState({
        newPassword2ErrorFlag: true,
        newPassword2ErrorMessage:
          "Potwierdzone hasło nie jest zgodne z podanym wyżej nowym hasłem",
      });
      return true;
    }
  };

  sprawdzTextList = (value, label) => {
    //place, remarks, client, country
    switch (label) {
      case "oldPassword":
        if (value === "") {
          this.setState({
            oldPasswordErrorFlag: true,
            oldPasswordErrorMessage: "Podaj swoje aktualne hasło.",
          });
          return true;
        } else {
          this.setState({
            oldPasswordErrorFlag: false,
            oldPasswordErrorMessage: "",
          });
          return false;
        }
      case "newPassword":
        if (
          /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/.test(
            value
          ) === false
        ) {
          this.setState({
            newPasswordErrorFlag: true,
            newPasswordErrorMessage:
              "Hasło musi zawierać: 1) przynajmniej jedną małą literę [a-z], 2) przynajmniej jedną wielką literę [A-Z], 3) przynajmniej jedną cyfrę [0-9], 4) przynajmniej jeden znak specjalny #?!@$%^&*- 5) przynajmniej 8 znaków",
          });
          return true;
        } else {
          this.setState({
            newPasswordErrorFlag: false,
            newPasswordErrorMessage: "",
          });
          return false;
        }
      default:
        return false;
    }
  };

  handleClickOpenDialogPanelUzytkownika = () => {
    this.setState({
      dialogPanelUzytkownikaOpen: true,
    });
  };

  handleClickCloseDialogPanelUzytkownika = () => {
    this.setState({
      dialogPanelUzytkownikaOpen: false,
      username: null,
      email: null,
      oldPassword: "",
      oldPasswordErrorFlag: false,
      oldPasswordErrorMessage: "",
      newPassword: "",
      newPasswordErrorFlag: false,
      newPasswordErrorMessage: "",
      newPassword2: "",
      newPassword2ErrorFlag: false,
      newPassword2ErrorMessage: "",
      editFlag: false,
      editButtonLabel: "Zmień hasło",
    });
  };

  handleClickOpenDialogEmployeeViewWarning = () => {
    this.setState({
      dialogEmployeeViewWarningOpen: true,
      redirect: false,
    });
  };

  handleClickCloseDialogEmployeeViewWarning = () => {
    this.setState({
      dialogEmployeeViewWarningOpen: false,
    });
  };

  handleLogout = () => {
    this.props.logout();
  };

  toggleEditMode = () => {
    const { editButtonLabel } = this.state;
    if (editButtonLabel === "Anuluj") {
      this.setState({
        username: null,
        email: null,
        oldPassword: "",
        oldPasswordErrorFlag: false,
        oldPasswordErrorMessage: "",
        newPassword: "",
        newPasswordErrorFlag: false,
        newPasswordErrorMessage: "",
        newPassword2: "",
        newPassword2ErrorFlag: false,
        newPassword2ErrorMessage: "",
        editButtonLabel: "Zmień hasło",
      });
    } else {
      this.setState({
        editButtonLabel: "Anuluj",
      });
    }
    this.setState({
      editFlag: !this.state.editFlag,
    });
  };

  handleTextList = (name) => ({ target: { value } }) => {
    this.setState({
      ...this.state,
      [name]: value,
    });
  };

  handleEmployeeView = () => {
    this.setState({ employeeView: !this.state.employeeView }, () => {
      const employeeView = this.state.employeeView;
      const employeeViewBody = {
        employeeView: employeeView,
      };
      this.props.patchProfile(
        employeeViewBody,
        this.props.auth.main_profile.pk
      );
      this.props.switchMenu(employeeView);
    });
    this.setState({
      dialogEmployeeViewWarningOpen: false,
    });

    this.setState({
      redirect: true,
    });
  };

  triggerSavePassword = () => {
    const { oldPassword, newPassword, newPassword2 } = this.state;

    //walidacja pól oldPassword i newPassword
    const oldPasswordErrorFlag = this.sprawdzTextList(
      oldPassword,
      "oldPassword"
    );
    const newPasswordErrorFlag = this.sprawdzTextList(
      newPassword,
      "newPassword"
    );

    //sprawdzenie zgodności newPassword i newPassword2
    const newPassword2ErrorFlag = this.sprawdzZgodnoscHasel(
      newPassword,
      newPassword2
    );
    //zapis na bazę
    if (
      oldPasswordErrorFlag === true ||
      newPasswordErrorFlag === true ||
      newPassword2ErrorFlag === true
    ) {
    } else {
      //przygotuj body
      const changePasswordBody = {
        old_password: oldPassword,
        new_password: newPassword,
      };

      //przygotuj pk usera
      this.props.changeUserPassword(
        changePasswordBody,
        this.props.auth.user.id
      );
      this.setState({
        dialogPanelUzytkownikaOpen: false,
        username: null,
        email: null,
        oldPassword: "",
        oldPasswordErrorFlag: false,
        oldPasswordErrorMessage: "",
        newPassword: "",
        newPasswordErrorFlag: false,
        newPasswordErrorMessage: "",
        newPassword2: "",
        newPassword2ErrorFlag: false,
        newPassword2ErrorMessage: "",
        editFlag: false,
        editButtonLabel: "Zmień hasło",
      });
    }
  };

  toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    this.setState({
      drawer: open,
    });

    if (open === false) {
      this.setState({
        collapse: open,
      });
    }
  };

  handleCollapse = () => {
    this.setState({
      collapse: !this.state.collapse,
    });
  };

  handleOptionChange = (option) => {
    var value = option.target.value;
    this.props.changeCompany(parseInt(value));
  }

  render() {
    const { isAuthenticated, user, permissions } = this.props.auth;
    const isSuperUser = user != null ? user.is_superuser : false;
    const { defaultCompany } = this.props.auth.main_profile;

    const {
      dialogPanelUzytkownikaOpen,
      dialogEmployeeViewWarningOpen,
      dialogSessionExpiredOpen,
      username,
      email,
      editFlag,
      editButtonLabel,
      newPassword,
      newPasswordErrorFlag,
      newPasswordErrorMessage,
      newPassword2,
      newPassword2ErrorFlag,
      newPassword2ErrorMessage,
      oldPassword,
      oldPasswordErrorFlag,
      oldPasswordErrorMessage,
      redirect,
      drawer,
    } = this.state;

    const { classes } = this.props;

    const selectCompany = (
      <Fragment>
        <StyledSelect
          onChange={this.handleOptionChange}
          style={{ width: "200px", marginRight: "16px" }}
          input={<StyledInput />}
          value={defaultCompany}
        >
          <StyledMenuItem value="1" disabled={!permissions.company_radioline_sp_zoo}>Radio Line Sp. z o. o.</StyledMenuItem>
          <StyledMenuItem value="3" disabled={!permissions.company_radioline_gmbh}>Radio Line GmbH</StyledMenuItem>
          <StyledMenuItem value="2" disabled={!permissions.company_radioline_2008_ab}>Radio Line 2008 AB</StyledMenuItem>
          <StyledMenuItem value="4" disabled>Radio Line AS</StyledMenuItem>
        </StyledSelect>
      </Fragment>
    )
    const authLinks = (
      <Fragment>
        <Button color="inherit" onClick={this.handleLogout}>
          wyloguj
        </Button>
      </Fragment>
    );
    var myAvatar = null;
    if (user !== null) {
      myAvatar = (
        <Link key="Pracownik" to="/employee">
          <Avatar className={classes.large} src={null /*user.profile.profileImage*/}>
            {user.first_name.substr(0, 1) + user.last_name.substr(0, 1)}
          </Avatar>
        </Link>
      );
    } else {
      myAvatar = <Avatar style={{ backgroundColor: "#AAAAAA" }}>NA</Avatar>;
    }

    const authMenu = (
      <div style={{ flexGrow: 1 }}>
        <IconButton
          edge="start"
          className={classes.menuButton}
          color="inherit"
          aria-label="menu"
          onClick={this.toggleDrawer(true)}
        >
          <MenuIcon />
        </IconButton>
      </div>
    );

    const guestLinks = (
      <Fragment>
        <Button color="inherit" href="/login">
          zaloguj
        </Button>
        <Button color="inherit" href="/register">
          zarejestruj
        </Button>
      </Fragment>
    );

    const guestLogo = (
      <div style={{ flexGrow: 1 }}>
        <Link to="/">
          <img src={Radioline} alt="logo" style={{ maxHeight: "50px" }} />
        </Link>
      </div>
    );

    const saveButton = (
      <Fab
        aria-label="add"
        className={classes.fabButton}
        onClick={this.triggerSavePassword}
      >
        <SaveIcon />
      </Fab>
    );

    const changePasswordFields = (
      <Fragment>
        <CssTextField
          error={oldPasswordErrorFlag}
          required
          type="password"
          label="Aktualne hasło "
          margin="normal"
          value={oldPassword}
          onChange={this.handleTextList("oldPassword")}
          helperText={oldPasswordErrorMessage}
        />
        <CssTextField
          error={newPasswordErrorFlag}
          required
          type="password"
          label="Nowe hasło"
          margin="normal"
          value={newPassword}
          onChange={this.handleTextList("newPassword")}
          helperText={newPasswordErrorMessage}
        />
        <CssTextField
          error={newPassword2ErrorFlag}
          required
          type="password"
          label="Nowe hasło - potwierdź"
          margin="normal"
          value={newPassword2}
          onChange={this.handleTextList("newPassword2")}
          helperText={newPassword2ErrorMessage}
        />
      </Fragment>
    );

    const userFields = (
      <Fragment>
        <CssTextField
          label="Login"
          margin="normal"
          value={username || ""}
          InputProps={{
            readOnly: true,
          }}
        />
        <CssTextField
          label="Adres email"
          margin="normal"
          value={email || ""}
          InputProps={{
            readOnly: true,
          }}
        />
      </Fragment>
    );

    const renderRedirect = <Redirect to="/" />;
    const renderRedirectLoginPage = <Redirect to="/login" />;
    //const renderSessionExpiredPage = <Redirect to="/sessionExpired" />;

    const schedulerlink = (
      <Link key="schedulerlink" to="/scheduler">
        <ListItem button onClick={this.toggleDrawer(false)}>
          <ListItemIcon>
            <DateRangeIcon />
          </ListItemIcon>
          <ListItemText primary="Harmonogram" />
        </ListItem>
      </Link>
    );

    const zfsslink = (
      <Link key="zfsslink" to="/zfss">
        <ListItem button onClick={this.toggleDrawer(false)}>
          <ListItemIcon>
            <BeachAccessIcon />
          </ListItemIcon>
          <ListItemText primary="ZFŚS" />
        </ListItem>
      </Link>
    );

    const drawerEmployeeMenu = (
      <List>
        <Link key="tripslink" to="/trips">
          <ListItem button onClick={this.toggleDrawer(false)}>
            <ListItemIcon>
              <BusinessCenterIcon />
            </ListItemIcon>
            <ListItemText primary="Delegacje" />
          </ListItem>
        </Link>
        {/*<Link key="holidayslink" to="/holidays">
          <ListItem button onClick={this.toggleDrawer(false)}>
            <ListItemIcon>
              <DateRangeIcon />
            </ListItemIcon>
            <ListItemText primary="Urlopy" />
          </ListItem>
        </Link>*/}
        <Link key="srodkitrwalelink" to="/assets">
          <ListItem button onClick={this.toggleDrawer(false)}>
            <ListItemIcon>
              <DevicesIcon />
            </ListItemIcon>
            <ListItemText primary="Środki trwałe" />
          </ListItem>
        </Link>
        <Link key="rozliczenialink" to="/accounting">
          <ListItem button onClick={this.toggleDrawer(false)}>
            <ListItemIcon>
              <MonetizationOnIcon />
            </ListItemIcon>
            <ListItemText primary="Finanse" />
          </ListItem>
        </Link>
        <Link key="samochodysluzbowelink" to="/fleet">
          <ListItem button onClick={this.toggleDrawer(false)}>
            <ListItemIcon>
              <LocalShippingIcon />
            </ListItemIcon>
            <ListItemText primary="Samochody służbowe" />
          </ListItem>
        </Link>
        <Link key="employeelink" to="/employee">
          <ListItem button onClick={this.toggleDrawer(false)}>
            <ListItemIcon>
              <PersonIcon />
            </ListItemIcon>
            <ListItemText primary="Pracownik" />
          </ListItem>
        </Link>
        <Link key="documentlink" to="/documents">
          <ListItem button onClick={this.toggleDrawer(false)}>
            <ListItemIcon>
              <DescriptionIcon />
            </ListItemIcon>
            <ListItemText primary="Dokumenty" />
          </ListItem>
        </Link>
        {permissions != null && permissions.module_scheduler || isSuperUser ? schedulerlink : ""}
        {(permissions != null && permissions.module_zfss || isSuperUser) && defaultCompany == 1 ? zfsslink : ""}
      </List>
    );

    const drawerCommonMenu = (
      <Fragment>
        <List>
          <Link key="cmml" to="/">
            <ListItem button key="cmm" onClick={this.toggleDrawer(false)}>
              <ListItemIcon>
                <HomeIcon />
              </ListItemIcon>
              <ListItemText primary="Menu Główne" />
            </ListItem>
          </Link>
        </List>
        <Divider />
      </Fragment>
    );

    return (
      <Fragment>
        {redirect ? renderRedirect : ""}
        {isAuthenticated === false ? renderRedirectLoginPage : ""}
        <ThemeProvider theme={materialTheme}>
          <ElevationScroll {...this.props}>
            <AppBar style={{ background: "#0071BC" }}>
              <Toolbar display="flex">
                {isAuthenticated ? authMenu : guestLogo}
                {isAuthenticated ? selectCompany : ""}
                {isAuthenticated ? myAvatar : myAvatar}
                {isAuthenticated ? authLinks : guestLinks}
              </Toolbar>
            </AppBar>
          </ElevationScroll>
          <Toolbar />
        </ThemeProvider>

        <Dialog
          fullScreen
          open={dialogPanelUzytkownikaOpen}
          onClose={this.handleClickCloseDialogPanelUzytkownika}
          aria-labelledby="form-dialog-title"
        >
          <AppBar position="static" style={{ background: "#0071BC" }}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={this.handleClickCloseDialogPanelUzytkownika}
                aria-label="close"
                className={classes.menuButton}
              >
                <CloseIcon />
              </IconButton>
              <Typography variant="h6" className={classes.title}>
                Panel użytkownika
              </Typography>
            </Toolbar>
          </AppBar>
          <AppBar position="fixed" color="primary" className={classes.appBar}>
            <Toolbar>
              {editFlag ? saveButton : ""}
              <div className={classes.grow} />
              <Button
                onClick={this.toggleEditMode}
                color="inherit"
                style={{ fontWeight: "bold" }}
              >
                {editButtonLabel}
              </Button>
            </Toolbar>
          </AppBar>

          <DialogContent>
            <form
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {editFlag ? changePasswordFields : ""}
              {!editFlag ? userFields : ""}
            </form>
          </DialogContent>
        </Dialog>

        <Dialog
          open={dialogEmployeeViewWarningOpen}
          onClose={this.handleClickCloseDialogEmployeeViewWarning}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {
              "Przełączam się pomiędzy widokiem Managera i Pracownika. Bierzący widok zostanie zamknięty."
            }
          </DialogTitle>
          <DialogActions>
            <Button
              onClick={this.handleClickCloseDialogEmployeeViewWarning}
              color="primary"
            >
              Anuluj
            </Button>
            <Button onClick={this.handleEmployeeView} color="primary" autoFocus>
              Przełącz
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={dialogSessionExpiredOpen}
          onClose={this.handleSessionExpired}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Sesja wygasła ...</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Wygląda na to że twoja sesja wygasła. Zaloguj się ponownie ...
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleSessionExpired} color="primary">
              OK
            </Button>
          </DialogActions>
        </Dialog>
        <Drawer open={drawer} onClose={this.toggleDrawer(false)}>
          <div
            className={classes.list}
            role="presentation"
            onClick={this.toggleDrawer(false)}
            onKeyDown={this.toggleDrawer(false)}
          ></div>
          {drawerCommonMenu}
          {drawerEmployeeMenu}
        </Drawer>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});

export default connect(
  mapStateToProps,
  {
    logout,
    changeUserPassword,
    patchProfile,
    clearErrors,
    changeCompany,
  }
)(withStyles(styles, { withTheme: true })(Navbar));
