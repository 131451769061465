import React, { Component, Fragment } from "react";
import Grid from "@material-ui/core/Grid";
import Card from "../../components/Card/Card.js";
import CardBody from "../../components/Card/CardBody.js";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";
import { formStyles } from "../../common/radiolineStyles";
import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { ThemeProvider } from "@material-ui/styles";
import { formsTheme } from "../../common/radiolineStyles";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  getUsersList,
} from "../../Redux/actions/common";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import { getFormattedDate } from "../../common/commonFunctions";
//import FormHelperText from "@material-ui/core/FormHelperText";
import { addTransaction } from "../../Redux/actions/accounting";
import { sendNotification, sendNewNotificationManager } from "../../Redux/actions/employee";
import { getProjects, clearProjects, getActiveProjects, clearActiveProjects } from "../../Redux/actions/project.js";
import { getFleet } from "../../Redux/actions/fleet.js";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CircularProgress from "@material-ui/core/CircularProgress";

export class AddCashTransactionForm extends Component {
  state = {
    withdrawValue: null,
    currency: "pln",
    transactionDescription: null,
    transactionDate: new Date(),
    transactionDateFormatted: getFormattedDate(new Date()),
    transactionProject: "",
    transactionCategory: "",
    purchaselist: "",
    car: "",
    baseStationIdentifier: "",
    transactionEmployee: "",
    newEmployeeSelected: null,
  };

  static propTypes = {
    auth: PropTypes.object.isRequired,
    usersList: PropTypes.array.isRequired,
    getUsersList: PropTypes.func.isRequired,
    addTransaction: PropTypes.func.isRequired,
    myManagerDetailsV2: PropTypes.object.isRequired,
    sendNotification: PropTypes.func.isRequired,
    sendNewNotificationManager: PropTypes.func.isRequired,
    projects: PropTypes.any.isRequired,
    active: PropTypes.any.isRequired,
    getProjects: PropTypes.func.isRequired,
    getActiveProjects: PropTypes.func.isRequired,
    clearProjects: PropTypes.func.isRequired,
    clearActiveProjects: PropTypes.func.isRequired,
    fleet: PropTypes.array.isRequired,
    getFleet: PropTypes.func.isRequired,
  };

  componentWillUnmount() {
    this.props.clearProjects();
    this.props.clearActiveProjects();
  }

  componentDidMount() {
    this.props.getProjects();
    this.props.getActiveProjects();
  }

  handleTextList = (name) => ({ target: { value } }) => {
    this.setState({
      [name]: value.toLowerCase(),
    });
  };

  handleSelectChangeCurrency = (event) => {
    console.log(event.target);
    this.setState({
      currency: event.target.value,
    });
  };

  getSelected = (event, value) => {
    if (value != null) {
      this.setState({
        newEmployeeSelected: value,
        transactionEmployee: value.first_name + " " + value.last_name,
      });
    }
    else {
      this.setState({
        newEmployeeSelected: value,
        transactionEmployee: "",
      });
    }
  };

  setOpen = () => {
    const open = this.state.open;
    if (open === false && this.props.usersList.length === 0) {
      this.props.getUsersList();
    }
    this.setState({
      open: !open,
    });
  };

  handleSelectCar = (event) => {
    this.setState({
      car: event.target.value,
    });
  };

  handleSelectProject = (event) => {
    console.log(event.target);
    this.setState({
      transactionProject: event.target.value,
    });
  };

  handleSelectChangeTransactioncategory = (event) => {
    if (event.target.value === "in") {
      this.setState({
        transactionCategory: event.target.value,
        transactionDescription: null,
        purchaselist: "",
        baseStationIdentifier: "",
        car: "",
        transactionEmployee: "",
      });
    } else if (event.target.value === "ms") {
      this.setState({
        transactionCategory: event.target.value,
        transactionDescription: "materiały na stację",
        purchaselist: "",
        baseStationIdentifier: "",
        car: "",
      });
    } else if (event.target.value === "wp") {
      this.props.getUsersList();
      this.setState({
        transactionCategory: event.target.value,
        transactionDescription: "wyposażenie pracownika",
        purchaselist: "",
        baseStationIdentifier: "",
        car: "",
        transactionEmployee: "",
      });
    } else if (event.target.value === "es") {
      this.setState({
        transactionCategory: event.target.value,
        transactionDescription: "flota",
        purchaselist: "",
        baseStationIdentifier: "",
        car: "",
        transactionEmployee: "",
      });
      this.props.getFleet();
    }
  };

  handleTransactionDateChange = (date) => {
    if (date !== null) {
      const dd = date.getDate();
      const mm = date.getMonth() + 1;
      const yyyy = date.getFullYear();
      const fullDate = yyyy + "-" + mm + "-" + dd;
      this.setState({
        transactionDate: date,
        transactionDateFormatted: fullDate,
      });
    } else {
      this.setState({
        transactionDate: null,
        transactionDateFormatted: null,
      });
    }
  };

  handleAddCashTransaction = () => {
    const body = new FormData();
    const transactionCategory = this.state.transactionCategory;

    if (transactionCategory === "ms") {
      body.append("value", this.state.withdrawValue);
      body.append("owner", this.props.auth.user.id);
      body.append("createDate", getFormattedDate(new Date()));
      body.append("currency", this.state.currency);
      body.append("project", this.state.transactionProject);
      body.append("transactionCategory", this.state.transactionCategory);
      body.append("transactionDate", this.state.transactionDateFormatted);
      body.append("bookDate", this.state.transactionDateFormatted);
      body.append("transactionDescription", "materiały na stację");
      body.append("baseStationIdentifier", this.state.baseStationIdentifier);
      body.append("purchaseList", this.state.purchaselist);
      body.append("transactionStatus", "ak");
      body.append("transactionType", "ch");
    } else if (transactionCategory === "wp") {
      body.append("value", this.state.withdrawValue);
      body.append("owner", this.props.auth.user.id);
      body.append("createDate", getFormattedDate(new Date()));
      body.append("currency", this.state.currency);
      body.append("project", this.state.transactionProject);
      body.append("transactionCategory", this.state.transactionCategory);
      body.append("transactionDate", this.state.transactionDateFormatted);
      body.append("bookDate", this.state.transactionDateFormatted);
      body.append("transactionDescription", "wyposażenie pracownika");
      body.append("purchaseList", this.state.purchaselist);
      body.append("transactionStatus", "ak");
      body.append("transactionType", "ch");
      body.append("transactionEmployee", this.state.transactionEmployee);
    } else if (transactionCategory === "es") {
      body.append("value", this.state.withdrawValue);
      body.append("owner", this.props.auth.user.id);
      body.append("createDate", getFormattedDate(new Date()));
      body.append("currency", this.state.currency);
      body.append("project", this.state.transactionProject);
      body.append("transactionCategory", this.state.transactionCategory);
      body.append("transactionDate", this.state.transactionDateFormatted);
      body.append("bookDate", this.state.transactionDateFormatted);
      body.append("transactionDescription", "flota");
      body.append("car", this.state.car);
      body.append("purchaseList", this.state.purchaselist);
      body.append("transactionStatus", "ak");
      body.append("transactionType", "ch");
    } else if (transactionCategory === "in") {
      body.append("value", this.state.withdrawValue);
      body.append("owner", this.props.auth.user.id);
      body.append("createDate", getFormattedDate(new Date()));
      body.append("currency", this.state.currency);
      body.append("project", this.state.transactionProject);
      body.append("transactionCategory", this.state.transactionCategory);
      body.append("transactionDate", this.state.transactionDateFormatted);
      body.append("bookDate", this.state.transactionDateFormatted);
      body.append("transactionDescription", this.state.transactionDescription);
      body.append("purchaseList", this.state.purchaselist);
      body.append("transactionStatus", "ak");
      body.append("transactionType", "ch");
    }

    /*
    const smsbody = {
      recipient: this.props.myManagerDetailsV2.phone,
      sender: "FINANSE",
      message:
        "Uzytkownik " +
        this.props.auth.user.username +
        " zarejestrowal transakcje platnosci GOTOWKA w wysokosci " +
        this.state.withdrawValue +
        " " +
        this.state.currency +
        ", tytulem: " +
        this.state.transactionDescription.toUpperCase() +
        ". Transakcja czeka na twoja akceptacje...",
      owner: this.props.auth.user.id,
      notificationType: "sms",
      isDelivered: true,
    };
    */

    const notifyBody = {
      user: this.props.auth.user.id,
      recipient: this.props.myManagerDetailsV2.email,
      module: "Finanse",
      topic: "Nowa transakcja gotówką służbową",
      message:
        "Użytkownik " + this.props.auth.user.first_name + " " + this.props.auth.user.last_name +
        " zarejestrował transakcję płatności gotówką służbową: \n" +
        "Data transakcji: " + this.state.transactionDateFormatted + "\n" +
        "Kwota: " + this.state.withdrawValue + " " + this.state.currency + "\n" +
        "Projekt: " + this.props.active.find((project) => project.pk === this.state.transactionProject).name + "\n" +
        "Tytułem: " + this.state.transactionDescription + "\n"
    }

    this.props.addTransaction(body, "ch");
    this.props.closeDialog();
    //this.props.sendNotification(smsbody);
    this.props.sendNewNotificationManager(notifyBody);
  };
  render() {
    const { classes, usersList } = this.props;
    const {
      open,
      newEmployeeSelected,
      withdrawValue,
      currency,
      transactionDescription,
      transactionDate,
      transactionProject,
      transactionCategory,
      purchaselist,
      baseStationIdentifier,
      car,
    } = this.state;

    const loading = open && usersList.length === 0;

    const buttonAddDi = (
      <Button
        disabled
        variant="contained"
        color="primary"
        className={classes.buttonAdd}
      >
        Dodaj
      </Button>
    );

    const buttonAddEn = (
      <Button
        variant="contained"
        color="primary"
        onClick={this.handleAddCashTransaction}
        className={classes.buttonAdd}
      >
        Dodaj
      </Button>
    );

    const msSpecificFormFields = (
      <Fragment>
        <TextField
          id="outlined-multiline-static"
          label="identyfikator stacji"
          className={classes.textFieldStatic}
          value={baseStationIdentifier || ""}
          onChange={this.handleTextList("baseStationIdentifier")}
          variant="filled"
          placeholder="projekt/stacja"
          helperText="np. KPN/6434_Venlo"
        />
        <br></br>
        <TextField
          id="outlined-multiline-static"
          label="lista zakupów"
          multiline
          className={classes.textFieldStatic}
          rows={4}
          value={purchaselist || ""}
          variant="filled"
          placeholder="Wprowadź listę zakupów"
          onChange={this.handleTextList("purchaselist")}
        />
      </Fragment>
    );

    const wpSpecificFormFields = (
      <Fragment>
        <Autocomplete
          value={newEmployeeSelected}
          id="asynchronous-demo"
          style={{ width: "260px", margin: "8px" }}
          open={open}
          onOpen={this.setOpen}
          onClose={this.setOpen}
          onChange={this.getSelected}
          getOptionSelected={(option, value) =>
            option.last_name === value.last_name &&
            option.first_name === value.first_name
          }
          getOptionLabel={(option) =>
            option.last_name + " " + option.first_name
          }
          options={usersList}
          loading={loading}
          renderInput={(params) => (
            <TextField
              {...params}
              label="wybierz pracownika"
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {loading ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
            />
          )}
        />
        <TextField
          id="outlined-multiline-static"
          label="lista zakupów"
          multiline
          className={classes.textFieldStatic}
          rows={4}
          value={purchaselist || ""}
          variant="filled"
          placeholder="producent/nazwa/model/ile_sztuk"
          helperText="jeżeli jest NumerSeryjny proszę również podać!"
          onChange={this.handleTextList("purchaselist")}
        />
      </Fragment>
    );

    const esSpecificFormFields = (
      <Fragment>
        <FormControl variant="filled" className={classes.textFieldStatic}>
          <InputLabel id="demo-simple-select-filled-label">
            Samochód
          </InputLabel>
          <Select
            labelId="demo-simple-select-filled-label"
            id="demo-simple-select-filled"
            value={car}
            onChange={this.handleSelectCar}
          >
            {this.props.fleet.map((item) => {
              return (
                <MenuItem value={item.pk}>{item.regNumber}</MenuItem>
              );
            })}
          </Select>
        </FormControl>
        <br></br>
        <TextField
          id="outlined-multiline-static"
          label="lista zakupów"
          multiline
          className={classes.textFieldStatic}
          rows={4}
          value={purchaselist || ""}
          variant="filled"
          placeholder="Wprowadź listę zakupów"
          onChange={this.handleTextList("purchaselist")}
        />
      </Fragment>
    );

    const inSpecificFormFields = (
      <Fragment>
        <TextField
          label="tytułem"
          helperText="Wprowadź tytuł wypłaty, max 50 znaków"
          id="filled-margin-none"
          className={classes.textFieldStatic}
          required
          multiline
          rowsMax={2}
          value={transactionDescription || ""}
          inputProps={{ maxLength: 50 }}
          onChange={this.handleTextList("transactionDescription")}
        />
        <br></br>
        <TextField
          id="outlined-multiline-static"
          label="lista zakupów"
          multiline
          className={classes.textFieldStatic}
          rows={4}
          value={purchaselist || ""}
          variant="filled"
          placeholder="Wprowadź listę zakupów"
          onChange={this.handleTextList("purchaselist")}
        />
      </Fragment>
    );

    return (
      <Fragment>
        <Grid container justify="center" spacing={2}>
          <Grid item xs={12} sm={12} md={12}>
            <Card style={{ overflow: "auto" }}>
              <CardBody>
                <Typography
                  style={{
                    marginBottom: "50px",
                    color: "#888888",
                    textAlign: "left",
                    fontFamily: "Lato, Open Sans, sans-serif",
                    fontSize: "1em",
                    fontWeight: "300",
                  }}
                >
                  Wprowadź szczegóły transakcji gotówkowej zgodnie z poniższymi
                  polami. Wszystkie pola są obowiązkowe ...
                </Typography>
                <ThemeProvider theme={formsTheme}>
                  <TextField
                    required
                    variant="filled"
                    id="standard-number"
                    placeholder="00,00"
                    label="Kwota płatności"
                    type="number"
                    className={classes.textFieldDynamic}
                    value={withdrawValue || ""}
                    onChange={this.handleTextList("withdrawValue")}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                  <FormControl
                    variant="filled"
                    className={classes.textFieldDynamic}
                  >
                    <InputLabel id="demo-simple-select-filled-label">
                      Waluta
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-filled-label"
                      id="demo-simple-select-filled"
                      value={currency}
                      onChange={this.handleSelectChangeCurrency}
                    >
                      <MenuItem value={"pln"}>PLN</MenuItem>
                      <MenuItem value={"eur"}>EUR</MenuItem>
                      <MenuItem value={"sek"}>SEK</MenuItem>
                      <MenuItem value={"nok"}>NOK</MenuItem>
                    </Select>
                  </FormControl>
                  <br></br>
                  <FormControl
                    variant="filled"
                    className={classes.textFieldStatic}
                  >
                    <InputLabel id="demo-simple-select-filled-label">
                      Projekt
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-filled-label"
                      id="demo-simple-select-filled"
                      value={transactionProject}
                      onChange={this.handleSelectProject}
                    >
                      {this.props.active.map((item) => {
                        return (
                          <MenuItem value={item.pk}>{item.name}</MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                  <br></br>
                  <FormControl
                    variant="filled"
                    className={classes.textFieldStatic}
                  >
                    <InputLabel id="demo-simple-select-filled-label">
                      kategoria zakupu
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-filled-label"
                      id="demo-simple-select-filled"
                      value={transactionCategory}
                      onChange={this.handleSelectChangeTransactioncategory}
                    >
                      <MenuItem value={"ms"}>materiały na stację</MenuItem>
                      <MenuItem value={"wp"}>wyposażenie pracownika</MenuItem>
                      <MenuItem value={"es"}>flota</MenuItem>
                      <MenuItem value={"in"}>inne</MenuItem>
                    </Select>
                  </FormControl>
                  <br></br>
                  {transactionCategory === "ms" ? msSpecificFormFields : ""}
                  {transactionCategory === "wp" ? wpSpecificFormFields : ""}
                  {transactionCategory === "es" ? esSpecificFormFields : ""}
                  {transactionCategory === "in" ? inSpecificFormFields : ""}
                  <br></br>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      className={classes.textFieldStatic}
                      autoOk
                      disableToolbar
                      variant="inline"
                      format="yyyy-MM-dd"
                      margin="normal"
                      id="date-picker-inline"
                      label="Data wypłaty"
                      value={transactionDate}
                      onChange={this.handleTransactionDateChange}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </ThemeProvider>
              </CardBody>
            </Card>
          </Grid>
          <Grid style={{ marginBottom: "30px" }} item xs={12} sm={12} md={12}>
            {(transactionCategory === "ms" && transactionProject && withdrawValue && baseStationIdentifier && purchaselist) ||
              (transactionCategory === "wp" && transactionProject && withdrawValue && purchaselist) ||
              (transactionCategory === "es" && transactionProject && withdrawValue && car && purchaselist) ||
              (transactionCategory === "in" && transactionProject && withdrawValue && purchaselist && transactionDescription)
              ? buttonAddEn
              : buttonAddDi}
          </Grid>
        </Grid>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  myManagerDetailsV2: state.employee.myManagerDetailsV2,
  projects: state.project.projects,
  active: state.project.active,
  fleet: state.fleet.fleet,
  usersList: state.common.users,
});

export default connect(
  mapStateToProps,
  { addTransaction, sendNotification, sendNewNotificationManager, getProjects, getFleet, clearProjects, getActiveProjects, clearActiveProjects, getUsersList }
)(withStyles(formStyles, { withTheme: true })(AddCashTransactionForm));
