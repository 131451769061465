import React, { Component, Fragment } from "react";
import BalanceCard from "./BalanceCard";
import EmployeeOperationsList from "../Common/EmployeeOperationsList";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { styles } from "../../common/radiolineStyles";
import Typography from "@material-ui/core/Typography";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import FormHelperText from "@material-ui/core/FormHelperText";
import {
  getTransactions,
  getTransactionDetails,
  patchTransactionDetails,
  clearTransactions,
} from "../../Redux/actions/accounting";
import Zoom from "@material-ui/core/Zoom";
import Tooltip from "@material-ui/core/Tooltip";
import Fab from "@material-ui/core/Fab";
import VisibilityIcon from "@material-ui/icons/Visibility";
import withdrawIconAA from "../../graphics/withdrawIconAA.svg";
import cashIconW from "../../graphics/cashIconW.svg";
import cashIconD from "../../graphics/cashIconD.svg";
import cashIconA from "../../graphics/cashIconA.svg";
import cashIconAA from "../../graphics/cashIconAA.svg";
import cardIconW from "../../graphics/cardIconW.svg";
import cardIconD from "../../graphics/cardIconD.svg";
import cardIconA from "../../graphics/cardIconA.svg";
import cardIconAA from "../../graphics/cardIconAA.svg";
import transferIconMinus from "../../graphics/transferIconMinus.svg";
import transferIconMinusW from "../../graphics/transferIconMinusW.svg";
import transferIconMinusD from "../../graphics/transferIconMinusD.svg";
import transferIconMinusAA from "../../graphics/transferIconMinusAA.svg";
import transferIconPlus from "../../graphics/transferIconPlus.svg";
import transferIconPlusD from "../../graphics/transferIconPlusD.svg";
import transferIconPlusW from "../../graphics/transferIconPlusW.svg";
import transferIconPlusAA from "../../graphics/transferIconPlusAA.svg";
import returnIcon from "../../graphics/returnIcon.svg";
import returnIconD from "../../graphics/returnIconD.svg";
import returnIconW from "../../graphics/returnIconW.svg";
import returnIconAA from "../../graphics/returnIconAA.svg";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import CustomFileInput from "components/CustomFileInput/CustomFileInput.js";
import AttachFile from "@material-ui/icons/AttachFile";
import Button from "@material-ui/core/Button";
import {
  getFormattedDate,
  getFormattedDateTime,
} from "../../common/commonFunctions";
import GetAppIcon from "@material-ui/icons/GetApp";

import ownCashIconW from "../../graphics/ownCashIconW.svg";
import ownCashIconD from "../../graphics/ownCashIconD.svg";
import ownCashIconA from "../../graphics/ownCashIconA.svg";
import ownCashIconAA from "../../graphics/ownCashIconAA.svg";

var ocak = <img style={{ height: "50px" }} src={ownCashIconW} alt="ocak"></img>;
var ocod = <img style={{ height: "50px" }} src={ownCashIconD} alt="ocod"></img>;
var ocar = <img style={{ height: "50px" }} src={ownCashIconA} alt="ocar"></img>;
var ocro = <img style={{ height: "50px" }} src={ownCashIconAA} alt="ocro"></img>;

var wi = <img style={{ height: "50px" }} src={withdrawIconAA} alt="wi"></img>;
var cpak = <img style={{ height: "50px" }} src={cardIconW} alt="cpak"></img>;
var cpod = <img style={{ height: "50px" }} src={cardIconD} alt="cpod"></img>;
var cpar = <img style={{ height: "50px" }} src={cardIconA} alt="cpar"></img>;
var cpro = <img style={{ height: "50px" }} src={cardIconAA} alt="cpro"></img>;
var chak = <img style={{ height: "50px" }} src={cashIconW} alt="chak"></img>;
var chod = <img style={{ height: "50px" }} src={cashIconD} alt="chod"></img>;
var char = <img style={{ height: "50px" }} src={cashIconA} alt="char"></img>;
var chro = <img style={{ height: "50px" }} src={cashIconAA} alt="chro"></img>;
var pbap = (
  <img style={{ height: "50px" }} src={transferIconPlusW} alt="pbap"></img>
);
var pbpc = (
  <img style={{ height: "50px" }} src={transferIconPlusAA} alt="pbpc"></img>
);
var dtap = (
  <img style={{ height: "50px" }} src={transferIconMinusW} alt="dtap"></img>
);
var dtpc = (
  <img style={{ height: "50px" }} src={transferIconMinusAA} alt="dtpc"></img>
);
var rtap = <img style={{ height: "50px" }} src={returnIconW} alt="rtap"></img>;
var rtpc = <img style={{ height: "50px" }} src={returnIconAA} alt="rtpc"></img>;

var border = (
  <div style={{ borderBottom: "1px solid #999999", width: "100%" }} />
);

export class EmployeeTransactionsView extends Component {
  state = {
    dialogAddWithdrawTransaction: false,
    dialogAddCardTransaction: false,
    dialogAddCashTransaction: false,
    today: new Date(),
    selectedCurrency: null,
    isTransactionChecked: false,
    checkedTransactionPk: null,
    dialogOpen: false,
    dialogAttachFile: false,
    invoice: null,
    view: "employeeView",
  };

  static propTypes = {
    auth: PropTypes.object.isRequired,
    balanceEUR: PropTypes.object.isRequired,
    balancePLN: PropTypes.object.isRequired,
    balanceSEK: PropTypes.object.isRequired,
    balanceNOK: PropTypes.object.isRequired,
    getTransactions: PropTypes.func.isRequired,
    getTransactionDetails: PropTypes.func.isRequired,
    clearTransactions: PropTypes.func.isRequired,
    patchTransactionDetails: PropTypes.func.isRequired,
    transactionDetails: PropTypes.object.isRequired,
    queryInProgress_tr_details: PropTypes.bool.isRequired,
  };

  componentDidMount() {
    this.props.clearTransactions();

    this.setState({
      selectedCurrency: this.props.auth.main_profile.accountingSelectedCurrency,
    });

    const thisYear = this.props.auth.main_profile.accountingThisYearView.toString();
    const thisYearFormatted = thisYear.charAt(0).toUpperCase() + thisYear.slice(1);

    this.props.getTransactions(
      this.props.auth.user.id,
      "eur",
      thisYearFormatted
    );
    this.props.getTransactions(
      this.props.auth.user.id,
      "pln",
      thisYearFormatted
    );
    this.props.getTransactions(
      this.props.auth.user.id,
      "sek",
      thisYearFormatted
    );
    this.props.getTransactions(
      this.props.auth.user.id,
      "nok",
      thisYearFormatted
    );
  }

  componentDidUpdate(prevProps) {
    if (this.props.auth.main_profile.defaultCompany != prevProps.auth.main_profile.defaultCompany) {
      this.props.clearTransactions();

      this.setState({
        selectedCurrency: this.props.auth.main_profile.accountingSelectedCurrency,
      });

      const thisYear = this.props.auth.main_profile.accountingThisYearView.toString();
      const thisYearFormatted = thisYear.charAt(0).toUpperCase() + thisYear.slice(1);

      this.props.getTransactions(
        this.props.auth.user.id,
        "eur",
        thisYearFormatted
      );
      this.props.getTransactions(
        this.props.auth.user.id,
        "pln",
        thisYearFormatted
      );
      this.props.getTransactions(
        this.props.auth.user.id,
        "sek",
        thisYearFormatted
      );
      this.props.getTransactions(
        this.props.auth.user.id,
        "nok",
        thisYearFormatted
      );
    }
  }

  handleCloseDialogAddWithdrawTransaction = () => {
    this.setState({
      dialogAddWithdrawTransaction: false,
    });
  };

  handleOpenDialogAddWithdrawTransaction = () => {
    this.setState({
      dialogAddWithdrawTransaction: true,
    });
  };

  handleCloseDialogAddCashTransaction = () => {
    this.setState({
      dialogAddCashTransaction: false,
    });
  };

  handleOpenDialogAddCashTransaction = () => {
    this.setState({
      dialogAddCashTransaction: true,
    });
  };

  handleCloseDialogAddCardTransaction = () => {
    this.setState({
      dialogAddCardTransaction: false,
    });
  };

  handleOpenDialogAddCardTransaction = () => {
    this.setState({
      dialogAddCardTransaction: true,
    });
  };

  handleChangeCurrency = (currency) => {
    this.setState({
      selectedCurrency: currency,
    });
  };

  handleChecked = (isChecked, transactionpk) => {
    this.setState({
      isTransactionChecked: isChecked,
      checkedTransactionPk: transactionpk,
    });

    if (isChecked === true) {
      this.props.getTransactionDetails(transactionpk);
    }
  };

  handleClickPreview = (event) => {
    this.setState({
      dialogOpen: true,
    });
  };

  handleClickCloseDialog = () => {
    this.setState({
      dialogOpen: false,
    });
  };

  triggerAttachInvoice = () => {
    this.setState({
      dialogAttachFile: true,
    });
  };

  handleCloseDialogAttachFile = () => {
    this.setState({
      dialogAttachFile: false,
    });
  };

  updateAttachInvoiceFilename = (inputFiles, inputFileNames) => {
    this.setState({
      invoice: inputFiles[0],
    });
  };

  handleAttachInvoice = () => {
    const body = new FormData();
    const invoiceUploadDate = getFormattedDate(new Date());
    body.append("invoice", this.state.invoice);
    body.append("invoiceUploadDate", invoiceUploadDate);
    body.append("transactionStatus", 'ak');

    this.props.patchTransactionDetails(
      this.state.checkedTransactionPk,
      body,
      "attachinvoice"
    );
    this.setState({
      dialogAttachFile: false,
      invoice: null,
    });
  };

  handleDownloadInvoice = () => {
    const file = this.props.transactionDetails.invoice;
    window.location.href = file;
  };

  render() {
    const {
      classes,
      auth,
      queryInProgress_tr_details,
      transactionDetails,
    } = this.props;
    const {
      isTransactionChecked,
      today,
      selectedCurrency,
      dialogOpen,
      dialogAttachFile,
      invoice,
      view,
    } = this.state;

    const infoThisYear = (
      <FormHelperText style={{ marginBottom: "30px" }}>
        Dane zaprezentowane poniżej dotycza okresu rozliczeniowego{" "}
        <b>
          01-{today.getFullYear()} do 12-{today.getFullYear()}
        </b>
        , zgodnie z filtrem ustawionym w zakładce Moje Saldo.
      </FormHelperText>
    );

    const infoPrevYear = (
      <FormHelperText style={{ marginBottom: "30px" }}>
        Dane zaprezentowane poniżej dotycza okresu rozliczeniowego{" "}
        <b>
          01-{today.getFullYear() - 1} do 12-{today.getFullYear() - 1}
        </b>
        , zgodnie z filtrem ustawionym w zakładce Moje Saldo.
      </FormHelperText>
    );

    const previewButtonDi = (
      <Fab
        disabled
        color="inherit"
        className={classes.fabblue}
        size="small"
        aria-label="scroll back to top"
      >
        <VisibilityIcon />
      </Fab>
    );

    const previewButtonEn = (
      <Fab
        color="inherit"
        className={classes.fabblue}
        size="small"
        aria-label="scroll back to top"
      >
        <VisibilityIcon />
      </Fab>
    );

    const attachButtonDi = (
      <Fab
        disabled
        size="small"
        aria-label="scroll back to top"
        className={classes.fabgreen}
      >
        <AttachFileIcon />
      </Fab>
    );

    const attachButtonEn = (
      <Fab
        size="small"
        aria-label="scroll back to top"
        className={classes.fabgreen}
      >
        <AttachFileIcon />
      </Fab>
    );

    const attachInvoiceButtonEn = (
      <Button
        variant="contained"
        color="inherit"
        size="large"
        onClick={this.handleAttachInvoice}
        style={{ marginTop: "20px" }}
        className={classes.buttonAccept}
      >
        Załącz
      </Button>
    );

    const attachInvoiceButtonDi = (
      <Button
        disabled
        variant="contained"
        color="inherit"
        size="large"
        style={{ marginTop: "20px" }}
        className={classes.buttonAccept}
      >
        Załącz
      </Button>
    );

    var transactionContent = null;
    var msspecificContent = null;
    var wpspecificContent = null;
    var esspecificContent = null;
    var purchaseListContent = null;
    var monitspecificContent = null;
    var invoiceLink = null;

    if (
      transactionDetails.pk !== undefined &&
      transactionDetails.monitDate &&
      !queryInProgress_tr_details
    ) {
      const datetime = new Date(transactionDetails.monitDate);
      const datetimeFormatted = getFormattedDateTime(datetime);

      monitspecificContent = (
        <Fragment>
          <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
            <Typography className={classes.textDescription7}>
              Treść monitu
            </Typography>
            <Typography className={classes.textDescription8}>
              {transactionDetails.monitMessage}
            </Typography>
          </Grid>
          <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
            <Typography className={classes.textDescription7}>
              Autor monitu
            </Typography>
            <Typography className={classes.textDescription6}>
              {transactionDetails.monitAuthor}
            </Typography>
          </Grid>
          <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
            <Typography className={classes.textDescription7}>
              Data monitu
            </Typography>
            <Typography className={classes.textDescription6}>
              {datetimeFormatted}
            </Typography>
          </Grid>
          <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
            <Typography className={classes.textDescription7}>
              Numer telefonu na który wysłano SMS
            </Typography>
            <Typography className={classes.textDescription6}>
              {transactionDetails.monitSendNumber}
            </Typography>
          </Grid>
        </Fragment>
      );
    }

    if (transactionDetails.pk !== undefined && !queryInProgress_tr_details) {
      switch (transactionDetails.transactionType) {
        case "wi":
          transactionContent = (
            <Fragment>
              <Typography style={{ marginTop: "30px" }}></Typography>
              <Grid container className={classes.root} spacing={2}>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={1}>
                  {wi}
                </Grid>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={11}>
                  <Typography className={classes.textDescription5}>
                    {transactionDetails.bookDate}
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.transactionDescription}
                  </Typography>
                </Grid>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textName}>
                    {transactionDetails.value +
                      " " +
                      transactionDetails.currency_description}
                  </Typography>
                </Grid>
                <div className={classes.border} />
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    typ transakcji
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.transactionType_description}
                  </Typography>
                </Grid>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    projekt
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.project.name}
                  </Typography>
                </Grid>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    status
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.transactionStatus_description}
                  </Typography>
                </Grid>
                <div className={classes.border} />
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    waluta
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.currency_description}
                  </Typography>
                </Grid>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    kwota
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.value}
                  </Typography>
                </Grid>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    numer karty płatniczej
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.card}
                  </Typography>
                </Grid>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    data transakcji
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.transactionDate}
                  </Typography>
                </Grid>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    data zarejestrowania transakcji
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.createDate}
                  </Typography>
                </Grid>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    data księgowania
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.bookDate}
                  </Typography>
                </Grid>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    tytułem
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.transactionDescription}
                  </Typography>
                </Grid>
              </Grid>
            </Fragment>
          );
          break;
        case "cp":
          purchaseListContent = (
            <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
              <Typography className={classes.textDescription7}>
                lista zakupów
              </Typography>
              <Typography className={classes.textDescription6}>
                {transactionDetails.purchaseList}
              </Typography>
            </Grid>
          );
          msspecificContent = (
            <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
              <Typography className={classes.textDescription7}>
                identyfikator stacji
              </Typography>
              <Typography className={classes.textDescription6}>
                {transactionDetails.baseStationIdentifier}
              </Typography>
            </Grid>
          );
          if (transactionDetails.car != null) {
            esspecificContent = (
              <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                <Typography className={classes.textDescription7}>
                  Numer rejestracyjny
                </Typography>
                <Typography className={classes.textDescription6}>
                  {transactionDetails.car.regNumber}
                </Typography>
              </Grid>
            );
          }
          wpspecificContent = (
            <Fragment>
              <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                <Typography className={classes.textDescription7}>
                  dla pracownika:
                </Typography>
                <Typography className={classes.textDescription6}>
                  {(transactionDetails.transactionEmployee == '' || transactionDetails.transactionEmployee == null) ? "nie podano" : transactionDetails.transactionEmployee}
                </Typography>
              </Grid>
              <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                <Typography className={classes.textDescription7}>
                  przypisać na stan Środków Trwałych
                </Typography>
                <Typography className={classes.textDescription6}>
                  {transactionDetails.assignAssetRequestFlag ? "tak" : "nie"}
                </Typography>
              </Grid>
              <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                <Typography className={classes.textDescription7}>
                  przypisano na stan Środków Trwałych
                </Typography>
                <Typography className={classes.textDescription6}>
                  {transactionDetails.assetAssignedFlag ? "tak" : "nie"}
                </Typography>
              </Grid>
            </Fragment>
          );
          invoiceLink = (
            <IconButton
              style={{ position: "relative", top: "-3px" }}
              aria-label="delete"
              onClick={this.handleDownloadInvoice}
            >
              <GetAppIcon />
            </IconButton>
          );
          transactionContent = (
            <Fragment>
              <Typography style={{ marginTop: "30px" }}></Typography>
              <Grid container className={classes.root} spacing={2}>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={1}>
                  {eval(
                    transactionDetails.transactionType +
                    transactionDetails.transactionStatus
                  )}
                </Grid>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={11}>
                  <Typography className={classes.textDescription5}>
                    {transactionDetails.bookDate}
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.transactionDescription}
                  </Typography>
                </Grid>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textName}>
                    {transactionDetails.value +
                      " " +
                      transactionDetails.currency_description}
                  </Typography>
                </Grid>
                <div className={classes.border} />
                {transactionDetails.monitDate ? monitspecificContent : ""}
                {transactionDetails.monitDate ? border : ""}
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    typ transakcji
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.transactionType_description}
                  </Typography>
                </Grid>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    projekt
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.project.name}
                  </Typography>
                </Grid>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    kategoria transakcji
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.transactionCategory_description}
                  </Typography>
                </Grid>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    status
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.transactionStatus_description}
                  </Typography>
                </Grid>
                <div className={classes.border} />
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    waluta
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.currency_description}
                  </Typography>
                </Grid>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    kwota
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.value}
                  </Typography>
                </Grid>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    numer karty płatniczej
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.card}
                  </Typography>
                </Grid>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    data transakcji
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.transactionDate}
                  </Typography>
                </Grid>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    data zarejestrowania transakcji
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.createDate}
                  </Typography>
                </Grid>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    data księgowania
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.bookDate}
                  </Typography>
                </Grid>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    tytułem
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.transactionDescription}
                  </Typography>
                </Grid>
                {purchaseListContent}
                {transactionDetails.transactionCategory === "es"
                  ? esspecificContent
                  : ""}
                {transactionDetails.transactionCategory === "ms"
                  ? msspecificContent
                  : ""}
                <div className={classes.border} />
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    właścicel budżetu
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.costsOwner === null
                      ? "-"
                      : transactionDetails.costsOwner}
                  </Typography>
                </Grid>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    komentarz właścicela budżetu
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.costsOwnerComment === null
                      ? "-"
                      : transactionDetails.costsOwnerComment}
                  </Typography>
                </Grid>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    data akceptacji kosztów
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.costsOwnerCommentDate === null
                      ? "-"
                      : transactionDetails.costsOwnerCommentDate}
                  </Typography>
                </Grid>
                <div className={classes.border} />
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    rozliczający
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.clearanceOwner === null
                      ? "-"
                      : transactionDetails.clearanceOwner}
                  </Typography>
                </Grid>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    komentarz rozliczającego
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.clearanceOwnerComment === null
                      ? "-"
                      : transactionDetails.clearanceOwnerComment}
                  </Typography>
                </Grid>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    data rozliczenia
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.clearanceOwnerCommentDate === null
                      ? "-"
                      : transactionDetails.clearanceOwnerCommentDate}
                  </Typography>
                </Grid>
                <div className={classes.border} />
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    faktura
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.invoice === null ? "-" : invoiceLink}
                  </Typography>
                </Grid>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    data zamieszczenia faktury
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.invoiceUploadDate === null
                      ? "-"
                      : transactionDetails.invoiceUploadDate}
                  </Typography>
                </Grid>
                {transactionDetails.transactionCategory === "wp" ? border : ""}
                {transactionDetails.transactionCategory === "wp"
                  ? wpspecificContent
                  : ""}
              </Grid>
            </Fragment>
          );
          break;
        case "ch":
          purchaseListContent = (
            <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
              <Typography className={classes.textDescription7}>
                lista zakupów
              </Typography>
              <Typography className={classes.textDescription6}>
                {transactionDetails.purchaseList}
              </Typography>
            </Grid>
          );
          msspecificContent = (
            <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
              <Typography className={classes.textDescription7}>
                identyfikator stacji
              </Typography>
              <Typography className={classes.textDescription6}>
                {transactionDetails.baseStationIdentifier}
              </Typography>
            </Grid>
          );
          if (transactionDetails.car != null) {
            esspecificContent = (
              <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                <Typography className={classes.textDescription7}>
                  Numer rejestracyjny
                </Typography>
                <Typography className={classes.textDescription6}>
                  {transactionDetails.car.regNumber}
                </Typography>
              </Grid>
            );
          }
          wpspecificContent = (
            <Fragment>
              <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                <Typography className={classes.textDescription7}>
                  dla pracownika:
                </Typography>
                <Typography className={classes.textDescription6}>
                  {(transactionDetails.transactionEmployee == '' || transactionDetails.transactionEmployee == null) ? "nie podano" : transactionDetails.transactionEmployee}
                </Typography>
              </Grid>
              <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                <Typography className={classes.textDescription7}>
                  przypisać na stan Środków Trwałych
                </Typography>
                <Typography className={classes.textDescription6}>
                  {transactionDetails.assignAssetRequestFlag ? "tak" : "nie"}
                </Typography>
              </Grid>
              <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                <Typography className={classes.textDescription7}>
                  przypisano na stan Środków Trwałych
                </Typography>
                <Typography className={classes.textDescription6}>
                  {transactionDetails.assetAssignedFlag ? "tak" : "nie"}
                </Typography>
              </Grid>
            </Fragment>
          );
          invoiceLink = (
            <IconButton
              style={{ position: "relative", top: "-3px" }}
              aria-label="delete"
              onClick={this.handleDownloadInvoice}
            >
              <GetAppIcon />
            </IconButton>
          );
          transactionContent = (
            <Fragment>
              <Typography style={{ marginTop: "30px" }}></Typography>
              <Grid container className={classes.root} spacing={2}>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={1}>
                  {eval(
                    transactionDetails.transactionType +
                    transactionDetails.transactionStatus
                  )}
                </Grid>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={11}>
                  <Typography className={classes.textDescription5}>
                    {transactionDetails.bookDate}
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.transactionDescription}
                  </Typography>
                </Grid>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textName}>
                    {transactionDetails.value +
                      " " +
                      transactionDetails.currency_description}
                  </Typography>
                </Grid>
                <div className={classes.border} />
                {transactionDetails.monitDate ? monitspecificContent : ""}
                {transactionDetails.monitDate ? border : ""}
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    typ transakcji
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.transactionType_description}
                  </Typography>
                </Grid>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    projekt
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.project.name}
                  </Typography>
                </Grid>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    kategoria transakcji
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.transactionCategory_description}
                  </Typography>
                </Grid>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    status
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.transactionStatus_description}
                  </Typography>
                </Grid>
                <div className={classes.border} />
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    waluta
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.currency_description}
                  </Typography>
                </Grid>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    kwota
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.value}
                  </Typography>
                </Grid>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    data transakcji
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.transactionDate}
                  </Typography>
                </Grid>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    data zarejestrowania transakcji
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.createDate}
                  </Typography>
                </Grid>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    data księgowania
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.bookDate}
                  </Typography>
                </Grid>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    tytułem
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.transactionDescription}
                  </Typography>
                </Grid>
                {purchaseListContent}
                {transactionDetails.transactionCategory === "es"
                  ? esspecificContent
                  : ""}
                {transactionDetails.transactionCategory === "ms"
                  ? msspecificContent
                  : ""}
                <div className={classes.border} />
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    właścicel budżetu
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.costsOwner === null
                      ? "-"
                      : transactionDetails.costsOwner}
                  </Typography>
                </Grid>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    komentarz właścicela budżetu
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.costsOwnerComment === null
                      ? "-"
                      : transactionDetails.costsOwnerComment}
                  </Typography>
                </Grid>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    data akceptacji kosztów
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.costsOwnerCommentDate === null
                      ? "-"
                      : transactionDetails.costsOwnerCommentDate}
                  </Typography>
                </Grid>
                <div className={classes.border} />
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    rozliczający
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.clearanceOwner === null
                      ? "-"
                      : transactionDetails.clearanceOwner}
                  </Typography>
                </Grid>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    komentarz rozliczającego
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.clearanceOwnerComment === null
                      ? "-"
                      : transactionDetails.clearanceOwnerComment}
                  </Typography>
                </Grid>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    data rozliczenia
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.clearanceOwnerCommentDate === null
                      ? "-"
                      : transactionDetails.clearanceOwnerCommentDate}
                  </Typography>
                </Grid>
                <div className={classes.border} />
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    faktura
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.invoice === null ? "-" : invoiceLink}
                  </Typography>
                </Grid>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    data zamieszczenia faktury
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.invoiceUploadDate === null
                      ? "-"
                      : transactionDetails.invoiceUploadDate}
                  </Typography>
                </Grid>
                {transactionDetails.transactionCategory === "wp" ? border : ""}
                {transactionDetails.transactionCategory === "wp"
                  ? wpspecificContent
                  : ""}
              </Grid>
            </Fragment>
          );
          break;
        case "oc":
          purchaseListContent = (
            <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
              <Typography className={classes.textDescription7}>
                lista zakupów
              </Typography>
              <Typography className={classes.textDescription6}>
                {transactionDetails.purchaseList}
              </Typography>
            </Grid>
          );
          msspecificContent = (
            <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
              <Typography className={classes.textDescription7}>
                identyfikator stacji
              </Typography>
              <Typography className={classes.textDescription6}>
                {transactionDetails.baseStationIdentifier}
              </Typography>
            </Grid>
          );
          if (transactionDetails.car != null) {
            esspecificContent = (
              <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                <Typography className={classes.textDescription7}>
                  Numer rejestracyjny
                </Typography>
                <Typography className={classes.textDescription6}>
                  {transactionDetails.car.regNumber}
                </Typography>
              </Grid>
            );
          }
          wpspecificContent = (
            <Fragment>
              <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                <Typography className={classes.textDescription7}>
                  dla pracownika:
                </Typography>
                <Typography className={classes.textDescription6}>
                  {(transactionDetails.transactionEmployee == '' || transactionDetails.transactionEmployee == null) ? "nie podano" : transactionDetails.transactionEmployee}
                </Typography>
              </Grid>
              <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                <Typography className={classes.textDescription7}>
                  przypisać na stan Środków Trwałych
                </Typography>
                <Typography className={classes.textDescription6}>
                  {transactionDetails.assignAssetRequestFlag ? "tak" : "nie"}
                </Typography>
              </Grid>
              <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                <Typography className={classes.textDescription7}>
                  przypisano na stan Środków Trwałych
                </Typography>
                <Typography className={classes.textDescription6}>
                  {transactionDetails.assetAssignedFlag ? "tak" : "nie"}
                </Typography>
              </Grid>
            </Fragment>
          );
          invoiceLink = (
            <IconButton
              style={{ position: "relative", top: "-3px" }}
              aria-label="delete"
              onClick={this.handleDownloadInvoice}
            >
              <GetAppIcon />
            </IconButton>
          );
          transactionContent = (
            <Fragment>
              <Typography style={{ marginTop: "30px" }}></Typography>
              <Grid container className={classes.root} spacing={2}>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={1}>
                  {eval(
                    transactionDetails.transactionType +
                    transactionDetails.transactionStatus
                  )}
                </Grid>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={11}>
                  <Typography className={classes.textDescription5}>
                    {transactionDetails.bookDate}
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.transactionDescription}
                  </Typography>
                </Grid>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textName}>
                    {transactionDetails.value +
                      " " +
                      transactionDetails.currency_description}
                  </Typography>
                </Grid>
                <div className={classes.border} />
                {transactionDetails.monitDate ? monitspecificContent : ""}
                {transactionDetails.monitDate ? border : ""}
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    typ transakcji
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.transactionType_description}
                  </Typography>
                </Grid>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    projekt
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.project.name}
                  </Typography>
                </Grid>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    kategoria transakcji
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.transactionCategory_description}
                  </Typography>
                </Grid>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    status
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.transactionStatus_description}
                  </Typography>
                </Grid>
                <div className={classes.border} />
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    waluta
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.currency_description}
                  </Typography>
                </Grid>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    kwota
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.value}
                  </Typography>
                </Grid>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    data transakcji
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.transactionDate}
                  </Typography>
                </Grid>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    data zarejestrowania transakcji
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.createDate}
                  </Typography>
                </Grid>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    data księgowania
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.bookDate}
                  </Typography>
                </Grid>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    tytułem
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.transactionDescription}
                  </Typography>
                </Grid>
                {purchaseListContent}
                {transactionDetails.transactionCategory === "es"
                  ? esspecificContent
                  : ""}
                {transactionDetails.transactionCategory === "ms"
                  ? msspecificContent
                  : ""}
                <div className={classes.border} />
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    właścicel budżetu
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.costsOwner === null
                      ? "-"
                      : transactionDetails.costsOwner}
                  </Typography>
                </Grid>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    komentarz właścicela budżetu
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.costsOwnerComment === null
                      ? "-"
                      : transactionDetails.costsOwnerComment}
                  </Typography>
                </Grid>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    data akceptacji kosztów
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.costsOwnerCommentDate === null
                      ? "-"
                      : transactionDetails.costsOwnerCommentDate}
                  </Typography>
                </Grid>
                <div className={classes.border} />
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    rozliczający
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.clearanceOwner === null
                      ? "-"
                      : transactionDetails.clearanceOwner}
                  </Typography>
                </Grid>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    komentarz rozliczającego
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.clearanceOwnerComment === null
                      ? "-"
                      : transactionDetails.clearanceOwnerComment}
                  </Typography>
                </Grid>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    data rozliczenia
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.clearanceOwnerCommentDate === null
                      ? "-"
                      : transactionDetails.clearanceOwnerCommentDate}
                  </Typography>
                </Grid>
                <div className={classes.border} />
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    faktura
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.invoice === null ? "-" : invoiceLink}
                  </Typography>
                </Grid>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    data zamieszczenia faktury
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.invoiceUploadDate === null
                      ? "-"
                      : transactionDetails.invoiceUploadDate}
                  </Typography>
                </Grid>
                {transactionDetails.transactionCategory === "wp" ? border : ""}
                {transactionDetails.transactionCategory === "wp"
                  ? wpspecificContent
                  : ""}
              </Grid>
            </Fragment>
          );
          break;
        case "pb":
          invoiceLink = (
            <IconButton
              style={{ position: "relative", top: "-3px" }}
              aria-label="delete"
              onClick={this.handleDownloadInvoice}
            >
              <GetAppIcon />
            </IconButton>
          );
          transactionContent = (
            <Fragment>
              <Typography style={{ marginTop: "30px" }}></Typography>
              <Grid container className={classes.root} spacing={2}>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={1}>
                  {eval(
                    transactionDetails.transactionType +
                    transactionDetails.transactionStatus
                  )}
                </Grid>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={11}>
                  <Typography className={classes.textDescription5}>
                    {transactionDetails.bookDate}
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.transactionDescription}
                  </Typography>
                </Grid>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textName}>
                    {transactionDetails.value +
                      " " +
                      transactionDetails.currency_description}
                  </Typography>
                </Grid>
                <div className={classes.border} />
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    typ transakcji
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.transactionType_description}
                  </Typography>
                </Grid>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    projekt
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.project.name}
                  </Typography>
                </Grid>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    kategoria transakcji
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.transactionCategory_description}
                  </Typography>
                </Grid>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    status
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.transactionStatus_description}
                  </Typography>
                </Grid>
                <div className={classes.border} />
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    waluta
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.currency_description}
                  </Typography>
                </Grid>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    kwota
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.value}
                  </Typography>
                </Grid>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    data zarejestrowania transakcji
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.createDate}
                  </Typography>
                </Grid>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    tytułem
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.transactionDescription}
                  </Typography>
                </Grid>
                <div className={classes.border} />
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    rozliczający
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.clearanceOwner === null
                      ? "-"
                      : transactionDetails.clearanceOwner}
                  </Typography>
                </Grid>
                <div className={classes.border} />
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    dokonujący wypłaty
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.costsOwner === null
                      ? "-"
                      : transactionDetails.costsOwner}
                  </Typography>
                </Grid>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    komentarz wypłacającego
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.costsOwnerComment === null
                      ? "-"
                      : transactionDetails.costsOwnerComment}
                  </Typography>
                </Grid>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    data wypłaty
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.payDate === null
                      ? "-"
                      : transactionDetails.payDate}
                  </Typography>
                </Grid>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    potwierdzenie wypłaty
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.invoice === null ? "-" : invoiceLink}
                  </Typography>
                </Grid>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    data zamieszczenia potwierdzenia
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.invoiceUploadDate === null
                      ? "-"
                      : transactionDetails.invoiceUploadDate}
                  </Typography>
                </Grid>
              </Grid>
            </Fragment>
          );
          break;
        case "dt":
          invoiceLink = (
            <IconButton
              style={{ position: "relative", top: "-3px" }}
              aria-label="delete"
              onClick={this.handleDownloadInvoice}
            >
              <GetAppIcon />
            </IconButton>
          );
          transactionContent = (
            <Fragment>
              <Typography style={{ marginTop: "30px" }}></Typography>
              <Grid container className={classes.root} spacing={2}>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={1}>
                  {eval(
                    transactionDetails.transactionType +
                    transactionDetails.transactionStatus
                  )}
                </Grid>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={11}>
                  <Typography className={classes.textDescription5}>
                    {transactionDetails.bookDate}
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.transactionDescription}
                  </Typography>
                </Grid>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textName}>
                    {transactionDetails.value +
                      " " +
                      transactionDetails.currency_description}
                  </Typography>
                </Grid>
                <div className={classes.border} />
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    typ transakcji
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.transactionType_description}
                  </Typography>
                </Grid>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    projekt
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.project.name}
                  </Typography>
                </Grid>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    kategoria transakcji
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.transactionCategory_description}
                  </Typography>
                </Grid>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    status
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.transactionStatus_description}
                  </Typography>
                </Grid>
                <div className={classes.border} />
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    waluta
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.currency_description}
                  </Typography>
                </Grid>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    kwota
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.value}
                  </Typography>
                </Grid>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    data zarejestrowania transakcji
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.createDate}
                  </Typography>
                </Grid>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    tytułem
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.transactionDescription}
                  </Typography>
                </Grid>
                <div className={classes.border} />
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    rozliczający
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.clearanceOwner === null
                      ? "-"
                      : transactionDetails.clearanceOwner}
                  </Typography>
                </Grid>
                <div className={classes.border} />
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    realizujący potrącenie
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.costsOwner === null
                      ? "-"
                      : transactionDetails.costsOwner}
                  </Typography>
                </Grid>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    komentarz realizującego potrącenie
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.costsOwnerComment === null
                      ? "-"
                      : transactionDetails.costsOwnerComment}
                  </Typography>
                </Grid>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    data potrącenia
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.payDate === null
                      ? "-"
                      : transactionDetails.payDate}
                  </Typography>
                </Grid>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    podstawa potrącenia
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.invoice === null ? "-" : invoiceLink}
                  </Typography>
                </Grid>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    data zamieszczenia podstawy
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.invoiceUploadDate === null
                      ? "-"
                      : transactionDetails.invoiceUploadDate}
                  </Typography>
                </Grid>
              </Grid>
            </Fragment>
          );
          break;
        case "rt":
          invoiceLink = (
            <IconButton
              style={{ position: "relative", top: "-3px" }}
              aria-label="delete"
              onClick={this.handleDownloadInvoice}
            >
              <GetAppIcon />
            </IconButton>
          );
          transactionContent = (
            <Fragment>
              <Typography style={{ marginTop: "30px" }}></Typography>
              <Grid container className={classes.root} spacing={2}>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={1}>
                  {eval(
                    transactionDetails.transactionType +
                    transactionDetails.transactionStatus
                  )}
                </Grid>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={11}>
                  <Typography className={classes.textDescription5}>
                    {transactionDetails.bookDate}
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.transactionDescription}
                  </Typography>
                </Grid>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textName}>
                    {transactionDetails.value +
                      " " +
                      transactionDetails.currency_description}
                  </Typography>
                </Grid>
                <div className={classes.border} />
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    typ transakcji
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.transactionType_description}
                  </Typography>
                </Grid>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    projekt
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.project.name}
                  </Typography>
                </Grid>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    kategoria transakcji
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.transactionCategory_description}
                  </Typography>
                </Grid>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    status
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.transactionStatus_description}
                  </Typography>
                </Grid>
                <div className={classes.border} />
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    waluta
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.currency_description}
                  </Typography>
                </Grid>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    kwota
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.value}
                  </Typography>
                </Grid>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    data zarejestrowania transakcji
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.createDate}
                  </Typography>
                </Grid>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    tytułem
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.transactionDescription}
                  </Typography>
                </Grid>
                <div className={classes.border} />
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    rozliczający
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.clearanceOwner === null
                      ? "-"
                      : transactionDetails.clearanceOwner}
                  </Typography>
                </Grid>
                <div className={classes.border} />
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    przyjmujący zwrot
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.costsOwner === null
                      ? "-"
                      : transactionDetails.costsOwner}
                  </Typography>
                </Grid>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    komentarz przyjmującego zwrot
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.costsOwnerComment === null
                      ? "-"
                      : transactionDetails.costsOwnerComment}
                  </Typography>
                </Grid>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    data zwrotu
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.payDate === null
                      ? "-"
                      : transactionDetails.payDate}
                  </Typography>
                </Grid>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    potwierdzenie zwrotu
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.invoice === null ? "-" : invoiceLink}
                  </Typography>
                </Grid>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    data zamieszczenia potwierdzenia
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.invoiceUploadDate === null
                      ? "-"
                      : transactionDetails.invoiceUploadDate}
                  </Typography>
                </Grid>
              </Grid>
            </Fragment>
          );
          break;
        default:
      }
    }

    return (
      <Fragment>
        <div className={classes.section}>
          <Box m={2}>
            {auth.main_profile.accountingThisYearView
              ? infoThisYear
              : infoPrevYear}
            <Grid container justify="flex-start" spacing={4}>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <BalanceCard
                  changeCurrency={this.handleChangeCurrency}
                  selectedCurrency={selectedCurrency}
                  view={view}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={8} lg={9}>
                <EmployeeOperationsList
                  checked={this.handleChecked}
                  selectedCurrency={selectedCurrency}
                />
              </Grid>
              {/*<Grid item xs={12} sm={12} md={12} lg={12}>
                <HelpCard />
            </Grid>*/}
            </Grid>
          </Box>
        </div>
        <Zoom in={isTransactionChecked}>
          <div
            onClick={this.handleClickPreview}
            role="presentation"
            className={classes.fb2}
          >
            <Tooltip title="Pokaż szczegóły" placement="left">
              {queryInProgress_tr_details ? previewButtonDi : previewButtonEn}
            </Tooltip>
          </div>
        </Zoom>
        <Zoom
          in={
            isTransactionChecked &&
            transactionDetails.transactionType !== "wi" &&
            transactionDetails.transactionType !== "pb" &&
            transactionDetails.transactionType !== "dt" &&
            transactionDetails.transactionType !== "rt"
          }
        >
          <div
            onClick={this.triggerAttachInvoice}
            role="presentation"
            className={classes.fb5}
          >
            <Tooltip title="Załącz fakturę" placement="left">
              {!queryInProgress_tr_details ? attachButtonEn : attachButtonDi}
            </Tooltip>
          </div>
        </Zoom>

        <Dialog
          fullScreen
          open={dialogOpen}
          onClose={this.handleClickCloseDialog}
          aria-labelledby="form-dialog-title"
        >
          <AppBar position="static" style={{ background: "#0071BC" }}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={this.handleClickCloseDialog}
                aria-label="close"
                className={classes.menuButton}
              >
                <CloseIcon />
              </IconButton>
              <Typography variant="h6" className={classes.title}>
                Szczegóły transakcji
              </Typography>
            </Toolbar>
          </AppBar>
          <DialogContent style={{ background: "#f5f5f5" }}>
            {transactionContent}
          </DialogContent>
        </Dialog>

        <Dialog
          fullScreen
          open={dialogAttachFile}
          onClose={this.handleCloseDialogAttachFile}
          aria-labelledby="form-dialog-title"
        >
          <AppBar position="static" style={{ background: "#0071BC" }}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={this.handleCloseDialogAttachFile}
                aria-label="close"
                className={classes.menuButton}
              >
                <CloseIcon />
              </IconButton>
              <Typography variant="h6" className={classes.title}>
                Załącz fakturę
              </Typography>
            </Toolbar>
          </AppBar>
          <DialogContent style={{ background: "#f5f5f5" }}>
            <CustomFileInput
              onChange={this.updateAttachInvoiceFilename}
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                placeholder: "wybierz plik ...",
              }}
              endButton={{
                buttonProps: {
                  round: true,
                  color: "primary",
                  justIcon: true,
                  fileButton: true,
                },
                icon: <AttachFile />,
              }}
            />
            {invoice ? attachInvoiceButtonEn : attachInvoiceButtonDi}
          </DialogContent>
        </Dialog>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  balanceEUR: state.accounting.balanceEUR,
  balancePLN: state.accounting.balancePLN,
  balanceSEK: state.accounting.balanceSEK,
  balanceNOK: state.accounting.balanceNOK,
  transactionDetails: state.accounting.transactionDetails,
  queryInProgress_tr_details: state.accounting.queryInProgress_tr_details,
});

export default connect(
  mapStateToProps,
  {
    getTransactions,
    getTransactionDetails,
    patchTransactionDetails,
    clearTransactions,
  }
)(withStyles(styles, { withTheme: true })(EmployeeTransactionsView));
