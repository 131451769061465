import React, { Fragment, Component } from "react";
import Box from "@material-ui/core/Box";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import AcceptanceCardPm from "../Common/AcceptanceCardPm";
import ClearanceCardPm from "../Common/ClearanceCardPm";
import NoInvoiceCardPm from "../Common/NoInvoiceCardPm";
import AddPaybackTransactionForm from "./AddPaybackTransactionForm";
import AddDeductTransactionForm from "./AddDeductTransactionForm";
import AddReturnTransactionForm from "./AddReturnTransactionForm";
import Grid from "@material-ui/core/Grid";
import { styles, materialTheme } from "../../common/radiolineStyles";
import Typography from "@material-ui/core/Typography";
import EmployeeOperationsList from "../Common/EmployeeOperationsList";
import BalanceCard from "../Employee/BalanceCard";
import { ThemeProvider } from "@material-ui/styles";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import CircularProgress from "@material-ui/core/CircularProgress";
import FormHelperText from "@material-ui/core/FormHelperText";
import Zoom from "@material-ui/core/Zoom";
import Tooltip from "@material-ui/core/Tooltip";
import Fab from "@material-ui/core/Fab";
import VisibilityIcon from "@material-ui/icons/Visibility";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import GetAppIcon from "@material-ui/icons/GetApp";
import DoneIcon from "@material-ui/icons/Done";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import CustomFileInput from "components/CustomFileInput/CustomFileInput.js";
import AttachFile from "@material-ui/icons/AttachFile";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import {
  getMonthNameAndYear,
  getLastDayOfPrevMonth,
} from "../../common/commonFunctions";
import {
  getTransactionsStat,
  getTransactions,
  getTransactionDetails,
  patchTransaction_admin,
  patchTransactionDetails,
  clearTransactions,
  getTransactionsByYear,
  changeTransactionBookDate,
} from "../../Redux/actions/accounting";
import {
  getUsersList,
  getUserDetails,
  clearUserDetails,
} from "../../Redux/actions/common";
import CommentIcon from "@material-ui/icons/Comment";
import {
  getFormattedDate,
  getFormattedDateTime,
  getFormattedDateTimeTimezone,
} from "../../common/commonFunctions";
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import { patchProfile } from "../../Redux/actions/auth";
import { RangeSliderUnclosed, RangeSlider } from "../Common/RangeSlider";
import cashIconW from "../../graphics/cashIconW.svg";
import cashIconD from "../../graphics/cashIconD.svg";
import cashIconA from "../../graphics/cashIconA.svg";
import cashIconAA from "../../graphics/cashIconAA.svg";
import cardIconW from "../../graphics/cardIconW.svg";
import cardIconD from "../../graphics/cardIconD.svg";
import cardIconA from "../../graphics/cardIconA.svg";
import cardIconAA from "../../graphics/cardIconAA.svg";
import transferIconMinus from "../../graphics/transferIconMinus.svg";
import transferIconMinusW from "../../graphics/transferIconMinusW.svg";
import transferIconMinusD from "../../graphics/transferIconMinusD.svg";
import transferIconMinusAA from "../../graphics/transferIconMinusAA.svg";
import transferIconPlus from "../../graphics/transferIconPlus.svg";
import transferIconPlusD from "../../graphics/transferIconPlusD.svg";
import transferIconPlusW from "../../graphics/transferIconPlusW.svg";
import transferIconPlusAA from "../../graphics/transferIconPlusAA.svg";
import returnIcon from "../../graphics/returnIcon.svg";
import returnIconD from "../../graphics/returnIconD.svg";
import returnIconW from "../../graphics/returnIconW.svg";
import returnIconAA from "../../graphics/returnIconAA.svg";
import withdrawIconAA from "../../graphics/withdrawIconAA.svg";

import Axios from "axios";
import fileDownload from "js-file-download";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";

import ownCashIconW from "../../graphics/ownCashIconW.svg";
import ownCashIconD from "../../graphics/ownCashIconD.svg";
import ownCashIconA from "../../graphics/ownCashIconA.svg";
import ownCashIconAA from "../../graphics/ownCashIconAA.svg";

var ocak = <img style={{ height: "50px" }} src={ownCashIconW} alt="ocak"></img>;
var ocod = <img style={{ height: "50px" }} src={ownCashIconD} alt="ocod"></img>;
var ocar = <img style={{ height: "50px" }} src={ownCashIconA} alt="ocar"></img>;
var ocro = <img style={{ height: "50px" }} src={ownCashIconAA} alt="ocro"></img>;

var wi = <img style={{ height: "50px" }} src={withdrawIconAA} alt="wi"></img>;
var cpak = <img style={{ height: "50px" }} src={cardIconW} alt="cpak"></img>;
var cpod = <img style={{ height: "50px" }} src={cardIconD} alt="cpod"></img>;
var cpar = <img style={{ height: "50px" }} src={cardIconA} alt="cpar"></img>;
var cpro = <img style={{ height: "50px" }} src={cardIconAA} alt="cpro"></img>;
var chak = <img style={{ height: "50px" }} src={cashIconW} alt="chak"></img>;
var chod = <img style={{ height: "50px" }} src={cashIconD} alt="chod"></img>;
var char = <img style={{ height: "50px" }} src={cashIconA} alt="char"></img>;
var chro = <img style={{ height: "50px" }} src={cashIconAA} alt="chro"></img>;
var pbap = (
  <img style={{ height: "50px" }} src={transferIconPlusW} alt="pbap"></img>
);
var pbpc = (
  <img style={{ height: "50px" }} src={transferIconPlusAA} alt="pbpc"></img>
);
var dtap = (
  <img style={{ height: "50px" }} src={transferIconMinusW} alt="dtap"></img>
);
var dtpc = (
  <img style={{ height: "50px" }} src={transferIconMinusAA} alt="dtpc"></img>
);
var rtap = <img style={{ height: "50px" }} src={returnIconW} alt="rtap"></img>;
var rtpc = <img style={{ height: "50px" }} src={returnIconAA} alt="rtpc"></img>;

var border = (
  <div style={{ borderBottom: "1px solid #999999", width: "100%" }} />
);

var today = new Date();
var toDate = getLastDayOfPrevMonth(today);

const CssTextField = withStyles({
  root: {
    width: 280,
    flexWrap: "wrap",
    marginBottom: "50px",

    "& label.Mui-focused": {
      color: "#0071bc",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#0071bc",
    },
  },
})(TextField);

export class ClearanceView extends Component {
  state = {
    open: false,
    newEmployeeSelected: null,
    selectedCurrency: this.props.auth.main_profile.accountingSelectedCurrencyClearanceView,
    thisYear: this.props.auth.main_profile.accountingThisYearClearanceView,
    selectedYear: (new Date()).getFullYear(),
    selectedType: "all",
    unclosed: false,
    isTransactionChecked: false,
    checkedTransactionPk: null,
    bookDate: new Date(),
    bookDateFormatted: getFormattedDate(new Date()),
    dialogOpen: false,
    dialogOpenDecline: false,
    dialogOpenAccept: false,
    dialogOpenPayback: false,
    dialogOpenDeduct: false,
    dialogOpenReturn: false,
    dialogOpenChangeBookDate: false,
    dialogNotify: false,
    dialogSaveButtonInProgress: false,
    clearanceOwnerComment: "",
    dialogAttachFile: false,
    invoice: null,
    monitMessage: null,
    monitAuthor: null,
    monitDate: null,
    view: "clearanceView",
  };

  static propTypes = {
    auth: PropTypes.object.isRequired,
    patchProfile: PropTypes.func.isRequired,
    usersList: PropTypes.array.isRequired,
    queryInProgress: PropTypes.bool.isRequired,
    dataChangeInProgress: PropTypes.bool.isRequired,
    getUsersList: PropTypes.func.isRequired,
    getUserDetails: PropTypes.func.isRequired,
    clearUserDetails: PropTypes.func.isRequired,
    getTransactionDetails: PropTypes.func.isRequired,
    clearTransactions: PropTypes.func.isRequired,
    patchTransaction_admin: PropTypes.func.isRequired,
    userDetails: PropTypes.object.isRequired,
    transactionDetails: PropTypes.object.isRequired,
    transactionsStat_ak: PropTypes.array.isRequired,
    transactionsStat_ar: PropTypes.array.isRequired,
    transactionsStat_noin: PropTypes.array.isRequired,
    queryInProgress_stat_ak: PropTypes.bool.isRequired,
    queryInProgress_stat_ar: PropTypes.bool.isRequired,
    queryInProgress_stat_noin: PropTypes.bool.isRequired,
    queryInProgress_tr_details: PropTypes.bool.isRequired,
    patchTransactionDetails: PropTypes.func.isRequired,
    changeTransactionBookDate: PropTypes.func.isRequired,
    //
    balanceEUR: PropTypes.object.isRequired,
    balancePLN: PropTypes.object.isRequired,
    balanceSEK: PropTypes.object.isRequired,
    balanceNOK: PropTypes.object.isRequired,
    getTransactions: PropTypes.func.isRequired,
    getTransactionsByYear: PropTypes.func.isRequired,
  };

  componentWillUnmount() {
    this.props.clearTransactions();
  }

  componentDidMount() {
    this.props.clearTransactions();
    this.props.clearUserDetails("s");
    this.props.getTransactionsStat("ak", toDate);
    this.props.getTransactionsStat("ar", toDate);
    this.props.getTransactionsStat("noin", toDate);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.userDetails.pk !== this.props.userDetails.pk) {
      if (this.props.userDetails.pk !== undefined) {
        this.setState({
          selectedCurrency: this.props.userDetails.accountingSelectedCurrencyClearanceView,
        });
        this.props.clearTransactions();
        const selectedYearString = this.state.selectedYear;

        this.props.getTransactionsByYear(
          this.props.userDetails.pk,
          "eur",
          selectedYearString
        );
        this.props.getTransactionsByYear(
          this.props.userDetails.pk,
          "pln",
          selectedYearString
        );
        this.props.getTransactionsByYear(
          this.props.userDetails.pk,
          "sek",
          selectedYearString
        );
        this.props.getTransactionsByYear(
          this.props.userDetails.pk,
          "nok",
          selectedYearString
        );
      } else {
        this.props.clearTransactions();
      }
    }

    if (
      prevProps.dataChangeInProgress === true &&
      this.props.dataChangeInProgress === false
    ) {
      this.setState({
        dialogOpenChangeBookDate: false,
      });
    }

    if (this.props.auth.main_profile.defaultCompany != prevProps.auth.main_profile.defaultCompany) {
      this.props.clearTransactions();
      this.props.clearUserDetails("s");
      this.props.getTransactionsStat("ak", toDate);
      this.props.getTransactionsStat("ar", toDate);
      this.props.getTransactionsStat("noin", toDate);
    }
  }

  handleTextList = (name) => ({ target: { value } }) => {
    this.setState({
      ...this.state,
      [name]: value,
    });
  };

  setOpen = () => {
    const open = this.state.open;
    if (open === false && this.props.usersList.length === 0) {
      this.props.getUsersList();
    }
    this.setState({
      open: !open,
    });
  };

  getSelected = (event, value) => {
    this.setState({
      newEmployeeSelected: value,
    });
    if (value !== null) {
      this.props.getUserDetails(value.id, "s");
    } else {
      this.props.clearUserDetails("s");
      this.setState({
        checkedTransactionPk: null,
        isTransactionChecked: false,
      });
    }
  };

  handlethisYearFilter = (event) => {
    const selectedYearString = event.target.value;
    var thisYear = false;

    if ((new Date()).getFullYear() == selectedYearString) {
      thisYear = true;
    }

    this.setState({
      thisYear: thisYear,
      selectedYear: event.target.value,
      checkedTransactionPk: null,
      isTransactionChecked: false,
    });
    this.props.getTransactionsByYear(this.props.userDetails.pk, "eur", selectedYearString);
    this.props.getTransactionsByYear(this.props.userDetails.pk, "pln", selectedYearString);
    this.props.getTransactionsByYear(this.props.userDetails.pk, "sek", selectedYearString);
    this.props.getTransactionsByYear(this.props.userDetails.pk, "nok", selectedYearString);
    //
    const profileBody = {
      accountingThisYearClearanceView: thisYear,
    };

    this.props.patchProfile(profileBody, this.props.auth.user.profile.pk);
  };

  handlethisTypeFilter = (event) => {
    this.setState({
      selectedType: event.target.value,
    });
  };

  handleUnclosedFilter = (unclosed) => {
    this.setState({
      unclosed: unclosed,
      checkedTransactionPk: null,
      isTransactionChecked: false,
    });
  };

  handleChangeCurrency = (currency) => {
    this.setState({
      selectedCurrency: currency,
    });
  };

  handleChecked = (isChecked, transactionpk) => {
    this.setState({
      isTransactionChecked: isChecked,
      checkedTransactionPk: transactionpk,
    });

    if (isChecked === true) {
      this.props.getTransactionDetails(transactionpk);
    }
  };

  handleClickPreview = () => {
    this.setState({
      dialogOpen: true,
    });
  };

  handleClickCloseDialog = () => {
    this.setState({
      dialogOpen: false,
    });
  };

  triggerDeclineClearance = () => {
    this.setState({
      dialogOpenDecline: true,
    });
  };

  handleCloseDeclineDialog = () => {
    this.setState({
      dialogOpenDecline: false,
    });
  };

  triggerAcceptClearance = () => {
    this.setState({
      dialogOpenAccept: true,
    });
  };

  handleCloseAcceptDialog = () => {
    this.setState({
      dialogOpenAccept: false,
    });
  };

  handleDeclineClearance = () => {
    const selectedCurrency = this.state.selectedCurrency;
    const checkedTransactionPk = this.state.checkedTransactionPk;
    //const transactionCategory = this.props.transactionDetails
    //  .transactionCategory;
    //transactionbody
    const transactionbody = new FormData();
    transactionbody.append("transactionStatus", "od");
    transactionbody.append(
      "clearanceOwnerComment",
      this.state.clearanceOwnerComment
    );
    transactionbody.append(
      "clearanceOwnerCommentDate",
      getFormattedDate(new Date())
    );
    transactionbody.append(
      "clearanceOwner",
      this.props.auth.user.first_name + " " + this.props.auth.user.last_name
    );

    //smsbody
    const smsbody = {
      recipient: this.props.userDetails.email,
      //sender: "FINANSE",
      module: "Finanse",
      topic: "Rozliczenie transakcji",
      message:
        "Użytkownik " +
        this.props.auth.user.first_name + " " + this.props.auth.user.last_name +
        " odrzucił rozliczenie twojej transakcji z dnia " +
        this.props.transactionDetails.bookDate +
        " na kwotę " +
        this.props.transactionDetails.value +
        this.props.transactionDetails.currency_description +
        ".",
      //owner: this.props.auth.user.id,
      //notificationType: "sms",
      //isDelivered: true,
    };

    this.setState({
      dialogOpenDecline: false,
      checkedTransactionPk: null,
      isTransactionChecked: false,
    });

    this.props.patchTransaction_admin(
      checkedTransactionPk,
      transactionbody,
      smsbody,
      selectedCurrency,
      "decline"
    );
  };

  handleAcceptClearance = () => {
    const selectedCurrency = this.state.selectedCurrency;
    const checkedTransactionPk = this.state.checkedTransactionPk;
    //const transactionCategory = this.props.transactionDetails
    //  .transactionCategory;
    //transactionbody
    const transactionbody = new FormData();
    transactionbody.append("transactionStatus", "ro");
    transactionbody.append(
      "clearanceOwnerComment",
      this.state.clearanceOwnerComment
    );
    transactionbody.append(
      "clearanceOwnerCommentDate",
      getFormattedDate(new Date())
    );
    transactionbody.append(
      "clearanceOwner",
      this.props.auth.user.first_name + " " + this.props.auth.user.last_name
    );

    //smsbody
    const smsbody = {
      recipient: this.props.userDetails.email,
      //sender: "FINANSE",
      module: "Finanse",
      topic: "Rozliczenie transakcji",
      message:
        "Użytkownik " +
        this.props.auth.user.first_name + " " + this.props.auth.user.last_name +
        " zaakceptował rozliczenie twojej transakcji z dnia " +
        this.props.transactionDetails.bookDate +
        " na kwotę " +
        this.props.transactionDetails.value +
        this.props.transactionDetails.currency_description +
        ".",
      //owner: this.props.auth.user.id,
      //notificationType: "sms",
      //isDelivered: true,
    };

    this.setState({
      dialogOpenAccept: false,
      checkedTransactionPk: null,
      isTransactionChecked: false,
    });

    this.props.patchTransaction_admin(
      checkedTransactionPk,
      transactionbody,
      smsbody,
      selectedCurrency,
      "accept"
    );
  };

  triggerDeduct = () => {
    this.setState({
      dialogOpenDeduct: true,
    });
  };

  triggerPayback = () => {
    this.setState({
      dialogOpenPayback: true,
    });
  };

  triggerReturn = () => {
    this.setState({
      dialogOpenReturn: true,
    });
  };

  handleCloseDialogPayback = () => {
    this.setState({
      dialogOpenPayback: false,
    });
  };

  handleCloseDialogDeduct = () => {
    this.setState({
      dialogOpenDeduct: false,
    });
  };

  handleCloseDialogReturn = () => {
    this.setState({
      dialogOpenReturn: false,
    });
  };

  triggerAttachInvoice = () => {
    this.setState({
      dialogAttachFile: true,
    });
  };

  handleCloseDialogAttachFile = () => {
    this.setState({
      dialogAttachFile: false,
    });
  };

  updateAttachInvoiceFilename = (inputFiles, inputFileNames) => {
    this.setState({
      invoice: inputFiles[0],
    });
  };

  handleAttachInvoice = () => {
    const body = new FormData();
    const invoiceUploadDate = getFormattedDate(new Date());
    body.append("invoice", this.state.invoice);
    body.append("invoiceUploadDate", invoiceUploadDate);
    body.append("transactionStatus", 'ak');

    this.props.patchTransactionDetails(
      this.state.checkedTransactionPk,
      body,
      "attachinvoice"
    );
    this.setState({
      dialogAttachFile: false,
      invoice: null,
    });
  };

  handleDownloadInvoice = () => {
    const fileurl = this.props.transactionDetails.invoice;

    //get filename
    //znajdz string ?AWSAccessKeyId

    const file = fileurl.substring(
      fileurl.lastIndexOf("invoices/") + 9,
      fileurl.lastIndexOf("?AWSAccessKeyId")
    );

    Axios.get(fileurl, {
      responseType: "blob",
    }).then((res) => {
      fileDownload(res.data, file);
    });
  };

  triggerNotifyUser = () => {
    this.setState({
      dialogNotify: true,
    });
  };

  handleCloseDialogNotify = () => {
    this.setState({
      dialogNotify: false,
    });
  };

  handleAddMonit = () => {
    const selectedCurrency = this.state.selectedCurrency;
    //Body transakcji
    const transactionbody = new FormData();

    const monitDate = getFormattedDateTimeTimezone(new Date());
    const monitMessage = this.state.monitMessage;
    const monitAuthor =
      this.props.auth.user.first_name + " " + this.props.auth.user.last_name;
    const monitSendNumber = this.props.userDetails.phone;

    transactionbody.append("monitDate", monitDate);
    transactionbody.append("monitMessage", monitMessage);
    transactionbody.append("monitAuthor", monitAuthor);
    transactionbody.append("monitSendNumber", monitSendNumber);

    //Body SMS
    const smsbody = {
      recipient: this.props.userDetails.email,
      //sender: "FINANSE",
      module: "Finanse",
      topic: "Monit",
      message:
        "Dot. trans. z dnia " +
        this.props.transactionDetails.bookDate +
        "/" +
        this.props.transactionDetails.value +
        this.props.transactionDetails.currency +
        ". " +
        this.state.monitMessage,
      //owner: this.props.auth.user.id,
      //notificationType: "sms",
      //isDelivered: true,
    };

    this.props.patchTransaction_admin(
      this.state.checkedTransactionPk,
      transactionbody,
      smsbody,
      selectedCurrency,
      "monit"
    );

    this.setState({
      dialogNotify: false,
      monitAuthor: null,
      monitDate: null,
      monitMessage: null,
      checkedTransactionPk: null,
      isTransactionChecked: false,
    });
  };

  handleTextList = (name) => ({ target: { value } }) => {
    this.setState({
      ...this.state,
      [name]: value,
    });
  };

  triggerChangeBookDate = () => {
    this.setState({
      dialogOpenChangeBookDate: true,
    });
  };

  handleCloseChangeBookDateDialog = () => {
    this.setState({
      dialogOpenChangeBookDate: false,
      bookDate: new Date(),
      bookDateFormatted: getFormattedDate(new Date()),
    });
  };

  handleTransactionBookDateChange = (date) => {
    if (date !== null) {
      const dd = date.getDate();
      const mm = date.getMonth() + 1;
      const yyyy = date.getFullYear();
      const fullDate = yyyy + "-" + mm + "-" + dd;
      this.setState({
        bookDate: date,
        bookDateFormatted: fullDate,
      });
    } else {
      this.setState({
        bookDate: null,
        bookDateFormatted: null,
      });
    }
  };

  handleChangeBookDate = () => {
    //dane do patch transaction details
    const checkedTransactionPk = this.state.checkedTransactionPk;
    const body = new FormData();
    const bookDate = this.state.bookDateFormatted;
    body.append("bookDate", bookDate);

    //dane do pobrania transakcji użytkownika
    const userid = this.props.userDetails.pk;
    const thisYearString = this.state.thisYear.toString();
    const thisYearFlag =
      thisYearString.charAt(0).toUpperCase() + thisYearString.slice(1);

    this.props.changeTransactionBookDate(
      checkedTransactionPk,
      body,
      userid,
      thisYearFlag,
      toDate
    );

    this.setState({
      bookDate: new Date(),
      bookDateFormatted: getFormattedDate(new Date()),
    });
  };

  render() {
    const {
      open,
      newEmployeeSelected,
      selectedCurrency,
      thisYear,
      selectedYear,
      selectedType,
      unclosed,
      bookDate,
      isTransactionChecked,
      dialogOpen,
      dialogOpenDecline,
      dialogOpenAccept,
      dialogOpenPayback,
      dialogOpenDeduct,
      dialogOpenReturn,
      dialogOpenChangeBookDate,
      dialogNotify,
      clearanceOwnerComment,
      dialogAttachFile,
      invoice,
      monitMessage,
      view,
    } = this.state;

    const {
      classes,
      usersList,
      queryInProgress_tr_details,
      transactionDetails,
      dataChangeInProgress,
    } = this.props;

    const loading = open && usersList.length === 0;

    const {
      accounting_clearance_cards_acceptance,
      accounting_clearance_cards_clearance,
      accounting_clearance_cards_noinvoice,
      accounting_clearance_accept,
      accounting_clearance_decline,
      accounting_clearance_notify,
      accounting_clearance_attach,
      accounting_clearance_add_payback,
      accounting_clearance_add_deduct,
      accounting_clearance_add_return,
      accounting_clearance_change_bookdate,
    } = this.props.auth.permissions;
    const isSuperUser = this.props.auth.user.is_superuser;

    const yearsList = Array.from({ length: (new Date()).getFullYear() - 2020 }, (_, i) => 2021 + i);
    const typesList = [['all', 'Wszystkie'], ['wi', 'Wypłata gotówki'], ['cp', 'Płatność kartą'], ['ch', 'Płatność gotówką'], ['oc', 'Płatność ze środków własnych']]

    const acceptancecardpm = (
      <AcceptanceCardPm
        monthAndYearToDisplay={getMonthNameAndYear(today, "lastMonth")}
        statsArray={this.props.transactionsStat_ak}
        queryInProgress={this.props.queryInProgress_stat_ak}
      />
    );

    const clearancecardpm = (
      <ClearanceCardPm
        monthAndYearToDisplay={getMonthNameAndYear(today, "lastMonth")}
        statsArray={this.props.transactionsStat_ar}
        queryInProgress={this.props.queryInProgress_stat_ar}
      />
    );

    const noinvoicecardpm = (
      <NoInvoiceCardPm
        monthAndYearToDisplay={getMonthNameAndYear(today, "lastMonth")}
        statsArray={this.props.transactionsStat_noin}
        queryInProgress={this.props.queryInProgress_stat_noin}
      />
    );

    const previewButtonDi = (
      <Fab
        disabled
        color="inherit"
        className={classes.fabblue}
        size="small"
        aria-label="scroll back to top"
      >
        <VisibilityIcon />
      </Fab>
    );

    const previewButtonEn = (
      <Fab
        color="inherit"
        className={classes.fabblue}
        size="small"
        aria-label="scroll back to top"
      >
        <VisibilityIcon />
      </Fab>
    );

    const discardButtonDisabled = (
      <Fab
        disabled
        color="inherit"
        className={classes.fabred}
        size="small"
        aria-label="scroll back to top"
      >
        <CloseIcon />
      </Fab>
    );

    const discardButtonEnabled = (
      <Fab
        onClick={this.triggerDeclineClearance}
        color="inherit"
        className={classes.fabred}
        size="small"
        aria-label="scroll back to top"
      >
        <CloseIcon />
      </Fab>
    );

    const acceptButtonDisabled = (
      <Fab
        disabled
        color="inherit"
        className={classes.fabgreen}
        size="small"
        aria-label="scroll back to top"
      >
        <DoneIcon />
      </Fab>
    );

    const acceptButtonEnabled = (
      <Fab
        onClick={this.triggerAcceptClearance}
        color="inherit"
        className={classes.fabgreen}
        size="small"
        aria-label="scroll back to top"
      >
        <DoneIcon />
      </Fab>
    );

    const declineButtonDi = (
      <Button
        disabled
        color="secondary"
        variant="contained"
        autoFocus
        size="large"
      >
        Odrzuć
      </Button>
    );

    const declineButtonEn = (
      <Button
        onClick={this.handleDeclineClearance}
        color="secondary"
        variant="contained"
        autoFocus
        size="large"
      >
        Odrzuć
      </Button>
    );

    const notifyButtonDisabled = (
      <Fab
        disabled
        color="inherit"
        className={classes.fabblue}
        size="small"
        aria-label="scroll back to top"
      >
        <CommentIcon />
      </Fab>
    );

    const notifyButtonEnabled = (
      <Fab
        onClick={this.triggerNotifyUser}
        color="inherit"
        className={classes.fabblue}
        size="small"
        aria-label="scroll back to top"
      >
        <CommentIcon />
      </Fab>
    );

    const paybackButtonEnabled = (
      <IconButton
        //className={classes.IconBtnInfo}
        onClick={this.triggerPayback}
        aria-label="add an alarm"
      >
        <img
          style={{ height: "25px" }}
          src={transferIconPlus}
          alt="Wypłata dla pracownika"
        ></img>
      </IconButton>
    );

    const paybackButtonDisabled = (
      <IconButton disabled aria-label="add an alarm">
        <img
          style={{ height: "25px" }}
          src={transferIconPlusD}
          alt="Wypłata dla pracownika"
        ></img>
      </IconButton>
    );

    const deductButtonEnabled = (
      <IconButton
        color="secondary"
        aria-label="add an alarm"
        onClick={this.triggerDeduct}
      >
        <img
          style={{ height: "25px" }}
          src={transferIconMinus}
          alt="Potrącenie"
        ></img>
      </IconButton>
    );

    const deductButtonDisabled = (
      <IconButton disabled aria-label="add an alarm">
        <img
          style={{ height: "25px" }}
          src={transferIconMinusD}
          alt="Potrącenie"
        ></img>
      </IconButton>
    );

    const returnButtonEnabled = (
      <IconButton aria-label="add an alarm" onClick={this.triggerReturn}>
        <img
          style={{ height: "25px" }}
          src={returnIcon}
          alt="Zwrot pracownika"
        ></img>
      </IconButton>
    );

    const returnButtonDisabled = (
      <IconButton disabled aria-label="add an alarm">
        <img
          style={{ height: "25px" }}
          src={returnIconD}
          alt="Zwrot pracownika"
        ></img>
      </IconButton>
    );

    var transactionContent = null;
    var msspecificContent = null;
    var wpspecificContent = null;
    var esspecificContent = null;
    var purchaseListContent = null;
    var monitspecificContent = null;
    var invoiceLink = null;

    if (
      transactionDetails.pk !== undefined &&
      transactionDetails.monitDate &&
      !queryInProgress_tr_details
    ) {
      const datetime = new Date(transactionDetails.monitDate);
      const datetimeFormatted = getFormattedDateTime(datetime);

      monitspecificContent = (
        <Fragment>
          <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
            <Typography className={classes.textDescription7}>
              Treść monitu
            </Typography>
            <Typography className={classes.textDescription8}>
              {transactionDetails.monitMessage}
            </Typography>
          </Grid>
          <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
            <Typography className={classes.textDescription7}>
              Autor monitu
            </Typography>
            <Typography className={classes.textDescription6}>
              {transactionDetails.monitAuthor}
            </Typography>
          </Grid>
          <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
            <Typography className={classes.textDescription7}>
              Data monitu
            </Typography>
            <Typography className={classes.textDescription6}>
              {datetimeFormatted}
            </Typography>
          </Grid>
          <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
            <Typography className={classes.textDescription7}>
              Numer telefonu na który wysłano SMS
            </Typography>
            <Typography className={classes.textDescription6}>
              {transactionDetails.monitSendNumber}
            </Typography>
          </Grid>
        </Fragment>
      );
    }

    if (transactionDetails.pk !== undefined && !queryInProgress_tr_details) {
      switch (transactionDetails.transactionType) {
        case "cp":
          purchaseListContent = (
            <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
              <Typography className={classes.textDescription7}>
                lista zakupów
              </Typography>
              <Typography className={classes.textDescription6}>
                {transactionDetails.purchaseList}
              </Typography>
            </Grid>
          );
          msspecificContent = (
            <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
              <Typography className={classes.textDescription7}>
                identyfikator stacji
              </Typography>
              <Typography className={classes.textDescription6}>
                {transactionDetails.baseStationIdentifier}
              </Typography>
            </Grid>
          );
          if (transactionDetails.car != null) {
            esspecificContent = (
              <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                <Typography className={classes.textDescription7}>
                  Numer rejestracyjny
                </Typography>
                <Typography className={classes.textDescription6}>
                  {transactionDetails.car.regNumber}
                </Typography>
              </Grid>
            );
          }
          wpspecificContent = (
            <Fragment>
              <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                <Typography className={classes.textDescription7}>
                  dla pracownika:
                </Typography>
                <Typography className={classes.textDescription6}>
                  {(transactionDetails.transactionEmployee == '' || transactionDetails.transactionEmployee == null) ? "nie podano" : transactionDetails.transactionEmployee}
                </Typography>
              </Grid>
              <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                <Typography className={classes.textDescription7}>
                  przypisać na stan Środków Trwałych
                </Typography>
                <Typography className={classes.textDescription6}>
                  {transactionDetails.assignAssetRequestFlag ? "tak" : "nie"}
                </Typography>
              </Grid>
              <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                <Typography className={classes.textDescription7}>
                  przypisano na stan Środków Trwałych
                </Typography>
                <Typography className={classes.textDescription6}>
                  {transactionDetails.assetAssignedFlag ? "tak" : "nie"}
                </Typography>
              </Grid>
            </Fragment>
          );
          invoiceLink = (
            <IconButton
              style={{ position: "relative", top: "-3px" }}
              aria-label="delete"
              onClick={this.handleDownloadInvoice}
            >
              <GetAppIcon />
            </IconButton>
          );
          transactionContent = (
            <Fragment>
              <Typography style={{ marginTop: "30px" }}></Typography>
              <Grid container className={classes.root} spacing={2}>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={1}>
                  {eval(
                    transactionDetails.transactionType +
                    transactionDetails.transactionStatus
                  )}
                </Grid>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={11}>
                  <Typography className={classes.textDescription5}>
                    {transactionDetails.bookDate +
                      " | " +
                      transactionDetails.owner_details.first_name +
                      " " +
                      transactionDetails.owner_details.last_name}
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.transactionDescription}
                  </Typography>
                </Grid>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textName}>
                    {transactionDetails.value +
                      " " +
                      transactionDetails.currency_description}
                  </Typography>
                </Grid>
                <div className={classes.border} />
                {transactionDetails.monitDate ? monitspecificContent : ""}
                {transactionDetails.monitDate ? border : ""}
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    typ transakcji
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.transactionType_description}
                  </Typography>
                </Grid>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    projekt
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.project.name}
                  </Typography>
                </Grid>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    kategoria transakcji
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.transactionCategory_description}
                  </Typography>
                </Grid>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    status
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.transactionStatus_description}
                  </Typography>
                </Grid>
                <div className={classes.border} />
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    waluta
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.currency_description}
                  </Typography>
                </Grid>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    kwota
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.value}
                  </Typography>
                </Grid>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    numer karty płatniczej
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.card}
                  </Typography>
                </Grid>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    data transakcji
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.transactionDate}
                  </Typography>
                </Grid>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    data zarejestrowania transakcji
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.createDate}
                  </Typography>
                </Grid>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    data księgowania
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.bookDate}
                  </Typography>
                </Grid>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    tytułem
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.transactionDescription}
                  </Typography>
                </Grid>
                {purchaseListContent}
                {transactionDetails.transactionCategory === "es"
                  ? esspecificContent
                  : ""}
                {transactionDetails.transactionCategory === "ms"
                  ? msspecificContent
                  : ""}
                <div className={classes.border} />
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    właścicel budżetu
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.costsOwner === null
                      ? "-"
                      : transactionDetails.costsOwner}
                  </Typography>
                </Grid>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    komentarz właścicela budżetu
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.costsOwnerComment === null
                      ? "-"
                      : transactionDetails.costsOwnerComment}
                  </Typography>
                </Grid>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    data akceptacji kosztów
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.costsOwnerCommentDate === null
                      ? "-"
                      : transactionDetails.costsOwnerCommentDate}
                  </Typography>
                </Grid>
                <div className={classes.border} />
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    rozliczający
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.clearanceOwner === null
                      ? "-"
                      : transactionDetails.clearanceOwner}
                  </Typography>
                </Grid>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    komentarz rozliczającego
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.clearanceOwnerComment === null
                      ? "-"
                      : transactionDetails.clearanceOwnerComment}
                  </Typography>
                </Grid>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    data rozliczenia
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.clearanceOwnerCommentDate === null
                      ? "-"
                      : transactionDetails.clearanceOwnerCommentDate}
                  </Typography>
                </Grid>
                <div className={classes.border} />
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    faktura
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.invoice === null ? "-" : invoiceLink}
                  </Typography>
                </Grid>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    data zamieszczenia faktury
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.invoiceUploadDate === null
                      ? "-"
                      : transactionDetails.invoiceUploadDate}
                  </Typography>
                </Grid>
                {transactionDetails.transactionCategory === "wp" ? border : ""}
                {transactionDetails.transactionCategory === "wp"
                  ? wpspecificContent
                  : ""}
              </Grid>
            </Fragment>
          );
          break;
        case "oc":
          purchaseListContent = (
            <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
              <Typography className={classes.textDescription7}>
                lista zakupów
              </Typography>
              <Typography className={classes.textDescription6}>
                {transactionDetails.purchaseList}
              </Typography>
            </Grid>
          );
          msspecificContent = (
            <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
              <Typography className={classes.textDescription7}>
                identyfikator stacji
              </Typography>
              <Typography className={classes.textDescription6}>
                {transactionDetails.baseStationIdentifier}
              </Typography>
            </Grid>
          );
          if (transactionDetails.car != null) {
            esspecificContent = (
              <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                <Typography className={classes.textDescription7}>
                  Numer rejestracyjny
                </Typography>
                <Typography className={classes.textDescription6}>
                  {transactionDetails.car.regNumber}
                </Typography>
              </Grid>
            );
          }
          wpspecificContent = (
            <Fragment>
              <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                <Typography className={classes.textDescription7}>
                  dla pracownika:
                </Typography>
                <Typography className={classes.textDescription6}>
                  {(transactionDetails.transactionEmployee == '' || transactionDetails.transactionEmployee == null) ? "nie podano" : transactionDetails.transactionEmployee}
                </Typography>
              </Grid>
              <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                <Typography className={classes.textDescription7}>
                  przypisać na stan Środków Trwałych
                </Typography>
                <Typography className={classes.textDescription6}>
                  {transactionDetails.assignAssetRequestFlag ? "tak" : "nie"}
                </Typography>
              </Grid>
              <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                <Typography className={classes.textDescription7}>
                  przypisano na stan Środków Trwałych
                </Typography>
                <Typography className={classes.textDescription6}>
                  {transactionDetails.assetAssignedFlag ? "tak" : "nie"}
                </Typography>
              </Grid>
            </Fragment>
          );
          invoiceLink = (
            <IconButton
              style={{ position: "relative", top: "-3px" }}
              aria-label="delete"
              onClick={this.handleDownloadInvoice}
            >
              <GetAppIcon />
            </IconButton>
          );
          transactionContent = (
            <Fragment>
              <Typography style={{ marginTop: "30px" }}></Typography>
              <Grid container className={classes.root} spacing={2}>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={1}>
                  {eval(
                    transactionDetails.transactionType +
                    transactionDetails.transactionStatus
                  )}
                </Grid>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={11}>
                  <Typography className={classes.textDescription5}>
                    {transactionDetails.bookDate +
                      " | " +
                      transactionDetails.owner_details.first_name +
                      " " +
                      transactionDetails.owner_details.last_name}
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.transactionDescription}
                  </Typography>
                </Grid>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textName}>
                    {transactionDetails.value +
                      " " +
                      transactionDetails.currency_description}
                  </Typography>
                </Grid>
                <div className={classes.border} />
                {transactionDetails.monitDate ? monitspecificContent : ""}
                {transactionDetails.monitDate ? border : ""}
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    typ transakcji
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.transactionType_description}
                  </Typography>
                </Grid>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    projekt
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.project.name}
                  </Typography>
                </Grid>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    kategoria transakcji
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.transactionCategory_description}
                  </Typography>
                </Grid>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    status
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.transactionStatus_description}
                  </Typography>
                </Grid>
                <div className={classes.border} />
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    waluta
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.currency_description}
                  </Typography>
                </Grid>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    kwota
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.value}
                  </Typography>
                </Grid>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    data transakcji
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.transactionDate}
                  </Typography>
                </Grid>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    data zarejestrowania transakcji
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.createDate}
                  </Typography>
                </Grid>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    data księgowania
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.bookDate}
                  </Typography>
                </Grid>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    tytułem
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.transactionDescription}
                  </Typography>
                </Grid>
                {purchaseListContent}
                {transactionDetails.transactionCategory === "es"
                  ? esspecificContent
                  : ""}
                {transactionDetails.transactionCategory === "ms"
                  ? msspecificContent
                  : ""}
                <div className={classes.border} />
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    właścicel budżetu
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.costsOwner === null
                      ? "-"
                      : transactionDetails.costsOwner}
                  </Typography>
                </Grid>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    komentarz właścicela budżetu
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.costsOwnerComment === null
                      ? "-"
                      : transactionDetails.costsOwnerComment}
                  </Typography>
                </Grid>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    data akceptacji kosztów
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.costsOwnerCommentDate === null
                      ? "-"
                      : transactionDetails.costsOwnerCommentDate}
                  </Typography>
                </Grid>
                <div className={classes.border} />
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    rozliczający
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.clearanceOwner === null
                      ? "-"
                      : transactionDetails.clearanceOwner}
                  </Typography>
                </Grid>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    komentarz rozliczającego
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.clearanceOwnerComment === null
                      ? "-"
                      : transactionDetails.clearanceOwnerComment}
                  </Typography>
                </Grid>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    data rozliczenia
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.clearanceOwnerCommentDate === null
                      ? "-"
                      : transactionDetails.clearanceOwnerCommentDate}
                  </Typography>
                </Grid>
                <div className={classes.border} />
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    faktura
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.invoice === null ? "-" : invoiceLink}
                  </Typography>
                </Grid>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    data zamieszczenia faktury
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.invoiceUploadDate === null
                      ? "-"
                      : transactionDetails.invoiceUploadDate}
                  </Typography>
                </Grid>
                {transactionDetails.transactionCategory === "wp" ? border : ""}
                {transactionDetails.transactionCategory === "wp"
                  ? wpspecificContent
                  : ""}
              </Grid>
            </Fragment>
          );
          break;
        case "ch":
          purchaseListContent = (
            <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
              <Typography className={classes.textDescription7}>
                lista zakupów
              </Typography>
              <Typography className={classes.textDescription6}>
                {transactionDetails.purchaseList}
              </Typography>
            </Grid>
          );
          msspecificContent = (
            <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
              <Typography className={classes.textDescription7}>
                identyfikator stacji
              </Typography>
              <Typography className={classes.textDescription6}>
                {transactionDetails.baseStationIdentifier}
              </Typography>
            </Grid>
          );
          if (transactionDetails.car != null) {
            esspecificContent = (
              <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                <Typography className={classes.textDescription7}>
                  Numer rejestracyjny
                </Typography>
                <Typography className={classes.textDescription6}>
                  {transactionDetails.car.regNumber}
                </Typography>
              </Grid>
            );
          }
          wpspecificContent = (
            <Fragment>
              <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                <Typography className={classes.textDescription7}>
                  dla pracownika:
                </Typography>
                <Typography className={classes.textDescription6}>
                  {(transactionDetails.transactionEmployee == '' || transactionDetails.transactionEmployee == null) ? "nie podano" : transactionDetails.transactionEmployee}
                </Typography>
              </Grid>
              <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                <Typography className={classes.textDescription7}>
                  przypisać na stan Środków Trwałych
                </Typography>
                <Typography className={classes.textDescription6}>
                  {transactionDetails.assignAssetRequestFlag ? "tak" : "nie"}
                </Typography>
              </Grid>
              <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                <Typography className={classes.textDescription7}>
                  przypisano na stan Środków Trwałych
                </Typography>
                <Typography className={classes.textDescription6}>
                  {transactionDetails.assetAssignedFlag ? "tak" : "nie"}
                </Typography>
              </Grid>
            </Fragment>
          );
          invoiceLink = (
            <IconButton
              style={{ position: "relative", top: "-3px" }}
              aria-label="delete"
              onClick={this.handleDownloadInvoice}
            >
              <GetAppIcon />
            </IconButton>
          );
          transactionContent = (
            <Fragment>
              <Typography style={{ marginTop: "30px" }}></Typography>
              <Grid container className={classes.root} spacing={2}>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={1}>
                  {eval(
                    transactionDetails.transactionType +
                    transactionDetails.transactionStatus
                  )}
                </Grid>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={11}>
                  <Typography className={classes.textDescription5}>
                    {transactionDetails.bookDate +
                      " | " +
                      transactionDetails.owner_details.first_name +
                      " " +
                      transactionDetails.owner_details.last_name}
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.transactionDescription}
                  </Typography>
                </Grid>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textName}>
                    {transactionDetails.value +
                      " " +
                      transactionDetails.currency_description}
                  </Typography>
                </Grid>
                <div className={classes.border} />
                {transactionDetails.monitDate ? monitspecificContent : ""}
                {transactionDetails.monitDate ? border : ""}
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    typ transakcji
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.transactionType_description}
                  </Typography>
                </Grid>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    projekt
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.project.name}
                  </Typography>
                </Grid>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    kategoria transakcji
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.transactionCategory_description}
                  </Typography>
                </Grid>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    status
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.transactionStatus_description}
                  </Typography>
                </Grid>
                <div className={classes.border} />
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    waluta
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.currency_description}
                  </Typography>
                </Grid>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    kwota
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.value}
                  </Typography>
                </Grid>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    data transakcji
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.transactionDate}
                  </Typography>
                </Grid>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    data zarejestrowania transakcji
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.createDate}
                  </Typography>
                </Grid>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    data księgowania
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.bookDate}
                  </Typography>
                </Grid>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    tytułem
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.transactionDescription}
                  </Typography>
                </Grid>
                {purchaseListContent}
                {transactionDetails.transactionCategory === "es"
                  ? esspecificContent
                  : ""}
                {transactionDetails.transactionCategory === "ms"
                  ? msspecificContent
                  : ""}
                <div className={classes.border} />
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    właścicel budżetu
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.costsOwner === null
                      ? "-"
                      : transactionDetails.costsOwner}
                  </Typography>
                </Grid>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    komentarz właścicela budżetu
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.costsOwnerComment === null
                      ? "-"
                      : transactionDetails.costsOwnerComment}
                  </Typography>
                </Grid>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    data akceptacji kosztów
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.costsOwnerCommentDate === null
                      ? "-"
                      : transactionDetails.costsOwnerCommentDate}
                  </Typography>
                </Grid>
                <div className={classes.border} />
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    rozliczający
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.clearanceOwner === null
                      ? "-"
                      : transactionDetails.clearanceOwner}
                  </Typography>
                </Grid>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    komentarz rozliczającego
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.clearanceOwnerComment === null
                      ? "-"
                      : transactionDetails.clearanceOwnerComment}
                  </Typography>
                </Grid>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    data rozliczenia
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.clearanceOwnerCommentDate === null
                      ? "-"
                      : transactionDetails.clearanceOwnerCommentDate}
                  </Typography>
                </Grid>
                <div className={classes.border} />
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    faktura
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.invoice === null ? "-" : invoiceLink}
                  </Typography>
                </Grid>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    data zamieszczenia faktury
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.invoiceUploadDate === null
                      ? "-"
                      : transactionDetails.invoiceUploadDate}
                  </Typography>
                </Grid>
                {transactionDetails.transactionCategory === "wp" ? border : ""}
                {transactionDetails.transactionCategory === "wp"
                  ? wpspecificContent
                  : ""}
              </Grid>
            </Fragment>
          );
          break;
        case "pb":
          invoiceLink = (
            <IconButton
              style={{ position: "relative", top: "-3px" }}
              aria-label="delete"
              onClick={this.handleDownloadInvoice}
            >
              <GetAppIcon />
            </IconButton>
          );
          transactionContent = (
            <Fragment>
              <Typography style={{ marginTop: "30px" }}></Typography>
              <Grid container className={classes.root} spacing={2}>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={1}>
                  {eval(
                    transactionDetails.transactionType +
                    transactionDetails.transactionStatus
                  )}
                </Grid>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={11}>
                  <Typography className={classes.textDescription5}>
                    {transactionDetails.bookDate}
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.transactionDescription}
                  </Typography>
                </Grid>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textName}>
                    {transactionDetails.value +
                      " " +
                      transactionDetails.currency_description}
                  </Typography>
                </Grid>
                <div className={classes.border} />
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    typ transakcji
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.transactionType_description}
                  </Typography>
                </Grid>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    projekt
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.project.name}
                  </Typography>
                </Grid>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    kategoria transakcji
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.transactionCategory_description}
                  </Typography>
                </Grid>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    status
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.transactionStatus_description}
                  </Typography>
                </Grid>
                <div className={classes.border} />
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    waluta
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.currency_description}
                  </Typography>
                </Grid>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    kwota
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.value}
                  </Typography>
                </Grid>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    data zarejestrowania transakcji
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.createDate}
                  </Typography>
                </Grid>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    tytułem
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.transactionDescription}
                  </Typography>
                </Grid>
                <div className={classes.border} />
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    rozliczający
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.clearanceOwner === null
                      ? "-"
                      : transactionDetails.clearanceOwner}
                  </Typography>
                </Grid>
                <div className={classes.border} />
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    dokonujący wypłaty
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.costsOwner === null
                      ? "-"
                      : transactionDetails.costsOwner}
                  </Typography>
                </Grid>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    komentarz wypłacającego
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.costsOwnerComment === null
                      ? "-"
                      : transactionDetails.costsOwnerComment}
                  </Typography>
                </Grid>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    data wypłaty
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.payDate === null
                      ? "-"
                      : transactionDetails.payDate}
                  </Typography>
                </Grid>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    potwierdzenie wypłaty
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.invoice === null ? "-" : invoiceLink}
                  </Typography>
                </Grid>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    data zamieszczenia potwierdzenia
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.invoiceUploadDate === null
                      ? "-"
                      : transactionDetails.invoiceUploadDate}
                  </Typography>
                </Grid>
              </Grid>
            </Fragment>
          );
          break;
        case "dt":
          invoiceLink = (
            <IconButton
              style={{ position: "relative", top: "-3px" }}
              aria-label="delete"
              onClick={this.handleDownloadInvoice}
            >
              <GetAppIcon />
            </IconButton>
          );
          transactionContent = (
            <Fragment>
              <Typography style={{ marginTop: "30px" }}></Typography>
              <Grid container className={classes.root} spacing={2}>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={1}>
                  {eval(
                    transactionDetails.transactionType +
                    transactionDetails.transactionStatus
                  )}
                </Grid>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={11}>
                  <Typography className={classes.textDescription5}>
                    {transactionDetails.bookDate}
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.transactionDescription}
                  </Typography>
                </Grid>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textName}>
                    {transactionDetails.value +
                      " " +
                      transactionDetails.currency_description}
                  </Typography>
                </Grid>
                <div className={classes.border} />
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    typ transakcji
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.transactionType_description}
                  </Typography>
                </Grid>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    projekt
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.project.name}
                  </Typography>
                </Grid>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    kategoria transakcji
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.transactionCategory_description}
                  </Typography>
                </Grid>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    status
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.transactionStatus_description}
                  </Typography>
                </Grid>
                <div className={classes.border} />
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    waluta
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.currency_description}
                  </Typography>
                </Grid>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    kwota
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.value}
                  </Typography>
                </Grid>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    data zarejestrowania transakcji
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.createDate}
                  </Typography>
                </Grid>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    tytułem
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.transactionDescription}
                  </Typography>
                </Grid>
                <div className={classes.border} />
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    rozliczający
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.clearanceOwner === null
                      ? "-"
                      : transactionDetails.clearanceOwner}
                  </Typography>
                </Grid>
                <div className={classes.border} />
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    realizujący potrącenie
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.costsOwner === null
                      ? "-"
                      : transactionDetails.costsOwner}
                  </Typography>
                </Grid>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    komentarz realizującego potrącenie
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.costsOwnerComment === null
                      ? "-"
                      : transactionDetails.costsOwnerComment}
                  </Typography>
                </Grid>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    data potrącenia
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.payDate === null
                      ? "-"
                      : transactionDetails.payDate}
                  </Typography>
                </Grid>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    podstawa potrącenia
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.invoice === null ? "-" : invoiceLink}
                  </Typography>
                </Grid>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    data zamieszczenia podstawy
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.invoiceUploadDate === null
                      ? "-"
                      : transactionDetails.invoiceUploadDate}
                  </Typography>
                </Grid>
              </Grid>
            </Fragment>
          );
          break;
        case "rt":
          invoiceLink = (
            <IconButton
              style={{ position: "relative", top: "-3px" }}
              aria-label="delete"
              onClick={this.handleDownloadInvoice}
            >
              <GetAppIcon />
            </IconButton>
          );
          transactionContent = (
            <Fragment>
              <Typography style={{ marginTop: "30px" }}></Typography>
              <Grid container className={classes.root} spacing={2}>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={1}>
                  {eval(
                    transactionDetails.transactionType +
                    transactionDetails.transactionStatus
                  )}
                </Grid>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={11}>
                  <Typography className={classes.textDescription5}>
                    {transactionDetails.bookDate}
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.transactionDescription}
                  </Typography>
                </Grid>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textName}>
                    {transactionDetails.value +
                      " " +
                      transactionDetails.currency_description}
                  </Typography>
                </Grid>
                <div className={classes.border} />
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    typ transakcji
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.transactionType_description}
                  </Typography>
                </Grid>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    projekt
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.project.name}
                  </Typography>
                </Grid>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    kategoria transakcji
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.transactionCategory_description}
                  </Typography>
                </Grid>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    status
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.transactionStatus_description}
                  </Typography>
                </Grid>
                <div className={classes.border} />
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    waluta
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.currency_description}
                  </Typography>
                </Grid>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    kwota
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.value}
                  </Typography>
                </Grid>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    data zarejestrowania transakcji
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.createDate}
                  </Typography>
                </Grid>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    tytułem
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.transactionDescription}
                  </Typography>
                </Grid>
                <div className={classes.border} />
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    rozliczający
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.clearanceOwner === null
                      ? "-"
                      : transactionDetails.clearanceOwner}
                  </Typography>
                </Grid>
                <div className={classes.border} />
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    przyjmujący zwrot
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.costsOwner === null
                      ? "-"
                      : transactionDetails.costsOwner}
                  </Typography>
                </Grid>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    komentarz przyjmującego zwrot
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.costsOwnerComment === null
                      ? "-"
                      : transactionDetails.costsOwnerComment}
                  </Typography>
                </Grid>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    data zwrotu
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.payDate === null
                      ? "-"
                      : transactionDetails.payDate}
                  </Typography>
                </Grid>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    potwierdzenie zwrotu
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.invoice === null ? "-" : invoiceLink}
                  </Typography>
                </Grid>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    data zamieszczenia potwierdzenia
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.invoiceUploadDate === null
                      ? "-"
                      : transactionDetails.invoiceUploadDate}
                  </Typography>
                </Grid>
              </Grid>
            </Fragment>
          );
          break;
        case "wi":
          transactionContent = (
            <Fragment>
              <Typography style={{ marginTop: "30px" }}></Typography>
              <Grid container className={classes.root} spacing={2}>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={1}>
                  {wi}
                </Grid>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={11}>
                  <Typography className={classes.textDescription5}>
                    {transactionDetails.bookDate}
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.transactionDescription}
                  </Typography>
                </Grid>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textName}>
                    {transactionDetails.value +
                      " " +
                      transactionDetails.currency_description}
                  </Typography>
                </Grid>
                <div className={classes.border} />
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    typ transakcji
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.transactionType_description}
                  </Typography>
                </Grid>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    projekt
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.project.name}
                  </Typography>
                </Grid>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    status
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.transactionStatus_description}
                  </Typography>
                </Grid>
                <div className={classes.border} />
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    waluta
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.currency_description}
                  </Typography>
                </Grid>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    kwota
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.value}
                  </Typography>
                </Grid>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    numer karty płatniczej
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.card}
                  </Typography>
                </Grid>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    data transakcji
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.transactionDate}
                  </Typography>
                </Grid>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    data zarejestrowania transakcji
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.createDate}
                  </Typography>
                </Grid>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    data księgowania
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.bookDate}
                  </Typography>
                </Grid>
                <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.textDescription7}>
                    tytułem
                  </Typography>
                  <Typography className={classes.textDescription6}>
                    {transactionDetails.transactionDescription}
                  </Typography>
                </Grid>
              </Grid>
            </Fragment>
          );
          break;
        default:
      }
    }

    const attachButtonDi = (
      <Fab
        disabled
        size="small"
        aria-label="scroll back to top"
        className={classes.fabgreen}
      >
        <AttachFileIcon />
      </Fab>
    );

    const attachButtonEn = (
      <Fab
        size="small"
        aria-label="scroll back to top"
        className={classes.fabgreen}
      >
        <AttachFileIcon />
      </Fab>
    );

    const attachInvoiceButtonEn = (
      <Button
        variant="contained"
        color="inherit"
        size="large"
        onClick={this.handleAttachInvoice}
        style={{ marginTop: "20px" }}
        className={classes.buttonAccept}
      >
        Załącz
      </Button>
    );

    const attachInvoiceButtonDi = (
      <Button
        disabled
        variant="contained"
        color="inherit"
        size="large"
        style={{ marginTop: "20px" }}
        className={classes.buttonAccept}
      >
        Załącz
      </Button>
    );

    const bookButtonDi = (
      <Fab
        disabled
        size="small"
        aria-label="scroll back to top"
        className={classes.fabgreen}
      >
        <CalendarTodayIcon />
      </Fab>
    );

    const bookButtonEn = (
      <Fab
        onClick={this.triggerChangeBookDate}
        size="small"
        aria-label="scroll back to top"
        className={classes.fabgreen}
      >
        <CalendarTodayIcon />
      </Fab>
    );

    return (
      <Fragment>
        <div className={classes.section}>
          <Box m={2}>
            <Typography
              style={{ marginBottom: "30px" }}
              className={classes.cardsGroup}
            >
              Statystyki poprzedniego miesiąca
            </Typography>
            <Grid container justify="flex-start" spacing={2}>
              {accounting_clearance_cards_acceptance || isSuperUser
                ? acceptancecardpm
                : ""}
              {accounting_clearance_cards_clearance || isSuperUser ? clearancecardpm : ""}
              {accounting_clearance_cards_noinvoice || isSuperUser ? noinvoicecardpm : ""}
            </Grid>
            <Typography
              style={{ marginBottom: "30px" }}
              className={classes.cardsGroup}
            >
              Rozliczenia pracowników
            </Typography>
            <Grid container justify="space-between" spacing={2}>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <FormHelperText>
                  wybierz pracownika z listy aby załadować listę jego transakcji
                  oraz wyświetlić saldo
                </FormHelperText>
                <ThemeProvider theme={materialTheme}>
                  <Autocomplete
                    value={newEmployeeSelected}
                    id="asynchronous-demo"
                    style={{ width: "300px" }}
                    open={open}
                    onOpen={this.setOpen}
                    onClose={this.setOpen}
                    onChange={this.getSelected}
                    getOptionSelected={(option, value) =>
                      option.last_name === value.last_name &&
                      option.first_name === value.first_name
                    }
                    getOptionLabel={(option) =>
                      option.last_name + " " + option.first_name
                    }
                    options={usersList}
                    loading={loading}
                    renderInput={(params) => (
                      <CssTextField
                        {...params}
                        label="wybierz z listy..."
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <React.Fragment>
                              {loading ? (
                                <CircularProgress color="inherit" size={20} />
                              ) : null}
                              {params.InputProps.endAdornment}
                            </React.Fragment>
                          ),
                        }}
                      />
                    )}
                  />
                </ThemeProvider>
              </Grid>
              <Grid item xs={12} sm={6} md={3} lg={3}>
                <Grid container direction="row">
                  <Grid item xs={12}>
                    <FormHelperText>
                      Wybrany okres rozliczeniowy pracownika
                    </FormHelperText>
                  </Grid>
                  <Grid item xs={6}>
                    <Select
                      labelId="demo-simple-select-filled-label"
                      id="demo-simple-select-filled"
                      value={selectedYear}
                      onChange={this.handlethisYearFilter}
                      style={{ width: "280px", textAlign: "left", marginTop: "16px" }}
                      disabled={!newEmployeeSelected}
                    >
                      {yearsList.map((year) => (
                        <MenuItem key={year} value={year}>
                          {year}
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={6} md={3} lg={2}>
                <Grid container direction="row">
                  <Grid item xs={12}>
                    <FormHelperText>
                      Wybierz typ transakcji
                    </FormHelperText>
                  </Grid>
                  <Grid item xs={6}>
                    <Select
                      labelId="demo-simple-select-filled-label"
                      id="demo-simple-select-filled"
                      value={selectedType}
                      onChange={this.handlethisTypeFilter}
                      style={{ width: "180px", textAlign: "left", marginTop: "16px" }}
                      disabled={!newEmployeeSelected}
                    >
                      {typesList.map((type) => (
                        <MenuItem key={type[0]} value={type[0]}>
                          {type[1]}
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={6} md={2} lg={2}>
                <FormHelperText>
                  Filtrowanie transakcji
                </FormHelperText>
                <RangeSliderUnclosed
                  unclosed={unclosed}
                  unclosedFilter={this.handleUnclosedFilter}
                  isActive={newEmployeeSelected ? true : false}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={2} lg={2}>
                <FormHelperText>
                  Zarejestruj transakcję na koncie
                </FormHelperText>
                <Grid container justify="flex-start" spacing={1}>
                  <Grid item>
                    <Tooltip title="Wypłata dla pracownika" placement="bottom">
                      <span>
                        {newEmployeeSelected && (accounting_clearance_add_payback || isSuperUser)
                          ? paybackButtonEnabled
                          : paybackButtonDisabled}
                      </span>
                    </Tooltip>
                  </Grid>
                  <Grid item>
                    <Tooltip title="Potrącenia" placement="bottom">
                      <span>
                        {newEmployeeSelected && (accounting_clearance_add_deduct || isSuperUser)
                          ? deductButtonEnabled
                          : deductButtonDisabled}
                      </span>
                    </Tooltip>
                  </Grid>
                  <Grid item>
                    <Tooltip title="Zwrot do Kasy" placement="bottom">
                      <span>
                        {newEmployeeSelected && (accounting_clearance_add_return || isSuperUser)
                          ? returnButtonEnabled
                          : returnButtonDisabled}
                      </span>
                    </Tooltip>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid container justify="flex-start" spacing={4}>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <BalanceCard
                  changeCurrency={this.handleChangeCurrency}
                  selectedCurrency={selectedCurrency}
                  view={view}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={8} lg={9}>
                <EmployeeOperationsList
                  checked={this.handleChecked}
                  selectedCurrency={selectedCurrency}
                  unclosed={unclosed}
                  selectedType={selectedType}
                />
              </Grid>
              {/*<Grid item xs={12} sm={12} md={12} lg={12}>
                <HelpCard />
                      </Grid>*/}
            </Grid>
          </Box>

          {/*ACTIONS*/}

          <Zoom in={isTransactionChecked}>
            <div
              onClick={this.handleClickPreview}
              role="presentation"
              className={classes.fb2}
            >
              <Tooltip title="Pokaż szczegóły" placement="left">
                {queryInProgress_tr_details ? previewButtonDi : previewButtonEn}
              </Tooltip>
            </div>
          </Zoom>
          <Zoom
            in={
              isTransactionChecked &&
              transactionDetails.transactionStatus === "ar"
            }
          >
            <div role="presentation" className={classes.fb3}>
              <Tooltip title="Brak akceptacji" placement="left">
                {!queryInProgress_tr_details && (accounting_clearance_decline || isSuperUser)
                  ? discardButtonEnabled
                  : discardButtonDisabled}
              </Tooltip>
            </div>
          </Zoom>
          <Zoom
            in={
              isTransactionChecked &&
              (transactionDetails.transactionStatus === "ar" ||
                transactionDetails.transactionStatus === "od")
            }
          >
            <div role="presentation" className={classes.fb1}>
              <Tooltip title="Zaakceptuj rozliczenie" placement="left">
                {!queryInProgress_tr_details && (accounting_clearance_accept || isSuperUser)
                  ? acceptButtonEnabled
                  : acceptButtonDisabled}
              </Tooltip>
            </div>
          </Zoom>
          <Zoom
            in={
              isTransactionChecked &&
              (transactionDetails.transactionStatus === "ar" ||
                transactionDetails.transactionStatus === "od")
            }
          >
            <div role="presentation" className={classes.fb6}>
              <Tooltip title="Wyślij monit" placement="left">
                {!queryInProgress_tr_details && (accounting_clearance_notify || isSuperUser)
                  ? notifyButtonEnabled
                  : notifyButtonDisabled}
              </Tooltip>
            </div>
          </Zoom>
          <Zoom in={isTransactionChecked}>
            <div
              onClick={this.triggerAttachInvoice}
              role="presentation"
              className={classes.fb5}
            >
              <Tooltip title="Załącz dokument" placement="left">
                {!queryInProgress_tr_details && (accounting_clearance_attach || isSuperUser)
                  ? attachButtonEn
                  : attachButtonDi}
              </Tooltip>
            </div>
          </Zoom>

          <Zoom
            in={
              isTransactionChecked &&
              transactionDetails.transactionType !== "pb" &&
              transactionDetails.transactionType !== "dt" &&
              transactionDetails.transactionType !== "rt"
            }
          >
            <div role="presentation" className={classes.fb4}>
              <Tooltip title="Zmień datę księgowania" placement="left">
                {!queryInProgress_tr_details && (
                  accounting_clearance_change_bookdate || isSuperUser)
                  ? bookButtonEn
                  : bookButtonDi}
              </Tooltip>
            </div>
          </Zoom>

          {/*DIALOGS*/}
          <Dialog
            fullScreen
            open={dialogOpen}
            onClose={this.handleClickCloseDialog}
            aria-labelledby="form-dialog-title"
          >
            <AppBar position="static" style={{ background: "#0071BC" }}>
              <Toolbar>
                <IconButton
                  edge="start"
                  color="inherit"
                  onClick={this.handleClickCloseDialog}
                  aria-label="close"
                  className={classes.menuButton}
                >
                  <CloseIcon />
                </IconButton>
                <Typography variant="h6" className={classes.title}>
                  Szczegóły transakcji
                </Typography>
              </Toolbar>
            </AppBar>
            <DialogContent style={{ background: "#f5f5f5" }}>
              {transactionContent}
            </DialogContent>
          </Dialog>

          <Dialog
            open={dialogOpenDecline}
            onClose={this.handleCloseDeclineDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              Brak akceptacji rozliczenia
            </DialogTitle>
            <DialogContent>
              <Typography className={classes.textDescription3}>
                Dodaj komentarz uzasadniający powód braku akceptacji rozliczenia
                ...
              </Typography>
              <br></br>
              <Typography className={classes.textDescription4}>
                (max 150 znaków)
              </Typography>
              <ThemeProvider theme={materialTheme}>
                <TextField
                  autoFocus
                  margin="dense"
                  id="name"
                  label="komentarz"
                  fullWidth
                  inputProps={{ maxLength: 150 }}
                  multiline
                  rowsMax={4}
                  variant="filled"
                  value={clearanceOwnerComment || ""}
                  onChange={this.handleTextList("clearanceOwnerComment")}
                />
              </ThemeProvider>
            </DialogContent>
            <ThemeProvider theme={materialTheme}>
              <DialogActions>
                <Button
                  onClick={this.handleCloseDeclineDialog}
                  size="large"
                  color="default"
                >
                  Anuluj
                </Button>
                {clearanceOwnerComment !== ""
                  ? declineButtonEn
                  : declineButtonDi}
              </DialogActions>
            </ThemeProvider>
          </Dialog>

          <Dialog
            open={dialogOpenAccept}
            onClose={this.handleCloseAcceptDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              Akceptacja rozliczenia
            </DialogTitle>
            <DialogContent>
              <Typography className={classes.textDescription3}>
                Możesz dodać krótki komentarz do akceptacji rozliczenia ...
              </Typography>
              <br></br>
              <Typography className={classes.textDescription4}>
                (max 150 znaków)
              </Typography>
              <ThemeProvider theme={materialTheme}>
                <TextField
                  autoFocus
                  margin="dense"
                  id="name"
                  label="komentarz"
                  fullWidth
                  inputProps={{ maxLength: 150 }}
                  multiline
                  rowsMax={4}
                  variant="filled"
                  value={clearanceOwnerComment || ""}
                  onChange={this.handleTextList("clearanceOwnerComment")}
                />
              </ThemeProvider>
            </DialogContent>
            <ThemeProvider theme={materialTheme}>
              <DialogActions>
                <Button
                  onClick={this.handleCloseAcceptDialog}
                  size="large"
                  color="default"
                >
                  Anuluj
                </Button>
                <Button
                  onClick={this.handleAcceptClearance}
                  color="primary"
                  variant="contained"
                  autoFocus
                  size="large"
                >
                  Akceptuj
                </Button>
              </DialogActions>
            </ThemeProvider>
          </Dialog>

          <Dialog
            fullScreen
            open={dialogOpenPayback}
            onClose={this.handleCloseDialogPayback}
            aria-labelledby="form-dialog-title"
          >
            <AppBar position="static" style={{ background: "#0071BC" }}>
              <Toolbar>
                <IconButton
                  edge="start"
                  color="inherit"
                  onClick={this.handleCloseDialogPayback}
                  aria-label="close"
                  className={classes.menuButton}
                >
                  <CloseIcon />
                </IconButton>
                <Typography variant="h6" className={classes.title}>
                  {"Zarejestruj wypłatę dla " +
                    this.props.userDetails.first_name +
                    " " +
                    this.props.userDetails.last_name}
                </Typography>
              </Toolbar>
            </AppBar>
            <DialogContent style={{ background: "#f5f5f5" }}>
              <AddPaybackTransactionForm
                closeDialog={this.handleCloseDialogPayback}
                selectedCurrency={selectedCurrency}
                thisYear={thisYear}
              />
            </DialogContent>
          </Dialog>

          <Dialog
            fullScreen
            open={dialogOpenDeduct}
            onClose={this.handleCloseDialogDeduct}
            aria-labelledby="form-dialog-title"
          >
            <AppBar position="static" style={{ background: "#0071BC" }}>
              <Toolbar>
                <IconButton
                  edge="start"
                  color="inherit"
                  onClick={this.handleCloseDialogDeduct}
                  aria-label="close"
                  className={classes.menuButton}
                >
                  <CloseIcon />
                </IconButton>
                <Typography variant="h6" className={classes.title}>
                  {"Zarejestruj potrącenie dla " +
                    this.props.userDetails.first_name +
                    " " +
                    this.props.userDetails.last_name}
                </Typography>
              </Toolbar>
            </AppBar>
            <DialogContent style={{ background: "#f5f5f5" }}>
              <AddDeductTransactionForm
                closeDialog={this.handleCloseDialogDeduct}
                selectedCurrency={selectedCurrency}
                thisYear={thisYear}
              />
            </DialogContent>
          </Dialog>

          <Dialog
            fullScreen
            open={dialogOpenReturn}
            onClose={this.handleCloseDialogReturn}
            aria-labelledby="form-dialog-title"
          >
            <AppBar position="static" style={{ background: "#0071BC" }}>
              <Toolbar>
                <IconButton
                  edge="start"
                  color="inherit"
                  onClick={this.handleCloseDialogReturn}
                  aria-label="close"
                  className={classes.menuButton}
                >
                  <CloseIcon />
                </IconButton>
                <Typography variant="h6" className={classes.title}>
                  {"Zarejestruj zwrot do kasy od " +
                    this.props.userDetails.first_name +
                    " " +
                    this.props.userDetails.last_name}
                </Typography>
              </Toolbar>
            </AppBar>
            <DialogContent style={{ background: "#f5f5f5" }}>
              <AddReturnTransactionForm
                closeDialog={this.handleCloseDialogReturn}
                selectedCurrency={selectedCurrency}
                thisYear={thisYear}
              />
            </DialogContent>
          </Dialog>

          <Dialog
            fullScreen
            open={dialogAttachFile}
            onClose={this.handleCloseDialogAttachFile}
            aria-labelledby="form-dialog-title"
          >
            <AppBar position="static" style={{ background: "#0071BC" }}>
              <Toolbar>
                <IconButton
                  edge="start"
                  color="inherit"
                  onClick={this.handleCloseDialogAttachFile}
                  aria-label="close"
                  className={classes.menuButton}
                >
                  <CloseIcon />
                </IconButton>
                <Typography variant="h6" className={classes.title}>
                  Załącz dokument
                </Typography>
              </Toolbar>
            </AppBar>
            <DialogContent style={{ background: "#f5f5f5" }}>
              <CustomFileInput
                onChange={this.updateAttachInvoiceFilename}
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  placeholder: "wybierz plik ...",
                }}
                endButton={{
                  buttonProps: {
                    round: true,
                    color: "primary",
                    justIcon: true,
                    fileButton: true,
                  },
                  icon: <AttachFile />,
                }}
              />
              {invoice ? attachInvoiceButtonEn : attachInvoiceButtonDi}
            </DialogContent>
          </Dialog>

          <Dialog
            open={dialogNotify}
            onClose={this.handleCloseDialogNotify}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {"Monit do transakcji dla użytkownika " +
                this.props.userDetails.first_name +
                " " +
                this.props.userDetails.last_name}
            </DialogTitle>
            <DialogContent>
              <Typography className={classes.textDescription3}>
                Wybrana transakcja zostanie oznaczona <b>Monitem</b>. Użytkownik
                otrzyma SMS o treści zamieszczonej poniżej.
              </Typography>
              <br></br>
              <Typography className={classes.textDescription4}>
                (max 119 znaków)
              </Typography>
              <ThemeProvider theme={materialTheme}>
                <TextField
                  autoFocus
                  margin="dense"
                  id="name"
                  label="treść monitu"
                  fullWidth
                  inputProps={{ maxLength: 119 }}
                  rowsMax={2}
                  multiline={true}
                  value={monitMessage || ""}
                  onChange={this.handleTextList("monitMessage")}
                />
              </ThemeProvider>
            </DialogContent>
            <ThemeProvider theme={materialTheme}>
              <DialogActions>
                <Button
                  onClick={this.handleCloseDialogNotify}
                  size="large"
                  color="default"
                >
                  Anuluj
                </Button>
                <Button
                  onClick={this.handleAddMonit}
                  color="primary"
                  variant="contained"
                  autoFocus
                  size="large"
                >
                  Wyślij
                </Button>
              </DialogActions>
            </ThemeProvider>
          </Dialog>

          <Dialog
            open={dialogOpenChangeBookDate}
            onClose={this.handleCloseChangeBookDateDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              Zmień datę zaksięgowania transakcji
            </DialogTitle>
            <DialogContent>
              <ThemeProvider theme={materialTheme}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    className={classes.textFieldStatic}
                    autoOk
                    disableToolbar
                    variant="inline"
                    format="yyyy-MM-dd"
                    margin="normal"
                    id="date-picker-inline"
                    label="Data księgowania"
                    value={bookDate}
                    onChange={this.handleTransactionBookDateChange}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                  />
                </MuiPickersUtilsProvider>
              </ThemeProvider>
            </DialogContent>
            <ThemeProvider theme={materialTheme}>
              <DialogActions>
                <Button
                  onClick={this.handleCloseChangeBookDateDialog}
                  size="large"
                  color="default"
                >
                  Anuluj
                </Button>
                <div className={classes.wrapper}>
                  <Button
                    onClick={this.handleChangeBookDate}
                    color="primary"
                    variant="contained"
                    autoFocus
                    size="large"
                    disabled={dataChangeInProgress}
                  >
                    Zmień
                  </Button>
                  {dataChangeInProgress && (
                    <CircularProgress
                      size={24}
                      className={classes.buttonProgress}
                    />
                  )}
                </div>
              </DialogActions>
            </ThemeProvider>
          </Dialog>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  usersList: state.common.users,
  queryInProgress: state.common.queryInProgress,
  userDetails: state.common.suser,
  transactionsStat_ak: state.accounting.transactionsStat_ak,
  transactionsStat_ar: state.accounting.transactionsStat_ar,
  transactionsStat_noin: state.accounting.transactionsStat_noin,
  transactionDetails: state.accounting.transactionDetails,
  queryInProgress_stat_ak: state.accounting.queryInProgress_stat_ak,
  queryInProgress_stat_ar: state.accounting.queryInProgress_stat_ar,
  queryInProgress_stat_noin: state.accounting.queryInProgress_stat_noin,
  queryInProgress_tr_details: state.accounting.queryInProgress_tr_details,
  balanceEUR: state.accounting.balanceEUR,
  balancePLN: state.accounting.balancePLN,
  balanceSEK: state.accounting.balanceSEK,
  balanceNOK: state.accounting.balanceNOK,
  dataChangeInProgress: state.accounting.dataChangeInProgress,
});

export default connect(
  mapStateToProps,
  {
    getTransactionsStat,
    getTransactionDetails,
    patchTransactionDetails,
    getUsersList,
    getUserDetails,
    clearUserDetails,
    patchProfile,
    getTransactions,
    getTransactionsByYear,
    clearTransactions,
    patchTransaction_admin,
    changeTransactionBookDate,
  }
)(withStyles(styles, { withTheme: true })(ClearanceView));
