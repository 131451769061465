import React, { Component, Fragment } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import AddAssetForm from "./AddAssetForm";
import AddAssetFormWar from "./AddAssetFormWar";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { styles } from "../../common/radiolineStyles";
import { withStyles } from "@material-ui/core/styles";
import { addAsset } from "../../Redux/actions/assets";

export class DialogAddAsset extends Component {
  static propTypes = {
    addAsset: PropTypes.func.isRequired,
  };

  handleCloseDialogAddAsset = () => {
    this.props.closeDialog();
  };

  handleAddAsset = (assetBody, modelSelected) => {
    this.props.addAsset(assetBody, modelSelected.eqtype);
    this.props.closeDialog();
  };

  render() {
    const { classes, dialogOpenAddAsset, who } = this.props;

    var assetform = null;
    if (who === "warehouse") {
      assetform = <AddAssetFormWar addAsset={this.handleAddAsset} />;
    } else if (who === "employee") {
      assetform = <AddAssetForm addAsset={this.handleAddAsset} />;
    }

    return (
      <Fragment>
        <Dialog
          fullScreen
          open={dialogOpenAddAsset}
          onClose={this.handleCloseDialogAddAsset}
          aria-labelledby="form-dialog-title"
        >
          <AppBar position="static" style={{ background: "#0071BC" }}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={this.handleCloseDialogAddAsset}
                aria-label="close"
                className={classes.menuButton}
              >
                <CloseIcon />
              </IconButton>
              <Typography variant="h6" className={classes.title}>
                Dodaj nowy Środek Trwały
              </Typography>
            </Toolbar>
          </AppBar>
          <DialogContent style={{ background: "#f5f5f5" }}>
            {assetform}
          </DialogContent>
        </Dialog>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({});

export default connect(
  mapStateToProps,
  {
    addAsset,
  }
)(withStyles(styles, { withTheme: true })(DialogAddAsset));
