import {
  GET_USERS_LIST,
  GET_SUSER_DETAILS,
  CLEAR_SUSER_DETAILS,
  GET_TUSER_DETAILS,
  CLEAR_TUSER_DETAILS,
  C_QUERY_FAILED,
  C_QUERY_IN_PROGRESS,
  GET_USERS_FULL_LIST,
  GET_USERS_CONTACTS_LIST,
  GET_USER_FULL_DETAILS,
  CLEAR_USER_FULL_DETAILS,
  CF_QUERY_FAILED,
  CF_QUERY_IN_PROGRESS,
  PATCH_PROFILE_EM,
  PATCH_SUSER_PROFILE,
  CFC_QUERY_FAILED,
  CFC_QUERY_IN_PROGRESS,


  GET_DOCUMENTS,
  GET_DOCUMENT_DETAIL,
  DELETE_DOCUMENT,
  ADD_DOCUMENT,
  UPDATE_DOCUMENT,
  PATCH_DOCUMENT,
  GET_COMPANIES
} from "../actions/types";

const initialState = {
  queryInProgress: false,
  users: [],
  suser: {},
  tuser: {},
  queryInProgressCF: false,
  queryInProgressCFC: false,
  usersFull: [],
  usersContacts: [],
  userFull: {},

  documents: [],
  companies: [],

};

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_DOCUMENTS:
      return {
        ...state,
        documents: action.payload,
        queryInProgress: false,
      };
    case GET_COMPANIES:
        return {
          ...state,
          companies: action.payload,
          queryInProgress: false,
      };
    case GET_USERS_LIST:
      return {
        ...state,
        users: action.payload,
        queryInProgress: false,
      };
    case GET_SUSER_DETAILS:
      return {
        ...state,
        suser: action.payload,
        queryInProgress: false,
      };
    case CLEAR_SUSER_DETAILS:
      return {
        ...state,
        suser: {},
      };
    case GET_TUSER_DETAILS:
      return {
        ...state,
        tuser: action.payload,
        queryInProgress: false,
      };
    case CLEAR_TUSER_DETAILS:
      return {
        ...state,
        tuser: {},
      };
    case C_QUERY_IN_PROGRESS:
      return {
        ...state,
        queryInProgress: true,
      };
    case C_QUERY_FAILED:
      return {
        ...state,
        queryInProgress: false,
      };
    case GET_USERS_FULL_LIST:
      return {
        ...state,
        usersFull: action.payload,
        queryInProgressCF: false,
      };
    case GET_USERS_CONTACTS_LIST:
      return {
        ...state,
        usersContacts: action.payload,
        queryInProgressCFC: false,
      };
    case GET_USER_FULL_DETAILS:
      return {
        ...state,
        userFull: action.payload,
        queryInProgressCF: false,
      };
    case CLEAR_USER_FULL_DETAILS:
      return {
        ...state,
        userFull: {},
      };
    case CF_QUERY_IN_PROGRESS:
      return {
        ...state,
        queryInProgressCF: true,
      };
    case CF_QUERY_FAILED:
      return {
        ...state,
        queryInProgressCF: false,
      };
    case CFC_QUERY_IN_PROGRESS:
      return {
        ...state,
        queryInProgressCFC: true,
      };
    case CFC_QUERY_FAILED:
      return {
        ...state,
        queryInProgressCFC: false,
      };
    default:
      return state;
  }
}
