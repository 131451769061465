import React, { Component, Fragment } from "react";
import clsx from "clsx";
import CircularProgress from "@material-ui/core/CircularProgress";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { getMyApplicationKids, clearApplicationKids } from "../../Redux/actions/zfss.js";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { lighten } from "@material-ui/core/styles";
import { createMuiTheme } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";

import Tooltip from "@material-ui/core/Tooltip";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import GetAppIcon from "@material-ui/icons/GetApp";
import CloseIcon from '@material-ui/icons/Close';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { doc } from '../../PDFGen/DejaVuSansCondensed'
import { generate } from '../Paczki/PaczkiDocument.js';
import KidsTable from './KidsTable';

const materialTheme = createMuiTheme({
  palette: {
    primary: {
      main: "#0071BC",
    },
    secondary: {
      main: "#0071BC"
    }
  },
  overrides: {
    MuiChip: {
      root: {
        color: "#ffffff",
        "& $avatar": {
          color: "#ffffff",
        },
      },
      icon: {
        color: "#ffffff",
      },
    },
    MuiCardActions: {
      root: {
        background: "#aaaaaa",
      },
    },
  },
});

const styles = theme => ({
  root: {
    width: "100%"
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2)
  },
  table: {
    minWidth: 750
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1
  },
  checkedIcon: {
    width: "20px",
    height: "20px",
    border: "1px solid rgba(0, 0, 0, 0.84)",
    borderRadius: "3px"
  },
  uncheckedIcon: {
    width: "0px",
    height: "0px",
    padding: "9px",
    border: "1px solid rgba(0, 0, 0, .54)",
    borderRadius: "3px"
  },
  checked: {
    color: "#0071BC"
  },
  checkRoot: {
    padding: "14px",
    "&:hover": {
      backgroundColor: "unset"
    }
  },
  roottb: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1)
  },
  highlighttb:
    theme.palette.type === "light"
      ? {
        color: "#0071BC",
        backgroundColor: lighten("#338dc9", 0.85)
      }
      : {
        color: "#0071BC",
        backgroundColor: "#004f83"
      },
  titletb: {
    flex: "1 1 100%",
    textAlign: "left"
  }
});

export class DochodyViewer extends Component {
  state = {
    selectedIndex: null,
    isKidSelected: false,
    selectedKid: null,
    expanded: false,
  };

  static propTypes = {
    auth: PropTypes.object.isRequired,
    kids: PropTypes.array.isRequired,
    queryInProgressKids: PropTypes.bool.isRequired,
    getMyApplicationKids: PropTypes.func.isRequired,
    clearApplicationKids: PropTypes.func.isRequired,
  };

  componentDidMount() {
    this.props.getMyApplicationKids(this.props.auth.user.id);
  }

  componentWillUnmount() {
    this.props.clearApplicationKids();
  }

  handleClick = (row, event) => {
    if (this.state.isKidSelected && this.state.selectedIndex == row.id) {
      this.setState({
        selectedIndex: null,
        isKidSelected: false,
        selectedKid: null,
        expanded: false,
      })
    }
    else {
      this.setState({
        selectedIndex: row.id,
        isKidSelected: true,
        selectedKid: row,
        expanded: true,
      })
    }
  };

  handleCloseClick = (event) => {
    this.setState({
      selectedIndex: null,
      isKidSelected: false,
      selectedKid: null,
    })
  };

  handlePreviewClick = (event) => {
    const row = this.state.selectedKid;
    const rowKids = row.application_kids;
    const childrenCount = rowKids.length;
    var child_fn = [];
    var child_ln = [];
    var child_bd = [];

    for (var i = 0; i < childrenCount; i++) {
      child_fn[i] = rowKids[i].first_name;
      child_ln[i] = rowKids[i].last_name;
      child_bd[i] = rowKids[i].birth_date;
    }

    const data = {
      firstName: row.owner.first_name,
      lastName: row.owner.last_name,
      childrenCount,
      child_fn,
      child_ln,
      child_bd,
      date: new Date(row.date),
    }
    generate(doc, data);
  };

  calcAge = (birthDate) => {
    birthDate = new Date(birthDate);
    var ageAtDate = new Date();
    var _m = ageAtDate.getMonth() - birthDate.getMonth();

    return (ageAtDate.getFullYear()) - birthDate.getFullYear()
      - ((_m < 0 || (_m === 0 && ageAtDate.getDate() < birthDate.getDate())) ? 1 : 0)
  }

  handleDownload = () => {
    var csvRow = [];
    var A = [
      [
        "Numer wnioski",
        "Data złożenia",
        "Nazwisko dziecka",
        "Imię dziecka",
        "Data urodzenia",
        "Wiek",
        "Nazwisko pracownika",
        "Imię pracownika",
      ]
    ];
    var rows = this.props.kids;

    if (rows.length > 0) {
      for (var j = 0; j < rows.length; j++) {
        var kids = rows[j].application_kids;
        for (var i = 0; i < kids.length; i++) {
          A.push([
            rows[j].number,
            new Date(rows[j].date).toISOString().split('T')[0],
            kids[i].last_name,
            kids[i].first_name,
            kids[i].birth_date,
            this.calcAge(kids[i].birth_date),
            rows[j].owner.last_name,
            rows[j].owner.first_name,
          ]);
        }
      }
    }

    for (var i = 0; i < A.length; ++i) {
      csvRow.push(A[i].join(";"));
    }

    var csvString = csvRow.join("\n");
    var link = window.document.createElement("a");
    link.setAttribute(
      "href",
      "data:text/csv;charset=utf-8,%EF%BB%BF" + encodeURI(csvString)
    );
    link.setAttribute("download", "wnioski_eksport.csv");
    link.click();
  };

  render() {
    const { classes, kids, queryInProgressKids } = this.props;
    const { selectedIndex, isKidSelected, expanded } = this.state;

    const queryInProgress = <CircularProgress color="secondary" />;

    const enhancedTableToolbar = (
      <ThemeProvider theme={materialTheme}>
        <Toolbar
          className={clsx(classes.roottb, {
            [classes.highlighttb]: isKidSelected
          })}
        >
          {isKidSelected ? (
            <Typography
              className={classes.titletb}
              color="inherit"
              variant="subtitle1"
            >
              Dostępne działania:
            </Typography>
          ) : (
            <Fragment>
              <Typography
                className={classes.titletb}
                variant="h6"
                id="tableTitle"
              >
                Przesłane wnioski
              </Typography>
            </Fragment>
          )}

          {isKidSelected ? (
            <Fragment>
              <Tooltip title="Pobierz dokument">
                <IconButton aria-label="preview" onClick={this.handlePreviewClick}>
                  <GetAppIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Zamknij">
                <IconButton aria-label="done" onClick={this.handleCloseClick}>
                  <CloseIcon />
                </IconButton>
              </Tooltip>
            </Fragment>
          ) : (
            <Fragment>
              {queryInProgressKids ? queryInProgress : ""}
              <Tooltip title="Eksportuj wszystkie dane">
                <IconButton
                  aria-label="filter list"
                  onClick={this.handleDownload}
                >
                  <GetAppIcon />
                </IconButton>
              </Tooltip>
            </Fragment>
          )}
        </Toolbar>
      </ThemeProvider>
    );

    const expand = (
      <TableCell align="right"><ExpandMoreIcon /></TableCell>
    );

    return (
      <Fragment>
        <Grid container justify="flex-start" spacing={2} style={{ width: "100%", padding: "1%" }}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Paper className={classes.paper}>
              <ThemeProvider theme={materialTheme}>
                {enhancedTableToolbar}
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell width="10%">ID</TableCell>
                        <TableCell width="10%" align="center">Data złożenia</TableCell>
                        <TableCell width="15%" align="center">Pracownik</TableCell>
                        <TableCell align="center">Dane</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {kids.map((row) => (
                        <TableRow
                          hover
                          key={row.id}
                          onClick={this.handleClick.bind(this, row)}
                          selected={row.id == selectedIndex ? true : false}
                        >
                          <TableCell width="10%">{row.number}</TableCell>
                          <TableCell width="10%" align="center">{(row.date).split('T')[0]}</TableCell>
                          <TableCell align="center">{row.owner.last_name + " " + row.owner.first_name}</TableCell>
                          {row.id == selectedIndex ? <KidsTable kids={row.application_kids} expanded={expanded} /> : expand}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </ThemeProvider>
            </Paper>
          </Grid>
        </Grid>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  kids: state.zfss.kids,
  queryInProgressKids: state.zfss.queryInProgressKids,
});

export default connect(
  mapStateToProps,
  { getMyApplicationKids, clearApplicationKids }
)(withStyles(styles, { withTheme: true })(DochodyViewer));
