import React, { Component, Fragment } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import AddAssetModelForm from "../Common/AddAssetModelForm";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { styles } from "../../common/radiolineStyles";
import { addAssetModel, addVendor } from "../../Redux/actions/assets";
import Grid from "@material-ui/core/Grid";
import Card from "../../components/Card/Card.js";
import CardBody from "../../components/Card/CardBody.js";
import { ThemeProvider } from "@material-ui/styles";
import { createMuiTheme } from "@material-ui/core";

const materialTheme = createMuiTheme({
  palette: {
    primary: {
      main: "#0071BC",
    },
  },
  overrides: {
    MuiTextField: {
      root: {
        width: "auto",
      },
    },
    MuiFormLabel: {
      root: {
        color: "#0071bc",
      },
    },
  },
});


export class DialogAddAssetModelInteractive extends Component {
  static propTypes = {
    addAssetModel: PropTypes.func.isRequired,
    addVendor: PropTypes.func.isRequired,
    assetData: PropTypes.object.isRequired,
  };

  handleAddVendor = (vendorBody) => {
    this.props.addVendor(vendorBody);
  };

  handleAddAssetModel = (assetModelBody) => {
    this.props.addAssetModel(assetModelBody);
    this.props.closeDialogAddAssetModel();
  };

  handleCloseDialogAddAssetModel = () => {
    this.props.closeDialogAddAssetModel();
  };

  render() {
    const { classes, dialogOpenAddAssetModel, assetData } = this.props;
    return (
      <Fragment>
        <Dialog
          fullScreen
          open={dialogOpenAddAssetModel}
          onClose={this.handleCloseDialogAddAssetModel}
          aria-labelledby="form-dialog-title"
        >
          <AppBar position="static" style={{ background: "#0071BC" }}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={this.handleCloseDialogAddAssetModel}
                aria-label="close"
                className={classes.menuButton}
              >
                <CloseIcon />
              </IconButton>
              <Typography variant="h6" className={classes.title}>
                Dodaj nowy model Środka Trwałego
              </Typography>
            </Toolbar>
          </AppBar>
          <DialogContent style={{ background: "#f5f5f5" }}>
            <Grid container justify="center" spacing={2}>
              <Grid item xs={6} md={9}>
                <AddAssetModelForm
                  addAssetModel={this.handleAddAssetModel}
                  addVendor={this.handleAddVendor}
                />
              </Grid>
              <Grid item xs={6} md={3}>
                <Card style={{ height: 'calc(100% - 141.5px)', display: 'flex', flexDirection: 'column' }} >
                  <CardBody style={{ flex: 1 }}>
                    <ThemeProvider theme={materialTheme}>
                      <Typography
                        style={{
                          marginBottom: "50px",
                          color: "#888888",
                          textAlign: "left",
                          fontFamily: "Lato, Open Sans, sans-serif",
                          fontSize: "1em",
                          fontWeight: "300",
                        }}
                      >
                        Informacje podane podczas inwentury...
                      </Typography>
                      <Typography>
                        <b>Producent:</b> {assetData.vendor}
                      </Typography>
                      <Typography>
                        <b>Model:</b> {assetData.model}
                      </Typography>
                      <Typography>
                        <b>Nazwa:</b> {assetData.itemName}
                      </Typography>
                      <Typography>
                        <b>Opis:</b> {assetData.description}
                      </Typography>
                    </ThemeProvider>
                  </CardBody>
                </Card>
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>
      </Fragment >
    );
  }
}

const mapStateToProps = (state) => ({});

export default connect(
  mapStateToProps,
  { addAssetModel, addVendor }
)(withStyles(styles, { withTheme: true })(DialogAddAssetModelInteractive));
