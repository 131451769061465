import React, { Component, Fragment } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import Card from "../../components/Card/Card.js";
import CardBody from "../../components/Card/CardBody.js";
import Typography from "@material-ui/core/Typography";
import { createMuiTheme } from "@material-ui/core";

import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getFormattedDate } from "../../common/commonFunctions.js";

const materialTheme = createMuiTheme({
  palette: {
    primary: {
      main: "#0071BC",
    },
  },
  overrides: {
    MuiChip: {
      root: {
        color: "#ffffff",
        "& $avatar": {
          color: "#ffffff",
        },
      },
      icon: {
        color: "#ffffff",
      },
    },
    MuiCardActions: {
      root: {
        background: "#aaaaaa",
      },
    },
    MuiIconButton: {
      root: {
        color: "#fff",
      },
    },
  },
});

const styles = (theme) => ({
  avatar: {
    backgroundColor: "#0071BC",
    height: 50,
    width: 50,
  },
  Address: {
    textAlign: "left",
    fontFamily: "Lato, Open Sans, sans-serif",
    fontSize: "1em",
    fontWeight: "300",
    color: "#444444",
  },
  Position: {
    textAlign: "left",
    fontFamily: "Lato, Open Sans, sans-serif",
    fontSize: "1em",
    fontWeight: "400",
    color: "#0071BC",
  },
  Name: {
    textAlign: "left",
    fontFamily: "Lato, Open Sans, sans-serif",
    fontSize: "2em",
    fontWeight: "700",
    color: "#444444",
  },
  ProfileBar: {
    textAlign: "left",
    fontFamily: "Lato, Open Sans, sans-serif",
    fontSize: "1em",
    fontWeight: "400",
    color: "#444444",
  },
  Country: {
    textAlign: "left",
    fontFamily: "Lato, Open Sans, sans-serif",
    fontSize: "1em",
    fontWeight: "300",
    color: "#444444",
    textTransform: "uppercase",
  },
  large: {
    width: "90px",
    height: "90px",
  },
});

export class InspectionDetailsCard extends Component {
  static propTypes = {
    inspectionDetails: PropTypes.object.isRequired,
  };

  render() {
    const { classes, inspectionDetails } = this.props;

    if (inspectionDetails == null) {
      return <Fragment></Fragment>;
    }

    const team = inspectionDetails.team;
    const driver = Array.isArray(team) && team.length > 0
      ? team.filter((employee) => employee.isDriver === true)[0]?.employee
      : null;

    const driverName = driver
      ? "Kierowca: " + driver.owner.first_name + " " + driver.owner.last_name
      : "Kierowca: Brak kierowcy";

    const passenger = inspectionDetails.team.filter((employee) => employee.isDriver == false);
    const passengerNames = passenger.map((passenger) => passenger.employee.owner.first_name + " " + passenger.employee.owner.last_name).join(", ");

    return (
      <Fragment>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Card style={{ marginTop: "0px", marginBottom: "0px" }}>
            <CardBody>
              <Grid container justify="space-between" alignItems="center" spacing={2}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.Name}>
                    Szczegóły
                  </Typography>
                  <Typography className={classes.Position}>
                    {"Data przeglądu: " + getFormattedDate(new Date(inspectionDetails.inspectionDate))}
                  </Typography>
                  <Typography className={classes.Address}>
                    {"Przebieg: " + inspectionDetails.mileage + " km"}
                  </Typography>
                  <Typography className={classes.Address}>
                    {"Status: " + inspectionDetails.status_description}
                  </Typography>
                  <Typography className={classes.Address}>
                    {"Szacunkowy koszt naprawy: " + inspectionDetails.approxRepairCost + " zł"}
                  </Typography>
                  <Typography className={classes.Address}>
                    {"Ostateczny koszt naprawy: " + (inspectionDetails.repairCost != null ? (inspectionDetails.repairCost + " zł") : "Nie podano")}
                  </Typography>
                  <Typography className={classes.Address}>
                    {"Data naprawy: " + (inspectionDetails.repariDate != null ? getFormattedDate(new Date(inspectionDetails.repariDate)) : "Nie podano")}
                  </Typography>
                  <br></br>
                  <Typography className={classes.Address}>
                    {"Opis:"}
                  </Typography>
                  <Typography className={classes.Address}>
                    {inspectionDetails.description}
                  </Typography>
                  <br></br>
                  <Typography className={classes.Position}>
                    {driverName}
                  </Typography>
                  <Typography className={classes.Position}>
                    {"Pasażerowie: " + (passengerNames != "" ? passengerNames : "Brak pasażerów")}
                  </Typography>
                </Grid>
              </Grid>
            </CardBody>
          </Card>
        </Grid>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  inspectionDetails: state.fleet.inspectionDetails,
});

export default connect(
  mapStateToProps,
  {}
)(withStyles(styles, { withTheme: true })(InspectionDetailsCard));
