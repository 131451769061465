import { green } from "@material-ui/core/colors";
import { primaryColor } from "../assets/jss/material-kit-pro-react";
import { lighten } from "@material-ui/core/styles";
import { createMuiTheme } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";

export const CssTextField = withStyles({
  root: {
    width: 300,
    flexWrap: "wrap",
    marginBottom: "50px",

    "& label.Mui-focused": {
      color: "#0071bc",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#0071bc",
    },
  },
})(TextField);

export const materialTheme = createMuiTheme({
  palette: {
    primary: {
      main: "#0071BC",
    },
  },
  overrides: {
    MuiFormLabel: {
      root: {
        "&.Mui-focused": {
          color: "#0071BC",
        },
      },
    },
    MuiInput: {
      underline: {
        "&:after": {
          borderBottomColor: "#0071bc",
        },
      },
    },
    MuiFilledInput: {
      underline: {
        "&:after": {
          borderBottomColor: "#0071bc",
        },
      },
    },
    MuiButton: {
      root: {},
      textPrimary: {
        color: "#0071BC",
      },
      containedPrimary: {
        backgroundColor: "#4CAF50",
        "&:hover": {
          backgroundColor: "#449A48",
        },
      },
    },
    MuiIconButton: {
      colorPrimary: {
        color: "#0071BC",
      },
    },
  },
});

export const radioButtonsTheme = createMuiTheme({
  palette: {
    primary: {
      main: "#0071BC",
    },
  },
  overrides: {
    MuiTextField: {
      root: {
        width: "auto",
      },
    },
    MuiFormLabel: {
      root: {
        color: "#0071bc",
      },
    },
    MuiFormControl: {
      root: {
        display: "inherit",
      },
    },
  },
});

export const formsTheme = createMuiTheme({
  palette: {
    primary: {
      main: "#0071BC",
    },
  },
});

export const listStyles = (theme) => ({
  infoListFetch: {
    fontFamily: "Lato, Open Sans, sans-serif",
    fontSize: "1.5em",
    fontWeight: "300",
    color: "#0071BC",
  },
  avatarInfo1: {
    backgroundColor: "#2196f3",
    height: 50,
    width: 50,
  },
  headerInfo1: {
    textAlign: "left",
    backgroundColor: lighten("#2196f3", 0.85),
  },
  avatarHistory: {
    backgroundColor: "#777777",
    height: 50,
    width: 50,
  },
  headerHistory: {
    textAlign: "left",
    backgroundColor: lighten("#777777", 0.85),
  },
  headertitle: {
    fontFamily: "Lato, Open Sans, sans-serif",
    fontSize: "1.2em",
    fontWeight: "700",
    color: "#444444",
  },
  subheaderText: {
    textAlign: "left",
    fontFamily: "Lato, Open Sans, sans-serif",
    fontSize: "1em",
    fontWeight: "300",
    color: "#999999",
  },
  subheaderTextContrast: {
    textAlign: "left",
    fontFamily: "Lato, Open Sans, sans-serif",
    fontSize: "1em",
    fontWeight: "300",
    color: "#000000",
  },
  rootListWithSubheaders: {
    width: "100%",
    //maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
    position: "relative",
    overflow: "auto",
    maxHeight: 510,
  },
  listSectionWithSubheaders: {
    backgroundColor: "inherit",
  },
  ulWithSubheaders: {
    backgroundColor: "inherit",
    padding: 0,
    textAlign: "left",
  },
  listTextSecondaryInline: {
    textAlign: "left",
    color: "#444444",
    fontFamily: "Lato, Open Sans, sans-serif",
    fontSize: "1em",
    fontWeight: "300",
    //display: "block",
  },
});

export const cardStyles = (theme) => ({
  avatarHistory: {
    //backgroundColor: "#2196f3",
    height: 50,
    width: 50,
  },
  avatarHistoryLarge: {
    //backgroundColor: "#2196f3",
    height: 100,
    width: 100,
  },
  CardDescriptionHistory: {
    textAlign: "left",
    fontFamily: "Lato, Open Sans, sans-serif",
    fontSize: "2em",
    fontWeight: "700",
    color: "#bdbdbd",
  },
  listTextSecondaryInline: {
    textAlign: "left",
    color: "#444444",
    fontFamily: "Lato, Open Sans, sans-serif",
    fontSize: "1em",
    fontWeight: "300",
    //display: "block",
  },
  infoListFetch: {
    fontFamily: "Lato, Open Sans, sans-serif",
    fontSize: "1.5em",
    fontWeight: "300",
    color: "#0071BC",
  },
  avatarInfo1: {
    backgroundColor: "#2196f3",
    height: 50,
    width: 50,
  },
  avatarInfoLight: {
    backgroundColor: "#64b5f6",
    height: 50,
    width: 50,
  },
  CardDescriptionInfo1: {
    textAlign: "left",
    fontFamily: "Lato, Open Sans, sans-serif",
    fontSize: "2em",
    fontWeight: "700",
    color: "#2196f3",
  },
  avatarWarning1: {
    backgroundColor: "#ff9800",
    height: 50,
    width: 50,
  },

  avatarWarningLight: {
    backgroundColor: "#ffb74d",
    height: 50,
    width: 50,
  },

  avatarSuccessLight: {
    backgroundColor: "#81c784",
    height: 50,
    width: 50,
  },

  CardDescriptionWarning1: {
    textAlign: "left",
    fontFamily: "Lato, Open Sans, sans-serif",
    fontSize: "2em",
    fontWeight: "700",
    color: "#ff9800",
  },
  avatarError1: {
    backgroundColor: "#dc004e",
    height: 50,
    width: 50,
  },
  CardDescriptionError1: {
    textAlign: "left",
    fontFamily: "Lato, Open Sans, sans-serif",
    fontSize: "2em",
    fontWeight: "700",
    color: "#dc004e",
  },

  CardDescriptionSuccessMain: {
    textAlign: "left",
    fontFamily: "Lato, Open Sans, sans-serif",
    fontSize: "2em",
    fontWeight: "700",
    color: "#4caf50",
  },

  CardTitle: {
    textAlign: "left",
    fontFamily: "Lato, Open Sans, sans-serif",
    fontSize: "1em",
    fontWeight: "300",
    color: "#999999",
  },

  CardHeader: {
    textAlign: "left",
    fontFamily: "Lato, Open Sans, sans-serif",
    fontSize: "1.2em",
    fontWeight: "700",
    color: "#444444",
  },
  headerInfo1: {
    textAlign: "left",
    //backgroundColor: lighten("#2196f3", 0.85),
  },
  CardSubheader: {
    textAlign: "left",
    fontFamily: "Lato, Open Sans, sans-serif",
    fontSize: "1em",
    fontWeight: "400",
    color: "#444444",
  },

  CardButtonLabel: {
    textAlign: "center",
    fontFamily: "Lato, Open Sans, sans-serif",
    fontSize: "1.2em",
    fontWeight: "700",
    color: "#ffffff",
  },
  media: {
    height: 140,
  },
  root: {
    maxWidth: 345,
  },
  textColorGrayMain: {
    color: "#aaaaaa",
  },
  textColorBlueMain: {
    color: "#1976d2",
  },
  textColorGreenMain: {
    color: "#4caf50",
  },
  textColorGreenLight: {
    color: "#81c784",
  },
  textColorOrangeMain: {
    color: "#ff9800",
  },
  textColorPinkMain: {
    color: "#e27bb1",
  },
  textColorRedMain: {
    color: "#dc004e",
  },
  textCenterXU: {
    position: "absolute",
    top: "60px",
    left: "50%",
    transform: "translateX(-50%)",
  },
  textCenterXL: {
    position: "absolute",
    top: "90px",
    left: "50%",
    transform: "translateX(-50%)",
  },
  textInternalU: {
    textAlign: "left",
    fontFamily: "Lato, Open Sans, sans-serif",
    fontSize: "1.5em",
    fontWeight: "400",
    color: "#444444",
  },
  textInternalL: {
    textAlign: "left",
    fontFamily: "Lato, Open Sans, sans-serif",
    fontSize: "1em",
    fontWeight: "300",
    color: "#444444",
  },
  rootTableWithSubheaders: {
    maxHeight: 250,
  },
  fixedheight250: {
    height: 250,
  },
  rootTableAvatarSmall: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
});

export const styles = (theme) => ({
  //style dla loading na buttonie
  wrapper: {
    margin: theme.spacing(1),
    position: "relative",
  },
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  //pozostale
  rootalert: {
    width: "300px",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
  buttonAccept: {
    color: theme.palette.common.white,
    backgroundColor: "#4caf50",
    "&:hover": {
      backgroundColor: "#388e3c",
    },
  },
  section: {
    backgroundPosition: "50%",
    backgroundSize: "cover",
    padding: 0,
    marginTop: "30px",
  },
  cardsGroup: {
    textAlign: "left",
    color: "#444444",
    fontFamily: "Lato, Open Sans, sans-serif",
    fontSize: "1.2em",
    fontWeight: "700",
    marginTop: "30px",
    marginBottom: "10px",
  },
  textCenter: {
    textAlign: "center",
  },
  tabSpace: {
    // padding: "20px 0 50px"
  },
  fb1: {
    position: "fixed",
    bottom: theme.spacing(20),
    right: theme.spacing(5),
    zIndex: "100",
  },
  fb2: {
    position: "fixed",
    bottom: theme.spacing(2),
    right: theme.spacing(5),
    zIndex: "100",
  },
  fb3: {
    position: "fixed",
    bottom: theme.spacing(26),
    right: theme.spacing(5),
    zIndex: "100",
  },
  fb4: {
    position: "fixed",
    bottom: theme.spacing(32),
    right: theme.spacing(5),
    zIndex: "100",
  },
  fb5: {
    position: "fixed",
    bottom: theme.spacing(8),
    right: theme.spacing(5),
    zIndex: "100",
  },
  fb6: {
    position: "fixed",
    bottom: theme.spacing(14),
    right: theme.spacing(5),
    zIndex: "100",
  },
  fabgreen: {
    color: theme.palette.common.white,
    backgroundColor: "#4caf50",
    "&:hover": {
      backgroundColor: green[600],
    },
  },
  fabblue: {
    color: theme.palette.common.white,
    backgroundColor: "#2196f3",
    "&:hover": {
      backgroundColor: "#1976d2",
    },
  },
  fabred: {
    color: theme.palette.common.white,
    backgroundColor: "#dc004e",
    "&:hover": {
      backgroundColor: "#9a0036",
    },
  },
  info: {
    borderRadius: "3px",
    color: "#FFF",
    background:
      "linear-gradient(60deg, " +
      primaryColor[1] +
      ", " +
      primaryColor[2] +
      ")",
    width: "100%",
    height: "100%",
  },
  textType: {
    color: "#888888",
    textAlign: "left",
    fontFamily: "Lato, Open Sans, sans-serif",
    textTransform: "uppercase",
    fontSize: "1em",
    fontWeight: "300",
  },
  textType2: {
    color: "#0071BC",
    textAlign: "left",
    fontFamily: "Lato, Open Sans, sans-serif",
    fontSize: "1em",
    fontWeight: "300",
  },
  textName: {
    textAlign: "left",
    color: "#444444",
    fontFamily: "Lato, Open Sans, sans-serif",
    fontSize: "1.4em",
    fontWeight: "700",
  },
  textName2: {
    textAlign: "left",
    fontFamily: "Lato, Open Sans, sans-serif",
    fontSize: "1em",
    fontWeight: "300",
  },
  textName3: {
    textAlign: "left",
    fontFamily: "Lato, Open Sans, sans-serif",
    fontSize: "1em",
    fontWeight: "300",
    color: "#999999",
  },
  textDescription: {
    textAlign: "left",
    fontFamily: "Lato, Open Sans, sans-serif",
    fontSize: "1em",
    fontWeight: "300",
  },
  textDescription2: {
    textAlign: "left",
    fontFamily: "Lato, Open Sans, sans-serif",
    fontSize: "1em",
    fontWeight: "700",
  },
  textDescription3: {
    textAlign: "left",
    fontFamily: "Lato, Open Sans, sans-serif",
    fontSize: "1em",
    fontWeight: "400",
  },
  textDescription4: {
    textAlign: "left",
    fontFamily: "Lato, Open Sans, sans-serif",
    fontSize: "0.7em",
    fontWeight: "700",
    color: "#0071BC",
  },
  textDescription5: {
    textAlign: "left",
    fontFamily: "Lato, Open Sans, sans-serif",
    fontSize: "0.8em",
    fontWeight: "400",
    color: "#999999",
  },
  textDescription6: {
    textAlign: "left",
    fontFamily: "Lato, Open Sans, sans-serif",
    fontSize: "1.2em",
    fontWeight: "400",
    color: "#444444",
  },
  textDescription7: {
    textAlign: "left",
    fontFamily: "Lato, Open Sans, sans-serif",
    fontSize: "0.8em",
    fontWeight: "400",
    color: "#0071BC",
  },
  textDescription8: {
    textAlign: "left",
    fontFamily: "Lato, Open Sans, sans-serif",
    fontSize: "1.2em",
    fontWeight: "400",
    color: "#f57c00",
  },
  textGridTitle: {
    fontFamily: "Lato, Open Sans, sans-serif",
    fontSize: "1em",
    fontWeight: "700",
    color: "#444444",
  },
  textGridContent: {
    fontFamily: "Lato, Open Sans, sans-serif",
    fontSize: "1.4em",
    fontWeight: "400",
    color: "#0071BC",
  },
  listTextSecondary: {
    textAlign: "left",
    color: "#444444",
    fontFamily: "Lato, Open Sans, sans-serif",
    fontSize: "1em",
    fontWeight: "300",
    display: "block",
  },
  listTextSecondaryInfo: {
    textAlign: "left",
    color: "#2196f3",
    fontFamily: "Lato, Open Sans, sans-serif",
    fontSize: "1em",
    fontWeight: "300",
    display: "block",
  },
  space: {
    height: 30,
    width: "100%",
  },
  chipAC: {
    background: "#4caf50",
    color: "#ffffff",
  },
  chipOD: {
    background: "#dc004e",
    color: "#ffffff",
  },
  chipWA: {
    background: "#6c757d",
    color: "#ffffff",
  },
  border: {
    borderBottom: "1px solid #999999",
    width: "100%",
  },
  IconBtnInfo: {
    color: "#0071BC",
  },
});

export const formStyles = (theme) => ({
  button: {
    marginRight: theme.spacing(1),
  },
  buttonNext: {
    marginRight: theme.spacing(1),
    color: theme.palette.common.white,
    backgroundColor: "#4791db",
    "&:hover": {
      backgroundColor: "#1976d2",
    },
  },
  buttonAdd: {
    marginRight: theme.spacing(1),
    color: theme.palette.common.white,
    backgroundColor: "#4caf50",
    "&:hover": {
      backgroundColor: "#388e3c",
    },
  },
  textFieldDynamic: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
    //width: "300px",
  },
  textFieldStatic: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
    width: "260px",
  },
});
