import { createDocumentRegistry } from "typescript";

//accounting messages
export const GET_TRANSACTIONS_EUR = "GET_TRANSACTIONS_EUR";
export const GET_TRANSACTIONS_PLN = "GET_TRANSACTIONS_PLN";
export const GET_TRANSACTIONS_SEK = "GET_TRANSACTIONS_SEK";
export const GET_TRANSACTIONS_NOK = "GET_TRANSACTIONS_NOK";
export const UPDATE_TRANSACTIONS_EUR = "UPDATE_TRANSACTIONS_EUR";
export const UPDATE_TRANSACTIONS_PLN = "UPDATE_TRANSACTIONS_PLN";
export const UPDATE_TRANSACTIONS_SEK = "UPDATE_TRANSACTIONS_SEK";
export const UPDATE_TRANSACTIONS_NOK = "UPDATE_TRANSACTIONS_NOK";
export const RELOAD_TRANSACTIONS = "RELOAD_TRANSACTIONS";
export const CLEAR_TRANSACTIONS = "CLEAR_TRANSACTIONS";
export const QUERY_IN_PROGRESS_TR_EUR = "QUERY_IN_PROGRESS_TR_EUR";
export const QUERY_IN_PROGRESS_TR_PLN = "QUERY_IN_PROGRESS_TR_PLN";
export const QUERY_IN_PROGRESS_TR_SEK = "QUERY_IN_PROGRESS_TR_SEK";
export const QUERY_IN_PROGRESS_TR_NOK = "QUERY_IN_PROGRESS_TR_NOK";

export const QUERY_IN_PROGRESS_TR_DETAILS = "QUERY_IN_PROGRESS_TR_DETAILS";
export const GET_TRANSACTION_DETAILS = "GET_TRANSACTION_DETAILS";

export const PATCH_TRANSACTION_DETAILS = "PATCH_TRANSACTION_DETAILS";
export const ADD_TRANSACTION_WI = "ADD_TRANSACTION_WI";
export const ADD_TRANSACTION_CP = "ADD_TRANSACTION_CP";
export const ADD_TRANSACTION_CH = "ADD_TRANSACTION_CH";
export const ADD_TRANSACTION_PB = "ADD_TRANSACTION_PB";
export const ADD_TRANSACTION_DT = "ADD_TRANSACTION_DT";
export const ADD_TRANSACTION_RT = "ADD_TRANSACTION_RT";
export const ADD_TRANSACTION_OC = "ADD_TRANSACTION_OC";

export const GET_TRANSACTIONS_AK = "GET_TRANSACTIONS_AK";
export const GET_TRANSACTIONS_PB = "GET_TRANSACTIONS_PB";
export const GET_TRANSACTIONS_RT = "GET_TRANSACTIONS_RT";
export const GET_TRANSACTIONS_DT = "GET_TRANSACTIONS_DT";
export const QUERY_IN_PROGRESS_AK = "QUERY_IN_PROGRESS_AK";
export const QUERY_IN_PROGRESS_PB = "QUERY_IN_PROGRESS_PB";
export const QUERY_IN_PROGRESS_RT = "QUERY_IN_PROGRESS_RT";
export const QUERY_IN_PROGRESS_DT = "QUERY_IN_PROGRESS_DT";
export const RS_TRANSACTIONS_AK = "RS_TRANSACTIONS_AK";
export const RS_TRANSACTIONS_PB = "RS_TRANSACTIONS_PB";
export const RS_TRANSACTIONS_RT = "RS_TRANSACTIONS_RT";
export const RS_TRANSACTIONS_DT = "RS_TRANSACTIONS_DT";

export const GET_CARDS = "GET_CARDS";
export const QUERY_IN_PROGRESS_CARDS = "QUERY_IN_PROGRESS_CARDS";
export const GET_CARDS_DETAILS = "GET_CARDS_DETAILS";
export const QUERY_IN_PROGRESS_CARDSDETAILS = "QUERY_IN_PROGRESS_CARDSDETAILS";
export const ADD_BUSINESSCARDFILE = "ADD_BUSINESSCARDFILE";
export const LOAD_IN_PROGRESS = "LOAD_IN_PROGRESS";
export const DATA_CHANGE_IN_PROGRESS = "DATA_CHANGE_IN_PROGRESS";

//stat messages
export const GET_TRANSACTIONS_STAT_AK = "GET_TRANSACTIONS_STAT_AK";
export const GET_TRANSACTIONS_STAT_AR = "GET_TRANSACTIONS_STAT_AR";
export const GET_TRANSACTIONS_STAT_NOIN = "GET_TRANSACTIONS_STAT_NOIN";
export const QUERY_IN_PROGRESS_STAT_AK = "QUERY_IN_PROGRESS_STAT_AK";
export const QUERY_IN_PROGRESS_STAT_AR = "QUERY_IN_PROGRESS_STAT_AR";
export const QUERY_IN_PROGRESS_STAT_NOIN = "QUERY_IN_PROGRESS_STAT_NOIN";

//holidays messages
export const GET_LEAVE_CONFIG_SUCCESS = "GET_LEAVE_CONFIG_SUCCESS";
export const GET_LEAVE_CONFIG_INPROGRESS = "GET_LEAVE_CONFIG_INPROGRESS";
export const GET_MYLEAVE_POOL = "GET_LEAVE_POOL";
export const GET_MYLEAVE = "GET_MYLEAVE";
export const CLEAR_MYLEAVE = "CLEAR_MYLEAVE";
export const GET_MYLEAVE_DETAILS = "GET_MYLEAVE_DETAILS";
export const ADD_LEAVE = "ADD_LEAVE";
export const DELETE_LEAVE = "DELETE_LEAVE";

//holidays-manager messages
export const GET_EMPLOYEE_LEAVES_ONLEAVE = "GET_EMPLOYEE_LEAVES_ONLEAVE";
export const GET_EMPLOYEE_LEAVES_ACCEPT = "GET_EMPLOYEE_LEAVES_ACCEPT";
export const GET_EMPLOYEE_LEAVES_SEARCH = "GET_EMPLOYEE_LEAVES_SEARCH";
export const GET_EMPLOYEE_LEAVES_PLANNED = "GET_EMPLOYEE_LEAVES_PLANNED";
export const GET_EMPLOYEE_LEAVES_DETAILS = "GET_EMPLOYEE_LEAVES_DETAILS";
export const CLEAR_EMPLOYEE_LEAVES_DETAILS = "CLEAR_EMPLOYEE_LEAVES_DETAILS";
export const PATCH_EMPLOYEE_LEAVE = "PATCH_EMPLOYEE_LEAVE";
export const ADD_EMPLOYEE_LEAVE = "ADD_EMPLOYEE_LEAVE";
export const H_QUERY_FAILED = "H_QUERY_FAILED";
export const H_QUERY_IN_PROGRESS = "H_QUERY_IN_PROGRESS";

//trips messages
export const GET_TRIPS = "GET_TRIPS";
export const GET_TRIP_DETAILS = "GET_TRIP_DETAILS";
export const CLEAR_TRIP_DETAILS = "CLEAR_TRIP_DETAILS";
export const DELETE_TRIP = "DELETE_TRIP";
export const ADD_TRIP = "ADD_TRIP";
export const UPDATE_TRIP = "UPDATE_TRIP";
export const PATCH_TRIP = "PATCH_TRIP";
export const ADD_TRIP_COMMENT_E = "ADD_TRIP_COMMENT_E";
export const ADD_TIMESTAMP_E = "ADD_TIMESTAMP_E";
export const DELETE_TIMESTAMP_E = "DELETE_TIMESTAMP_E";
export const T_QUERY_FAILED = "T_QUERY_FAILED";
export const T_QUERY_IN_PROGRESS = "T_QUERY_IN_PROGRESS";
export const T_BULK_QUERY_IN_PROGRESS_RZ = "T_BULK_QUERY_IN_PROGRESS_RZ";
export const T_BULK_QUERY_IN_PROGRESS_CA = "T_BULK_QUERY_IN_PROGRESS_CA";
export const T_BULK_QUERY_IN_PROGRESS_ZA = "T_BULK_QUERY_IN_PROGRESS_ZA";

//trips-manager messages
export const GET_EMPLOYEE_TRIPS_CA = "GET_EMPLOYEE_TRIPS_CA";
export const GET_EMPLOYEE_TRIPS_ZA = "GET_EMPLOYEE_TRIPS_ZA";
export const GET_EMPLOYEE_TRIPS_RZ = "GET_EMPLOYEE_TRIPS_RZ";
export const GET_EMPLOYEE_TRIPS_SEARCH = "GET_EMPLOYEE_TRIPS_SEARCH";
export const GET_EMPLOYEE_TRIP_DETAILS = "GET_EMPLOYEE_TRIP_DETAILS";
export const CLEAR_EMPLOYEE_TRIP_DETAILS = "CLEAR_EMPLOYEE_TRIP_DETAILS";
export const PATCH_EMPLOYEE_TRIP = "PATCH_EMPLOYEE_TRIP";
export const ADD_TRIP_COMMENT_M = "ADD_TRIP_COMMENT_M";
export const ADD_TIMESTAMP_M = "ADD_TIMESTAMP_M";
export const DELETE_TIMESTAMP_M = "DELETE_TIMESTAMP_M";

//trips-accounting messages
export const PATCH_ACCOUNTING_TRIP = "PATCH_ACCOUNTING_TRIP";

//fixed-assets messages
export const GET_VENDORS_LIST = "GET_VENDORS_LIST";
export const GET_EQMODELS_LIST = "GET_EQMODELS_LIST";
export const CLEAR_EQMODELS_LIST = "CLEAR_EQMODELS_LIST";
export const GET_EMPLOYEE_ASSETS_AL = "GET_EMPLOYEE_ASSETS_AL";
export const GET_EMPLOYEE_ASSETS_NA = "GET_EMPLOYEE_ASSETS_NA";
export const GET_EMPLOYEE_ASSETS_EL = "GET_EMPLOYEE_ASSETS_EL";
export const GET_EMPLOYEE_ASSETS_IT = "GET_EMPLOYEE_ASSETS_IT";
export const GET_EMPLOYEE_ASSETS_PO = "GET_EMPLOYEE_ASSETS_PO";
export const ADD_ASSET_MODEL = "ADD_ASSET_MODEL";
export const ADD_VENDOR = "ADD_VENDOR";
export const ADD_ASSET_AL = "ADD_ASSET_AL";
export const ADD_ASSET_NA = "ADD_ASSET_NA";
export const ADD_ASSET_EL = "ADD_ASSET_EL";
export const ADD_ASSET_IT = "ADD_ASSET_IT";
export const ADD_ASSET_PO = "ADD_ASSET_PO";
export const CLEAR_EMPLOYEE_ASSETS_STATE = "CLEAR_EMPLOYEE_ASSETS_STATE";
export const CLEAR_CUR_EQ_TR_LOG = "CLEAR_CUR_EQ_TR_LOG";
export const GET_ASSET_DETAILS = "GET_ASSET_DETAILS";
export const GET_EMPLOYEE_ASSETS_BYSTATUS_AC =
  "GET_EMPLOYEE_ASSETS_BYSTATUS_AC";
export const GET_EMPLOYEE_ASSETS_BYSTATUS_TR =
  "GET_EMPLOYEE_ASSETS_BYSTATUS_TR";
export const GET_EMPLOYEE_ASSETS_BYSTATUS_SR =
  "GET_EMPLOYEE_ASSETS_BYSTATUS_SR";
export const GET_EMPLOYEE_ASSETS_BYSTATUS_WR =
  "GET_EMPLOYEE_ASSETS_BYSTATUS_WR";
export const GET_EMPLOYEE_ASSETS_BYNAME = "GET_EMPLOYEE_ASSETS_BYNAME";
export const CLEAR_EMPLOYEE_ASSETS_BYSTATUS = "CLEAR_EMPLOYEE_ASSETS_BYSTATUS";
export const GET_EMPLOYEE_ASSETS_DETAILS_AL = "GET_EMPLOYEE_ASSETS_DETAILS_AL";
export const GET_EMPLOYEE_ASSETS_DETAILS_NA = "GET_EMPLOYEE_ASSETS_DETAILS_NA";
export const GET_EMPLOYEE_ASSETS_DETAILS_EL = "GET_EMPLOYEE_ASSETS_DETAILS_EL";
export const GET_EMPLOYEE_ASSETS_DETAILS_IT = "GET_EMPLOYEE_ASSETS_DETAILS_IT";
export const GET_EMPLOYEE_ASSETS_DETAILS_PO = "GET_EMPLOYEE_ASSETS_DETAILS_PO";
export const PATCH_EMPLOYEE_ASSET = "PATCH_EMPLOYEE_ASSET";
export const ASSET_RS_AL = "ASSET_RS_AL";
export const ASSET_RS_NA = "ASSET_RS_NA";
export const ASSET_RS_EL = "ASSET_RS_EL";
export const ASSET_RS_IT = "ASSET_RS_IT";
export const ASSET_RS_PO = "ASSET_RS_PO";
export const GET_MY_ASSETS_AL = "GET_MY_ASSETS_AL";
export const GET_MY_ASSETS_NA = "GET_MY_ASSETS_NA";
export const GET_MY_ASSETS_EL = "GET_MY_ASSETS_EL";
export const GET_MY_ASSETS_IT = "GET_MY_ASSETS_IT";
export const GET_MY_ASSETS_PO = "GET_MY_ASSETS_PO";
export const GET_WAR_ASSETS_AL = "GET_WAR_ASSETS_AL";
export const GET_WAR_ASSETS_NA = "GET_WAR_ASSETS_NA";
export const GET_WAR_ASSETS_EL = "GET_WAR_ASSETS_EL";
export const GET_WAR_ASSETS_IT = "GET_WAR_ASSETS_IT";
export const GET_WAR_ASSETS_PO = "GET_WAR_ASSETS_PO";
export const GET_MY_ASSETS_DETAILS = "GET_MY_ASSETS_DETAILS";
export const GET_MY_ASSETS_BYSTATUS_SR = "GET_MY_ASSETS_BYSTATUS_SR";
export const GET_MY_ASSETS_BYSTATUS_WR = "GET_MY_ASSETS_BYSTATUS_WR";
export const GET_MY_ASSETS_BYSTATUS_SD = "GET_MY_ASSETS_BYSTATUS_SD";
export const PATCH_MY_ASSET_AL = "PATCH_MY_ASSET_AL";
export const PATCH_MY_ASSET_NA = "PATCH_MY_ASSET_NA";
export const PATCH_MY_ASSET_EL = "PATCH_MY_ASSET_EL";
export const PATCH_MY_ASSET_IT = "PATCH_MY_ASSET_IT";
export const PATCH_MY_ASSET_PO = "PATCH_MY_ASSET_PO";
export const PATCH_MY_ASSET_AL_RS = "PATCH_MY_ASSET_AL_RS";
export const PATCH_MY_ASSET_NA_RS = "PATCH_MY_ASSET_NA_RS";
export const PATCH_MY_ASSET_EL_RS = "PATCH_MY_ASSET_EL_RS";
export const PATCH_MY_ASSET_IT_RS = "PATCH_MY_ASSET_IT_RS";
export const PATCH_MY_ASSET_PO_RS = "PATCH_MY_ASSET_PO_RS";
export const ADD_ASSET_TRANSFER_LOG = "ADD_ASSET_TRANSFER_LOG";
export const PATCH_ASSET_TRANSFER_LOG = "PATCH_ASSET_TRANSFER_LOG";
export const A_QUERY_FAILED = "A_QUERY_FAILED";
export const A_QUERY_IN_PROGRESS = "A_QUERY_IN_PROGRESS";
export const A_QUERY_FILTEREDAC_IN_PROGRESS = "A_QUERY_FILTEREDAC_IN_PROGRESS";
export const A_QUERY_FILTEREDTR_IN_PROGRESS = "A_QUERY_FILTEREDTR_IN_PROGRESS";
export const A_QUERY_FILTEREDFI_IN_PROGRESS = "A_QUERY_FILTEREDNA_IN_PROGRESS";
export const A_QUERY_FILTEREDSR_IN_PROGRESS = "A_QUERY_FILTEREDSR_IN_PROGRESS";
export const A_QUERY_FILTEREDWR_IN_PROGRESS = "A_QUERY_FILTEREDWR_IN_PROGRESS";
export const A_QUERY_MY_SR_IN_PROGRESS = "A_QUERY_MY_SR_IN_PROGRESS";
export const A_QUERY_MY_WR_IN_PROGRESS = "A_QUERY_MY_WR_IN_PROGRESS";
export const A_QUERY_MY_SD_IN_PROGRESS = "A_QUERY_MY_SD_IN_PROGRESS";
export const A_QUERY_AL_IN_PROGRESS = "A_QUERY_AL_IN_PROGRESS";
export const A_QUERY_NA_IN_PROGRESS = "A_QUERY_NA_IN_PROGRESS";
export const A_QUERY_EL_IN_PROGRESS = "A_QUERY_EL_IN_PROGRESS";
export const A_QUERY_IT_IN_PROGRESS = "A_QUERY_IT_IN_PROGRESS";
export const A_QUERY_PO_IN_PROGRESS = "A_QUERY_PO_IN_PROGRESS";
export const CLEAR_ASSETS_DETAILS = "CLEAR_ASSETS_DETAILS";
export const CLEAR_VENDORS_LIST = "CLEAR_VENDORS_LIST";
//Scrap messages
export const SCRAP_MY_ASSET_AL_REQ = "SCRAP_MY_ASSET_AL_REQ";
export const SCRAP_MY_ASSET_NA_REQ = "SCRAP_MY_ASSET_NA_REQ";
export const SCRAP_MY_ASSET_EL_REQ = "SCRAP_MY_ASSET_EL_REQ";
export const SCRAP_MY_ASSET_IT_REQ = "SCRAP_MY_ASSET_IT_REQ";
export const SCRAP_MY_ASSET_PO_REQ = "SCRAP_MY_ASSET_PO_REQ";
export const SCRAP_MY_ASSET_AL_CAN = "SCRAP_MY_ASSET_AL_CAN";
export const SCRAP_MY_ASSET_NA_CAN = "SCRAP_MY_ASSET_NA_CAN";
export const SCRAP_MY_ASSET_EL_CAN = "SCRAP_MY_ASSET_EL_CAN";
export const SCRAP_MY_ASSET_IT_CAN = "SCRAP_MY_ASSET_IT_CAN";
export const SCRAP_MY_ASSET_PO_CAN = "SCRAP_MY_ASSET_PO_CAN";
export const SCRAP_MY_ASSET_AL_UPD = "SCRAP_MY_ASSET_AL_UPD";
export const SCRAP_MY_ASSET_NA_UPD = "SCRAP_MY_ASSET_NA_UPD";
export const SCRAP_MY_ASSET_EL_UPD = "SCRAP_MY_ASSET_EL_UPD";
export const SCRAP_MY_ASSET_IT_UPD = "SCRAP_MY_ASSET_IT_UPD";
export const SCRAP_MY_ASSET_PO_UPD = "SCRAP_MY_ASSET_PO_UPD";
export const SCRAP_EMP_ASSET_SD = "SCRAP_EMP_ASSET_SD";
export const SCRAP_EMP_ASSET_WR = "SCRAP_EMP_ASSET_WR";
//Inventory
export const START_INVENTORY = "START_INVENTORY";
export const REPORT_INVENTORY = "REPORT_INVENTORY";
export const STOP_INVENTORY = "STOP_INVENTORY";
export const GET_ACTIVE_INVENTORY = "GET_ACTIVE_INVENTORY";
export const GET_INVENTORY = "GET_INVENTORY";
export const CLEAR_INVENTORY = "CLEAR_INVENTORY";
export const INV_QUERY_AL_IN_PROGRESS = "INV_QUERY_AL_IN_PROGRESS";
export const INV_QUERY_NA_IN_PROGRESS = "INV_QUERY_NA_IN_PROGRESS";
export const INV_QUERY_EL_IN_PROGRESS = "INV_QUERY_EL_IN_PROGRESS";
export const INV_QUERY_IT_IN_PROGRESS = "INV_QUERY_IT_IN_PROGRESS";
export const INV_QUERY_PO_IN_PROGRESS = "INV_QUERY_PO_IN_PROGRESS";
export const GET_MY_INV_AL = "GET_MY_INV_AL";
export const GET_MY_INV_NA = "GET_MY_INV_NA";
export const GET_MY_INV_EL = "GET_MY_INV_EL";
export const GET_MY_INV_IT = "GET_MY_INV_IT";
export const GET_MY_INV_PO = "GET_MY_INV_PO";
export const GET_MY_INV_DETAILS = "GET_MY_INV_DETAILS";
export const UPDATE_MY_INV_DETAILS = "UPDATE_MY_INV_DETAILS";
export const UPDATE_EMP_INV_DETAILS = "UPDATE_EMP_INV_DETAILS";
export const DELETE_MY_INV_DETAILS = "DELETE_MY_INV_DETAILS";
export const ADD_MY_INV_DETAILS = "ADD_MY_INV_DETAILS";
export const GET_INVENTORY_BH = "GET_INVENTORY_BH";
export const GET_INVENTORY_FI = "GET_INVENTORY_FI";
export const GET_INVENTORY_IP = "GET_INVENTORY_IP";
export const GET_INVENTORY_MA = "GET_INVENTORY_MA";
export const GET_INVENTORY_RE = "GET_INVENTORY_RE";
export const GET_EMPLOYEE_INV_ASSETS_AL = "GET_EMPLOYEE_INV_ASSETS_AL";
export const GET_EMPLOYEE_INV_ASSETS_NA = "GET_EMPLOYEE_INV_ASSETS_NA";
export const GET_EMPLOYEE_INV_ASSETS_EL = "GET_EMPLOYEE_INV_ASSETS_EL";
export const GET_EMPLOYEE_INV_ASSETS_IT = "GET_EMPLOYEE_INV_ASSETS_IT";
export const GET_EMPLOYEE_INV_ASSETS_PO = "GET_EMPLOYEE_INV_ASSETS_PO";
export const GET_INVENTORY_ASSETS = "GET_INVENTORY_ASSETS";
export const GET_INVENTORY_DETAILS = "GET_INVENTORY_DETAILS";
export const CLEAR_INVENTORY_ASSETS = "CLEAR_INVENTORY_ASSETS";
export const RAPORT_QUERY_IN_PROGRESS = "RAPORT_QUERY_IN_PROGRESS";
export const RAPORT_QUERY_IN_PROGRESS2 = "RAPORT_QUERY_IN_PROGRESS2";
export const CLEAR_INVENTORY_EMP_ASSETS = "CLEAR_INVENTORY_EMP_ASSETS";
export const CLEAR_EMP_INVENTORIES = "CLEAR_EMP_INVENTORIES";

//employee messages
export const GET_MYMANAGER_DETAILS = "GET_MYMANAGER_DETAILS";
export const GET_MYMANAGER_DETAILS_V2 = "GET_MYMANAGER_DETAILS_V2";
export const GET_MYMANAGER_LIST = "GET_MYMANAGER_LIST";
export const GET_EMPLOYEE_DETAILS = "GET_EMPLOYEE_DETAILS";
export const GET_EMPLOYEE_SECURE_DETAILS = "GET_EMPLOYEE_SECURE_DETAILS";
export const PATCH_EMPLOYEE = "PATCH_EMPLOYEE";

//manager messages
export const GET_MANAGER_DETAILS = "GET_MANAGER_DETAILS";
export const M_QUERY_FAILED = "M_QUERY_FAILED";
export const M_QUERY_IN_PROGRESS = "M_QUERY_IN_PROGRESS";

//error and info messages
export const GET_ERRORS = "GET_ERRORS";
export const CLEAR_ERRORS = "CLEAR_ERRORS";
export const GET_MESSAGES = "GET_MESSAGES";
export const CREATE_MESSAGE = "CREATE_MESSAGE";

//authentication messages
export const USER_LOADING = "USER_LOADING";
export const USER_LOADED = "USER_LOADED";
export const AUTH_ERROR = "AUTH_ERROR";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_PENDING = "LOGOUT_PENDING";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const CHANGE_PASSWORD = "CHANGE_PASSWORD";

//user profile messages
export const PATCH_PROFILE = "PATCH_PROFILE";
export const PATCH_PROFILE_EM = "PATCH_PROFILE_EM";
export const PATCH_SUSER_PROFILE = "PATCH_SUSER_PROFILE";

//common messages
export const SEND_NOTIFICATION = "SEND_NOTIFICATION";
export const C_QUERY_FAILED = "C_QUERY_FAILED";
export const C_QUERY_IN_PROGRESS = "C_QUERY_IN_PROGRESS";
export const CF_QUERY_FAILED = "CF_QUERY_FAILED";
export const CF_QUERY_IN_PROGRESS = "CF_QUERY_IN_PROGRESS";
export const CFC_QUERY_FAILED = "CFC_QUERY_FAILED";
export const CFC_QUERY_IN_PROGRESS = "CFC_QUERY_IN_PROGRESS";
export const GET_USERS_LIST = "GET_USERS_LIST";
export const GET_SUSER_DETAILS = "GET_SUSER_DETAILS";
export const GET_TUSER_DETAILS = "GET_TUSER_DETAILS";
export const CLEAR_SUSER_DETAILS = "CLEAR_SUSER_DETAILS";
export const CLEAR_TUSER_DETAILS = "CLEAR_TUSER_DETAILS";
export const GET_USERS_FULL_LIST = "GET_USERS_FULL_LIST";
export const GET_USERS_CONTACTS_LIST = "GET_USERS_CONTACTS_LIST";
export const GET_USER_FULL_DETAILS = "GET_USER_FULL_DETAILS";
export const CLEAR_USER_FULL_DETAILS = "CLEAR_USER_FULL_DETAILS";

// Documents
export const GET_DOCUMENTS = "GET_DOCUMENTS";
export const GET_DOCUMENT_DETAIL = "GET_DOCUMENT_DETAIL";
export const DELETE_DOCUMENT = "DELETE_DOCUMENT";
export const ADD_DOCUMENT = "ADD_DOCUMENT";
export const UPDATE_DOCUMENT = "UPDATE_DOCUMENT";
export const PATCH_DOCUMENT = "PATCH_DOCUMENT";
export const GET_COMPANIES = "GET_COMPANIES";

// Remote
export const GET_REMOTE = "GET_REMOTE";
export const GET_MY_REMOTE = "GET_MY_REMOTE";
export const DELETE_REMOTE = "DELETE_REMOTE";
export const ADD_REMOTE = "ADD_REMOTE";
export const PATCH_REMOTE = "PATCH_REMOTE";
export const LOG_QUERY_IN_PROGRESS = "LOG_QUERY_IN_PROGRESS";
export const LOG_QUERY_FAILED = "LOG_QUERY_FAILED";
export const MY_LOG_QUERY_IN_PROGRESS = "MY_LOG_QUERY_IN_PROGRESS";
export const MY_LOG_QUERY_FAILED = "MY_LOG_QUERY_FAILED";
export const CLEAR_REMOTE_DETAILS = "CLEAR_REMOTE_DETAILS";
export const CLEAR_REMOTE = "CLEAR_REMOTE";

// Projects
export const GET_PROJECTS = "GET_PROJECTS";
export const GET_ACTIVE_PROJECTS = "GET_ACTIVE_PROJECTS";
export const DELETE_PROJECT = "DELETE_PROJECT";
export const ADD_PROJECT = "ADD_PROJECT";
export const UPDATE_PROJECT = "UPDATE_PROJECT";
export const CLEAR_PROJECTS = "CLEAR_PROJECTS";
export const CLEAR_ACTIVE_PROJECTS = "CLEAR_ACTIVE_PROJECTS";

// Fleet
export const GET_FLEET = "GET_FLEET";
export const DELETE_FLEET = "DELETE_FLEET";
export const ADD_FLEET = "ADD_FLEET";
export const UPDATE_FLEET = "UPDATE_FLEET";
export const FLEET_QUERY_FAILED = "FLEET_QUERY_FAILED";
export const FLEET_QUERY_IN_PROGRESS = "FLEET_QUERY_IN_PROGRESS";
export const GET_FLEET_DETAILS = "GET_FLEET_DETAILS";
export const GET_FLEET_DETAILS2 = "GET_FLEET_DETAILS2";
export const FLEET_DETAILS_QUERY_FAILED = "FLEET_DETAILS_QUERY_FAILED";
export const FLEET_DETAILS_QUERY_IN_PROGRESS = "FLEET_DETAILS_QUERY_IN_PROGRESS";
export const CLEAR_FLEET_DETAILS = "CLEAR_FLEET_DETAILS";

// Events
export const GET_EVENTS = "GET_EVENTS";
export const GET_EVENT_TYPES = "GET_EVENT_TYPES";
export const GET_EVENT_RESOURCES = "GET_EVENT_RESOURCES";
export const GET_ROOM_EVENTS = "GET_ROOM_EVENTS";
export const DELETE_EVENT = "DELETE_EVENT";
export const DELETE_ROOM_EVENT = "DELETE_ROOM_EVENT";
export const ADD_EVENT = "ADD_EVENT";
export const ADD_ROOM_EVENT = "ADD_ROOM_EVENT";
export const UPDATE_EVENT = "UPDATE_EVENT";
export const UPDATE_ROOM_EVENT = "UPDATE_ROOM_EVENT";
export const CLEAR_EVENTS = "CLEAR_EVENTS";
export const CLEAR_ROOM_EVENTS = "CLEAR_ROOM_EVENTS";
export const CLEAR_EVENT_TYPES = "CLEAR_EVENT_TYPES";
export const CLEAR_EVENT_RESOURCES = "CLEAR_EVENT_RESOURCES";

//VerifyCodes
export const SEND_CODE = "SEND_CODE";
export const VERIFY_CODE = "VERIFY_CODE";
export const CLEAR_CODE = "CLEAR_CODE";

//ZFSS
export const ADD_APPLICATION = "ADD_APPLICATION";
export const ADD_APPLICATION_INCOME = "ADD_APPLICATION_INCOME";
export const ADD_APPLICATION_KIDS = "ADD_APPLICATION_KIDS";
export const GET_APPLICATION = "GET_APPLICATION";
export const GET_APPLICATION_INCOME = "GET_APPLICATION_INCOME";
export const GET_APPLICATION_KIDS = "GET_APPLICATION_KIDS";
export const GET_LAST_INCOME = "GET_LAST_INCOME";
export const APPLICATION_INCOME_QUERY_FALIED = "APPLICATION_INCOME_QUERY_FALIED";
export const APPLICATION_INCOME_QUERY_IN_PROGRESS = "APPLICATION_INCOME_QUERY_IN_PROGRESS";
export const APPLICATION_KIDS_QUERY_FALIED = "APPLICATION_KIDS_QUERY_FALIED";
export const APPLICATION_KIDS_QUERY_IN_PROGRESS = "APPLICATION_KIDS_QUERY_IN_PROGRESS";
export const CLEAR_APPLICATION_INCOME = "CLEAR_APPLICATION_INCOME";
export const CLEAR_APPLICATION_KIDS = "CLEAR_APPLICATION_KIDS";
export const CLEAR_LAST_INCOME = "CLEAR_LAST_INCOME";
export const GET_CLAUSULE = "GET_CLAUSULE";
export const ADD_APPLICATION_SWO = "ADD_APPLICATION_SWO";
export const APPLICATION_SWO_QUERY_IN_PROGRESS = "APPLICATION_SWO_QUERY_IN_PROGRESS";
export const GET_APPLICATION_SWO = "GET_APPLICATION_SWO";
export const APPLICATION_SWO_QUERY_FALIED = "APPLICATION_SWO_QUERY_FALIED";
export const CLEAR_APPLICATION_SWO = "CLEAR_APPLICATION_SWO";
export const GET_LAST_PACKAGE = "GET_LAST_PACKAGE";
export const GET_LAST_SWO = "GET_LAST_SWO";

export const GET_INSPECTIONS = "GET_INSPECTIONS";
export const INSPECTIONS_QUERY_IN_PROGRESS = "INSPECTIONS_QUERY_IN_PROGRESS";
export const INSPECTION_DETAILS_QUERY_IN_PROGRESS = "INSPECTION_DETAILS_QUERY_IN_PROGRESS";
export const GET_INSPECTION_DETAILS = "GET_INSPECTION_DETAILS";
export const CLEAR_INSPECTION_DETAILS = "CLEAR_INSPECTION_DETAILS";

export const CHANGE_COMPANY = "CHANGE_COMPANY";