import React, { Fragment, Component } from "react";
import Box from "@material-ui/core/Box";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  getMyInventoryDetails,
  getEmployeeInventoryAssets,
} from "../../Redux/actions/assets";
import { clearUserDetails } from "../../Redux/actions/common";
import { withStyles } from "@material-ui/core/styles";
import AssetNavigation from "../Common/AssetNavigation";
import EmployeeInventoryList from "./EmployeeInventoryList";
import Typography from "@material-ui/core/Typography";
import { styles } from "../../common/radiolineStyles";
import { createMuiTheme } from "@material-ui/core";
import Zoom from "@material-ui/core/Zoom";
import Tooltip from "@material-ui/core/Tooltip";
import Fab from "@material-ui/core/Fab";
import VisibilityIcon from "@material-ui/icons/Visibility";
import CloseIcon from "@material-ui/icons/Close";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";
import MyInventoryDetails from "../EmployeeView/MyInventoryDetails.js";


const materialThemeNext = createMuiTheme({
  palette: {
    primary: {
      main: "#0071BC",
    },
  },
  overrides: {
    MuiTextField: {
      root: {
        width: "auto",
      },
    },
    MuiInputBase: {
      root: {
        width: "300px",
      },
    },
    MuiFormLabel: {
      root: {
        color: "#0071bc",
      },
    },
  },
});

export class InventoryEmployeeView extends Component {
  state = {
    checkedAssetPk: null,
    isAssetChecked: false,
    eqTypeSelected: "al",
    open: false,
    dialogOpen: false,
    chargeEmployeeFlag: false,
    alertOpen: false,
    activeListType: null,
    pesel: '',
    peselValid: null,
    peselError: false,
    code: '',
    openStep1: false,
    openStep2: false,
    assetDescription: '',
    dialogOpenAddAsset: false,
  };

  static propTypes = {
    auth: PropTypes.object.isRequired,
    queryInProgressCommon: PropTypes.bool.isRequired,
    queryInProgress: PropTypes.bool.isRequired,
    userDetails: PropTypes.object.isRequired,
    getEmployeeInventoryAssets: PropTypes.func.isRequired,
    getMyInventoryDetails: PropTypes.func.isRequired,
    clearUserDetails: PropTypes.func.isRequired,
  };

  componentDidMount() {
    this.props.clearUserDetails("s");

    const ownerId = this.props.invData.inventoryUser.pk;

    this.props.getEmployeeInventoryAssets(this.props.inv, ownerId, "al");
    this.props.getEmployeeInventoryAssets(this.props.inv, ownerId, "na");
    this.props.getEmployeeInventoryAssets(this.props.inv, ownerId, "el");
    this.props.getEmployeeInventoryAssets(this.props.inv, ownerId, "it");
    this.props.getEmployeeInventoryAssets(this.props.inv, ownerId, "po");
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.userDetails.pk !== undefined &&
      nextProps.userDetails.pk !== this.props.userDetails.pk
    ) {
      const ownerId = this.props.invData.inventoryUser.pk;

      this.props.getEmployeeInventoryAssets(this.props.inv, ownerId, "al");
      this.props.getEmployeeInventoryAssets(this.props.inv, ownerId, "na");
      this.props.getEmployeeInventoryAssets(this.props.inv, ownerId, "el");
      this.props.getEmployeeInventoryAssets(this.props.inv, ownerId, "it");
      this.props.getEmployeeInventoryAssets(this.props.inv, ownerId, "po");
    }
  }

  handleTextList = (name) => ({ target: { value } }) => {
    this.setState({
      //...this.state,
      [name]: value,
    });
  };

  handleChecked = (isChecked, assetpk, listtype) => {
    this.setState({
      isAssetChecked: isChecked,
      checkedAssetPk: assetpk,
      activeListType: listtype,
    });

    if (isChecked === true) {
      this.props.getMyInventoryDetails(assetpk);
    }
  };

  handleEqTypeSelected = (eqtypeselected) => {
    this.props.getEmployeeInventoryAssets(this.props.inv, this.props.invData.inventoryUser.pk, eqtypeselected);
    this.setState({
      eqTypeSelected: eqtypeselected,
      checkedAssetPk: null,
      isAssetChecked: false,
    });
  };

  handleClickPreview = (event) => {
    this.setState({
      dialogOpen: true,
    });
  };

  handleClickCloseDialog = () => {
    this.setState({
      dialogOpen: false,
    });
  };

  handleTextChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  handleDownload = (eqtype) => {
    var assetsToBeDownloaded = [];

    if (eqtype === "al") {
      assetsToBeDownloaded = this.props.employeeAssetsAl;
    } else if (eqtype === "na") {
      assetsToBeDownloaded = this.props.employeeAssetsNa;
    } else if (eqtype === "el") {
      assetsToBeDownloaded = this.props.employeeAssetsEl;
    } else if (eqtype === "it") {
      assetsToBeDownloaded = this.props.employeeAssetsIt;
    } else if (eqtype === "po") {
      assetsToBeDownloaded = this.props.employeeAssetsPo;
    } else if (eqtype === "ws") {
      assetsToBeDownloaded = this.props.employeeAssetsAl.concat(
        this.props.employeeAssetsNa,
        this.props.employeeAssetsEl,
        this.props.employeeAssetsIt,
        this.props.employeeAssetsPo
      );
    }

    if (assetsToBeDownloaded.length !== 0) {
      var csvRow = [];
      var A = [
        [
          "Grupa",
          "Nazwa",
          "Model",
          "Producent",
          "Numer seryjny",
          "Numer Produktu",
          "Data zakupu",
          "Data przekazania",
          "Opis dodatkowy",
          "Właściciel środka trwałego",
        ],
      ];
      var re = assetsToBeDownloaded;

      for (var item = 0; item < re.length; item++) {
        A.push([
          re[item].model.eqtype_description,
          re[item].model.name,
          re[item].model.description,
          re[item].model.vendor,
          re[item].serialNumber,
          re[item].partNumber,
          re[item].purchaseDate,
          re[item].assignDate,
          re[item].description,
          re[item].owner.first_name + " " + re[item].owner.last_name,
        ]);
      }

      for (var i = 0; i < A.length; ++i) {
        csvRow.push(A[i].join(";"));
      }

      var csvString = csvRow.join("\n");
      //var a = document.createElement("a");
      var link = window.document.createElement("a");
      link.setAttribute(
        "href",
        "data:text/csv;charset=utf-8,%EF%BB%BF" + encodeURI(csvString)
      );
      if (eqtype === "ws") {
        link.setAttribute(
          "download",
          `SrodkiTrwale${re[0].owner.first_name + re[0].owner.last_name}.csv`
        );
      } else {
        link.setAttribute(
          "download",
          `SrodkiTrwale${re[0].owner.first_name + re[0].owner.last_name + "_"}${re[0].model.eqtype_description
          }.csv`
        );
      }
      link.click();
    } else {
      this.setState({
        alertOpen: true,
      });
    }
  };

  setFilter = (filtername) => {
    this.setState({
      activeFilter: filtername,
    });

    if (
      this.state.isAssetChecked &&
      this.state.activeListType !== "AssetListManager"
    ) {
      this.setState({
        checkedAssetPk: null,
        isAssetChecked: false,
        activeListType: null,
      });
    }
  };

  handleClickPreview = (event) => {
    this.setState({
      dialogOpen: true,
    });
  };

  render() {
    const {
      eqTypeSelected,
      dialogOpen,
      activeListType,
      isAssetChecked,
    } = this.state;
    const {
      classes,
      employeeInventoryAl,
      employeeInventoryNa,
      employeeInventoryEl,
      employeeInventoryIt,
      employeeInventoryPo,
      queryInProgress,
    } = this.props;

    var employeeInventoryAssets = [];
    //var employeeAssetTransfer = null;

    if (eqTypeSelected === "al") {
      employeeInventoryAssets = employeeInventoryAl;
    } else if (eqTypeSelected === "na") {
      employeeInventoryAssets = employeeInventoryNa;
    } else if (eqTypeSelected === "el") {
      employeeInventoryAssets = employeeInventoryEl;
    } else if (eqTypeSelected === "it") {
      employeeInventoryAssets = employeeInventoryIt;
    } else if (eqTypeSelected === "po") {
      employeeInventoryAssets = employeeInventoryPo;
    } else if (eqTypeSelected === "ws") {
      employeeInventoryAssets = employeeInventoryAl.concat(
        employeeInventoryNa,
        employeeInventoryEl,
        employeeInventoryIt,
        employeeInventoryPo
      );
    }

    var inventoryDetails = null;

    const circle = (
      <Grid
        container
        className={classes.root}
        spacing={0}
        justify="center"
        alignItems="center"
      >
        <CircularProgress color="secondary" />
      </Grid>
    );

    if (
      (dialogOpen === true) &&
      this.props.myInventoryDetails.pk !== undefined
    ) {
      inventoryDetails = (
        <MyInventoryDetails myInventoryDetails={this.props.myInventoryDetails}></MyInventoryDetails>
      );
    } else {
      inventoryDetails = <Fragment></Fragment>;
    }

    const previewButtonDi = (
      <Fab
        disabled
        color="inherit"
        className={classes.fabblue}
        size="small"
        aria-label="scroll back to top"
      >
        <VisibilityIcon />
      </Fab>
    );

    const previewButtonEn = (
      <Fab
        color="inherit"
        className={classes.fabblue}
        size="small"
        aria-label="scroll back to top"
      >
        <VisibilityIcon />
      </Fab>
    );

    return (
      <Fragment>
        <div className={classes.section}>
          <Box m={2}>
            <AssetNavigation
              eqTypeSelected={this.handleEqTypeSelected}
            />
            <div style={{ marginTop: "30px" }}></div>
            <EmployeeInventoryList
              assets={employeeInventoryAssets}
              checked={this.handleChecked}
              downloadTrigger={this.handleDownload}
              eqTypeSelected={eqTypeSelected}
              activeListType={activeListType}
              status={this.props.invData.status}
              owner={this.props.invData.inventoryUser}
            />
          </Box>

          <Zoom in={isAssetChecked}>
            <div
              onClick={this.handleClickPreview}
              role="presentation"
              className={classes.fb2}
            >
              <Tooltip title="Pokaż szczegóły" placement="left">
                {queryInProgress ? previewButtonDi : previewButtonEn}
              </Tooltip>
            </div>
          </Zoom>

          <Dialog
            fullScreen
            open={dialogOpen}
            onClose={this.handleClickCloseDialog}
            aria-labelledby="form-dialog-title"
          >
            <AppBar position="static" style={{ background: "#0071BC" }}>
              <Toolbar>
                <IconButton
                  edge="start"
                  color="inherit"
                  onClick={this.handleClickCloseDialog}
                  aria-label="close"
                  className={classes.menuButton}
                >
                  <CloseIcon />
                </IconButton>
                <Typography variant="h6" className={classes.title}>
                  Szczegóły środka trwałego
                </Typography>
              </Toolbar>
            </AppBar>
            <DialogContent style={{ background: "#f5f5f5" }}>
              {queryInProgress ? circle : inventoryDetails}
            </DialogContent>
          </Dialog>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  employeeInventoryAl: state.assets.employeeInventoryAl,
  employeeInventoryNa: state.assets.employeeInventoryNa,
  employeeInventoryEl: state.assets.employeeInventoryEl,
  employeeInventoryIt: state.assets.employeeInventoryIt,
  employeeInventoryPo: state.assets.employeeInventoryPo,
  queryInProgress: state.assets.queryInProgress,
  auth: state.auth,
  usersList: state.common.users,
  userDetails: state.common.suser,
  queryInProgressCommon: state.common.queryInProgress,
  myInventoryDetails: state.assets.myInventoryDetails,
});

export default connect(
  mapStateToProps,
  {
    getEmployeeInventoryAssets,
    clearUserDetails,
    getMyInventoryDetails,
  }
)(withStyles(styles, { withTheme: true })(InventoryEmployeeView));
