import axios from "axios";
import { createMessages, returnErrors } from "./messages";
import { tokenConfig } from "./auth";
import { getDatabaseUrl, serverurl } from "./databaseServerURL";

import {
  GET_EMPLOYEE_TRIPS_CA,
  GET_EMPLOYEE_TRIPS_ZA,
  GET_EMPLOYEE_TRIPS_RZ,
  GET_EMPLOYEE_TRIPS_SEARCH,
  GET_EMPLOYEE_TRIP_DETAILS,
  CLEAR_EMPLOYEE_TRIP_DETAILS,
  PATCH_EMPLOYEE_TRIP,
  PATCH_ACCOUNTING_TRIP,
  T_QUERY_IN_PROGRESS,
  T_QUERY_FAILED,
  T_BULK_QUERY_IN_PROGRESS_RZ,
  T_BULK_QUERY_IN_PROGRESS_CA,
  T_BULK_QUERY_IN_PROGRESS_ZA,
  ADD_TRIP_COMMENT_M,
  ADD_TIMESTAMP_M,
  DELETE_TIMESTAMP_M
} from "./types";

//GET EMPLOYEE TRIPS Filtered
export const getEmployeeTripsFiltered = (filterParameter, filterValue) => (
  dispatch,
  getState
) => {
  if (filterValue === "RZ") {
    dispatch({ type: T_BULK_QUERY_IN_PROGRESS_RZ });
  } else if (filterValue === "CA") {
    dispatch({ type: T_BULK_QUERY_IN_PROGRESS_CA });
  } else if (filterValue === "ZA") {
    dispatch({ type: T_BULK_QUERY_IN_PROGRESS_ZA });
  }

  axios
    .get(
      serverurl + getDatabaseUrl() + `/myemployeetrips/?${filterParameter}=${filterValue}`,
      tokenConfig(getState)
    )
    .then(res => {
      if (filterValue === "CA") {
        dispatch({
          type: GET_EMPLOYEE_TRIPS_CA,
          payload: res.data
        });
      } else if (filterValue === "ZA") {
        dispatch({
          type: GET_EMPLOYEE_TRIPS_ZA,
          payload: res.data
        });
      } else if (filterValue === "RZ") {
        dispatch({
          type: GET_EMPLOYEE_TRIPS_RZ,
          payload: res.data
        });
      } else {
      }
    })
    .catch(err => {
      dispatch(returnErrors(err.response.data.detail, err.response.status));
      dispatch({ type: T_QUERY_FAILED });
    });
};

//GET EMPLOYEE TRIPS Search
export const getEmployeeTripsSearch = (filterParameter, filterValue) => (
  dispatch,
  getState
) => {
  dispatch({ type: T_QUERY_IN_PROGRESS });
  axios
    .get(
      serverurl + getDatabaseUrl() + `/myemployeetrips/?${filterParameter}=${filterValue}`,
      tokenConfig(getState)
    )
    .then(res => {
      dispatch({
        type: GET_EMPLOYEE_TRIPS_SEARCH,
        payload: res.data
      });
    })
    .catch(err => {
      dispatch(returnErrors(err.response.data.detail, err.response.status));
      dispatch({ type: T_QUERY_FAILED });
    });
};

//GET EMPLOYEE TRIP DETAILS
export const getEmployeeTripDetails = pk => (dispatch, getState) => {
  dispatch({ type: T_QUERY_IN_PROGRESS });
  axios
    .get(serverurl + getDatabaseUrl() + `/myemployeetrips/${pk}`, tokenConfig(getState))
    .then(res => {
      dispatch({
        type: GET_EMPLOYEE_TRIP_DETAILS,
        payload: res.data
      });
    })
    .catch(err => {
      dispatch(returnErrors(err.response.data.detail, err.response.status));
      dispatch({ type: T_QUERY_FAILED });
    });
};

//CLEAR EMPLOYEE TRIP DETAILS
export const clearEmployeeTripDetails = () => (dispatch, getState) => {
  dispatch({ type: CLEAR_EMPLOYEE_TRIP_DETAILS });
};

//PATCH EMPLOYEE TRIP
export const patchEmployeeTrip = (trip, pk, message) => (
  dispatch,
  getState
) => {
  axios
    .patch(serverurl + getDatabaseUrl() + `/myemployeetrips/${pk}`, trip, tokenConfig(getState))
    .then(res => {
      dispatch(createMessages({ patchEmployeeTrip: message }));
      dispatch({
        type: PATCH_EMPLOYEE_TRIP,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch(returnErrors(err.response.data.detail, err.response.status))
    );
};

//PATCH ACCOUNTING TRIP
export const patchAccountingTrip = (trip, pk, message) => (
  dispatch,
  getState
) => {
  axios
    .patch(serverurl + getDatabaseUrl() + `/myemployeetrips/${pk}`, trip, tokenConfig(getState))
    .then(res => {
      dispatch(createMessages({ patchAccountingTrip: message }));
      dispatch({
        type: PATCH_ACCOUNTING_TRIP,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch(returnErrors(err.response.data.detail, err.response.status))
    );
};

//ADD EMPLOYEE TRIP COMMENT
export const addTripComment = tripCommentBody => (dispatch, getState) => {
  axios
    .post(serverurl + getDatabaseUrl() + "/comment/", tripCommentBody, tokenConfig(getState))
    .then(res => {
      dispatch(createMessages({ addTripComment: "Komentarz został zapisany" }));
      dispatch({
        type: ADD_TRIP_COMMENT_M,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch(returnErrors(err.response.data.detail, err.response.status))
    );
};

//ADD TIMESTAMP
export const addTimestamp = timestampBody => (dispatch, getState) => {
  axios
    .post(serverurl + getDatabaseUrl() + "/timestamp/", timestampBody, tokenConfig(getState))
    .then(res => {
      dispatch(
        createMessages({ addTimestamp: "Znacznik czasu został dodany" })
      );
      dispatch({
        type: ADD_TIMESTAMP_M,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch(returnErrors(err.response.data.detail, err.response.status))
    );
};

//DELETE TIMESTAMP M
export const deleteTimestamp = id => (dispatch, getState) => {
  axios
    .delete(serverurl + getDatabaseUrl() + `/timestamp/${id}`, tokenConfig(getState))
    .then(res => {
      dispatch(createMessages({ deleteTimestamp: "Znacznik został usunięty" }));
      dispatch({
        type: DELETE_TIMESTAMP_M,
        payload: id
      });
    })
    .catch(err =>
      dispatch(returnErrors(err.response.data.detail, err.response.status))
    );
};
