import React, { Fragment, Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { styles } from "../../common/radiolineStyles";
import Grid from "@material-ui/core/Grid";
import Card from "../../components/Card/Card.js";
import CardBody from "../../components/Card/CardBody.js";
import CardHeader from "@material-ui/core/CardHeader";
import Typography from "@material-ui/core/Typography";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import EditableField from "./EditableField.js";
import Button from "@material-ui/core/Button";
import { patchEmployeeAsset, updateEmpInventoryDetails } from "../../Redux/actions/assets.js";

export class InventoryDetailsCompareAndEdit extends Component {
  state = {
    newfvNumber: this.props.myInventoryDetails.fvNumber,
    newValue: this.props.myInventoryDetails.value,
    newDescription: this.props.myInventoryDetails.description,
    newIsST: this.props.myInventoryDetails.isST,
  }

  static propTypes = {
    myInventoryDetails: PropTypes.object.isRequired,
    updateEmpInventoryDetails: PropTypes.func.isRequired,
    patchEmployeeAsset: PropTypes.func.isRequired,
  }

  handleSetFvNumber = (value) => {
    this.setState({ newfvNumber: value });
  }

  handleSetValue = (value) => {
    this.setState({ newValue: value });
  }

  handleSetDescription = (value) => {
    this.setState({ newDescription: value });
  }

  handleSetIsST = (value) => {
    this.setState({ newIsST: value });
  }

  handleSaveChanges = () => {
    var body = {
      fvNumber: this.state.newfvNumber,
      value: this.state.newValue,
      isST: this.state.newIsST,
    }
    this.props.updateEmpInventoryDetails(this.props.myInventoryDetails.pk, body);
    body.description = this.state.newDescription;
    this.props.patchEmployeeAsset(this.props.myInventoryDetails.equipment, body);
  }

  render() {
    const { classes, myInventoryDetails } = this.props;
    const { newfvNumber, newValue, newDescription, newIsST } = this.state;

    const checkIcon = (<CheckIcon style={{ marginTop: "-15px", marginBottom: "-7.5px" }} />);
    const closeIcon = (<CloseIcon style={{ marginTop: "-15px", marginBottom: "-7.5px" }} />);

    return (
      <Fragment>
        <Grid container className={classes.root} spacing={2}>
          <Grid p={0} m={0} item xs={12}>
            <Typography className={classes.textName2}>Dane wspólne</Typography>
          </Grid>
          <Grid p={0} m={0} item xs={12}>
            <Grid container className={classes.root} spacing={2}>
              <Grid p={0} m={0} item xs={12} sm={6} md={3} lg={3}>
                <Card style={{ margin: 0 }}>
                  <CardHeader
                    title={
                      <Typography className={classes.textName2}>
                        Typ
                      </Typography>
                    }
                  ></CardHeader>
                  <CardBody>
                    <Typography className={classes.textDescription2}>
                      {myInventoryDetails.itemName}
                    </Typography>
                  </CardBody>
                </Card>
              </Grid>
              <Grid p={0} m={0} item xs={12} sm={6} md={3} lg={3}>
                <Card style={{ margin: 0 }}>
                  <CardHeader
                    title={
                      <Typography className={classes.textName2}>
                        Producent
                      </Typography>
                    }
                  ></CardHeader>
                  <CardBody>
                    <Typography className={classes.textDescription2}>
                      {myInventoryDetails.vendor}
                    </Typography>
                  </CardBody>
                </Card>
              </Grid>
              <Grid p={0} m={0} item xs={12} sm={6} md={3} lg={3}>
                <Card style={{ margin: 0 }}>
                  <CardHeader
                    title={
                      <Typography className={classes.textName2}>
                        Model
                      </Typography>
                    }
                  ></CardHeader>
                  <CardBody>
                    <Typography className={classes.textDescription2}>
                      {myInventoryDetails.model != "" ? myInventoryDetails.model : "-"}
                    </Typography>
                  </CardBody>
                </Card>
              </Grid>
              <Grid p={0} m={0} item xs={12} sm={6} md={3} lg={3}>
                <Card style={{ margin: 0 }}>
                  <CardHeader
                    title={
                      <Typography className={classes.textName2}>
                        Extranet ID
                      </Typography>
                    }
                  ></CardHeader>
                  <CardBody>
                    <Typography className={classes.textDescription2}>
                      {myInventoryDetails.equipment != null ? myInventoryDetails.equipment : "-"}
                    </Typography>
                  </CardBody>
                </Card>
              </Grid>
              <Grid p={0} m={0} item xs={12} sm={6} md={6} lg={6}>
                <Card style={{ margin: 0 }}>
                  <CardHeader
                    title={
                      <Typography className={classes.textName2}>
                        Nr faktury
                      </Typography>
                    }
                  ></CardHeader>
                  <CardBody>
                    {/*<Typography className={classes.textDescription2}>
                      {myInventoryDetails.fvNumber == "" || myInventoryDetails.fvNumber == null
                        ? "-BRAK-"
                        : myInventoryDetails.fvNumber}
                    </Typography>*/}
                    <EditableField value={newfvNumber} onSave={this.handleSetFvNumber} label="Nr faktury" isBold={true} classes={classes} />
                  </CardBody>
                </Card>
              </Grid>
              <Grid p={0} m={0} item xs={12} sm={6} md={3} lg={3}>
                <Card style={{ margin: 0 }}>
                  <CardHeader
                    title={
                      <Typography className={classes.textName2}>
                        Wartość
                      </Typography>
                    }
                  ></CardHeader>
                  <CardBody>
                    <Typography className={classes.textDescription2}>
                      {/*{myInventoryDetails.value == "" || myInventoryDetails.value == null
                        ? "-BRAK-"
                        : myInventoryDetails.value}*/}
                      <EditableField value={newValue} onSave={this.handleSetValue} label="Wartość" isBold={true} classes={classes} />
                    </Typography>
                  </CardBody>
                </Card>
              </Grid>
              <Grid p={0} m={0} item xs={12} sm={6} md={3} lg={3}>
                <Card style={{ margin: 0 }}>
                  <CardHeader
                    title={
                      <Typography className={classes.textName2}>
                        Środek Trwały
                      </Typography>
                    }
                  ></CardHeader>
                  <CardBody>
                    <Typography className={classes.textDescription2}>
                      {/*{myInventoryDetails.isST == true ? checkIcon : myInventoryDetails.isST == false ? closeIcon : "?"}*/}
                      <EditableField value={newIsST} onSave={this.handleSetIsST} label="Środek Trwały" isBool={true} isBold={true} classes={classes} />
                    </Typography>
                  </CardBody>
                </Card>
              </Grid>
            </Grid>
          </Grid>

          <Grid p={0} m={0} item xs={12}>
            <Typography className={classes.textName2}>Dane oryginalne</Typography>
          </Grid>
          <Grid p={0} m={0} item xs={12}>
            <Grid container className={classes.root} spacing={2}>
              <Grid p={0} m={0} item xs={12} sm={6} md={3} lg={3}>
                <Card style={{ margin: 0 }}>
                  <CardHeader
                    title={
                      <Typography className={classes.textName2}>
                        Numer seryjny
                      </Typography>
                    }
                  ></CardHeader>
                  <CardBody>
                    <Typography className={classes.textDescription2}>
                      {myInventoryDetails.serialNumber != "" && myInventoryDetails.serialNumber != null ? myInventoryDetails.serialNumber : "-"}
                    </Typography>
                  </CardBody>
                </Card>
              </Grid>
              <Grid p={0} m={0} item xs={12} sm={6} md={3} lg={3}>
                <Card style={{ margin: 0 }}>
                  <CardHeader
                    title={
                      <Typography className={classes.textName2}>
                        Numer produktu
                      </Typography>
                    }
                  ></CardHeader>
                  <CardBody>
                    <Typography className={classes.textDescription2}>
                      {myInventoryDetails.partNumber != "" && myInventoryDetails.partNumber != null ? myInventoryDetails.partNumber : "-"}
                    </Typography>
                  </CardBody>
                </Card>
              </Grid>
              <Grid p={0} m={0} item xs={12} sm={12} md={6} lg={6}>
                <Card className={classes.info} style={{ margin: 0 }}>
                  <CardHeader
                    title={
                      <Typography className={classes.textName2}>
                        Informacje dodatkowe
                      </Typography>
                    }
                  ></CardHeader>
                  <CardBody>
                    <Typography className={classes.textDescription}>
                      {/*{myInventoryDetails.description == "" || myInventoryDetails.description == null
                        ? "-BRAK-"
                        : myInventoryDetails.description}*/}
                      <EditableField value={newDescription} onSave={this.handleSetDescription} label="Informacje dodatkowe" classes={classes} />
                    </Typography>
                  </CardBody>
                </Card>
              </Grid>
            </Grid>
          </Grid>

          <Grid p={0} m={0} item xs={12}>
            <Typography className={classes.textName2}>Dane z inwentury</Typography>
          </Grid>
          <Grid p={0} m={0} item xs={12}>
            <Grid container className={classes.root} spacing={2}>
              <Grid p={0} m={0} item xs={12} sm={6} md={9} lg={9}>
                <Card style={{ margin: 0 }}>
                  <CardHeader
                    title={
                      <Typography className={classes.textName2}>
                        Komentarz pracownika
                      </Typography>
                    }
                  ></CardHeader>
                  <CardBody>
                    <Typography className={classes.textDescription2}>
                      {myInventoryDetails.userComment == "" || myInventoryDetails.userComment == null
                        ? "-BRAK-"
                        : myInventoryDetails.userComment}
                    </Typography>
                  </CardBody>
                </Card>
              </Grid>
              <Grid p={0} m={0} item xs={12} sm={6} md={3} lg={3}>
                <Card style={{ margin: 0 }}>
                  <CardHeader
                    title={
                      <Typography className={classes.textName2}>
                        Czy posiadany?
                      </Typography>
                    }
                  ></CardHeader>
                  <CardBody>
                    <Typography className={classes.textDescription2}>
                      {myInventoryDetails.isOwned == true ? checkIcon : myInventoryDetails.isOwned == false ? closeIcon : "?"}
                    </Typography>
                  </CardBody>
                </Card>
              </Grid>
              <Grid p={0} m={0} item xs={12} sm={6} md={9} lg={9}>
                <Card style={{ margin: 0 }}>
                  <CardHeader
                    title={
                      <Typography className={classes.textName2}>
                        Komentarz Opiekuna
                      </Typography>
                    }
                  ></CardHeader>
                  <CardBody>
                    <Typography className={classes.textDescription2}>
                      {myInventoryDetails.managerComment == "" || myInventoryDetails.managerComment == null
                        ? "-BRAK-"
                        : myInventoryDetails.managerComment}
                    </Typography>
                  </CardBody>
                </Card>
              </Grid>
              <Grid p={0} m={0} item xs={12} sm={6} md={3} lg={3}>
                <Card style={{ margin: 0 }}>
                  <CardHeader
                    title={
                      <Typography className={classes.textName2}>
                        Czy zaakceptowany?
                      </Typography>
                    }
                  ></CardHeader>
                  <CardBody>
                    <Typography className={classes.textDescription2}>
                      {myInventoryDetails.accepted == true ? checkIcon : myInventoryDetails.accepted == false ? closeIcon : "?"}
                    </Typography>
                  </CardBody>
                </Card>
              </Grid>
              <Grid p={0} m={0} item xs={12} sm={6} md={9} lg={9}>
                <Card style={{ margin: 0 }}>
                  <CardHeader
                    title={
                      <Typography className={classes.textName2}>
                        Komentarz Kontrolingu
                      </Typography>
                    }
                  ></CardHeader>
                  <CardBody>
                    <Typography className={classes.textDescription2}>
                      {myInventoryDetails.controlComment == "" || myInventoryDetails.controlComment == null
                        ? "-BRAK-"
                        : myInventoryDetails.controlComment}
                    </Typography>
                  </CardBody>
                </Card>
              </Grid>
            </Grid>
          </Grid>

          <Button
            variant="contained"
            color="primary"
            style={{ margin: "20px auto" }}
            onClick={this.handleSaveChanges}
          >Zapisz zmiany</Button>
        </Grid>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({

});

export default connect(mapStateToProps, {
  patchEmployeeAsset,
  updateEmpInventoryDetails,
})(withStyles(styles, { withTheme: true })(InventoryDetailsCompareAndEdit));