import React, { Component, Fragment } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import CheckIcon from "@material-ui/icons/Check.js";
import TransferLog from "../Common/TransferLog";
import { styles } from "../../common/radiolineStyles";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Card from "../../components/Card/Card.js";
import CardBody from "../../components/Card/CardBody.js";
import CardHeader from "@material-ui/core/CardHeader";
import Avatar from "@material-ui/core/Avatar";

export class DialogAssetDetails extends Component {
  state = {
    //dialogOpen: false,
  };

  handleClickCloseDialog = () => {
    this.props.closeDialog();
  };

  render() {
    //const {  } = this.state;
    const { classes, assetDetails, dialogOpen } = this.props;

    const history = (
      <Fragment>
        <TransferLog assetsDetails={this.props.assetDetails} />
      </Fragment>
    );

    const checkIcon = (<CheckIcon style={{ marginTop: "-15px", marginBottom: "-7.5px" }} />);
    const closeIcon = (<CloseIcon style={{ marginTop: "-15px", marginBottom: "-7.5px" }} />);

    var assetDetailsWindow = (
      <Grid container className={classes.root} spacing={2}>
        <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
          <Card style={{ overflow: "auto", margin: 0 }}>
            <CardBody>
              <Typography className={classes.textType}>
                {assetDetails.model.eqtype_description}
              </Typography>
              <Typography className={classes.textName}>
                <b>{assetDetails.model.name}</b>
              </Typography>
              <Typography className={classes.textDescription}>
                {assetDetails.model.description}
              </Typography>
            </CardBody>
          </Card>
        </Grid>
        <Grid p={0} m={0} item xs={12} sm={6} md={3} lg={3}>
          <Card style={{ margin: 0 }}>
            <CardHeader
              title={
                <Typography className={classes.textName2}>Producent</Typography>
              }
            ></CardHeader>
            <CardBody>
              <Typography className={classes.textDescription2}>
                {assetDetails.model.vendor}
              </Typography>
            </CardBody>
          </Card>
        </Grid>
        <Grid p={0} m={0} item xs={12} sm={6} md={3} lg={3}>
          <Card style={{ margin: 0 }}>
            <CardHeader
              title={
                <Typography className={classes.textName2}>
                  Numer seryjny
                </Typography>
              }
            ></CardHeader>
            <CardBody>
              <Typography className={classes.textDescription2}>
                {assetDetails.serialNumber === "" || assetDetails.serialNumber === null
                  ? "-BRAK-"
                  : assetDetails.serialNumber}
              </Typography>
            </CardBody>
          </Card>
        </Grid>
        <Grid p={0} m={0} item xs={12} sm={6} md={3} lg={3}>
          <Card style={{ margin: 0 }}>
            <CardHeader
              title={
                <Typography className={classes.textName2}>
                  Numer produktu
                </Typography>
              }
            ></CardHeader>
            <CardBody>
              <Typography className={classes.textDescription2}>
                {assetDetails.partNumber === "" || assetDetails.partNumber === null
                  ? "-BRAK-"
                  : assetDetails.partNumber}
              </Typography>
            </CardBody>
          </Card>
        </Grid>
        <Grid p={0} m={0} item xs={12} sm={6} md={3} lg={3}>
          <Card style={{ margin: 0 }}>
            <CardHeader
              title={
                <Typography className={classes.textName2}>
                  Data zakupu
                </Typography>
              }
            ></CardHeader>
            <CardBody>
              <Typography className={classes.textDescription2}>
                {assetDetails.purchaseDate === null
                  ? "-BRAK-"
                  : assetDetails.purchaseDate}
              </Typography>
            </CardBody>
          </Card>
        </Grid>
        <Grid p={0} m={0} item xs={12} sm={6} md={3} lg={3}>
          <Card style={{ margin: 0 }}>
            <CardHeader
              title={
                <Typography className={classes.textName2}>
                  Data przekazania
                </Typography>
              }
            ></CardHeader>
            <CardBody>
              <Typography className={classes.textDescription2}>
                {assetDetails.assignDate !== null
                  ? assetDetails.assignDate
                  : "-BRAK-"}
              </Typography>
            </CardBody>
          </Card>
        </Grid>
        <Grid p={0} m={0} item xs={12} sm={6} md={6} lg={6}>
          <Card className={classes.info} style={{ margin: 0 }}>
            <CardBody>
              <Typography className={classes.textName2}>
                Informacje dodatkowe
              </Typography>
              <Typography className={classes.textDescription}>
                {assetDetails.description === ""
                  ? "-BRAK-"
                  : assetDetails.description}
              </Typography>
            </CardBody>
          </Card>
        </Grid>
        <Grid p={0} m={0} item xs={12} sm={6} md={3} lg={3}>
          <Card style={{ overflow: "auto", margin: 0 }}>
            <CardBody>
              <Grid container className={classes.root} spacing={2}>
                <Grid item xs={12} m={1}>
                  <Typography className={classes.textName2}>
                    Właściciel środka trwałego:
                  </Typography>
                </Grid>
                <Grid item xs={2} sm={3} md={3} lg={2}>
                  <Avatar style={{ backgroundColor: "#AE9336" }}>
                    {assetDetails.owner !== null
                      ? assetDetails.owner.username.substr(0, 2).toUpperCase()
                      : "MG"}
                  </Avatar>
                </Grid>
                <Grid
                  item
                  xs={10}
                  sm={9}
                  md={9}
                  lg={10}
                  style={{ position: "relative" }}
                >
                  <Typography
                    className={classes.textDescription2}
                    style={{
                      margin: 0,
                      position: "absolute",
                      top: "50%",
                      transform: "translate(0, -50%",
                    }}
                  >
                    {assetDetails.owner !== null
                      ? assetDetails.owner.first_name +
                      " " +
                      assetDetails.owner.last_name
                      : "magazyn"}
                  </Typography>
                </Grid>
              </Grid>
            </CardBody>
          </Card>
        </Grid>
        <Grid p={0} m={0} item xs={12} sm={6} md={3} lg={3}>
          <Card style={{ margin: 0 }}>
            <CardHeader
              title={
                <Typography className={classes.textName2}>
                  Extranet ID
                </Typography>
              }
            ></CardHeader>
            <CardBody>
              <Typography className={classes.textDescription2}>
                {assetDetails.pk === ""
                  ? "-BRAK-"
                  : assetDetails.pk}
              </Typography>
            </CardBody>
          </Card>
        </Grid>
        <Grid p={0} m={0} item xs={12} sm={6} md={3} lg={3}>
          <Card style={{ margin: 0 }}>
            <CardHeader
              title={
                <Typography className={classes.textName2}>
                  Nr faktury
                </Typography>
              }
            ></CardHeader>
            <CardBody>
              <Typography className={classes.textDescription2}>
                {assetDetails.fvNumber === "" || assetDetails.fvNumber === null
                  ? "-BRAK-"
                  : assetDetails.fvNumber}
              </Typography>
            </CardBody>
          </Card>
        </Grid>
        <Grid p={0} m={0} item xs={12} sm={6} md={3} lg={3}>
          <Card style={{ margin: 0 }}>
            <CardHeader
              title={
                <Typography className={classes.textName2}>
                  Wartość
                </Typography>
              }
            ></CardHeader>
            <CardBody>
              <Typography className={classes.textDescription2}>
                {assetDetails.value == "" || assetDetails.value == null
                  ? "-BRAK-"
                  : assetDetails.value}
              </Typography>
            </CardBody>
          </Card>
        </Grid>
        <Grid p={0} m={0} item xs={12} sm={6} md={3} lg={3}>
          <Card style={{ margin: 0 }}>
            <CardHeader
              title={
                <Typography className={classes.textName2}>
                  Środek Trwały
                </Typography>
              }
            ></CardHeader>
            <CardBody>
              <Typography className={classes.textDescription2}>
                {assetDetails.isST == true ? checkIcon : assetDetails.isST == false ? closeIcon : "?"}
              </Typography>
            </CardBody>
          </Card>
        </Grid>
      </Grid>
    );

    return (
      <Fragment>
        <Dialog
          fullScreen
          open={dialogOpen}
          onClose={this.handleClickCloseDialog}
          aria-labelledby="form-dialog-title"
        >
          <AppBar position="static" style={{ background: "#0071BC" }}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={this.handleClickCloseDialog}
                aria-label="close"
                className={classes.menuButton}
              >
                <CloseIcon />
              </IconButton>
              <Typography variant="h6" className={classes.title}>
                Szczegóły środka trwałego
              </Typography>
            </Toolbar>
          </AppBar>
          <DialogContent style={{ background: "#f5f5f5" }}>
            {assetDetailsWindow}
            <div style={{ marginTop: 20 }}></div>
            {history}
          </DialogContent>
        </Dialog>
      </Fragment>
    );
  }
}

export default withStyles(styles, { withTheme: true })(DialogAssetDetails);
