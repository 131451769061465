import axios from "axios";
import { createMessages, returnErrors } from "./messages";
import { serverurl, getDatabaseUrl } from "./databaseServerURL";

import {
  SEND_CODE,
  VERIFY_CODE,
  CLEAR_CODE,
} from "./types";

const config = {
  headers: {
    'content-type': 'application/x-www-form-urlencoded;charset=utf-8',
  },
};

//SEND
export const sendCode = body => (dispatch, getState) => {
  const formBody = Object.keys(body).map(key => encodeURIComponent(key) + '=' + encodeURIComponent(body[key])).join('&');

  axios
    .post(serverurl + "/send_code", formBody, config)
    .then(res => {
      dispatch(createMessages({ sendCode: "Kod został wysłany" }));
      dispatch({
        type: SEND_CODE,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch(returnErrors(err.response.data.detail, err.response.status))
    );
};

//Clear
export const clearCode = () => (dispatch, getState) => {
  dispatch({
    type: CLEAR_CODE,
  });
};

//Verify
export const verifyCode = body => (dispatch, getState) => {
  const formBody = Object.keys(body).map(key => encodeURIComponent(key) + '=' + encodeURIComponent(body[key])).join('&');

  axios
    .post(serverurl + "/verify_code", formBody, config)
    .then(res => {
      dispatch(createMessages({ verifyCode: "Kod został zweryfikowany" }));
      dispatch({
        type: VERIFY_CODE,
        payload: res.data
      });
    })
    .catch(err => {
      var status = err.response.status;
      if (status == 403) {
        dispatch(createMessages({ errorCode: err.response.data.message }));
      }
      else {
        dispatch(returnErrors(err.response.data.detail, err.response.status));
      }
    }
    );
};