import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import MyAssetView from "./EmployeeView//MyAssetView.js";
import MainManagerView from "./ManagerView/MainManagerView.js";
import MainWarehouseView from "./WarehouseView/MainWarehouseView.js";
import { connect } from "react-redux";
import { patchProfile } from "../Redux/actions/auth";
import { TabPanel } from "../common/TabPanel.js";
import MyInventoryViewer from "./InventoryView/MyInventoryViewer.js";
import ManagerInventoryViewer from "./InventoryView/ManagerInventoryViewer.js";

export class AssetsViewer extends Component {
  state = {
    value: null,
  };

  static propTypes = {
    auth: PropTypes.object.isRequired,
    patchProfile: PropTypes.func.isRequired,
  };

  static getDerivedStateFromProps(props, state) {
    if (props.auth.user !== null && state.value === null) {
      return {
        value: props.auth.main_profile.assetsDefaultTab,
      };
    } else {
      return null;
    }
  }

  handleChange = (event, newValue) => {
    this.setState({
      value: newValue,
    });
    const profileViewBody = {
      assetsDefaultTab: newValue,
    };

    this.props.patchProfile(profileViewBody, this.props.auth.main_profile.pk);
  };

  render() {
    const { value } = this.state;
    const {
      equipment_my_tab,
      equipment_employee_tab,
      equipment_warehouse_tab,
      equipment_inventory_admin,
      equipment_inventory_manager,
    } = this.props.auth.permissions;

    const isSuperUser = this.props.auth.user.is_superuser;

    var subViewsConfig;

    var inventoryManager = (
      <TabPanel value={value} index={4}>
        <ManagerInventoryViewer />
      </TabPanel>
    );

    subViewsConfig = (
      <Fragment>
        <Tabs
          value={value}
          onChange={this.handleChange}
          aria-label="simple tabs example"
        >
          <Tab label="Moje" disabled={equipment_my_tab || isSuperUser ? false : true} />
          <Tab label="Moje inwentury" disabled={equipment_my_tab || isSuperUser ? false : true} />
          <Tab label="Pracownicy" disabled={equipment_employee_tab || isSuperUser ? false : true} />
          <Tab label="Magazyn" disabled={equipment_warehouse_tab || isSuperUser ? false : true} />
          <Tab label="Inwentury" disabled={equipment_inventory_manager || equipment_inventory_admin || isSuperUser ? false : true} />
        </Tabs>
        <TabPanel value={value} index={0}>
          <MyAssetView />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <MyInventoryViewer />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <MainManagerView />
        </TabPanel>
        <TabPanel value={value} index={3}>
          <MainWarehouseView />
        </TabPanel>
        {equipment_inventory_manager || equipment_inventory_admin || isSuperUser ? inventoryManager : ""}
      </Fragment>
    );

    return <div>{subViewsConfig}</div>;
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(
  mapStateToProps,
  { patchProfile }
)(AssetsViewer);
