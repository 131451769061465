import React, { Component } from "react";
import RootMenu from "./RootMenu";
//Integracja reduxa
import { Provider } from "react-redux";
import store from "./store";
import { loadUser } from "./Redux/actions/auth";

//tutaj muszę dodać stan i flagę na podstawie której rozróżnię czy uzer jest zalogowany czy nie

class App extends Component {
  componentWillMount() {
    store.dispatch(loadUser());
  }

  render() {
    return (
      <Provider store={store}>
        <RootMenu></RootMenu>
      </Provider>
    );
  }
}

export default App;
