import axios from "axios";
import { createMessages, returnErrors } from "./messages";
import { serverurl, getDatabaseUrl } from "./databaseServerURL";
import { tokenConfig } from "./auth";

import {
  GET_MYMANAGER_DETAILS,
  GET_MYMANAGER_DETAILS_V2,
  GET_MYMANAGER_LIST,
  GET_EMPLOYEE_DETAILS,
  GET_EMPLOYEE_SECURE_DETAILS,
  PATCH_EMPLOYEE,
  SEND_NOTIFICATION
} from "./types";
import { getCompany } from "./getCompany";

//GET EMPLOYEE DETAILS
export const getEmployeeDetails = pk => (dispatch, getState) => {
  axios
    .get(serverurl + getDatabaseUrl() + `/employees/${pk}`, tokenConfig(getState))
    .then(res => {
      dispatch({
        type: GET_EMPLOYEE_DETAILS,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch(returnErrors(err.response.data.detail, err.response.status))
    );
};

//GET EMPLOYEE SECURE DETAILS
export const getEmployeeSecureDetails = pk => (dispatch, getState) => {
  axios
    .get(serverurl + getDatabaseUrl() + `/employees-secure/${pk}`, tokenConfig(getState))
    .then(res => {
      dispatch({
        type: GET_EMPLOYEE_SECURE_DETAILS,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch(returnErrors(err.response.data.detail, err.response.status))
    );
};

//GET MY MANAGER DETAILS
export const getMyManagerDetails = pk => (dispatch, getState) => {
  axios
    .get(serverurl + getDatabaseUrl() + `/mymanager/${pk}`, tokenConfig(getState))
    .then(res => {
      dispatch({
        type: GET_MYMANAGER_DETAILS,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch(returnErrors(err.response.data.detail, err.response.status))
    );
};

//GET MY MANAGER DETAILS - V2 (from User)!
export const getMyManagerDetailsV2 = pk => (dispatch, getState) => {
  axios
    .get(serverurl + `/auth/users/${pk}?db=${getCompany()}`, tokenConfig(getState))
    .then(res => {
      dispatch({
        type: GET_MYMANAGER_DETAILS_V2,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch(returnErrors(err.response.data.detail, err.response.status))
    );
};

//GET MY MANAGER LIST
export const getMyManagerList = () => (dispatch, getState) => {
  axios
    .get(serverurl + getDatabaseUrl() + `/mymanager/`, tokenConfig(getState))
    .then(res => {
      dispatch({
        type: GET_MYMANAGER_LIST,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch(returnErrors(err.response.data.detail, err.response.status))
    );
};

//PATCH EMPLOYEE
export const patchEmployee = (employee, pk) => (dispatch, getState) => {
  axios
    .patch(serverurl + getDatabaseUrl() + `/employees/${pk}`, employee, tokenConfig(getState))
    .then(res => {
      dispatch(
        createMessages({ patchEmployee: "Twoje dane zostały zaktualizowane " })
      );
      dispatch({
        type: PATCH_EMPLOYEE,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch(returnErrors(err.response.data.detail, err.response.status))
    );
};

//SEND NOTIFICATION
export const sendNotification = body => (dispatch, getState) => {
  axios
    .post(serverurl + "/notificationsms/", body, tokenConfig(getState))
    .then(res => {
      dispatch(
        createMessages({
          sendNotification: "Notyfikacja wysłana do użytkownika"
        })
      );
      dispatch({
        type: SEND_NOTIFICATION,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch(returnErrors(err.response.data.detail, err.response.status))
    );
};

// NEW NOTIFICATION
export const sendNewNotification = body => (dispatch, getState) => {
  axios
    .post(serverurl + getDatabaseUrl() + "/send_notify", body, tokenConfig(getState))
    .then(res => {
      dispatch(
        createMessages({
          sendNotification: "Powiadomienie wysłane do użytkownika"
        })
      );
      dispatch({
        type: SEND_NOTIFICATION,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch(returnErrors(err.response.data.detail, err.response.status))
    );
}

export const sendNewNotificationNoMail = body => (dispatch, getState) => {
  axios
    .post(serverurl + getDatabaseUrl() + "/send_notify_nomail", body, tokenConfig(getState))
    .then(res => {
      dispatch(
        createMessages({
          sendNotification: "Powiadomienie wysłane do użytkownika"
        })
      );
      dispatch({
        type: SEND_NOTIFICATION,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch(returnErrors(err.response.data.detail, err.response.status))
    );
}

export const sendNewNotificationManager = body => (dispatch, getState) => {
  axios
    .post(serverurl + getDatabaseUrl() + "/send_notify_manager", body, tokenConfig(getState))
    .then(res => {
      dispatch(
        createMessages({
          sendNotification: "Powiadomienie wysłane do przełożonego"
        })
      );
      dispatch({
        type: SEND_NOTIFICATION,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch(returnErrors(err.response.data.detail, err.response.status))
    );
}

export const sendNewNotificationKeepers = body => (dispatch, getState) => {
  axios
    .post(serverurl + getDatabaseUrl() + "/send_notify_keepers", body, tokenConfig(getState))
    .then(res => {
      dispatch(
        createMessages({
          sendNotification: "Powiadomienie wysłane do opiekunów sprzętu"
        })
      );
      dispatch({
        type: SEND_NOTIFICATION,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch(returnErrors(err.response.data.detail, err.response.status))
    );
}

export const sendNewNotificationBHP = body => (dispatch, getState) => {
  axios
    .post(serverurl + getDatabaseUrl() + "/send_notify_bhp", body, tokenConfig(getState))
    .then(res => {
      dispatch(
        createMessages({
          sendNotification: "Powiadomienie wysłane do BHP"
        })
      );
      dispatch({
        type: SEND_NOTIFICATION,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch(returnErrors(err.response.data.detail, err.response.status))
    );
}

export const sendNewNotificationIT = body => (dispatch, getState) => {
  axios
    .post(serverurl + getDatabaseUrl() + "/send_notify_it", body, tokenConfig(getState))
    .then(res => {
      dispatch(
        createMessages({
          sendNotification: "Powiadomienie wysłane do IT"
        })
      );
      dispatch({
        type: SEND_NOTIFICATION,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch(returnErrors(err.response.data.detail, err.response.status))
    );
}

export const sendNewNotificationControler = body => (dispatch, getState) => {
  axios
    .post(serverurl + getDatabaseUrl() + "/send_notify_controler", body, tokenConfig(getState))
    .then(res => {
      dispatch(
        createMessages({
          sendNotification: "Powiadomienie wysłane do kontrolingu"
        })
      );
      dispatch({
        type: SEND_NOTIFICATION,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch(returnErrors(err.response.data.detail, err.response.status))
    );
}

export const sendNewNotificationAccounting = body => (dispatch, getState) => {
  axios
    .post(serverurl + getDatabaseUrl() + "/send_notify_accounting", body, tokenConfig(getState))
    .then(res => {
      dispatch(
        createMessages({
          sendNotification: "Powiadomienie wysłane do księgowości"
        })
      );
      dispatch({
        type: SEND_NOTIFICATION,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch(returnErrors(err.response.data.detail, err.response.status))
    );
}
