import React, { Component, Fragment } from "react";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Avatar from "@material-ui/core/Avatar";
import CircularProgress from "@material-ui/core/CircularProgress";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Card from "../components/Card/Card.js";
import CardBody from "../components/Card/CardBody.js";
import Typography from "@material-ui/core/Typography";
import getFormattedDate from "../common/commonFunctions"

import {
  getUsersFullList,
  getUserFullDetails,
  clearUserFullDetails,
} from "../Redux/actions/common";
import {
  getDocuments,
  getCompanies,
  deleteDocument
} from "../Redux/actions/document";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { lighten } from "@material-ui/core/styles";
import CardHeader from "@material-ui/core/CardHeader";
import Zoom from "@material-ui/core/Zoom";
import Tooltip from "@material-ui/core/Tooltip";
import GetAppIcon from '@material-ui/icons/GetApp';
import SubjectIcon from '@material-ui/icons/Subject';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd';
import Fab from "@material-ui/core/Fab";
import { green } from "@material-ui/core/colors";
import { red } from "@material-ui/core/colors";
import Container from '@material-ui/core/Container';
import VisibilityIcon from "@material-ui/icons/Visibility";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Box from "@material-ui/core/Box";
import DocumentListItemChips from "./DocumentListItemChips.js";

const styles = (theme) => ({
  root: {
    width: "100%",
  },
  Name: {
    textAlign: "left",
    fontFamily: "Lato, Open Sans, sans-serif",
    fontSize: "1em",
    fontWeight: "700",
    color: "#444444",
  },
  Position: {
    textAlign: "left",
    fontFamily: "Lato, Open Sans, sans-serif",
    fontSize: "1em",
    fontWeight: "400",
    color: "#aaaaaa",
  },
  chipA: {
    background: "#81c784",
    color: "#ffffff",
  },
  chipN: {
    background: "#bbbbbb",
    color: "#ffffff",
  },
  header: {
    textAlign: "left",
    backgroundColor: lighten("#338dc9", 0.85),
  },
  avatar: {
    backgroundColor: "#0071bc",
  },
  headertitle: {
    fontFamily: "Lato, Open Sans, sans-serif",
    fontSize: "1.2em",
    fontWeight: "700",
    color: "#444444",
  },
  info: {
    fontFamily: "Lato, Open Sans, sans-serif",
    fontSize: "1.5em",
    fontWeight: "300",
    color: "#0071BC",
  },
  fabblue: {
    color: theme.palette.common.white,
    backgroundColor: "#2196f3",
    "&:hover": {
      backgroundColor: "#1976d2",
    },
  },
  fb1: {
    position: "fixed",
    bottom: theme.spacing(8),
    right: theme.spacing(5),
  },
  fb2: {
    position: "fixed",
    bottom: theme.spacing(16),
    right: theme.spacing(5),
  },
  fb3: {
    position: "fixed",
    bottom: theme.spacing(24),
    right: theme.spacing(5),
  },
  fabgreen: {
    color: theme.palette.common.white,
    backgroundColor: "#4caf50",
    "&:hover": {
      backgroundColor: green[600],
    },
  },
  fabred: {
    color: theme.palette.common.white,
    backgroundColor: red[600],
    "&:hover": {
      backgroundColor: red[600],
    },
  },
});

export class DocumentViewList extends Component {
  state = {
    selectedIndex: null,
    index: null,
    documentSelected: null,
    isDocumentSelected: false,
    selectedObject: null
  };

  static propTypes = {
    queryInProgressCF: PropTypes.bool.isRequired,
    deleteDocument: PropTypes.func.isRequired,
    defaultCompany: PropTypes.any.isRequired,
  };

  componentDidMount() {
    this.props.getDocuments(this.props.type);
  }

  componentDidUpdate(prevProps) {
    if (this.props.defaultCompany !== prevProps.defaultCompany) {
      this.props.getDocuments(this.props.type);
    }
  }

  handleListItemClick = (event, index, document) => {
    const selectedIndex = this.state.selectedIndex;
    if (selectedIndex !== index) {
      //console.log('set state');
      this.setState({
        selectedIndex: index,
        documentSelected: document.pk,
        isDocumentSelected: true,
        selectedObject: document
      });
    } else {
      this.setState({
        selectedIndex: null,
        documentSelected: null,
        isDocumentSelected: false,
        selectedObject: null
      });
    }
  };

  handleDownloadDocument = () => {
    //console.log(this.state);
    const file = this.state.selectedObject.file;
    window.location.href = file;
  };

  handleClickCloseDialog = () => {
  };

  triggerAddNewDocument = () => {
    //console.log("add new!");
  }

  handleDeleteDocument = () => {
    this.props.deleteDocument(this.state.selectedObject.pk);
  }

  render() {
    //console.log(this.props);
    const { module_document_admin } = this.props.auth.permissions;
    const isSuperUser = this.props.auth.is_superuser;
    const { classes, queryInProgressCF, documents } = this.props;
    const { selectedIndex, isDocumentSelected } = this.state;

    var documentsList = null;
    const queryInProgress = <CircularProgress color="secondary" />;

    const nothingToDisplay = (
      <div className={classes.info}>Brak dokumentów do wyświetlenia ...</div>
    );

    const waitToDisplay = (
      <div className={classes.info}>Czekaj, pobieram dane z serwera ...</div>
    );

    const showButtonDisabled = (
      <Fab
        disabled
        color="inherit"
        className={classes.fabblue}
        size="small"
        aria-label="scroll back to top"
      >
        <GetAppIcon />
      </Fab>
    );

    const showButtonEnabled = (
      <Fab
        color="inherit"
        className={classes.fabblue}
        size="small"
        aria-label="scroll back to top"
      >
        <GetAppIcon />
      </Fab>
    );

    if (documents !== undefined) {

      documentsList = (
        <List component="nav" aria-label="main mailbox folders">
          {documents.length === 0 && !queryInProgressCF ? nothingToDisplay : ""}
          {documents.length === 0 && queryInProgressCF ? waitToDisplay : ""}
          {documents.map((document, index) => (
            <ListItem
              key={document.pk}
              button
              selected={selectedIndex === index}
              onClick={(event) =>
                this.handleListItemClick(event, index, document)
              }
            >
              <Box m="2rem">
                <SubjectIcon color="primary" />
              </Box>
              <ListItemText
                primary={
                  <Fragment>
                    <Typography className={classes.Name}>
                      {document.name}
                    </Typography>
                  </Fragment>
                }
                secondary={
                  <Typography className={classes.Position}>
                    Zatwierdzony przez: {document.approver.first_name + ' ' + document.approver.last_name}<br />
                    Data dodania: {getFormattedDate(new Date(document.created_at))}<br />
                    Data modyfikacji: {getFormattedDate(new Date(document.updated_at))}
                  </Typography>
                }
              />
              <DocumentListItemChips companies={document.companies} />
            </ListItem>
          ))}
        </List>
      );
    } else {
      documentsList = <List className={classes.root}></List>;
    }

    return (
      <Fragment>
        <Grid container justify="flex-start" spacing={2}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Card style={{ overflow: "auto" }}>
              <CardBody style={{ padding: 0 }}>
                <CardHeader
                  className={classes.header}
                  avatar={
                    <Avatar aria-label="recipe" className={classes.avatar}>
                      Dok
                    </Avatar>
                  }
                  action={queryInProgressCF ? queryInProgress : ""}
                  title={
                    <div className={classes.headertitle}>
                      Lista dokumentów
                    </div>
                  }
                />
                {documentsList}
              </CardBody>
            </Card>
          </Grid>
        </Grid>
        <Zoom in={isDocumentSelected}>
          <div
            onClick={this.handleDownloadDocument}
            role="presentation"
            className={classes.fb1}
          >
            <Tooltip title="Pobierz" placement="left">
              {queryInProgressCF ? showButtonDisabled : showButtonEnabled}
            </Tooltip>
          </div>
        </Zoom>
        <Zoom in={(isSuperUser || module_document_admin) && isDocumentSelected ? true : false}>
          <div className={classes.fb2}>
            <Tooltip title="Usuń zaznaczony dokument" placement="left">
              <Fab
                onClick={this.handleDeleteDocument}
                className={classes.fabred}
                size="small"
              >
                <DeleteForeverIcon />
              </Fab>
            </Tooltip>
          </div>
        </Zoom>
        <Zoom in={isSuperUser || module_document_admin ? true : false}>
          <div className={classes.fb3}>
            <Tooltip title="Dodaj nowy dokument" placement="left">
              <Fab
                onClick={this.triggerAddNewDocument}
                color="inherit"
                className={classes.fabgreen}
                size="small"
              >
                <PlaylistAddIcon />
              </Fab>
            </Tooltip>
          </div>
        </Zoom>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  queryInProgressCF: state.document.queryInProgressCF,
  auth: state.auth,
  documents: state.document.documents,
  defaultCompany: state.auth.main_profile.defaultCompany,
});

export default connect(
  mapStateToProps,
  { deleteDocument, getDocuments }
)(withStyles(styles, { withTheme: true })(DocumentViewList));
