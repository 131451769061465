import {
  GET_LEAVE_CONFIG_SUCCESS,
  GET_LEAVE_CONFIG_INPROGRESS,
  GET_MYLEAVE_POOL,
  GET_MYLEAVE,
  CLEAR_MYLEAVE,
  GET_MYLEAVE_DETAILS,
  ADD_LEAVE,
  DELETE_LEAVE
} from "../actions/types";

const initialState = {
  isGettingConfig: false,
  leaveConfig: [],
  leavePool: [],
  leave: [],
  leaveDetails: {}
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_LEAVE_CONFIG_SUCCESS:
      return {
        ...state,
        leaveConfig: action.payload,
        isGettingConfig: false
      };
    case GET_LEAVE_CONFIG_INPROGRESS:
      return {
        ...state,
        isGettingConfig: true
      };
    case GET_MYLEAVE_POOL:
      return {
        ...state,
        leavePool: action.payload,
      };
    case GET_MYLEAVE:
      return {
        ...state,
        leave: action.payload
      };
    case CLEAR_MYLEAVE:
      return {
        ...state,
        leave: []
      };
    case GET_MYLEAVE_DETAILS:
      return {
        ...state,
        leaveDetails: action.payload
      };
    case ADD_LEAVE:
      return {
        ...state,
        leave: [...state.leave, action.payload]
      };
    case DELETE_LEAVE:
      return {
        ...state,
        leave: state.leave.filter(oneleave => oneleave.pk !== action.payload)
      };
    default:
      return state;
  }
}
