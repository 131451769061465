import React, { Component, Fragment } from "react";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import CircularProgress from "@material-ui/core/CircularProgress";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Card from "../components/Card/Card.js";
import CardBody from "../components/Card/CardBody.js";
import Typography from "@material-ui/core/Typography";
import { getUsersContactsList } from "../Redux/actions/common";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { lighten } from "@material-ui/core/styles";
import CardHeader from "@material-ui/core/CardHeader";
import PhoneIcon from "@material-ui/icons/Phone";
import MailIcon from "@material-ui/icons/Mail";
import { createMuiTheme } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";

const materialTheme = createMuiTheme({
  palette: {
    primary: {
      main: "#0071BC",
    },
  },
  overrides: {
    MuiChip: {
      root: {
        color: "#ffffff",
        "& $avatar": {
          color: "#ffffff",
        },
      },
      icon: {
        color: "#ffffff",
      },
    },
    MuiCardActions: {
      root: {
        background: "#aaaaaa",
      },
    },
    MuiIconButton: {
      root: {
        color: "#fff",
      },
    },
  },
});

const styles = (theme) => ({
  root: {
    width: "100%",
  },
  Name: {
    textAlign: "left",
    fontFamily: "Lato, Open Sans, sans-serif",
    fontSize: "1em",
    fontWeight: "700",
    color: "#444444",
  },
  Position: {
    textAlign: "left",
    fontFamily: "Lato, Open Sans, sans-serif",
    fontSize: "1em",
    fontWeight: "400",
    color: "#aaaaaa",
  },
  chipA: {
    background: "#81c784",
    color: "#ffffff",
  },
  chipN: {
    background: "#bbbbbb",
    color: "#ffffff",
  },
  header: {
    textAlign: "left",
    backgroundColor: lighten("#338dc9", 0.85),
  },
  avatar: {
    backgroundColor: "#0071bc",
  },
  headertitle: {
    fontFamily: "Lato, Open Sans, sans-serif",
    fontSize: "1.2em",
    fontWeight: "700",
    color: "#444444",
  },
  info: {
    fontFamily: "Lato, Open Sans, sans-serif",
    fontSize: "1.5em",
    fontWeight: "300",
    color: "#0071BC",
  },
  fabblue: {
    color: theme.palette.common.white,
    backgroundColor: "#2196f3",
    "&:hover": {
      backgroundColor: "#1976d2",
    },
  },
  fb1: {
    position: "fixed",
    bottom: theme.spacing(8),
    right: theme.spacing(5),
  },
  inline: {
    display: "inline",
  },
});

export class EmployeesBook extends Component {
  state = {
    selectedIndex: null,
    index: null,
    userSelected: null,
    isUserSelected: false,
    openDialog: false,
  };

  static propTypes = {
    usersContacts: PropTypes.array.isRequired,
    queryInProgressCFC: PropTypes.bool.isRequired,
    getUsersContactsList: PropTypes.func.isRequired,
  };

  componentDidMount() {
    if (this.props.usersContacts.length === 0) {
      this.props.getUsersContactsList();
    }
  }

  handleListItemClick = (event, index, id) => {
    const selectedIndex = this.state.selectedIndex;
    if (selectedIndex !== index) {
      this.setState({
        selectedIndex: index,
        userSelected: id,
        isUserSelected: true,
      });
    } else {
      this.setState({
        selectedIndex: null,
        userSelected: null,
        isUserSelected: false,
      });
    }
  };

  render() {
    const { classes, usersContacts, queryInProgressCFC } = this.props;
    const { selectedIndex } = this.state;

    var usersList = null;
    const queryInProgress = <CircularProgress color="secondary" />;

    const nothingToDisplay = (
      <div className={classes.info}>Brak pracowników do wyświetlenia ...</div>
    );

    const waitToDisplay = (
      <div className={classes.info}>Czekaj, pobieram dane z serwera ...</div>
    );

    if (usersContacts !== undefined) {
      usersList = (
        <List component="nav" aria-label="main mailbox folders">
          {usersContacts.length === 0 && !queryInProgressCFC
            ? nothingToDisplay
            : ""}
          {usersContacts.length === 0 && queryInProgressCFC
            ? waitToDisplay
            : ""}
          {usersContacts.map((user, index) => (
            <ListItem
              key={user.pk}
              button
              selected={selectedIndex === index}
              onClick={(event) =>
                this.handleListItemClick(event, index, user.id)
              }
            >
              <ListItemAvatar>
                <Avatar src={user.profileImage}>
                  {user.last_name.substr(0, 1) + user.first_name.substr(0, 1)}
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={
                  <Fragment>
                    <Typography
                      component="span"
                      className={`${classes.inline} ${classes.Name}`}
                    >
                      {user.last_name + " " + user.first_name}
                    </Typography>
                    <Typography
                      component="span"
                      className={`${classes.inline} ${classes.Position}`}
                    >
                      {" | " + user.position}
                    </Typography>
                  </Fragment>
                }
                secondary={
                  <Fragment>
                    <ThemeProvider theme={materialTheme}>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <PhoneIcon color="primary" />
                        <Typography
                          component="span"
                          className={classes.Position}
                        >
                          {" | " + user.phone}
                        </Typography>
                      </div>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <MailIcon color="primary" />
                        <Typography
                          component="span"
                          className={classes.Position}
                        >
                          {" | " + user.email}
                        </Typography>
                      </div>
                    </ThemeProvider>
                  </Fragment>
                }
              />
            </ListItem>
          ))}
        </List>
      );
    } else {
      usersList = <List className={classes.root}></List>;
    }

    return (
      <Fragment>
        <Grid container justify="flex-start" spacing={2}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Card style={{ overflow: "auto" }}>
              <CardBody style={{ padding: 0 }}>
                <CardHeader
                  className={classes.header}
                  avatar={
                    <Avatar aria-label="recipe" className={classes.avatar}>
                      Pr
                    </Avatar>
                  }
                  action={queryInProgressCFC ? queryInProgress : ""}
                  title={
                    <div className={classes.headertitle}>Dane kontaktowe</div>
                  }
                />
                {usersList}
              </CardBody>
            </Card>
          </Grid>
        </Grid>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  usersContacts: state.common.usersContacts,
  queryInProgressCFC: state.common.queryInProgressCFC,
});

export default connect(
  mapStateToProps,
  { getUsersContactsList }
)(withStyles(styles, { withTheme: true })(EmployeesBook));
