import axios from "axios";
import { createMessages, returnErrors } from "./messages";
import { tokenConfig } from "./auth";
import { serverurl, getDatabaseUrl } from "./databaseServerURL";

import {
  GET_PROJECTS,
  GET_ACTIVE_PROJECTS,
  DELETE_PROJECT,
  ADD_PROJECT,
  UPDATE_PROJECT,
  CLEAR_PROJECTS,
  CLEAR_ACTIVE_PROJECTS,
} from "./types";

//GET PROJECTS
export const getProjects = () => (dispatch, getState) => {
  axios
    .get(serverurl + "/projects", tokenConfig(getState))
    .then(res => {
      dispatch({
        type: GET_PROJECTS,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch(returnErrors(err.response.data.detail, err.response.status))
    );
};

export const clearProjects = () => (dispatch, getState) => {
  dispatch({ type: CLEAR_PROJECTS });
};

export const getActiveProjects = () => (dispatch, getState) => {
  axios
    .get(serverurl + "/projects?active=True", tokenConfig(getState))
    .then(res => {
      dispatch({
        type: GET_ACTIVE_PROJECTS,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch(returnErrors(err.response.data.detail, err.response.status))
    );
};

export const clearActiveProjects = () => (dispatch, getState) => {
  dispatch({ type: CLEAR_ACTIVE_PROJECTS });
};

//DELETE PROJECT
export const deleteProject = id => (dispatch, getState) => {
  axios
    .delete(serverurl + `/project/${id}`, tokenConfig(getState))
    .then(res => {
      dispatch(createMessages({ deleteProject: "Projekt został usunięty" }));
      dispatch({
        type: DELETE_PROJECT,
        payload: id
      });
    })
    .catch(err =>
      dispatch(returnErrors(err.response.data.detail, err.response.status))
    );
};

//ADD PROJECT
export const addProject = body => (dispatch, getState) => {
  axios
    .post(serverurl + "/project", body, tokenConfig(getState))
    .then(res => {
      dispatch(createMessages({ addProject: "Projekt został dodany" }));
      dispatch({
        type: ADD_PROJECT,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch(returnErrors(err.response.data.detail, err.response.status))
    );
};

//UPDATE PROJECT
export const updateProject = (body, pk) => (dispatch, getState) => {
  axios
    .put(serverurl + `/project/${pk}`, body, tokenConfig(getState))
    .then(res => {
      dispatch(createMessages({ patchProject: "Projekt został zapisany" }));
      dispatch({
        type: UPDATE_PROJECT,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch(returnErrors(err.response.data.detail, err.response.status))
    );
};
