import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import BeachAccessIcon from "@material-ui/icons/BeachAccess";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
//integracja z reduxem
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { deleteLeave } from "../Redux/actions/holidays";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";

class Holidays extends Component {
  state = {
    dialogDeleteOpen: false,
    deleteLeaveId: null
  };

  //integracja z reduxem
  static propTypes = {
    holidays: PropTypes.object.isRequired,
    deleteLeave: PropTypes.func.isRequired
  };

  handleDeleteLeave = () => {
    const LeaveToBeDeleted = this.state.deleteLeaveId;
    this.props.deleteLeave(LeaveToBeDeleted);
    this.setState({
      dialogDeleteOpen: false,
      deleteLeaveId: null
    });
  };

  handleOpenWarning = LeaveId => {
    this.setState({
      dialogDeleteOpen: true,
      deleteLeaveId: LeaveId
    });
  };

  handleCloseWarning = () => {
    this.setState({
      dialogDeleteOpen: false,
      deleteleaveId: null
    });
  };

  render() {
    const { status } = this.props;
    const { leave } = this.props.holidays;
    const LeavesFiltered = [];
    const { dialogDeleteOpen } = this.state;

    const dialogDelete = (
      <Dialog
        open={dialogDeleteOpen}
        onClose={this.handleCloseWarning}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Wybrany wniosek zostanie skasowany."}
        </DialogTitle>
        <DialogActions>
          <Button onClick={this.handleCloseWarning} color="primary">
            Anuluj
          </Button>
          <Button onClick={this.handleDeleteLeave} color="primary" autoFocus>
            Skasuj
          </Button>
        </DialogActions>
      </Dialog>
    );

    leave.map(oneleave => {
      if (oneleave.status === status) {
        LeavesFiltered.push(oneleave);
      }
    });

    if (status === "OD") {
      return (
        <Fragment>
          <List>
            {LeavesFiltered.map(oneleave => (
              <ListItem
                key={oneleave.pk}
                component={Link}
                to={"/holidays/my/" + oneleave.pk}
              >
                <ListItemAvatar>
                  <Avatar style={{ backgroundColor: "#aaaaaa" }}>
                    <BeachAccessIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={oneleave.startDate + " | " + oneleave.endDate}
                  secondary={
                    oneleave.statusDescription +
                    ", " +
                    oneleave.leaveTypeDescription
                  }
                />
                <ListItemSecondaryAction>
                  <IconButton
                    edge="end"
                    aria-label="delete"
                    listid={oneleave.pk}
                    onClick={this.handleOpenWarning.bind(this, oneleave.pk)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
          {dialogDelete}
        </Fragment>
      );
    } else if (status === "CA") {
      return (
        <Fragment>
          <List>
            {LeavesFiltered.map(oneleave => (
              <ListItem
                key={oneleave.pk}
                component={Link}
                to={"/holidays/my/" + oneleave.pk}
              >
                <ListItemAvatar>
                  <Avatar style={{ backgroundColor: "#c2a33a" }}>
                    <BeachAccessIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={oneleave.startDate + " | " + oneleave.endDate}
                  secondary={
                    oneleave.statusDescription +
                    ", " +
                    oneleave.leaveTypeDescription
                  }
                />
                <ListItemSecondaryAction>
                  <IconButton
                    edge="end"
                    aria-label="delete"
                    listid={oneleave.pk}
                    onClick={this.handleOpenWarning.bind(this, oneleave.pk)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
          {dialogDelete}
        </Fragment>
      );
    } else if (status === "ZA") {
      return (
        <Fragment>
          <List>
            {LeavesFiltered.map(oneleave => (
              <ListItem
                key={oneleave.pk}
                component={Link}
                to={"/holidays/my/" + oneleave.pk}
              >
                <ListItemAvatar>
                  <Avatar style={{ backgroundColor: "#0071bc" }}>
                    <BeachAccessIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={oneleave.startDate + " | " + oneleave.endDate}
                  secondary={
                    oneleave.statusDescription +
                    ", " +
                    oneleave.leaveTypeDescription
                  }
                />
              </ListItem>
            ))}
          </List>
        </Fragment>
      );
    }
    //jeżeli label Zaakceptowane i Zamknięte to renderuj ...
  }
}

const mapStateToProps = state => ({
  holidays: state.holidays
});

export default connect(mapStateToProps, { deleteLeave })(Holidays);
