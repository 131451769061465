import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import ListItemText from "@material-ui/core/ListItemText";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import CircularProgress from "@material-ui/core/CircularProgress";

const styles = theme => ({
  progress: {
    margin: theme.spacing(2)
  }
});

export class TripsManagerTrips extends Component {
  static propTypes = {
    tripsManagerReducer: PropTypes.object.isRequired
  };
  render() {
    const { classes, label, status, employeeTrips } = this.props;
    const { queryInProgress, bulkQueryInProgressCA, bulkQueryInProgressZA, bulkQueryInProgressRZ } = this.props.tripsManagerReducer;

    const title = (
      <h1>
        {label} ({employeeTrips.length})
      </h1>
    );

    const circle = (
      <Fragment>
        {title}
        <CircularProgress className={classes.progress} color="secondary" />
        <div style={{ marginBottom: 130 }}></div>
      </Fragment>
    );

    if (status === "CA") {
      if (bulkQueryInProgressCA === false) {
        return (
          <Fragment>
            <h1>
              {label} ({employeeTrips.length})
            </h1>
            <List>
              {employeeTrips.map(trip => (
                <ListItem
                  key={trip.pk}
                  component={Link}
                  to={"/trips/employees/" + trip.pk}
                >
                  <ListItemAvatar>
                    <Avatar style={{ backgroundColor: "#c2a33a" }}>
                      {trip.employee.last_name.substr(0, 1).toUpperCase()}
                      {trip.employee.first_name.substr(0, 1).toUpperCase()}
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      trip.endDate
                        ? trip.startDate + " | " + trip.endDate
                        : trip.startDate + " | "
                    }
                    secondary={trip.employee.last_name + " " + trip.employee.first_name + " | " + trip.place}
                  />
                </ListItem>
              ))}
            </List>
          </Fragment>
        );
      } else {
        return (
          <Fragment>
            {circle}
          </Fragment>
        );
      }
    } else if (status === "ZA") {
      if (bulkQueryInProgressZA === false) {
        return (
          <Fragment>
            <h1>
              {label} ({employeeTrips.length})
            </h1>
            <List>
              {employeeTrips.map(trip => (
                <ListItem
                  key={trip.pk}
                  component={Link}
                  to={"/trips/employees/" + trip.pk}
                >
                  <ListItemAvatar>
                    <Avatar style={{ backgroundColor: "#0071bc" }}>
                      {trip.employee.last_name.substr(0, 1).toUpperCase()}
                      {trip.employee.first_name.substr(0, 1).toUpperCase()}
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      trip.endDate
                        ? trip.startDate + " | " + trip.endDate
                        : trip.startDate + " | "
                    }
                    secondary={trip.employee.last_name + " " + trip.employee.first_name + " | " + trip.place}
                  />
                </ListItem>
              ))}
            </List>
          </Fragment>
        );
      } else {
        return (
          <Fragment>
            {circle}
          </Fragment>
        );
      }
    } else if (status === "RZ") {
      if (bulkQueryInProgressRZ === false) {
        return (
          <Fragment>
            <h1>
              {label} ({employeeTrips.length})
            </h1>
            <List>
              {employeeTrips.map(trip => (
                <ListItem
                  key={trip.pk}
                  component={Link}
                  to={"/trips/employees/" + trip.pk}
                >
                  <ListItemAvatar>
                    <Avatar style={{ backgroundColor: "#b35340" }}>
                      {trip.employee.last_name.substr(0, 1).toUpperCase()}
                      {trip.employee.first_name.substr(0, 1).toUpperCase()}
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      trip.endDate
                        ? trip.startDate + " | " + trip.endDate
                        : trip.startDate + " | "
                    }
                    secondary={trip.employee.last_name + " " + trip.employee.first_name + " | " + trip.place}
                  />
                </ListItem>
              ))}
            </List>
          </Fragment>
        );
      } else {
        return (
          <Fragment>
            {circle}
          </Fragment>
        );
      }
    } else if (status === "FILTERED") {
      const title = (
        <h1>
          {label} ({employeeTrips.length})
        </h1>
      );

      if (queryInProgress === false) {
        return (
          <Fragment>
            {title}
            <List>
              {employeeTrips.map(trip => (
                <ListItem
                  key={trip.pk}
                  component={Link}
                  to={"/trips/employees/" + trip.pk}
                >
                  <ListItemAvatar>
                    <Avatar style={{ backgroundColor: "#aaaaaa" }}>
                      {trip.employee.last_name.substr(0, 1).toUpperCase()}
                      {trip.employee.first_name.substr(0, 1).toUpperCase()}
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      trip.endDate
                        ? trip.startDate + " | " + trip.endDate
                        : trip.startDate + " | "
                    }
                    secondary={trip.employee.last_name + " " + trip.employee.first_name + " | " + trip.place}
                  />
                </ListItem>
              ))}
            </List>
            <div style={{ marginBottom: 130 }}></div>
          </Fragment>
        );
      } else {
        return (
          <Fragment>
            {circle}
          </Fragment>
        );
      }
    } else {
      return null;
    }
  }
}

const mapStateToProps = state => ({
  tripsManagerReducer: state.tripsManagerReducer
});

export default connect(
  mapStateToProps,
  {}
)(withStyles(styles, { withTheme: true })(TripsManagerTrips));
