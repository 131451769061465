import React, { Component } from "react";
// @material-ui/core components
import Radio from "@material-ui/core/Radio";
import FormControlLabel from "@material-ui/core/FormControlLabel";
// @material-ui/icons
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";
// core components
import styles from "../../assets/jss/material-kit-pro-react/customCheckboxRadioSwitchStyle.js";
import Grid from "@material-ui/core/Grid";
import Card from "../../components/Card/Card.js";
import CardBody from "../../components/Card/CardBody.js";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";

export class AssetNavigation extends Component {
  state = {
    selectedEnabled: "al",
  };

  setSelectedEnabled = (category) => {
    this.setState({
      selectedEnabled: category,
    });
    this.props.eqTypeSelected(category);
  };

  render() {
    //const { classes } = this.props;
    const { selectedEnabled } = this.state;
    const { classes } = this.props;
    return (
      <div>
        <Grid container justify="center">
          <Grid item xs={12} sm={12} md={12}>
            <Card style={{ overflow: "auto", marginTop: 0, marginBottom: "16px" }}>
              <CardBody>
                <Typography
                  style={{ marginBottom: "20px", color: "rgb(136, 136, 136)", fontFamily: "Lato, 'Open Sans'", fontSize: "14px", fontWeight: "300", textTransform: "uppercase", textAlign: "center" }}
                  className={classes.textType}
                >
                  Zaznacz grupę środków trwałych, którą chcesz wyświetlić ...
                </Typography>
                <Grid container style={{ textAlign: "left" }}>
                  <Grid item xs={11} sm={4} md={2}>
                    <FormControlLabel
                      control={
                        <Radio
                          checked={selectedEnabled === "al"}
                          onChange={() => this.setSelectedEnabled("al")}
                          value="al"
                          name="radio button enabled"
                          aria-label="A"
                          icon={
                            <FiberManualRecord
                              className={classes.radioUnchecked}
                            />
                          }
                          checkedIcon={
                            <FiberManualRecord
                              className={classes.radioChecked}
                            />
                          }
                          classes={{
                            checked: classes.radio,
                            root: classes.radioRoot,
                          }}
                        />
                      }
                      classes={{
                        label: classes.label,
                      }}
                      label="BHP"
                    />
                  </Grid>
                  <Grid item xs={11} sm={4} md={2}>
                    <FormControlLabel
                      control={
                        <Radio
                          checked={selectedEnabled === "na"}
                          onChange={() => this.setSelectedEnabled("na")}
                          value="na"
                          name="radio button enabled"
                          aria-label="A"
                          icon={
                            <FiberManualRecord
                              className={classes.radioUnchecked}
                            />
                          }
                          checkedIcon={
                            <FiberManualRecord
                              className={classes.radioChecked}
                            />
                          }
                          classes={{
                            checked: classes.radio,
                            root: classes.radioRoot,
                          }}
                        />
                      }
                      classes={{
                        label: classes.label,
                      }}
                      label="Narzędzia"
                    />
                  </Grid>
                  <Grid item xs={11} sm={4} md={2}>
                    <FormControlLabel
                      control={
                        <Radio
                          checked={selectedEnabled === "el"}
                          onChange={() => this.setSelectedEnabled("el")}
                          value="el"
                          name="radio button enabled"
                          aria-label="A"
                          icon={
                            <FiberManualRecord
                              className={classes.radioUnchecked}
                            />
                          }
                          checkedIcon={
                            <FiberManualRecord
                              className={classes.radioChecked}
                            />
                          }
                          classes={{
                            checked: classes.radio,
                            root: classes.radioRoot,
                          }}
                        />
                      }
                      classes={{
                        label: classes.label,
                      }}
                      label="Elektro-narzędzia"
                    />
                  </Grid>
                  <Grid item xs={11} sm={4} md={2}>
                    <FormControlLabel
                      control={
                        <Radio
                          checked={selectedEnabled === "it"}
                          onChange={() => this.setSelectedEnabled("it")}
                          value="it"
                          name="radio button enabled"
                          aria-label="A"
                          icon={
                            <FiberManualRecord
                              className={classes.radioUnchecked}
                            />
                          }
                          checkedIcon={
                            <FiberManualRecord
                              className={classes.radioChecked}
                            />
                          }
                          classes={{
                            checked: classes.radio,
                            root: classes.radioRoot,
                          }}
                        />
                      }
                      classes={{
                        label: classes.label,
                      }}
                      label="IT"
                    />
                  </Grid>
                  <Grid item xs={11} sm={4} md={2}>
                    <FormControlLabel
                      control={
                        <Radio
                          checked={selectedEnabled === "po"}
                          onChange={() => this.setSelectedEnabled("po")}
                          value="po"
                          name="radio button enabled"
                          aria-label="A"
                          icon={
                            <FiberManualRecord
                              className={classes.radioUnchecked}
                            />
                          }
                          checkedIcon={
                            <FiberManualRecord
                              className={classes.radioChecked}
                            />
                          }
                          classes={{
                            checked: classes.radio,
                            root: classes.radioRoot,
                          }}
                        />
                      }
                      classes={{
                        label: classes.label,
                      }}
                      label="Pozostałe"
                    />
                  </Grid>
                </Grid>
              </CardBody>
            </Card>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(AssetNavigation);
