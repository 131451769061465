import React, { Component, Fragment } from "react";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
//import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import Avatar from "@material-ui/core/Avatar";
//import ImageIcon from "@material-ui/icons/Image";
//import WorkIcon from "@material-ui/icons/Work";
//import BeachAccessIcon from "@material-ui/icons/BeachAccess";
import CircularProgress from "@material-ui/core/CircularProgress";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Card from "../components/Card/Card.js";
import CardBody from "../components/Card/CardBody.js";
import Typography from "@material-ui/core/Typography";
import {
  getUsersFullList,
  getUserFullDetails,
  clearUserFullDetails,
} from "../Redux/actions/common";
import PropTypes from "prop-types";
import { connect } from "react-redux";
//import Chip from "@material-ui/core/Chip";
import { lighten } from "@material-ui/core/styles";
import CardHeader from "@material-ui/core/CardHeader";
import Zoom from "@material-ui/core/Zoom";
import Tooltip from "@material-ui/core/Tooltip";
import Fab from "@material-ui/core/Fab";
import VisibilityIcon from "@material-ui/icons/Visibility";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Box from "@material-ui/core/Box";
import NameCardE from "./NameCardE";
import ProfileCardE from "./ProfileCardE";
import BankingCardE from "./BankingCardE";
import { getActiveInventory } from "../Redux/actions/assets";

const styles = (theme) => ({
  root: {
    width: "100%",
  },
  Name: {
    textAlign: "left",
    fontFamily: "Lato, Open Sans, sans-serif",
    fontSize: "1em",
    fontWeight: "700",
    color: "#444444",
  },
  Position: {
    textAlign: "left",
    fontFamily: "Lato, Open Sans, sans-serif",
    fontSize: "1em",
    fontWeight: "400",
    color: "#aaaaaa",
  },
  chipA: {
    background: "#81c784",
    color: "#ffffff",
  },
  chipN: {
    background: "#bbbbbb",
    color: "#ffffff",
  },
  header: {
    textAlign: "left",
    backgroundColor: lighten("#338dc9", 0.85),
  },
  avatar: {
    backgroundColor: "#0071bc",
  },
  headertitle: {
    fontFamily: "Lato, Open Sans, sans-serif",
    fontSize: "1.2em",
    fontWeight: "700",
    color: "#444444",
  },
  info: {
    fontFamily: "Lato, Open Sans, sans-serif",
    fontSize: "1.5em",
    fontWeight: "300",
    color: "#0071BC",
  },
  fabblue: {
    color: theme.palette.common.white,
    backgroundColor: "#2196f3",
    "&:hover": {
      backgroundColor: "#1976d2",
    },
  },
  fb1: {
    position: "fixed",
    bottom: theme.spacing(8),
    right: theme.spacing(5),
  },
});

export class EmployeesDetail extends Component {
  state = {
    selectedIndex: null,
    index: null,
    userSelected: null,
    isUserSelected: false,
    openDialog: false,
  };

  static propTypes = {
    usersFull: PropTypes.array.isRequired,
    userFull: PropTypes.object.isRequired,
    queryInProgressCF: PropTypes.bool.isRequired,
    getUsersFullList: PropTypes.func.isRequired,
    getUserFullDetails: PropTypes.func.isRequired,
  };

  componentDidMount() {
    if (this.props.usersFull.length === 0) {
      this.props.getUsersFullList();
    }
  }

  handleListItemClick = (event, index, id) => {
    const selectedIndex = this.state.selectedIndex;
    if (selectedIndex !== index) {
      this.setState({
        selectedIndex: index,
        userSelected: id,
        isUserSelected: true,
      });
      this.props.getUserFullDetails(id);
      this.props.getActiveInventory(id);
    } else {
      this.setState({
        selectedIndex: null,
        userSelected: null,
        isUserSelected: false,
      });
      this.props.clearUserFullDetails();
    }
  };

  handleDiscardAssetTransfer = () => {
    this.setState({
      openDialog: true,
    });
  };

  handleClickCloseDialog = () => {
    this.setState({
      openDialog: false,
    });
  };

  render() {
    const { classes, usersFull, queryInProgressCF } = this.props;
    const { selectedIndex, isUserSelected, openDialog } = this.state;

    var usersList = null;
    const queryInProgress = <CircularProgress color="secondary" />;

    const nothingToDisplay = (
      <div className={classes.info}>Brak pracowników do wyświetlenia ...</div>
    );

    const waitToDisplay = (
      <div className={classes.info}>Czekaj, pobieram dane z serwera ...</div>
    );

    const showButtonDisabled = (
      <Fab
        disabled
        color="inherit"
        className={classes.fabblue}
        size="small"
        aria-label="scroll back to top"
      >
        <VisibilityIcon />
      </Fab>
    );

    const showButtonEnabled = (
      <Fab
        color="inherit"
        className={classes.fabblue}
        size="small"
        aria-label="scroll back to top"
      >
        <VisibilityIcon />
      </Fab>
    );

    if (usersFull !== undefined) {
      usersList = (
        <List component="nav" aria-label="main mailbox folders">
          {usersFull.length === 0 && !queryInProgressCF ? nothingToDisplay : ""}
          {usersFull.length === 0 && queryInProgressCF ? waitToDisplay : ""}
          {usersFull.map((user, index) => (
            <ListItem
              key={user.username}
              button
              selected={selectedIndex === index}
              onClick={(event) =>
                this.handleListItemClick(event, index, user.pk)
              }
            >
              <ListItemAvatar>
                <Avatar src={user.profileImage}>
                  {user.last_name.substr(0, 1) + user.first_name.substr(0, 1)}
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={
                  <Fragment>
                    <Typography className={classes.Name}>
                      {user.last_name + " " + user.first_name}
                    </Typography>
                  </Fragment>
                }
                secondary={
                  <Typography className={classes.Position}>
                    {user.position}
                  </Typography>
                }
              />
            </ListItem>
          ))}
        </List>
      );
    } else {
      usersList = <List className={classes.root}></List>;
    }

    return (
      <Fragment>
        <Grid container justify="flex-start" spacing={2}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Card style={{ overflow: "auto" }}>
              <CardBody style={{ padding: 0 }}>
                <CardHeader
                  className={classes.header}
                  avatar={
                    <Avatar aria-label="recipe" className={classes.avatar}>
                      Pr
                    </Avatar>
                  }
                  action={queryInProgressCF ? queryInProgress : ""}
                  title={
                    <div className={classes.headertitle}>
                      Pracownicy Radioline
                    </div>
                  }
                />
                {usersList}
              </CardBody>
            </Card>
          </Grid>
        </Grid>
        <Zoom in={isUserSelected}>
          <div
            onClick={this.handleDiscardAssetTransfer}
            role="presentation"
            className={classes.fb1}
          >
            <Tooltip title="Wyświetl" placement="left">
              {queryInProgressCF ? showButtonDisabled : showButtonEnabled}
            </Tooltip>
          </div>
        </Zoom>

        <Dialog
          fullScreen
          open={openDialog}
          onClose={this.handleClickCloseDialog}
          aria-labelledby="form-dialog-title"
        >
          <AppBar position="static" style={{ background: "#0071BC" }}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={this.handleClickCloseDialog}
                aria-label="close"
                className={classes.menuButton}
              >
                <CloseIcon />
              </IconButton>
              <Typography variant="h6" className={classes.title}>
                Profil pracownika
              </Typography>
            </Toolbar>
          </AppBar>
          <DialogContent style={{ background: "#f5f5f5" }}>
            <div className={classes.section}>
              <Box m={2}>
                <Grid container justify="flex-start" spacing={2}>
                  <NameCardE />
                  <ProfileCardE />
                  <BankingCardE />
                </Grid>
              </Box>
            </div>
          </DialogContent>
        </Dialog>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  usersFull: state.common.usersFull,
  userFull: state.common.userFull,
  queryInProgressCF: state.common.queryInProgressCF,
});

export default connect(
  mapStateToProps,
  { getUsersFullList, getUserFullDetails, clearUserFullDetails, getActiveInventory }
)(withStyles(styles, { withTheme: true })(EmployeesDetail));
