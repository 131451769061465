import {
  GET_EMPLOYEE_DETAILS,
  GET_MYMANAGER_DETAILS,
  GET_MYMANAGER_DETAILS_V2,
  GET_MYMANAGER_LIST,
  GET_EMPLOYEE_SECURE_DETAILS,
  PATCH_EMPLOYEE,
  SEND_NOTIFICATION,
} from "../actions/types";

const initialState = {
  employeeSecureDetails: {},
  employeeDetails: {},
  myManagerDetails: {},
  myManagerDetailsV2: {},
  myManagerList: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_EMPLOYEE_DETAILS:
      return {
        ...state,
        employeeDetails: action.payload
      };
    case GET_EMPLOYEE_SECURE_DETAILS:
      return {
        ...state,
        employeeSecureDetails: action.payload
      };
    case GET_MYMANAGER_LIST:
      return {
        ...state,
        myManagerList: action.payload
      };
    case GET_MYMANAGER_DETAILS:
      return {
        ...state,
        myManagerDetails: action.payload
      };
    case GET_MYMANAGER_DETAILS_V2:
      return {
        ...state,
        myManagerDetailsV2: action.payload
      };
    case PATCH_EMPLOYEE:
      return {
        ...state,
        employeeSecureDetails: action.payload
      };
    case SEND_NOTIFICATION:
      return {
        ...state,
      };
    default:
      return state;
  }
}
