import axios from "axios";
import { createMessages, returnErrors } from "./messages";
import { tokenConfig } from "./auth";
import { serverurl, getDatabaseUrl } from "./databaseServerURL";

import {
  GET_REMOTE,
  GET_MY_REMOTE,
  DELETE_REMOTE,
  ADD_REMOTE,
  PATCH_REMOTE,
  LOG_QUERY_FAILED,
  MY_LOG_QUERY_FAILED,
  LOG_QUERY_IN_PROGRESS,
  MY_LOG_QUERY_IN_PROGRESS,
  CLEAR_REMOTE_DETAILS,
  CLEAR_REMOTE,
} from "./types";

//GET REMOTE
export const getRemote = () => (dispatch, getState) => {
  dispatch({ type: LOG_QUERY_IN_PROGRESS });
  axios
    .get(serverurl + "/remote/?log=1", tokenConfig(getState))
    .then(res => {
      dispatch({
        type: GET_REMOTE,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch({ type: LOG_QUERY_FAILED })
    );
};

//GET MY REMOTE
export const getMyRemote = (employee) => (dispatch, getState) => {
  dispatch({ type: MY_LOG_QUERY_IN_PROGRESS });
  axios
    .get(serverurl + "/remote/?employee=" + employee, tokenConfig(getState))
    .then(res => {
      dispatch({
        type: GET_MY_REMOTE,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch({ type: MY_LOG_QUERY_FAILED })
    );
};

//DELETE REMOTE
export const deleteRemote = (id) => (dispatch, getState) => {
  axios
    .delete(serverurl + `/remote/${id}`, tokenConfig(getState))
    .then(res => {
      dispatch(createMessages({ deleteRemote: "Wpis został usunięty" }));
      dispatch({
        type: DELETE_REMOTE,
        payload: id
      });
    })
    .catch(err =>
      dispatch(returnErrors(err.response.data.detail, err.response.status))
    );
};

//ADD REMOTE
export const addRemote = (body) => (dispatch, getState) => {
  axios
    .post(serverurl + "/remote-raw/", body, tokenConfig(getState))
    .then(res => {
      dispatch(createMessages({ startRemote: "Rozpoczęto pracę" }));
      dispatch({
        type: ADD_REMOTE,
        payload: res.data
      });
    })
    .catch(err => dispatch({ type: MY_LOG_QUERY_FAILED }));
};


//PATCH REMOTE
export const patchRemote = (body, pk, employee) => (dispatch, getState) => {
  axios
    .patch(serverurl + `/remote-raw/${pk}`, body, tokenConfig(getState))
    .then((res) => {
      dispatch(
        createMessages({ stopRemote: "Zakończono pracę" })
      );
      dispatch({
        type: PATCH_REMOTE,
        payload: res.data,
      });
      dispatch({ type: MY_LOG_QUERY_IN_PROGRESS });
      axios
        .get(serverurl + "/remote/?employee=" + employee, tokenConfig(getState))
        .then(res => {
          dispatch({
            type: GET_MY_REMOTE,
            payload: res.data
          });
        })
        .catch(err =>
          dispatch({ type: MY_LOG_QUERY_FAILED })
        );
    })
    .catch((err) =>
      dispatch(returnErrors(err.response.data.detail, err.response.status))
    );
};

//CLEAR REMOTE
export const clearRemoteDetails = () => (dispatch) => {
  dispatch({ type: CLEAR_REMOTE_DETAILS });
};

//CLEAR REMOTE
export const clearRemote = () => (dispatch) => {
  dispatch({ type: CLEAR_REMOTE });
};