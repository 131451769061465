import React, { Component, Fragment } from "react";
import { getMyRemote, clearRemoteDetails, addRemote, patchRemote } from "../../Redux/actions/remote";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import { createMuiTheme } from "@material-ui/core";
import { lighten } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";

import MonthView from "./MonthView";

import Fab from "@material-ui/core/Fab";
import Zoom from "@material-ui/core/Zoom";
import Tooltip from "@material-ui/core/Tooltip";
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import StopIcon from '@material-ui/icons/Stop';

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Avatar from "@material-ui/core/Avatar";
import CircularProgress from "@material-ui/core/CircularProgress";

import Grid from "@material-ui/core/Grid";
import Card from "../../components/Card/Card.js";
import CardBody from "../../components/Card/CardBody.js";
import CardHeader from "@material-ui/core/CardHeader";

import { NothingToDisplay, WaitToDisplay } from "../Common/infoData.js";
import { calcTime, onlyToday } from "../Common/calcTime.js";
import { returnErrors } from "../../Redux/actions/messages";

const materialTheme = createMuiTheme({
  palette: {
    primary: {
      main: "#0071BC",
    },
  },
  overrides: {
    MuiChip: {
      root: {
        color: "#ffffff",
        "& $avatar": {
          color: "#ffffff",
        },
      },
      icon: {
        color: "#ffffff",
      },
    },
    MuiCardActions: {
      root: {
        background: "#aaaaaa",
      },
    },
    MuiIconButton: {
      root: {
        color: "#fff",
      },
    },
  },
});

const styles = (theme) => ({
  root: {
    width: "100%",
  },
  section: {
    backgroundPosition: "50%",
    backgroundSize: "cover",
    padding: 0,
    marginTop: "30px",
  },
  cardsGroup: {
    textAlign: "left",
    color: "#444444",
    fontFamily: "Lato, Open Sans, sans-serif",
    fontSize: "1.2em",
    fontWeight: "700",
    marginTop: "30px",
    marginBottom: "10px",
  },
  Name: {
    textAlign: "left",
    fontFamily: "Lato, Open Sans, sans-serif",
    fontSize: "1em",
    fontWeight: "700",
    color: "#444444",
  },
  Name2: {
    textAlign: "left",
    fontFamily: "Lato, Open Sans, sans-serif",
    fontSize: "1.2em",
    fontWeight: "700",
    color: "#444444",
  },
  Position: {
    textAlign: "left",
    fontFamily: "Lato, Open Sans, sans-serif",
    fontSize: "1em",
    fontWeight: "400",
    color: "#aaaaaa",
  },
  header: {
    textAlign: "left",
    backgroundColor: lighten("#338dc9", 0.85),
  },
  avatar: {
    backgroundColor: "#0071bc",
  },
  headertitle: {
    fontFamily: "Lato, Open Sans, sans-serif",
    fontSize: "1.2em",
    fontWeight: "700",
    color: "#444444",
  },
  info: {
    fontFamily: "Lato, Open Sans, sans-serif",
    fontSize: "1.5em",
    fontWeight: "300",
    color: "#0071BC",
  },
  inline: {
    display: "inline",
  },
  button: {
    width: '100%',
    height: '50vh',
    fontSize: '3em',
  },
  alarm: {
    padding: '0 0 0 10px',
    fontSize: '1em',
  },
  fb5: {
    position: "fixed",
    bottom: theme.spacing(8),
    right: theme.spacing(5),
    zIndex: "100",
  },
  fb6: {
    position: "fixed",
    bottom: theme.spacing(14),
    right: theme.spacing(5),
    zIndex: "100",
  },
  fabgreen: {
    color: theme.palette.common.white,
    backgroundColor: "#4caf50",
    "&:hover": {
      backgroundColor: green[600],
    },
  },
  fabred: {
    color: theme.palette.common.white,
    backgroundColor: "#dc004e",
    "&:hover": {
      backgroundColor: "#9a0036",
    },
  },
});

export class MyRemoteView extends Component {
  static propTypes = {
    auth: PropTypes.object.isRequired,
    remoteMyLog: PropTypes.array.isRequired,
    getMyRemote: PropTypes.func.isRequired,
    addRemote: PropTypes.func.isRequired,
    patchRemote: PropTypes.func.isRequired,
    returnErrors: PropTypes.func.isRequired,
    queryInProgressMyLOG: PropTypes.bool.isRequired,
  };

  componentDidMount() {
    this.props.getMyRemote(this.props.auth.user.id);
  }

  componentWillUnmount() {
    this.props.clearRemoteDetails();
  }

  getLocationStart = async () => {
    await navigator.geolocation.getCurrentPosition(
      position => this.handleStart(position),
      err => this.props.returnErrors('Lokalizacja niemożliwa', 'Lk:' + err.PERMISSION_DENIED),
    );
  }

  handleStart = (position) => {
    const employee = this.props.auth.user.id;
    const logStart = new Date();
    const longitude = position.coords.longitude;
    const latitude = position.coords.latitude;
    const status = 'START';

    //console.log('https://maps.google.com/?q=' + latitude + ',' + longitude);

    const body = {
      employee,
      logStart,
      longitude,
      latitude,
      status
    }

    this.props.addRemote(body);
  }

  handleStop = () => {
    if (this.props.remoteMyLog !== undefined) {
      if (!this.props.queryInProgressMyLOG) {
        const last = this.props.remoteMyLog["0"];
        const employee = this.props.auth.user.id;
        const logStop = new Date();
        const status = 'STOP';

        const body = {
          logStop,
          status
        }

        this.props.patchRemote(body, last["pk"], employee);
      }
    }
  }

  render() {
    const { classes, remoteMyLog, queryInProgressMyLOG } = this.props;

    var log = null;
    var worktime = null;
    var work = null;
    var today = [];
    const queryInProgress = <CircularProgress color="secondary" />;

    const startButtonDi = (
      <span>
        <Fab
          disabled
          color="inherit"
          className={classes.fabgreen}
          size="small"
          aria-label="Rozpoczęcie niemożliwe"
        >
          <PlayArrowIcon />
        </Fab>
      </span>
    );

    const startButtonEn = (
      <Fab
        onClick={this.getLocationStart}
        color="inherit"
        className={classes.fabgreen}
        size="small"
        aria-label="Rozpoczęcie pracy"
      >
        <PlayArrowIcon />
      </Fab>
    );

    const stopButtonDi = (
      <span>
        <Fab
          disabled
          color="inherit"
          className={classes.fabred}
          size="small"
          aria-label="Zakończenie niemożliwe"
        >
          <StopIcon />
        </Fab>
      </span>
    );

    const stopButtonEn = (
      <Fab
        onClick={this.handleStop}
        color="inherit"
        className={classes.fabred}
        size="small"
        aria-label="Zakończenie pracy"
      >
        <StopIcon />
      </Fab>
    );

    if (remoteMyLog !== undefined) {
      if (!queryInProgressMyLOG) {
        var inWork = false;
        const last = remoteMyLog["0"];
        for (var key in last) {
          if (key == "status") {
            if (last[key] == "START") inWork = true;
            else inWork = false;
          }
        }
        today = onlyToday(remoteMyLog);
        worktime = calcTime(today, 0);
      }

      if (worktime != null) {
        if (worktime[0] != undefined) {
          work = (
            <span style={{ float: "right" }}>Czas pracy: {worktime[0][1]}</span>
          );
        }
      }

      log = (
        <List component="nav" aria-label="main mailbox folders" style={{ paddingTop: "0" }}>
          <ListItem style={{ backgroundColor: "rgba(224, 237, 246, 0.4)" }}>
            <Grid container style={{ textAlign: "center" }}>
              <Grid item xs={4} sm={4} md={4} lg={4}>
                <Typography component="span" className={`${classes.inline} ${classes.Name2}`}>
                  Status
                </Typography>
              </Grid>
              <Grid item xs={4} sm={4} md={4} lg={4}>
                <Typography component="span" className={`${classes.inline} ${classes.Name2}`}>
                  Godzina rozpoczęcia
                </Typography>
              </Grid>
              <Grid item xs={4} sm={4} md={4} lg={4}>
                <Typography component="span" className={`${classes.inline} ${classes.Name2}`}>
                  Godzina zakończenia
                </Typography>
              </Grid>
            </Grid>
          </ListItem>
          {
            today.length === 0 && !queryInProgressMyLOG
              ? <NothingToDisplay classes={classes.info} />
              : ""
          }
          {
            today.length === 0 && queryInProgressMyLOG
              ? <WaitToDisplay classes={classes.info} />
              : ""
          }
          {
            today.map((singlelog) => (
              <ListItem key={singlelog.pk} button>
                <Grid container style={{ textAlign: "center" }}>
                  <Grid item xs={4} sm={4} md={4} lg={4}>
                    <Typography component="span" className={`${classes.inline} ${classes.Name}`}>
                      <span className={singlelog.ctrl != '' ? classes.red : ''}>{singlelog.status == 'START' ? 'Aktywny' : 'Nieaktywny'}</span>
                    </Typography>
                  </Grid>
                  <Grid item xs={4} sm={4} md={4} lg={4}>
                    <Typography component="span" className={`${classes.inline} ${classes.Name}`}>
                      <span className={singlelog.ctrl != '' ? classes.red : ''}>{new Date(singlelog.logStart).toLocaleString()}</span>
                    </Typography>
                  </Grid>
                  <Grid item xs={4} sm={4} md={4} lg={4}>
                    <Typography component="span" className={`${classes.inline} ${classes.Name}`}>
                      <span className={singlelog.ctrl != '' ? classes.red : ''}>{singlelog.logStop ? new Date(singlelog.logStop).toLocaleString() : 'Nie zakończono'}</span>
                    </Typography>
                  </Grid>
                </Grid>
              </ListItem>
            ))
          }
        </List>
      );
    } else {
      log = <List className={classes.root}></List>;
      work = <span></span>;
    }

    return (
      <Fragment>
        <div style={{ padding: "0px 15px" }}>
          <div className={classes.section}>
            <Typography style={{ marginBottom: "30px", marginTop: "5px" }} className={classes.cardsGroup}>
              Widok dzienny
            </Typography>
            <Grid container justify="flex-start">
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Card style={{ overflow: "auto", maxHeight: "50vh", marginTop: "0" }}>
                  <CardBody style={{ padding: 0 }}>
                    <CardHeader
                      className={classes.header}
                      avatar={
                        <Avatar aria-label="recipe" className={classes.avatar}>
                          Md
                        </Avatar>
                      }
                      action={queryInProgressMyLOG ? queryInProgress : ""}
                      title={<div className={classes.headertitle}>Mój dzień{work}</div>}
                    />
                    {log}
                  </CardBody>
                </Card>
              </Grid>
            </Grid>
          </div>
          <div className={classes.section}>
            <Typography style={{ marginBottom: "30px" }} className={classes.cardsGroup}>
              Widok miesięczny
            </Typography>
            <MonthView />
          </div>
        </div>

        <Zoom in={true}>
          <div role="presentation" className={classes.fb6}>
            <Tooltip title="Rozpocznij pracę" placement="left">
              {inWork ? startButtonDi : startButtonEn}
            </Tooltip>
          </div>
        </Zoom>
        <Zoom in={true}>
          <div role="presentation" className={classes.fb5}>
            <Tooltip title="Zakończ pracę" placement="left">
              {!inWork ? stopButtonDi : stopButtonEn}
            </Tooltip>
          </div>
        </Zoom>
      </Fragment >
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  remoteMyLog: state.remote.remoteMyLog,
  queryInProgressMyLOG: state.remote.queryInProgressMyLOG,
});

export default connect(
  mapStateToProps,
  { getMyRemote, clearRemoteDetails, addRemote, patchRemote, returnErrors }
)(withStyles(styles, { withTheme: true })(MyRemoteView));
