import React, { Component, Fragment } from 'react';
import { connect } from "react-redux";
import PropTypes from 'prop-types';
import ReadOnly from '../Trips-manager/ReadOnly.js';
import CommentRO from "../Trips-manager/CommentRO";
import TimestampRO from "../Trips-manager/TimestampRO";
import Card from "../components/Card/Card.js";
import CardBody from "../components/Card/CardBody.js";
import CardHeader from "../components/Card/CardHeader.js";
import White from "components/Typography/White.js";
import Container from "@material-ui/core/Container";
import Chip from "@material-ui/core/Chip";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
    withStyles
} from "@material-ui/core/styles";

const styles = theme => ({
    root: {
        width: '100%',
        maxWidth: "600px",
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    chipNW: {
        background: "#1bab7f",
        color: "#ffffff"
    },
    chipCA: {
        background: "#c2a33a",
        color: "#ffffff"
    },
    chipZA: {
        background: "#0071BC",
        color: "#ffffff"
    },
    chipRZ: {
        background: "#b35340",
        color: "#ffffff"
    },
    chipZM: {
        background: "#aaaaaa",
        color: "#ffffff"
    },
});

export class AccountingPreview extends Component {

    static propTypes = {
        tripsManagerReducer: PropTypes.object.isRequired
    };

    render() {
        const { classes, isChecked, tripIdentifierpk } = this.props;
        const { queryInProgress, employeeTripDetails } = this.props.tripsManagerReducer;

        var styleCSS;
        if (employeeTripDetails.tripStatus === "NW") {
            styleCSS = classes.chipNW;
        } else if (employeeTripDetails.tripStatus === "CA") {
            styleCSS = classes.chipCA;
        } else if (employeeTripDetails.tripStatus === "ZA") {
            styleCSS = classes.chipZA;
        } else if (employeeTripDetails.tripStatus === "RZ") {
            styleCSS = classes.chipRZ;
        } else {
            styleCSS = classes.chipZM;
        }

        var showContent;
        if (employeeTripDetails.pk === tripIdentifierpk) {
            showContent = true;
        } else {
            showContent = false;
        }

        const info = (
            <Fragment>
                <h2 style={{ height: "500px" }}>Wybierz delegację z tabeli i kliknij na przycisk <b>Podgląd</b></h2>
            </Fragment>
        );

        const tripContent = (
            <Fragment>
                <Container maxWidth="sm">
                    <h1>
                        szczegóły delegacji Nr:{" "}
                        {employeeTripDetails.tripIdentifier}
                    </h1>
                </Container>
                <div style={{ marginBottom: 30 }}></div>
                <Chip
                    className={styleCSS}
                    label={employeeTripDetails.tripStatusDescription}
                />
                <ReadOnly
                    tripDetails={employeeTripDetails}
                />
                <CommentRO tripDetails={employeeTripDetails} />
                <TimestampRO tripDetails={employeeTripDetails} />
                <div style={{ marginBottom: 60 }}></div>
            </Fragment>
        )

        const query = (
            <CircularProgress className={classes.progress} color="secondary" />
        );

        return (
            <div className={classes.root}>
                <Card>
                    <CardHeader style={{ width: "200px", height: "50px" }} color="primary"><h3 style={{ margin: "0", padding: "0" }}><White>Podgląd delegacji</White></h3></CardHeader>
                    <CardBody>
                        {queryInProgress ? query : ""}
                        {!isChecked || !showContent ? info : ""}
                        {!queryInProgress && isChecked && showContent ? tripContent : ""}
                    </CardBody>
                </Card>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    tripsManagerReducer: state.tripsManagerReducer
});

export default connect(
    mapStateToProps,
    {}
)(withStyles(styles, { withTheme: true })(AccountingPreview));
