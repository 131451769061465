import React, { Component } from "react";
// @material-ui/core components
import Radio from "@material-ui/core/Radio";
import FormControlLabel from "@material-ui/core/FormControlLabel";
// @material-ui/icons
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";
// core components
import styles from "../../assets/jss/material-kit-pro-react/customCheckboxRadioSwitchStyle.js";
import Grid from "@material-ui/core/Grid";
import Card from "../../components/Card/Card.js";
import CardBody from "../../components/Card/CardBody.js";
import Typography from "@material-ui/core/Typography";
import { ThemeProvider } from "@material-ui/styles";
import { createMuiTheme } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CircularProgress from "@material-ui/core/CircularProgress";
import { connect } from "react-redux";
import {
  getUsersList,
  getUserDetails,
  clearUserDetails,
} from "../../Redux/actions/common";
import { clearEmployeeAssetsState } from "../../Redux/actions/assets";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import auth from "../../Redux/reducers/auth.js";

const CssTextField = withStyles({
  root: {
    width: 280,
    flexWrap: "wrap",
    marginBottom: "50px",

    "& label.Mui-focused": {
      color: "#0071bc",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#0071bc",
    },
  },
})(TextField);

const materialTheme = createMuiTheme({
  overrides: {
    MuiTextField: {
      root: {
        width: 280,
      },
    },
  },
});

export class Navigation extends Component {
  state = {
    selectedEnabled: "al",
    open: false,
    newEmployeeSelected: null,
  };

  static propTypes = {
    usersList: PropTypes.array.isRequired,
    queryInProgress: PropTypes.bool.isRequired,
    getUserDetails: PropTypes.func.isRequired,
    clearUserDetails: PropTypes.func.isRequired,
    clearEmployeeAssetsState: PropTypes.func.isRequired,
    userDetails: PropTypes.object.isRequired,
    auth: PropTypes.any.isRequired,
  };

  componentWillReceiveProps(nextProps) {
    //wykryj czy utworzono nowy equipment transfer log
    if (
      nextProps.userDetails.pk !== undefined &&
      nextProps.userDetails.pk !== this.props.userDetails.pk
    ) {
      this.setState({
        newEmployeeSelected: nextProps.userDetails,
      });
    }
  }

  getSelected = (event, value) => {
    //console.log("getSelected", value);
    this.setState({
      newEmployeeSelected: value,
    });
    if (value !== null) {
      this.props.getUserDetails(value.id, "s");
    } else {
      this.props.clearUserDetails("s");
      this.props.clearEmployeeAssetsState();
      this.props.clearEmployeeFilter();
    }
  };

  setSelectedEnabled = (category) => {
    //console.log("getSelectedEnabled");
    this.setState({
      selectedEnabled: category,
    });
    this.props.eqTypeSelected(category);
  };

  setOpen = () => {
    //console.log("setOpen");
    const open = this.state.open;
    if (open === false && this.props.usersList.length === 0) {
      this.props.getUsersList();
    }
    this.setState({
      open: !open,
    });
  };

  render() {
    //const { classes } = this.props;
    const { selectedEnabled, open, newEmployeeSelected } = this.state;
    const { classes, usersList } = this.props;

    const loading = open && usersList.length === 0;

    return (
      <div>
        <Grid container justify="space-between">
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Card style={{ overflow: "auto", marginTop: 0, marginBottom: 0 }}>
              <CardBody>
                <Typography
                  style={{
                    marginBottom: "50px",
                    color: "#888888",
                    textAlign: "left",
                    fontFamily: "Lato, Open Sans, sans-serif",
                    textTransform: "uppercase",
                    fontSize: "1em",
                    fontWeight: "300",
                  }}
                >
                  Wyszukaj pracownika, aby wyświetlić jego środki trwałe,
                  następnie zaznacz wybraną grupę środków trwałych...
                </Typography>
                <Typography
                  style={{
                    textAlign: "left",
                    color: "#444444",
                    fontFamily: "Lato, Open Sans, sans-serif",
                    fontSize: "1.4em",
                    fontWeight: "700",
                  }}
                >
                  Pracownik
                </Typography>
                <ThemeProvider theme={materialTheme}>
                  <Autocomplete
                    value={newEmployeeSelected}
                    id="asynchronous-demo"
                    style={{ width: "300px" }}
                    open={open}
                    onOpen={this.setOpen}
                    onClose={this.setOpen}
                    onChange={this.getSelected}
                    getOptionSelected={(option, value) =>
                      option.last_name === value.last_name &&
                      option.first_name === value.first_name
                    }
                    getOptionLabel={(option) =>
                      option.first_name + " " + option.last_name
                    }
                    options={usersList}
                    loading={loading}
                    renderInput={(params) => (
                      <CssTextField
                        {...params}
                        label="wybierz z listy..."
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <React.Fragment>
                              {loading ? (
                                <CircularProgress color="inherit" size={20} />
                              ) : null}
                              {params.InputProps.endAdornment}
                            </React.Fragment>
                          ),
                        }}
                      />
                    )}
                  />
                </ThemeProvider>
                <Grid container style={{ textAlign: "left" }}>
                  <Grid item xs={11} sm={4} md={2}>
                    <FormControlLabel
                      control={
                        <Radio
                          checked={selectedEnabled === "al"}
                          onChange={() => this.setSelectedEnabled("al")}
                          value="al"
                          name="radio button enabled"
                          aria-label="A"
                          icon={
                            <FiberManualRecord
                              className={classes.radioUnchecked}
                            />
                          }
                          checkedIcon={
                            <FiberManualRecord
                              className={classes.radioChecked}
                            />
                          }
                          classes={{
                            checked: classes.radio,
                            root: classes.radioRoot,
                          }}
                        />
                      }
                      classes={{
                        label: classes.label,
                      }}
                      label="BHP"
                    />
                  </Grid>
                  <Grid item xs={11} sm={4} md={2}>
                    <FormControlLabel
                      control={
                        <Radio
                          checked={selectedEnabled === "na"}
                          onChange={() => this.setSelectedEnabled("na")}
                          value="na"
                          name="radio button enabled"
                          aria-label="A"
                          icon={
                            <FiberManualRecord
                              className={classes.radioUnchecked}
                            />
                          }
                          checkedIcon={
                            <FiberManualRecord
                              className={classes.radioChecked}
                            />
                          }
                          classes={{
                            checked: classes.radio,
                            root: classes.radioRoot,
                          }}
                        />
                      }
                      classes={{
                        label: classes.label,
                      }}
                      label="Narzędzia"
                    />
                  </Grid>
                  <Grid item xs={11} sm={4} md={2}>
                    <FormControlLabel
                      control={
                        <Radio
                          checked={selectedEnabled === "el"}
                          onChange={() => this.setSelectedEnabled("el")}
                          value="el"
                          name="radio button enabled"
                          aria-label="A"
                          icon={
                            <FiberManualRecord
                              className={classes.radioUnchecked}
                            />
                          }
                          checkedIcon={
                            <FiberManualRecord
                              className={classes.radioChecked}
                            />
                          }
                          classes={{
                            checked: classes.radio,
                            root: classes.radioRoot,
                          }}
                        />
                      }
                      classes={{
                        label: classes.label,
                      }}
                      label="Elektro-narzędzia"
                    />
                  </Grid>
                  <Grid item xs={11} sm={4} md={2}>
                    <FormControlLabel
                      control={
                        <Radio
                          checked={selectedEnabled === "it"}
                          onChange={() => this.setSelectedEnabled("it")}
                          value="it"
                          name="radio button enabled"
                          aria-label="A"
                          icon={
                            <FiberManualRecord
                              className={classes.radioUnchecked}
                            />
                          }
                          checkedIcon={
                            <FiberManualRecord
                              className={classes.radioChecked}
                            />
                          }
                          classes={{
                            checked: classes.radio,
                            root: classes.radioRoot,
                          }}
                        />
                      }
                      classes={{
                        label: classes.label,
                      }}
                      label="IT"
                    />
                  </Grid>
                  <Grid item xs={11} sm={4} md={2}>
                    <FormControlLabel
                      control={
                        <Radio
                          checked={selectedEnabled === "po"}
                          onChange={() => this.setSelectedEnabled("po")}
                          value="po"
                          name="radio button enabled"
                          aria-label="A"
                          icon={
                            <FiberManualRecord
                              className={classes.radioUnchecked}
                            />
                          }
                          checkedIcon={
                            <FiberManualRecord
                              className={classes.radioChecked}
                            />
                          }
                          classes={{
                            checked: classes.radio,
                            root: classes.radioRoot,
                          }}
                        />
                      }
                      classes={{
                        label: classes.label,
                      }}
                      label="Pozostałe"
                    />
                  </Grid>
                  <Grid item xs={11} sm={4} md={2}>
                    <FormControlLabel
                      control={
                        <Radio
                          checked={selectedEnabled === "ws"}
                          onChange={() => this.setSelectedEnabled("ws")}
                          value="ws"
                          name="radio button enabled"
                          aria-label="A"
                          icon={
                            <FiberManualRecord
                              className={classes.radioUnchecked}
                            />
                          }
                          checkedIcon={
                            <FiberManualRecord
                              className={classes.radioChecked}
                            />
                          }
                          classes={{
                            checked: classes.radio,
                            root: classes.radioRoot,
                          }}
                        />
                      }
                      classes={{
                        label: classes.label,
                      }}
                      label="Wszystkie"
                    />
                  </Grid>
                </Grid>
              </CardBody>
            </Card>
          </Grid>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  usersList: state.common.users,
  queryInProgress: state.common.queryInProgress,
  userDetails: state.common.suser,
  auth: state.auth,
});

export default connect(
  mapStateToProps,
  { getUsersList, getUserDetails, clearUserDetails, clearEmployeeAssetsState }
)(withStyles(styles, { withTheme: true })(Navigation));
