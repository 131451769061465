import React, { Fragment } from "react";
import { withStyles } from "@material-ui/core/styles";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import FormHelperText from "@material-ui/core/FormHelperText";

const PurpleSwitch = withStyles({
  switchBase: {
    color: "#0071bc",
    "&$checked": {
      color: "#0071bc",
    },
    "&$checked + $track": {
      backgroundColor: "#0071bc",
    },
  },
  checked: {},
  track: {},
})(Switch);

export function RangeSlider(props) {
  const today = new Date();

  const handleChange = (event) => {
    props.thisYearFilter(!props.thisYear);
    //setState({ ...state, [event.target.name]: event.target.checked });
  };

  const switchen = (
    <PurpleSwitch
      //disabled
      checked={props.thisYear}
      onChange={handleChange}
      name="thisYear"
    />
  );

  const switchdi = (
    <PurpleSwitch disabled checked={props.thisYear} name="thisYear" />
  );

  return (
    <Fragment>
      <FormGroup>
        <FormControlLabel
          control={props.isActive ? switchen : switchdi}
          label={props.thisYear ? today.getFullYear() : today.getFullYear() - 1}
        />
      </FormGroup>
      <FormHelperText style={{ marginBottom: "30px" }}>
        {props.thisYear
          ? "przełącz, aby wyświetlić dane z ub. roku"
          : "przełącz, aby wyświetlić dane z tego roku"}
      </FormHelperText>
    </Fragment>
  );
}

export function RangeSliderUnclosed(props) {
  const handleChange = (event) => {
    props.unclosedFilter(!props.unclosed);
    //setState({ ...state, [event.target.name]: event.target.checked });
  };

  const switchen = (
    <PurpleSwitch
      //disabled
      checked={props.unclosed}
      onChange={handleChange}
      name="unclosed"
    />
  );

  const switchdi = (
    <PurpleSwitch disabled checked={props.unclosed} name="unclosed" />
  );

  return (
    <Fragment>
      <FormGroup>
        <FormControlLabel
          control={props.isActive ? switchen : switchdi}
          label={props.unclosed ? "Nierozliczone" : "Wszystkie"}
        />
      </FormGroup>
      <FormHelperText style={{ marginBottom: "30px" }}>
        {props.unclosed
          ? "przełącz, aby wyświetlić wszystkie transakcje"
          : "przełącz, aby wyświetlić nierozliczone transakcje"}
      </FormHelperText>
    </Fragment>
  );
}
