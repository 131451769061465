import React, { Component, Fragment } from "react";
import Grid from "@material-ui/core/Grid";
import Card from "../../components/Card/Card.js";
import CardBody from "../../components/Card/CardBody.js";
import Avatar from "@material-ui/core/Avatar";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Typography from "@material-ui/core/Typography";
import CardHeader from "@material-ui/core/CardHeader";
import { listStyles } from "../../common/radiolineStyles";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import IconButton from "@material-ui/core/IconButton";
import CircularProgress from "@material-ui/core/CircularProgress";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import returnIconW from "../../graphics/returnIconW.svg";
import returnIconAA from "../../graphics/returnIconAA.svg";

var rtap = <img style={{ height: "26px" }} src={returnIconW} alt="rtap"></img>;
var rtpc = <img style={{ height: "26px" }} src={returnIconAA} alt="rtpc"></img>;

export class ListReturn extends Component {
  state = {
    selectedIndex: null,
    transactionSelected: null,
  };

  static propTypes = {
    queryInProgress_rt: PropTypes.bool.isRequired,
    transactions_rt: PropTypes.array.isRequired,
  };

  componentDidUpdate(prevProps) {
    if (
      prevProps.isTransactionChecked === true &&
      this.props.isTransactionChecked === false &&
      this.state.selectedIndex !== null
    ) {
      this.setState({
        selectedIndex: null,
        transactionSelected: null,
      });
    }

    if (
      prevProps.activeList === "return" &&
      this.props.activeList !== "return"
    ) {
      this.setState({
        selectedIndex: null,
        transactionSelected: null,
      });
    }
  }

  handleListItemClick = (event, index, pk) => {
    const selectedIndex = this.state.selectedIndex;
    if (selectedIndex !== index) {
      this.setState({
        selectedIndex: index,
        transactionSelected: pk,
      });
      this.props.checked(true, pk, "return");
    } else {
      this.setState({
        selectedIndex: null,
        transactionSelected: null,
      });
      this.props.checked(false, null, null);
    }
  };

  render() {
    const { classes, queryInProgress_rt, transactions_rt } = this.props;

    const { selectedIndex } = this.state;

    const circle = (
      <IconButton aria-label="settings">
        <CircularProgress color="secondary" />
      </IconButton>
    );

    var nothingToDisplay = (
      <div className={classes.infoListFetch}>
        Brak transakcji w tej kategorii ...
      </div>
    );

    const waitToDisplay = (
      <div className={classes.infoListFetch}>
        Czekaj, pobieram dane z serwera ...
      </div>
    );

    var lista = "";

    if (transactions_rt.length > 0) {
      lista = (
        <Fragment>
          {transactions_rt.map((transaction, index) => (
            <ListItem
              key={transaction.pk}
              button
              selected={selectedIndex === index}
              onClick={(event) =>
                this.handleListItemClick(event, index, transaction.pk)
              }
            >
              <ListItemAvatar>
                <Avatar src={transaction.owner_details.profileImage}>
                  {transaction.owner_details.first_name.substr(0, 1) +
                    transaction.owner_details.last_name.substr(0, 1)}
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={transaction.transactionDescription}
                secondary={
                  <Fragment>
                    <Typography
                      className={classes.listTextSecondaryInline}
                      component="span"
                    >
                      {eval(
                        transaction.transactionType +
                        transaction.transactionStatus
                      )}
                    </Typography>
                    <Typography
                      className={classes.listTextSecondaryInline}
                      component="span"
                    >
                      {"  |  " + transaction.bookDate}
                    </Typography>
                  </Fragment>
                }
              />
              <ListItemSecondaryAction>
                <Typography>
                  {transaction.value + " " + transaction.currency_description}
                </Typography>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </Fragment>
      );
    }

    return (
      <Card
        style={{
          marginTop: 0,
          marginBottom: 0,
          maxHeight: "500px",
        }}
      >
        <CardHeader
          className={classes.headerInfo1}
          avatar={
            <Avatar aria-label="recipe" className={classes.avatarInfo1}>
              ZK
            </Avatar>
          }
          action={queryInProgress_rt ? circle : ""}
          title={<div className={classes.headertitle}>Zwroty do Kasy</div>}
        />
        <CardBody
          style={{
            padding: 0,
            //height: "calc(100vh - 220px)",
            overflow: "auto",
          }}
        >
          <List component="nav" aria-label="main mailbox folders">
            {queryInProgress_rt ? waitToDisplay : ""}
            {!queryInProgress_rt && transactions_rt.length > 0 ? lista : ""}
            {!queryInProgress_rt && transactions_rt.length === 0
              ? nothingToDisplay
              : ""}
          </List>
        </CardBody>
      </Card>
    );
  }
}

const mapStateToProps = (state) => ({
  queryInProgress_rt: state.accounting.queryInProgress_rt,
  transactions_rt: state.accounting.transactions_rt,
});

export default connect(
  mapStateToProps,
  {}
)(withStyles(listStyles, { withTheme: true })(ListReturn));
