import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";

import Button from "@material-ui/core/Button";
import Chip from "@material-ui/core/Chip";
import { withStyles } from "@material-ui/core/styles";

import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  getEmployeeTripDetails,
  clearEmployeeTripDetails,
  patchEmployeeTrip,
  addTripComment
} from "../Redux/actions/trips-manager";
import {
  sendNotification,
  sendNewNotification
} from "../Redux/actions/employee";
import { getEmployeeSecureDetails } from "../Redux/actions/employee";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Fab from "@material-ui/core/Fab";
import DoneIcon from "@material-ui/icons/Done";
import DoneAllIcon from "@material-ui/icons/DoneAll";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";



import SaveIcon from "@material-ui/icons/Save";
import Comment from "../Trips-manager/Comment";
import Timestamp from "../Trips-manager/Timestamp";

import ReadOnly from "./ReadOnly";

const styles = theme => ({
  appBar: {
    top: "auto",
    bottom: 0,
    background: "#0071BC"
  },
  chipNW: {
    background: "#1bab7f",
    color: "#ffffff"
  },
  chipCA: {
    background: "#c2a33a",
    color: "#ffffff"
  },
  chipZA: {
    background: "#0071BC",
    color: "#ffffff"
  },
  chipRZ: {
    background: "#b35340",
    color: "#ffffff"
  },
  chipZM: {
    background: "#aaaaaa",
    color: "#ffffff"
  },
  grow: {
    flexGrow: 1
  },
  fabButton: {
    position: "absolute",
    zIndex: 1,
    top: -30,
    left: 0,
    right: 0,
    margin: "0 auto"
  }
});

export class TripsManagerViewerDetails extends Component {
  state = {
    anchorEl: null,
    tripId: null,
    dialogAccept: false,
    dialogReject: false,
    dialogAddComment: false,
    dialogClose: false,
    remarks: "",
    endDate: "",
    editFlag: false,
    saveFlag: false
  };

  static propTypes = {
    auth: PropTypes.object.isRequired,
    employeeTripDetails: PropTypes.object.isRequired,
    getEmployeeTripDetails: PropTypes.func.isRequired,
    clearEmployeeTripDetails: PropTypes.func.isRequired,
    patchEmployeeTrip: PropTypes.func.isRequired,
    addTripComment: PropTypes.func.isRequired,
    getEmployeeSecureDetails: PropTypes.func.isRequired,
    employeeSecureDetails: PropTypes.object.isRequired,
    sendNotification: PropTypes.func.isRequired,
    sendNewNotification: PropTypes.func.isRequired,
  };

  componentWillUnmount() {
    this.props.clearEmployeeTripDetails();
  }

  componentDidMount() {
    this.props.getEmployeeTripDetails(this.props.match.params.pk);
    this.props.getEmployeeSecureDetails(this.props.auth.user.id);
  }

  handleClick = event => {
    this.setState({
      anchorEl: event.currentTarget
    });
  };

  handleCloseMenu = () => {
    this.setState({
      anchorEl: null
    });
  };

  handleCloseDialogAccept = () => {
    this.setState({
      dialogAccept: false,
      tripId: null
    });
  };

  handleCloseDialogClose = () => {
    this.setState({
      dialogClose: false,
      tripId: null
    });
  };

  handleCloseDialogReject = () => {
    this.setState({
      dialogReject: false,
      tripId: null
    });
  };

  handleCloseDialogAddComment = () => {
    this.setState({
      dialogAddComment: false,
      tripId: null,
      remarks: ""
    });
  };

  handleAcceptTrip = () => {
    const tripId = this.state.tripId;
    const message = "delegacja została zaakceptowana";
    //przygotowanie body
    const tripBody = {
      tripStatus: "ZA"
    };
    this.props.patchEmployeeTrip(tripBody, tripId, message);

    //wysyłanie SMSa
    /*
    const recipient = this.props.employeeTripDetails.employee.phone;
    const sender = "DELEGACJE";
    const smsmessage = "Delegacja o numerze " + this.props.employeeTripDetails.tripIdentifier + " zostala ZAAKCEPTOWANA przez twojego przelozonego!";
    const owner = this.props.auth.user.id;
    const notificationType = "sms"
    const isDelivered = true;
    //przygotowanie body
    const body = {
      recipient,
      sender,
      message: smsmessage,
      owner,
      notificationType,
      isDelivered
    };
    this.props.sendNotification(body);
    */

    const notifyBody = {
      recipient: this.props.employeeTripDetails.employee.email,
      module: "Delegacje",
      topic: "Delegacja zaakceptowana",
      message:
        "Delegacja o numerze " + this.props.employeeTripDetails.tripIdentifier + " została zaakceptowana przez twojego przełożonego."
    }
    this.props.sendNewNotification(notifyBody);

    this.setState({
      dialogAccept: false,
      tripId: null
    });
  };

  handleCloseTrip = () => {
    const tripId = this.state.tripId;
    const message = "delegacja została zamknięta";
    //przygotowanie body
    const tripBody = {
      tripStatus: "ZM"
    };
    this.props.patchEmployeeTrip(tripBody, tripId, message);
    this.setState({
      dialogClose: false,
      tripId: null
    });
  };

  handleRejectTrip = () => {
    const tripId = this.state.tripId;
    const message = "delegacja została odrzucona";
    //przygotowanie body
    const tripBody = {
      tripStatus: "NW"
    };
    this.props.patchEmployeeTrip(tripBody, tripId, message);

    //wysyłanie SMSa
    /*
    const recipient = this.props.employeeTripDetails.employee.phone;
    const sender = "DELEGACJE";
    const smsmessage = "Delegacja o numerze " + this.props.employeeTripDetails.tripIdentifier + " zostala ODRZUCONA przez twojego przelozonego! Skontaktuj sie ze swoim kierownikiem.";
    const owner = this.props.auth.user.id;
    const notificationType = "sms"
    const isDelivered = true;
    //przygotowanie body
    const body = {
      recipient,
      sender,
      message: smsmessage,
      owner,
      notificationType,
      isDelivered
    };
    this.props.sendNotification(body);
    */

    const notifyBody = {
      recipient: this.props.employeeTripDetails.employee.email,
      module: "Delegacje",
      topic: "Delegacja odrzucona",
      message:
        "Delegacja o numerze " + this.props.employeeTripDetails.tripIdentifier + " została odrzucona przez twojego przełożonego. Skontaktuj się ze swoim przełożonym."
    }
    this.props.sendNewNotification(notifyBody);

    this.setState({
      dialogReject: false,
      tripId: null
    });
  };

  handleChangeEndDate = endDate => {
    const tripId = this.state.tripId;
    const message = "data zakończenia została zaktualizowana";
    //przygotowanie body
    const tripBody = {
      endDate
    };
    this.props.patchEmployeeTrip(tripBody, tripId, message);
    this.setState({
      tripId: null,
      editFlag: false,
      saveFlag: false
    });
  };

  triggerAcceptTrip = tripId => {
    this.setState({
      tripId: tripId,
      dialogAccept: true
    });
  };

  triggerCloseTrip = tripId => {
    this.setState({
      tripId: tripId,
      dialogClose: true
    });
  };

  triggerRejectTrip = tripId => {
    this.setState({
      tripId: tripId,
      dialogReject: true,
      anchorEl: null
    });
  };

  triggerChangeEndDate = tripId => {
    this.setState({
      tripId: tripId,
      editFlag: true,
      anchorEl: null
    });
  };

  triggerSaveEndDate = () => {
    this.setState({
      saveFlag: true
    });
  };

  handleTextList = name => ({ target: { value } }) => {
    //console.log(name);
    this.setState({
      ...this.state,
      [name]: value
    });
  };

  render() {
    const { classes } = this.props;
    const {
      anchorEl,
      dialogAccept,
      dialogClose,
      dialogReject,
      editFlag,
      saveFlag
    } = this.state;

    var styleCSS;
    if (this.props.employeeTripDetails.tripStatus === "NW") {
      styleCSS = classes.chipNW;
    } else if (this.props.employeeTripDetails.tripStatus === "CA") {
      styleCSS = classes.chipCA;
    } else if (this.props.employeeTripDetails.tripStatus === "ZA") {
      styleCSS = classes.chipZA;
    } else if (this.props.employeeTripDetails.tripStatus === "RZ") {
      styleCSS = classes.chipRZ;
    } else {
      styleCSS = classes.chipZM;
    }

    const readOnly = (
      <ReadOnly
        editFlag={editFlag}
        tripDetails={this.props.employeeTripDetails}
        saveEndDate={this.handleChangeEndDate}
        saveTrigger={saveFlag}
      />
    );
    const chip = (
      <Chip
        className={styleCSS}
        label={this.props.employeeTripDetails.tripStatusDescription}
      />
    );

    var saveAction = (
      <Fragment>
        <Fab
          aria-label="add"
          className={classes.fabButton}
          onClick={this.triggerSaveEndDate.bind(
            this,
            this.props.employeeTripDetails.pk
          )}
        >
          <SaveIcon />
        </Fab>
      </Fragment>
    );

    var moreMenu;
    var tripFooter;
    var mainAction;
    //AppBar MoreMenu configuration and AppBar MainAction configuration
    if (this.props.employeeTripDetails.tripStatus === "NW") {
      mainAction = <Fragment></Fragment>;
      moreMenu = (
        <Fragment>
          <div className={classes.grow} />
          <IconButton color="inherit" edge="end" onClick={this.handleClick}>
            <MoreVertIcon />
          </IconButton>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={this.handleCloseMenu}
          ></Menu>
        </Fragment>
      );
      tripFooter = (
        <Fragment>
          <Comment tripDetails={this.props.employeeTripDetails} />
        </Fragment>
      );
    } else if (this.props.employeeTripDetails.tripStatus === "CA") {
      mainAction = (
        <Fragment>
          <Fab
            color="secondary"
            aria-label="add"
            className={classes.fabButton}
            onClick={this.triggerAcceptTrip.bind(
              this,
              this.props.employeeTripDetails.pk
            )}
          >
            <DoneIcon />
          </Fab>
        </Fragment>
      );
      moreMenu = (
        <Fragment>
          <div className={classes.grow} />
          <IconButton color="inherit" edge="end" onClick={this.handleClick}>
            <MoreVertIcon />
          </IconButton>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={this.handleCloseMenu}
          >
            <MenuItem
              onClick={this.triggerRejectTrip.bind(
                this,
                this.props.employeeTripDetails.pk
              )}
            >
              odrzuć
            </MenuItem>
            <MenuItem
              onClick={this.triggerChangeEndDate.bind(
                this,
                this.props.employeeTripDetails.pk
              )}
            >
              zmień datę zakończenia
            </MenuItem>
          </Menu>
        </Fragment>
      );
      tripFooter = (
        <Fragment>
          <Comment tripDetails={this.props.employeeTripDetails} />
        </Fragment>
      );
    } else if (this.props.employeeTripDetails.tripStatus === "ZA") {
      mainAction = <Fragment></Fragment>;
      moreMenu = (
        <Fragment>
          <div className={classes.grow} />
          <IconButton color="inherit" edge="end" onClick={this.handleClick}>
            <MoreVertIcon />
          </IconButton>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={this.handleCloseMenu}
          >
            <MenuItem
              onClick={this.triggerChangeEndDate.bind(
                this,
                this.props.employeeTripDetails.pk
              )}
            >
              zmień datę zakończenia
            </MenuItem>
          </Menu>
        </Fragment>
      );
      tripFooter = (
        <Fragment>
          <Comment tripDetails={this.props.employeeTripDetails} />
          <Timestamp tripDetails={this.props.employeeTripDetails} />
        </Fragment>
      );
    } else if (this.props.employeeTripDetails.tripStatus === "RZ") {
      mainAction = (
        <Fragment>
          <Fab
            color="secondary"
            aria-label="add"
            className={classes.fabButton}
            onClick={this.triggerCloseTrip.bind(
              this,
              this.props.employeeTripDetails.pk
            )}
          >
            <DoneAllIcon />
          </Fab>
        </Fragment>
      );
      moreMenu = (
        <Fragment>
          <div className={classes.grow} />
          <IconButton color="inherit" edge="end" onClick={this.handleClick}>
            <MoreVertIcon />
          </IconButton>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={this.handleCloseMenu}
          >
            <MenuItem
              onClick={this.triggerChangeEndDate.bind(
                this,
                this.props.employeeTripDetails.pk
              )}
            >
              zmień datę zakończenia
            </MenuItem>
          </Menu>
        </Fragment>
      );
      tripFooter = (
        <Fragment>
          <Comment tripDetails={this.props.employeeTripDetails} />
          <Timestamp tripDetails={this.props.employeeTripDetails} />
        </Fragment>
      );
    } else {
      mainAction = <Fragment></Fragment>;
      moreMenu = <Fragment></Fragment>;
      tripFooter = (
        <Fragment>
          <Comment tripDetails={this.props.employeeTripDetails} />
          <Timestamp tripDetails={this.props.employeeTripDetails} />
        </Fragment>
      );
    }

    return (
      <Fragment>
        <CssBaseline />
        <Container maxWidth="sm">
          <h1>
            szczegóły delegacji Nr:{" "}
            {this.props.employeeTripDetails.tripIdentifier}
          </h1>
        </Container>
        <div style={{ marginBottom: 30 }}></div>
        {chip}
        {readOnly}
        {tripFooter}
        <div style={{ marginBottom: 60 }}></div>

        <AppBar position="fixed" color="primary" className={classes.appBar}>
          <Toolbar>
            <IconButton
              color="inherit"
              edge="end"
              component={Link}
              to={"/trips/"}
            >
              <ArrowBackIcon />
            </IconButton>
            {editFlag ? saveAction : mainAction}
            {moreMenu}
          </Toolbar>
        </AppBar>

        <Dialog
          open={dialogAccept}
          onClose={this.handleCloseDialogAccept}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Zaakceptować delegację ?"}
          </DialogTitle>
          <DialogActions>
            <Button onClick={this.handleCloseDialogAccept} color="primary">
              Anuluj
            </Button>
            <Button onClick={this.handleAcceptTrip} color="primary" autoFocus>
              Zaakceptuj
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={dialogReject}
          onClose={this.handleCloseDialogReject}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Odrzucić delegację ?"}
          </DialogTitle>
          <DialogActions>
            <Button onClick={this.handleCloseDialogReject} color="primary">
              Anuluj
            </Button>
            <Button onClick={this.handleRejectTrip} color="primary" autoFocus>
              Odrzuć
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={dialogClose}
          onClose={this.handleCloseDialogClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Zamknąć delegację ?"}
          </DialogTitle>
          <DialogActions>
            <Button onClick={this.handleCloseDialogClose} color="primary">
              Anuluj
            </Button>
            <Button onClick={this.handleCloseTrip} color="primary" autoFocus>
              Zamknij
            </Button>
          </DialogActions>
        </Dialog>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  employeeTripDetails: state.tripsManagerReducer.employeeTripDetails,
  auth: state.auth,
  employeeSecureDetails: state.employee.employeeSecureDetails
});

export default connect(mapStateToProps, {
  getEmployeeTripDetails,
  clearEmployeeTripDetails,
  patchEmployeeTrip,
  addTripComment,
  getEmployeeSecureDetails,
  sendNotification,
  sendNewNotification
})(withStyles(styles, { withTheme: true })(TripsManagerViewerDetails));
