import React, { Component, Fragment } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import Card from "../../components/Card/Card.js";
import CardBody from "../../components/Card/CardBody.js";
import { Avatar } from "@material-ui/core";
import PanToolIcon from "@material-ui/icons/PanTool";
import CloseIcon from "@material-ui/icons/Close";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import CircularProgress from "@material-ui/core/CircularProgress";
import CardHeader from "@material-ui/core/CardHeader";
import CardActions from "@material-ui/core/CardActions";

const styles = (theme) => ({
  avatar: {
    backgroundColor: "#ff9800",
    height: 30,
    width: 30,
  },
  CardTitle: {
    textAlign: "left",
    fontFamily: "Lato, Open Sans, sans-serif",
    fontSize: "1em",
    fontWeight: "300",
    color: "#999999",
  },
  CardDescription: {
    textAlign: "center",
    fontFamily: "Lato, Open Sans, sans-serif",
    fontSize: "2em",
    fontWeight: "700",
    color: "#ff9800",
  },
});

export class MyAssetWaitForReturnCard extends Component {
  state = {
    activeFilter: null,
  };

  static propTypes = {
    myAssetsByStatusWr: PropTypes.array.isRequired,
    queryMyWrInProgress: PropTypes.bool.isRequired,
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.activeFilter !== this.state.activeFilter) {
      this.setState({
        activeFilter: nextProps.activeFilter,
      });
    }
  }

  handleFilterClick = () => {
    const activeFilter = this.state.activeFilter;
    if (activeFilter === "returnfilter") {
      this.props.setFilter(null);
      this.setState({
        activeFilter: null,
      });
    } else {
      this.props.setFilter("returnfilter");
      this.setState({
        activeFilter: "returnfilter",
      });
    }
  };

  render() {
    const { activeFilter } = this.state;
    const { classes, myAssetsByStatusWr, queryMyWrInProgress } = this.props;

    const arrow = (
      <IconButton aria-label="delete" onClick={this.handleFilterClick}>
        <ArrowForwardIcon />
      </IconButton>
    );
    const cancel = (
      <IconButton
        color="secondary"
        aria-label="delete"
        onClick={this.handleFilterClick}
      >
        <CloseIcon />
      </IconButton>
    );

    const circle = (
      <IconButton aria-label="settings">
        <CircularProgress color="secondary" size="1em" />
      </IconButton>
    );

    return (
      <Grid item xs={6} sm={4} md={2} lg={2}>
        <Card style={{ marginTop: 0, marginBottom: 0 }}>
          <CardHeader
            style={{ padding: "5px" }}
            avatar={
              <Avatar className={classes.avatar}>
                <PanToolIcon />
              </Avatar>
            }
            action={
              <Fragment>
                {queryMyWrInProgress ? circle : ""}
                {activeFilter !== "returnfilter" && !queryMyWrInProgress
                  ? arrow
                  : ""}
                {activeFilter === "returnfilter" ? cancel : ""}
              </Fragment>
            }
          />
          <CardBody style={{ position: "relative", padding: 0 }}>
            <Typography className={classes.CardDescription}>
              {myAssetsByStatusWr.length}
            </Typography>
          </CardBody>
          <CardActions>
            <Typography className={classes.CardTitle}>
              zwróć do magazynu
            </Typography>
          </CardActions>
        </Card>
      </Grid>
    );
  }
}

const mapStateToProps = (state) => ({
  myAssetsByStatusWr: state.assets.myAssetsByStatusWr,
  queryMyWrInProgress: state.assets.queryMyWrInProgress,
});

export default connect(
  mapStateToProps,
  {}
)(withStyles(styles, { withTheme: true })(MyAssetWaitForReturnCard));
