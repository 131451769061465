import axios from "axios";
import { returnErrors, createMessages } from "./messages";
import { tokenConfig } from "./auth";
import { serverurl, getDatabaseUrl } from "./databaseServerURL";

import {
  GET_USERS_LIST,
  GET_SUSER_DETAILS,
  CLEAR_SUSER_DETAILS,
  GET_TUSER_DETAILS,
  CLEAR_TUSER_DETAILS,
  C_QUERY_FAILED,
  C_QUERY_IN_PROGRESS,
  GET_USERS_FULL_LIST,
  GET_USERS_CONTACTS_LIST,
  GET_USER_FULL_DETAILS,
  CLEAR_USER_FULL_DETAILS,
  CF_QUERY_FAILED,
  CF_QUERY_IN_PROGRESS,
  CFC_QUERY_FAILED,
  CFC_QUERY_IN_PROGRESS,
  PATCH_PROFILE_EM,
  PATCH_SUSER_PROFILE,
} from "./types";
import { getCompany } from "./getCompany";

//GET USERS LIST

/*export const getUsersList = () => (dispatch, getState) => {
  dispatch({ type: C_QUERY_IN_PROGRESS });
  axios
    .get(serverurl + `/auth/users/`, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: GET_USERS_LIST,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch(returnErrors(err.response.data.detail, err.response.status));
      dispatch({ type: C_QUERY_FAILED });
    });
};
*/
export const getUsersList = () => (dispatch, getState) => {
  dispatch({ type: C_QUERY_IN_PROGRESS });
  axios
    .get(serverurl + getDatabaseUrl() + `/profile_list/`, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: GET_USERS_LIST,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch(returnErrors(err.response.data.detail, err.response.status));
      dispatch({ type: C_QUERY_FAILED });
    });
};

//GET USER DETAILS
export const getUserDetails = (pk, usertype) => (dispatch, getState) => {
  dispatch({ type: C_QUERY_IN_PROGRESS });
  if (usertype === "s") {
    axios
      .get(serverurl + `/auth/users/${pk}?db=${getCompany()}`, tokenConfig(getState))
      .then((res) => {
        dispatch({
          type: GET_SUSER_DETAILS,
          payload: res.data,
        });
      })
      .catch((err) => {
        dispatch(returnErrors(err.response.data.detail, err.response.status));
        dispatch({ type: C_QUERY_FAILED });
      });
  } else if (usertype === "t") {
    axios
      .get(serverurl + `/auth/users/${pk}?db=${getCompany()}`, tokenConfig(getState))
      .then((res) => {
        dispatch({
          type: GET_TUSER_DETAILS,
          payload: res.data,
        });
      })
      .catch((err) => {
        dispatch(returnErrors(err.response.data.detail, err.response.status));
        dispatch({ type: C_QUERY_FAILED });
      });
  }
};

//CLEAR USER DETAILS
export const clearUserDetails = (usertype) => (dispatch, getState) => {
  if (usertype === "s") {
    dispatch({ type: CLEAR_SUSER_DETAILS });
  } else if (usertype === "t") {
    dispatch({ type: CLEAR_TUSER_DETAILS });
  }
};

//GET USERS (ADMIN) LIST
export const getUsersFullList = () => (dispatch, getState) => {
  dispatch({ type: CF_QUERY_IN_PROGRESS });
  axios
    .get(serverurl + `/auth/users-adminv/?db=${getCompany()}`, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: GET_USERS_FULL_LIST,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch(returnErrors(err.response.data.detail, err.response.status));
      dispatch({ type: CF_QUERY_FAILED });
    });
};

//GET USER (ADMIN) DETAILS
export const getUserFullDetails = (pk) => (dispatch, getState) => {
  dispatch({ type: CF_QUERY_IN_PROGRESS });
  axios
    .get(serverurl + `/auth/users-adminv/${pk}?db=${getCompany()}`, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: GET_USER_FULL_DETAILS,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch(returnErrors(err.response.data.detail, err.response.status));
      dispatch({ type: CF_QUERY_FAILED });
    });
};

//GET USERS (CONTACTS) LIST
export const getUsersContactsList = () => (dispatch, getState) => {
  dispatch({ type: CFC_QUERY_IN_PROGRESS });
  axios
    .get(serverurl + `/auth/users-contacts/?db=${getCompany()}`, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: GET_USERS_CONTACTS_LIST,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch(returnErrors(err.response.data.detail, err.response.status));
      dispatch({ type: CFC_QUERY_FAILED });
    });
};

//CLEAR USER (ADMIN) DETAILS
export const clearUserFullDetails = () => (dispatch, getState) => {
  dispatch({ type: CLEAR_USER_FULL_DETAILS });
};

//PATCH PROFILEEM //Patchuj profil pracownika
export const patchProfileEm = (profile, pk, up) => (dispatch, getState) => {
  axios
    .patch(serverurl + getDatabaseUrl() + `/profile_additional/${pk}?up=${up}`, profile, tokenConfig(getState))
    .then((res) => {
      dispatch(
        createMessages({
          patchProfileEm: "Zaktualizowano zmiany profilu pracownika ...",
        })
      );
      dispatch({
        type: PATCH_PROFILE_EM,
        payload: res.data,
      });
    })
    .catch((err) =>
      dispatch(returnErrors(err.response.data.detail, err.response.status))
    );
};

//PATCH sUser PROFILE
export const patchSUserProfile = (profile, pk) => (dispatch, getState) => {
  axios
    .patch(serverurl + getDatabaseUrl() + `/profile/${pk}`, profile, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: PATCH_SUSER_PROFILE,
        payload: res.data,
      });
    })
    .catch((err) =>
      dispatch(returnErrors(err.response.data.detail, err.response.status))
    );
};
