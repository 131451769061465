import { combineReducers } from "redux";
import accounting from "./accounting";
import trips from "./trips";
import tripsManager from "./trips-manager";
import errors from "./errors";
import messages from "./messages";
import auth from "./auth";
import employee from "./employee";
import manager from "./manager";
import holidays from "./holidays";
import holidaysManager from "./holidays-manager";
import assets from "./assets";
import common from "./common";
import document from "./document";
import remote from "./remote";
import project from "./project";
import fleet from "./fleet";
import scheduler from "./scheduler";
import verifycodes from "./verifycodes";
import zfss from "./zfss";

export default combineReducers({
  tripsReducer: trips,
  tripsManagerReducer: tripsManager,
  errors,
  messages,
  auth,
  employee,
  manager,
  holidays,
  holidaysManager,
  assets,
  common,
  accounting,
  document,
  remote,
  project,
  fleet,
  scheduler,
  verifycodes,
  zfss,
});
