import React, { Component, Fragment } from "react";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import { createMuiTheme } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";
import FormLabel from "@material-ui/core/FormLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import Checkbox from "@material-ui/core/Checkbox";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

import { connect } from "react-redux";
import { getProjects, clearProjects } from "../Redux/actions/project.js"
import PropTypes from "prop-types";
import ListItemText from "@material-ui/core/ListItemText";

const CssTextField = withStyles({
  root: {
    width: 300,
    flexWrap: "wrap",
    marginTop: "50px",
    marginLeft: "30px",
    marginRight: "30px",
    "& label.Mui-focused": {
      color: "#0071bc"
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#0071bc"
    }
  }
})(TextField);

const materialTheme = createMuiTheme({
  overrides: {
    MuiFormControl: {
      root: {
        width: 300,
        marginTop: 50
      },
      marginNormal: {
        marginTop: "50px"
      }
    },
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: "#0071BC"
      }
    },
    MuiButton: {
      root: {},
      textPrimary: {
        color: "#0071BC"
      },
      containedPrimary: {
        backgroundColor: "#1BAB7F",
        "&:hover": {
          backgroundColor: "#188E6A"
        }
      }
    },
    MuiFormLabel: {
      root: {
        MuiFocused: {
          color: "0071BC"
        }
      }
    },
    MuiPickersDay: {
      day: {
        color: "#0071BC"
      },
      daySelected: {
        backgroundColor: "#0071BC",
        "&:hover": {
          backgroundColor: "#0071BC"
        }
      },

      dayDisabled: {
        color: "gray"
      },
      current: {
        color: "#0071BC"
      }
    },
    MuiInput: {
      underline: {
        "&:after": {
          borderBottomColor: "#0071bc"
        }
      }
    },
    MuiInputBase: {
      root: {
        textAlign: "left"
      }
    },
    MuiCheckbox: {
      colorPrimary: {
        "&.Mui-checked": {
          color: "#0071bc"
        }
      }
    },
    MuiFormGroup: {
      root: {
        marginTop: "10px"
      }
    }
  }
});

export class ReadWrite extends Component {
  state = {
    tripIdentifier: null,
    place: null,
    country: null,
    project: null,
    tVehicle: null,
    tTrain: null,
    tPlane: null,
    tShip: null,
    isAccommodationNeeded: null,
    remarks: null,
    startDate: null,
    endDate: null,
    employee: null,
    owner: null,
    ownersManager: null,
    tripStatus: null
  };

  static propTypes = {
    getProjects: PropTypes.func.isRequired,
    clearProjects: PropTypes.func.isRequired,
  }

  componentWillUnmount() {
    this.props.clearProjects();
  }

  componentDidMount() {
    this.props.getProjects();
  }

  componentDidUpdate() {
    if (this.props.saveTrigger === true) {
      //tutaj muszę daty jeszcze raz obrobić jeżeli trzeba
      let startDateFormatted = null;
      let endDateFormatted = null;
      const isStartDateObject = typeof this.state.startDate;
      const isEndDateObject = typeof this.state.endDate;

      if (isStartDateObject === "object" && this.state.startDate !== null) {
        const dd = this.state.startDate.getDate();
        const mm = this.state.startDate.getMonth() + 1;
        const yyyy = this.state.startDate.getFullYear();
        const fullDate = yyyy + "-" + mm + "-" + dd;
        startDateFormatted = fullDate;
      }
      if (isEndDateObject === "object" && this.state.endDate !== null) {
        const dd = this.state.endDate.getDate();
        const mm = this.state.endDate.getMonth() + 1;
        const yyyy = this.state.endDate.getFullYear();
        const fullDate = yyyy + "-" + mm + "-" + dd;
        endDateFormatted = fullDate;
      }

      if (startDateFormatted === null) {
        startDateFormatted = this.state.startDate;
      }
      if (endDateFormatted === null) {
        endDateFormatted = this.state.endDate;
      }

      const trip = {
        tripIdentifier: this.state.tripIdentifier,
        place: this.state.place,
        country: this.state.country,
        project: this.state.project,
        tVehicle: this.state.tVehicle,
        tTrain: this.state.tTrain,
        tPlane: this.state.tPlane,
        tShip: this.state.tShip,
        isAccommodationNeeded: this.state.isAccommodationNeeded,
        remarks: this.state.remarks,
        startDate: startDateFormatted,
        endDate: endDateFormatted,
        employee: this.state.employee,
        owner: this.state.owner,
        ownersManager: this.state.ownersManager,
        tripStatus: this.state.tripStatus
      };

      this.props.saveData(trip);
    }
  }

  static getDerivedStateFromProps(props, state) {
    //console.log(props.tripDetails.place);
    //console.log(state.place);
    if (state.place == null) {
      return {
        tripIdentifier: props.tripDetails.tripIdentifier,
        place: props.tripDetails.place,
        country: props.tripDetails.country,
        project: props.tripDetails.project,
        tVehicle: props.tripDetails.tVehicle,
        tTrain: props.tripDetails.tTrain,
        tPlane: props.tripDetails.tPlane,
        tShip: props.tripDetails.tShip,
        isAccommodationNeeded: props.tripDetails.isAccommodationNeeded,
        remarks: props.tripDetails.remarks,
        startDate: props.tripDetails.startDate,
        endDate: props.tripDetails.endDate,
        employee: props.tripDetails.employee,
        owner: props.tripDetails.owner,
        ownersManager: props.tripDetails.ownersManager,
        tripStatus: props.tripDetails.tripStatus
      };
    }

    // Return null if the state hasn't changed
    return null;
  }

  handleTextList = name => ({ target: { value } }) => {
    //console.log(name);
    this.setState({
      ...this.state,
      [name]: value
    });
  };

  handleCheckbox = name => ({ target: { checked } }) => {
    this.setState({
      ...this.state,
      [name]: checked
    });
  };

  handleStartDate = date => {
    if (date !== null) {
      const dd = date.getDate();
      const mm = date.getMonth() + 1;
      const yyyy = date.getFullYear();
      const fullDate = yyyy + "-" + mm + "-" + dd;
      this.setState({
        startDate: date,
        startDateFormatted: fullDate
      });
    } else {
      this.setState({
        startDate: null,
        startDateFormatted: null
      });
    }
  };

  handleEndDate = date => {
    if (date !== null) {
      const dd = date.getDate();
      const mm = date.getMonth() + 1;
      const yyyy = date.getFullYear();
      const fullDate = yyyy + "-" + mm + "-" + dd;
      this.setState({
        endDate: date,
        endDateFormatted: fullDate
      });
    } else {
      this.setState({
        endDate: null,
        endDateFormatted: null
      });
    }
  };

  render() {
    const {
      place,
      country,
      project,
      tPlane,
      tTrain,
      tShip,
      tVehicle,
      isAccommodationNeeded,
      remarks,
      startDate,
      endDate
    } = this.state;

    var project_id = [];
    if (project != null) {
      project_id = project;
    }

    return (
      <Fragment>
        <form
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <CssTextField
            required
            multiline
            rowsMax="3"
            label="Miejsce/Obszar"
            inputProps={{ maxLength: 100 }}
            margin="normal"
            value={place || ""}
            onChange={this.handleTextList("place")}
          />
          <MuiPickersUtilsProvider
            utils={DateFnsUtils}
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            <ThemeProvider theme={materialTheme}>
              <KeyboardDatePicker
                margin="normal"
                label="Data wyjazdu"
                format="yyyy/MM/dd"
                minDate={new Date()}
                value={startDate}
                onChange={this.handleStartDate}
              />
            </ThemeProvider>
            <ThemeProvider theme={materialTheme}>
              <KeyboardDatePicker
                margin="normal"
                label="Data powrotu"
                format="yyyy/MM/dd"
                emptyLabel=""
                minDate={new Date()}
                value={endDate}
                onChange={this.handleEndDate}
              />
            </ThemeProvider>
          </MuiPickersUtilsProvider>
          <ThemeProvider theme={materialTheme}>
            <FormControl required>
              <InputLabel htmlFor="country">Kraj</InputLabel>
              <Select
                name="country"
                value={country || ""}
                onChange={this.handleTextList("country")}
              >
                <MenuItem value="DK">Dania</MenuItem>
                <MenuItem value="FI">Finlandia</MenuItem>
                <MenuItem value="DE">Niemcy</MenuItem>
                <MenuItem value="PL">Polska</MenuItem>
                <MenuItem value="SE">Szwecja</MenuItem>
                <MenuItem value="NL">Holandia</MenuItem>
              </Select>
            </FormControl>
            <ThemeProvider theme={materialTheme}>
              <FormControl required>
                <InputLabel htmlFor="project">
                  Projekt
                </InputLabel>
                <Select
                  name="project"
                  multiple
                  value={project_id}
                  renderValue={(selected) => selected.map((id) => {
                    const project = this.props.projects.find((p) => p.pk === id);
                    return project ? project.name : '';
                  }).join(', ')}
                >
                  {this.props.projects.map((item) => {
                    return (
                      <MenuItem key={item.pk} value={item.pk}>
                        <ListItemText primary={item.name} />
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </ThemeProvider>
            <FormControl required component="fieldset">
              <FormLabel component="legend">Transport</FormLabel>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={Boolean(tPlane)}
                      value={Boolean(tPlane)}
                      onChange={this.handleCheckbox("tPlane")}
                    />
                  }
                  label="Samolot"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={Boolean(tVehicle)}
                      value={tVehicle}
                      onChange={this.handleCheckbox("tVehicle")}
                    />
                  }
                  label="Samochód"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={Boolean(tTrain)}
                      value={tTrain}
                      onChange={this.handleCheckbox("tTrain")}
                    />
                  }
                  label="Pociąg"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={Boolean(tShip)}
                      value={tShip}
                      onChange={this.handleCheckbox("tShip")}
                    />
                  }
                  label="Prom"
                />
              </FormGroup>
              <FormHelperText>
                Zaznacz przynajmniej jedną z opcji
              </FormHelperText>
            </FormControl>
            <FormControl>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={Boolean(isAccommodationNeeded)}
                      value={isAccommodationNeeded}
                      color="primary"
                      onChange={this.handleCheckbox("isAccommodationNeeded")}
                    />
                  }
                  label="Zakwaterowanie"
                />
              </FormGroup>
            </FormControl>
          </ThemeProvider>
          <CssTextField
            required
            label="Opis delegacji"
            multiline
            rows="4"
            margin="normal"
            value={remarks || ""}
            onChange={this.handleTextList("remarks")}
          />
          <div style={{ marginBottom: 50 }}></div>
        </form>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  projects: state.project.projects,
});

export default connect(
  mapStateToProps,
  {
    getProjects, clearProjects
  }
)(ReadWrite);
