import React, { Component, Fragment } from 'react';
import AccountingTable from './AccountingTable.js';
import AccountingPreview from './AccountingPreview.js';

export class Accounting extends Component {

    state = {
        isChecked: false,
        tripIdentifierpk: null,
    }

    handleChecked = (isChecked) => {
        this.setState({
            isChecked: isChecked,
            tripIdentifierpk: null,
        });
    };

    handleTripIdentifierPK = (tripIdentifierpk) => {
        this.setState({
            tripIdentifierpk: tripIdentifierpk
        });
    };

    render() {

        const { isChecked, tripIdentifierpk } = this.state;

        return (
            <Fragment>
                <AccountingTable
                    checked={this.handleChecked}
                    tripIdentifierpk={this.handleTripIdentifierPK}
                />
                <AccountingPreview
                    isChecked={isChecked}
                    tripIdentifierpk={tripIdentifierpk}
                />
            </Fragment>
        )
    }
}

export default Accounting;
