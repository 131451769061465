import React, { Component, Fragment } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import Card from "../../components/Card/Card.js";
//import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import Typography from "@material-ui/core/Typography";
import { connect } from "react-redux";
//import PropTypes from "prop-types";
import CircularProgress from "@material-ui/core/CircularProgress";
import CardHeader from "@material-ui/core/CardHeader";
import { cardStyles } from "../../common/radiolineStyles";
import CompareArrowsIcon from "@material-ui/icons/CompareArrows";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import IconButton from "@material-ui/core/IconButton";
import GetAppIcon from "@material-ui/icons/GetApp";
import CreditCardIcon from "@material-ui/icons/CreditCard";
import CardContent from "@material-ui/core/CardContent";
import creditcard from "../../graphics/creditcard.svg";
import InfoIcon from "@material-ui/icons/Info";
import {
  getFormattedDateTimeTimezone,
  getFormattedDateTime,
} from "../../common/commonFunctions";
import Axios from "axios";
import fileDownload from "js-file-download";

var creditcardlogo = (
  <img style={{ height: "20vh" }} src={creditcard} alt="creditcard"></img>
);

export class CardDetailsCard extends Component {
  handleDownloadFile = (filepath, filename) => {
    const fileurl = filepath;

    //get filename
    //znajdz string ?AWSAccessKeyId

    const file = filename;

    Axios.get(fileurl, {
      responseType: "blob",
    }).then((res) => {
      fileDownload(res.data, file);
    });
  };

  render() {
    const {
      classes,
      isCardChecked,
      queryInProgress_cardsdetails,
      loadInProgress,
      cardsDetails,
    } = this.props;

    const circle = <CircularProgress color="secondary" size="1em" />;

    const waitloadinprogress = (
      <Fragment>
        <CircularProgress color="secondary" size="1em" />
        <div className={classes.infoListFetch}>
          Czekaj, ładuję plik na serwer ...
        </div>
      </Fragment>
    );

    var comparearrows = <CompareArrowsIcon />;

    var headerOP = (
      <CardHeader
        className={classes.headerInfo1}
        title={
          <Typography className={classes.CardHeader}>
            Wyciągi z karty
          </Typography>
        }
        subheader={
          <Fragment>
            <Typography component="span" className={classes.CardSubheader}>
              {" " + "zaznacz kartę aby wyświetlić ..."}
            </Typography>
            <InfoIcon />
          </Fragment>
        }
      />
    );

    var filelist = null;

    if (
      this.props.cardsDetails.businesscardfiles !== undefined &&
      this.props.cardsDetails.businesscardfiles.length === 0
    ) {
      filelist = (
        <div className={classes.infoListFetch}>brak plików do wyświetlenia</div>
      );
    } else if (
      this.props.cardsDetails.businesscardfiles !== undefined &&
      this.props.cardsDetails.businesscardfiles.length !== 0
    ) {
      filelist = (
        <Fragment>
          {this.props.cardsDetails.businesscardfiles.map((card, index) => (
            <ListItem key={"Card-" + card.pk}>
              <ListItemIcon>
                <InsertDriveFileIcon />
              </ListItemIcon>
              <ListItemText
                primary={card.filename}
                secondary={
                  <Fragment>
                    <Typography
                      className={classes.listTextSecondaryInline}
                      component="span"
                    >
                      Zapisano
                    </Typography>
                    <Typography
                      className={classes.listTextSecondaryInline}
                      component="span"
                    >
                      {"| " + card.datetime}
                    </Typography>
                  </Fragment>
                }
              />
              <ListItemSecondaryAction>
                <IconButton
                  edge="end"
                  aria-label="delete"
                  onClick={this.handleDownloadFile.bind(
                    this,
                    card.filepath,
                    card.filename
                  )}
                >
                  <GetAppIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </Fragment>
      );
    }

    const selectcardmessage = <Fragment>{creditcardlogo}</Fragment>;

    var nothingToDisplay = (
      <div className={classes.infoListFetch}>
        Brak transakcji do akceptacji ...
      </div>
    );

    const waitToDisplay = (
      <div className={classes.infoListFetch}>
        Czekaj, pobieram dane z serwera ...
      </div>
    );

    return (
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Card
          style={{
            marginTop: "0px",
            marginBottom: "0px",
            minHeight: "50vh",
            maxHeight: "80vh",
          }}
        >
          {headerOP}
          <CardContent>
            {!isCardChecked ? selectcardmessage : ""}
            <List>
              {isCardChecked && queryInProgress_cardsdetails ? circle : ""}
              {isCardChecked && loadInProgress ? waitloadinprogress : ""}
              {isCardChecked && !queryInProgress_cardsdetails && !loadInProgress
                ? filelist
                : ""}
            </List>
          </CardContent>
        </Card>
      </Grid>
    );
  }
}

const mapStateToProps = (state) => ({});

export default connect(
  mapStateToProps,
  {}
)(withStyles(cardStyles, { withTheme: true })(CardDetailsCard));
