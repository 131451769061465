import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Employee from "./Employee.js";
import Accounting from "./Accounting.js";
import TripsManagerViewer from "../Trips-manager/TripsManagerViewer.js";
import { connect } from "react-redux";
import { patchProfile } from "../Redux/actions/auth";

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

export class TripsViewer extends Component {
  state = {
    value: null,
  };

  static propTypes = {
    auth: PropTypes.object.isRequired,
    patchProfile: PropTypes.func.isRequired,
  };

  static getDerivedStateFromProps(props, state) {
    if (props.auth.user !== null && state.value === null) {
      return {
        value: props.auth.main_profile.tripsDefaultTab,
      };
    } else {
      return null;
    }
  }

  handleChange = (event, newValue) => {
    this.setState({
      value: newValue,
    });
    const profileViewBody = {
      tripsDefaultTab: newValue,
    };

    this.props.patchProfile(profileViewBody, this.props.auth.main_profile.pk);
  };

  render() {
    const { value } = this.state;
    const { module_trips_admin } = this.props.auth.permissions;
    const { cooperationType } = this.props.auth.additional_profile;
    const isSuperUser = this.props.auth.user.is_superuser;

    var subViewsConfig;
    if ((cooperationType == "emp" && module_trips_admin) || isSuperUser) {
      subViewsConfig = (
        <Fragment>
          <Tabs
            value={value}
            onChange={this.handleChange}
            aria-label="simple tabs example"
          >
            <Tab label="Moje" />
            <Tab label="Pracownicy" />
            <Tab label="Rozliczenia" />
          </Tabs>
          <TabPanel value={value} index={0}>
            <Employee />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <TripsManagerViewer />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <Accounting />
          </TabPanel>
        </Fragment>
      );
    } else if (cooperationType == "emp" || isSuperUser) {
      subViewsConfig = (
        <Fragment>
          <Employee />
        </Fragment>
      );
    }

    return <div>{subViewsConfig}</div>;
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(
  mapStateToProps,
  { patchProfile }
)(TripsViewer);
