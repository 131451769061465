import React from "react";

const NothingToDisplay = ({ classes }) => {
  return (
    <div className={classes}>Brak zdarzeń do wyświetlenia ...</div>
  )
};

const WaitToDisplay = ({ classes }) => {
  return (
    <div className={classes}>Czekaj, pobieram dane z serwera ...</div>
  )
};

export { NothingToDisplay, WaitToDisplay };