import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import ListItemText from "@material-ui/core/ListItemText";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import CircularProgress from "@material-ui/core/CircularProgress";

const styles = theme => ({
  progress: {
    margin: theme.spacing(2)
  }
});

export class HolidaysManagerHolidays extends Component {
  static propTypes = {
    holidaysManager: PropTypes.object.isRequired
  };

  render() {
    const { classes, label } = this.props;
    const { queryInProgress } = this.props.holidaysManager;

    if (label === "Aktualne nieobecności") {
      const title = (
        <h1>
          {label} ({this.props.holidaysManager.employeeLeavesOnLeave.length})
        </h1>
      );
      return (
        <Fragment>
          {title}
          <List>
            {this.props.holidaysManager.employeeLeavesOnLeave.map(leave => (
              <ListItem
                key={leave.pk}
                component={Link}
                to={"/holidays/employees/" + leave.pk}
              >
                <ListItemAvatar>
                  <Avatar style={{ backgroundColor: "#0071bc" }}>
                    {leave.employee.first_name.substr(0, 1).toUpperCase()}
                    {leave.employee.last_name.substr(0, 1).toUpperCase()}
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={leave.startDate + " | " + leave.endDate}
                  secondary={leave.leaveTypeDescription}
                />
              </ListItem>
            ))}
          </List>
        </Fragment>
      );
    } else if (label === "Urlopy do zatwierdzenia") {
      const title = (
        <h1>
          {label} ({this.props.holidaysManager.employeeLeavesCA.length})
        </h1>
      );
      return (
        <Fragment>
          {title}
          <List>
            {this.props.holidaysManager.employeeLeavesCA.map(leave => (
              <ListItem
                key={leave.pk}
                component={Link}
                to={"/holidays/employees/" + leave.pk}
              >
                <ListItemAvatar>
                  <Avatar style={{ backgroundColor: "#c2a33a" }}>
                    {leave.employee.first_name.substr(0, 1).toUpperCase()}
                    {leave.employee.last_name.substr(0, 1).toUpperCase()}
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={leave.startDate + " | " + leave.endDate}
                  secondary={leave.leaveTypeDescription}
                />
              </ListItem>
            ))}
          </List>
        </Fragment>
      );
    } else if (label === "Nadchodzące urlopy") {
      const title = (
        <h1>
          {label} ({this.props.holidaysManager.employeeLeavesPlanned.length})
        </h1>
      );
      return (
        <Fragment>
          {title}
          <List>
            {this.props.holidaysManager.employeeLeavesPlanned.map(leave => (
              <ListItem
                key={leave.pk}
                component={Link}
                to={"/holidays/employees/" + leave.pk}
              >
                <ListItemAvatar>
                  <Avatar style={{ backgroundColor: "#0071bc" }}>
                    {leave.employee.first_name.substr(0, 1).toUpperCase()}
                    {leave.employee.last_name.substr(0, 1).toUpperCase()}
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={leave.startDate + " | " + leave.endDate}
                  secondary={leave.leaveTypeDescription}
                />
              </ListItem>
            ))}
          </List>
        </Fragment>
      );
    } else if (label === "Filtr") {
      const title = (
        <h1>
          {label} ({this.props.holidaysManager.employeeLeavesSEARCH.length})
        </h1>
      );

      if (queryInProgress === false) {
        return (
          <Fragment>
            {title}
            <List>
              {this.props.holidaysManager.employeeLeavesSEARCH.map(leave => (
                <ListItem
                  key={leave.pk}
                  component={Link}
                  to={"/holidays/employees/" + leave.pk}
                >
                  <ListItemAvatar>
                    <Avatar style={{ backgroundColor: "#aaaaaa" }}>
                      {leave.employee.first_name.substr(0, 1).toUpperCase()}
                      {leave.employee.last_name.substr(0, 1).toUpperCase()}
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={leave.startDate + " | " + leave.endDate}
                    secondary={leave.leaveTypeDescription}
                  />
                </ListItem>
              ))}
            </List>
            <div style={{ marginBottom: 130 }}></div>
          </Fragment>
        );
      } else {
        return (
          <Fragment>
            {title}
            <CircularProgress className={classes.progress} color="secondary" />
            <div style={{ marginBottom: 130 }}></div>
          </Fragment>
        );
      }
    } else {
      return null;
    }
  }
}

const mapStateToProps = state => ({
  holidaysManager: state.holidaysManager
});

export default connect(
  mapStateToProps,
  {}
)(withStyles(styles, { withTheme: true })(HolidaysManagerHolidays));
