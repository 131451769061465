import {
  ADD_APPLICATION,
  ADD_APPLICATION_INCOME,
  ADD_APPLICATION_KIDS,
  GET_APPLICATION,
  GET_APPLICATION_INCOME,
  GET_APPLICATION_KIDS,
  GET_LAST_INCOME,
  APPLICATION_INCOME_QUERY_FALIED,
  APPLICATION_INCOME_QUERY_IN_PROGRESS,
  APPLICATION_KIDS_QUERY_FALIED,
  APPLICATION_KIDS_QUERY_IN_PROGRESS,
  CLEAR_LAST_INCOME,
  CLEAR_APPLICATION_INCOME,
  CLEAR_APPLICATION_KIDS,
  GET_CLAUSULE,
  ADD_APPLICATION_SWO,
  APPLICATION_SWO_QUERY_IN_PROGRESS,
  GET_APPLICATION_SWO,
  APPLICATION_SWO_QUERY_FALIED,
  CLEAR_APPLICATION_SWO,
  GET_LAST_PACKAGE,
  GET_LAST_SWO,
} from "../actions/types";

const initialState = {
  income: [],
  kids: [],
  swo: [],
  last: [],
  lastPackage: [],
  lastSWO: [],
  clausule: [],
  placed: false,
  queryInProgressIncome: false,
  queryInProgressSWO: false,
  queryInProgressKids: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case APPLICATION_INCOME_QUERY_FALIED:
      return {
        ...state,
        queryInProgressIncome: false,
      };
    case APPLICATION_INCOME_QUERY_IN_PROGRESS:
      return {
        ...state,
        queryInProgressIncome: true,
      };
    case APPLICATION_SWO_QUERY_FALIED:
      return {
        ...state,
        queryInProgressSWO: false,
      };
    case APPLICATION_SWO_QUERY_IN_PROGRESS:
      return {
        ...state,
        queryInProgressSWO: true,
      };
    case APPLICATION_KIDS_QUERY_FALIED:
      return {
        ...state,
        queryInProgressKids: false,
      };
    case APPLICATION_KIDS_QUERY_IN_PROGRESS:
      return {
        ...state,
        queryInProgressKids: true,
      };
    case ADD_APPLICATION:
      return {
        ...state,
      };
    case ADD_APPLICATION_INCOME:
      return {
        ...state,
        income: action.payload.application_income,
        placed: action.placed,
      };
    case ADD_APPLICATION_KIDS:
      return {
        ...state,
        lastPackage: { 0: action.payload.application_data },
      };
    case ADD_APPLICATION_SWO:
      return {
        ...state,
        lastSWO: { 0: action.payload.application_data },
      };
    case GET_APPLICATION:
      return {
        ...state,
        list: action.payload,
      };
    case GET_CLAUSULE:
      return {
        ...state,
        clausule: action.payload,
      };
    case GET_APPLICATION_INCOME:
      return {
        ...state,
        income: action.payload,
        queryInProgressIncome: false,
      };
    case GET_APPLICATION_SWO:
      return {
        ...state,
        swo: action.payload,
        queryInProgressSWO: false,
      };
    case GET_APPLICATION_KIDS:
      return {
        ...state,
        kids: action.payload,
        queryInProgressKids: false,
      };
    case GET_LAST_INCOME:
      return {
        ...state,
        last: action.payload,
      };
    case GET_LAST_PACKAGE:
      return {
        ...state,
        lastPackage: action.payload,
      };
    case GET_LAST_SWO:
      return {
        ...state,
        lastSWO: action.payload,
      };
    case CLEAR_LAST_INCOME:
      return {
        ...state,
        last: []
      };
    case CLEAR_APPLICATION_INCOME:
      return {
        ...state,
        income: [],
      };
    case CLEAR_APPLICATION_KIDS:
      return {
        ...state,
        kids: [],
      };
    case CLEAR_APPLICATION_SWO:
      return {
        ...state,
        swo: [],
      };
    default:
      return state;
  }
}
