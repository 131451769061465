import React, { Component, Fragment } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import { Avatar } from "@material-ui/core";
import Card from "../components/Card/Card.js";
import CardBody from "../components/Card/CardBody.js";
//import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
import Typography from "@material-ui/core/Typography";
//import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";
import { createMuiTheme } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";
import LinearProgress from "@material-ui/core/LinearProgress";
import Box from "@material-ui/core/Box";
import { updateImage } from "../Redux/actions/auth";

import PropTypes from "prop-types";
import { connect } from "react-redux";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import CustomFileInput from "components/CustomFileInput/CustomFileInput.js";
import AttachFile from "@material-ui/icons/AttachFile";
import PicImg from "./selfieInstructions.jpg";

const materialTheme = createMuiTheme({
  palette: {
    primary: {
      main: "#0071BC",
    },
  },
  overrides: {
    MuiChip: {
      root: {
        color: "#ffffff",
        "& $avatar": {
          color: "#ffffff",
        },
      },
      icon: {
        color: "#ffffff",
      },
    },
    MuiCardActions: {
      root: {
        background: "#aaaaaa",
      },
    },
    MuiIconButton: {
      root: {
        color: "#fff",
      },
    },
  },
});

const styles = (theme) => ({
  avatar: {
    backgroundColor: "#0071BC",
    height: 50,
    width: 50,
  },
  Address: {
    textAlign: "left",
    fontFamily: "Lato, Open Sans, sans-serif",
    fontSize: "1em",
    fontWeight: "300",
    color: "#444444",
  },
  Position: {
    textAlign: "left",
    fontFamily: "Lato, Open Sans, sans-serif",
    fontSize: "1em",
    fontWeight: "400",
    color: "#0071BC",
  },
  Name: {
    textAlign: "left",
    fontFamily: "Lato, Open Sans, sans-serif",
    fontSize: "2em",
    fontWeight: "700",
    color: "#444444",
  },
  ProfileBar: {
    textAlign: "left",
    fontFamily: "Lato, Open Sans, sans-serif",
    fontSize: "1em",
    fontWeight: "400",
    color: "#444444",
  },
  Country: {
    textAlign: "left",
    fontFamily: "Lato, Open Sans, sans-serif",
    fontSize: "1em",
    fontWeight: "300",
    color: "#444444",
    textTransform: "uppercase",
  },
  large: {
    width: "90px",
    height: "90px",
  },
});

function LinearProgressWithLabel(props) {
  return (
    <Box display="flex" alignItems="center">
      <Box width="100%" mr={1}>
        <ThemeProvider theme={materialTheme}>
          <LinearProgress variant="determinate" {...props} />
        </ThemeProvider>
      </Box>
      <Box minWidth={35}>
        <Typography variant="body2" color="textSecondary">{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

export class NameCard extends Component {
  state = {
    progress: 0,
    dialogRemovePicture: false,
    dialogAddPicture: false,
    profileImage: null,
    file: null,
    fileName: null,
  };

  static propTypes = {
    auth: PropTypes.object.isRequired,
    updateImage: PropTypes.func.isRequired,
  };

  componentDidMount() {
    const procentProfilu = 5.882353;
    const procentInit = 7 * procentProfilu;
    var procentTotal = procentInit;

    if (
      this.props.auth.main_profile.phone !== "" &&
      this.props.auth.main_profile.phone !== null
    ) {
      procentTotal = procentTotal + procentProfilu;
    }
    if (
      this.props.auth.main_profile.idNumber !== "" &&
      this.props.auth.main_profile.idNumber !== null
    ) {
      procentTotal = procentTotal + procentProfilu;
    }
    if (
      this.props.auth.main_profile.pesel !== "" &&
      this.props.auth.main_profile.pesel !== null
    ) {
      procentTotal = procentTotal + procentProfilu;
    }
    if (
      this.props.auth.main_profile.birthDate !== "" &&
      this.props.auth.main_profile.birthDate !== null
    ) {
      procentTotal = procentTotal + procentProfilu;
    }
    if (
      this.props.auth.main_profile.birthPlace !== "" &&
      this.props.auth.main_profile.birthPlace !== null
    ) {
      procentTotal = procentTotal + procentProfilu;
    }
    if (
      this.props.auth.main_profile.addrCity !== "" &&
      this.props.auth.main_profile.addrCity !== null
    ) {
      procentTotal = procentTotal + procentProfilu;
    }
    if (
      this.props.auth.main_profile.addrStreet !== "" &&
      this.props.auth.main_profile.addrStreet !== null
    ) {
      procentTotal = procentTotal + procentProfilu;
    }
    if (
      this.props.auth.main_profile.addrPostalCode !== "" &&
      this.props.auth.main_profile.addrPostalCode !== null
    ) {
      procentTotal = procentTotal + procentProfilu;
    }
    if (
      this.props.auth.main_profile.addrCountry !== "" &&
      this.props.auth.main_profile.addrCountry !== null
    ) {
      procentTotal = procentTotal + procentProfilu;
    }
    if (
      this.props.auth.main_profile.profileImage !== "" &&
      this.props.auth.main_profile.profileImage !== null
    ) {
      procentTotal = procentTotal + procentProfilu;
    }

    this.setState({
      progress: Math.floor(procentTotal),
      profileImage: this.props.auth.main_profile.profileImage,
    });
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.auth.main_profile.profileImage !==
      this.props.auth.main_profile.profileImage
    ) {
      this.setState({
        profileImage: nextProps.auth.main_profile.profileImage,
      });
    }
  }

  triggerRemovePicture = () => {
    this.setState({
      dialogRemovePicture: true,
    });
  };

  handleRemovePicture = () => {
    this.setState({
      dialogRemovePicture: false,
    });

    const profileBody = {
      profileImage: null,
    };

    this.props.updateImage(profileBody, this.props.auth.main_profile.pk);
  };

  handleCloseDialogRemovePicture = () => {
    this.setState({
      dialogRemovePicture: false,
    });
  };

  triggerAddPicture = () => {
    this.setState({
      dialogAddPicture: true,
    });
  };

  handleAddPicture = () => {
    this.setState({
      dialogAddPicture: false,
    });

    var profileBody = new FormData();
    profileBody.append("profileImage", this.state.file);

    this.props.updateImage(profileBody, this.props.auth.main_profile.pk);
  };

  handleCloseDialogAddPicture = () => {
    this.setState({
      dialogAddPicture: false,
      file: null,
      fileName: null,
    });
  };

  attachPicture = (inputFiles, inputFileNames) => {
    this.setState({
      file: inputFiles[0],
      fileName: inputFileNames,
    });
  };

  render() {
    const { classes, auth } = this.props;
    const {
      progress,
      dialogRemovePicture,
      dialogAddPicture,
      profileImage,
      file,
    } = this.state;

    const removeBtnEn = (
      <Button
        style={{
          marginBottom: "40px",
        }}
        variant="contained"
        onClick={this.triggerRemovePicture}
      >
        Usuń zdjęcie
      </Button>
    );

    const removeBtnDi = (
      <Button
        style={{
          marginBottom: "40px",
        }}
        variant="contained"
        disabled
      >
        Usuń zdjęcie
      </Button>
    );

    const addBtnEn = (
      <Button
        style={{
          marginBottom: "40px",
        }}
        variant="contained"
        onClick={this.triggerAddPicture}
        color="primary"
      >
        Dodaj zdjęcie
      </Button>
    );

    const addBtnDi = (
      <Button
        style={{
          marginBottom: "40px",
        }}
        variant="contained"
        color="primary"
        disabled
      >
        Dodaj zdjęcie
      </Button>
    );

    const addDialogBtnEn = (
      <Button
        onClick={this.handleAddPicture}
        color="primary"
        autoFocus
        variant="contained"
      >
        Dodaj
      </Button>
    );

    const addDialogBtnDi = (
      <Button disabled color="primary" autoFocus variant="contained">
        Dodaj
      </Button>
    );

    return (
      <Fragment>
        <Grid item xs={12} sm={12} md={6} lg={4}>
          <Card style={{ marginBottom: "0px" }}>
            <CardBody>
              <Grid
                container
                justify="space-between"
                alignItems="center"
                spacing={2}
              >
                <Grid item xs={7} sm={10} md={6} lg={9}>
                  <Typography className={classes.Name}>
                    {auth.user.first_name + " " + auth.user.last_name}
                  </Typography>
                  <Typography className={classes.Position}>
                    {auth.additional_profile.position}
                  </Typography>
                  <Typography className={classes.Address}>
                    {auth.additional_profile.addrStreet}
                  </Typography>
                  <Typography className={classes.Address}>
                    {auth.additional_profile.addrPostalCode +
                      " " +
                      auth.additional_profile.addrCity}
                  </Typography>
                  <Typography className={classes.Country}>
                    {auth.additional_profile.addrCountry}
                  </Typography>
                </Grid>
                <Grid item xs={3} sm={2} md={4} lg={3}>
                  <Avatar
                    className={classes.large}
                    alt={auth.user.first_name + " " + auth.user.last_name}
                    src={auth.main_profile.profileImage}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.ProfileBar}>
                    Kompletność profilu
                  </Typography>
                  <LinearProgressWithLabel value={progress} />
                </Grid>
                <ThemeProvider theme={materialTheme}>
                  {profileImage ? removeBtnEn : removeBtnDi}
                  {profileImage ? addBtnDi : addBtnEn}
                </ThemeProvider>
              </Grid>
            </CardBody>
          </Card>
        </Grid>

        <Dialog
          open={dialogRemovePicture}
          onClose={this.handleCloseDialogRemovePicture}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Aktualizacja profilu użytkownika..."}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Czy na pewno chcesz usunąć zdjęcie profilowe ?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <ThemeProvider theme={materialTheme}>
              <Button
                color="primary"
                onClick={this.handleCloseDialogRemovePicture}
              >
                Anuluj
              </Button>
              <Button
                onClick={this.handleRemovePicture}
                color="secondary"
                autoFocus
                variant="contained"
              >
                Usuń
              </Button>
            </ThemeProvider>
          </DialogActions>
        </Dialog>

        <Dialog
          open={dialogAddPicture}
          onClose={this.handleCloseDialogAddPicture}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Aktualizacja profilu użytkownika..."}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Wybierz zdjęcie profilowe. Pamiętaj aby zrobić zdjęcie w ujęciu
              jak pokazano na poniższym rysunku. W przeciwnym razie zdjęcie może
              być odwrócone...
            </DialogContentText>
            <img src={PicImg} style={{ width: "300px" }} alt="PicImg"></img>
            <ThemeProvider theme={materialTheme}>
              <CustomFileInput
                style={{ marginLeft: "8px" }}
                onChange={this.attachPicture}
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  placeholder: "załącz zdjęcie profilowe...",
                }}
                endButton={{
                  buttonProps: {
                    round: true,
                    color: "primary",
                    justIcon: true,
                    fileButton: true,
                  },
                  icon: <AttachFile />,
                }}
              />
            </ThemeProvider>
          </DialogContent>
          <DialogActions>
            <ThemeProvider theme={materialTheme}>
              <Button
                color="primary"
                onClick={this.handleCloseDialogAddPicture}
              >
                Anuluj
              </Button>
              {file ? addDialogBtnEn : addDialogBtnDi}
            </ThemeProvider>
          </DialogActions>
        </Dialog>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(
  mapStateToProps,
  { updateImage }
)(withStyles(styles, { withTheme: true })(NameCard));
