import React, { Fragment, Component } from "react";
// @material-ui/core components
// core components
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import Box from "@material-ui/core/Box";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Tooltip from "@material-ui/core/Tooltip";
import PlaylistAddSharpIcon from "@material-ui/icons/PlaylistAddSharp";
import {
  getEmployeeAssets,
  getAssetDetails,
  clearEmployeeAssetsState,
  getEmployeeAssetsByStatus,
  clearEmployeeAssetsByStatus,
  patchAssetTransferLog,
  patchEmployeeAsset,
  scrapEmployeeAsset,
  addAsset,
  clearEquipmentModelsList,
  addAssetModel,
  addVendor,
} from "../../Redux/actions/assets";
import { clearUserDetails } from "../../Redux/actions/common";
import { withStyles } from "@material-ui/core/styles";
import Navigation from "../Common/Navigation";
import AssetList from "../Common/AssetList";
import AddAssetForm from "../Common/AddAssetForm";
import HomeWorkIcon from "@material-ui/icons/HomeWork";
import AcceptCard from "./AcceptCard";
import ScrapAcceptCard from "./ScrapAcceptCard";
import ScrapWaitForReturnCard from "../Common/ScrapWaitForReturnCard";
import TransferCard from "./TransferCard";
import FindCard from "../Common/FindCard";
import AssetFilterList from "../Common/AssetFilterList";
import AssetFindList from "../Common/AssetFindList";
import AssetScrapFilterList from "../Common/AssetScrapFilterList";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import VisibilityIcon from "@material-ui/icons/Visibility";
import Zoom from "@material-ui/core/Zoom";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import Grid from "@material-ui/core/Grid";
import DoneIcon from "@material-ui/icons/Done";
import TextField from "@material-ui/core/TextField";
import { getFormattedDate } from "../../common/commonFunctions";
import { styles } from "../../common/radiolineStyles";
import { sendNotification, sendNewNotification } from "../../Redux/actions/employee";
import { ThemeProvider } from "@material-ui/styles";
import { createMuiTheme } from "@material-ui/core";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
import DialogAssetTransfer from "../Common/DialogAssetTransfer";
import DialogAddAssetModel from "../Common/DialogAddAssetModel";
import DialogMoveToWarehouse from "../Common/DialogMoveToWarehouse";
import DialogAssetDetails from "../Common/DialogAssetDetails";
import DeleteIcon from '@material-ui/icons/Delete';

const materialThemeNext = createMuiTheme({
  palette: {
    primary: {
      main: "#0071BC",
    },
  },
  overrides: {
    MuiTextField: {
      root: {
        width: "auto",
      },
    },
    MuiInputBase: {
      root: {
        width: "300px",
      },
    },
    MuiFormLabel: {
      root: {
        color: "#0071bc",
      },
    },
  },
});

export class MainManagerView extends Component {
  state = {
    checkedAssetPk: null,
    isAssetChecked: false,
    eqTypeSelected: "al",
    open: false,
    dialogOpen: false,
    dialogOpenAddAsset: false,
    dialogOpenAddAssetModel: false,
    dialogAcceptAssetScrap: false,
    dialogOpenAssetTransfer: false,
    dialogMoveToWarehouse: false,
    returnAssetRequestFlag: false,
    dialogAssetReturn: false,
    chargeEmployeeFlag: false,
    alertOpen: false,
    activeFilter: null,
    activeListType: null,
    chargeValue: "",
    chargeValueErrorFlag: false,
    chargeValueErrorMessage: "wartość obciążenia musi być powyżej 0PLN",
    scrapageManagerComment: "",
    scrapageManagerCommentErrorFlag: false,
    scrapageManagerCommentErrorMessage: "podaj uzasadnienie obciążenia",
  };

  static propTypes = {
    auth: PropTypes.object.isRequired,
    queryInProgressCommon: PropTypes.bool.isRequired,
    queryInProgress: PropTypes.bool.isRequired,
    userDetails: PropTypes.object.isRequired,
    getAssetDetails: PropTypes.func.isRequired,
    clearEmployeeAssetsState: PropTypes.func.isRequired,
    getEmployeeAssetsByStatus: PropTypes.func.isRequired,
    clearEmployeeAssetsByStatus: PropTypes.func.isRequired,
    addAsset: PropTypes.func.isRequired,
    patchAssetTransferLog: PropTypes.func.isRequired,
    scrapEmployeeAsset: PropTypes.func.isRequired,
    sendNotification: PropTypes.func.isRequired,
    sendNewNotification: PropTypes.func.isRequired,
    clearEquipmentModelsList: PropTypes.func.isRequired,
    addAssetModel: PropTypes.func.isRequired,
    addVendor: PropTypes.func.isRequired,
  };

  componentDidMount() {
    this.props.clearUserDetails("s");
    this.props.clearEmployeeAssetsState();
    this.props.getEmployeeAssetsByStatus("ac");
    this.props.getEmployeeAssetsByStatus("tr");
    this.props.getEmployeeAssetsByStatus("sr");
    this.props.getEmployeeAssetsByStatus("wr");
    //dorobic zapytanie do pobrania danych o oczekujace na złomowanie i oczekujące na zwrot do magazynu
  }

  componentWillReceiveProps(nextProps) {
    //wykryj czy utworzono nowy equipment transfer log
    if (
      nextProps.userDetails.pk !== undefined &&
      nextProps.userDetails.pk !== this.props.userDetails.pk
    ) {
      this.props.getEmployeeAssets(nextProps.userDetails.pk, "al");
      this.props.getEmployeeAssets(nextProps.userDetails.pk, "na");
      this.props.getEmployeeAssets(nextProps.userDetails.pk, "el");
      this.props.getEmployeeAssets(nextProps.userDetails.pk, "it");
      this.props.getEmployeeAssets(nextProps.userDetails.pk, "po");
    }
  }

  sprawdzCzyWypelniono = (nazwapola, wartoscpola) => {
    switch (nazwapola) {
      case "chargeValue":
        if (wartoscpola <= 0) {
          this.setState({
            chargeValueErrorFlag: true,
          });
          return true;
        } else {
          this.setState({
            chargeValueErrorFlag: false,
          });
          return false;
        }
      case "scrapageManagerComment":
        if (wartoscpola === "") {
          this.setState({
            scrapageManagerCommentErrorFlag: true,
          });
          return true;
        } else {
          this.setState({
            scrapageManagerCommentErrorFlag: false,
          });
          return false;
        }
      default:
        return false;
    }
  };

  handleTextList = (name) => ({ target: { value } }) => {
    this.setState({
      //...this.state,
      [name]: value,
    });
  };

  handleChecked = (isChecked, assetpk, listtype) => {
    this.setState({
      isAssetChecked: isChecked,
      checkedAssetPk: assetpk,
      activeListType: listtype,
    });

    if (isChecked === true) {
      this.props.getAssetDetails(assetpk);
    }
  };

  handleEqTypeSelected = (eqtypeselected) => {
    this.setState({
      eqTypeSelected: eqtypeselected,
      checkedAssetPk: null,
      isAssetChecked: false,
    });
  };

  handleEmployeeSelected = (employeepk) => {
    this.setState({
      employeeSelected: employeepk,
    });
  };

  handleClickPreview = (event) => {
    this.setState({
      dialogOpen: true,
    });
  };

  handleAlertClose = () => {
    this.setState({
      alertOpen: false,
    });
  };

  handleClickCloseDialog = () => {
    this.setState({
      dialogOpen: false,
    });
  };

  triggerAddNewAsset = () => {
    this.setState({
      dialogOpenAddAsset: true,
    });
  };

  triggerAddNewAssetModel = () => {
    this.setState({
      dialogOpenAddAssetModel: true,
    });
  };

  handleCloseDialogAddAsset = () => {
    this.setState({
      dialogOpenAddAsset: false,
    });

    this.props.clearEquipmentModelsList();
  };

  handleCloseDialogAddAssetModel = () => {
    this.setState({
      dialogOpenAddAssetModel: false,
    });
  };

  handleDownload = (eqtype) => {
    var assetsToBeDownloaded = [];

    if (eqtype === "al") {
      assetsToBeDownloaded = this.props.employeeAssetsAl;
    } else if (eqtype === "na") {
      assetsToBeDownloaded = this.props.employeeAssetsNa;
    } else if (eqtype === "el") {
      assetsToBeDownloaded = this.props.employeeAssetsEl;
    } else if (eqtype === "it") {
      assetsToBeDownloaded = this.props.employeeAssetsIt;
    } else if (eqtype === "po") {
      assetsToBeDownloaded = this.props.employeeAssetsPo;
    } else if (eqtype === "ws") {
      assetsToBeDownloaded = this.props.employeeAssetsAl.concat(
        this.props.employeeAssetsNa,
        this.props.employeeAssetsEl,
        this.props.employeeAssetsIt,
        this.props.employeeAssetsPo
      );
    }

    if (assetsToBeDownloaded.length !== 0) {
      var csvRow = [];
      var A = [
        [
          "Grupa",
          "Nazwa",
          "Model",
          "Producent",
          "Numer seryjny",
          "Numer Produktu",
          "Data zakupu",
          "Data przekazania",
          "Opis dodatkowy",
          "Właściciel środka trwałego",
        ],
      ];
      var re = assetsToBeDownloaded;

      for (var item = 0; item < re.length; item++) {
        A.push([
          re[item].model.eqtype_description,
          re[item].model.name,
          re[item].model.description,
          re[item].model.vendor,
          re[item].serialNumber,
          re[item].partNumber,
          re[item].purchaseDate,
          re[item].assignDate,
          re[item].description,
          re[item].owner.first_name + " " + re[item].owner.last_name,
        ]);
      }

      for (var i = 0; i < A.length; ++i) {
        csvRow.push(A[i].join(";"));
      }

      var csvString = csvRow.join("\n");
      //var a = document.createElement("a");
      var link = window.document.createElement("a");
      link.setAttribute(
        "href",
        "data:text/csv;charset=utf-8,%EF%BB%BF" + encodeURI(csvString)
      );
      if (eqtype === "ws") {
        link.setAttribute(
          "download",
          `SrodkiTrwale${re[0].owner.first_name + re[0].owner.last_name}.csv`
        );
      } else {
        link.setAttribute(
          "download",
          `SrodkiTrwale${re[0].owner.first_name + re[0].owner.last_name + "_"}${re[0].model.eqtype_description
          }.csv`
        );
      }
      link.click();
    } else {
      this.setState({
        alertOpen: true,
      });
    }
  };

  handleDiscardAssetTransfer = () => {
    const acceptDate = getFormattedDate(new Date());
    const bodytrl = {
      acceptStatus: "de",
      acceptDate: acceptDate,
    };
    const bodyasset = {
      status: "iu",
      transferowner: null,
      acceptowner: null,
      currentEquipmentTransferLog: null,
    };
    /*
    const smsbodyowner = {
      recipient: this.props.assetDetails.owner.phone,
      sender: "WYPOSAZENIE",
      message:
        "Uzytkownik " +
        this.props.auth.user.username +
        " odrzucil przesuniecie srodka trwalego " +
        this.props.assetDetails.model.name +
        " " +
        this.props.assetDetails.model.description,
      owner: this.props.auth.user.id,
      notificationType: "sms",
      isDelivered: true,
    };
    const smsbodytransferowner = {
      recipient: this.props.assetDetails.transferowner.phone,
      sender: "WYPOSAZENIE",
      message:
        "Uzytkownik " +
        this.props.auth.user.username +
        " odrzucil przesuniecie srodka trwalego " +
        this.props.assetDetails.model.name +
        " " +
        this.props.assetDetails.model.description,
      owner: this.props.auth.user.id,
      notificationType: "sms",
      isDelivered: true,
    };
    */

    var tempSerialNumber = "Nie podano";
    var tempPartNumber = "Nie podano";

    if (this.props.assetDetails.serialNumber)
      tempSerialNumber = this.props.assetDetails.serialNumber

    if (this.props.assetDetails.partNumber)
      tempPartNumber = this.props.assetDetails.partNumber

    const notifyBodyOwner = {
      recipient: this.props.assetDetails.owner.email,
      module: "Środki Trwałe",
      topic: "Odrzucenie środka trwałego",
      message:
        "Użytkownik " + this.props.auth.user.first_name + " " + this.props.auth.user.last_name +
        " odrzucił przesunięcie środka trwałego: \n" +
        "Model: " + this.props.assetDetails.model.name + "\n" +
        "Opis: " + this.props.assetDetails.model.description + "\n" +
        "Numer seryjny: " + tempSerialNumber + "\n" +
        "Numer produktu: " + tempPartNumber + "\n"
    }

    const notifyBodyTransfer = {
      recipient: this.props.assetDetails.transferowner.email,
      module: "Środki Trwałe",
      topic: "Odrzucenie środka trwałego",
      message:
        "Użytkownik " + this.props.auth.user.first_name + " " + this.props.auth.user.last_name +
        " odrzucił przesunięcie środka trwałego: \n" +
        "Model: " + this.props.assetDetails.model.name + "\n" +
        "Opis: " + this.props.assetDetails.model.description + "\n" +
        "Numer seryjny: " + tempSerialNumber + "\n" +
        "Numer produktu: " + tempPartNumber + "\n"
    }

    this.props.patchAssetTransferLog(
      Number(this.props.assetDetails.currentEquipmentTransferLog),
      bodytrl
    );
    this.props.patchEmployeeAsset(this.props.assetDetails.pk, bodyasset);
    //this.props.sendNotification(smsbodyowner);
    //this.props.sendNotification(smsbodytransferowner);
    this.props.sendNewNotification(notifyBodyOwner);
    this.props.sendNewNotification(notifyBodyTransfer);
  };

  handleAcceptAssetTransfer = () => {
    const acceptDate = getFormattedDate(new Date());
    const bodytrl = {
      acceptStatus: "ac",
      acceptDate: acceptDate,
    };
    const bodyasset = {
      owner: this.props.assetDetails.transferowner.pk,
      status: "iu",
      transferowner: null,
      acceptowner: null,
      currentEquipmentTransferLog: null,
    };

    /*
    const smsbodyowner = {
      recipient: this.props.assetDetails.owner.phone,
      sender: "WYPOSAZENIE",
      message:
        "Uzytkownik " +
        this.props.auth.user.username +
        " zaakceptowal przesuniecie srodka trwalego " +
        this.props.assetDetails.model.name +
        " " +
        this.props.assetDetails.model.description,
      owner: this.props.auth.user.id,
      notificationType: "sms",
      isDelivered: true,
    };

    const smsbodytransferowner = {
      recipient: this.props.assetDetails.transferowner.phone,
      sender: "WYPOSAZENIE",
      message:
        "Uzytkownik " +
        this.props.auth.user.username +
        " zaakceptowal przesuniecie srodka trwalego " +
        this.props.assetDetails.model.name +
        " " +
        this.props.assetDetails.model.description,
      owner: this.props.auth.user.id,
      notificationType: "sms",
      isDelivered: true,
    };
    */

    var tempSerialNumber = "Nie podano";
    var tempPartNumber = "Nie podano";

    if (this.props.assetDetails.serialNumber)
      tempSerialNumber = this.props.assetDetails.serialNumber

    if (this.props.assetDetails.partNumber)
      tempPartNumber = this.props.assetDetails.partNumber

    const notifyBodyOwner = {
      recipient: this.props.assetDetails.owner.email,
      module: "Środki Trwałe",
      topic: "Przekazanie środka trwałego",
      message:
        "Użytkownik " + this.props.auth.user.first_name + " " + this.props.auth.user.last_name +
        " zaakceptował przesunięcie środka trwałego: \n" +
        "Model: " + this.props.assetDetails.model.name + "\n" +
        "Opis: " + this.props.assetDetails.model.description + "\n" +
        "Numer seryjny: " + tempSerialNumber + "\n" +
        "Numer produktu: " + tempPartNumber + "\n"
    }

    const notifyBodyTransfer = {
      recipient: this.props.assetDetails.transferowner.email,
      module: "Środki Trwałe",
      topic: "Przekazanie środka trwałego",
      message:
        "Użytkownik " + this.props.auth.user.first_name + " " + this.props.auth.user.last_name +
        " zaakceptował przesunięcie środka trwałego: \n" +
        "Model: " + this.props.assetDetails.model.name + "\n" +
        "Opis: " + this.props.assetDetails.model.description + "\n" +
        "Numer seryjny: " + tempSerialNumber + "\n" +
        "Numer produktu: " + tempPartNumber + "\n"
    }

    this.props.patchAssetTransferLog(
      Number(this.props.assetDetails.currentEquipmentTransferLog),
      bodytrl
    );
    this.props.patchEmployeeAsset(this.props.assetDetails.pk, bodyasset);
    //this.props.sendNotification(smsbodyowner);
    //this.props.sendNotification(smsbodytransferowner);
    this.props.sendNewNotification(notifyBodyOwner);
    this.props.sendNewNotification(notifyBodyTransfer);
  };

  handleAddAsset = (assetBody, modelSelected) => {
    /*
    const smsbodyowner = {
      recipient: this.props.userDetails.phone,
      sender: "WYPOSAZENIE",
      message:
        "Uzytkownik " +
        this.props.auth.user.username +
        " przypisal do Ciebie nowy srodek trwaly " +
        modelSelected.vendor +
        "/" +
        modelSelected.name +
        "/" +
        modelSelected.description +
        ". Mozesz znalezc nowy srodek w grupie " +
        modelSelected.eqtype_description +
        ".",
      owner: this.props.auth.user.id,
      notificationType: "sms",
      isDelivered: true,
    };
    */

    const notifyBody = {
      recipient: this.props.userDetails.email,
      module: "Środki Trwałe",
      topic: "Otrzymano nowy środek trwały",
      message:
        "Użytkownik " + this.props.auth.user.first_name + " " + this.props.auth.user.last_name +
        " przypisał do Ciebie nowy środek trwały: \n" +
        "Model: " + modelSelected.vendor + " " + modelSelected.name + "\n" +
        "Opis: " + modelSelected.description
    }

    this.props.addAsset(assetBody, modelSelected.eqtype);
    this.setState({
      dialogOpenAddAsset: false,
    });
    this.props.clearEquipmentModelsList();
    //this.props.sendNotification(smsbodyowner);
    this.props.sendNewNotification(notifyBody);
  };

  handleAddAssetModel = (assetModelBody) => {
    this.props.addAssetModel(assetModelBody);
    this.setState({
      dialogOpenAddAssetModel: false,
    });
  };

  handleAddVendor = (vendorBody) => {
    this.props.addVendor(vendorBody);
  };

  triggerAcceptAssetScrap = () => {
    this.setState({
      dialogAcceptAssetScrap: true,
    });
  };

  triggerAssetReturn = () => {
    this.setState({
      dialogAssetReturn: true,
    });
  };

  handleCloseDialogAcceptAssetScrap = () => {
    this.setState({
      dialogAcceptAssetScrap: false,
      returnAssetRequestFlag: false,
      chargeEmployeeFlag: false,
      chargeValue: "",
    });
  };

  handleCloseDialogAssetReturn = () => {
    this.setState({
      dialogAssetReturn: false,
    });
  };

  handleChangeReturnAssetRequestFlag = () => {
    const returnAssetRequestFlag = this.state.returnAssetRequestFlag;
    this.setState({
      returnAssetRequestFlag: !returnAssetRequestFlag,
    });
  };

  handleChangeChargeEmployeeFlag = () => {
    const chargeEmployeeFlag = this.state.chargeEmployeeFlag;
    this.setState({
      chargeEmployeeFlag: !chargeEmployeeFlag,
    });
  };

  handleAssetReturn = () => {
    const scrapagedDate = getFormattedDate(new Date());
    const body = {
      scrapagedDate: scrapagedDate,
      status: "sd",
    };

    /*
    const smsbody = {
      recipient: this.props.assetDetails.owner.phone,
      sender: "WYPOSAZENIE",
      message:
        "Uzytkownik " +
        this.props.auth.user.username +
        " potwierdzil zwrot do magazynu srodka trwalego " +
        this.props.assetDetails.model.name +
        " " +
        this.props.assetDetails.model.description,
      owner: this.props.auth.user.id,
      notificationType: "sms",
      isDelivered: true,
    };
    */

    var tempSerialNumber = "Nie podano";
    var tempPartNumber = "Nie podano";

    if (this.props.assetDetails.serialNumber)
      tempSerialNumber = this.props.assetDetails.serialNumber

    if (this.props.assetDetails.partNumber)
      tempPartNumber = this.props.assetDetails.partNumber

    const notifyBody = {
      recipient: this.props.assetDetails.owner.email,
      module: "Środki Trwałe",
      topic: "Potwierdzenie otrzymania zwrotu",
      message:
        "Użytkownik " + this.props.auth.user.first_name + " " + this.props.auth.user.last_name +
        " potwierdził zwrot do magazynu środka trwałego: \n" +
        "Model: " + this.props.assetDetails.model.name + "\n" +
        "Opis: " + this.props.assetDetails.model.description + "\n" +
        "Numer seryjny: " + tempSerialNumber + "\n" +
        "Numer produktu: " + tempPartNumber + "\n"
    }

    this.props.scrapEmployeeAsset(this.props.assetDetails.pk, body, "sd");

    //this.props.sendNotification(smsbody);
    this.props.sendNewNotification(notifyBody);

    this.setState({
      dialogAssetReturn: false,
    });
  };

  handleAcceptAssetScrap = () => {
    const returnAssetRequestFlag = this.state.returnAssetRequestFlag;
    const chargeEmployeeFlag = this.state.chargeEmployeeFlag;
    const chargeValue = this.state.chargeValue;
    const scrapageAcceptDate = getFormattedDate(new Date());
    const scrapagedDate = getFormattedDate(new Date());
    const scrapageManagerComment = this.state.scrapageManagerComment;
    //const chargeValueErrorFlag = this.state.chargeValueErrorFlag;
    //const scrapageManagerCommentErrorFlag = this.state
    //  .scrapageManagerCommentErrorFlag;

    //I. nie zwraca
    if (!returnAssetRequestFlag && !chargeEmployeeFlag) {
      const body = {
        scrapageAcceptDate: scrapageAcceptDate,
        scrapagedDate: scrapagedDate,
        status: "sd",
        scrapageChargeStatus: "nr",
      };

      /*
      const smsbody1 = {
        recipient: this.props.assetDetails.owner.phone,
        sender: "WYPOSAZENIE",
        message:
          "Uzytkownik " +
          this.props.auth.user.username +
          " zaakceptowal zlomowanie srodka trwalego " +
          this.props.assetDetails.model.name +
          " " +
          this.props.assetDetails.model.description +
          ". INFO: Zwrot do magazynu nie jest wymagany.",
        owner: this.props.auth.user.id,
        notificationType: "sms",
        isDelivered: true,
      };
      */

      var tempSerialNumber = "Nie podano";
      var tempPartNumber = "Nie podano";

      if (this.props.assetDetails.serialNumber)
        tempSerialNumber = this.props.assetDetails.serialNumber

      if (this.props.assetDetails.partNumber)
        tempPartNumber = this.props.assetDetails.partNumber

      const notifyBody = {
        recipient: this.props.assetDetails.owner.email,
        module: "Środki Trwałe",
        topic: "Złomowanie sprzętu",
        message:
          "Użytkownik " + this.props.auth.user.first_name + " " + this.props.auth.user.last_name +
          " zaakceptował złomowanie środka trwałego: \n" +
          "Model: " + this.props.assetDetails.model.name + "\n" +
          "Opis: " + this.props.assetDetails.model.description + "\n" +
          "Numer seryjny: " + tempSerialNumber + "\n" +
          "Numer produktu: " + tempPartNumber + "\n" +
          "INFO! Zwrot do magazynu nie jest wymagany."
      }

      this.props.scrapEmployeeAsset(this.props.assetDetails.pk, body, "sd");

      //this.props.sendNotification(smsbody1);
      this.props.sendNewNotification(notifyBody);

      this.setState({
        dialogAcceptAssetScrap: false,
        returnAssetRequestFlag: false,
        chargeEmployeeFlag: false,
        chargeValue: "",
        chargeValueErrorFlag: false,
        scrapageManagerComment: "",
        scrapageManagerCommentErrorFlag: false,
      });
    }
    //II. zwraca i nie jest obciążony
    else if (returnAssetRequestFlag && !chargeEmployeeFlag) {
      const body = {
        scrapageAcceptDate: scrapageAcceptDate,
        status: "wr",
        scrapageChargeStatus: "nr",
      };

      /*
      const smsbody2 = {
        recipient: this.props.assetDetails.owner.phone,
        sender: "WYPOSAZENIE",
        message:
          "Uzytkownik " +
          this.props.auth.user.username +
          " zaakceptowal zlomowanie srodka trwalego " +
          this.props.assetDetails.model.name +
          " " +
          this.props.assetDetails.model.description +
          ". UWAGA: Zwoc srodek trwaly do magazynu. Masz na to 60 dni!",
        owner: this.props.auth.user.id,
        notificationType: "sms",
        isDelivered: true,
      };
      */

      var tempSerialNumber = "Nie podano";
      var tempPartNumber = "Nie podano";

      if (this.props.assetDetails.serialNumber)
        tempSerialNumber = this.props.assetDetails.serialNumber

      if (this.props.assetDetails.partNumber)
        tempPartNumber = this.props.assetDetails.partNumber

      const notifyBody = {
        recipient: this.props.assetDetails.owner.email,
        module: "Środki Trwałe",
        topic: "Złomowanie sprzętu",
        message:
          "Użytkownik " + this.props.auth.user.first_name + " " + this.props.auth.user.last_name +
          " zaakceptował złomowanie środka trwałego: \n" +
          "Model: " + this.props.assetDetails.model.name + "\n" +
          "Opis: " + this.props.assetDetails.model.description + "\n" +
          "Numer seryjny: " + tempSerialNumber + "\n" +
          "Numer produktu: " + tempPartNumber + "\n" +
          "UWAGA! Zwróć środek trwały do magazynu. Masz na to 60 dni!"
      }

      this.props.scrapEmployeeAsset(this.props.assetDetails.pk, body, "wr");

      //this.props.sendNotification(smsbody2);
      this.props.sendNewNotification(notifyBody);

      this.setState({
        dialogAcceptAssetScrap: false,
        returnAssetRequestFlag: false,
        chargeEmployeeFlag: false,
        chargeValue: "",
        chargeValueErrorFlag: false,
        scrapageManagerComment: "",
        scrapageManagerCommentErrorFlag: false,
      });
    }

    //III. nie zwraca i obciążyć
    else if (!returnAssetRequestFlag && chargeEmployeeFlag) {
      const chargeValueErrorFlag = this.sprawdzCzyWypelniono(
        "chargeValue",
        chargeValue
      );
      const scrapageManagerCommentErrorFlag = this.sprawdzCzyWypelniono(
        "scrapageManagerComment",
        scrapageManagerComment
      );

      if (!chargeValueErrorFlag && !scrapageManagerCommentErrorFlag) {
        const body = {
          scrapageAcceptDate: scrapageAcceptDate,
          scrapagedDate: scrapagedDate,
          status: "sd",
          scrapageChargeValue: chargeValue,
          scrapageChargeStatus: "nc",
          scrapageManagerComment: scrapageManagerComment,
        };

        /*
        const smsbody3 = {
          recipient: this.props.assetDetails.owner.phone,
          sender: "WYPOSAZENIE",
          message:
            "Uzytkownik " +
            this.props.auth.user.username +
            " zaakceptowal zlomowanie srodka trwalego " +
            this.props.assetDetails.model.name +
            " " +
            this.props.assetDetails.model.description +
            ". INFO: Zwrot do magazynu nie jest wymagany. UWAGA: Zostaniesz obciazony kwota " +
            chargeValue +
            " PLN.",
          owner: this.props.auth.user.id,
          notificationType: "sms",
          isDelivered: true,
        };
        */

        var tempSerialNumber = "Nie podano";
        var tempPartNumber = "Nie podano";

        if (this.props.assetDetails.serialNumber)
          tempSerialNumber = this.props.assetDetails.serialNumber

        if (this.props.assetDetails.partNumber)
          tempPartNumber = this.props.assetDetails.partNumber

        const notifyBody = {
          recipient: this.props.assetDetails.owner.email,
          module: "Środki Trwałe",
          topic: "Złomowanie sprzętu",
          message:
            "Użytkownik " + this.props.auth.user.first_name + " " + this.props.auth.user.last_name +
            " zaakceptował złomowanie środka trwałego: \n" +
            "Model: " + this.props.assetDetails.model.name + "\n" +
            "Opis: " + this.props.assetDetails.model.description + "\n" +
            "Numer seryjny: " + tempSerialNumber + "\n" +
            "Numer produktu: " + tempPartNumber + "\n" +
            "INFO! Zwrot do magazynu nie jest wymagany. \n" +
            "UWAGA! Zostałeś obciążony kwotą " + chargeValue + " PLN.",
        }

        this.props.scrapEmployeeAsset(this.props.assetDetails.pk, body, "sd");

        //this.props.sendNotification(smsbody3);
        this.props.sendNewNotification(notifyBody);

        //console.log("OBCIAZAM!");
        //const bla = modifyPdf(deductconfirmationreport);

        this.setState({
          dialogAcceptAssetScrap: false,
          returnAssetRequestFlag: false,
          chargeEmployeeFlag: false,
          chargeValue: "",
          chargeValueErrorFlag: false,
          scrapageManagerComment: "",
          scrapageManagerCommentErrorFlag: false,
        });
      }
    }
    //IV. zwraca i obciążyć
    else if (returnAssetRequestFlag && chargeEmployeeFlag) {
      const chargeValueErrorFlag = this.sprawdzCzyWypelniono(
        "chargeValue",
        chargeValue
      );
      const scrapageManagerCommentErrorFlag = this.sprawdzCzyWypelniono(
        "scrapageManagerComment",
        scrapageManagerComment
      );

      if (!chargeValueErrorFlag && !scrapageManagerCommentErrorFlag) {
        const body = {
          scrapageAcceptDate: scrapageAcceptDate,
          status: "wr",
          scrapageChargeValue: chargeValue,
          scrapageChargeStatus: "nc",
          scrapageManagerComment: scrapageManagerComment,
        };

        /*
        const smsbody4 = {
          recipient: this.props.assetDetails.owner.phone,
          sender: "WYPOSAZENIE",
          message:
            "Uzytkownik " +
            this.props.auth.user.username +
            " zaakceptowal zlomowanie srodka trwalego " +
            this.props.assetDetails.model.name +
            " " +
            this.props.assetDetails.model.description +
            ". UWAGA: Zwoc srodek trwaly do magazynu. Masz na to 60 dni! Zostaniesz obciazony kwota " +
            chargeValue +
            " PLN.",
          owner: this.props.auth.user.id,
          notificationType: "sms",
          isDelivered: true,
        };
        */

        var tempSerialNumber = "Nie podano";
        var tempPartNumber = "Nie podano";

        if (this.props.assetDetails.serialNumber)
          tempSerialNumber = this.props.assetDetails.serialNumber

        if (this.props.assetDetails.partNumber)
          tempPartNumber = this.props.assetDetails.partNumber

        const notifyBody = {
          recipient: this.props.assetDetails.owner.email,
          module: "Środki Trwałe",
          topic: "Złomowanie sprzętu",
          message:
            "Użytkownik " + this.props.auth.user.first_name + " " + this.props.auth.user.last_name +
            " zaakceptował złomowanie środka trwałego: \n" +
            "Model: " + this.props.assetDetails.model.name + "\n" +
            "Opis: " + this.props.assetDetails.model.description + "\n" +
            "Numer seryjny: " + tempSerialNumber + "\n" +
            "Numer produktu: " + tempPartNumber + "\n" +
            "UWAGA! Zwróć środek trwały do magazynu. Masz na to 60 dni! \n" +
            "UWAGA! Zostałeś obciążony kwotą " + chargeValue + " PLN.",
        }

        this.props.scrapEmployeeAsset(this.props.assetDetails.pk, body, "wr");

        //this.props.sendNotification(smsbody4);
        this.props.sendNewNotification(notifyBody);

        this.setState({
          dialogAcceptAssetScrap: false,
          returnAssetRequestFlag: false,
          chargeEmployeeFlag: false,
          chargeValue: "",
          chargeValueErrorFlag: false,
          scrapageManagerComment: "",
          scrapageManagerCommentErrorFlag: false,
        });
      }
    }
  };

  handleClearEmployee = () => {
    this.setState({
      //eqTypeSelected: null,
      checkedAssetPk: null,
      isAssetChecked: false,
    });
  };

  triggerTransferAsset = (event) => {
    this.setState({
      dialogOpenAssetTransfer: true,
    });
    //this.props.getMyAssetsDetails(this.state.checkedAssetPk);
  };

  handleCloseDialogAssetTransfer = () => {
    this.setState({
      dialogOpenAssetTransfer: false,
    });
  };

  setFilter = (filtername) => {
    this.setState({
      activeFilter: filtername,
    });

    if (
      this.state.isAssetChecked &&
      this.state.activeListType !== "AssetListManager"
    ) {
      this.setState({
        checkedAssetPk: null,
        isAssetChecked: false,
        activeListType: null,
      });
    }
  };

  triggerMoveToWarehouse = () => {
    this.setState({
      dialogMoveToWarehouse: true,
    });
  };

  handleCloseDialogMoveToWarehouse = () => {
    this.setState({
      dialogMoveToWarehouse: false,
    });
  };

  render() {
    const {
      eqTypeSelected,
      isAssetChecked,
      alertOpen,
      dialogOpen,
      dialogOpenAddAsset,
      dialogOpenAddAssetModel,
      dialogOpenAssetTransfer,
      dialogMoveToWarehouse,
      activeListType,
      activeFilter,
      dialogAcceptAssetScrap,
      dialogAssetReturn,
      returnAssetRequestFlag,
      chargeEmployeeFlag,
      chargeValue,
      chargeValueErrorFlag,
      chargeValueErrorMessage,
      scrapageManagerComment,
      scrapageManagerCommentErrorFlag,
      scrapageManagerCommentErrorMessage,
    } = this.state;
    const {
      classes,
      employeeAssetsAl,
      employeeAssetsNa,
      employeeAssetsEl,
      employeeAssetsIt,
      employeeAssetsPo,
      queryInProgress,
      assetDetails,
    } = this.props;

    const {
      equipment_employee_cards_manager,
      equipment_employee_cards_employee,
      equipment_employee_cards_scrap,
      equipment_employee_cards_warehouse,
      equipment_employee_cards_find,
      equipment_employee_add_asset,
      equipment_employee_add_assetmodel,
      equipment_employee_moveto_employee,
      equipment_employee_moveto_warehouse,
      equipment_employee_view_asset,
      equipment_employee_cards_manager_accept,
      equipment_employee_cards_manager_decline,
      equipment_employee_cards_scrap_accept,
      equipment_employee_cards_warehouse_accept,
    } = this.props.auth.permissions;

    const isSuperUser = this.props.auth.user.is_superuser;

    var employeeAssets = [];
    //var employeeAssetTransfer = null;

    if (eqTypeSelected === "al") {
      employeeAssets = employeeAssetsAl;
    } else if (eqTypeSelected === "na") {
      employeeAssets = employeeAssetsNa;
    } else if (eqTypeSelected === "el") {
      employeeAssets = employeeAssetsEl;
    } else if (eqTypeSelected === "it") {
      employeeAssets = employeeAssetsIt;
    } else if (eqTypeSelected === "po") {
      employeeAssets = employeeAssetsPo;
    } else if (eqTypeSelected === "ws") {
      employeeAssets = employeeAssetsAl.concat(
        employeeAssetsNa,
        employeeAssetsEl,
        employeeAssetsIt,
        employeeAssetsPo
      );
    }

    const alert = (
      <Snackbar
        open={alertOpen}
        autoHideDuration={6000}
        onClose={this.handleAlertClose}
      >
        <Alert variant="filled" severity="info">
          Brak danych do zaimportowania!
        </Alert>
      </Snackbar>
    );

    //inicjalizacja dialogdetails
    var dialogassetdetails = null;
    if (dialogOpen === true) {
      dialogassetdetails = (
        <DialogAssetDetails
          assetDetails={assetDetails}
          dialogOpen={dialogOpen}
          closeDialog={this.handleClickCloseDialog}
        />
      );
    }

    const assetFilterList = (
      <AssetFilterList
        checked={this.handleChecked}
        activeListType={activeListType}
        activeFilter={activeFilter}
      />
    );

    const assetFindList = (
      <AssetFindList
        checked={this.handleChecked}
        activeListType={activeListType}
        activeFilter={activeFilter}
      />
    );

    const assetScrapFilterList = (
      <AssetScrapFilterList
        checked={this.handleChecked}
        activeListType={activeListType}
        activeFilter={activeFilter}
      />
    );

    const assettransferacceptButtonDisabled = (
      <Fab
        disabled
        color="inherit"
        className={classes.fabgreen}
        size="small"
        aria-label="scroll back to top"
      >
        <DoneIcon />
      </Fab>
    );

    const assettransferacceptButtonEnabled = (
      <Fab
        onClick={this.handleAcceptAssetTransfer}
        color="inherit"
        className={classes.fabgreen}
        size="small"
        aria-label="scroll back to top"
      >
        <DoneIcon />
      </Fab>
    );

    const assetscrapacceptButtonDisabled = (
      <Fab
        disabled
        color="inherit"
        className={classes.fabgreen}
        size="small"
        aria-label="scroll back to top"
      >
        <DoneIcon />
      </Fab>
    );

    const assetscrapacceptButtonEnabled = (
      <Fab
        onClick={this.triggerAcceptAssetScrap}
        color="inherit"
        className={classes.fabgreen}
        size="small"
        aria-label="scroll back to top"
      >
        <DoneIcon />
      </Fab>
    );

    const assetreturnacceptButtonDisabled = (
      <Fab
        disabled
        color="inherit"
        className={classes.fabgreen}
        size="small"
        aria-label="scroll back to top"
      >
        <DoneIcon />
      </Fab>
    );

    const assetreturnacceptButtonEnabled = (
      <Fab
        onClick={this.triggerAssetReturn}
        color="inherit"
        className={classes.fabgreen}
        size="small"
        aria-label="scroll back to top"
      >
        <DoneIcon />
      </Fab>
    );

    const transferDiscardButtonDisabled = (
      <Fab
        disabled
        color="inherit"
        className={classes.fabred}
        size="small"
        aria-label="scroll back to top"
      >
        <CloseIcon />
      </Fab>
    );

    const transferDiscardButtonEnabled = (
      <Fab
        onClick={this.handleDiscardAssetTransfer}
        color="inherit"
        className={classes.fabred}
        size="small"
        aria-label="scroll back to top"
      >
        <CloseIcon />
      </Fab>
    );

    const previewButtonDi = (
      <Fab
        disabled
        color="inherit"
        className={classes.fabblue}
        size="small"
        aria-label="scroll back to top"
      >
        <VisibilityIcon />
      </Fab>
    );

    const previewButtonEn = (
      <Fab
        onClick={this.handleClickPreview}
        color="inherit"
        className={classes.fabblue}
        size="small"
        aria-label="scroll back to top"
      >
        <VisibilityIcon />
      </Fab>
    );

    const addnewassetmodelButtonDi = (
      <Fab
        disabled
        color="inherit"
        className={classes.fabgreen}
        size="small"
        aria-label="scroll back to top"
      >
        <PlaylistAddSharpIcon />
      </Fab>
    );

    const addnewassetmodelButtonEn = (
      <Fab
        onClick={this.triggerAddNewAssetModel}
        color="inherit"
        className={classes.fabgreen}
        size="small"
        aria-label="scroll back to top"
      >
        <PlaylistAddSharpIcon />
      </Fab>
    );

    const addnewassetButtonDi = (
      <Fab
        disabled
        color="inherit"
        className={classes.fabgreen}
        size="small"
        aria-label="scroll back to top"
      >
        <AddIcon />
      </Fab>
    );

    const addnewassetButtonEn = (
      <Fab
        onClick={this.triggerAddNewAsset}
        color="inherit"
        className={classes.fabgreen}
        size="small"
        aria-label="scroll back to top"
      >
        <AddIcon />
      </Fab>
    );

    const scrapassetButtonDi = (
      <Fab
        disabled
        color="inherit"
        className={classes.fabred}
        size="small"
        aria-label="scroll back to top"
      >
        <DeleteIcon />
      </Fab>
    );

    const scrapassetButtonEn = (
      <Fab
        onClick={this.triggerAcceptAssetScrap}
        color="inherit"
        className={classes.fabred}
        size="small"
        aria-label="scroll back to top"
      >
        <DeleteIcon />
      </Fab>
    );

    const transferAssetDi = (
      <Fab
        disabled
        color="inherit"
        className={classes.fabgreen}
        size="small"
        aria-label="scroll back to top"
      >
        <SupervisorAccountIcon />
      </Fab>
    );

    const transferAssetEn = (
      <Fab
        onClick={this.triggerTransferAsset}
        color="inherit"
        className={classes.fabgreen}
        size="small"
        aria-label="scroll back to top"
      >
        <SupervisorAccountIcon />
      </Fab>
    );

    const moveToWarehouseDi = (
      <Fab
        disabled
        color="inherit"
        className={classes.fabgreen}
        size="small"
        aria-label="scroll back to top"
      >
        <HomeWorkIcon />
      </Fab>
    );

    const moveToWarehouseEn = (
      <Fab
        onClick={this.triggerMoveToWarehouse}
        color="inherit"
        className={classes.fabgreen}
        size="small"
        aria-label="scroll back to top"
      >
        <HomeWorkIcon />
      </Fab>
    );

    const chargeFieldsDi = (
      <Fragment>
        <TextField
          variant="filled"
          disabled
          id="standard-number"
          placeholder="00,00"
          label="Kwota obciążenia w PLN"
          type="number"
          className={classes.textField}
          value={chargeValue}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <FormHelperText style={{ marginTop: "20px" }}>
          Możesz dodać krótki komentarz, uzasadniający obciążenie pracownika
          (max 30 znaków)
        </FormHelperText>
        <TextField
          disabled
          autoFocus
          margin="dense"
          id="name"
          label="uzasadnienie"
          className={classes.textField}
          inputProps={{ maxLength: 30 }}
          value={scrapageManagerComment || ""}
        />
      </Fragment>
    );

    const chargeFieldsEn = (
      <Fragment>
        <TextField
          error={chargeValueErrorFlag}
          variant="filled"
          id="standard-number"
          placeholder="00,00"
          label="Kwota obciążenia w PLN"
          type="number"
          className={classes.textField}
          value={chargeValue}
          onChange={this.handleTextList("chargeValue")}
          InputLabelProps={{
            shrink: true,
          }}
          helperText={chargeValueErrorMessage}
        />
        <FormHelperText style={{ marginTop: "20px" }}>
          Możesz dodać krótki komentarz, uzasadniający obciążenie pracownika
          (max 30 znaków)
        </FormHelperText>
        <TextField
          error={scrapageManagerCommentErrorFlag}
          autoFocus
          margin="dense"
          id="name"
          label="uzasadnienie"
          className={classes.textField}
          inputProps={{ maxLength: 30 }}
          value={scrapageManagerComment || ""}
          onChange={this.handleTextList("scrapageManagerComment")}
          helperText={scrapageManagerCommentErrorMessage}
        />
      </Fragment>
    );

    const dialogassettransfer = (
      <DialogAssetTransfer
        dialogOpenAssetTransfer={dialogOpenAssetTransfer}
        closeDialogAssetTransfer={this.handleCloseDialogAssetTransfer}
        assetsDetails={this.props.assetDetails}
        mode="u2u"
      />
    );

    const dialogaddassetmodel = (
      <DialogAddAssetModel
        dialogOpenAddAssetModel={dialogOpenAddAssetModel}
        closeDialogAddAssetModel={this.handleCloseDialogAddAssetModel}
      />
    );

    const dialogmovetowarehouse = (
      <DialogMoveToWarehouse
        dialogMoveToWarehouse={dialogMoveToWarehouse}
        closeDialog={this.handleCloseDialogMoveToWarehouse}
        assetsDetails={this.props.assetDetails}
      />
    );

    const acceptcard = (
      <AcceptCard setFilter={this.setFilter} activeFilter={activeFilter} />
    );

    const transfercard = (
      <TransferCard setFilter={this.setFilter} activeFilter={activeFilter} />
    );

    const scrapacceptcard = (
      <ScrapAcceptCard setFilter={this.setFilter} activeFilter={activeFilter} />
    );

    const scrapwaitforreturncard = (
      <ScrapWaitForReturnCard
        setFilter={this.setFilter}
        activeFilter={activeFilter}
      />
    );

    const findcard = (
      <FindCard setFilter={this.setFilter} activeFilter={activeFilter} />
    );

    return (
      <Fragment>
        <div className={classes.section}>
          <Box m={2}>
            <Grid container justify="flex-start" spacing={2}>
              {equipment_employee_cards_manager || isSuperUser ? acceptcard : ""}
              {equipment_employee_cards_employee || isSuperUser ? transfercard : ""}
              {equipment_employee_cards_scrap || isSuperUser ? scrapacceptcard : ""}
              {equipment_employee_cards_warehouse || isSuperUser ? scrapwaitforreturncard : ""}
              {equipment_employee_cards_find || isSuperUser ? findcard : ""}
              {activeFilter === "acceptfilter" ||
                activeFilter === "transferfilter"
                ? assetFilterList
                : ""}
              {activeFilter === "scrapfilter" || activeFilter === "returnfilter"
                ? assetScrapFilterList
                : ""}
              {activeFilter === "findfilter" ? assetFindList : ""}
            </Grid>
            <Typography className={classes.cardsGroup}>
              Wyszukaj Środki Trwałe pracownika
            </Typography>
            <Navigation
              eqTypeSelected={this.handleEqTypeSelected}
              clearEmployeeFilter={this.handleClearEmployee}
            />
            <div style={{ marginTop: "30px" }}></div>
            <AssetList
              assets={employeeAssets}
              checked={this.handleChecked}
              downloadTrigger={this.handleDownload}
              eqTypeSelected={eqTypeSelected}
              activeListType={activeListType}
            />
          </Box>
        </div>
        {
          //common ACTIONS
        }
        <Zoom in={isAssetChecked}>
          <div role="presentation" className={classes.fb1}>
            <Tooltip title="Pokaż szczegóły" placement="left">
              {!queryInProgress && (equipment_employee_view_asset || isSuperUser)
                ? previewButtonEn
                : previewButtonDi}
            </Tooltip>
          </div>
        </Zoom>
        <Zoom in={isAssetChecked}>
          <div role="presentation" className={classes.fb6}>
            <Tooltip title="Zezłomuj środek trwały" placement="left">
              {!queryInProgress && (equipment_employee_cards_scrap_accept || isSuperUser)
                ? scrapassetButtonEn
                : scrapassetButtonDi}
            </Tooltip>
          </div>
        </Zoom>
        {
          //Transfer asset ACTIONS
        }
        <Zoom
          in={
            isAssetChecked &&
            activeListType === "AssetFilterList" &&
            activeFilter === "acceptfilter"
          }
        >
          <div role="presentation" className={classes.fb4}>
            <Tooltip title="Odrzuć przeniesienie" placement="left">
              {!queryInProgress && (equipment_employee_cards_manager_decline || isSuperUser)
                ? transferDiscardButtonEnabled
                : transferDiscardButtonDisabled}
            </Tooltip>
          </div>
        </Zoom>
        <Zoom
          in={
            isAssetChecked &&
            activeListType === "AssetFilterList" &&
            activeFilter === "acceptfilter"
          }
        >
          <div role="presentation" className={classes.fb3}>
            <Tooltip title="Zaakceptuj przeniesienie" placement="left">
              {!queryInProgress && (equipment_employee_cards_manager_accept || isSuperUser)
                ? assettransferacceptButtonEnabled
                : assettransferacceptButtonDisabled}
            </Tooltip>
          </div>
        </Zoom>
        <Zoom in={isAssetChecked && this.props.assetDetails.status === "iu"}>
          <div role="presentation" className={classes.fb3}>
            <Tooltip title="Przekaż" placement="left">
              {!queryInProgress && (equipment_employee_moveto_employee || isSuperUser)
                ? transferAssetEn
                : transferAssetDi}
            </Tooltip>
          </div>
        </Zoom>
        <Zoom in={isAssetChecked && this.props.assetDetails.status === "iu"}>
          <div role="presentation" className={classes.fb4}>
            <Tooltip title="Przesuń do magazynu" placement="left">
              {!queryInProgress && (equipment_employee_moveto_warehouse || isSuperUser)
                ? moveToWarehouseEn
                : moveToWarehouseDi}
            </Tooltip>
          </div>
        </Zoom>
        {
          //Scrap asset ACTIONS
        }
        <Zoom
          in={
            isAssetChecked &&
            activeListType === "AssetScrapFilterList" &&
            activeFilter === "scrapfilter"
          }
        >
          <div role="presentation" className={classes.fb3}>
            <Tooltip title="Zaakceptuj złomowanie" placement="left">
              {!queryInProgress && (equipment_employee_cards_scrap_accept || isSuperUser)
                ? assetscrapacceptButtonEnabled
                : assetscrapacceptButtonDisabled}
            </Tooltip>
          </div>
        </Zoom>

        <Zoom
          in={
            isAssetChecked &&
            activeListType === "AssetScrapFilterList" &&
            activeFilter === "returnfilter"
          }
        >
          <div role="presentation" className={classes.fb3}>
            <Tooltip title="Potwierdź zwrot" placement="left">
              {!queryInProgress && (equipment_employee_cards_warehouse_accept || isSuperUser)
                ? assetreturnacceptButtonEnabled
                : assetreturnacceptButtonDisabled}
            </Tooltip>
          </div>
        </Zoom>

        {
          //new asset/model ACTIONS
        }
        <Zoom in={true}>
          <div role="presentation" className={classes.fb5}>
            <Tooltip title="Dodaj model środka trwałego" placement="left">
              {equipment_employee_add_assetmodel || isSuperUser
                ? addnewassetmodelButtonEn
                : addnewassetmodelButtonDi}
            </Tooltip>
          </div>
        </Zoom>
        <Zoom in={true}>
          <div role="presentation" className={classes.fb2}>
            <Tooltip title="Dodaj środek trwały" placement="left">
              {equipment_employee_add_asset || isSuperUser
                ? addnewassetButtonEn
                : addnewassetButtonDi}
            </Tooltip>
          </div>
        </Zoom>
        {alert}

        {dialogassetdetails}

        <Dialog
          fullScreen
          open={dialogOpenAddAsset}
          onClose={this.handleCloseDialogAddAsset}
          aria-labelledby="form-dialog-title"
        >
          <AppBar position="static" style={{ background: "#0071BC" }}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={this.handleCloseDialogAddAsset}
                aria-label="close"
                className={classes.menuButton}
              >
                <CloseIcon />
              </IconButton>
              <Typography variant="h6" className={classes.title}>
                Dodaj nowy Środek Trwały
              </Typography>
            </Toolbar>
          </AppBar>
          <DialogContent style={{ background: "#f5f5f5" }}>
            <AddAssetForm
              //assets={employeeAssets}
              addAsset={this.handleAddAsset}
            />
          </DialogContent>
        </Dialog>

        {dialogaddassetmodel}

        <Dialog
          open={dialogAcceptAssetScrap}
          onClose={this.handleCloseDialogAcceptAssetScrap}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Zaakceptuj wniosek"}
          </DialogTitle>
          <DialogContent>
            <FormHelperText style={{ marginTop: "20px" }}>
              Zaznacz opcję "Zwrot do magazynu", jeżeli pracownik ma zwrócić
              złomowany sprzęt."
            </FormHelperText>
            <FormControlLabel
              control={
                <Switch
                  checked={returnAssetRequestFlag}
                  onChange={this.handleChangeReturnAssetRequestFlag}
                  name="checkedA"
                />
              }
              label="Zwrot do magazynu"
            />
            <FormHelperText style={{ marginTop: "20px" }}>
              Zaznacz opcję "Obciążyć konto pracownika", jeżeli pracownik ma
              zostać obciążony kosztami złomowanego sprzętu. Jeżeli zaznaczysz
              tą opcję uzupełnij również pole "Wartość obciążenia" wpisując tam
              kwotę jaką ma zostać obciążone konto pracownika.
            </FormHelperText>
            <FormControlLabel
              control={
                <Switch
                  checked={chargeEmployeeFlag}
                  onChange={this.handleChangeChargeEmployeeFlag}
                  name="checkedA"
                />
              }
              label="Obciążyć konto pracownika"
            />
            <br></br>
            <ThemeProvider theme={materialThemeNext}>
              {chargeEmployeeFlag ? chargeFieldsEn : chargeFieldsDi}
            </ThemeProvider>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={this.handleCloseDialogAcceptAssetScrap}
              color="default"
              size="large"
            >
              Anuluj
            </Button>
            <Button
              onClick={this.handleAcceptAssetScrap}
              color="inherit"
              variant="contained"
              autoFocus
              size="large"
              className={classes.buttonAccept}
            >
              Zaakceptuj
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={dialogAssetReturn}
          onClose={this.handleCloseDialogAssetReturn}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Potwierdź zwrot"}</DialogTitle>
          <DialogContent>
            <FormHelperText>
              Klikając przycisk "Zaakceptuj", potwierdzasz zwrot Środka Trwałego
              przez pracownika.
            </FormHelperText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={this.handleCloseDialogAssetReturn}
              color="default"
              size="large"
            >
              Anuluj
            </Button>
            <Button
              onClick={this.handleAssetReturn}
              color="inherit"
              variant="contained"
              autoFocus
              size="large"
              className={classes.buttonAccept}
            >
              Zaakceptuj
            </Button>
          </DialogActions>
        </Dialog>

        {dialogOpenAssetTransfer ? dialogassettransfer : ""}
        {dialogMoveToWarehouse ? dialogmovetowarehouse : ""}
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  employeeAssetsAl: state.assets.employeeAssetsAl,
  employeeAssetsNa: state.assets.employeeAssetsNa,
  employeeAssetsEl: state.assets.employeeAssetsEl,
  employeeAssetsIt: state.assets.employeeAssetsIt,
  employeeAssetsPo: state.assets.employeeAssetsPo,
  assetDetails: state.assets.assetDetails,
  queryInProgress: state.assets.queryInProgress,
  auth: state.auth,
  usersList: state.common.users,
  userDetails: state.common.suser,
  queryInProgressCommon: state.common.queryInProgress,
});

export default connect(
  mapStateToProps,
  {
    getEmployeeAssets,
    clearUserDetails,
    getAssetDetails,
    clearEmployeeAssetsState,
    getEmployeeAssetsByStatus,
    clearEmployeeAssetsByStatus,
    patchAssetTransferLog,
    scrapEmployeeAsset,
    patchEmployeeAsset,
    sendNotification,
    sendNewNotification,
    addAsset,
    clearEquipmentModelsList,
    addAssetModel,
    addVendor,
  }
)(withStyles(styles, { withTheme: true })(MainManagerView));
