import React from "react";

export function getWeekdaysInMonth(date) {
  var t = new Date(date);
  var m = date.getMonth();
  var d = date.getDate();
  var daysPast = 0, daysToGo = 0;
  var day;

  while (t.getMonth() == m) {
    day = t.getDay();
    daysPast += (day == 0 || day == 6) ? 0 : 1;
    t.setDate(--d);
  }

  t = new Date(date);
  t.setDate(t.getDate() + 1);
  d = t.getDate();

  while (t.getMonth() == m) {
    day = t.getDay();
    daysToGo += (day == 0 || day == 6) ? 0 : 1;
    t.setDate(++d);
  }
  return daysPast + daysToGo;
}