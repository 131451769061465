import { correctDateFormat } from "../Common/correctDateFormat";

export function generate(doc, info) {
  doc.setProperties({
    title: 'Oświadczenie o dochodach',
    author: 'Radio Line',
    creator: 'Radio Line EXTRANET',
  });

  var signature = correctDateFormat(info.date) + " " + info.firstName + " " + info.lastName;

  var xOffset = doc.internal.pageSize.width / 2;
  var xSignature = doc.internal.pageSize.width / 5 * 3;
  var xStart = 20;
  var xEnd = 20;
  var yOffset = 25;
  doc.setLineWidth(0.25);

  //Clearing
  doc.deletePage(1);
  doc.deletePage(1);
  doc.addPage();

  function genLine(lineStart, lineHeight, lineLength) {
    doc.setLineDashPattern([0.25, 0.5], 0);
    doc.line(lineStart, lineHeight, lineStart + lineLength, lineHeight);
    doc.setLineDashPattern([], 0);
  }

  function genLineText2(text) {
    var lineEnd = doc.getTextWidth(text) + 10;
    doc.setLineDashPattern([0.25, 0.5], 0);
    doc.line(xStart, yOffset + 0.5, lineEnd, yOffset + 0.5);
    doc.setLineDashPattern([], 0);
  }

  function genSignature() {
    var lineLength = 60;
    var signatureOffset = xSignature + (lineLength - doc.getTextWidth(signature)) / 2;
    doc.text(signature, signatureOffset, yOffset);
    yOffset += 4;
    doc.setFontSize(8);
    signatureOffset = xSignature + (lineLength - doc.getTextWidth("Autoryzowano kodem")) / 2;
    doc.text("Autoryzowano kodem", signatureOffset, yOffset);
    doc.setFontSize(11);
    yOffset += 2;
    genLine(xSignature, yOffset, lineLength);
    yOffset += 5;
    signatureOffset = xSignature + (lineLength - doc.getTextWidth("(data i podpis wnioskodawcy)")) / 2;
    doc.text("(data i podpis wnioskodawcy)", signatureOffset, yOffset);
    yOffset += 30;
  }

  //OŚWIADCZENIE
  doc.setFontSize(11);
  doc.setFont('DejaVuSansCondensed', 'bold');
  doc.text("Oświadczenie", xOffset, yOffset, 'center');
  yOffset += 6;
  doc.text("w sprawie przeciętnego miesięcznego dochodu brutto przypadającego na", xOffset, yOffset, 'center');
  yOffset += 6;
  doc.text("jedną osobę w gospodarstwie domowym w roku kalendarzowym " + info.year + ", ", xOffset, yOffset, 'center');
  yOffset += 6;
  doc.text("składane w celu ubiegania się o świadczenia z Zakładowego Funduszu", xOffset, yOffset, 'center');
  yOffset += 6;
  doc.text("Świadczeń Socjalnych w Radio Line Sp. z o.o.", xOffset, yOffset, 'center');
  yOffset += 15;

  doc.setFont('DejaVuSansCondensed', 'normal');
  doc.text(info.firstName + " " + info.lastName, xStart, yOffset);
  genLineText2("(imię i nazwisko wnioskodawcy)");
  yOffset += 5;
  doc.setFontSize(9);
  doc.text("(imię i nazwisko wnioskodawcy)", xStart, yOffset);
  yOffset += 10;
  doc.text("Oświadczam, że:", xStart, yOffset);
  xStart += 5;
  yOffset += 5;
  doc.text("1. mój dochód brutto w roku kalendarzowym " + info.year + " na 1 osobę w gospodarstwie domowym wynosi: " + info.fin + " zł", xStart, yOffset);
  yOffset += 7;
  doc.text("2. liczba osób w moim gospodarstwie domowym (wspólnie zamieszkujących i wspólnie się utrzymujących)", xStart, yOffset);
  yOffset += 5;
  xStart += doc.getTextWidth("2. ");
  doc.text("wynosi: " + info.gospCount, xStart, yOffset);
  xStart -= doc.getTextWidth("2. ");
  yOffset += 7;
  doc.text("3. W przypadku złożenia nieprawdziwego oświadczenia zobowiązuję się do zwrotu bezpodstawnie", xStart, yOffset);
  xStart += doc.getTextWidth("3. ");
  yOffset += 5;
  doc.text("uzyskanych świadczeń w terminie 1 miesiąca od dnia wezwania przez Pracodawcę. Przyjmuję także do", xStart, yOffset);
  yOffset += 5;
  doc.text("wiadomości, iż w takim przypadku tracę uprawnienie do ubiegania się o świadczenia z ZFŚS przez okres", xStart, yOffset);
  yOffset += 5;
  doc.text("kolejnych 12 miesięcy kalendarzowych.", xStart, yOffset);
  xStart = 25;
  yOffset += 15;

  doc.setFontSize(11);
  genSignature();
  yOffset += 10;

  doc.setFontSize(8);
  doc.text("Potwierdzam, że oświadczenie jest dokumentem w rozumieniu art. 245 kodeksu postępowania cywilnego, a prawdziwość danych", xStart, yOffset);
  yOffset += 5;
  doc.text("w nim zawartych została potwierdzona własnoręcznym podpisem i może być weryfikowana w trybie i na warunkach określonych", xStart, yOffset);
  yOffset += 5;
  doc.text("w art. 253 k.p.c.", xStart, yOffset);
  yOffset += 5;
  doc.setLineDashPattern([0.25, 0.5], 0);
  doc.line(xStart, yOffset, doc.internal.pageSize.width - xEnd, yOffset);
  doc.setLineDashPattern([], 0);
  yOffset += 5;
  doc.setFont('DejaVuSansCondensed', 'bold');
  doc.text("Dochód brutto ", xStart, yOffset);
  let temp = doc.getTextWidth("Dochód brutto ");
  doc.setFont('DejaVuSansCondensed', 'normal');
  doc.text("oznacza łączny przychód (za rok poprzedzający rok, w którym składana jest informacja), który jest pomniejszony", xStart + temp, yOffset);
  yOffset += 5;
  doc.text("o koszty uzyskania przychodu, każdego członka rodziny prowadzącego wspólne gospodarstwo domowe i uprawnionego do", xStart, yOffset);
  yOffset += 5;
  doc.text("korzystania z pomocy funduszu. ", xStart, yOffset);
  temp = doc.getTextWidth("korzystania z pomocy funduszu. ");
  doc.setFont('DejaVuSansCondensed', 'bold');
  doc.text("Łączny dochód brutto w rodzinie /12 miesięcy /ilość członków rodziny = średni", xStart + temp, yOffset);
  yOffset += 5;
  doc.text("miesięczny dochód brutto.", xStart, yOffset);
  yOffset += 5;

  doc.setFont('DejaVuSansCondensed', 'normal');
  doc.text("Do dochodu wlicza się wszystkie uzyskane dochody osób zamieszkujących oraz utrzymujących się wspólnie (prowadzących", xStart, yOffset);
  yOffset += 5;
  doc.text("wspólne gospodarstwo domowe), osiągnięte w roku poprzedzającym złożenie wniosku. ", xStart, yOffset);
  temp = doc.getTextWidth("wspólne gospodarstwo domowe), osiągnięte w roku poprzedzającym złożenie wniosku. ");
  doc.setFont('DejaVuSansCondensed', 'bold');
  doc.text("UWAGA: ", xStart + temp, yOffset);
  temp += doc.getTextWidth("UWAGA: ");
  doc.setFont('DejaVuSansCondensed', 'normal');
  doc.text("Dochód brutto należy ustalić", xStart + temp, yOffset);
  yOffset += 5;
  doc.text("na podstawie zeznania podatkowego PIT za rok poprzedni. Należy uwzględnić również dochody, które zostały rozliczone z", xStart, yOffset);
  yOffset += 5;
  doc.text("fiskusem na zasadzie stałej wysokości ryczałtu oraz karty podatkowej. W przypadku osób osiągających dochody z tytułu pracy", xStart, yOffset);
  yOffset += 5;
  doc.text("na gospodarstwie rolnym a nie zobowiązanych do tego, by wykazywać przychód do opodatkowania PIT bierze się pod uwagę", xStart, yOffset);
  yOffset += 5;
  doc.text("wysokość dochodu z hektara przeliczeniowego.", xStart, yOffset);

  //KLAUZULA RODO
  doc.addPage();
  var yOffset = 25;

  doc.setFontSize(11);
  doc.setFont('DejaVuSansCondensed', 'bold');
  doc.text("Oświadczenie", xOffset, yOffset, 'center');
  yOffset += 15;

  doc.setFont('DejaVuSansCondensed', 'normal');
  doc.setFontSize(9);
  doc.text("Oświadczam, że zaponzałem się i akceptuję treść klauzuli informacyjnej o przetwarzaniu danych osobowych", xStart, yOffset);
  yOffset += 5;
  doc.text("na potrzeby przyznawania świadczeń z Zakładowego Funduszu Świadczeń Socjalnych w Radio Line Sp. z o.o.", xStart, yOffset);
  yOffset += 15;

  doc.setFontSize(11);
  genSignature();
  yOffset += 10;

  /*
  doc.setFontSize(11);
  doc.setFont('DejaVuSansCondensed', 'bold');
  doc.text("Klauzula informacyjna o przetwarzaniu", xOffset, yOffset, 'center');
  yOffset += 6;
  doc.text("danych osobowych na potrzeby przyznawania świadczeń", xOffset, yOffset, 'center');
  yOffset += 6;
  doc.text("z Zakładowego Funduszu Świadczeń Socjalnych", xOffset, yOffset, 'center');
  yOffset += 6;
  doc.text("w Radio Line Sp. z o.o.", xOffset, yOffset, 'center');
  yOffset += 15;

  doc.setFont('DejaVuSansCondensed', 'normal');
  doc.setFontSize(9);
  doc.text("1. Administratorem Pani/Pana osobowych zebranych w związku z ubieganiem się o", xStart, yOffset);
  yOffset += 5;
  xStart += doc.getTextWidth("1. ");
  doc.text("świadczenia w ramach zakładowego funduszu świadczeń socjalnych jest Radio Line", xStart, yOffset);
  yOffset += 5;
  doc.text("spółka z ograniczoną odpowiedzialnością z siedzibą w Poznaniu, nr KRS 0000812999.", xStart, yOffset);
  yOffset += 5;
  xStart -= doc.getTextWidth("1. ");
  doc.text("2. We wszelkich sprawach związanych z przetwarzaniem Pani/Pana danych osobowych", xStart, yOffset);
  yOffset += 5;
  xStart += doc.getTextWidth("2. ");
  doc.text("przez Administratora można kontaktować się pod numerem telefonu 532 779 157,", xStart, yOffset);
  yOffset += 5;
  doc.text("888 726 692; e- mailem: kadry@radioline.com.pl", xStart, yOffset);
  yOffset += 5;
  xStart -= doc.getTextWidth("2. ");
  doc.text("3. Wszelkie Pani/Pana dane osobowe pozyskane w związku z ubieganiem się o", xStart, yOffset);
  yOffset += 5;
  xStart += doc.getTextWidth("3. ");
  doc.text("świadczenia w ramach zakładowego funduszu świadczeń socjalnych przetwarzane", xStart, yOffset);
  yOffset += 5;
  doc.text("będą wyłącznie w celu przyznania ulgowej usługi lub świadczenia lub dopłaty z", xStart, yOffset);
  yOffset += 5;
  doc.text("funduszu i ustalenia ich wysokości.", xStart, yOffset);
  yOffset += 5;
  xStart -= doc.getTextWidth("3. ");
  doc.text("4. Podstawą przetwarzania Pani/Pana danych osobowych jest art. 6 ust. 1 lit. c ", xStart, yOffset);
  yOffset += 5;
  */

  doc.save("oswiadczenie_o_dochodach.pdf");
}