import React from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles(theme => ({
  progress: {
    margin: theme.spacing(2)
  },
  root: {
    flexGrow: 1
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary
  }
}));

const PrivateRoute = ({ component: Component, auth, ...rest }) => (
  <Route
    {...rest}
    render={props => {
      const classes = useStyles;
      if (auth.isLoading) {
        return (
          <React.Fragment>
            <Grid
              style={{ height: "90vh" }}
              display="flex"
              container
              direction="row"
              justify="center"
              alignItems="center"
            >
              <Grid item>
                <CircularProgress
                  className={classes.progress}
                  color="secondary"
                />
              </Grid>
            </Grid>
          </React.Fragment>
        );
      } else if (!auth.isAuthenticated) {
        return <Redirect to="/login" />;
      } else {
        return <Component {...props} />;
      }
    }}
  />
);

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(mapStateToProps)(PrivateRoute);
