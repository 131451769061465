import React, { Fragment, Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import {
  styles,
  materialTheme,
  CssTextField,
} from "../../common/radiolineStyles";
import Grid from "@material-ui/core/Grid";
import Card from "../../components/Card/Card.js";
import CardBody from "../../components/Card/CardBody.js";
import CardHeader from "@material-ui/core/CardHeader";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import ThemeProvider from "@material-ui/styles/ThemeProvider";
import { addMyInventoryDetails } from "../../Redux/actions/assets.js";

export class NewAssetDialog extends Component {
  state = {
    assetType: "",
    assetVendor: "",
    assetModel: "",
    assetAdditionalInfo: "",
  }

  handleTextChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  handleAddAsset = () => {
    const newAsset = {
      inventory: this.props.invId,
      eqType: this.props.invType,
      itemName: this.state.assetType,
      vendor: this.state.assetVendor,
      model: this.state.assetModel,
      description: this.state.assetAdditionalInfo,
    };

    this.props.addMyInventoryDetails(newAsset);
    this.props.closeDialog();
  };

  render() {
    const { classes } = this.props;

    return (
      <Grid container className={classes.root} spacing={2}>
        <Grid p={0} m={0} item xs={12} sm={6} md={4} lg={4}>
          <Card style={{ margin: 0 }}>
            <CardHeader
              title={
                <Typography className={classes.textName2}>
                  Typ*
                </Typography>
              }
            ></CardHeader>
            <CardBody style={{ paddingTop: 0 }}>
              <TextField
                name="assetType"
                variant="outlined"
                fullWidth
                value={this.state.assetType}
                onChange={this.handleTextChange}
                placeholder="np. Wiertarka, Laptop"
              />
            </CardBody>
          </Card>
        </Grid>
        <Grid p={0} m={0} item xs={12} sm={6} md={4} lg={4}>
          <Card style={{ margin: 0 }}>
            <CardHeader
              title={
                <Typography className={classes.textName2}>
                  Producent*
                </Typography>
              }
            ></CardHeader>
            <CardBody style={{ paddingTop: 0 }}>
              <TextField
                name="assetVendor"
                variant="outlined"
                fullWidth
                value={this.state.assetVendor}
                onChange={this.handleTextChange}
                placeholder="np. BOSCH, DELL"
              />
            </CardBody>
          </Card>
        </Grid>
        <Grid p={0} m={0} item xs={12} sm={6} md={4} lg={4}>
          <Card style={{ margin: 0 }}>
            <CardHeader
              title={
                <Typography className={classes.textName2}>
                  Model*
                </Typography>
              }
            ></CardHeader>
            <CardBody style={{ paddingTop: 0 }}>
              <TextField
                name="assetModel"
                variant="outlined"
                fullWidth
                value={this.state.assetModel}
                onChange={this.handleTextChange}
                placeholder="np. GSR 18 V-90 C, Latitude 5520"
              />
            </CardBody>
          </Card>
        </Grid>
        <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
          <Card style={{ margin: 0 }}>
            <CardHeader
              title={
                <Typography className={classes.textName2}>
                  Informacje dodatkowe
                </Typography>
              }
            ></CardHeader>
            <CardBody style={{ paddingTop: 0 }}>
              <TextField
                name="assetAdditionalInfo"
                variant="outlined"
                fullWidth
                value={this.state.assetAdditionalInfo}
                onChange={this.handleTextChange}
                placeholder="np. numer seryjny, data zakupu, data otrzymania itp."
              />
            </CardBody>
          </Card>
        </Grid>
        <Typography className={classes.textName2} style={{ margin: "8px" }}>
          * Pola obowiązkowe
        </Typography>
        <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12} style={{ marginTop: "16px" }}>
          <ThemeProvider theme={materialTheme}>
            <Button
              onClick={this.handleAddAsset}
              variant="contained"
              color="primary"
              fullWidth
              disabled={this.state.assetType === "" || this.state.assetVendor === "" || this.state.assetModel === ""}
              style={{ padding: "12.5px 8px" }}
            >
              Dodaj środek
            </Button>
          </ThemeProvider>
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = (state) => ({

});

export default connect(
  mapStateToProps,
  {
    addMyInventoryDetails,
  }
)(withStyles(styles, { withTheme: true })(NewAssetDialog));